// from https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollManagement extends Component {
  componentDidUpdate(prevProps) {
    // if we changed pages, scroll back to the top of the page
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollManagement);
