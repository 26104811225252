import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { debounce, isEmpty } from "lodash";

// components
import EpicFilterCheckboxList from "../EpicFilterCheckboxList/EpicFilterCheckboxList";
import InfoIcon from "../InfoIcon/InfoIcon";

// constants
import {
  sprintOptions,
  urlParamKeys,
  urlParamsValuesLabels
} from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";
import {
  EpicDetailsInterface,
  GroupsListInterface,
  ResponseTotalInterface
} from "../../interfaces/work-items";
import {
  UrlParamsInterface,
  UrlParamsHookReturnInterface
} from "../../interfaces/url-params";

// styled components
const Option = styled.span`
  align-items: center;
  display: flex;
`;

type Props = {
  className?: string;
  otherSprintItems: UrlParamsInterface["otherSprintItems"];
  testId?: string;
  updateUrlParams: UrlParamsHookReturnInterface["updateUrlParams"];
  workItemsData?: EpicDetailsInterface | GroupsListInterface;
};

export const OtherSprintItemsFilter = ({
  className,
  otherSprintItems,
  testId = "testId",
  updateUrlParams,
  workItemsData
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-owner-contributor-filter`;
  const { trackEvent } = useTracking();
  const [areFiltersDisabled, setAreFiltersDisabled] = useState(false);
  const otherSprintItemsTotals = workItemsData?.otherSprintItemsTotals;
  const optionsList = ([
    {
      id: sprintOptions.UNASSIGNED.id,
      isDisabled: areFiltersDisabled,
      isSelected: otherSprintItems.includes(sprintOptions.UNASSIGNED.id),
      helpTextKey: "sprint-options-unassigned"
    },
    {
      id: sprintOptions.OTHER_TEAMS.id,
      isDisabled: areFiltersDisabled,
      isSelected: otherSprintItems.includes(sprintOptions.OTHER_TEAMS.id),
      helpTextKey: "sprint-options-other-teams"
    }
  ] as Array<{
    name: string;
    id: string;
    isDisabled: boolean;
    isSelected: boolean;
    helpTextKey: "sprint-options-unassigned" | "sprint-options-other-teams";
  }>).map(o => {
    return {
      ...o,
      name: (
        <Option>
          {urlParamsValuesLabels[o.id]} (
          {otherSprintItemsTotals?.find(
            (totals: ResponseTotalInterface) => totals.otherSprintItem === o.id
          )?.total || 0}
          ){" "}
          <InfoIcon content={o.helpTextKey} testId={`${thisTestId}-${o.id}`} />
        </Option>
      )
    };
  });

  // fixing ts error with this:
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedToggle = useMemo(
    () =>
      debounce(updatedOtherSprintItems => {
        updateUrlParams({
          [urlParamKeys.SPRINT_WORK_OTHER]: updatedOtherSprintItems
        });
        setAreFiltersDisabled(false);
      }, 500),
    [updateUrlParams]
  );

  function onToggleOtherSprintItems(
    updated: Array<UiFilterSelectionStatusInterface>
  ) {
    const updatedOtherSprintItems = updated
      .filter((u: UiFilterSelectionStatusInterface) => u.isSelected)
      .map((s: UiFilterSelectionStatusInterface) => s.id);

    setAreFiltersDisabled(true);
    debouncedToggle(updatedOtherSprintItems);
    trackEvent({
      label: "ui-filter-sprint-options-change",
      value: `changed selected sprint options to ${
        isEmpty(updatedOtherSprintItems)
          ? "none selected"
          : updatedOtherSprintItems.join(",")
      }`
    });
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <EpicFilterCheckboxList
        header="Other Sprint Items"
        list={optionsList}
        name="other-sprint-items"
        onUpdateSelection={onToggleOtherSprintItems}
        testId="other-sprint-items"
      />
    </div>
  );
};

export default OtherSprintItemsFilter;
