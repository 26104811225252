import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { isEmpty, partition } from "lodash";

// components
import DirectorViewNavigation from "../DirectorViewNavigation/DirectorViewNavigation";
import FlexibleTeamsNavigation from "../FlexibleTeamsNavigation/FlexibleTeamsNavigation";
import UiCenteringWrapper from "../UiCenteringWrapper/UiCenteringWrapper";
import UiFilterTimeRange from "../UiFilterTimeRange/UiFilterTimeRange";

// constants
import { pageIds, workDeepDiveSections } from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTracking } from "../../hooks/useTracking";
import { useDefaultTeam } from "../../hooks/useDefaultTeam";
import { useOrganizationData } from "../../hooks/useOrganizationData";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { TeamInterface } from "../../interfaces/team";
import { UserReportWithMetadataInterface } from "../../interfaces/user";

// utils
import { useActiveUsersTeams } from "../../hooks/useActiveUsersTeams";
import LoadingStates from "../LoadingStates/LoadingStates";
import { useQueryClient } from "react-query";

// styled components
const Container = styled.nav`
  margin-bottom: 1.5rem;
`;
const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
const DirectorAndTeams = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;
const TimerangeWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;
const DirectorNav = styled(DirectorViewNavigation)`
  margin-right: 2rem;
`;
const FlexTeamsLoader = styled(LoadingStates)`
  width: auto;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const GlobalNavigation = ({
  className,
  testId = "testId"
}: Props): React.ReactElement | null => {
  const thisTestId = `${testId}-global-navigation`;
  const [activeOverlay, setActiveOverlay] = useState<string | null>(null);
  const { trackEvent } = useTracking();

  const flags = useSelector((state: AppStateInterface) => state.flags);
  const {
    getWorkDeepDivePath,
    workDeepDiveParams,
    urlParams,
    updateUrlParams
  } = useUrlParams();
  const { team } = urlParams;
  const defaultTeam = useDefaultTeam();
  const queryClient = useQueryClient();
  const { data: orgChartRootTeam } = useOrganizationData();
  const { data: activeUsersTeams } = useActiveUsersTeams();
  const history = useHistory();
  const match = useRouteMatch<{ pageId?: string }>("/:pageId");
  const groupIdMatch = useRouteMatch<{ groupId?: string }>(
    `/${pageIds.WORK_DEEP_DIVE}/${workDeepDiveSections.PROJECT_WORK}/:groupId`
  );
  const pageId = match?.params.pageId;
  const groupId = groupIdMatch?.params.groupId;
  const teamLeadId = urlParams.teamLeadId;

  // if a default team exists, pop it on the front so it's not buried in a later
  // page of teams
  const displayTeams: Array<TeamInterface> = useMemo(() => {
    if (!!activeUsersTeams?.length) {
      if (!!activeUsersTeams?.length && !!defaultTeam) {
        const [foundDefaultTeam, otherTeams] = partition(
          activeUsersTeams,
          (t: TeamInterface) => t.teamId === defaultTeam?.teamId
        );
        if (!!foundDefaultTeam) {
          return [...foundDefaultTeam, ...otherTeams];
        }
      }
    }
    return [];
  }, [activeUsersTeams, defaultTeam]);

  if (
    pageId === pageIds.REPORTS ||
    pageId === pageIds.LABS ||
    pageId === pageIds.SETTINGS ||
    pageId === pageIds.OVERVIEW
  ) {
    return null;
  }

  const shouldShowFlexibleTeams =
    flags?.["flexible-teams-settings"] && !!displayTeams?.length && !!team;
  const shouldShowDirectorView = orgChartRootTeam?.teamMembers?.some(
    (t: UserReportWithMetadataInterface) => !!t?.team
  );

  function onTriggerOverlay(overlayKey: string) {
    trackEvent({
      label: overlayKey,
      value: `clicked ${activeOverlay === overlayKey ? "closed" : "open"}`
    });
    setActiveOverlay(activeOverlay === overlayKey ? null : overlayKey);
  }

  function onHideOverlay(overlayKey: string) {
    trackEvent({
      label: overlayKey,
      value: "closed"
    });
    setActiveOverlay(null);
  }

  function onCancelOverlay(overlayKey: string) {
    trackEvent({
      label: overlayKey,
      value: "closed with X"
    });
    setActiveOverlay(null);
  }

  async function onClickTeam({
    e,
    team
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    team: TeamInterface;
  }) {
    e.persist();

    // await trackEvent({
    //   e,
    //   label: "flexible-teams-navigation",
    //   value: `clicked ${team.teamName} team`
    // });
    await trackEvent({
      e,
      label: `changed-team-${team.teamId}`,
      value: `updated app to ${team.teamName} team`
    });

    // persist a team lead if we're flipping between teams, otherwise clear the
    // rest
    updateUrlParams({ team, teamLeadId: teamLeadId || null }, false);
    // if a groupId exists, ie we're on a details page in work deep dive, clear
    // that and send them back to the root project work page
    if (!!groupId) {
      history.replace(
        getWorkDeepDivePath({
          selectedSection: workDeepDiveParams.selectedSection
        })
      );
    }
    // this is duplicative with ReportsCard and OrganizationNavigationMenu, this should be
    // in a hook somewhere central, not a super good place until we're off the
    // legacy app completely
    queryClient.invalidateQueries("active-board");
    queryClient.invalidateQueries("projects-and-boards");
    queryClient.invalidateQueries("sprint-metadata");
    queryClient.invalidateQueries("sprint-health");
    queryClient.invalidateQueries(["teams", { type: "active-users-teams" }]);
  }

  return (
    <Container className={className} data-testid={thisTestId}>
      <UiCenteringWrapper>
        <ContentWrapper>
          <DirectorAndTeams>
            {shouldShowDirectorView ? (
              <DirectorNav
                activeOverlay={activeOverlay}
                onHideOverlay={() => onHideOverlay("director-view-navigation")}
                onTriggerOverlay={() =>
                  onTriggerOverlay("director-view-navigation")
                }
                testId={thisTestId}
              />
            ) : null}
            {shouldShowFlexibleTeams ? (
              <FlexTeamsLoader
                isSpinningState={isEmpty(displayTeams)}
                content={
                  <FlexibleTeamsNavigation
                    teams={displayTeams}
                    testId={thisTestId}
                    onClickTeam={onClickTeam}
                    selectedTeam={team}
                  />
                }
                testId={thisTestId}
              />
            ) : null}
          </DirectorAndTeams>
          <TimerangeWrapper>
            <UiFilterTimeRange
              activeOverlay={activeOverlay}
              onCancelOverlay={() => onCancelOverlay("timerange")}
              setActiveOverlay={() => onTriggerOverlay("timerange")}
              testId={thisTestId}
            />
          </TimerangeWrapper>
        </ContentWrapper>
      </UiCenteringWrapper>
    </Container>
  );
};

export default GlobalNavigation;
