import { useCallback, useContext, useMemo } from "react";
import querystring from "query-string";
import { invert, isEmpty } from "lodash";

// constants
import {
  teamDeepDiveMeasurePathParams,
  teamDeepDiveSections,
  meetingInsightsMeasurePathParams,
  pageIds,
  pageMetadata,
  workDeepDiveSections
} from "../constants/constants";

// context
import { UrlParamsContext } from "../context/url-params";

// hooks
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDefaultTeam } from "./useDefaultTeam";
import { useUserSettings } from "./useUserSettings";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";
import {
  PathHelperOptionsInterface,
  UrlParamsHookReturnInterface,
  UrlParamsInterface
} from "../interfaces/url-params";

// utils
import {
  getQuerystringParamsToSet,
  parseQuerystring
} from "../utils/url-params";
import { getWorkItemsRequestParamsFromUrlParams } from "../utils/work-items";

export const useUrlParams = (): UrlParamsHookReturnInterface => {
  // selectors
  const session = useSelector((state: AppStateInterface) => state.session);
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const canViewOpsMetrics = !!flags?.[
    pageMetadata[workDeepDiveSections.OPS_METRICS].flag as string
  ];

  // hooks
  const history = useHistory();
  const defaultTeam = useDefaultTeam();
  const { data: userSettings } = useUserSettings();
  const matchWorkDeepDive = useRouteMatch<{
    pageId: string;
    selectedSection?: string;
    groupId?: string;
  }>(`/${pageIds.WORK_DEEP_DIVE}/:selectedSection?/:groupId?`);
  const groupId = matchWorkDeepDive?.params.groupId;
  const matchPageId = useRouteMatch<{
    pageId: string;
  }>(`/:pageId`);
  const currentPageId = matchPageId?.params.pageId;
  const selectedWorkDeepDiveSection = useMemo(
    () =>
      // TODO: this is kind of gross - we need to figure out a more
      // elegant way of defaulting the tabs wrt dynamic tabs from being flagged,
      // etc.
      !matchWorkDeepDive?.params.selectedSection
        ? canViewOpsMetrics
          ? workDeepDiveSections.OPS_METRICS
          : workDeepDiveSections.PROJECT_WORK
        : matchWorkDeepDive?.params.selectedSection,
    [canViewOpsMetrics, matchWorkDeepDive]
  );
  const matchTeamDeepDive = useRouteMatch<{
    selectedSection?: string;
    selectedMetric?: string;
  }>(`/${pageIds.TEAM_DEEP_DIVE}/:selectedSection?/:selectedMetric?`);
  const selectedTeamDeepDiveSection = useMemo(
    () =>
      matchTeamDeepDive?.params.selectedSection ||
      teamDeepDiveSections.PEOPLE_HEALTH,
    [matchTeamDeepDive]
  );
  const selectedTeamDeepDiveMetric = useMemo(() => {
    const invertedParams = invert({
      ...teamDeepDiveMeasurePathParams,
      ...meetingInsightsMeasurePathParams
    });
    return !!matchTeamDeepDive?.params.selectedMetric &&
      !!invertedParams[matchTeamDeepDive.params.selectedMetric]
      ? invertedParams[matchTeamDeepDive.params.selectedMetric]
      : selectedTeamDeepDiveSection === teamDeepDiveSections.MEETING_INSIGHTS
      ? "MEETING_DISTRIBUTION"
      : "OVERALL_TEAM_HEALTH";
  }, [matchTeamDeepDive, selectedTeamDeepDiveSection]);

  // variables
  const urlParamsContextValues = useContext(UrlParamsContext);
  const workItemsRequestParams = useMemo(
    () =>
      getWorkItemsRequestParamsFromUrlParams(urlParamsContextValues, groupId),
    [groupId, urlParamsContextValues]
  );
  const timerange = useMemo(() => urlParamsContextValues.timerange, [
    urlParamsContextValues
  ]);
  const namedTimeranges = useMemo(() => session.namedTimeranges, [session]);

  const getPathForPageId = useCallback(
    (pageId: string, options: PathHelperOptionsInterface = {}): string => {
      const {
        groupId,
        selectedSection,
        selectedMetric,
        persistQuerystring = true,
        querystringParams = {}
      } = options;
      const path = groupId
        ? {
            // todo: if group id ever lives on pages other than the project explorer's
            // todo: your-teams-data tab this will need to be reworked
            pageId,
            selectedSection: workDeepDiveSections.PROJECT_WORK,
            groupId
          }
        : selectedSection
        ? selectedMetric
          ? {
              pageId,
              selectedSection,
              selectedMetric:
                teamDeepDiveMeasurePathParams[selectedMetric] ||
                meetingInsightsMeasurePathParams[selectedMetric] ||
                selectedMetric
            }
          : {
              pageId,
              selectedSection
            }
        : {
            pageId
          };

      const parsedQuerystring = parseQuerystring(window.location.search);
      const paramsToSet = getQuerystringParamsToSet({
        pageId,
        parsedQuerystring,
        paramsFromUi: querystringParams,
        persistQuerystring,
        defaultTeam,
        namedTimeranges,
        timerange,
        userSettings
      });

      return `${generatePath(
        pageId === pageIds.START
          ? "/"
          : pageId === pageIds.WORK_DEEP_DIVE
          ? "/:pageId/:selectedSection?/:groupId?"
          : pageId === pageIds.TEAM_DEEP_DIVE
          ? "/:pageId/:selectedSection?/:selectedMetric?"
          : pageId === pageIds.SETTINGS
          ? "/:pageId/:selectedSection?/:selectedMetric?"
          : pageId === pageIds.OVERVIEW
          ? "/:pageId/:selectedSection?/:selectedMetric?"
          : "/:pageId",
        path
      )}${
        isEmpty(paramsToSet)
          ? ""
          : `?${querystring.stringify(paramsToSet, {
              arrayFormat: "bracket-separator",
              arrayFormatSeparator: "|"
            })}`
      }`;
    },
    [defaultTeam, namedTimeranges, timerange, userSettings]
  );

  const getWorkDeepDivePath = useCallback(
    (options: PathHelperOptionsInterface = {}): string =>
      getPathForPageId(pageIds.WORK_DEEP_DIVE, options),
    [getPathForPageId]
  );

  const getSprintRetrosPath = useCallback(
    (options: PathHelperOptionsInterface = {}): string =>
      getPathForPageId(pageIds.RETROS, options),
    [getPathForPageId]
  );

  const getStartPath = useCallback(
    (options: PathHelperOptionsInterface = {}): string =>
      getPathForPageId(pageIds.START, options),
    [getPathForPageId]
  );

  const getTeamHomePath = useCallback(
    (options: PathHelperOptionsInterface = {}): string =>
      getPathForPageId(pageIds.TEAM_HOME, options),
    [getPathForPageId]
  );

  const getTeamDeepDivePath = useCallback(
    (options: PathHelperOptionsInterface = {}): string =>
      getPathForPageId(pageIds.TEAM_DEEP_DIVE, options),
    [getPathForPageId]
  );

  const getSettingsPath = useCallback(
    (options: PathHelperOptionsInterface = {}): string =>
      getPathForPageId(pageIds.SETTINGS, options),
    [getPathForPageId]
  );

  const getOverviewPath = useCallback(
    (options: PathHelperOptionsInterface = {}): string =>
      getPathForPageId(pageIds.OVERVIEW, options),
    [getPathForPageId]
  );

  const updateUrlParams = useCallback(
    (
      paramsFromUi: Partial<UrlParamsInterface>,
      persistQuerystring: Array<string> | boolean = true
    ) => {
      const parsedQuerystring = parseQuerystring(window.location.search);
      const paramsToSet = getQuerystringParamsToSet({
        pageId: currentPageId,
        parsedQuerystring,
        paramsFromUi,
        persistQuerystring,
        defaultTeam,
        namedTimeranges,
        timerange,
        userSettings
      });
      history.push(
        `${window.location.pathname}?${querystring.stringify(paramsToSet, {
          arrayFormat: "bracket-separator",
          arrayFormatSeparator: "|"
        })}`
      );
    },
    [
      currentPageId,
      defaultTeam,
      history,
      namedTimeranges,
      timerange,
      userSettings
    ]
  );

  const teamDeepDiveParams = useMemo(
    () => ({
      selectedMetric: selectedTeamDeepDiveMetric,
      selectedSection: selectedTeamDeepDiveSection,
      selectedUser: urlParamsContextValues.selectedUser || "team"
    }),
    [
      selectedTeamDeepDiveMetric,
      selectedTeamDeepDiveSection,
      urlParamsContextValues
    ]
  );

  const workDeepDiveParams = useMemo(
    () => ({
      selectedSection: selectedWorkDeepDiveSection,
      selectedUser: urlParamsContextValues.selectedUser || "team"
    }),
    [selectedWorkDeepDiveSection, urlParamsContextValues]
  );

  return {
    getOverviewPath,
    getPathForPageId,
    getSettingsPath,
    getSprintRetrosPath,
    getStartPath,
    getTeamDeepDivePath,
    getTeamHomePath,
    getWorkDeepDivePath,
    teamDeepDiveParams,
    updateUrlParams,
    urlParams: urlParamsContextValues,
    workDeepDiveParams,
    workItemsRequestParams
  };
};
