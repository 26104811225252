import React, { useEffect, useState } from "react";

// components
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
// components
import { Route, Switch, useHistory } from "react-router-dom";
import DashboardPage from "../DashboardPage/DashboardPage";
import { ErrorBoundary } from "@sentry/react";
import ScrollManagement from "../ScrollManagement/ScrollManagement";
import OktaUiReadyWrapper from "../OktaUiReadyWrapper/OktaUiReadyWrapper";
import UplevelUi from "../UplevelUi/UplevelUi";

// containers
import UnknownUser from "../../containers/UnknownUser/UnknownUser";

// context
import { UrlParamsProvider } from "../../context/url-params";

// hooks
import { useSelector } from "react-redux";
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

const OktaWrapper = () => {
  const history = useHistory();
  const { trackEvent } = useTracking();

  const [preOktaRedirectUrl, setPreOktaRedirectUrl] = useState<string | null>(
    null
  );
  const [hasSentEvent, setHasSentEvent] = useState(false);
  const [hasRedirected, setHasRedirected] = useState(false);
  const auth = useSelector((state: AppStateInterface) => state.auth);
  const authParams = auth?.authParams;
  const session = useSelector((state: AppStateInterface) => state.session);
  const sessionUsername = session?.username;

  const onOktaReturn = async (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
    setPreOktaRedirectUrl(originalUri);
    setHasRedirected(true);
  };
  // fire analytics event when fully populated session params are available on okta redirect
  useEffect(() => {
    if (
      !hasSentEvent &&
      hasRedirected &&
      !!preOktaRedirectUrl &&
      !!sessionUsername &&
      sessionUsername !== "unauthenticated-user"
    ) {
      trackEvent({
        e: {
          type: "auth"
        },
        label: "okta-login-return",
        value: `returned to ${preOktaRedirectUrl}`
      });
      setHasSentEvent(true);
    }
  }, [
    preOktaRedirectUrl,
    hasRedirected,
    hasSentEvent,
    sessionUsername,
    trackEvent
  ]);

  const oktaAuth = new OktaAuth({
    issuer: authParams.issuer,
    clientId: authParams.clientId,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ["openid", "profile", "email"],
    pkce: true
  });

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={onOktaReturn}>
      <ErrorBoundary>
        <UrlParamsProvider>
          <ScrollManagement>
            <UplevelUi testId="client-dashboard">
              <Switch>
                <Route exact path="/login/callback">
                  <LoginCallback />
                </Route>
                <Route path="/unknown-user">
                  <UnknownUser />
                </Route>
                <SecureRoute path="/:pageId?">
                  <OktaUiReadyWrapper hasRedirected={hasRedirected}>
                    <DashboardPage />
                  </OktaUiReadyWrapper>
                </SecureRoute>
              </Switch>
            </UplevelUi>
          </ScrollManagement>
        </UrlParamsProvider>
      </ErrorBoundary>
    </Security>
  );
};

export default OktaWrapper;
