import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { debounce, flattenDeep, get } from "lodash";

// components
import SprintStepper from "../SprintStepper/SprintStepper";
import TimeRangeSelector from "../TimeRangeSelector/TimeRangeSelector";
import TimerangeStepper from "../TimerangeStepper/TimerangeStepper";
import UiFilterOverlay from "../UiFilterOverlay/UiFilterOverlay";

// constants
import {
  pageIds,
  timerangeIds,
  timerangeTypes
} from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";
import { useSprintMetadata } from "../../hooks/useSprintMetadata";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { TimerangeMetadataInterface } from "../../interfaces/constants";
import { UiFilterPropsInterface } from "../../interfaces/ui-filter";
import { BoardInterface } from "../../interfaces/sprint-metadata";

// utils
import { formatTimestamp } from "../../utils/date";
import { getSprintTimerangesForBoard } from "../../utils/timeranges";

// styled components
const Container = styled.div`
  align-items: center;
  display: flex;
`;
const StepperWrapper = styled.div`
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  margin-left: 2rem;
`;
const TriggerContent = styled.div`
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  margin-right: 1rem;
`;
const Sprint = styled(SprintStepper)`
  width: 100%;
`;
const Timerange = styled(TimerangeStepper)`
  width: 100%;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
} & UiFilterPropsInterface;

export const UiFilterTimeRange = ({
  activeOverlay,
  className,
  onCancelOverlay,
  setActiveOverlay,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-ui-filter-time-range`;
  const { trackEvent } = useTracking();
  const { pageId } = useParams<{ pageId: string }>();
  const { urlParams, updateUrlParams } = useUrlParams();
  const { timerange, board, boardId, team } = urlParams;

  const [isStepperDisabled, setIsStepperDisabled] = useState(false);

  const namedTimeranges = useSelector(
    (state: AppStateInterface) => state.session.namedTimeranges
  );
  const {
    data: sprintMetadata,
    isSuccess: isSuccessSprintMetadata
  } = useSprintMetadata({ type: `${team?.teamId}-team`, team });

  const activeSprints: Array<TimerangeMetadataInterface> = useMemo(
    () =>
      !!team?.useSprints
        ? !!team?.isOrgChartTeam
          ? isSuccessSprintMetadata && !!sprintMetadata?.length
            ? flattenDeep(
                sprintMetadata.map((b: BoardInterface) =>
                  getSprintTimerangesForBoard(b)
                )
              )
            : []
          : !!board
          ? getSprintTimerangesForBoard(board)
          : []
        : [],
    [board, isSuccessSprintMetadata, sprintMetadata, team]
  );

  const steppableSprints = useMemo(
    () =>
      !!boardId
        ? activeSprints.filter(
            (s: TimerangeMetadataInterface) => s.boardId === boardId
          )
        : [],
    [activeSprints, boardId]
  );

  const selectionTimeranges = useMemo(() => {
    return pageId === pageIds.RETROS
      ? activeSprints
      : [
          ...activeSprints,
          namedTimeranges[timerangeIds.YESTERDAY],
          namedTimeranges[timerangeIds.THIS_WEEK],
          namedTimeranges[timerangeIds.LAST_WEEK],
          namedTimeranges[timerangeIds.PREVIOUS_14_DAYS],
          namedTimeranges[timerangeIds.PREVIOUS_30_DAYS],
          namedTimeranges[timerangeIds.PREVIOUS_90_DAYS]
        ];
  }, [activeSprints, namedTimeranges, pageId]);

  const debouncedStepperChange = useCallback(
    debounce(selection => {
      updateUrlParams({ timerange: selection });
      setIsStepperDisabled(false);
    }, 500),
    []
  );

  function onChangeStepper(selection: TimerangeMetadataInterface) {
    setIsStepperDisabled(true);
    debouncedStepperChange(selection);
  }

  function onSubmitTimeRangeSelector(selection: TimerangeMetadataInterface) {
    const { end, start, id: updatedTimerangeId, timerangeType } = selection;
    const eventParams = {
      label: "ui-filter-time-range-change",
      value: `(${formatTimestamp({
        format: "MM/DD/YYYY",
        timestamp: start
      })}-${formatTimestamp({
        format: "MM/DD/YYYY",
        timestamp: end
      })})`
    };
    if (timerangeType === timerangeTypes.SPRINTS) {
      const fullSprintMetadata = activeSprints.find(
        (s: TimerangeMetadataInterface) => s.id === updatedTimerangeId
      );
      if (!!fullSprintMetadata) {
        const { displayName: sprintName } = fullSprintMetadata;

        trackEvent({
          ...eventParams,
          value: `changed selected time range to sprint ${sprintName} ${eventParams.value}`
        });
      }
    } else {
      trackEvent({
        ...eventParams,
        value: `changed selected time range to ${updatedTimerangeId} ${eventParams.value}`
      });
    }
    updateUrlParams({ timerange: selection });
    onCancelOverlay();
  }

  return !!timerange ? (
    <Container className={className} data-testid={thisTestId}>
      <UiFilterOverlay
        isActive={activeOverlay === "timerange"}
        onCancelOverlay={onCancelOverlay}
        onTriggerOverlay={() => setActiveOverlay("timerange")}
        triggerContent={
          <TriggerContent data-heap-redact-text="true">
            {timerange.displayName}
          </TriggerContent>
        }
        testId={thisTestId}
      >
        <TimeRangeSelector
          onCancel={onCancelOverlay}
          onSubmit={onSubmitTimeRangeSelector}
          selectionTimeranges={selectionTimeranges}
          testId={thisTestId}
          timerange={timerange}
        />
      </UiFilterOverlay>
      <StepperWrapper>
        {!Object.values(timerangeIds).includes(timerange.id) ? (
          <Sprint
            steppableSprints={steppableSprints}
            isDisabled={isStepperDisabled}
            onChange={onChangeStepper}
            testId={thisTestId}
            timerange={timerange}
          />
        ) : (
          <Timerange
            isDisabled={isStepperDisabled}
            onChange={onChangeStepper}
            testId={thisTestId}
            timerange={timerange}
          />
        )}
      </StepperWrapper>
    </Container>
  ) : null;
};

export default UiFilterTimeRange;
