import { isUndefined } from "lodash";
import React, { useState } from "react";
import { useAuthProvider } from "../../hooks/useAuthProvider";
import DataLoadWrapper from "../DataLoadWrapper/DataLoadWrapper";
import LoadingStates from "../LoadingStates/LoadingStates";

// typescript props
type Props = {
  children: React.ReactNode;
  hasRedirected: boolean;
};

const Auth0UiReadyWrapper = ({ children, hasRedirected }: Props) => {
  const { getIsAuthenticated } = useAuthProvider();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  // using then notation because I want to make sure we have the latest accurate
  // result for isAuthenticated and I'm not sure if a useeffect would fire as
  // expected if getIsAuthenticated were just a dependency.
  getIsAuthenticated().then((response: boolean) =>
    setIsAuthenticated(response)
  );

  // wait with a spinner until getIsAuthenticated response fully resolves. if
  // authenticated, load the children with the data load wrapper, which has our
  // checks to make sure the data is loaded properly. if not authenticated,
  // attempt to load the child tree with the secure route in it to get it to hit
  // the login flow.
  return (
    <LoadingStates
      isSpinningState={hasRedirected && isUndefined(isAuthenticated)}
      isNoDataState={
        !hasRedirected && !isUndefined(isAuthenticated) && !isAuthenticated
      }
      noDataContent={children}
      content={
        isAuthenticated ? <DataLoadWrapper>{children}</DataLoadWrapper> : null
      }
      testId="ui-ready-wrapper"
    />
  );
};

export default Auth0UiReadyWrapper;
