import React from "react";
import styled from "styled-components/macro";

// components
import CloseButton from "../CloseButton/CloseButton";

// styled components
const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;
const Footer = styled.footer`
  background: ${props => props.theme.colors.all.jean};
  border-top: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  justify-content: flex-end;
  padding: 2rem 1rem;
`;
const SubmitButton = styled.button<{ disabled: boolean }>`
  background: ${props =>
    props.disabled
      ? props.theme.colors.button.disabled.default.bg
      : props.theme.colors.button.default.default.bg};
  border: 0;
  color: ${props =>
    props.disabled
      ? props.theme.colors.button.disabled.default.fg
      : props.theme.colors.button.default.default.fg};
  transition: 0.2s all;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  letter-spacing: 0.1rem;
  padding: 1rem 3rem;

  &:hover {
    background-color: ${props =>
      props.disabled
        ? props.theme.colors.button.disabled.hover.bg
        : props.theme.colors.button.default.hover.bg};
    color: ${props =>
      props.disabled
        ? props.theme.colors.button.disabled.hover.fg
        : props.theme.colors.button.default.hover.fg};
  }
`;

// typescript props
type Props = {
  children: React.ReactNode;
  className?: string;
  isSubmitDisabled?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  showApplyChanges?: boolean;
  testId?: string;
};

const UiFilterPopupWrapper = ({
  children,
  className,
  isSubmitDisabled = false,
  onCancel,
  onSubmit,
  showApplyChanges = true,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-ui-filter-popup-wrapper`;
  return (
    <div className={className} data-testid={thisTestId}>
      <PositionedCloseButton onClick={onCancel} testId={thisTestId} />
      {children}
      {showApplyChanges ? (
        <Footer>
          <SubmitButton
            disabled={isSubmitDisabled}
            onClick={onSubmit}
            data-testid={`${thisTestId}-submit-button`}
          >
            Apply
          </SubmitButton>
        </Footer>
      ) : null}
    </div>
  );
};

export default UiFilterPopupWrapper;
