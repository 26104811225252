import React from "react";
import styled from "styled-components/macro";

// components

// constants
import { groupByTypes, pageIds } from "../../constants/constants";

// hooks
import { useParams } from "react-router-dom";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import GroupBySelector from "../GroupBySelector/GroupBySelector";

// styled components
const Container = styled.div`
  position: relative;
  width: 16rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const WorkItemsGroupBySelector = ({
  className,
  testId = "testId"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-work-items-group-by-selector`;
  const { pageId } = useParams<{ pageId: string }>();
  const { urlParams } = useUrlParams();
  const groupBy = urlParams.groupBy;

  const options = [
    groupByTypes.EPIC,
    groupByTypes.PROJECT,
    groupByTypes.USER
  ].filter(o => {
    if (pageId === pageIds.RETROS && o === groupByTypes.USER) {
      return groupBy === groupByTypes.USER;
    }
    return true;
  });

  return (
    <Container className={className} data-testId={thisTestId}>
      <GroupBySelector options={options} testId={thisTestId} />
    </Container>
  );
};

export default WorkItemsGroupBySelector;
