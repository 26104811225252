import React from "react";
import styled from "styled-components/macro";

// components
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

// styled components
const Container = styled.div`
  display: flex;
  padding: 0 5rem 5rem 3rem;
`;
const IconWrapper = styled.div`
  flex: 0 0 15rem;
  text-align: center;
  && {
    padding-left: 2rem;
    padding-right: 6rem;
  }
`;
const StyledIcon = styled(Icon)`
  font-size: 12rem;
  line-height: 1.3;
`;
const TextWrapper = styled.div``;
const Header = styled.h1`
  font-family: ${props => props.theme.fonts.header.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.header.sizes.md};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
`;
const StyledButton = styled(Button)`
  margin-top: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  isSuccess: boolean;
  onClickConfirmation: (isConfirmationButton?: boolean) => void;
  onClickResumeEditing: () => void;
  testId?: string;
};

const MeetingClassificationOverrideConfirmation = ({
  className,
  isSuccess,
  onClickConfirmation,
  onClickResumeEditing,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-meeting-classification-override-confirmation`;

  return (
    <Container className={className} data-testid={thisTestId}>
      <IconWrapper>
        <StyledIcon
          icon={isSuccess ? "paper-airplane" : "warning"}
          testId={testId}
        />
      </IconWrapper>
      <TextWrapper>
        <Header>{isSuccess ? "Super!" : "Yikes!"}</Header>
        {isSuccess ? (
          <p>
            Meeting type has been updated for your team. Deep work and meeting
            trends will reflect the change in tomorrow&rsquo;s report.
          </p>
        ) : (
          <p>
            Well that&rsquo;s not good. Something went wrong there. Please click
            the button below to try that again.
          </p>
        )}

        <StyledButton
          bordered={false}
          onClick={() =>
            isSuccess ? onClickConfirmation(true) : onClickResumeEditing()
          }
          testId={`${testId}-meeting-classification-override-confirmation-${
            isSuccess ? "back-to-meetings" : "try-again"
          }`}
        >
          {isSuccess ? "Back to my meetings" : "Back to edit classification"}
        </StyledButton>
      </TextWrapper>
    </Container>
  );
};

export default MeetingClassificationOverrideConfirmation;
