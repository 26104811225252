import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { isEmpty, sortBy } from "lodash";

// components
import ContentWithIcon from "../ContentWithIcon/ContentWithIcon";
import EmptyState from "../EmptyState/EmptyState";
import MeetingClassificationLabel from "../MeetingClassificationLabel/MeetingClassificationLabel";
import PipedList from "../PipedList/PipedList";
import ScrollContainerWithReset from "../ScrollContainerWithReset/ScrollContainerWithReset";
import Spinner from "../Spinner/Spinner";
import Table from "../Table/Table";
import TimeRange from "../TimeRange/TimeRange";

// utils
import { formatTimestamp } from "../../utils/date";

// styled components
const ScrollContainer = styled(ScrollContainerWithReset)`
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
`;
const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 50rem;
  width: 100%;
`;
const MeetingTable = styled(Table)`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  width: 100%;

  & th {
    background: ${props => props.theme.colors.all.white};
    font-size: 1.1rem;
    font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
    padding-bottom: 1rem;
    position: sticky;
    top: 0;
  }

  & td {
    font-size: ${props => props.theme.fonts.primary.sizes.xs};
    vertical-align: middle;

    &.classificationName {
      width: 25%;
    }
    &.title {
      width: 30%;
    }
  }
`;
const StyledEmptyState = styled(EmptyState)`
  text-transform: none;
`;
const EditButton = styled.button`
  background: none;
  border: 0;
  display: flex;
  text-align: left;
  width: 100%;
`;
const LabelWithIcon = styled(ContentWithIcon)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div {
    order: 1;
    margin-right: 1rem;
  }
  & > span {
    order: 2;
  }
`;

export const MeetingList = ({
  className,
  isEditing,
  list = [],
  onClickEdit,
  testId = "testId"
}) => {
  const thisTestId = `${testId}-meeting-classifications-meetings-list`;

  // memoing these to see if it will help with perf
  const { data, columns } = useMemo(() => {
    return {
      data: sortBy(
        list.map(l => ({
          ...l,
          // constructing a string version for the accessor for proper sorting
          dateAndTime: `${formatTimestamp({
            format: "M/DD",
            timestamp: l.timerange.start
          })} | ${formatTimestamp({
            format: "HH:mm a",
            timestamp: l.timerange.start
          })}-${formatTimestamp({
            format: "HH:mm a",
            timestamp: l.timerange.end
          })}`
        })),
        ["measure", "dateAndTime"]
      ),
      columns: [
        {
          accessor: "classificationName",
          Header: "Classification",
          Cell: ({ cell }) => {
            const label = (
              <MeetingClassificationLabel
                label={cell.value}
                measure={cell.row.original.measure}
                testId={`${thisTestId}-${cell.row.original.measure}`}
              />
            );
            return label;
            // todo: re-enable this when the backend is fixed to support overrides
            // return (
            //   <EditButton onClick={() => onClickEdit(cell.row.original)}>
            //     <LabelWithIcon
            //       icon="edit"
            //       content={label}
            //       testId={thisTestId}
            //     />
            //   </EditButton>
            // );
          },
          className: "classificationName"
        },
        {
          accessor: "title",
          Cell: ({ cell }) => (
            <span data-heap-redact-text="true">{cell.value}</span>
          ),
          Header: "Title",
          className: "title"
        },
        {
          accessor: "dateAndTime",
          Header: "Date/Time",
          Cell: ({ cell }) => {
            const { timerange } = cell.row.original;
            return (
              <PipedList
                list={[
                  formatTimestamp({
                    format: "MM/DD",
                    timestamp: timerange.start
                  }),
                  <TimeRange
                    config={{ format: { start: "hh:mm a", end: "hh:mm a" } }}
                    timerange={timerange}
                    testId={thisTestId}
                  />
                ]}
                testId={thisTestId}
              />
            );
          },
          className: "dateAndTime"
        },
        {
          accessor: "meetingOwner",
          Cell: ({ cell }) => (
            <span data-heap-redact-text="true">{cell.value}</span>
          ),
          Header: "Owner",
          className: "owner"
        }
      ]
    };
  }, [list, onClickEdit, thisTestId]);

  if (isEditing) {
    return (
      <SpinnerWrapper>
        <Spinner testId={testId} />
      </SpinnerWrapper>
    );
  }

  if (isEmpty(list)) {
    return (
      <StyledEmptyState
        body={
          <span>
            It looks like we don&rsquo;t have data for you.{" "}
            <a href="mailto:support@uplevelteam.com">Contact us</a> if this is a
            mistake and we&rsquo;ll get this fixed.
          </span>
        }
        header="Uh oh."
        testId={thisTestId}
      />
    );
  }

  return (
    <ScrollContainer
      className={className}
      testId={thisTestId}
      resetTrigger={list.length}
    >
      <MeetingTable columns={columns} data={data} testId={thisTestId} />
    </ScrollContainer>
  );
};

MeetingList.propTypes = {
  className: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  list: PropTypes.array,
  onClickEdit: PropTypes.func.isRequired,
  testId: PropTypes.string
};

export default MeetingList;
