import React from "react";
import styled from "styled-components";
import { isNull } from "lodash";

// components
import HtmlText from "../HtmlText/HtmlText";
import InfoBook from "../InfoBook/InfoBook";
import InfoPopup from "../InfoPopup/InfoPopup";
import RouteLink from "../RouteLink/RouteLink";
import ValueWithDirectionality from "../ValueWithDirectionality/ValueWithDirectionality";
import ChartOpsMetricsSparkline from "../ChartOpsMetricsSparkline/ChartOpsMetricsSparkline";

// constants
import { directionalityTypes } from "../../constants/constants";

// interfaces
import { TimerangeMetadataInterface } from "../../interfaces/constants";
import {
  OperationalMetricsTextContent,
  OpMetricData,
  OpMetricValueTypes
} from "../../interfaces/ops-metrics";

// utils
import {
  getMetricBoxDisplayValue,
  getMetricBoxUnit
} from "../../utils/operational-metrics";

const Container = styled.div`
  display: flex;
  width: 26.4rem;
  flex-direction: column;
  margin-right: ${props => props.theme.grid.gap};
`;

const Title = styled.header`
  align-items: center;
  border-bottom: ${props => `1px solid ${props.theme.colors.all.jean}`};
  display: flex;
  font-size: ${props => props.theme.fonts.header.sizes.sm};
  font-weight: ${props => props.theme.fonts.header.weights.demiBold};
  margin-bottom: 1.6rem;
`;

const InfoPopups = styled.div`
  align-items: center;
  display: flex;
  margin-left: 0.8rem;
`;

const InfoI = styled(InfoPopup)`
  margin-right: 0.8rem;
`;

const ContentWrapper = styled.div`
  font-size: ${props => props.theme.fonts.header.sizes.xs};
`;

const CurrentValues = styled.div`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${props => props.theme.grid.gap};

  & > div {
    &:nth-of-type(1) {
      &:not(:last-of-type) {
        margin-right: 0.8rem;
      }
    }
  }
`;

const ValueBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;
const PrimaryValue = styled.div`
  font-family: ${props => props.theme.fonts.subheader.name};
  font-size: ${props => props.theme.fonts.subheader.sizes.xl};
  font-weight: ${props => props.theme.fonts.subheader.weights.bold};
  line-height: ${props => props.theme.fonts.subheader.sizes.xl};
`;

const ValueUnit = styled.div`
  font-family: ${props => props.theme.fonts.primary.name};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  line-height: ${props => props.theme.fonts.primary.sizes.xs};
  white-space: nowrap;
`;

const Previous = styled.div`
  align-items: baseline;
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

const PreviousValue = styled.div`
  font-family: ${props => props.theme.fonts.subheader.name};
  font-weight: ${props => props.theme.fonts.subheader.weights.bold};
`;

const PreviousTitle = styled.div`
  white-space: nowrap;
  &::after {
    content: ":";
    margin-right: 0.8rem;
  }
`;

const Link = styled(RouteLink)`
  color: ${props => props.theme.colors.all.wolverine};

  &,
  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    text-decoration: none;
  }
`;

const Delta = styled(ValueWithDirectionality)`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  white-space: nowrap;
`;

const Comparison = styled.div`
  align-items: baseline;
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const ComparisonHeader = styled.header`
  margin-right: 0.8rem;
  &::after {
    content: ": ";
  }
`;
const OverallDelta = styled(ValueWithDirectionality)`
  white-space: nowrap;
`;
const Sparkline = styled(ChartOpsMetricsSparkline)`
  margin-top: 1.6rem;
`;

type Props = {
  deltaValue: number | null;
  firstMonth: string;
  isActiveSprint?: boolean;
  isSprintMode?: boolean;
  linkPath?: string;
  name: OpMetricValueTypes;
  opMetricsData: OpMetricData;
  overallDeltaValue: number | null;
  previousTimerangeHeader: React.ReactNode;
  previousValue: number | null;
  testId?: string;
  textContent: OperationalMetricsTextContent;
  timerange: TimerangeMetadataInterface;
  value: number | null;
};

const OperationalMetricsBox = ({
  deltaValue,
  firstMonth,
  isActiveSprint = false,
  isSprintMode = false,
  linkPath,
  name,
  opMetricsData,
  overallDeltaValue,
  previousTimerangeHeader,
  previousValue,
  testId = "testId",
  textContent,
  value
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-operational-metrics-box-${name}`;
  const currentDisplayUnit = getMetricBoxUnit(
    name,
    isSprintMode,
    isActiveSprint,
    false
  );
  const previousDisplayUnit = getMetricBoxUnit(
    name,
    isSprintMode,
    isActiveSprint,
    true
  );

  // TODO: refactor this component so that we can pass in the entire metric
  // (value + unit) instead of deconstructing the value and then having to do
  // this logic to reconstruct. (maybe even bring in all the value/delta calcs
  // too. this component is kind of confusing.)
  let deltaUnit;
  const unitIsPercentage = /%/.test(currentDisplayUnit);
  if (unitIsPercentage) {
    deltaUnit = "%";
  }
  const isInverted = name === "bugAllocation" || name === "complexPrs";

  const valueAndUnitDisplayBlock = (
    <ValueBlock>
      <PrimaryValue>
        {getMetricBoxDisplayValue(value, unitIsPercentage)}
      </PrimaryValue>
      <ValueUnit>{currentDisplayUnit}</ValueUnit>
    </ValueBlock>
  );

  return (
    <Container>
      <Title>
        {textContent.title}
        {isNull(textContent.definition) &&
        isNull(textContent.summary) ? null : (
          <InfoPopups>
            {isNull(textContent.summary) ? null : (
              <InfoI
                content={
                  <ContentWrapper>
                    <HtmlText
                      htmlText={textContent.summary}
                      testId={thisTestId}
                    />
                  </ContentWrapper>
                }
                name={name}
                testId={thisTestId}
              />
            )}
            {isNull(textContent.definition) ? null : (
              <InfoBook
                content={textContent.definition.content}
                header={textContent.definition.header}
                link={textContent.definition.link}
                name={name}
                testId={thisTestId}
              />
            )}
          </InfoPopups>
        )}
      </Title>
      <CurrentValues>
        <div>
          {!!linkPath ? (
            <Link name={thisTestId} to={linkPath} testId={thisTestId}>
              {valueAndUnitDisplayBlock}
            </Link>
          ) : (
            valueAndUnitDisplayBlock
          )}
        </div>
        {!!deltaValue ? (
          <Delta
            className="ops-metrics-delta-value"
            direction={
              deltaValue > 0
                ? directionalityTypes.POSITIVE
                : directionalityTypes.NEGATIVE
            }
            value={
              getMetricBoxDisplayValue(deltaValue, unitIsPercentage) as number
            }
            unit={deltaUnit}
            isInverted={isInverted}
          />
        ) : (
          <div>--</div>
        )}
      </CurrentValues>
      <Previous>
        <PreviousTitle>{previousTimerangeHeader}</PreviousTitle>
        <PreviousValue>
          {getMetricBoxDisplayValue(previousValue, unitIsPercentage)}
        </PreviousValue>
        <HtmlText
          htmlText={
            name === "releaseCount" ||
            (name === "completionRate" && !isSprintMode)
              ? `&nbsp;${previousDisplayUnit}`
              : previousDisplayUnit
          }
        />
      </Previous>
      <Comparison>
        <ComparisonHeader>Compared to {firstMonth}</ComparisonHeader>
        {isNull(overallDeltaValue) || overallDeltaValue === 0 ? (
          <div>--</div>
        ) : (
          <OverallDelta
            direction={
              overallDeltaValue > 0
                ? directionalityTypes.POSITIVE
                : directionalityTypes.NEGATIVE
            }
            display="text"
            value={overallDeltaValue}
            isInverted={isInverted}
          />
        )}
      </Comparison>
      <Sparkline
        data={opMetricsData}
        isInverted={isInverted}
        metric={name}
        name={name}
        testId={thisTestId}
      />
    </Container>
  );
};

export default OperationalMetricsBox;
