import React from "react";
import styled from "styled-components/macro";

// components
import RouteLink from "../RouteLink/RouteLink";

import WorkMetadataActivities from "../WorkMetadataActivities/WorkMetadataActivities";
import WorkMetadataEpic from "../WorkMetadataEpic/WorkMetadataEpic";
import WorkMetadataTeamList from "../WorkMetadataTeamList/WorkMetadataTeamList";

// constants
import {
  reservedEpics,
  reservedEpicsMetadata
} from "../../constants/constants";

// interfaces
import { GroupInterface } from "../../interfaces/work-items";
import { useUrlParams } from "../../hooks/useUrlParams";

// styled components
const Link = styled(RouteLink)`
  display: block;
  && {
    color: ${props => props.theme.colors.all.wolverine};
    &:hover {
      text-decoration: none;
    }
  }
`;
const Container = styled.div`
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: block;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: ${props =>
      `minmax(calc(2 * (${props.theme.grid.columnWidth} + ${props.theme.grid.gap})), 1fr)
      minmax(calc(3 * (${props.theme.grid.columnWidth} + ${props.theme.grid.gap}) + ${props.theme.grid.columnWidth}), 1fr)
      1fr
      1fr`};
  }
`;
const MetadataWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    padding: 0 0.75rem;
  }
`;
const MetadataHeader = styled.header`
  font-family: ${props => props.theme.fonts.primary.name}, sans-serif;
  font-size: 1.1rem;
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  margin-right: 1.5rem;
  margin-left: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;
const ActivitiesWrapper = styled(MetadataWrapper)`
  border-top: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    border-right: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    border-top: 0;
    justify-content: center;
  }
`;
const OwnersWrapper = styled(MetadataWrapper)`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    border-right: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  }
`;

// typescript props
type Props = {
  className?: string;
  metadata: GroupInterface;
  testId?: string;
};

const WorkMetadata = ({ className, metadata, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-work-metadata`;
  const { groupId, magicRow } = metadata;
  const { getWorkDeepDivePath } = useUrlParams();
  const groupPath = getWorkDeepDivePath({
    groupId:
      magicRow === reservedEpicsMetadata[reservedEpics.UNLINKED_PRS].magicRowId
        ? reservedEpics.UNLINKED_PRS
        : magicRow === reservedEpicsMetadata[reservedEpics.NO_EPIC].magicRowId
        ? reservedEpics.NO_EPIC
        : groupId
  });

  return (
    <Link
      className={className}
      name="work-metadata-epic-link"
      to={groupPath}
      testId={thisTestId}
    >
      <Container data-testid={thisTestId}>
        <WorkMetadataEpic metadata={metadata} testId={thisTestId} />
        <ActivitiesWrapper>
          <MetadataHeader>Activities</MetadataHeader>
          <WorkMetadataActivities
            activities={metadata.activities}
            testId={thisTestId}
          />
        </ActivitiesWrapper>
        <OwnersWrapper>
          <MetadataHeader>Owners</MetadataHeader>
          <WorkMetadataTeamList
            list={metadata.owners}
            maxNumAvatars={6}
            testId={thisTestId}
          />
        </OwnersWrapper>
        <MetadataWrapper>
          <MetadataHeader>Contributors</MetadataHeader>
          <WorkMetadataTeamList
            list={metadata.collaborators}
            maxNumAvatars={6}
            testId={thisTestId}
          />
        </MetadataWrapper>
      </Container>
    </Link>
  );
};

export default WorkMetadata;
