import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { isArray } from "lodash";

// styled components
const StyledImage = styled.img`
  ${props =>
    props.isResponsive &&
    `
    height: auto;
    width: 100%;
    max-height: inherit;
    max-width: inherit;
  `}
`;

const Image = ({
  altText,
  isResponsive = true,
  onError = () => {
    // do nothing
  },
  src,
  testId,
  useSrcset = false,
  ...otherProps
}) => {
  if (!altText || !src || !testId) {
    return null;
  }

  let srcsetString;
  if (useSrcset && isArray(src)) {
    srcsetString = src.map((s, index) => `${s} ${index + 1}x`).join(",");
  }

  return (
    <StyledImage
      alt={altText}
      isResponsive={isResponsive}
      onError={e => onError({ e, src })}
      src={isArray(src) ? src[0] : src}
      data-testid={`${testId}-image`}
      srcSet={srcsetString}
      useSrcset={useSrcset}
      {...otherProps}
    />
  );
};

Image.propTypes = {
  className: PropTypes.string,
  altText: PropTypes.string.isRequired,
  isResponsive: PropTypes.bool,
  onError: PropTypes.func,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  testId: PropTypes.string.isRequired
};
export default Image;
