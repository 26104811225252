import moment from "moment";
import { DefaultTheme } from "styled-components/macro";
import {
  flatten,
  toPairs,
  uniq,
  sum,
  sumBy,
  capitalize,
  partition,
  startCase,
  isNull
} from "lodash";

// constants
import {
  momentUnitTypes,
  healthFactorsMetadata,
  healthScoreRanges,
  healthScoreTypes,
  alwaysOnScoreRanges,
  contextSwitchingScoreRanges,
  contextSwitchingScoreTypes,
  contextSwitchingFactorsMetadata,
  Signals
} from "../../constants/constants";

import { alwaysOnRiskTypes } from "../../constants/charts";
import {
  getPeopleHealthBucketTooltip,
  getPeopleHealthContextSwitchingTooltip,
  getDeepWorkTooltipDisplay
} from "../../utils/chart";

// interfaces
import {
  DeepDiveData,
  PeopleMetrics,
  SelectablePeopleMetrics,
  ContextSwitchingRecord,
  BucketTypes
} from "../../interfaces/team-deep-dive";

// utils
import {
  getAdjustedTooltipPosition,
  getPlotBands,
  getBandTickPositions
} from "../../utils/chart";
import { createTimestamp, formatTimestamp } from "../../utils/date";
import { UserReportWithMetadataInterface } from "../../interfaces/user";
import {
  getNumberDataSeries,
  getPersonScore
} from "../../utils/health-metrics";

// typescript props
type Props = {
  data?: DeepDiveData;
  theme: DefaultTheme;
  selectedMetric: SelectablePeopleMetrics;
  chartView: UserReportWithMetadataInterface | "team";
  teamMembers: UserReportWithMetadataInterface[] | undefined;
  canSeeAlwaysOnData: boolean;
};
type PrReposType = {
  bucket: BucketTypes;
  details: {
    repos: Array<string>;
  };
};
type MeetingsType = {
  bucket: BucketTypes;
  details: Record<string, number>;
};

export const getDeepWorkTooltips = (
  data: DeepDiveData,
  pointData: { name: string; series: { name: string; type: string } },
  chartView: UserReportWithMetadataInterface | "team",
  teamMembers: UserReportWithMetadataInterface[] | undefined,
  showFullTeam: boolean
) => {
  const dateRecord = data.find(d => d.dateEnd === pointData.name);

  const teamDeepWorkBuckets = dateRecord?.peopleScores
    ?.map(record => {
      const userMetadataRecord = teamMembers?.find(t => t.id === record.user);
      return {
        name: userMetadataRecord?.name,
        id: record.user,
        slackAvatar: userMetadataRecord?.slackAvatar,
        bucket: capitalize(record.DEEP_WORK?.HOURS?.TOTAL) || "N/A",
        hours:
          !!record.DEEP_WORK?.HOURS?.DETAILS &&
          !!Object.values(record.DEEP_WORK?.HOURS?.DETAILS).length
            ? sum(Object.values(record.DEEP_WORK.HOURS.DETAILS)) /
              Object.values(record.DEEP_WORK.HOURS.DETAILS).length
            : 0
      };
    })
    .filter(
      i =>
        (showFullTeam || (chartView !== "team" && i.id === chartView.id)) &&
        !!i.name
    );

  const currentAverageDeepWorkTime =
    sumBy(teamDeepWorkBuckets, "hours") / (teamDeepWorkBuckets?.length || 1) ||
    0;
  const rollingAverageRecordSet = data.filter(d =>
    moment(d.dateEnd).isSameOrBefore(moment(dateRecord?.dateEnd))
  );

  const rollingAverageDeepWorkTime =
    !showFullTeam && chartView !== "team" && !!rollingAverageRecordSet
      ? sum(
          rollingAverageRecordSet.map(record => {
            const hoursDetails =
              record.peopleScores.find(i => i.user === chartView.id)?.DEEP_WORK
                ?.HOURS?.DETAILS || {};
            return !!hoursDetails
              ? sum(Object.values(hoursDetails)) /
                  Object.values(hoursDetails).length
              : 0;
          })
        ) / rollingAverageRecordSet.length
      : 0;

  return getDeepWorkTooltipDisplay(
    teamDeepWorkBuckets,
    currentAverageDeepWorkTime,
    rollingAverageDeepWorkTime
  );
};

export const getContextSwitchingTooltips = (
  data: DeepDiveData,
  chartView: UserReportWithMetadataInterface | "team",
  pointData: { name: string; series: { name: string; type: string } },
  selectedMetric?: string
) => {
  const dataRecord = data
    .find(d => d.dateEnd === pointData.name)
    ?.peopleScores.filter(
      scoreRecord => chartView === "team" || scoreRecord.user === chartView?.id
    );
  let itemsToShow;
  let title;
  switch (pointData.series.name) {
    case "Context Switching":
      itemsToShow = flatten(
        dataRecord?.map(scoreRecord => {
          const bucketMap = {
            "Jira Epics":
              startCase(
                scoreRecord?.CONTEXT_SWITCHING?.[
                  "JIRA_EPICS"
                ]?.bucket.toLowerCase()
              ) || "None",
            "Top Priority Bugs":
              startCase(
                scoreRecord?.CONTEXT_SWITCHING?.[
                  "TOP_PRIORITY_BUGS"
                ]?.bucket.toLowerCase()
              ) || "None",
            "Chat Channels with Interruptions":
              startCase(
                scoreRecord?.CONTEXT_SWITCHING?.[
                  "CHAT_CHANNELS_WITH_INTERRUPTIONS"
                ]?.bucket.toLowerCase()
              ) || "None",
            "PR Repos":
              startCase(
                scoreRecord?.CONTEXT_SWITCHING?.[
                  "PR_REPOS"
                ]?.bucket.toLowerCase()
              ) || "None",
            "Meetings with 2+ Teams":
              startCase(
                scoreRecord?.CONTEXT_SWITCHING?.[
                  "MEETINGS_WITH_2+_TEAMS"
                ]?.bucket.toLowerCase()
              ) || "None"
          };
          return selectedMetric === "OVERALL_TEAM_HEALTH"
            ? scoreRecord?.CONTEXT_SWITCHING?.TOTAL?.bucket.toLowerCase()
            : Object.entries(bucketMap).map(([key, value]) => {
                return `${key}: ${value}`;
              });
        })
      );
      title = "Context Switching";
      break;
    case "Jira Epics":
      itemsToShow = uniq(
        flatten(
          dataRecord?.map(scoreRecord => {
            const theRecord =
              scoreRecord["CONTEXT_SWITCHING"]?.["JIRA_EPICS"]?.details;
            return !!theRecord ? Object.values(theRecord) : [];
          })
        ).sort((a, b) => a[0].localeCompare(b[0]))
      );
      title = `Jira Epic - ${itemsToShow.length}`;
      break;
    case "Top Priority Bugs":
      itemsToShow = flatten(
        dataRecord?.map(scoreRecord => {
          const record =
            scoreRecord["CONTEXT_SWITCHING"]?.["TOP_PRIORITY_BUGS"]?.details;
          return !!record
            ? Object.values(record).map(d => d.name)
            : ["No Top Priority Bugs"];
        })
      ).sort((a, b) => a[0].localeCompare(b[0]));
      title = `Top Priority Bugs ${
        itemsToShow[0] === "No Top Priority Bugs"
          ? ""
          : `- ${itemsToShow.length}`
      }`;
      break;
    case "Chat Channels with Interruptions":
      const totalChannels = sum(
        dataRecord?.map(scoreRecord => {
          return (
            scoreRecord["CONTEXT_SWITCHING"]?.[
              "CHAT_CHANNELS_WITH_INTERRUPTIONS"
            ]?.details?.count || 0
          );
        })
      );
      itemsToShow = [
        totalChannels > 0
          ? `${totalChannels} channel${
              totalChannels === 1 ? "s" : ""
            } with more Interruptions`
          : "Few Channels impacted your team"
      ];
      title = "Chat Channels with Interruptions";
      break;
    case "PR Repos":
      itemsToShow = uniq(
        flatten(
          dataRecord
            ?.map(scoreRecord => {
              const repos = scoreRecord["CONTEXT_SWITCHING"]?.[
                "PR_REPOS"
              ] as PrReposType;
              return repos?.details?.repos;
            })
            .filter(s => !!s)
        )
      ).sort((a, b) => a[0].localeCompare(b[0]));
      title = `PR Repos - ${itemsToShow.length}`;
      break;
    case "Meetings with 2+ teams":
      const [namedMeetings, otherMeetings] = partition(
        flatten(
          dataRecord?.map(scoreRecord => {
            const meetings = scoreRecord["CONTEXT_SWITCHING"]?.[
              "MEETINGS_WITH_2+_TEAMS"
            ] as MeetingsType;
            return !!meetings?.details
              ? Object.entries(meetings.details).sort((a, b) => b[1] - a[1])
              : [];
          })
        ),
        i => i[1] > 0.1
      );
      const combinedMeetings: [string, number][] =
        [
          ...namedMeetings,
          [`Other Meeting Types`, otherMeetings.reduce((c, i) => i[1] + c, 0)]
        ] || [];
      itemsToShow = combinedMeetings.map(
        cat => `${(cat[1] * 100).toFixed(0)}% ${cat[0]}`
      );
      title = "Meetings with 2+ Teams";
      break;
  }
  return getPeopleHealthContextSwitchingTooltip(
    title,
    itemsToShow || [],
    (pointData as any).color
  );
};

export const getUserRecordsForTooltips = (
  pointData: { name: string; series: { name: string; type: string } },
  chartView: UserReportWithMetadataInterface | "team",
  data: DeepDiveData,
  teamMembers: UserReportWithMetadataInterface[] | undefined,
  selectedMetric: SelectablePeopleMetrics
) => {
  return data
    .find(d => d.dateEnd === pointData.name)
    ?.peopleScores.filter(scoreRecord => {
      const theRecord =
        (chartView === "team" ||
          pointData.series.type === "column" ||
          scoreRecord.user === chartView.id) &&
        teamMembers?.find(member => member.id === scoreRecord.user);

      return theRecord;
    })
    .map(person => {
      const personRecord = teamMembers?.find(
        member => member.id === person.user
      );

      // the data points only have display names at this point, so they need to be mapped back to the actual metric name for lookup if context switching
      const nameMetricMap = toPairs({
        "Top Priority Bugs": "TOP_PRIORITY_BUGS",
        "Jira Epics": "JIRA_EPICS",
        "Meetings with 2+ teams": "MEETINGS_WITH_2+_TEAMS",
        "Chat Channels with Interruptions": "CHAT_CHANNELS_WITH_INTERRUPTIONS",
        "PR Repos": "PR_REPOS",
        "Deep Work": "DEEP_WORK",
        "Always On": "ALWAYS_ON",
        "Context Switching": "CONTEXT_SWITCHING",
        "Context Switching (Team)": "CONTEXT_SWITCHING",
        "Chat Interruptions": "CHAT_INTERRUPTIONS",
        "Overall Team Health": "OVERALL_TEAM_HEALTH"
      }).find(keyPairs => keyPairs[0] === pointData.series.name);

      const hasSubMetric =
        selectedMetric === Signals.CONTEXT_SWITCHING ||
        selectedMetric === "OVERALL_TEAM_HEALTH";

      return {
        userId: person.user,
        slackAvatar: personRecord?.slackAvatar,
        name: personRecord?.name,
        // this is the canonical metric being viewed, ie the tab that is selected displaying the chart
        selectedMetric,
        // this is the value of the actual series that the hover is on, eg: "Top Priority Bugs" on Context Switching chart
        selectedSubMetric: hasSubMetric ? nameMetricMap?.[0] : "",
        // value when on Context Switching is just the subMetric, but on PeopleHealth, we need to know whether to display either a standard other metric OR the "Toral" submetric value from Context Switching
        // it is indeed as confusing as it sounds
        // nb getPersonScore is called slightly different in each case, but thankfully  is polymorphic enough to handle all cases
        value:
          selectedMetric === "OVERALL_TEAM_HEALTH"
            ? nameMetricMap?.[1] === "CONTEXT_SWITCHING"
              ? getPersonScore(
                  person,
                  Signals.CONTEXT_SWITCHING as PeopleMetrics,
                  "TOTAL"
                )
              : nameMetricMap?.[1] === "CHAT_INTERRUPTIONS"
              ? person?.CHAT_INTERRUPTIONS?.TOTAL
              : getPersonScore(
                  person,
                  nameMetricMap?.[1] as PeopleMetrics,
                  "TOTAL"
                )
            : selectedMetric === Signals.CONTEXT_SWITCHING
            ? nameMetricMap?.[1] === Signals.CONTEXT_SWITCHING
              ? getPersonScore(
                  person,
                  Signals.CONTEXT_SWITCHING as PeopleMetrics,
                  "TOTAL"
                )
              : getPersonScore(
                  person,
                  Signals.CONTEXT_SWITCHING as PeopleMetrics,
                  nameMetricMap?.[1] as keyof ContextSwitchingRecord
                )
            : getPersonScore(person, selectedMetric as PeopleMetrics)
      };
    });
};

const getYAxisMax = (
  selectedMetric: SelectablePeopleMetrics,
  series: {
    data: (string | number | null)[][];
  }[],
  data: DeepDiveData
) => {
  return selectedMetric === "OVERALL_TEAM_HEALTH"
    ? 101
    : selectedMetric === "DEEP_WORK"
    ? 9 // 9 hours is the max for deep work
    : (selectedMetric === "CHAT_INTERRUPTIONS" // keep scale consistent for interruptions across all users
        ? Math.max(
            ...flatten(
              data.map(r =>
                flatten(
                  r.peopleScores.map(
                    d => getPersonScore(d, "CHAT_INTERRUPTIONS") || 0
                  )
                )
              )
            )
          )
        : Math.max(
            ...series.map(s =>
              Math.max(
                ...s.data.map(d => (typeof d[1] === "number" ? d[1] : 1))
              )
            )
          )) * 1.2;
};

const config = ({
  data,
  theme,
  selectedMetric,
  chartView,
  teamMembers,
  canSeeAlwaysOnData
}: Props) => {
  if (!data?.length) {
    return {};
  }

  const alwaysOn = {
    name: healthFactorsMetadata["ALWAYS_ON"].title,
    type: "line",
    color: theme.colors.chart.healthFactors.ALWAYS_ON,
    marker: {
      fillColor: theme.colors.chart.healthFactors.ALWAYS_ON,
      lineColor: theme.colors.chart.healthFactors.ALWAYS_ON
    },
    data:
      selectedMetric === "OVERALL_TEAM_HEALTH" || selectedMetric === "ALWAYS_ON"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              chartView,
              "ALWAYS_ON"
            );
          })
        : [],
    opacity: theme.charts.series.opacity.full,
    lineWidth: "2px"
  };
  const deepWork = {
    name: healthFactorsMetadata["DEEP_WORK"].title,
    type: "line",
    color: theme.colors.chart.healthFactors.DEEP_WORK,
    marker: {
      fillColor: theme.colors.chart.healthFactors.DEEP_WORK,
      lineColor: theme.colors.chart.healthFactors.DEEP_WORK
    },
    data:
      selectedMetric === "OVERALL_TEAM_HEALTH" ||
      selectedMetric === Signals.DEEP_WORK
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              chartView,
              "DEEP_WORK"
            );
          })
        : [],
    opacity: theme.charts.series.opacity.full,
    visible: true,
    lineWidth: "2px"
  };
  const deepWorkTeam = {
    ...deepWork,
    name: `${deepWork.name} (Team)`,
    maxPointWidth: 20,
    color: theme.colors.all.storm,
    type: "column",
    data:
      selectedMetric === Signals.DEEP_WORK && chartView !== "team"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              "team",
              "DEEP_WORK"
            );
          })
        : []
  };
  const chatInterruptions = {
    name: healthFactorsMetadata["CHAT_INTERRUPTIONS"].title,
    type: "line",
    color: theme.colors.chart.healthFactors.CHAT_INTERRUPTIONS,
    marker: {
      fillColor: theme.colors.chart.healthFactors.CHAT_INTERRUPTIONS,
      lineColor: theme.colors.chart.healthFactors.CHAT_INTERRUPTIONS
    },
    data:
      selectedMetric === "OVERALL_TEAM_HEALTH" ||
      selectedMetric === "CHAT_INTERRUPTIONS"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              chartView,
              "CHAT_INTERRUPTIONS"
            );
          })
        : [],
    opacity: theme.charts.series.opacity.full,
    visible: true,
    lineWidth: "2px"
  };
  const chatInterruptionsTeam = {
    ...chatInterruptions,
    name: `${chatInterruptions.name} (Team)`,
    maxPointWidth: 20,
    color: theme.colors.all.storm,
    type: "column",
    data:
      selectedMetric === "CHAT_INTERRUPTIONS" && chartView !== "team"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              "team",
              "CHAT_INTERRUPTIONS"
            );
          })
        : []
  };

  // context switching factors
  const contextSwitchingTotal = {
    name: healthFactorsMetadata["CONTEXT_SWITCHING"].title,
    color: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_TOTAL,
    marker: {
      fillColor: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_TOTAL,
      lineColor: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_TOTAL
    },
    data:
      selectedMetric === "CONTEXT_SWITCHING"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              chartView,
              "CONTEXT_SWITCHING",
              "TOTAL"
            );
          })
        : selectedMetric === "OVERALL_TEAM_HEALTH"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              "team",
              "CONTEXT_SWITCHING"
            );
          })
        : [],
    opacity: theme.charts.series.opacity.full,
    type: "line",
    visible: true,
    lineWidth: "4px"
  };

  // series existing specifically to show the team average when on ic view of Context Switching, is the average of previous metric series
  const contextSwitchingTeam = {
    ...contextSwitchingTotal,
    name: `${healthFactorsMetadata["CONTEXT_SWITCHING"].title} (Team)`,
    maxPointWidth: 20,
    color: theme.colors.all.storm,
    type: "column",
    data:
      selectedMetric === "CONTEXT_SWITCHING" && chartView !== "team"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              "team",
              "CONTEXT_SWITCHING",
              "TOTAL"
            );
          })
        : []
  };
  const contextSwitchingJiraP1BugTickets = {
    name:
      contextSwitchingFactorsMetadata["CONTEXT_SWITCHING_TOP_PRIORITY_BUGS"]
        .title,
    type: "line",
    color: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_TOP_PRIORITY_BUGS,
    marker: {
      fillColor:
        theme.colors.chart.healthFactors.CONTEXT_SWITCHING_TOP_PRIORITY_BUGS,
      lineColor:
        theme.colors.chart.healthFactors.CONTEXT_SWITCHING_TOP_PRIORITY_BUGS
    },
    data:
      selectedMetric === "CONTEXT_SWITCHING"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              chartView,
              "CONTEXT_SWITCHING",
              "TOP_PRIORITY_BUGS"
            );
          })
        : [],
    opacity: theme.charts.series.opacity.full,
    visible: true
  };
  const contextSwitchingMeetings = {
    name: contextSwitchingFactorsMetadata["CONTEXT_SWITCHING_MEETINGS"].title,
    color: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_MEETINGS,
    type: "line",
    marker: {
      fillColor: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_MEETINGS,
      lineColor: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_MEETINGS
    },
    data:
      selectedMetric === "CONTEXT_SWITCHING"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              chartView,
              "CONTEXT_SWITCHING",
              "MEETINGS_WITH_2+_TEAMS"
            );
          })
        : [],
    opacity: theme.charts.series.opacity.full,
    visible: true
  };
  const contextSwitchingSlackChannels = {
    name:
      contextSwitchingFactorsMetadata["CONTEXT_SWITCHING_CHAT_CHANNELS"].title,
    type: "line",
    color: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_CHAT_CHANNELS,
    marker: {
      fillColor:
        theme.colors.chart.healthFactors.CONTEXT_SWITCHING_CHAT_CHANNELS,
      lineColor:
        theme.colors.chart.healthFactors.CONTEXT_SWITCHING_CHAT_CHANNELS
    },
    data:
      selectedMetric === "CONTEXT_SWITCHING"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              chartView,
              "CONTEXT_SWITCHING",
              "CHAT_CHANNELS_WITH_INTERRUPTIONS"
            );
          })
        : [],
    opacity: theme.charts.series.opacity.full,
    visible: true
  };
  const contextSwitchingPRRepos = {
    name: contextSwitchingFactorsMetadata["CONTEXT_SWITCHING_PR_REPOS"].title,
    color: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_PR_REPOS,
    type: "line",
    marker: {
      fillColor: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_PR_REPOS,
      lineColor: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_PR_REPOS
    },
    data:
      selectedMetric === "CONTEXT_SWITCHING"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              chartView,
              "CONTEXT_SWITCHING",
              "PR_REPOS"
            );
          })
        : [],
    opacity: theme.charts.series.opacity.full,
    visible: true
  };
  const contextSwitchingJiraEpics = {
    name: contextSwitchingFactorsMetadata["CONTEXT_SWITCHING_JIRA_EPICS"].title,
    color: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_JIRA_EPICS,
    type: "line",
    marker: {
      fillColor: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_JIRA_EPICS,
      lineColor: theme.colors.chart.healthFactors.CONTEXT_SWITCHING_JIRA_EPICS
    },
    data:
      selectedMetric === "CONTEXT_SWITCHING"
        ? data.map(record => {
            return getNumberDataSeries(
              record,
              selectedMetric,
              chartView,
              "CONTEXT_SWITCHING",
              "JIRA_EPICS"
            );
          })
        : [],
    opacity: theme.charts.series.opacity.full,
    visible: true
  };

  let series;
  switch (selectedMetric) {
    case "CONTEXT_SWITCHING":
      series = [
        contextSwitchingTotal,
        contextSwitchingJiraP1BugTickets,
        contextSwitchingMeetings,
        contextSwitchingSlackChannels,
        contextSwitchingPRRepos,
        contextSwitchingJiraEpics
      ];
      if (chartView !== "team") {
        series = [contextSwitchingTeam, ...series];
      }
      // filter out nulls that bubbled up into the series -- for customizations,
      // this should result in an empty array for the series
      series = series.filter(s => flatten(s.data).every(d => !isNull(d)));
      break;
    case "DEEP_WORK":
      series = [deepWork];
      if (chartView !== "team") {
        series = [deepWorkTeam, ...series];
      }
      break;
    case "ALWAYS_ON":
      series = [alwaysOn];
      if (chartView !== "team") {
        series = [...series];
      }
      break;
    case "CHAT_INTERRUPTIONS":
      series = [chatInterruptions];
      if (chartView !== "team") {
        series = [chatInterruptionsTeam, ...series];
      }
      break;
    case "OVERALL_TEAM_HEALTH":
      const baseSeries = [deepWork, chatInterruptions, contextSwitchingTotal];
      series = canSeeAlwaysOnData ? [...baseSeries, alwaysOn] : baseSeries;
      break;
  }

  return {
    chart: {
      height: 350,
      width: 1126,
      marginRight: 0,
      style: {
        fontFamily: theme.fonts.primary.name,
        fontWeight: theme.fonts.primary.weights.regular
      }
    },
    xAxis: {
      min: -0.4,
      categories: data.map(d => {
        const isSameMonth = moment(d.dateStart).isSame(
          d.dateEnd,
          momentUnitTypes.MONTHS
        );
        const start = formatTimestamp({
          format: "M/DD",
          timestamp: createTimestamp(d.dateStart)
        });
        const end = formatTimestamp({
          format: isSameMonth ? "DD" : "M/DD",
          timestamp: createTimestamp(d.dateEnd || new Date())
        });
        return `${start}-${end}`;
      }),
      lineColor: theme.colors.all.wolverine,
      labels: {
        enabled: true,
        style: {
          color: theme.colors.all.wolverine,
          fontFamily: theme.fonts.subheader.name,
          fontWeight: theme.fonts.subheader.weights.bold
        },
        y: 20
      }
    },
    yAxis: [
      {
        min: 0,
        max: getYAxisMax(selectedMetric, series, data),
        endOnTick: true,
        labels: {
          enabled:
            selectedMetric === "DEEP_WORK" ||
            selectedMetric === "CHAT_INTERRUPTIONS",
          formatter: function() {
            return `${(this as any).value}${
              selectedMetric === "DEEP_WORK"
                ? "hr"
                : selectedMetric === "CHAT_INTERRUPTIONS"
                ? "min"
                : ""
            }`;
          }
        },
        plotBands:
          selectedMetric === "OVERALL_TEAM_HEALTH"
            ? getPlotBands(healthScoreRanges, healthScoreTypes, theme)
            : selectedMetric === "ALWAYS_ON"
            ? getPlotBands(
                alwaysOnScoreRanges,
                alwaysOnRiskTypes,
                theme,
                "ALWAYS_ON"
              )
            : selectedMetric === "CONTEXT_SWITCHING"
            ? getPlotBands(
                contextSwitchingScoreRanges,
                contextSwitchingScoreTypes,
                theme,
                "CONTEXT_SWITCHING"
              )
            : [{}],
        tickPositions:
          selectedMetric === "OVERALL_TEAM_HEALTH"
            ? getBandTickPositions(healthScoreRanges)
            : selectedMetric === "ALWAYS_ON"
            ? getBandTickPositions(alwaysOnScoreRanges, "ALWAYS_ON")
            : selectedMetric === "CONTEXT_SWITCHING"
            ? getBandTickPositions(
                contextSwitchingScoreRanges,
                "CONTEXT_SWITCHING"
              )
            : undefined,
        title: {
          enabled: false
        }
      }
    ],
    legend: {
      align: "right",
      enabled: true,
      itemDistance: 30,
      itemStyle: {
        fontWeight: theme.fonts.primary.weights.book
      },
      layout: "horizontal",
      verticalAlign: "bottom",
      width: selectedMetric === "CONTEXT_SWITCHING" ? 500 : 0,
      symbolHeight: 8,
      symbolWidth: 8,
      symbolPadding: 8,
      // a bit odd, but radius here implicitly applies to just column type series and not line series
      symbolRadius: 0
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: function() {
            const seriesIndex = (this as any).index;
            const { series } = (this as any).chart;

            type Series = {
              visible: boolean;
              index: number;
              setVisible: (arg0: boolean, arg1: boolean) => void;
            };
            // if every series is visible, then for each series, if it is not the one being clicked, hide it
            if (series.every((s: Series) => s.visible)) {
              series.forEach((s: Series) => {
                if (s.index !== seriesIndex) {
                  s.setVisible(false, true);
                }
              });
              return false;
            } else {
              // if the series clicked is visible, but the other series are not, then show all series
              if (
                series.find((s: Series) => s.index === seriesIndex).visible &&
                series
                  .filter((s: Series) => s.index !== seriesIndex)
                  .every((s: Series) => !s.visible)
              ) {
                series.forEach((s: Series) => {
                  if (s.index !== seriesIndex) {
                    s.setVisible(true, true);
                  }
                });
                return false;
              } else {
                // in all other cases, set the visibility of the series clicked to the opposite of what it is currently
                (this as any).setVisible(!(this as any).visible, true);
                return false;
              }
            }
          }
        }
      },
      line: {
        connectNulls: true,
        dataLabels: {
          align: "left",
          enabled: false,
          formatter: function() {
            return (this as any).point.index === data.length - 1
              ? (this as any).y.toFixed(0)
              : null;
          },
          style: {
            fontFamily: theme.fonts.subheader.name,
            fontSize: "10px",
            opacity: theme.charts.series.opacity.full
          },
          verticalAlign: "middle",
          x: 10
        },
        marker: {
          symbol: "circle"
        },
        states: {
          hover: {
            opacity: theme.charts.series.opacity.full,
            lineWidth: "4px",
            lineWidthPlus: 0
          },
          inactive: {
            animation: {
              duration: 0
            }
          }
        }
      }
    },
    title: {
      text: undefined
    },
    tooltip: {
      useHTML: true,
      enabled: true,
      backgroundColor: null,
      shadow: false,
      borderWidth: 0,
      borderRadius: 0,
      outside: true,
      positioner: function(w: number, h: number, point: any) {
        return getAdjustedTooltipPosition({
          currentPosition: (this as any).getPosition(w, h, point),
          windowScroll: window.scrollY
        });
      },
      formatter: function() {
        const pointData = (this as any).point;

        if (
          selectedMetric === Signals.CONTEXT_SWITCHING &&
          pointData.series.type !== "column" &&
          chartView !== "team"
        ) {
          return getContextSwitchingTooltips(
            data,
            chartView,
            pointData,
            selectedMetric
          );
        }

        if (
          selectedMetric === Signals.DEEP_WORK ||
          (selectedMetric === "OVERALL_TEAM_HEALTH" &&
            pointData.series.name === "Deep Work")
        ) {
          return getDeepWorkTooltips(
            data,
            pointData,
            chartView,
            teamMembers,
            chartView === "team" || pointData.series.type === "column"
          );
        }

        const userRecords = getUserRecordsForTooltips(
          pointData,
          chartView,
          data,
          teamMembers,
          selectedMetric
        );

        return getPeopleHealthBucketTooltip(userRecords, pointData.color);
      }
    },
    series
  };
};

export default config;
