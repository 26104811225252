import React from "react";
import styled from "styled-components/macro";

// components
import OrganizationNavigationMenuCard from "../OrganizationNavigationMenuCard/OrganizationNavigationMenuCard";

// interfaces
import { UserReportWithMetadataInterface } from "../../interfaces/user";
import { TeamInterface } from "../../interfaces/team";

// styled components
const ListItem = styled.li`
  & + & {
    margin-top: 0.5rem;
  }
`;

// typescript props
type Props = {
  activePerson?: UserReportWithMetadataInterface;
  className?: string;
  onClickPerson: ({
    e,
    person
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    person: UserReportWithMetadataInterface;
    activePerson?: UserReportWithMetadataInterface;
  }) => void;
  onClickReportsNav: ({
    e,
    person
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    person: UserReportWithMetadataInterface;
  }) => void;
  team?: TeamInterface;
  testId?: string;
};

const OrganizationNavigationMenuCardsList = ({
  activePerson,
  className,
  onClickPerson,
  onClickReportsNav,
  team,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-organization-navigation-menu-cards-list`;
  const teamMembers = team?.teamMembers?.filter(
    t => t.id !== team?.orgChartTeamLeadId
  );
  return teamMembers?.length ? (
    <ul className={className} data-testid={thisTestId}>
      {teamMembers.map(o => (
        <ListItem key={o.id}>
          <OrganizationNavigationMenuCard
            person={o}
            isActive={o.id === activePerson?.id}
            onClickPerson={(
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) =>
              onClickPerson({
                e,
                person: o,
                activePerson
              })
            }
            onClickReportsNav={(
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => onClickReportsNav({ e, person: o })}
            testId={thisTestId}
          />
        </ListItem>
      ))}
    </ul>
  ) : null;
};

export default OrganizationNavigationMenuCardsList;
