import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { isEmpty, kebabCase, sortBy, truncate, isNull, isNil } from "lodash";

// components
import EmptyState from "../EmptyState/EmptyState";
import HtmlText from "../HtmlText/HtmlText";
import ScrollContainerWithReset from "../ScrollContainerWithReset/ScrollContainerWithReset";
import Table from "../Table/Table";
import Time from "../Time/Time";
import Tooltip from "../Tooltip/Tooltip";

// constants
import { STRING_TRUNCATION_LENGTH } from "../../constants/components";

// styled components
const Container = styled.div`
  font-family: ${props => props.theme.fonts.primary.name}, monospace;
`;
const StyledTable = styled(Table)`
  width: 100%;

  & th.title {
    width: 25%;
  }
`;
const StyledHeader = styled.header`
  align-items: center;
  border-bottom: ${props =>
    `${props.theme.borders.widths.md} solid ${props.theme.colors.border}`};
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
`;
const PopupContent = styled.div`
  max-width: 20rem;
`;

const PrCadenceList = ({
  className,
  list = [],
  selectedDateRange,
  testId = "testId",
  ...otherProps
}) => {
  const thisTestId = `${testId}-pr-cadence-list`;

  let columns = [
    {
      accessor: "mergedDtm",
      Header: "Merge date",
      Cell: ({ cell }) => (
        <Time
          format="M/D"
          timestamp={cell.row.original.mergedDtm}
          testId={thisTestId}
        />
      ),
      filter: (rows, id, filterType) =>
        rows.filter(row => row.values[id] === filterType),
      className: "mergeDate"
    },
    {
      accessor: "prTitle",
      Header: "Title",
      Cell: ({ cell }) => {
        let displayTitle = truncate(cell.value, {
          length: STRING_TRUNCATION_LENGTH
        });
        const titleIsTruncated = cell.value.length > STRING_TRUNCATION_LENGTH;

        displayTitle = (
          <span data-heap-redact-text="true">
            {cell.row.original.prUrl ? (
              <a
                data-testid={`${thisTestId}-${kebabCase(cell.value)}`}
                href={cell.row.original.prUrl}
                rel="noopener noreferrer"
                target="_blank"
                data-heap-redact-attributes="href"
              >
                {displayTitle}
              </a>
            ) : (
              <span>{displayTitle}</span>
            )}
          </span>
        );

        return titleIsTruncated ? (
          <Tooltip
            placement="top"
            popupContent={<PopupContent>{cell.value}</PopupContent>}
            testId={testId}
            trigger={displayTitle}
          />
        ) : (
          displayTitle
        );
      },
      className: "title"
    },
    {
      accessor: "branch",
      Header: "Branch",
      Cell: ({ cell }) => (
        <span data-heap-redact-text="true">
          <HtmlText
            htmlText={`${cell.row.original.toBranch} &#8592; ${cell.row.original.fromBranch}`}
            testId={thisTestId}
          />
        </span>
      ),
      className: "branch"
    },
    {
      accessor: "authorName",
      Header: "Author",
      Cell: ({ cell }) => (
        <span data-heap-redact-text="true">{cell.value}</span>
      ),
      className: "authorName"
    },
    {
      accessor: "reviewers",
      Header: "Reviewers",
      Cell: ({ cell }) => (
        <span data-heap-redact-text="true">
          {isNull(cell.row.original.reviewerNames)
            ? ""
            : cell.row.original.reviewerNames.join(", ")}
        </span>
      ),
      className: "reviewers"
    },
    {
      accessor: "reviewTimeHours",
      Header: "Review Time",
      className: "reviewTime",
      Cell: ({ cell }) =>
        isNil(cell.value) ? null : (
          <>
            {cell.value.toFixed(1)} {cell.value === 1 ? "hr" : "hrs"}
          </>
        )
    },
    {
      accessor: "repository",
      Header: "Repo",
      Cell: ({ cell }) => {
        return (
          <span data-heap-redact-text="true">
            {cell.row.original.repositoryUrl ? (
              <a
                href={cell.row.original.repositoryUrl}
                target="_blank"
                rel="noopener noreferrer"
                data-heap-redact-attributes="href"
              >
                {cell.value}
              </a>
            ) : (
              <span>{cell.value}</span>
            )}
          </span>
        );
      },
      className: "repository"
    }
  ];

  // constructing a string version for the accessor for proper sorting
  const data = sortBy(
    list.map(l => ({
      ...l,
      reviewers: isNull(l.reviewerNames) ? "" : l.reviewerNames.join(", ")
    })),
    ["mergedDtm"]
  );

  return (
    <Container className={className} data-testid={thisTestId}>
      {isEmpty(list) ? (
        <EmptyState
          body={
            <span>
              It looks like we don&rsquo;t have data for this team.{" "}
              <a href="mailto:support@uplevelteam.com">Contact us</a> if this is
              a mistake and we&rsquo;ll get this fixed.
            </span>
          }
          header="Uh oh."
          testId={thisTestId}
        />
      ) : (
        <>
          <StyledHeader>
            <div>PRs</div>
          </StyledHeader>
          <ScrollContainerWithReset resetTrigger={data.length}>
            <StyledTable
              caption="PR Cadence"
              columns={columns}
              data={data}
              testId={testId}
            />
          </ScrollContainerWithReset>
        </>
      )}
    </Container>
  );
};

PrCadenceList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
  selectedDateRange: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }),
  testId: PropTypes.string.isRequired
};

export default PrCadenceList;
