import React, { ReactElement } from "react";
import styled, { css } from "styled-components/macro";
import { isNull } from "lodash";

// components
import CycleTimeValueDisplay from "../CycleTimeValueDisplay/CycleTimeValueDisplay";
import InfoIcon from "../InfoIcon/InfoIcon";

// constants
import { cycleTimeStages } from "../../constants/constants";

// interfaces
import {
  CycleTimeInterface,
  CycleTimeStageInterface
} from "../../interfaces/work-items";

// utils
import { rollupCycleTime } from "../../utils/cycle-time";
import { CycleTimeStageTypes } from "../../interfaces/constants";

// styled components
const Container = styled.div<{ display: string }>`
  font-size: ${props =>
    props.display === "rollup"
      ? props.theme.fonts.primary.sizes.sm
      : props.theme.fonts.primary.sizes.xs};
`;
const Title = styled.h3<{ display: string }>`
  font-size: inherit;

  ${props => {
    if (props.display === "rollup") {
      return css`
        font-weight: ${props.theme.fonts.primary.weights.bold};
      `;
    } else {
      return css`
        font-weight: ${props.theme.fonts.primary.weights.regular};

        &::after {
          content: ":";
          margin-right: 0.3em;
        }
      `;
    }
  }}
`;
const Header = styled.header<{ display: "rollup" | "inline" }>`
  ${props => {
    if (props.display === "rollup") {
      return css`
        font-size: inherit;
      `;
    } else {
      return css`
        align-items: baseline;
        display: flex;
        font-size: inherit;
        font-weight: ${props.theme.fonts.primary.weights.regular};
      `;
    }
  }}
`;
const HeaderGroup = styled.hgroup`
  align-items: baseline;
  display: flex;
  margin-bottom: 1.6rem;
`;
const RollupValue = styled(CycleTimeValueDisplay)`
  font-size: ${props => props.theme.fonts.primary.sizes.xl};
`;
const Values = styled.div`
  align-items: center;
  display: flex;
  line-height: 1;
  margin-bottom: 1em;
`;
const BreakdownByStage = styled.ul`
  display: flex;
`;
const BreakoutValue = styled.li`
  & + & {
    margin-left: 2rem;
  }
`;

// typescript props
type Props = {
  className?: string;
  cycleTime: CycleTimeInterface | null;
  display?: "rollup" | "inline";
  testId?: string;
};

const WorkMetadataCycleTimeSummary = ({
  className,
  cycleTime,
  display = "inline",
  testId = "testId"
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-work-metadata-cycle-time-summary`;
  if (isNull(cycleTime)) {
    return null;
  }

  const rollupTotals = rollupCycleTime(cycleTime);

  return (
    <Container display={display} className={className} data-testid={thisTestId}>
      <Header display={display}>
        <HeaderGroup>
          <Title display={display}>PR Median Cycle Time</Title>
          {display === "rollup" ? (
            <InfoIcon content="cycle-time" testId={thisTestId} />
          ) : null}
        </HeaderGroup>
        <Values>
          {display === "rollup" ? (
            rollupTotals.find(
              (total: CycleTimeStageInterface) => total.value !== 0
            ) ? (
              <RollupValue
                display={display}
                stage={rollupTotals}
                testId={thisTestId}
              />
            ) : (
              <RollupValue
                display={display}
                stage={[{ unit: "HOURS", value: 0 }]}
                testId={thisTestId}
              />
            )
          ) : (
            <CycleTimeValueDisplay
              display={display}
              stage={rollupTotals}
              testId={thisTestId}
            />
          )}
        </Values>
      </Header>
      <div>
        <BreakdownByStage>
          {([
            cycleTimeStages.DEV,
            cycleTimeStages.WAITING_FOR_REVIEW,
            cycleTimeStages.REVIEW,
            cycleTimeStages.RELEASE
          ] as Array<keyof CycleTimeStageTypes>).map(stageKey => {
            const stage = cycleTime[stageKey];
            return !!stage ? (
              <BreakoutValue key={stageKey}>
                <CycleTimeValueDisplay
                  display="breakout"
                  stage={[stage]}
                  stageKey={stageKey}
                  testId={thisTestId}
                />
              </BreakoutValue>
            ) : null;
          })}
        </BreakdownByStage>
      </div>
    </Container>
  );
};

export default WorkMetadataCycleTimeSummary;
