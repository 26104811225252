import React, { useMemo } from "react";

// components
import Chart from "../Chart/Chart";

// config
import generateConfig from "./config";

// constants
import { timerangeIds } from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import {
  Benchmark,
  OrgByTimeItem,
  OrgByTimeItems
} from "../../interfaces/organization";
import { OrgByTimePoint } from "../../interfaces/charts";

// utils
import { createTimestamp } from "../../utils/date";

// typescript props
type Props = {
  benchmark?: Benchmark;
  className?: string;
  current?: OrgByTimeItem;
  data: OrgByTimeItems;
  isFetchingData?: boolean;
  testId?: string;
  title: string;
  tooltip: React.ReactElement;
  isDaysScale?: boolean;
  measure: "deep-work" | "cycle-time" | "release-frequency";
};

const ChartOverviewTrendline = ({
  className,
  current,
  data,
  benchmark,
  isFetchingData = false,
  testId = "testId",
  title,
  tooltip,
  isDaysScale,
  measure
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-chart-overview-trendline`;
  const { updateUrlParams } = useUrlParams();
  const selectedStartDate = current?.startDate;

  const chart = useMemo(() => {
    const onSelectDate = (
      pointData: Pick<OrgByTimePoint, "endDate" | "startDate">
    ) => {
      updateUrlParams({
        endDate: createTimestamp(pointData.endDate),
        startDate: createTimestamp(pointData.startDate),
        timerangeId: timerangeIds.DAYS
      });
    };
    return {
      config: generateConfig({
        benchmark,
        data,
        onSelectDate,
        selectedStartDate,
        isDaysScale,
        title,
        measure
      }),
      id: "overview-trendline-chart",
      isFetchingData
    };
  }, [
    benchmark,
    data,
    isFetchingData,
    isDaysScale,
    measure,
    selectedStartDate,
    title,
    updateUrlParams
  ]);

  return (
    <div className={className} data-testid={thisTestId}>
      <Chart
        chart={chart}
        className={className}
        testId={thisTestId}
        tooltipComponent={tooltip}
      />
      <p className="highcharts-definition visuallyHidden">{title}</p>
    </div>
  );
};

export default ChartOverviewTrendline;
