import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { isNaN, isNil } from "lodash";

// components
import ValueWithDirectionality from "../ValueWithDirectionality/ValueWithDirectionality";

// constants
import { directionalityTypes } from "../../constants/constants";

// styled components
const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  font-family: ${props => props.theme.fonts.primary.name};
`;
const StyledHeader = styled.header`
  margin-right: 0.5rem;
`;
const StyledValueContainer = styled.div`
  padding: 0.5rem 0;
`;
const StyledValue = styled.span`
  font-weight: bold;
`;
const StyledValueWithDirectionality = styled(ValueWithDirectionality)`
  display: inline-block;
`;
const StyledDeltaContainer = styled.div`
  display: inline-block;
  margin-left: 1rem;
`;

const StoryPointsAverage = ({
  className,
  testId,
  date,
  delta,
  value,
  ...otherProps
}) => {
  if (isNaN(value) || isNil(value) || !date || !testId) {
    return null;
  }

  const formattedValue = value.toFixed(1);

  let displayDelta;
  if (!isNil(delta) && !isNaN(delta)) {
    const formattedDelta = delta.toFixed(1);
    if (delta === 0) {
      displayDelta = formattedDelta;
    } else {
      displayDelta = (
        <StyledValueWithDirectionality
          direction={
            delta > 0
              ? directionalityTypes.POSITIVE
              : directionalityTypes.NEGATIVE
          }
          value={formattedDelta}
          unit="%"
          testId={testId}
        />
      );
    }
    displayDelta = (
      <StyledDeltaContainer>({displayDelta})</StyledDeltaContainer>
    );
  }

  return (
    <StyledContainer
      className={className}
      data-testid={`${testId}-story-points-average`}
    >
      <StyledHeader>Tickets with story points on {date}:</StyledHeader>
      <StyledValueContainer>
        <StyledValue>{formattedValue}%</StyledValue>
        {displayDelta}
      </StyledValueContainer>
    </StyledContainer>
  );
};

StoryPointsAverage.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  testId: PropTypes.string.isRequired
};

export default StoryPointsAverage;
