import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { PopupPlacements } from "../../interfaces/controls";

// components
import Icon from "../Icon/Icon";
import InfoPopupContent from "../InfoPopupContent/InfoPopupContent";
import TriggeredOverlay from "../TriggeredOverlay/TriggeredOverlay";

// hooks
import { useTracking } from "../../hooks/useTracking";
import { isUndefined } from "lodash";

// styled components
const TriggerIcon = styled(Icon)<{ icon: string }>`
  color: ${props =>
    props.icon === "info"
      ? props.theme.colors.all.storm
      : props.theme.colors.all.wolverine};
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
  }
  font-size: 1.2rem;
`;
const PopupContent = styled(InfoPopupContent)<{
  popupPlacement: PopupPlacements;
}>`
  max-width: 35rem;

  ${props => {
    switch (props.popupPlacement) {
      case "top":
        return css`
          margin-bottom: 1rem;
        `;
      case "bottom":
        return css`
          margin-top: 1rem;
        `;
      case "left":
        return css`
          margin-right: 1rem;
        `;

      default:
        return css`
          margin-left: 1rem;
        `;
    }
  }}
`;

// typescript props
type Actions = "hover" | "click";
type Icons = "book" | "info" | "question-circle";
type Props = {
  action?: Actions;
  className?: string;
  content: React.ReactNode;
  header?: React.ReactNode;
  icon?: Icons;
  name: string;
  popupPlacement?: PopupPlacements;
  testId?: string;
};

const InfoPopup = ({
  action = "hover",
  className,
  content,
  header,
  icon,
  name,
  popupPlacement = "right",
  testId = "testId"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-info-popup`;
  const [showPopup, setShowPopup] = useState(false);

  const { trackEvent } = useTracking();
  const isHoverAction = action === "hover";

  function onCancelPopup(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setShowPopup(false);
    trackEvent({
      e,
      label: `${name}-info-popup`,
      value: "canceled via close button"
    });
  }

  function onTogglePopup(nextShow: boolean) {
    setShowPopup(nextShow);

    trackEvent({
      label: `${name}-info-popup`,
      value: nextShow ? "shown" : "hidden"
    });
  }

  return (
    <TriggeredOverlay
      onToggleShow={onTogglePopup}
      overlayContent={
        <PopupContent
          content={content}
          context={action}
          header={header}
          onCancelPopup={onCancelPopup}
          popupPlacement={popupPlacement}
          testId={thisTestId}
        />
      }
      overlayPlacement={popupPlacement}
      show={showPopup}
      testId={thisTestId}
      triggerAction={isHoverAction ? ["hover", "focus"] : action}
      triggerContent={
        <TriggerIcon
          className={className}
          data-testid={thisTestId}
          icon={
            isUndefined(icon)
              ? isHoverAction
                ? "info"
                : "question-circle"
              : icon
          }
        />
      }
    />
  );
};

export default InfoPopup;
