import { useEffect, useMemo, useState } from "react";
import { get, isUndefined } from "lodash";
import { captureException, withScope } from "@sentry/react";

// hooks
import { useSelector } from "react-redux";
import { useOrganizationData } from "../../hooks/useOrganizationData";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { UserInterface, UserReportInterface } from "../../interfaces/user";
import { ListInterface } from "../../interfaces/reports-list";

// utils
import parseData from "./parser";
import { requestReportsList } from "../../utils/reports";

export const useBackingData = (manualState?: {
  data: Array<ListInterface>;
  isFetchingData?: boolean;
  hasError?: boolean;
}): [Array<ListInterface> | null, boolean, boolean] => {
  const [data, _setData] = useState<Array<ListInterface> | null>(null);
  const [isFetchingData, _setIsFetchingData] = useState<boolean>(false);
  const [hasError, _setHasError] = useState<boolean>(false);

  const { data: orgChartRootTeam } = useOrganizationData();
  const accessToken = useSelector((state: AppStateInterface) =>
    get(state, "auth.authAccessToken")
  );
  const user: UserInterface = useSelector((state: AppStateInterface) =>
    get(state, "user")
  );
  const tenantId = useSelector((state: AppStateInterface) =>
    get(state, "auth.authParams.tenantId")
  );
  const reportingTeam = useMemo(() => {
    if (!isUndefined(orgChartRootTeam)) {
      return user.enabled_roles.includes("manager-self") &&
        !!orgChartRootTeam?.teamMembers
        ? orgChartRootTeam.teamMembers.filter(
            (u: UserReportInterface) => u.id !== user.id
          )
        : [user];
    }
    return [];
  }, [orgChartRootTeam, user]);

  useEffect(() => {
    (async () => {
      if (!isUndefined(manualState)) {
        _setIsFetchingData(!!manualState.isFetchingData);
        _setHasError(!!manualState.hasError);
        _setData(manualState.data);
      } else {
        try {
          if (!hasError && !isFetchingData && !!reportingTeam.length) {
            _setIsFetchingData(true);
            const responseData = await requestReportsList({
              accessToken,
              tenantId,
              users: reportingTeam
            });
            const parsedData = parseData(responseData, reportingTeam);
            _setData(parsedData);
            _setIsFetchingData(false);
          }
        } catch (e) {
          withScope(scope => {
            scope.setContext("request-reports-list", { users: reportingTeam });
            captureException(e);
          });
          _setData(null);
          _setHasError(true);
          _setIsFetchingData(false);
        }
      }
    })();
  }, [accessToken, tenantId, reportingTeam]);

  return [data, isFetchingData, hasError];
};
