import { color } from "highcharts";
import { reverse } from "lodash";
import { DefaultTheme } from "styled-components/macro";
import { SprintProgressActivityInterface } from "../../interfaces/sprint-metadata";
import { currentTheme } from "../../themes/currentTheme";
import { hexToRgb } from "../../utils/color";

export default ({
  data,
  onBarClick,
  onBarMouseOver,
  theme
}: {
  data: Array<SprintProgressActivityInterface>;
  onBarClick: ({
    e,
    pointData
  }: {
    e: React.MouseEvent<HTMLElement>;
    pointData: any;
  }) => void;
  onBarMouseOver: ({
    e,
    pointData
  }: {
    e: React.MouseEvent<HTMLElement>;
    pointData: any;
  }) => void;
  theme: DefaultTheme;
}) => {
  const colorRamp = [
    theme.colors.all.jean,
    theme.colors.all.banshee,
    theme.colors.all.petra
  ];
  const series = reverse(
    data.map((d, index) => {
      return {
        name: d.displayTitle,
        borderColor: colorRamp[index],
        color: colorRamp[index],
        data: [d.percentage],
        status: d.status
      };
    })
  );

  return {
    chart: {
      height: 60,
      margin: 0,
      type: "bar"
    },
    xAxis: { visible: false },
    yAxis: { visible: false },
    plotOptions: {
      bar: {
        borderWidth: 1,
        cursor: "pointer",
        dataLabels: {
          align: "left",
          borderColor: 0,
          color: theme.colors.all.wolverine,
          enabled: true,
          formatter: function() {
            return (this as any).y > 0.0
              ? `${(this as any).y.toFixed(0)}%`
              : ``;
          },
          style: {
            textOutline: "none"
          }
        },
        point: {
          events: {
            click: function(e: React.MouseEvent<HTMLElement>) {
              onBarClick({ e, pointData: this });
            },
            mouseOver: function(e: React.MouseEvent<HTMLElement>) {
              onBarMouseOver({ e, pointData: this });
            }
          }
        },
        stacking: "percent",
        states: {
          hover: {
            enabled: true,
            brightness: -0.2,
            borderColor: hexToRgb({
              hex: currentTheme.colors.all.storm,
              opacity: 0.5
            })
          },
          inactive: {
            enabled: false
          }
        }
      }
    },
    legend: { enabled: false },
    tooltip: {
      enabled: false
    },
    title: {
      text: undefined
    },
    series
  };
};
