import React from "react";
import styled from "styled-components/macro";

// components
import CrossTeamAvatar from "../CrossTeamAvatar/CrossTeamAvatar";
import InfoIcon from "../InfoIcon/InfoIcon";
import SlackAvatar from "../SlackAvatar/SlackAvatar";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { TeamMemberInterface } from "../../interfaces/work-items";

// utils
import { addTeamAttributes } from "../../utils/work-deep-dive";

// styled components
const Header = styled.h3`
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  flex-direction: row;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  margin-bottom: 0;
  padding-bottom: 1rem;
`;
const ListItem = styled.li`
  align-items: center;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  padding: 1rem 0;
`;
const StyledSlackAvatar = styled(SlackAvatar)`
  border: ${props => `0.2rem solid ${props.theme.colors.all.wolverine}`};
  border-radius: 50% 50%;
  height: 3rem;
  width: 3rem;
`;
const StyledCrossTeamAvatar = styled(CrossTeamAvatar)`
  font-size: 1em;
`;
const Name = styled.span`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin-left: 1rem;
`;
const NoMembers = styled.p`
  margin-top: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  list: Array<TeamMemberInterface>;
  testId?: string;
  header: string;
};

const WorkMetadataTeamList = ({
  className,
  list,
  testId = "testId",
  header
}: Props) => {
  const thisTestId = `${testId}-work-metadata-team-list`;
  const { urlParams } = useUrlParams();
  const team = urlParams.team;
  const teamMembers = team?.teamMembers;

  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        {header}
        <InfoIcon content={header === "Owners" ? "owners" : "contributors"} />
      </Header>
      {!!list?.length && !!teamMembers?.length ? (
        <ul>
          {list.map(l => {
            const enhancedTeamMember = addTeamAttributes(l, teamMembers);
            return (
              <ListItem key={enhancedTeamMember.teamMemberId}>
                {enhancedTeamMember.isCrossTeam ? (
                  <StyledCrossTeamAvatar
                    testId={`${thisTestId}-${enhancedTeamMember.teamMemberId}`}
                  />
                ) : (
                  <StyledSlackAvatar
                    altText={enhancedTeamMember.displayName}
                    src={enhancedTeamMember.slackAvatar}
                    testId={`${thisTestId}-${enhancedTeamMember.teamMemberId}`}
                  />
                )}
                <Name data-heap-redact-text="true">
                  {enhancedTeamMember.displayName}
                </Name>
              </ListItem>
            );
          })}
        </ul>
      ) : (
        <NoMembers>There are no members available.</NoMembers>
      )}
    </div>
  );
};

export default WorkMetadataTeamList;
