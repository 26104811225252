import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import {
  cycleTimeStages,
  prActivityStatusLabels,
  prActivityStatusTypes
} from "../../constants/constants";
import { CycleTimeStageTypes } from "../../interfaces/constants";

// components
import Icon from "../Icon/Icon";

// styled components
const Container = styled.div`
  align-items: flex-start;
  display: flex;
  font-size: 1.1rem;
`;
const KeySection = styled.div`
  padding: 0 ${props => props.theme.grid.gap};

  & + & {
    border-left: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  }

  &:last-child {
    padding: 0 0 0 ${props => props.theme.grid.gap};
  }
`;
const KeyList = styled.ul<{ itemsPerLine: number }>`
  display: grid;
  grid-template-columns: ${props =>
    `repeat(${props.itemsPerLine}, minmax(min-content, auto))`};
  gap: 1rem;
`;
const ChartEvent = styled.li`
  align-items: flex-start;
  display: flex;
`;
const ChartActivity = styled.li`
  align-items: flex-start;
  display: flex;
`;
const Bullet = styled.span<{ type: string }>`
  font-size: ${props => props.theme.fonts.primary.sizes.xl};
  line-height: 1rem;
  color: ${props => {
    switch (props.type) {
      case prActivityStatusTypes.WITHDRAWN:
        return props.theme.colors.all.cyclops;
      case prActivityStatusTypes.MERGED:
        return props.theme.colors.all.nightcrawler;
      case cycleTimeStages.RELEASE:
        return props.theme.colors.all.thunderbird;
      default:
        // case prActivityStatusTypes.OPENED:
        return props.theme.colors.chart.cycleTime[
          props.type as keyof CycleTimeStageTypes
        ].backgroundColor;
    }
  }};
  margin-right: 1rem;
`;
const Chevron = styled(Icon)`
  margin-right: 1rem;
`;
const Instruction = styled.li`
  font-style: italic;

  & + & {
    margin-top: 1rem;
  }
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const PrWorkflowChartKey = ({
  className,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-pr-workflow-chart-key`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <KeySection>
        <ul>
          <Instruction>To Zoom: Click and Drag</Instruction>
          <Instruction>To Pan: Press "Shift", Click and Drag</Instruction>
        </ul>
      </KeySection>
      <KeySection>
        <KeyList itemsPerLine={3}>
          {[
            cycleTimeStages.DEV,
            cycleTimeStages.WAITING_FOR_REVIEW,
            cycleTimeStages.REVIEW,
            prActivityStatusTypes.WITHDRAWN,
            prActivityStatusTypes.MERGED,
            cycleTimeStages.RELEASE
          ].map(t => (
            <ChartEvent key={t}>
              <Bullet type={t}>•</Bullet>
              {prActivityStatusLabels[t as string]}
            </ChartEvent>
          ))}
        </KeyList>
      </KeySection>
      <KeySection>
        <KeyList itemsPerLine={2}>
          {[
            prActivityStatusTypes.COMMITS_ADDED,
            prActivityStatusTypes.APPROVED,
            prActivityStatusTypes.COMMENTED
          ].map(t => (
            <ChartActivity key={t}>
              <Chevron
                icon={
                  t === prActivityStatusTypes.APPROVED
                    ? "pr-activity-chevron-approved"
                    : t === prActivityStatusTypes.COMMENTED
                    ? "pr-activity-chevron-commented"
                    : "pr-activity-chevron-update"
                }
                testId={thisTestId}
              />
              {prActivityStatusLabels[t as string]}
            </ChartActivity>
          ))}
        </KeyList>
      </KeySection>
    </Container>
  );
};

export default PrWorkflowChartKey;
