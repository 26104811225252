import { snakeCase } from "lodash";
import { z } from "zod";
import { zLegacyRoles, zLegacyRolesSnakeCase } from "../interfaces/zod-schema";

export const legacyRoles = [
  // Uplevel
  "admin",
  // For ICs, and M1s with small teams. They should only see results about themselves.
  "self",
  // For M2s who don't also have a big team of ICs directly reporting to them. They should see content about themselves, and a rollup of their M1s (who have enough directs to receive team results)
  "self-rollup",
  "self-smallteam",
  "self-smallteam-rollup",
  "self-smallteam-rollup-exec",
  // For M1s with 5+ IC engineers in their team. Should get results about themselves, and about their team
  "self-team",
  // For M2s who also have a big team of ICs directly reporting to them.
  "self-team-rollup",
  // For users where we just want to show team rather than IC view.
  "team",
  // For individual ICs
  "ic",
  // For individual ICs who can only see their own data, not other team members.
  "privateic",
  "privateic-exec"
] as const;

export const legacyRolesSnakeCase = [
  "admin",
  "self",
  "self_rollup",
  "self_smallteam",
  "self_smallteam_rollup",
  "self_smallteam_rollup_exec",
  "self_team",
  "self_team_rollup",
  "team",
  "ic",
  "privateic",
  "privateic_exec"
] as const;

export const legacyRoleTypes = legacyRoles.reduce(
  (acc, current) => ({
    ...acc,
    [snakeCase(current).toUpperCase()]: current
  }),
  {} as { [key: string]: z.infer<typeof zLegacyRoles> }
);
