import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isUndefined } from "lodash";

// hooks
import { useOrganizationData } from "./useOrganizationData";
import { useLocation } from "react-router-dom";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";
import {
  UserInterface,
  UserReportWithMetadataInterface
} from "../interfaces/user";

// utils
import { getFlattenedTeamMembersTreeForTeam } from "../utils/teams";
import { getTeamLeadId, parseQuerystring } from "../utils/url-params";

export const useActiveUser = () => {
  const [activeUser, setActiveUser] = useState<
    UserReportWithMetadataInterface | UserInterface | null
  >(null);
  const user = useSelector((state: AppStateInterface) => state.user);
  // we have to use the raw querystring here because this hook is used by the
  // url params hook and referencing it would set up a circular dependency
  const { search } = useLocation();
  const parsedQuerystring = useMemo(() => parseQuerystring(search), [
    search,
    parseQuerystring
  ]);
  const teamLeadId = getTeamLeadId(parsedQuerystring);

  // organization
  const { data: usersOrgChartRootTeam } = useOrganizationData();
  const flattenedUsersOrganization = useMemo(() => {
    if (!!usersOrgChartRootTeam) {
      return getFlattenedTeamMembersTreeForTeam(usersOrgChartRootTeam);
    }
  }, [usersOrgChartRootTeam]);
  const teamLead = useMemo(
    () => flattenedUsersOrganization?.find(t => t.id === teamLeadId),
    [flattenedUsersOrganization, teamLeadId]
  );

  useEffect(() => {
    if (!isUndefined(usersOrgChartRootTeam) && !isUndefined(user)) {
      setActiveUser(teamLead || user);
    } else {
      setActiveUser(null);
    }
  }, [usersOrgChartRootTeam, teamLead, user]);

  return activeUser;
};
