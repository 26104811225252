import moment from "moment";

import { momentUnitTypes } from "../constants/date";
import { timerangeIds, timerangeTypes } from "../constants/constants";
import { getSprintTimerangesForBoard } from "./timeranges";

/**
 * get ISO timestamp for date
 * @param {Object} date
 */
export const getTimestampForDate = date => {
  return date.toISOString();
};

export const getTimeDifference = ({ base, comparator, unit }) => {
  return moment(comparator).diff(moment(base), unit);
};

export const createTimestamp = time => moment(time).toISOString();

export const createTimestampWithOffset = time => moment(time).toISOString(true);

export const formatTimestamp = ({
  format = "ddd. MMM. Do h:mm A",
  timestamp
}) => {
  return moment(timestamp, moment.ISO_8601).format(format);
};

export const getDateWeekBefore = date => {
  return moment(date)
    .subtract(7, "days")
    .format();
};

export const getCurrentWeekTimerange = date => {
  return {
    start: getTimestampForDate(moment(date).startOf(momentUnitTypes.WEEKS)),
    end: getTimestampForDate(moment(date).endOf(momentUnitTypes.WEEKS))
  };
};

export const getPreviousWeekTimerange = date => {
  return {
    start: getTimestampForDate(
      moment(date)
        .startOf(momentUnitTypes.WEEKS)
        .subtract(1, momentUnitTypes.WEEKS)
    ),
    end: getTimestampForDate(
      moment(date)
        .endOf(momentUnitTypes.WEEKS)
        .subtract(1, momentUnitTypes.WEEKS)
    )
  };
};

export const getPreviousNumDaysTimerange = (days, date) => {
  return {
    start: getTimestampForDate(
      moment(date)
        .startOf(momentUnitTypes.DAYS)
        .subtract(days, momentUnitTypes.DAYS)
    ),
    end: getTimestampForDate(
      moment(date)
        .endOf(momentUnitTypes.DAYS)
        .subtract(1, momentUnitTypes.DAYS)
    )
  };
};

const TIMERANGES_TO_DISPLAY = 6;

export const getChartTimerangesForBoardAndTimerange = (timerange, board) => {
  let timeranges = [];
  if (!!board && timerange.timerangeType === timerangeTypes.SPRINTS) {
    timeranges = getSprintTimerangesForBoard(board).filter(
      t => t.start <= timerange.start
    );
    timeranges.splice(TIMERANGES_TO_DISPLAY);
  }
  const timerangeSorter = (t1, t2) => t1.start.localeCompare(t2.start);
  const baselineDate = !!timeranges.length
    ? moment(timeranges.sort(timerangeSorter)[0].start).subtract(
        1,
        momentUnitTypes.DAYS
      )
    : moment(timerange.end).add(1, momentUnitTypes.DAYS);
  const timerangesToAdd = TIMERANGES_TO_DISPLAY - timeranges.length;
  for (let i = 0; i < timerangesToAdd; i++) {
    // If the timerangeType is sprints but we don't have enough to show, add extra
    // timeranges of 2 week intervals occuring before the earliest sprint start date.
    // If the timerangeType is not sprints, create 6 intervals of 2 week days using the
    // timerange end date as the baseline.
    timeranges.push({
      start: moment(baselineDate)
        .subtract(2 + 2 * i, momentUnitTypes.WEEKS)
        .toISOString(),
      end: moment(baselineDate)
        .subtract(2 * i, momentUnitTypes.WEEKS)
        .toISOString()
    });
  }

  return timeranges.sort(timerangeSorter);
};

export const getDisplayTextForPreviousTimerange = (
  timerange,
  timerangeMetadata
) => {
  // adding a 1 here because display time difference is zero-based
  const timeDifferenceInDays =
    getTimeDifference({
      base: timerange.start,
      comparator: timerange.end,
      unit: momentUnitTypes.DAYS
    }) + 1;
  if (timerange.timerangeType === timerangeTypes.SPRINTS) {
    return "Last Sprint";
  } else if (timerange.id === timerangeIds.YESTERDAY) {
    return "Previous Day";
  } else if (timerange.id === timerangeIds.THIS_WEEK) {
    return timerangeMetadata[timerangeIds.LAST_WEEK].displayName;
  } else if (timerange.id === timerangeIds.LAST_WEEK) {
    return "Previous Week";
  } else if (
    timerange.id === timerangeIds.PREVIOUS_14_DAYS ||
    timerange.id === timerangeIds.PREVIOUS_30_DAYS ||
    timerange.id === timerangeIds.PREVIOUS_90_DAYS
  ) {
    return timerangeMetadata[timerange.id].displayName;
  } else {
    // "custom" | "days"
    return `Previous ${
      timeDifferenceInDays === 1
        ? "Day"
        : timeDifferenceInDays === 7
        ? "Week"
        : `${timeDifferenceInDays} Days`
    }`;
  }
};
