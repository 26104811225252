import React, { useEffect } from "react";
import styled from "styled-components/macro";

// components
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useHistory
} from "react-router-dom";
import WorkDeepDivePage from "../WorkDeepDivePage/WorkDeepDivePage";
import GlobalNavigation from "../GlobalNavigation/GlobalNavigation";
import LabsPage from "../LabsPage/LabsPage";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import OverviewPage from "../OverviewPage/OverviewPage";
import ReportsPage from "../ReportsPage/ReportsPage";
import RetrosPage from "../RetrosPage/RetrosPage";
import SettingsPage from "../SettingsPage/SettingsPage";
import Sidebar from "../Sidebar/Sidebar";
import StartPage from "../StartPage/StartPage";
import TeamDeepDivePage from "../TeamDeepDive/TeamDeepDivePage";
import RouteLink from "../RouteLink/RouteLink";

// constants
import { pageIds } from "../../constants/constants";

// hooks
import { useLocation } from "react-router-dom";
import { useTracking } from "../../hooks/useTracking";
import { useCanAccessTeamData } from "../../hooks/useCanAccessTeamData";
import { useUrlParams } from "../../hooks/useUrlParams";
import TeamHomePage from "../TeamHomePage/TeamHomePage";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import NoAccessClientDashboard from "../NoAccessClientDashboard/NoAccessClientDashboard";
import { isNull } from "lodash";

// styled components
const PageContainer = styled.div`
  background: ${props => props.theme.colors.all.lightJean};
  border-top: 1rem solid ${props => props.theme.colors.border};
  display: grid;
  grid-template-columns:
    ${props => props.theme.grid.sidebar}
    1fr;
  gap: ${props => props.theme.grid.sidebarGutter};
  min-height: 100vh;
`;
const GridContainer = styled.main`
  width: ${props =>
    `calc(12*${props.theme.grid.columnWidth} + 11*${props.theme.grid.gap} + 2*${props.theme.grid.gridOffset})`};
`;
const ContentWrapper = styled.div`
  padding: 2rem 0;
`;

const NoTeamWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  background: ${props => props.theme.colors.all.rogue}40;
  margin-bottom: 2rem;
`;

const WarningTitle = styled.div`
  font-family: ${props => props.theme.fonts.primary.name};
  font-size: ${props => props.theme.fonts.primary.sizes.md};
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  padding-bottom: 1rem;
`;

const WarningBodyText = styled.div`
  font-family: ${props => props.theme.fonts.primary.name};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

const WarningSubheader = styled.div`
  font-family: ${props => props.theme.fonts.primary.name};
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  padding-top: 2rem;
`;

const WarningStepsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 1rem;
`;

const WarningStep = styled.div`
  margin-right: 2rem;
`;

const StyledRouteLink = styled(RouteLink)`
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
`;

const SettingsLinkButton = styled.button`
  background: ${props =>
    props.disabled
      ? props.theme.colors.button.disabled.default.bg
      : props.theme.colors.button.default.default.bg};
  border: 0;
  color: ${props =>
    props.disabled
      ? props.theme.colors.button.disabled.default.fg
      : props.theme.colors.button.default.default.fg};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  padding: 1rem 3rem;
  align-self: flex-end;
`;
const NoAccessContainer = styled.div`
  align-items: center;
  display: grid;
  justify-items: center;
  width: 100vw;
`;

// typescript props
type Props = {
  className?: string;
};
type MainPagesProps = {
  canAccessTeamData: boolean;
};

// helper component for rendering routes
function MainPages({ canAccessTeamData }: MainPagesProps) {
  const { pageId } = useParams<{ pageId: string }>();

  if (
    (pageId === pageIds.WORK_DEEP_DIVE ||
      pageId === pageIds.RETROS ||
      pageId === pageIds.TEAM_DEEP_DIVE) &&
    !canAccessTeamData
  ) {
    return <NoAccessPage pageId={pageId} testId="dashboard" />;
  }

  switch (pageId) {
    case pageIds.WORK_DEEP_DIVE:
      return <WorkDeepDivePage testId="dashboard" />;
    case pageIds.REPORTS:
      return <ReportsPage testId="dashboard" />;
    case pageIds.SETTINGS:
      return <SettingsPage testId="dashboard" />;
    case pageIds.RETROS:
      return <RetrosPage testId="dashboard" />;
    case pageIds.LABS:
      return <LabsPage />;
    case pageIds.TEAM_DEEP_DIVE:
      return <TeamDeepDivePage />;
    case pageIds.OVERVIEW:
      return <OverviewPage testId="dashboard" />;
    case pageIds.TEAM_HOME:
      return <TeamHomePage testId="dashboard" />;
    default:
      return <Redirect to="/" />;
  }
}

export const DashboardPage = ({
  className
}: Props): React.ReactElement | null => {
  const thisTestId = "client-dashboard-page";
  const { trackEvent } = useTracking();
  const location = useLocation();
  const canAccessTeamData = useCanAccessTeamData();
  const { urlParams } = useUrlParams();
  const history = useHistory();
  const hasNoTeam = urlParams.team?.teamMembers.length === 1;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const canAccessDashboard = flags?.["uplevel-client-dashboard"];

  // todo: add browser forward/back event back in here

  // page load event
  // asnider: beware adding trackEvent in the dependency array causes an infinite loop
  useEffect(() => {
    trackEvent({
      e: {
        type: "page-load"
      },
      label: "page-load",
      value: `loaded`
    });
  }, [location]);

  return isNull(flags) ? null : (
    <PageContainer className={className} data-testid={thisTestId}>
      {canAccessDashboard ? (
        <>
          <Sidebar />
          <GridContainer>
            <ContentWrapper>
              <GlobalNavigation testId="dashboard" />
              {hasNoTeam && (
                <NoTeamWarningContainer>
                  <WarningTitle>Build your Team</WarningTitle>
                  <WarningBodyText>
                    Please go create your team in the{" "}
                    <StyledRouteLink
                      name="team-builder"
                      to="/settings"
                      type="nav"
                    >
                      <span>Team Builder</span>
                    </StyledRouteLink>
                  </WarningBodyText>
                  <WarningSubheader>How to create a team</WarningSubheader>
                  <WarningStepsList>
                    <WarningStep>
                      <WarningBodyText>1 - Add a team name</WarningBodyText>
                    </WarningStep>
                    <WarningStep>
                      <WarningBodyText>
                        2 - Select whether you use sprints
                      </WarningBodyText>
                    </WarningStep>
                    <WarningStep>
                      <WarningBodyText>
                        3 - Assign the Jira Project
                      </WarningBodyText>
                    </WarningStep>
                    <WarningStep>
                      <WarningBodyText>
                        4 - Assign the Jira Board
                      </WarningBodyText>
                    </WarningStep>
                    <WarningStep>
                      <WarningBodyText>5 - Add Team Members</WarningBodyText>
                    </WarningStep>
                  </WarningStepsList>
                  <SettingsLinkButton onClick={() => history.push("/settings")}>
                    Get Started
                  </SettingsLinkButton>
                </NoTeamWarningContainer>
              )}
              <Switch>
                <Route path={`/:pageId`}>
                  <MainPages canAccessTeamData={canAccessTeamData} />
                </Route>
                <Route>
                  {canAccessTeamData ? (
                    <StartPage />
                  ) : (
                    <NoAccessPage testId="dashboard" />
                  )}
                </Route>
              </Switch>
            </ContentWrapper>
          </GridContainer>
        </>
      ) : (
        <NoAccessContainer>
          <NoAccessClientDashboard />
        </NoAccessContainer>
      )}
    </PageContainer>
  );
};

export default DashboardPage;
