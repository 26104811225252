import React from "react";
import styled from "styled-components/macro";

// utils
import { getSprintRangeString } from "../../utils/sprints";

// styled components
const Container = styled.em<{ scoreType: string }>`
  background: ${props => props.theme.colors.signalHealth[props.scoreType]};
  display: inline-block;
  font-size: 1.1rem;
  font-style: normal;
  line-height: 1.8;
  padding: 0 0.8rem;
`;

// typescript props
type Props = {
  className?: string;
  content?: React.ReactNode;
  score: number;
  testId?: string;
};

const SignalHealthColor = ({
  className,
  content,
  score,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-signal-health-color`;
  const scoreType = getSprintRangeString(score);

  return (
    <Container
      className={className}
      data-testid={thisTestId}
      scoreType={scoreType}
    >
      {content || scoreType}
    </Container>
  );
};

export default SignalHealthColor;
