// utils
import { legacyApi } from "./api";

export const requestCharts = async ({ accessToken, chartNames, tenantId }) => {
  const response = await legacyApi.post(
    `/${tenantId}/charts`,
    JSON.stringify(chartNames),
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return response.data;
};
