import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

// components
import Col from "react-bootstrap/Col";

// styled components
const StyledGridCol = styled(Col)``;

const GridCol = ({ children, ...otherProps }) => {
  if (!children) {
    return null;
  }

  return <StyledGridCol {...otherProps}>{children}</StyledGridCol>;
};

GridCol.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired
};

export default GridCol;
