import React, { ReactElement, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { get } from "lodash";

// components
import { Redirect, Route, Switch } from "react-router-dom";
import EpicDetailsPage from "../EpicDetailsPage/EpicDetailsPage";
import OperationalMetricsPage from "../OperationalMetricsPage/OperationalMetricsPage";
import PRWorkflow from "../PRWorkflow/PRWorkflow";
import PageTitle from "../PageTitle/PageTitle";
import PeoplePicker from "../PeoplePicker/PeoplePicker";
import PrCadenceChartWrapper from "../PrCadenceChartWrapper/PrCadenceChartWrapper";
import ProjectWork from "../ProjectWork/ProjectWork";
import StoryPointsChartWrapper from "../StoryPointsChartWrapper/StoryPointsChartWrapper";
import RouteLinkTabs from "../RouteLinkTabs/RouteLinkTabs";
import TemplateHeader from "../TemplateHeader/TemplateHeader";

// constants
import {
  groupByTypes,
  pageIds,
  pageMetadata,
  urlGlobalParams,
  workDeepDiveSectionLabels,
  workDeepDiveSections
} from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

// utils
import { getWorkDeepDivePageTitle } from "../../utils/work-deep-dive";
import { getTeamNameFromTeam } from "../../utils/teams";
import { formatTimestamp } from "../../utils/date";
import { getIsSprintMode } from "../../utils/sprints";

// styled components
const StyledTemplateHeader = styled(TemplateHeader)`
  border-bottom: 0;
`;
const HeaderContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const StyledTabs = styled(RouteLinkTabs)`
  padding-bottom: 0.5rem;
  margin: 2rem 0 0.5rem 0;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;

const StyledStoryPoints = styled(StoryPointsChartWrapper)`
  background-color: ${props => props.theme.colors.all.white};
  padding: 2rem;
`;
const StyledPRCadence = styled(PrCadenceChartWrapper)`
  background-color: ${props => props.theme.colors.all.white};
  padding: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const WorkDeepDivePage = ({
  className,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-work-deep-dive-page`;
  const location = useLocation();

  const flags = useSelector((state: AppStateInterface) => state.flags);
  const accessToken = useSelector(state => get(state, "auth.authAccessToken"));
  const tenantId = useSelector(state => get(state, "auth.authParams.tenantId"));
  const user = useSelector(state => get(state, "user", {}));
  const { urlParams, workDeepDiveParams, getWorkDeepDivePath } = useUrlParams();
  const selectedSection = workDeepDiveParams.selectedSection;
  const activeTeam = useMemo(() => urlParams.team, [urlParams]);
  const activeTimerange = useMemo(() => urlParams.timerange, [urlParams]);
  const pageTitle = `${pageMetadata[pageIds.WORK_DEEP_DIVE].title}${
    !!activeTeam ? ": " + getTeamNameFromTeam(activeTeam) : ""
  }${
    activeTimerange &&
    activeTeam?.useSprints &&
    getIsSprintMode(activeTimerange)
      ? " - " + activeTimerange.displayName
      : " - " +
        formatTimestamp({
          format: "M/D",
          timestamp: activeTimerange.start
        }) +
        " - " +
        formatTimestamp({ format: "M/D", timestamp: activeTimerange.end })
  }`;
  const canViewPrWorkflowTab = flags?.["project-explorer-workflow"];
  const canViewPrFrequency = flags?.["pr-frequency"];
  const canViewOpsMetrics = flags?.["project-explorer-ops-metrics"];
  const canViewStoryPoints = flags?.["project-explorer-story-points"];

  const allowedSections = useMemo(
    () =>
      [
        workDeepDiveSections.OPS_METRICS,
        workDeepDiveSections.PROJECT_WORK,
        workDeepDiveSections.PR_WORKFLOW,
        workDeepDiveSections.PR_FREQUENCY,
        workDeepDiveSections.STORY_POINTS
      ].filter(section => {
        switch (section) {
          case workDeepDiveSections.STORY_POINTS:
            return canViewStoryPoints;
          case workDeepDiveSections.OPS_METRICS:
            return canViewOpsMetrics;
          case workDeepDiveSections.PR_WORKFLOW:
            return canViewPrWorkflowTab;
          case workDeepDiveSections.PR_FREQUENCY:
            return canViewPrFrequency;
          default:
            return true;
        }
      }),
    [
      canViewOpsMetrics,
      canViewPrFrequency,
      canViewPrWorkflowTab,
      canViewStoryPoints
    ]
  );

  const teamAvatarEnabled = !(
    selectedSection === workDeepDiveSections.PROJECT_WORK &&
    urlParams.groupBy === groupByTypes.USER
  );
  const selectedTeamMembers = useMemo(
    () =>
      workDeepDiveParams.selectedUser === "team"
        ? activeTeam?.teamMembers
        : [
            activeTeam?.teamMembers.find(
              m => m.email === workDeepDiveParams.selectedUser
            )
          ],
    [activeTeam, workDeepDiveParams]
  );

  // page title change
  useEffect(() => {
    document.title = getWorkDeepDivePageTitle(selectedSection);
  }, [location, selectedSection]);

  return flags?.["work-deep-dive"] ? (
    <div className={className} data-testid={thisTestId}>
      <StyledTemplateHeader
        content={
          <>
            <HeaderContentWrapper>
              <PageTitle title={pageTitle} testId={thisTestId} />
              <PeoplePicker teamAvatarEnabled={teamAvatarEnabled} />
            </HeaderContentWrapper>
            <StyledTabs
              defaultTabId={selectedSection}
              tabs={allowedSections.map(section => ({
                path: getWorkDeepDivePath({
                  persistQuerystring:
                    section === workDeepDiveSections.OPS_METRICS
                      ? urlGlobalParams
                      : true,
                  selectedSection: section
                }),
                title: workDeepDiveSectionLabels[section],
                id: section,
                isDisabled: false
              }))}
              testId="work-deep-dive-tabs"
            />
          </>
        }
        testId={thisTestId}
      />
      <Switch>
        <Route exact path={`/${pageIds.WORK_DEEP_DIVE}`}>
          <div className={className} data-testid={thisTestId}>
            {canViewOpsMetrics ? (
              <OperationalMetricsPage />
            ) : (
              <ProjectWork testId={thisTestId} />
            )}
          </div>
        </Route>

        <Route
          path={`/${pageIds.WORK_DEEP_DIVE}/${workDeepDiveSections.OPS_METRICS}`}
        >
          <OperationalMetricsPage />
        </Route>

        {/* TODO: temp redirect until it seems like people are not bookmarking your-teams-work anymore */}
        <Route exact path={`/${pageIds.WORK_DEEP_DIVE}/your-teams-work`}>
          <Redirect
            to={`/${pageIds.WORK_DEEP_DIVE}/${
              workDeepDiveSections.PROJECT_WORK
            }${!!window.location.search ? window.location.search : ""}`}
          />
        </Route>

        {/* TODO: temp redirect until it seems like people are not bookmarking ops-metrics anymore */}
        <Route exact path={`/${pageIds.WORK_DEEP_DIVE}/ops-metrics`}>
          <Redirect
            to={`/${pageIds.WORK_DEEP_DIVE}/${
              workDeepDiveSections.OPS_METRICS
            }${!!window.location.search ? window.location.search : ""}`}
          />
        </Route>

        <Route
          exact
          path={`/${pageIds.WORK_DEEP_DIVE}/${workDeepDiveSections.PROJECT_WORK}`}
        >
          <div className={className} data-testid={thisTestId}>
            <ProjectWork testId={thisTestId} />
          </div>
        </Route>
        <Route
          path={`/${pageIds.WORK_DEEP_DIVE}/${workDeepDiveSections.PROJECT_WORK}/:groupId`}
        >
          <EpicDetailsPage testId="dashboard" />
        </Route>
        {canViewPrWorkflowTab ? (
          <Route
            path={`/${pageIds.WORK_DEEP_DIVE}/${workDeepDiveSections.PR_WORKFLOW}`}
          >
            <PRWorkflow />
          </Route>
        ) : null}
        {canViewPrFrequency ? (
          <Route
            path={`/${pageIds.WORK_DEEP_DIVE}/${workDeepDiveSections.PR_FREQUENCY}`}
          >
            <StyledPRCadence
              className={className}
              testId={testId}
              accessToken={accessToken}
              tenantId={tenantId}
              user={user}
              teamMembers={selectedTeamMembers}
              board={urlParams.board}
              timerange={urlParams.timerange}
            />
          </Route>
        ) : (
          <Redirect
            to={`/${pageIds.WORK_DEEP_DIVE}${
              !!window.location.search ? window.location.search : ""
            }`}
          />
        )}
        {flags?.["project-explorer-story-points"] && (
          <Route
            path={`/${pageIds.WORK_DEEP_DIVE}/${workDeepDiveSections.STORY_POINTS}`}
          >
            <StyledStoryPoints
              className={className}
              testId={testId}
              accessToken={accessToken}
              tenantId={tenantId}
              user={user}
              teamMembers={selectedTeamMembers}
              startDate={urlParams.timerange.start}
              endDate={urlParams.timerange.end}
            />
          </Route>
        )}
      </Switch>
    </div>
  ) : (
    <Redirect to={`/`} />
  );
};

export default WorkDeepDivePage;
