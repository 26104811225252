import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import styled, {
  createGlobalStyle,
  DefaultTheme
} from "styled-components/macro";
import { get, isEmpty } from "lodash";
// highstock gives us the ability to add scrollbars on charts
import HighchartsStock from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsNetworkGraph from "highcharts/modules/networkgraph";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsTilemap from "highcharts/modules/tilemap";
import HighchartsPatternFill from "highcharts/modules/pattern-fill";
import HighchartsReact from "highcharts-react-official";
import spinner from "../../images/spinner.svg";

// components
import ChartTooltipWrapper from "../ChartTooltipWrapper/ChartTooltipWrapper";

// config
import generateConfig from "./config";

// utils
import { hexToRgb } from "../../utils/color";

// init the module
[
  HighchartsMore,
  HighchartsNetworkGraph,
  HighchartsHeatmap,
  HighchartsTilemap,
  HighchartsPatternFill,
  HighchartsAccessibility
].forEach(highchartsModule => {
  highchartsModule(HighchartsStock);
});

const GlobalStyle = createGlobalStyle`
  /*
    chart styles -- creating global styles because tooltip will be appended to body,
    outside the confines of the chart container
  */
  .highcharts-tooltip-container {
    position: fixed !important;
  }

  .colorKey {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      height: 2rem;
      margin-right: 1.5rem;
      width: 2rem;
    }

    &.colorKeyCircle {
      &::before {
        border-radius: 50%;
      }
    }

    &.reviewTimeColor::before {
      background: ${props => props.theme.colors.chart.prCadence.reviewTime};
    }
    &.prsColor::before {
      background: ${props => props.theme.colors.chart.prCadence.prs};
    }
    &.teamMeetingTimeColor::before {
      background: ${props => props.theme.colors.all.storm};
    }
    &.userMeetingTimeColor::before {
      background: ${props => props.theme.colors.all.nightcrawler};
    }
  }

  .chart-tooltip {
    background-color: #fff;
    border: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    font-family: ${props =>
      props.theme.fonts.primary.name}, Arial, Helvetica, sans-serif;
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
    padding: 1rem;
    position: relative;
    font-size: ${props => props.theme.fonts.primary.sizes.xs};

    & p {
      max-width: 20rem;
    }

    & .fixed-width {
      font-family: ${props =>
        props.theme.fonts.primary.name}, Arial, Helvetica, sans-serif;
    }

    & .chart-tooltip-header {
      font-family: ${props =>
        props.theme.fonts.primary.name}, Arial, Helvetica, sans-serif;
      font-weight: ${props => props.theme.fonts.primary.weights.bold};
      margin-bottom: 1rem;
    }

    & .chart-tooltip-subheader {
      font-family: ${props =>
        props.theme.fonts.primary.name}, Arial, Helvetica, sans-serif;
      font-weight: ${props => props.theme.fonts.primary.weights.regular};
    }

    & .chart-tooltip-footer {
      color: ${props => props.theme.colors.all.auroraTeal};
      font-size: ${props => props.theme.fonts.primary.sizes.xs};
      font-weight: ${props => props.theme.fonts.primary.weights.extraBold};
    }

    & .chart-slackAvatar {
      margin-right: 1.5rem;
      top: 0;
    }

    & ul {
      margin-top: 1rem;
    }

    & li {
      display: flex;
    }

    & dl div {
      align-items: baseline;
      display: flex;
    }

    & dt {
      font-weight: ${props => props.theme.fonts.primary.weights.regular};
      margin-right: .5rem;
    }

    & .negative::before,
    & .positive::before {
      display: inline-block;
      margin-right: 0.2rem;
    }
    & .positive::before {
      content: "\u2191";
    }
    & .negative::before {
      content: "\u2193";
    }

    &.meeting-time-chart-tooltip,
    &.pr-cadence-chart-tooltip {
      font-weight: ${props => props.theme.fonts.primary.weights.regular};
      & li {
        display: block;

        & + li  {
          margin-top: .5rem;
        }
      }
    }

    &.people-health-chart-tooltip {
      border: ${props =>
        `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
        font-family: ${props => props.theme.fonts.primary.name};
    }

    &.sprint-health-chart-tooltip,
    &.sprint-health-factors-chart-tooltip,
    &.sprint-health-trend-analysis-chart-tooltip {
      background-color: #fff;
      border: ${props =>
        `${props.theme.borders.widths.sm} solid ${hexToRgb({
          hex: props.theme.colors.all.wolverine,
          opacity: 0.25
        })}`};
      font-family: ${props =>
        props.theme.fonts.primary.name}, Arial, Helvetica, sans-serif;
      font-weight: ${props => props.theme.fonts.primary.weights.regular};
      padding: 1.2rem;
      font-size: ${props => props.theme.fonts.primary.sizes.xs};

      & .chart-tooltip-header {
        display: flex;
        font-weight: inherit;
        flex-flow: column nowrap;
        margin: 0;
      }

      & h2, & h3 {
        font-size: inherit;
        margin: 0;
      }

      & h3  {
        font-weight: inherit;
        order: 1;
      }

      & h2 {
        font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
        order: 2;
      }

      & dl {
        border-top: ${props =>
          `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.wolverine}`};
        margin-top: .4rem;
        padding-top: .4rem;
      }
    }
  }

  .chart-slackAvatar {
    position: relative;
    top: .5rem;
    left: -0.1rem;

    & .avatar {
      border-color: ${props => props.theme.colors.border};
      border-radius: 50%;
      border-style: solid;
      border-width: 0.2rem;
      height: inherit;
      width: inherit;
    }
  }
`;

// styled components
const StyledContainer = styled.div`
  /* highcharts classes */
  & .highcharts-loading {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 0.6 !important;
    transition: 0.2s all;
  }
  & .highcharts-loading-inner {
    background-image: url(${spinner});
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: inline-block;
    height: 8rem;
    position: static !important;
    text-indent: -9999rem;
    width: 8rem;
  }
`;
const Tooltip = styled.div`
  background-color: #fff;
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding: 1rem;
`;

// typescript props
type ChartConfig = {
  config: Highcharts.Options | Record<string, unknown>;
  id: string;
  isFetchingData?: boolean;
};

type Props = {
  chart?: ChartConfig;
  className?: string;
  testId?: string;
  tooltipComponent?: ReactElement;
  theme?: DefaultTheme;
};

const Chart = ({
  chart = {} as ChartConfig,
  className,
  tooltipComponent,
  testId = "testId",
  theme
}: Props): ReactElement => {
  const chartRef = useRef(null);
  const [highchart, setHighchart] = useState(null);
  const callback = useCallback(chart => {
    setHighchart(chart);
  }, []);

  const { config, id, isFetchingData } = chart;
  const finalConfig = useMemo(() => {
    return generateConfig({ config });
  }, [config]);

  useEffect(() => {
    const chartInstance = get(chartRef, "current.chart");
    if (
      chartInstance &&
      chartInstance.showLoading &&
      chartInstance.hideLoading
    ) {
      if (isFetchingData) {
        chartInstance.showLoading();
      } else {
        chartInstance.hideLoading();
      }
      return () => chartInstance.hideLoading();
    }
  }, [chartRef, isFetchingData]);

  useEffect(() => {
    const chartInstance = get(chartRef, "current.chart");
    if (chartInstance && !isEmpty(finalConfig)) {
      // this assumes 1 axis per dimension, that will be brittle for more complicated charts
      // axes can be a bare object or an array of objects, which is annoying
      const yAxisConfig = finalConfig.yAxis?.[0] || finalConfig.yAxis;
      const xAxisConfig = finalConfig.xAxis?.[0] || finalConfig.xAxis;
      if (yAxisConfig) {
        chartInstance.yAxis[0].setExtremes(yAxisConfig.min, yAxisConfig.max);
      }
      if (xAxisConfig) {
        chartInstance.xAxis[0].setExtremes(xAxisConfig.min, xAxisConfig.max);
      }
    }
  }, [chartRef, finalConfig]);

  return (
    <StyledContainer className={className}>
      <GlobalStyle />
      <HighchartsReact
        data-testid={testId}
        highcharts={HighchartsStock}
        id={id}
        options={finalConfig}
        ref={chartRef}
        callback={callback}
      />
      {!!tooltipComponent ? (
        <ChartTooltipWrapper chart={highchart}>
          {context =>
            !!context?.point?.series ? (
              <Tooltip>
                {React.cloneElement(tooltipComponent, {
                  context
                })}
              </Tooltip>
            ) : null
          }
        </ChartTooltipWrapper>
      ) : null}
    </StyledContainer>
  );
};

export default Chart;
