import React from "react";
import styled from "styled-components/macro";

// components
import Icon from "../Icon/Icon";
import PopupContent from "../PopupContent/PopupContent";
import TriggeredOverlay from "../TriggeredOverlay/TriggeredOverlay";

// styled components
const TriggerIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.storm};
  font-size: 1.2rem;
`;

// typescript props
type Props = {
  className?: string;
  content: React.ReactNode;
  onToggle: (nextShow: boolean) => void;
  showPopup: boolean;
  testId?: string;
};

const DotMenu = ({
  className,
  content,
  onToggle,
  showPopup,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-dot-menu`;
  return (
    <TriggeredOverlay
      onToggleShow={onToggle}
      overlayContent={<PopupContent content={content} testId={thisTestId} />}
      overlayPlacement="top"
      show={showPopup}
      testId={thisTestId}
      triggerAction="click"
      triggerContent={
        <TriggerIcon
          className={className}
          data-testid={`${thisTestId}-trigger`}
          icon="ellipsis-horizontal"
        />
      }
    />
  );
};

export default DotMenu;
