import React from "react";
import styled from "styled-components/macro";

// components
import OrganizationNavigationMenu from "../OrganizationNavigationMenu/OrganizationNavigationMenu";
import UiFilterOverlay from "../UiFilterOverlay/UiFilterOverlay";

// hooks
import { useSelector } from "react-redux";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

// styled components
const Container = styled.nav`
  align-items: center;
  border-right: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  padding-right: 2rem;
`;
const TriggerWrapper = styled.span`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  margin-right: 1rem;
  &:after {
    content: ":";
  }
`;

// typescript props
type Props = {
  activeOverlay: string | null;
  className?: string;
  onHideOverlay: () => void;
  onTriggerOverlay: () => void;
  testId?: string;
};

const DirectorViewNavigation = ({
  activeOverlay,
  className,
  onHideOverlay,
  onTriggerOverlay,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-director-view-navigation`;
  const user = useSelector((state: AppStateInterface) => state.user);
  const { urlParams } = useUrlParams();
  const { teamLead } = urlParams;
  const displayUser = teamLead || user;

  return (
    <Container className={className} data-testid={thisTestId}>
      <UiFilterOverlay
        hasModalBg={true}
        isActive={activeOverlay === "director-view-navigation"}
        onCancelOverlay={onHideOverlay}
        onTriggerOverlay={onTriggerOverlay}
        triggerContent={
          <TriggerWrapper data-heap-redact-text="true">
            {displayUser?.name}
          </TriggerWrapper>
        }
      >
        <OrganizationNavigationMenu
          onUpdate={onHideOverlay}
          testId={thisTestId}
        />
      </UiFilterOverlay>
    </Container>
  );
};

export default DirectorViewNavigation;
