// utils
import { legacyApi } from "./api";

/**
 * parse auth params
 * @param {Object} params
 */
export const parseAuthParams = authParams => {
  return { ...authParams.attributes, tenantId: parseInt(authParams.id, 10) };
};

/**
 * request params needed for authentication from level9
 * @param {String} queryParam
 */
export const requestAuthParams = async domain => {
  const response = await legacyApi.get("/0/id", {
    headers: { Authorization: `Bearer allow` },
    params: { domain }
  });
  return response.data;
};
