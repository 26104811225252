import React from "react";
import styled from "styled-components/macro";
import { isEmpty, isNull } from "lodash";

// components
import EmptyState from "../EmptyState/EmptyState";
import PersonCard from "../PersonCard/PersonCard";
import Spinner from "../Spinner/Spinner";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";

// hooks
import { useBackingData } from "./useBackingData";
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { ListInterface } from "../../interfaces/reports-list";

// styled components
const Container = styled.div`
  min-height: 40rem;
`;
const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
`;
const Card = styled(PersonCard)`
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;
const Reports = styled.ul`
  display: grid;
  gap: 2rem;
  grid-template-columns: 25rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 25rem);
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(3, 25rem);
  }
`;
const DownloadLink = styled.a`
  font-size: ${props => props.theme.fonts.header.sizes.xs};
`;

// typescript props
type Props = {
  className?: string;
  manualState?: {
    data: Array<ListInterface>;
    isFetchingData?: boolean;
    hasError?: boolean;
  };
  testId?: string;
};

const ReportsList = ({ className, manualState, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-reports-list`;
  const [reportsListData, isFetchingData, hasError] = useBackingData(
    manualState
  );
  const { trackEvent } = useTracking();

  function onClickDownloadLink({
    e,
    report
  }: {
    e: React.MouseEvent<HTMLAnchorElement>;
    report: ListInterface;
  }) {
    e.preventDefault();
    trackEvent({
      e,
      label: `1-1-download-link-click`,
      value: `clicked download link for ${report.user.name} 1:1 report`
    });
    window.open(report.url, "_blank");
  }

  let displayContent;

  if (isFetchingData) {
    displayContent = (
      <SpinnerWrapper>
        <span>Loading reports...</span>
        <Spinner testId={thisTestId} />
      </SpinnerWrapper>
    );
  } else if (hasError) {
    displayContent = (
      <EmptyState
        header="Uh oh!"
        body="It looks like something went wrong fetching the reports. Please reload your browser to try again."
        testId="reports-container-error"
      />
    );
  } else if (isNull(reportsListData)) {
    displayContent = <div />;
  } else if (reportsListData.every(report => isEmpty(report.url))) {
    displayContent = (
      <p data-testid="reports-container-no-reports-available">
        No 1:1 reports are available at this time. These reports are generated
        weekly, so please check back later.
      </p>
    );
  } else {
    displayContent = (
      <Reports>
        {reportsListData.map(report => {
          return (
            <li key={report.user.id}>
              <Card
                person={report.user}
                metadata={
                  report.url ? (
                    <DownloadLink
                      data-testid={`reports-container-report-link-${report.user.email}-download-link`}
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                        onClickDownloadLink({ e, report })
                      }
                      href={report.url}
                    >
                      Download 1:1 report
                    </DownloadLink>
                  ) : null
                }
              />
            </li>
          );
        })}
      </Reports>
    );
  }

  return (
    <Container className={className} data-testid={thisTestId}>
      <TransitionWrapper>{displayContent}</TransitionWrapper>
    </Container>
  );
};

export default ReportsList;
