import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { isNull } from "lodash";

// components
import HtmlText from "../HtmlText/HtmlText";
import InfoBook from "../InfoBook/InfoBook";
import InfoPopup from "../InfoPopup/InfoPopup";
import OperationalMetricsCycleTimeBreakout from "../OperationalMetricsCycleTimeBreakout/OperationalMetricsCycleTimeBreakout";
import OperationalMetricsCycleTimeSummary from "../OperationalMetricsCycleTimeSummary/OperationalMetricsCycleTimeSummary";

// interfaces
import {
  OperationalMetricsTextContent,
  OpMetricData,
  OpMetricSprintRecord,
  OpMetricValueTypes
} from "../../interfaces/ops-metrics";

// styled components
const Header = styled.header`
  align-items: center;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  margin-bottom: 1rem;
`;
const InfoPopups = styled.div`
  align-items: center;
  display: flex;
  margin-left: 0.8rem;
`;
const Book = styled(InfoBook)`
  margin-left: 0.8rem;
`;
const ContentWrapper = styled.div`
  font-size: ${props => props.theme.fonts.header.sizes.xs};
`;

const DisplayContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;
const Summary = styled(OperationalMetricsCycleTimeSummary)`
  margin-right: ${props =>
    `calc(${props.theme.grid.columnWidth} + (2 * ${props.theme.grid.gap}) - 3px)`};
  width: ${props =>
    `calc((3 * ${props.theme.grid.columnWidth}) + (2 * ${props.theme.grid.gap}))`};
`;
const Breakout = styled(OperationalMetricsCycleTimeBreakout)`
  margin-top: 0.5rem;
`;

// typescript props
type Props = {
  all: OpMetricData;
  className?: string;
  current: OpMetricSprintRecord;
  first: OpMetricSprintRecord;
  firstMonth: string;
  name: OpMetricValueTypes;
  previous?: OpMetricSprintRecord;
  previousTimerangeHeader: React.ReactNode;
  testId?: string;
  textContent: OperationalMetricsTextContent;
};

const OperationalMetricsCycleTime = ({
  all,
  className,
  current,
  first,
  firstMonth,
  name,
  previous,
  previousTimerangeHeader,
  testId = "testId",
  textContent
}: Props): ReactElement => {
  const thisTestId = `${testId}-ops-metrics-cycle-time`;
  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        {textContent.title}
        {isNull(textContent.definition) &&
        isNull(textContent.summary) ? null : (
          <InfoPopups>
            {isNull(textContent.summary) ? null : (
              <InfoPopup
                content={
                  <ContentWrapper>
                    <HtmlText
                      htmlText={textContent.summary}
                      testId={thisTestId}
                    />
                  </ContentWrapper>
                }
                name={name}
                testId={thisTestId}
              />
            )}
            {isNull(textContent.definition) ? null : (
              <Book
                content={textContent.definition.content}
                header={textContent.definition.header}
                link={textContent.definition.link}
                name={name}
                testId={thisTestId}
              />
            )}
          </InfoPopups>
        )}
      </Header>
      <DisplayContainer>
        <Summary
          all={all}
          current={current}
          first={first}
          firstMonth={firstMonth}
          previous={previous}
          previousTimerangeHeader={previousTimerangeHeader}
          testId={thisTestId}
        />
        <Breakout
          all={all}
          current={current}
          previous={previous}
          previousTimerangeHeader={previousTimerangeHeader}
          testId={thisTestId}
        />
      </DisplayContainer>
    </div>
  );
};

export default OperationalMetricsCycleTime;
