import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

// components
import TemplateHeader from "../TemplateHeader/TemplateHeader";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

// utils
import { getTeamNameFromTeam } from "../../utils/teams";
import LoadingStates from "../LoadingStates/LoadingStates";
import { isNull } from "lodash";
import { pageIds, pageMetadata } from "../../constants/constants";

// styled components
const Header = styled.header`
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const StyledTemplateHeader = styled(TemplateHeader)`
  border-bottom: 0;
  padding-bottom: 0;
`;
const HeaderWrapper = styled.div`
  align-items: flex-end;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
`;
const Greeting = styled.h4`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;
const Title = styled.h3`
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const ContentContainer = styled.div`
  background: ${props => props.theme.colors.all.white};
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 40rem;
`;
const ContentWidthWrapper = styled.div`
  max-width: 30rem;
`;

// typescript props
type Props = {
  pageId?: string;
  testId?: string;
};

const NoAccessPage = ({ pageId, testId = "testId" }: Props): ReactElement => {
  const thisTestId = `${testId}-no-access-page`;
  const pageTitle = pageMetadata[pageId || pageIds.START].title;
  const user = useSelector((state: AppStateInterface) => state.user);
  const { urlParams } = useUrlParams();
  const activeTeam = urlParams.team;

  return (
    <LoadingStates
      isSpinningState={isNull(activeTeam)}
      content={
        <div data-testid={thisTestId}>
          <StyledTemplateHeader
            content={
              <HeaderWrapper data-heap-redact-text="true">
                <hgroup>
                  <Greeting>Hi, {user?.["givenName"]}</Greeting>
                  {isNull(activeTeam) ? null : (
                    <Title>
                      {pageTitle}: {getTeamNameFromTeam(activeTeam)}
                    </Title>
                  )}
                </hgroup>
              </HeaderWrapper>
            }
            testId={thisTestId}
          />
          <ContentContainer>
            <ContentWidthWrapper>
              <Header>
                Looks like you don&rsquo;t have access to this team&rsquo;s
                data.
              </Header>
              <p>
                If you think this is an error, have the team&rsquo;s owner add
                you to the team to see the data.
              </p>
            </ContentWidthWrapper>
          </ContentContainer>
        </div>
      }
      testId={thisTestId}
    />
  );
};

export default NoAccessPage;
