import React, { useState } from "react";
import styled from "styled-components/macro";
import { isUndefined, sortBy } from "lodash";

// components
import Button from "../Button/Button";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interface
import { AnnotationsInterface } from "../../interfaces/annotations";
import { UserReportInterface } from "../../interfaces/user";
import UserCommentBlock from "../UserCommentBlock/UserCommentBlock";

// styled components
const Footer = styled.footer`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

// typescript props
type Props = {
  className?: string;
  comments: Array<AnnotationsInterface>;
  maxVisibleComments?: number;
  teamMembers: Array<UserReportInterface>;
  testId?: string;
};

const CommentsList = ({
  className,
  comments,
  maxVisibleComments,
  teamMembers,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-comments-list`;
  const { trackEvent } = useTracking();
  const sortedComments = sortBy(comments, "timestamp").reverse();
  const [isCollapsed, setIsCollapsed] = useState(true);

  function onToggleCommentsLength(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    setIsCollapsed(!isCollapsed);
    trackEvent({
      e,
      label: `${thisTestId}-comments-toggle`,
      value: `clicked to ${!isCollapsed ? "show comments" : "hide comments"}`
    });
  }

  const visibleComments =
    isUndefined(maxVisibleComments) || !isCollapsed
      ? sortedComments
      : sortedComments.slice(0, maxVisibleComments);

  return (
    <div className={className} data-testid={thisTestId}>
      {visibleComments.reverse().map((annotation, index) => {
        const teamMember = teamMembers.find(
          member => member.id === annotation.userId
        );
        return (
          <UserCommentBlock
            key={index}
            annotation={annotation}
            teamMember={teamMember}
          />
        );
      })}
      {isUndefined(maxVisibleComments) ||
      sortedComments.length === maxVisibleComments ? null : (
        <Footer>
          <Button
            button="unstyled"
            onClick={onToggleCommentsLength}
            testId={`${thisTestId}-comments-toggle`}
          >
            {isCollapsed ? `${sortedComments.length} comments` : "hide"}
          </Button>
        </Footer>
      )}
    </div>
  );
};

export default CommentsList;
