import React, { useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { isEmpty, isUndefined } from "lodash";

// components
import EmptyState from "../EmptyState/EmptyState";
import EpicDetailsIssuesList from "../EpicDetailsIssuesList/EpicDetailsIssuesList";
import LoadingStates from "../LoadingStates/LoadingStates";
import PaginationStepper from "../PaginationStepper/PaginationStepper";
import WorkMetadataDetailsSummary from "../WorkMetadataDetailsSummary/WorkMetadataDetailsSummary";

// hooks
import { useWorkItemsData } from "../../hooks/useWorkItemsData";
import { useAnnotationsData } from "../../hooks/useAnnotationsData";
import { useSelector } from "react-redux";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { EpicDetailsInterface } from "../../interfaces/work-items";

//  utils
import { getEpicDetailsPageTitle } from "../../utils/work-deep-dive";
import { flattenWorkItems } from "../../utils/work-items";
import { useUrlParams } from "../../hooks/useUrlParams";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";
import { urlParamKeys } from "../../constants/constants";

// styled components
const IssuesList = styled(EpicDetailsIssuesList)`
  margin-top: 3rem;
`;
const ContentWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
  padding: 1.2rem 2rem 2rem;
`;
const PaginationStepperWrapper = styled.div`
  margin-top: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const EpicDetails = ({ className, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-epic-details`;

  const { updateUrlParams, urlParams } = useUrlParams();
  const groupBy = urlParams.groupBy;
  const { data, isError, isFetching, isSuccess } = useWorkItemsData();
  const { data: annotationsData } = useAnnotationsData({
    type: "epic-details"
  });

  // FIXME: for some reason TS isn't understanding that this is an epicdetailsinterface coming out of useWorkItems
  const rawWorkItemsData = data as EpicDetailsInterface;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const hasAnnotations = flags?.["annotations"];
  const flattenedAndAnnotatedWorkItems = useMemo(() => {
    if (
      !isUndefined(rawWorkItemsData?.workItems) &&
      !isUndefined(annotationsData)
    ) {
      const flattenedWorkItems = flattenWorkItems({
        workItems: rawWorkItemsData.workItems,
        rootWorkItemId: null,
        level: 0
      });
      if (!isUndefined(annotationsData)) {
        return flattenedWorkItems.map(item => ({
          ...item,
          annotations: annotationsData.filter(
            (annotation: Record<string, unknown>) =>
              hasAnnotations &&
              !!annotation?.workItemId &&
              annotation?.workItemId === item.id
          )
        }));
      }
      return flattenedWorkItems;
    }
    return null;
  }, [annotationsData, hasAnnotations, rawWorkItemsData]);

  // page title change to add extra epic details
  useEffect(() => {
    if (!!rawWorkItemsData) {
      document.title = getEpicDetailsPageTitle({
        groupBy,
        workCategoryId: rawWorkItemsData.workCategoryId,
        workCategoryName: rawWorkItemsData.workCategoryName
      });
    }
  }, [rawWorkItemsData, groupBy, isSuccess]);

  function onChangePaginationStepper({ updatedPage }: { updatedPage: number }) {
    updateUrlParams({
      [urlParamKeys.GROUP_DETAILS]: {
        [urlParamKeys.PAGE]: updatedPage
      }
    });
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <LoadingStates
        isSpinningState={
          isFetching &&
          isUndefined(rawWorkItemsData) &&
          isEmpty(flattenedAndAnnotatedWorkItems)
        }
        isNoDataState={
          isError ||
          (!isFetching && isSuccess && !rawWorkItemsData?.workItems?.length)
        }
        noDataContent={
          <EmptyState
            body="Your team doesn't seem to have any data for this date range."
            header="Hmmm."
            testId={testId}
          />
        }
        content={
          !!rawWorkItemsData && !!flattenedAndAnnotatedWorkItems?.length ? (
            <TransitionWrapper>
              <ContentWrapper>
                <WorkMetadataDetailsSummary
                  data={rawWorkItemsData}
                  testId={thisTestId}
                />
              </ContentWrapper>
              <IssuesList
                data={flattenedAndAnnotatedWorkItems}
                testId={thisTestId}
              />
              <PaginationStepperWrapper>
                <PaginationStepper
                  currentPage={rawWorkItemsData?.page}
                  finalPage={rawWorkItemsData?.finalPage}
                  onChange={onChangePaginationStepper}
                  name="epic-details-issues-pagination"
                  testId={thisTestId}
                />
              </PaginationStepperWrapper>
            </TransitionWrapper>
          ) : null
        }
        testId={thisTestId}
      />
    </div>
  );
};

export default EpicDetails;
