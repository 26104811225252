import React, { ReactElement, useMemo } from "react";
import styled from "styled-components/macro";
import { isEmpty, isNil, isNull, min } from "lodash";

// components
import DataDisclaimer from "../DataDisclaimer/DataDisclaimer";
import EmptyState from "../EmptyState/EmptyState";
import InfoIcon from "../InfoIcon/InfoIcon";
import RouteLink from "../RouteLink/RouteLink";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";
import OverviewSummary from "../OverviewSummary/OverviewSummary";
import OverviewCycleTimeTrendlineTooltip from "../OverviewCycleTimeTrendlineTooltip/OverviewCycleTimeTrendlineTooltip";

// hooks
import { useSelector } from "react-redux";
import { useOrganizationCycleTimeData } from "../../hooks/useOrganizationCycleTimeData";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import {
  OrgByTimeItems,
  OrgTimeRange,
  OrgTimeRanges
} from "../../interfaces/organization";

// utils
import {
  getMappedSummaryData,
  getDisplayTrendlinePoints
} from "../../utils/organization-data";

// styled components
const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  min-height: inherit;
`;
const Header = styled.header`
  display: flex;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  align-items: center;
  margin-bottom: ${props => props.theme.grid.gap};
`;
const MainContent = styled.div`
  min-height: 70rem;
`;
const EmptyStateWrapper = styled(TransitionWrapper)`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: inherit;
`;
const Footer = styled.footer`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  gap: 10rem;
  margin-bottom: 1rem;
`;
const Benchmark = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.all.storm};
  display: flex;
`;
const BenchmarkLine = styled.div`
  border-top: ${props =>
    `${props.theme.borders.widths.sm} dashed ${props.theme.colors.all.storm}`};
  width: 3rem;
  margin-right: 1rem;
`;

// typescript props
type Props = {
  className?: string;
  isFetchingOverviewData: boolean;
  testId?: string;
  userIds: Array<number> | null;
  selectedTimeRange?: OrgTimeRange;
  orgTimeRanges?: OrgTimeRanges;
};

const OverviewCycleTime = ({
  className,
  isFetchingOverviewData,
  testId = "testId",
  userIds,
  selectedTimeRange,
  orgTimeRanges
}: Props): ReactElement => {
  const thisTestId = `${testId}-overview-cycle-time`;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const { getOverviewPath } = useUrlParams();
  const {
    data: summaryData,
    isError: isErrorSummaryData,
    isFetching: isFetchingSummaryData,
    isSuccess: isSuccessSummaryData
  } = useOrganizationCycleTimeData(
    {
      type: "year",
      userIds: userIds as Array<number>
    },
    !isEmpty(userIds)
  );

  // create a sorted array of dates with extended metadata
  const mappedSummaryData: OrgByTimeItems | null = useMemo(() => {
    if (
      isNil(orgTimeRanges) ||
      isNil(summaryData) ||
      Object.values(summaryData).every(v => isNull(v))
    ) {
      return null;
    }

    return getMappedSummaryData(summaryData, orgTimeRanges);
  }, [summaryData, orgTimeRanges]);

  // get the current and previous trendline points to select for data display
  const [previous, current] = useMemo(() => {
    if (isNil(mappedSummaryData) || isNil(selectedTimeRange)) {
      return [];
    }
    return getDisplayTrendlinePoints(mappedSummaryData, selectedTimeRange);
  }, [mappedSummaryData, selectedTimeRange]);

  const isDaysScale = useMemo(() => {
    if (isNil(mappedSummaryData)) {
      return false;
    }
    const truthyValuesInHours = mappedSummaryData
      .map(d => d.value)
      .filter(v => !isNil(v)) as Array<number>;
    return (min(truthyValuesInHours) as number) >= 24;
  }, [mappedSummaryData]);

  const hasNoRawSummaryData = useMemo(() => {
    return (
      isErrorSummaryData ||
      (isSuccessSummaryData &&
        (isNil(summaryData) ||
          Object.values(summaryData).every(v => isNull(v))))
    );
  }, [isErrorSummaryData, isSuccessSummaryData, summaryData]);
  const hasNoSummaryData = useMemo(() => {
    return !userIds?.length || isNull(mappedSummaryData);
  }, [mappedSummaryData, userIds]);

  const isFetchingRawChartsData = isFetchingSummaryData;
  const isFetchingAnyData = isFetchingRawChartsData || isFetchingOverviewData;

  const hasNoFetchedData = !isFetchingAnyData && hasNoRawSummaryData;
  const hasNoFilteredData = !isFetchingAnyData && hasNoSummaryData;

  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        <h3>Cycle Time</h3>
        <InfoIcon content="cycle-time" />
      </Header>
      <MainContent>
        {!isErrorSummaryData && (hasNoFetchedData || hasNoFilteredData) ? (
          <EmptyStateWrapper>
            <EmptyState
              body={
                hasNoFetchedData ? (
                  <>There doesn&rsquo;t seem to be any data available.</>
                ) : (
                  <>
                    <p>
                      There doesn&rsquo;t seem to be any data for the filters
                      that are applied.
                    </p>
                    <p>
                      Click{" "}
                      <RouteLink
                        name={`${thisTestId}-no-data`}
                        to={getOverviewPath({
                          persistQuerystring: false
                        })}
                        testId={thisTestId}
                      >
                        here
                      </RouteLink>{" "}
                      to reset all filters.
                    </p>
                  </>
                )
              }
              header="Hmmm."
              testId={testId}
            />
          </EmptyStateWrapper>
        ) : (
          <ChartGrid>
            <OverviewSummary
              all={mappedSummaryData}
              benchmark={flags?.["cycle-time-benchmarks"]}
              current={current}
              isEmptyData={hasNoSummaryData}
              isError={isErrorSummaryData}
              isFetching={isFetchingSummaryData || isFetchingOverviewData}
              previous={previous}
              isDaysScale={isDaysScale}
              testId={thisTestId}
              tooltip={<OverviewCycleTimeTrendlineTooltip />}
              measure={"cycle-time"}
            />
          </ChartGrid>
        )}
      </MainContent>
      <Footer>
        {!isFetchingAnyData &&
        (hasNoFetchedData || hasNoFilteredData) ? null : (
          <Benchmark>
            <BenchmarkLine>
              <span className="visuallyHidden">visible dashed line</span>
            </BenchmarkLine>
            <small>Uplevel Benchmark</small>
            <InfoIcon content="overview-benchmark" />
          </Benchmark>
        )}
        <DataDisclaimer testId={thisTestId} />
      </Footer>
    </div>
  );
};

export default OverviewCycleTime;
