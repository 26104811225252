import React, { ReactElement, useMemo } from "react";
import { isNull, isUndefined } from "lodash";
import styled from "styled-components/macro";

// components
import DataDisclaimer from "../DataDisclaimer/DataDisclaimer";
import EmptyState from "../EmptyState/EmptyState";
import LoadingStates from "../LoadingStates/LoadingStates";
import OperationalMetricsBox from "../OperationalMetricsBox/OperationalMetricsBox";
import OperationalMetricsCycleTime from "../OperationalMetricsCycleTime/OperationalMetricsCycleTime";

// constants
import {
  urlParamKeys,
  workDeepDiveSections,
  workMetadataActivityTypes,
  workMetadataStatusTypes
} from "../../constants/constants";
import operationalMetricsText from "../../constants/operationalMetricsText.json";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";
import { useOperationalMetricsData } from "../../hooks/useOperationalMetricsData";

// utils
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import {
  formatTimestamp,
  getDisplayTextForPreviousTimerange
} from "../../utils/date";
import {
  getOperationalMetric,
  getOperationalMetricDelta
} from "../../utils/operational-metrics";
import { getIsActiveSprint, getIsSprintMode } from "../../utils/sprints";

const Content = styled.div`
  background: ${props => props.theme.colors.all.white};
  padding: ${props => props.theme.grid.gridOffset};
`;

const MetricBoxes = styled.div`
  display: flex;
  flex-direction: row;
`;
const Footer = styled.footer`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
`;
const CycleTimeWrapper = styled.div`
  margin-top: 5rem;
`;

const OperationalMetricsPage = (): ReactElement => {
  const thisTestId = "operational-metrics-page";
  const { getWorkDeepDivePath, urlParams } = useUrlParams();
  const timerange = urlParams.timerange;
  const isSprintMode = getIsSprintMode(timerange);
  const isActiveSprint = getIsActiveSprint(timerange);
  // FIXME: fully own that there's a lot of duplication and changing stuff in
  // multiple places for this. we don't have the granular extracted text we need
  // yet, hopefully not too much longer.
  const informationText = isSprintMode
    ? operationalMetricsText.sprint
    : operationalMetricsText.kanban;

  const session = useSelector((state: AppStateInterface) => state.session);
  const displayPreviousHeader = getDisplayTextForPreviousTimerange(
    timerange,
    session.namedTimeranges
  );
  const {
    data: operationalMetricsData,
    isFetching: isFetchingOperationalMetricsData,
    isSuccess: isSuccessOperationalMetricsData
  } = useOperationalMetricsData();
  const [first, current, previous] = useMemo(
    () =>
      isUndefined(operationalMetricsData)
        ? []
        : operationalMetricsData
            .slice(operationalMetricsData.length - 1)
            .concat(operationalMetricsData.slice(0, 2)),
    [operationalMetricsData]
  );

  // release count
  const releaseCount = getOperationalMetric("releaseCount", current);
  const previousReleaseCount = getOperationalMetric("releaseCount", previous);
  const firstReleaseCount = getOperationalMetric("releaseCount", first);
  const releaseCountDelta = getOperationalMetricDelta(
    releaseCount,
    previousReleaseCount
  );
  const overallReleaseCountDelta = getOperationalMetricDelta(
    releaseCount,
    firstReleaseCount
  );
  const releaseCountValue = isNull(releaseCount)
    ? releaseCount
    : (releaseCount.value as number);
  const previousReleaseCountValue = isNull(previousReleaseCount)
    ? previousReleaseCount
    : (previousReleaseCount.value as number);

  // bug allocation
  const bugAllocation = getOperationalMetric("bugAllocation", current);
  const previousBugAllocation = getOperationalMetric("bugAllocation", previous);
  const firstBugAllocation = getOperationalMetric("bugAllocation", first);
  const bugAllocationDelta = getOperationalMetricDelta(
    bugAllocation,
    previousBugAllocation
  );
  const overallBugAllocationDelta = getOperationalMetricDelta(
    bugAllocation,
    firstBugAllocation
  );
  const bugAllocationValue = isNull(bugAllocation)
    ? bugAllocation
    : (bugAllocation.value as number);
  const previousBugAllocationValue = isNull(previousBugAllocation)
    ? previousBugAllocation
    : (previousBugAllocation.value as number);

  // completion rate
  const completionRate = getOperationalMetric("completionRate", current);
  const previousCompletionRate = getOperationalMetric(
    "completionRate",
    previous
  );
  const firstCompletionRate = getOperationalMetric("completionRate", first);
  const completionRateDelta = getOperationalMetricDelta(
    completionRate,
    previousCompletionRate
  );
  const overallCompletionRateDelta = getOperationalMetricDelta(
    completionRate,
    firstCompletionRate
  );
  const completionRateValue = isNull(completionRate)
    ? completionRate
    : (completionRate.value as number);
  const previousCompletionRateValue = isNull(previousCompletionRate)
    ? previousCompletionRate
    : (previousCompletionRate.value as number);

  // complex prs rate
  const complexPrsRate = getOperationalMetric("complexPrs", current);
  const previousComplexPrsRate = getOperationalMetric("complexPrs", previous);
  const firstComplexPrsRate = getOperationalMetric("complexPrs", first);
  const complexPrsRateDelta = getOperationalMetricDelta(
    complexPrsRate,
    previousComplexPrsRate
  );
  const overallComplexPrsRateDelta = getOperationalMetricDelta(
    complexPrsRate,
    firstComplexPrsRate
  );
  const complexPrsRateValue = isNull(complexPrsRate)
    ? complexPrsRate
    : (complexPrsRate.value as number);
  const previousComplexPrsRateValue = isNull(previousComplexPrsRate)
    ? previousComplexPrsRate
    : (previousComplexPrsRate.value as number);

  const firstMonth = !!first?.startDate
    ? formatTimestamp({
        format: "MMM",
        timestamp: first.startDate
      })
    : "";
  const prWorkFlowLinkPath = getWorkDeepDivePath({
    selectedSection: workDeepDiveSections.PR_WORKFLOW
  });

  return (
    <Content>
      <LoadingStates
        isSpinningState={isFetchingOperationalMetricsData}
        isNoDataState={
          !isSuccessOperationalMetricsData ||
          (isSuccessOperationalMetricsData &&
            !operationalMetricsData?.length) ||
          !current
        }
        noDataContent={
          <EmptyState
            body="Your team doesn't seem to have any data for this date range."
            header="Hmmm."
            testId={thisTestId}
          />
        }
        content={
          !!operationalMetricsData?.length ? (
            <>
              <MetricBoxes>
                <OperationalMetricsBox
                  firstMonth={firstMonth}
                  isSprintMode={isSprintMode}
                  name="releaseCount"
                  value={releaseCountValue}
                  previousValue={previousReleaseCountValue}
                  deltaValue={releaseCountDelta}
                  // use current value as signed delta if overall delta is null (most likely previous was 0)
                  overallDeltaValue={
                    isNull(overallReleaseCountDelta)
                      ? releaseCountValue
                      : overallReleaseCountDelta
                  }
                  textContent={informationText.releaseCount}
                  timerange={timerange}
                  opMetricsData={operationalMetricsData}
                  previousTimerangeHeader={displayPreviousHeader}
                  linkPath={prWorkFlowLinkPath}
                />
                <OperationalMetricsBox
                  firstMonth={firstMonth}
                  isSprintMode={isSprintMode}
                  name="bugAllocation"
                  value={bugAllocationValue}
                  previousValue={previousBugAllocationValue}
                  deltaValue={bugAllocationDelta}
                  // use current value as signed delta if overall delta is null (most likely previous was 0)
                  overallDeltaValue={
                    isNull(overallBugAllocationDelta)
                      ? bugAllocationValue
                      : overallBugAllocationDelta
                  }
                  previousTimerangeHeader={displayPreviousHeader}
                  textContent={informationText.bugAllocation}
                  linkPath={getWorkDeepDivePath({
                    querystringParams: {
                      [urlParamKeys.WORK_ITEM_TYPES]: [
                        workMetadataActivityTypes.BUG
                      ]
                    },
                    selectedSection: workDeepDiveSections.PROJECT_WORK
                  })}
                  opMetricsData={operationalMetricsData}
                  timerange={timerange}
                />
                <OperationalMetricsBox
                  firstMonth={firstMonth}
                  isActiveSprint={isActiveSprint}
                  isSprintMode={isSprintMode}
                  name="completionRate"
                  value={completionRateValue}
                  previousValue={previousCompletionRateValue}
                  deltaValue={completionRateDelta}
                  // use current value as signed delta if overall delta is null (most likely previous was 0)
                  overallDeltaValue={
                    isNull(overallCompletionRateDelta)
                      ? completionRateValue
                      : overallCompletionRateDelta
                  }
                  previousTimerangeHeader={displayPreviousHeader}
                  textContent={informationText.completionRate}
                  linkPath={getWorkDeepDivePath({
                    querystringParams: isSprintMode
                      ? {
                          [urlParamKeys.WORK_ITEM_TYPES]: []
                        }
                      : {
                          [urlParamKeys.WORK_ITEM_TYPES]: [
                            workMetadataActivityTypes.BUG,
                            workMetadataActivityTypes.ISSUE,
                            workMetadataActivityTypes.SUB_TASK
                          ],
                          [urlParamKeys.STATUS]: [workMetadataStatusTypes.DONE]
                        },
                    selectedSection: workDeepDiveSections.PROJECT_WORK
                  })}
                  timerange={timerange}
                  opMetricsData={operationalMetricsData}
                />
                <OperationalMetricsBox
                  firstMonth={firstMonth}
                  isSprintMode={isSprintMode}
                  name="complexPrs"
                  value={complexPrsRateValue}
                  previousValue={previousComplexPrsRateValue}
                  deltaValue={complexPrsRateDelta}
                  // use current value as signed delta if overall delta is null (most likely previous was 0)
                  overallDeltaValue={
                    isNull(overallComplexPrsRateDelta)
                      ? complexPrsRateValue
                      : overallComplexPrsRateDelta
                  }
                  previousTimerangeHeader={displayPreviousHeader}
                  textContent={informationText.complexPrs}
                  linkPath={prWorkFlowLinkPath}
                  timerange={timerange}
                  opMetricsData={operationalMetricsData}
                />
              </MetricBoxes>
              <CycleTimeWrapper>
                <OperationalMetricsCycleTime
                  all={operationalMetricsData}
                  current={current}
                  first={first}
                  firstMonth={firstMonth}
                  name="cycleTime"
                  previous={previous}
                  previousTimerangeHeader={displayPreviousHeader}
                  testId={thisTestId}
                  textContent={informationText.cycleTime}
                />
              </CycleTimeWrapper>
            </>
          ) : null
        }
      />
      <Footer>
        <DataDisclaimer testId={thisTestId} />
      </Footer>
    </Content>
  );
};

export default OperationalMetricsPage;
