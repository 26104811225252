// avatar images
import avatar_01 from "../images/avatar_01.svg";
import avatar_02 from "../images/avatar_02.svg";
import avatar_03 from "../images/avatar_03.svg";
import avatar_04 from "../images/avatar_04.svg";
import avatar_05 from "../images/avatar_05.svg";
import avatar_06 from "../images/avatar_06.svg";
import avatar_07 from "../images/avatar_07.svg";
import avatar_08 from "../images/avatar_08.svg";

export const avatarImages = [
  avatar_01,
  avatar_02,
  avatar_03,
  avatar_04,
  avatar_05,
  avatar_06,
  avatar_07,
  avatar_08
];
