import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { captureException, withScope } from "@sentry/react";

// actions
import { storeAuthParams } from "../../actions/authActions";

// components
import { Redirect, useLocation } from "react-router-dom";
import DomainForm from "../DomainForm/DomainForm";
import LoadingStates from "../LoadingStates/LoadingStates";

// constants
import { authProviders } from "../../constants/constants";

// hooks
import { useDispatch, useSelector } from "react-redux";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

// utils
import { requestAuthParams, parseAuthParams } from "../../utils/auth";
import { parseQuerystring } from "../../utils/url-params";

const AuthPage = () => {
  const thisTestId = "auth-page";
  const location = useLocation();
  const parsedQuerystring = parseQuerystring(location.search);
  const { d: urlDomain } = parsedQuerystring;
  const localStorageDomain = localStorage.getItem("domain");
  const [userEnteredDomain, setUserEnteredDomain] = useState<string>();
  const [domain, setDomain] = useState<string>();

  const dispatch = useDispatch();
  const authParams = useSelector(
    (state: AppStateInterface) => state.auth.authParams
  );
  const hasAuthParams = !isEmpty(authParams?.clientId);

  useEffect(() => {
    // figure out what domain to use -- userEnteredDomain and
    // urlDomain *should* be mutually exclusive as putting the domain in the
    // querystring bypasses the domain form.
    const reconciledDomain =
      userEnteredDomain || (urlDomain as string) || localStorageDomain;
    if (!!reconciledDomain) {
      // cache the reconciled domain in localstorage -- fully aware this could
      // be the same domain that already exists in localstorage, but it seemed more
      // straightforward to include that in the logic and mostly harmless to
      // re-set the same value
      localStorage.setItem("domain", reconciledDomain);
      // and a state variable to trigger the auth params fetch
      setDomain(reconciledDomain);
    }
  }, [localStorageDomain, urlDomain, userEnteredDomain]);

  useEffect(() => {
    (async () => {
      if (!hasAuthParams) {
        try {
          let fetchedAuthParams;

          if (!!domain) {
            fetchedAuthParams = await requestAuthParams(domain);
          }

          if (fetchedAuthParams) {
            const parsedAuthParams = parseAuthParams(fetchedAuthParams);
            const authProvider = /auth0/.test(parsedAuthParams.audience)
              ? authProviders.AUTH0
              : authProviders.OKTA;
            // authParams are used in App.tsx to pass to the auth
            // provider and trigger display of the actual routes
            dispatch(
              storeAuthParams({
                ...parsedAuthParams,
                authProvider
              })
            );
          }
        } catch (e) {
          withScope(scope => {
            scope.setContext("request-auth-params", { domain });
            captureException(e);
          });
        }
      }
    })();
  }, [domain, hasAuthParams]);

  return (
    <LoadingStates
      isSpinningState={!!domain && !hasAuthParams}
      isNoDataState={!domain}
      noDataContent={<DomainForm onSubmit={setUserEnteredDomain} />}
      content={<Redirect to={{ pathname: `/` }} />}
      testId={thisTestId}
    />
  );
};

export default AuthPage;
