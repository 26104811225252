import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { OrgByGroupPoint } from "../../interfaces/charts";
import TimeRange from "../TimeRange/TimeRange";

// styled components
const Container = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const Header = styled.header`
  margin-bottom: 1.6rem;
`;
const DefinitionWrapper = styled.div`
  align-items: baseline;
  display: flex;
`;
const Term = styled.dt`
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;

// typescript props
type Props = {
  context?: {
    point?: OrgByGroupPoint;
  };
};

const OverviewDeepWorkScatterplotTooltip = ({
  context
}: Props): ReactElement | null => {
  if (!context?.point) {
    return null;
  }
  const { x, endDate, startDate, teamLeadsName, role } = context.point;
  const thisTestId = `overview-deep-work-scatterplot-tooltip`;
  return (
    <Container className={thisTestId} data-testid={thisTestId}>
      <Header>
        <TimeRange
          config={{
            format: { end: "MM/DD/YYYY", start: "MM/DD/YYYY" }
          }}
          timerange={{ end: endDate, start: startDate }}
          testId={thisTestId}
        />
      </Header>
      <dl>
        <DefinitionWrapper>
          <Term>Manager:&nbsp;</Term>
          <dd data-heap-redact-text="true">{teamLeadsName}</dd>
        </DefinitionWrapper>
        <DefinitionWrapper>
          <Term>Role:&nbsp;</Term>
          <dd>{role}</dd>
        </DefinitionWrapper>
        <DefinitionWrapper>
          <Term>Average daily deep work:&nbsp;</Term>
          <dd>{x.toFixed(1)}h</dd>
        </DefinitionWrapper>
      </dl>
    </Container>
  );
};

export default OverviewDeepWorkScatterplotTooltip;
