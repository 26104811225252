import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components/macro";

// components
import Chart from "../Chart/Chart";

// config
import generateConfig from "./config";
import { useSelector } from "react-redux";

// styled components
const StyledChart = styled(Chart)`
  & .axisTitle {
    text-transform: uppercase;
    font-size: ${props => props.theme.fonts.subheader.sizes.xs};
  }
`;

// exporting for targeting in tests
export const ChartMeetingTime = ({
  className,
  data = {},
  isFetchingData = false,
  onClickSeries = () => {
    // do nothing
  },
  selectedDate,
  testId = "testId",
  theme,
  user,
  selectedTeamMember,
  selectedUser,
  canViewTeam = true
}) => {
  const thisTestId = `${testId}-meeting-time-chart`;
  const configProps = useMemo(() => {
    return {
      data,
      onClickSeries,
      selectedDate,
      theme,
      selectedTeamMember,
      selectedUser,
      canViewTeam
    };
  }, [
    data,
    onClickSeries,
    selectedDate,
    theme,
    selectedTeamMember,
    selectedUser,
    canViewTeam
  ]);

  return (
    <StyledChart
      chart={{
        config: generateConfig(configProps),
        id: "meeting-time-chart",
        isFetchingData
      }}
      className={className}
      testId={thisTestId}
    />
  );
};

ChartMeetingTime.propTypes = {
  data: PropTypes.object,
  isFetchingData: PropTypes.bool,
  testId: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default withTheme(ChartMeetingTime);
