import React from "react";

// components
import Time from "../Time/Time";

// interfaces
import { TimerangeInterface } from "../../interfaces/date";

// typescript props
type Props = {
  className?: string;
  config?: { format?: TimerangeInterface };
  testId?: string;
  timerange: TimerangeInterface;
};

const TimeRange = ({
  className,
  config = {},
  testId = "testId",
  timerange
}: Props) => {
  const thisTestId = `${testId}-time-range`;
  const { start, end } = timerange;
  const { format } = config;

  return (
    <div className={className} data-testid={thisTestId}>
      <Time
        format={format && format.start}
        timestamp={start}
        testId={thisTestId}
      />
      &ndash;
      <Time format={format && format.end} timestamp={end} testId={thisTestId} />
    </div>
  );
};

export default TimeRange;
