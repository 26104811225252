import React, { useMemo } from "react";
import { DefaultTheme } from "styled-components/macro";
import { isNull, sortBy } from "lodash";

// components
import Chart from "../Chart/Chart";
import { ErrorBoundary } from "@sentry/react";

// config
import generateConfig from "./config";

// constants
import { SPRINT_HEALTH_CHART_NUM_SPRINTS } from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";
import { useSelector } from "react-redux";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import {
  FilteredSprintHealthInterface,
  SprintHealthDataPointInterface,
  SprintHealthInterface
} from "../../interfaces/sprint-metadata";
import { getFilteredSprintHealthData } from "../../utils/health-metrics";

// typescript props
type Props = {
  className?: string;
  data: SprintHealthInterface;
  isFetchingData?: boolean;
  testId?: string;
};

const ChartSprintHealth = ({
  className,
  data,
  isFetchingData = false,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-chart-sprint-health`;
  const { trackEvent } = useTracking();
  const filteredData = getFilteredSprintHealthData(
    data
  ) as FilteredSprintHealthInterface;
  const sortedData = sortBy(Object.values(filteredData), "startAt").slice(
    0,
    SPRINT_HEALTH_CHART_NUM_SPRINTS
  );
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const hasPeopleHealthData = flags?.["people-health-insights"];

  // TODO: update this to an event for showing tooltip when this
  // bug gets fixed:
  // https://github.com/highcharts/highcharts/issues/13868
  function onChartMouseOver(e: React.MouseEvent<HTMLElement>) {
    e.persist();
    trackEvent({
      e,
      label: "chart-sprint-health",
      value: "chart moused over"
    });
  }
  function onChartMouseOut(e: React.MouseEvent<HTMLElement>) {
    e.persist();
    trackEvent({
      e,
      label: "chart-sprint-health",
      value: "chart moused out"
    });
  }

  const chart = useMemo(() => {
    return {
      config: generateConfig({ data: sortedData, hasPeopleHealthData }),
      id: "sprint-health-chart",
      isFetchingData
    };
  }, [hasPeopleHealthData, sortedData, isFetchingData]);

  return (
    <div
      onMouseEnter={onChartMouseOver}
      onMouseLeave={onChartMouseOut}
      className={className}
      data-testid={thisTestId}
    >
      <ErrorBoundary>
        <Chart
          chart={chart}
          className={className}
          testId={`${thisTestId}-sprint-health-chart`}
        />
      </ErrorBoundary>
    </div>
  );
};

export default ChartSprintHealth;
