import React from "react";
import styled from "styled-components/macro";

// components
import Icon from "../Icon/Icon";

//  styled components
const Container = styled.div`
  height: 3rem;
  width: 3rem;
  font-size: 2rem;
  border: ${props => `.2rem solid ${props.theme.colors.border}`};
  border-radius: 50% 50%;
  background: ${props => props.theme.colors.all.iceman};
  color: ${props => props.theme.colors.all.wolverine};
  display: inline-block;
  padding: 0.25rem;
  text-align: center;
`;
const IconAvatar = styled(Icon)`
  font-size: inherit;
  height: auto;
  color: inherit;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const CrossTeamAvatar = ({ className, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-cross-team-avatar`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <IconAvatar icon="team-plus" testId={thisTestId} />
    </Container>
  );
};

export default CrossTeamAvatar;
