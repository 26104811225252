import { startCase } from "lodash";
import moment from "moment";
import { currentTheme } from "../../themes/currentTheme";

// constants
import {
  momentUnitTypes,
  overviewHealthTypes,
  healthFactorsMetadata,
  healthScoreRanges,
  healthScoreTypes,
  sprintStates
} from "../../constants/constants";

// interfaces
import { SprintHealthDataPointInterface } from "../../interfaces/sprint-metadata";

// utils
import {
  getAdjustedTooltipPosition,
  getSprintHealthTooltip
} from "../../utils/chart";
import { hexToRgb } from "../../utils/color";
import { createTimestamp, formatTimestamp } from "../../utils/date";

// typescript props
type Props = {
  data: Array<SprintHealthDataPointInterface>;
  hasPeopleHealthData: boolean;
};

type SeriesData = {
  name: string;
  color: string;
  marker: {
    fillColor: string;
    lineColor: string;
  };
  data: Array<SprintHealthDataPointInterface>;
  opacity?: number;
  zIndex?: number;
};

const config = ({ data, hasPeopleHealthData }: Props) => {
  let series: Array<SeriesData> = [
    {
      name: healthFactorsMetadata[overviewHealthTypes.PROJECT_HEALTH].title,
      color:
        currentTheme.colors.chart.healthFactors[
          overviewHealthTypes.PROJECT_HEALTH
        ],
      marker: {
        fillColor:
          currentTheme.colors.chart.healthFactors[
            overviewHealthTypes.PROJECT_HEALTH
          ],
        lineColor:
          currentTheme.colors.chart.healthFactors[
            overviewHealthTypes.PROJECT_HEALTH
          ]
      },
      data: data.map(d => {
        return { ...d, y: d.projectHealth };
      }),
      opacity: 0.75
    }
  ];

  if (hasPeopleHealthData) {
    series = [
      ...series,
      {
        name: healthFactorsMetadata[overviewHealthTypes.AVERAGE_HEALTH].title,
        color:
          currentTheme.colors.chart.healthFactors[
            overviewHealthTypes.AVERAGE_HEALTH
          ],
        marker: {
          fillColor:
            currentTheme.colors.chart.healthFactors[
              overviewHealthTypes.AVERAGE_HEALTH
            ],
          lineColor:
            currentTheme.colors.chart.healthFactors[
              overviewHealthTypes.AVERAGE_HEALTH
            ]
        },
        data: data.map(d => {
          return { ...d, y: d.averageHealth };
        }),
        zIndex: 1
      },
      {
        name: healthFactorsMetadata[overviewHealthTypes.PEOPLE_HEALTH].title,
        color:
          currentTheme.colors.chart.healthFactors[
            overviewHealthTypes.PEOPLE_HEALTH
          ],
        marker: {
          fillColor:
            currentTheme.colors.chart.healthFactors[
              overviewHealthTypes.PEOPLE_HEALTH
            ],
          lineColor:
            currentTheme.colors.chart.healthFactors[
              overviewHealthTypes.PEOPLE_HEALTH
            ]
        },
        data: data.map(d => {
          return { ...d, y: d.peopleHealth };
        }),
        opacity: 0.75
      }
    ];
  }

  return {
    chart: {
      height: 250,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      spacingBottom: 0,
      style: {
        fontFamily: currentTheme.fonts.primary.name,
        fontWeight: currentTheme.fonts.primary.weights.regular
      }
    },
    xAxis: {
      categories: data.map(d => {
        const isCurrentSprint = d.state === sprintStates.ACTIVE;
        const isSameMonth = moment(d.startAt).isSame(
          d.completeAt,
          momentUnitTypes.MONTHS
        );
        const start = formatTimestamp({
          format: "M/DD",
          timestamp: createTimestamp(d.startAt)
        });
        const end = formatTimestamp({
          format: isSameMonth ? "DD" : "M/DD",
          timestamp: createTimestamp(d.completeAt || new Date())
        });
        return isCurrentSprint ? "this Sprint" : `${start}-${end}`;
      }),
      lineColor: currentTheme.colors.all.wolverine,
      labels: {
        enabled: true,
        style: {
          color: currentTheme.colors.all.wolverine,
          fontFamily: currentTheme.fonts.subheader.name,
          fontWeight: currentTheme.fonts.subheader.weights.bold
        },
        y: 20
      }
    },
    yAxis: {
      min: 0,
      max: 105,
      endOnTick: false,
      plotBands: Object.entries(healthScoreRanges).map(([key, range]) => {
        return {
          color: hexToRgb({
            hex: currentTheme.colors.signalHealth[key],
            opacity: 0.2
          }),
          label: {
            text: startCase(key.toLowerCase()),
            verticalAlign: "bottom",
            y: -5
          },
          from: range[0],
          to:
            // to adjust for the ranges in constants being exclusive, restrict the top band to 100
            key === healthScoreTypes.EXCELLENT ? range[1] - 1 : range[1]
        };
      }),
      tickPositions: [
        healthScoreRanges.CONCERNING[0],
        healthScoreRanges.OK[0],
        healthScoreRanges.GOOD[0],
        healthScoreRanges.EXCELLENT[0],
        100
      ],
      title: {
        enabled: false
      }
    },
    legend: {
      align: "right",
      enabled: true,
      itemDistance: 30,
      itemStyle: {
        fontWeight: currentTheme.fonts.primary.weights.book
      },
      layout: "horizontal",
      verticalAlign: "bottom",
      symbolHeight: 8,
      symbolWidth: 8,
      symbolPadding: 8
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false
        },
        marker: {
          symbol: "circle"
        },
        states: {
          hover: {
            opacity: 1
          }
        }
      }
    },
    title: {
      text: undefined
    },
    tooltip: {
      useHTML: true,
      enabled: true,
      backgroundColor: null,
      shadow: false,
      borderWidth: 0,
      borderRadius: 0,
      outside: true,
      positioner: function(w: number, h: number, point: any) {
        return getAdjustedTooltipPosition({
          currentPosition: (this as any).getPosition(w, h, point),
          windowScroll: window.scrollY
        });
      },
      formatter: function() {
        return getSprintHealthTooltip((this as any).point);
      }
    },
    series
  };
};
export default config;
