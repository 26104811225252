import React, { createContext } from "react";
import { useUrlParamsContextValues } from "../hooks/useUrlParamsContextValues";
import { UrlParamsInterface } from "../interfaces/url-params";

export const UrlParamsContext = createContext<UrlParamsInterface>(
  {} as UrlParamsInterface
);
export const UrlParamsProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return (
    <UrlParamsContext.Provider value={useUrlParamsContextValues()}>
      {children}
    </UrlParamsContext.Provider>
  );
};
export const UrlParamsConsumer = UrlParamsContext.Consumer;
