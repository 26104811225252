// constants
import {
  pageIds,
  pageMetadata,
  sprintMetadataWorkTypes,
  urlParamKeys,
  workDeepDiveSections,
  workItemCalloutTypes,
  workMetadataActivityTypes,
  workMetadataStatusTypes
} from "../constants/constants";
import { PathHelperOptionsInterface } from "../interfaces/url-params";
import { RetroTotalsInterface } from "../interfaces/work-items";

// utils
import { getPageTitle } from "./router";

export const getRetrosPageTitle = (): string => {
  return getPageTitle(pageMetadata[pageIds.RETROS].title);
};

export const getSprintUpdateTablePathParams = (
  itemKey: keyof RetroTotalsInterface,
  isStrict?: boolean
): PathHelperOptionsInterface => {
  switch (itemKey) {
    case "addedMidSprint":
      let querystringParams = {
        [urlParamKeys.CALLOUTS]: [workItemCalloutTypes.ADDED_MID_SPRINT],
        [urlParamKeys.SHOW_RELATED]: false
      };
      if (!!isStrict) {
        querystringParams = {
          ...querystringParams,
          [urlParamKeys.SPRINT_WORK_TYPES]: [
            sprintMetadataWorkTypes.SPRINT_WORK,
            sprintMetadataWorkTypes.PULL_REQUESTS_NOT_IN_SPRINT,
            sprintMetadataWorkTypes.REMOVED_FROM_SPRINT
          ]
        };
      }
      return {
        querystringParams,
        selectedSection: workDeepDiveSections.PROJECT_WORK
      };
    case "addedMidSprintInSprint":
      return {
        querystringParams: {
          [urlParamKeys.CALLOUTS]: [workItemCalloutTypes.ADDED_MID_SPRINT],
          [urlParamKeys.SHOW_RELATED]: false
        },
        selectedSection: workDeepDiveSections.PROJECT_WORK
      };
    case "addedMidSprintRemoved":
      return {
        querystringParams: {
          [urlParamKeys.CALLOUTS]: [workItemCalloutTypes.ADDED_MID_SPRINT],
          [urlParamKeys.SHOW_RELATED]: false,
          [urlParamKeys.SPRINT_WORK_TYPES]: [
            sprintMetadataWorkTypes.PULL_REQUESTS_NOT_IN_SPRINT,
            sprintMetadataWorkTypes.REMOVED_FROM_SPRINT
          ]
        },
        selectedSection: workDeepDiveSections.PROJECT_WORK
      };
    case "notCompleted":
      return {
        querystringParams: {
          [urlParamKeys.STATUS]: [
            workMetadataStatusTypes.IN_PROGRESS,
            workMetadataStatusTypes.OPEN
          ],
          [urlParamKeys.SHOW_RELATED]: false,
          [urlParamKeys.SPRINT_WORK_TYPES]: [
            sprintMetadataWorkTypes.SPRINT_WORK
          ],
          [urlParamKeys.WORK_ITEM_TYPES]: [
            workMetadataActivityTypes.BUG,
            workMetadataActivityTypes.ISSUE,
            workMetadataActivityTypes.SUB_TASK
          ]
        },
        selectedSection: workDeepDiveSections.PROJECT_WORK
      };
    case "removedMidSprint":
      return {
        querystringParams: {
          [urlParamKeys.SPRINT_WORK_TYPES]: [
            sprintMetadataWorkTypes.REMOVED_FROM_SPRINT
          ],
          [urlParamKeys.SHOW_RELATED]: false,
          [urlParamKeys.WORK_ITEM_TYPES]: [
            workMetadataActivityTypes.BUG,
            workMetadataActivityTypes.ISSUE,
            workMetadataActivityTypes.SUB_TASK
          ]
        },
        selectedSection: workDeepDiveSections.PROJECT_WORK
      };
    case "carryOverFromPreviousSprint":
      return {
        querystringParams: {
          [urlParamKeys.CALLOUTS]: [workItemCalloutTypes.OPEN_MULTIPLE_SPRINTS],
          [urlParamKeys.SHOW_RELATED]: false
        },
        selectedSection: workDeepDiveSections.PROJECT_WORK
      };
    case "unestimatedItems":
      return {
        querystringParams: {
          [urlParamKeys.CALLOUTS]: [workItemCalloutTypes.NULL_STORY_POINTS],
          [urlParamKeys.SHOW_RELATED]: false,
          [urlParamKeys.WORK_ITEM_TYPES]: [
            workMetadataActivityTypes.ISSUE,
            workMetadataActivityTypes.SUB_TASK
          ]
        },
        selectedSection: workDeepDiveSections.PROJECT_WORK
      };
    case "completedOutsideSprint":
      return {
        querystringParams: {
          [urlParamKeys.SPRINT_WORK_TYPES]: [
            sprintMetadataWorkTypes.COMPLETED_OUTSIDE_SPRINT
          ],
          [urlParamKeys.SHOW_RELATED]: false,
          [urlParamKeys.WORK_ITEM_TYPES]: [
            workMetadataActivityTypes.BUG,
            workMetadataActivityTypes.ISSUE,
            workMetadataActivityTypes.SUB_TASK
          ]
        },
        selectedSection: workDeepDiveSections.PROJECT_WORK
      };
    default:
      return {};
      break;
  }
};
