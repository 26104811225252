import React, { useState } from "react";

// components
import DefaultAvatar from "../DefaultAvatar/DefaultAvatar";
import Image from "../Image/Image";

// typescript props
type Props = {
  altText?: string;
  className?: string;
  src: string | null;
  testId?: string;
};

const SlackAvatar = ({
  altText = "slack avatar",
  className,
  src,
  testId = "testId"
}: Props) => {
  const [hasError, setHasError] = useState(false);
  return hasError || !src ? (
    <DefaultAvatar className={className} testId={`${testId}-slack-avatar`} />
  ) : (
    <Image
      altText={altText}
      className={className}
      onError={() => setHasError(true)}
      src={src}
      testId={`${testId}-slack-avatar`}
    />
  );
};

export default SlackAvatar;
