import api, { validationHandler } from "./api";
import moment from "moment";

import {
  compact,
  findIndex,
  flattenDeep,
  groupBy as lodashGroupBy,
  invert,
  isNil,
  partition,
  union,
  uniq,
  pick,
  mapKeys,
  groupBy,
  mapValues,
  flattenDepth
} from "lodash";

// interfaces
import {
  OrgByTime,
  OrgByTimeItems,
  OrgByTimePoints,
  OrgDeepWorkByUser,
  OrgDeepWorkByUserDataRecord,
  OrgDeepWorkByUserDataRecordWithNulls,
  OrgDeepWorkGroups,
  OrgTeamItems,
  OrgTeams,
  OrgTimeRange,
  OrgTimeRanges
} from "../interfaces/organization";
import { UserReportWithUplevelRole } from "../interfaces/user";
import { UrlParamsInterface } from "../interfaces/url-params";
import { AxiosResponse } from "axios";
import { zOrgByTime, zOrgByUser } from "../interfaces/zod-schema";

// utils
import { createTimestampWithOffset } from "./date";

// constants
import {
  groupByTypes,
  managerRoles,
  momentUnitTypes,
  namedTimerangeIds,
  roleTypes
} from "../constants/constants";
const invertedRoleTypes = invert(roleTypes);

export const requestOrganizationTimeRanges = async ({
  accessToken,
  tenantId
}: {
  accessToken: string;
  tenantId: number;
}): Promise<unknown> => {
  const response = await api.get(`/${tenantId}/org/time-ranges/year`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const requestOrganizationDeepWorkData = async ({
  accessToken,
  endDate,
  tenantId,
  type,
  userIds
}: {
  accessToken: string;
  endDate?: string;
  tenantId: number;
  type: "year" | typeof namedTimerangeIds["PREVIOUS_14_DAYS"];
  userIds?: Array<number>;
}): Promise<OrgByTime | OrgDeepWorkByUser> => {
  const url = `/${tenantId}/org/deep-work/${
    type === namedTimerangeIds["PREVIOUS_14_DAYS"] ? "by-user" : "year"
  }`;

  let params: { endDate?: string; userIds?: string } = {
    endDate: createTimestampWithOffset(endDate)
  };
  if (type === "year" && !!userIds?.length) {
    params = {
      ...params,
      userIds: userIds?.join(",")
    };
  }

  if (type === "year") {
    return api
      .post<OrgByTime>(url, params, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then(response => validationHandler<OrgByTime>(zOrgByTime, response))
      .catch(e => Promise.reject(e));
  }
  return api
    .post<OrgDeepWorkByUser>(url, params, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
    .then(response =>
      validationHandler<OrgDeepWorkByUser>(zOrgByUser, response)
    )
    .catch(e => Promise.reject(e));
};

export const requestOrganizationReleaseFrequencyData = async ({
  accessToken,
  endDate,
  tenantId,
  userIds
}: {
  accessToken: string;
  endDate?: string;
  tenantId: number;
  userIds?: Array<number>;
}): Promise<OrgByTime> => {
  const url = `/${tenantId}/org/release-frequency/year`;

  let params: { endDate?: string; userIds?: string } = {
    endDate: createTimestampWithOffset(endDate)
  };
  if (!!userIds?.length) {
    params = {
      ...params,
      userIds: userIds?.join(",")
    };
  }
  return api
    .post<OrgByTime>(url, params, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
    .then(response => validationHandler<OrgByTime>(zOrgByTime, response))
    .catch(e => Promise.reject(e));
};

export const requestOrganizationCycleTimeData = async ({
  accessToken,
  endDate,
  tenantId,
  userIds
}: {
  accessToken: string;
  endDate?: string;
  tenantId: number;
  userIds?: Array<number>;
}): Promise<OrgByTime> => {
  const url = `/${tenantId}/org/cycle-time/year`;

  let params: { endDate?: string; userIds?: string } = {
    endDate: createTimestampWithOffset(endDate)
  };
  if (!!userIds?.length) {
    params = {
      ...params,
      userIds: userIds?.join(",")
    };
  }
  return api
    .post<OrgByTime>(url, params, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
    .then(response => validationHandler<OrgByTime>(zOrgByTime, response))
    .catch(e => Promise.reject(e));
};

export const requestOrganizationTeamsData = async ({
  accessToken,
  params,
  tenantId
}: {
  accessToken: string;
  params: {
    includeFlexTeams: boolean;
  };
  tenantId: number;
}): Promise<OrgTeams> => {
  const response: AxiosResponse<OrgTeams> = await api.post<OrgTeams>(
    `/${tenantId}/teams`,
    params,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return response.data;
};

export const getRoleTypesFromUsers = (
  fullOrganizationData: UserReportWithUplevelRole[]
): Array<string> =>
  uniq(
    compact(fullOrganizationData.map(d => invertedRoleTypes[d.uplevel_role]))
  );

export const getReportGroupsFromUsers = (
  fullOrganizationData: UserReportWithUplevelRole[]
): Array<string> => uniq(compact(fullOrganizationData.map(d => d.reportGroup)));

export const getFilteredTeamMembersMetadata = ({
  allUsers,
  data,
  groupBy,
  teams
}: {
  allUsers: UserReportWithUplevelRole[];
  data: OrgDeepWorkByUser;
  groupBy: string;
  teams: OrgTeamItems;
}): Array<OrgDeepWorkByUserDataRecord> =>
  flattenDeep(
    compact(
      teams
        .filter(team => {
          return groupBy === groupByTypes.FLEX_TEAM
            ? !team.isOrgChartTeam
            : team.isOrgChartTeam;
        })
        .map(team => {
          const teamMembersValues: Array<OrgDeepWorkByUserDataRecord> = compact(
            team.teamMemberUplevelUserIds.map(
              (userId): OrgDeepWorkByUserDataRecord | null => {
                const value = data?.data.find(
                  (record: OrgDeepWorkByUserDataRecordWithNulls): boolean =>
                    record.userId === userId
                )?.value;
                const userData = allUsers?.find(
                  (record: UserReportWithUplevelRole): boolean =>
                    record.id === userId
                );

                // if this team is a flex team and we couldn't assign a team
                // lead to it before, find this user's org chart team, get their
                // managers id and then use it to find the manager's user
                // metadata
                let teamLeadsName;
                if (isNil(team.teamLeadsName)) {
                  const teamLead = allUsers.find(
                    t =>
                      t.id ===
                      teams.find(
                        t =>
                          t.isOrgChartTeam &&
                          t.teamMemberUplevelUserIds.includes(userId)
                      )?.orgChartTeamLeadId
                  );
                  teamLeadsName = teamLead?.name || "None";
                } else {
                  teamLeadsName = team.teamLeadsName;
                }

                // filter out falsy values
                return isNil(value) || isNil(userData)
                  ? null
                  : {
                      userId,
                      value,
                      role: isNil(userData?.uplevel_role)
                        ? "None"
                        : userData?.uplevel_role,
                      reportGroup: isNil(userData?.reportGroup)
                        ? "None"
                        : userData.reportGroup,
                      teamId: team.teamId,
                      teamName: team.teamName,
                      teamLeadsName
                    };
              }
            )
          );
          // filter out teams where all values are null
          return !!teamMembersValues.length ? teamMembersValues : null;
        })
    )
  );

export const getOrgDeepWorkByGroup = ({
  allUsers,
  data,
  groupBy,
  teams,
  urlParamsTeams,
  userIds
}: {
  allUsers: UserReportWithUplevelRole[];
  userIds: Array<number>;
  teams: OrgTeamItems;
  data: OrgDeepWorkByUser;
  groupBy: UrlParamsInterface["groupBy"];
  urlParamsTeams: UrlParamsInterface["teams"];
}): OrgDeepWorkGroups => {
  let groupProperty;
  switch (groupBy) {
    case groupByTypes.FLEX_TEAM:
      groupProperty = "teamId";
      break;
    case groupByTypes.MANAGER:
      groupProperty = "teamLeadsName";
      break;
    case groupByTypes.REPORT_GROUP:
      groupProperty = "reportGroup";
      break;
    // groupByTypes.ROLE
    default:
      groupProperty = "role";
      break;
  }
  const truthyTeamMemberMetadata = getFilteredTeamMembersMetadata({
    allUsers,
    data,
    groupBy,
    teams
  });
  const filteredTeamMembers = truthyTeamMemberMetadata.filter(t =>
    userIds.includes(t.userId)
  );

  const groupedTeamMembers = lodashGroupBy(filteredTeamMembers, groupProperty);

  // if the grouping is by flex team, use the urlParamsTeams array to filter
  // only the matching uuids out of the grouped teams, and then remap the keys
  // with the flex team's name
  return groupBy === groupByTypes.FLEX_TEAM
    ? mapKeys(
        !!urlParamsTeams.length
          ? pick(groupedTeamMembers, urlParamsTeams)
          : groupedTeamMembers,
        (v, k) => teams.find(t => k === t.teamId)?.teamName
      )
    : groupedTeamMembers;
};

export const getOrganizationIcTeamMembers = (
  fullOrganizationData: Array<UserReportWithUplevelRole>
): Array<UserReportWithUplevelRole> => {
  return fullOrganizationData.filter(
    d => !!d.uplevel_role && !managerRoles.includes(d.uplevel_role)
  );
};

export const getFilteredOrganizationUserIds = ({
  organizationIcTeamMembers,
  organizationTeamsData,
  urlParams
}: {
  organizationIcTeamMembers: Array<UserReportWithUplevelRole>;
  organizationTeamsData: OrgTeams;
  urlParams: UrlParamsInterface;
}): Array<number> => {
  const roles = urlParams.roles;
  const reportGroups = urlParams.reportGroups;
  const teams = urlParams.teams;

  if (!roles.length && !teams.length && !reportGroups.length) {
    return organizationIcTeamMembers.map(d => d.id);
  }

  let unionOrgTeamUserIds: Array<number> = [];
  let unionFlexTeamUserIds: Array<number> = [];
  if (!!teams.length) {
    const [filteredOrgTeamUserIds, filteredFlexTeamUserIds] = partition(
      organizationTeamsData,
      t => t.isOrgChartTeam
    ).map(teamsList =>
      teamsList
        .filter(t => teams.includes(t.teamId))
        .map(t => t.teamMemberUplevelUserIds)
    );

    // pass the arrays of userids as arguments
    unionOrgTeamUserIds = union(...filteredOrgTeamUserIds);
    unionFlexTeamUserIds = union(...filteredFlexTeamUserIds);
  }

  return organizationIcTeamMembers
    .filter(d => {
      let result = true;
      if (!!roles.length) {
        result = result && roles.includes(invertedRoleTypes[d.uplevel_role]);
      }
      if (!!reportGroups.length && d.reportGroup) {
        result = result && reportGroups.includes(d.reportGroup);
      }
      if (!!unionOrgTeamUserIds.length) {
        result = result && unionOrgTeamUserIds.includes(d.id);
      }
      if (!!unionFlexTeamUserIds.length) {
        result = result && unionFlexTeamUserIds.includes(d.id);
      }
      return result;
    })
    .map(d => d.id);
};

export const getExtendedTeamsWithNames = (
  fullOrganizationData: Array<UserReportWithUplevelRole>,
  organizationTeamsData: OrgTeams,
  organizationIcTeamMembers: Array<UserReportWithUplevelRole>
): OrgTeamItems => {
  const organizationIcTeamMemberIds = organizationIcTeamMembers.map(t => t.id);
  const extendedTeams = organizationTeamsData
    .map(team => {
      const teamLead = fullOrganizationData.find(
        d => d.id === team.orgChartTeamLeadId
      );
      const icUserIds = team.teamMemberUplevelUserIds.filter(id =>
        organizationIcTeamMemberIds.includes(id)
      );
      const teamName = team.isOrgChartTeam
        ? teamLead?.name || (team.orgChartTeamLeadId as number).toString()
        : (team.teamName as string);
      const teamLeadsName = team.isOrgChartTeam ? teamName : null;
      return {
        ...team,
        teamLeadsName,
        teamName,
        teamMemberUplevelUserIds: icUserIds
      };
    })
    .filter(t => !!t.teamMemberUplevelUserIds.length);
  return dedupTeamNames(extendedTeams);
};

export const dedupTeamNames = (teams: OrgTeamItems): OrgTeamItems => {
  const dedupedTeamNames = mapValues(groupBy(teams, "teamName"), teams => {
    // if more than one team is grouped, the name is duplicate
    if (teams.length > 1) {
      return teams.map((t, index) => ({
        ...t,
        // for each duped name past the first, append " (num)" to the name
        teamName: `${t.teamName}${!!index ? ` (${index})` : ""}`
      }));
    }
    return teams;
  });
  // re-flatten teams into array
  return flattenDepth(Object.values(dedupedTeamNames), 1);
};

export const getMappedSummaryData = (
  data: OrgByTime,
  orgTimeRanges: OrgTimeRanges
): OrgByTimeItems => {
  return Object.keys(data)
    .sort()
    .map(k => {
      const startDate = moment(k).format("YYYY-MM-DD");
      const endDate =
        orgTimeRanges.find(o => o.startDate === startDate)?.endDate ||
        moment(k)
          .add(14, momentUnitTypes.DAYS)
          .format("YYYY-MM-DD");
      return {
        endDate,
        includesFutureDates: moment().isBefore(endDate),
        startDate: startDate,
        value: data[k]
      };
    });
};

export const getDisplayTrendlinePoints = (
  points: OrgByTimeItems,
  selectedTimeRange?: OrgTimeRange
): OrgByTimePoints => {
  if (isNil(selectedTimeRange)) return [];
  const selectedIndex = findIndex(
    points,
    d => d.startDate === selectedTimeRange.startDate
  );
  const previousIndex = findIndex(
    points,
    d =>
      d.startDate ===
      moment(selectedTimeRange.startDate)
        .subtract(14, momentUnitTypes.DAYS)
        .format("YYYY-MM-DD")
  );
  return isNil(selectedIndex)
    ? isNil(previousIndex)
      ? []
      : [points[previousIndex]]
    : [points[previousIndex], points[selectedIndex]];
};
