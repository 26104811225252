import React from "react";
import styled from "styled-components/macro";

// styled components
const Container = styled.div`
  padding: 0 ${props => props.theme.grid.gridOffset};
`;

// typescript props
type Props = {
  children: React.ReactNode;
  className?: string;
  testId?: string;
};

const UiCenteringWrapper = ({
  children,
  className,
  testId = "testId"
}: Props) => {
  return (
    <Container
      className={className}
      data-testid={`${testId}-centering-wrapper`}
    >
      {children}
    </Container>
  );
};

export default UiCenteringWrapper;
