import React, { ReactElement, useEffect } from "react";
import { get } from "lodash";

// components
import { Redirect } from "react-router-dom";
import ReportsBaseTemplate from "../ReportsBaseTemplate/ReportsBaseTemplate";

// hooks
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

// utils
import { getReportsPageTitle } from "../../utils/reports";

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const ReportsPage = ({ className, testId = "testId" }: Props): ReactElement => {
  const thisTestId = `${testId}-reports-page`;
  const location = useLocation();
  const hasReports = useSelector(
    (state: AppStateInterface) => !!get(state, "flags.reports")
  );

  // page title change
  useEffect(() => {
    document.title = getReportsPageTitle();
  }, [location]);

  return hasReports ? (
    <div className={className} data-testid={thisTestId}>
      <ReportsBaseTemplate testId={thisTestId} />
    </div>
  ) : (
    <Redirect to={`/`} />
  );
};

export default ReportsPage;
