import React, { useEffect, useState } from "react";

// components
import { Auth0Provider } from "@auth0/auth0-react";
import { Route, Switch } from "react-router-dom";
import Auth0UiReadyWrapper from "../Auth0UiReadyWrapper/Auth0UiReadyWrapper";
import SecureRoute from "../SecureRoute/SecureRoute";
import DashboardPage from "../DashboardPage/DashboardPage";
import { ErrorBoundary } from "@sentry/react";
import ScrollManagement from "../ScrollManagement/ScrollManagement";
import UplevelUi from "../UplevelUi/UplevelUi";

// containers
import UnknownUser from "../../containers/UnknownUser/UnknownUser";

// context
import { UrlParamsProvider } from "../../context/url-params";

// hooks
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

const Auth0Wrapper = () => {
  const history = useHistory();
  const { trackEvent } = useTracking();
  const [preAuth0RedirectUrl, setPreAuth0RedirectUrl] = useState<string | null>(
    null
  );
  const [hasRedirected, setHasRedirected] = useState(false);
  const [hasSentEvent, setHasSentEvent] = useState(false);
  const auth = useSelector((state: AppStateInterface) => state.auth);
  const authParams = auth?.authParams;
  const session = useSelector((state: AppStateInterface) => state.session);
  const sessionUsername = session?.username;

  // fire analytics event when fully populated session params are available on auth0 redirect
  useEffect(() => {
    if (
      !hasSentEvent &&
      hasRedirected &&
      !!preAuth0RedirectUrl &&
      !!sessionUsername &&
      sessionUsername !== "unauthenticated-user"
    ) {
      trackEvent({
        e: {
          type: "auth"
        },
        label: "auth0-login-return",
        value: `returned to ${preAuth0RedirectUrl}`
      });
      setHasSentEvent(true);
    }
  }, [
    hasRedirected,
    hasSentEvent,
    preAuth0RedirectUrl,
    sessionUsername,
    trackEvent
  ]);

  const onRedirectCallback = (appState: any) => {
    const originalUri =
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname;
    history.push(originalUri);
    setHasRedirected(true);
    setPreAuth0RedirectUrl(originalUri);
  };

  const providerConfig = {
    domain: authParams.domain as string,
    clientId: authParams.clientId,
    audience: authParams.audience,
    redirectUri: window.location.origin,
    onRedirectCallback
  };

  return (
    <Auth0Provider {...providerConfig}>
      <ErrorBoundary>
        <UrlParamsProvider>
          <ScrollManagement>
            <UplevelUi testId="client-dashboard">
              <Switch>
                <Route exact path="/unknown-user">
                  <UnknownUser />
                </Route>
                <Route path="/:pageId?">
                  {/*
                        the way auth0 recommends building the secure route (here:
                        https://auth0.com/blog/complete-guide-to-react-user-authentication/)
                        makes it so that the component passed to it doesn't seem to
                        be stably mounted, which means our initial data load needs
                        to be outside it so that everything doesn't get reinitialized
                        every time the hook is run
                  */}
                  <Auth0UiReadyWrapper hasRedirected={hasRedirected}>
                    <SecureRoute component={DashboardPage} />
                  </Auth0UiReadyWrapper>
                </Route>
              </Switch>
            </UplevelUi>
          </ScrollManagement>
        </UrlParamsProvider>
      </ErrorBoundary>
    </Auth0Provider>
  );
};

export default Auth0Wrapper;
