import React from "react";
import PropTypes from "prop-types";

// utils
import { formatTimestamp } from "../../utils/date";

const Time = ({ format = "ddd. MMM. Do h:mm A", testId, timestamp }) => {
  if (!testId || !timestamp) {
    return null;
  }

  return (
    <time dateTime={timestamp}>{formatTimestamp({ format, timestamp })}</time>
  );
};

Time.propTypes = {
  testId: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired
};
export default Time;
