import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { isEmpty, debounce, union, sum } from "lodash";

// components
import EpicFilterCheckboxList from "../EpicFilterCheckboxList/EpicFilterCheckboxList";
import InfoIcon from "../InfoIcon/InfoIcon";

// constants
import {
  urlParamKeys,
  pullRequestCalloutTypes,
  workItemCalloutTypes,
  PullRequestStalledOptions,
  urlParamsValuesLabels
} from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";
import {
  EpicDetailsInterface,
  GroupsListInterface,
  ResponseTotalInterface
} from "../../interfaces/work-items";
import {
  UrlParamsInterface,
  UrlParamsHookReturnInterface
} from "../../interfaces/url-params";

// styled components
const Option = styled.span`
  align-items: center;
  display: flex;
`;

// typescript props
type Props = {
  callouts: UrlParamsInterface["callouts"];
  className?: string;
  testId?: string;
  updateUrlParams: UrlParamsHookReturnInterface["updateUrlParams"];
  workItemsData?: EpicDetailsInterface | GroupsListInterface;
};

const PullRequestCalloutsFilter = ({
  callouts,
  className,
  testId = "testId",
  updateUrlParams,
  workItemsData
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-pull-request-callouts-filter`;
  const { trackEvent } = useTracking();
  const [areFiltersDisabled, setAreFiltersDisabled] = useState(false);
  const sprintCallouts = callouts.filter((c: string) =>
    Object.values(workItemCalloutTypes).includes(c)
  );
  const calloutTotals = workItemsData?.calloutTotals;
  const enabledCalloutIds = calloutTotals?.map(c => c.callout) as string[];
  const optionsList = ([
    {
      helpTextKey: "stuck",
      id: pullRequestCalloutTypes.STUCK
    },
    {
      helpTextKey: "pr-callout-merged-no-approval",
      id: pullRequestCalloutTypes.PULL_REQUEST_MERGED_WITHOUT_APPROVAL
    },
    {
      helpTextKey: "pr-callout-surprising",
      id: pullRequestCalloutTypes.LOW_COMPLEXITY_HIGH_COMMENTS
    }
  ] as Array<{
    name: string;
    helpTextKey:
      | "stuck"
      | "pr-callout-merged-no-approval"
      | "pr-callout-surprising";
    id: string;
  }>)
    .filter(
      c =>
        enabledCalloutIds?.includes(c.id) ||
        (c.id === pullRequestCalloutTypes.STUCK &&
          enabledCalloutIds?.find(i => i.startsWith("STALLED")))
    )
    .map(m => {
      const displayTotal =
        m.id === pullRequestCalloutTypes.STUCK
          ? sum(
              calloutTotals
                ?.filter((t: ResponseTotalInterface) =>
                  PullRequestStalledOptions.map(option => option.id).includes(
                    t.callout as string
                  )
                )
                ?.map((t: ResponseTotalInterface) => t.total)
            ) || 0
          : calloutTotals?.find(
              (t: ResponseTotalInterface) => t.callout === m.id
            )?.total || 0;
      return {
        id: m.id,
        isDisabled: areFiltersDisabled,
        isSelected: callouts.includes(m.id),
        name: (
          <Option>
            {urlParamsValuesLabels[m.id]} ({displayTotal}){" "}
            <InfoIcon
              content={m.helpTextKey}
              testId={`${thisTestId}-${m.id}`}
            />
          </Option>
        )
      };
    });

  // fixing ts error with this:
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedToggle = useMemo(
    () =>
      debounce(updatedTypes => {
        updateUrlParams({
          [urlParamKeys.CALLOUTS]: updatedTypes
        });
        setAreFiltersDisabled(false);
      }, 500),
    [updateUrlParams]
  );

  function onToggleType(
    updated: Array<UiFilterSelectionStatusInterface>,
    retainedCallouts: Array<string>
  ) {
    const updatedTypes = updated
      .filter((u: UiFilterSelectionStatusInterface) => u.isSelected)
      .map((s: UiFilterSelectionStatusInterface) => s.id as string);

    setAreFiltersDisabled(true);
    debouncedToggle(union(updatedTypes, retainedCallouts));

    trackEvent({
      label: "ui-filter-pull-request-callouts-change",
      value: `changed pull request callouts to ${(isEmpty(updatedTypes)
        ? Object.values(pullRequestCalloutTypes).filter(
            t => !t.startsWith("STALLED")
          )
        : updatedTypes
      ).join(",")}`
    });
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <EpicFilterCheckboxList
        header="PR Callouts"
        list={optionsList}
        name="pull-request-callouts"
        onUpdateSelection={updated => onToggleType(updated, sprintCallouts)}
        testId="pull-request-callouts"
      />
    </div>
  );
};

export default PullRequestCalloutsFilter;
