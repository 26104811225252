import { farsApi } from "../utils/api";
import * as Sentry from "@sentry/react";

export const sendEvent = async ({ accessToken, farsUrl, params, tenantId }) => {
  if (!farsUrl || !accessToken) {
    return null;
  }
  try {
    await farsApi.put(`/${tenantId}/submit`, params, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};
