import { AxiosResponse } from "axios";
import api from "./api";
import {
  teamDeepDiveSectionLabels,
  teamDeepDiveSections,
  healthFactorsMetadata,
  pageIds,
  pageMetadata
} from "../constants/constants";
import { getPageTitle } from "./router";
import {
  DeepDiveData,
  PeopleMetrics,
  RawDeepDiveData,
  UserDeepDiveData
} from "../interfaces/team-deep-dive";
import { mapKeys } from "lodash";

// typescript props
type RequestProps = {
  accessToken: string;
  tenantId: number;
  params: {
    users: number[];
    endDate: string;
    endSprintId?: string;
    teamId?: string;
  };
};

export const requestTeamDeepDiveData = async ({
  accessToken,
  tenantId,
  params
}: RequestProps): Promise<RawDeepDiveData> => {
  const response: AxiosResponse<RawDeepDiveData> = await api.get<
    RawDeepDiveData
  >(`/${tenantId}/health/people`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      users: params.users.join(","),
      endDate: params.endDate,
      teamId: params.teamId,
      endSprintId: params.endSprintId
    }
  });
  return response.data;
};

export const getTeamDeepDivePageTitle = (metric: string): string => {
  // this is the OVERALL_TEAM_HEALTH TDD metric, not the TDD section
  const metricTitle =
    metric === "OVERALL_TEAM_HEALTH"
      ? teamDeepDiveSectionLabels[teamDeepDiveSections.PEOPLE_HEALTH]
      : healthFactorsMetadata[metric]?.title;
  const teamDeepDiveTitle = pageMetadata[pageIds.TEAM_DEEP_DIVE].title;
  return getPageTitle(
    `${!!metricTitle ? `${metricTitle} - ` : ""}${teamDeepDiveTitle}`
  );
};

export const transformTeamDeepDiveData = (
  data: RawDeepDiveData
): DeepDiveData => {
  const transformHandler = (value: any, key: string) =>
    key.replace(/SLACK/, "CHAT");
  return data.map(d => {
    return {
      ...d,
      teamScores: mapKeys(d.teamScores, transformHandler) as Record<
        PeopleMetrics,
        number
      >,
      peopleScores: d.peopleScores.map(scores => {
        return mapKeys(
          {
            ...scores,
            CONTEXT_SWITCHING: mapKeys(
              scores.CONTEXT_SWITCHING,
              transformHandler
            )
          },
          transformHandler
        );
      }) as Array<UserDeepDiveData>
    };
  });
};
