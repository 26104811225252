import React from "react";
import { isEmpty, lowerCase } from "lodash";
import styled from "styled-components";
import {
  DeepDiveDataRecord,
  PeopleMetrics
} from "../../interfaces/team-deep-dive";
import { UserReportWithMetadataInterface } from "../../interfaces/user";
type Props = {
  testId?: string;
  className?: string;
  metricTitle: PeopleMetrics;
  mostRecentData?: DeepDiveDataRecord;
  chartView: UserReportWithMetadataInterface;
};

const SignalNotInDropdown = styled.div<{ healthy: boolean }>`
  margin-bottom: 0.5rem;
  width: 100%;
  padding: 1rem 0 1rem 1rem;
  background-color: ${props => `${props.theme.colors.all.white}`};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  box-shadow: ${props =>
    `inset 5px 0px ${
      props.healthy
        ? props.theme.colors.all.marvelMint
        : props.theme.colors.all.rogue
    }`};
`;
export const MetricTileDeepWorkDetails = ({
  mostRecentData,
  chartView,
  testId = "testId"
}: Props): JSX.Element | null => {
  const userScores = mostRecentData?.peopleScores.find(
    record => record.user === chartView.id
  );

  if (isEmpty(userScores?.DEEP_WORK?.CALLOUT_DETAILS)) {
    return null;
  }

  return (
    <SignalNotInDropdown healthy={false}>{`${
      chartView.name
    } has low deep work due to ${Object.values(
      userScores?.["DEEP_WORK"]["CALLOUT_DETAILS"] || {}
    ).map((submetricValue, submetricIndex) => {
      return Object.keys(submetricValue?.["DETAILS"] || {})
        .map(key => {
          return lowerCase(key === "MINUTES" ? "CHAT_INTERRUPTIONS" : key);
        })
        .reduce((acc, curr, index, array) => {
          return `${acc}${
            index === 0 && submetricIndex === 0
              ? ""
              : index === array.length - 1 &&
                submetricIndex ===
                  Object.values(
                    userScores?.["DEEP_WORK"]["CALLOUT_DETAILS"] || {}
                  ).length -
                    1
              ? " and "
              : ", "
          }${curr}`;
        }, "");
    })}.`}</SignalNotInDropdown>
  );
};

export default MetricTileDeepWorkDetails;
