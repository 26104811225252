export const actionTypes = [
  "STORE_AUTH_ACCESS_TOKEN",
  "STORE_AUTH_PARAMS",
  "STORE_FLAGS",
  "STORE_ORGANIZATION",
  "STORE_SESSION_LOGIN_TIMESTAMP",
  "STORE_SESSION_PARAMS",
  "STORE_SESSION_REFERRER",
  "STORE_SESSION_UUID",
  "STORE_USER",
  "TRACK_EVENT"
].reduce((result, key) => {
  result[key] = key.toLowerCase();
  return result;
}, {});
