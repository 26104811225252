import { merge } from "lodash";

export default ({ config, theme }) => {
  return merge(
    {},
    {
      chart: {
        type: "spline",
        style: {
          fontFamily: theme.fonts.subheader.name
        }
      },
      title: null,
      xAxis: {
        labels: {
          style: {
            color: theme.colors.text.body
          }
        }
      },
      yAxis: {
        labels: {
          style: {
            color: theme.colors.text.body
          }
        }
      },
      plotOptions: {
        spline: {
          connectNulls: true,
          lineWidth: 3,
          marker: {
            enabled: true,
            lineWidth: 3,
            radius: 7.5,
            states: {
              hover: {
                radius: 15,
                lineWidth: 4
              },
              select: {
                radius: 20,
                lineWidth: 4
              }
            }
          }
        },
        series: {
          allowPointSelect: true,
          states: { hover: { lineWidth: 3 } }
        }
      }
    },
    config
  );
};
