import React from "react";
import styled from "styled-components/macro";

// interfaces
import { TimerangeMetadataInterface } from "../../interfaces/constants";

// styled components
const Container = styled.span`
  display: block;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  timerange: TimerangeMetadataInterface;
};

const TimeRangeSelectorLabel = ({
  className,
  testId = "testId",
  timerange
}: Props) => {
  const thisTestId = `${testId}-time-range-selector-label`;
  return (
    <Container className={className} data-testid={thisTestId}>
      {timerange.displayName}
    </Container>
  );
};

export default TimeRangeSelectorLabel;
