import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { flatten, get, isEmpty, isUndefined } from "lodash";

// components
import ChartMeetingTime from "../ChartMeetingTime/ChartMeetingTime";
import EmptyState from "../EmptyState/EmptyState";
import LoadingStates from "../LoadingStates/LoadingStates";
import MeetingListSummary from "../MeetingListSummary/MeetingListSummary";

// constants
import meetingClassifications from "../../constants/meetingClassifications.json";

// utils
import { useMeetingLengthData } from "../../hooks/useMeetingLengthData";
import { useUrlParams } from "../../hooks/useUrlParams";
import { useSelector } from "react-redux";
import ChartMeetingClassifications from "../ChartMeetingClassifications/ChartMeetingClassifications";
import InfoPopups from "../InfoPopups/InfoPopups";

// styled components
const Figure = styled.figure`
  width: 100%;
`;
const Caption = styled.figcaption`
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const Title = styled.div`
  display: flex;
`;

export const MeetingTimeChartWrapper = ({
  className = "",
  selectedUser,
  testId = "testId",
  user
}) => {
  const thisTestId = `${testId}-meeting-time-chart-wrapper`;
  const [selectedDate, setSelectedDate] = useState(null);
  const [overrideOption, setOverrideOption] = useState(null);
  const flags = useSelector(state => state.flags);
  const canViewTeamData = flags?.["transparent-data-for-peers"];

  const { urlParams, teamDeepDiveParams } = useUrlParams();
  const teamMembers = urlParams.team?.teamMembers;
  const selectedTeamMember = teamMembers?.find(t => t.email === selectedUser);

  const {
    data: meetingLengthData,
    isLoading: isFetchingData,
    isError
  } = useMeetingLengthData({
    canViewTeamData,
    override: overrideOption,
    selectedUser,
    teamMembers
  });

  function handleOverride(newValue) {
    setOverrideOption(newValue);
  }

  // memoing this guy in case creating the empty array in the component here
  // was causing unnecessary rerenders
  const meetingTimeList = useMemo(() => {
    if (isEmpty(meetingLengthData)) {
      return [];
    }
    return (selectedDate
      ? meetingLengthData.userValues.find(m => m.date === selectedDate).metadata
      : flatten(meetingLengthData.userValues.map(r => r.metadata))
    ).map(m => ({
      ...m,
      classificationName: get(
        meetingClassifications.find(c => c.value === m.measure),
        "label",
        "Unknown classification"
      ),
      timerange: {
        end: m.endTime,
        start: m.startTime
      }
    }));
  }, [meetingLengthData, selectedDate]);

  return (
    <div className={className} data-testid={thisTestId}>
      <Figure>
        <LoadingStates
          isSpinningState={isFetchingData}
          isNoDataState={isError || isUndefined(meetingLengthData)}
          noDataContent={
            <EmptyState
              body={
                <span>
                  It looks like we don&rsquo;t have data for you.{" "}
                  <a href="mailto:support@uplevelteam.com">Contact us</a> if
                  this is a mistake and we&rsquo;ll get this fixed.
                </span>
              }
              header="Uh oh."
              testId={thisTestId}
            />
          }
          content={
            <ChartMeetingTime
              data={meetingLengthData}
              isFetchingData={isFetchingData}
              onClickSeries={setSelectedDate}
              selectedDate={selectedDate}
              testId={thisTestId}
              selectedTeamMember={selectedTeamMember}
              selectedUser={selectedUser}
              canViewTeam={canViewTeamData}
            />
          }
          testId={thisTestId}
        />
        <Title>
          <Caption
            className={!!meetingTimeList.length ? null : "visuallyHidden"}
          >
            Meeting Length
          </Caption>
          <InfoPopups selectedMetric={teamDeepDiveParams.selectedMetric} />
        </Title>
      </Figure>
      {!!meetingTimeList.length ? (
        <MeetingListSummary
          meetings={meetingTimeList}
          handleOverride={handleOverride}
          name="meeting-time-chart"
          testId={thisTestId}
          error={isError}
          overrideOption={overrideOption}
        />
      ) : null}
    </div>
  );
};

MeetingTimeChartWrapper.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string,
  user: PropTypes.object.isRequired
};
export default MeetingTimeChartWrapper;
