import { useEffect, useState } from "react";
import { requestLabsData } from "../../utils/labs";
import { sendEvent } from "../../utils/uplevelAnalytics";
import { useSelector } from "react-redux";
import { get, isEmpty, isUndefined } from "lodash";
import config from "../../env-config";
import { getTimestampForDate } from "../../utils/date";
import { FeedbackParams } from "../../interfaces/labs";
import { captureException, withScope } from "@sentry/react";

type Manifest = {
  experiments: {
    title: string;
    displayIsNew: boolean;
    externalPage: string;
    descriptionHeader: string;
    descriptionBody: string;
    feedbackId: string;
  }[];
};

export const useBackingData: (
  manifest: Manifest,
  manualState?: {
    charts: Array<{ id: string; url: string }>;
    isFetching?: boolean;
    error?: boolean;
    sendFeedback?: (feedBackArgs: FeedbackParams) => Promise<void>;
  }
) => {
  charts: Array<{ id: string; url: string }>;
  isFetching: boolean;
  error: boolean;
  sendFeedback: (feedBackArgs: FeedbackParams) => Promise<void>;
} = (
  manifest: Manifest,
  manualState?: {
    charts: Array<{ id: string; url: string }>;
    isFetching?: boolean;
    error?: boolean;
    sendFeedback?: (feedBackArgs: FeedbackParams) => Promise<void>;
  }
): {
  charts: Array<{ id: string; url: string }>;
  isFetching: boolean;
  error: boolean;
  sendFeedback: (feedBackArgs: FeedbackParams) => Promise<void>;
} => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);
  const [charts, setCharts] = useState<Array<{ id: string; url: string }>>([]);
  const accessToken: string = useSelector(state =>
    get(state, "auth.authAccessToken")
  );
  const session = useSelector(state => get(state, "session"));
  const tenantId: number = useSelector(state =>
    get(state, "auth.authParams.tenantId")
  );
  const farsUrl = config.FARS_URL;

  async function sendFeedback({
    widgetName,
    question,
    rating,
    comment
  }: FeedbackParams): Promise<void> {
    const requestParams = {
      accessToken,
      farsUrl,
      tenantId,
      params: {
        session,
        feedback: {
          widgetName,
          question,
          rating,
          enteredFeedback: comment
        },
        uiEvent: {
          eventLabel: "labs-feedback",
          timestamp: getTimestampForDate(new Date())
        }
      }
    };
    try {
      sendEvent(requestParams);
    } catch ({ errorType }) {
      sendEvent({
        ...requestParams,
        params: {
          ...requestParams.params,
          error: errorType
        }
      });
    }
  }

  useEffect(() => {
    (async () => {
      if (!isUndefined(manualState)) {
        setIsFetching(!!manualState.isFetching);
        setError(!!manualState.error);
        setCharts(manualState.charts);
      } else {
        const requestCharts = manifest.experiments.map(
          (chart: {
            title: string;
            displayIsNew: boolean;
            externalPage: string;
            descriptionHeader: string;
            descriptionBody: string;
            feedbackId: string;
          }): string => chart.externalPage
        );
        try {
          if (!error && !isFetching) {
            setIsFetching(true);
            const fetchedCharts: Record<string, string> = await requestLabsData(
              {
                accessToken,
                tenantId,
                charts: requestCharts
              }
            );
            if (!isEmpty(fetchedCharts)) {
              setCharts(
                Object.entries(fetchedCharts).map((chart: [string, string]): {
                  id: string;
                  url: string;
                } => {
                  return {
                    id: chart[0],
                    url: chart[1]
                  };
                })
              );
            }
            setIsFetching(false);
          }
        } catch (err) {
          setCharts([]);
          setError(true);
          setIsFetching(false);
          withScope(scope => {
            scope.setContext("request-enabled-jira-projects-data", {
              accessToken,
              tenantId,
              charts: requestCharts
            });
            captureException(err);
          });
        }
      }
    })();
  }, [accessToken, tenantId]);
  return { charts, isFetching, error, sendFeedback };
};
