import React, { useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { isNil, isNull } from "lodash";
import moment from "moment";

// components
import NoPermissions from "../NoPermissions/NoPermissions";
import OverviewCycleTime from "../OverviewCycleTime/OverviewCycleTime";
import OverviewDeepWorkPage from "../OverviewDeepWorkPage/OverviewDeepWorkPage";
import OverviewFilters from "../OverviewFilters/OverviewFilters";
import OverviewReleaseFrequency from "../OverviewReleaseFrequency/OverviewReleaseFrequency";
import PageTitle from "../PageTitle/PageTitle";
import TemplateHeader from "../TemplateHeader/TemplateHeader";
import UiCenteringWrapper from "../UiCenteringWrapper/UiCenteringWrapper";

// constants
import { pageIds, pageMetadata } from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useOrganizationTeamsData } from "../../hooks/useOrganizationTeamsData";
import { useOrganizationTimeRanges } from "../../hooks/useOrganizationTimeRanges";
import { useFullOrganizationData } from "../../hooks/useFullOrganizationData";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { UserReportWithUplevelRole } from "../../interfaces/user";
import {
  OrgTeamItems,
  OrgTimeRange,
  Roles
} from "../../interfaces/organization";

// utils
import { getOverviewPageTitle } from "../../utils/overview";
import {
  getFilteredOrganizationUserIds,
  getRoleTypesFromUsers,
  getExtendedTeamsWithNames,
  getOrganizationIcTeamMembers,
  getReportGroupsFromUsers
} from "../../utils/organization-data";
import { parseQuerystring } from "../../utils/url-params";
import SupportEmailLink from "../SupportEmailLink/SupportEmailLink";
import HtmlText from "../HtmlText/HtmlText";

// styled components
const ContentWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;
const Title = styled(PageTitle)`
  margin-right: 1rem;
`;
const Filters = styled(OverviewFilters)`
  margin-bottom: ${props => props.theme.grid.gap};
`;
const MessageBlock = styled.div`
  margin-bottom: 2rem;
  background: ${props => props.theme.colors.all.auroraTeal};
  color: ${props => props.theme.colors.all.white};
  padding: 2rem;
`;
const Email = styled(SupportEmailLink)`
  && {
    color: ${props => props.theme.colors.all.white};
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
    &:hover {
      color: ${props => props.theme.colors.all.marvelMint};
    }
  }
`;
const SupportText = styled.p`
  margin: 0;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const OverviewPage = ({
  className,
  testId = "testId"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-overview-page`;
  const location = useLocation();
  const { urlParams } = useUrlParams();
  const parsedQuerystring = parseQuerystring(location.search);
  const querystringStartDate = parsedQuerystring.startDate;
  const querystringEndDate = parsedQuerystring.endDate;

  const flags = useSelector((state: AppStateInterface) => state.flags);
  const displayTitle = pageMetadata[pageIds.OVERVIEW].title;

  const {
    data: timeRangesData,
    isError: isErrorOrganizationTimeRanges,
    isFetching: isFetchingOrganizationTimeRanges
  } = useOrganizationTimeRanges();
  const selectedTimeRange: OrgTimeRange | undefined = useMemo(() => {
    if (!!querystringEndDate && !!querystringStartDate) {
      return {
        endDate: moment(querystringEndDate).format("YYYY-MM-DD"),
        startDate: moment(querystringStartDate).format("YYYY-MM-DD")
      };
    }
    if (timeRangesData?.length) {
      const nonFutureTimeRanges = timeRangesData.filter(t =>
        moment(t.endDate).isBefore(moment())
      );
      return nonFutureTimeRanges?.pop();
    }
  }, [querystringStartDate, querystringEndDate, timeRangesData]);

  const {
    data: organizationTeamsData,
    isError: isErrorOrganizationTeamsData,
    isFetching: isFetchingOrganizationTeamsData
  } = useOrganizationTeamsData();

  const {
    data: fullOrganizationData,
    isError: isErrorFullOrganizationData,
    isFetching: isFetchingFullOrganizationData
  } = useFullOrganizationData();

  // build a subset of fullOrganizationData with leadership roles filtered out
  const organizationIcTeamMembers: Array<
    UserReportWithUplevelRole
  > | null = useMemo(() => {
    if (isNil(fullOrganizationData)) {
      return null;
    }
    return getOrganizationIcTeamMembers(fullOrganizationData);
  }, [fullOrganizationData]);

  // build a list of userIds filtered based on the current url params
  const filteredOrganizationUserIds: Array<number> | null = useMemo(() => {
    if (isNil(organizationIcTeamMembers) || isNil(organizationTeamsData)) {
      return null;
    }
    return getFilteredOrganizationUserIds({
      organizationIcTeamMembers,
      organizationTeamsData,
      urlParams
    });
  }, [organizationIcTeamMembers, organizationTeamsData, urlParams]);

  // pull a list of all the roles from the ic only team members to use in the
  // filter dropdown.
  const availableRoles: Array<Roles> | null = useMemo(() => {
    if (isNil(organizationIcTeamMembers)) {
      return null;
    }
    return getRoleTypesFromUsers(organizationIcTeamMembers);
  }, [organizationIcTeamMembers]);

  const availableReportGroups: Array<string> | null = useMemo(() => {
    if (isNil(organizationIcTeamMembers)) {
      return null;
    }
    return getReportGroupsFromUsers(organizationIcTeamMembers);
  }, [organizationIcTeamMembers]);

  // org chart teams don't have names, because
  // they use the name of their team lead
  // instead. populate all the org chart teams
  // with their team lead's name to use in the
  // filter dropdown.
  const extendedTeamsWithNames: OrgTeamItems | null = useMemo(() => {
    if (
      isNil(fullOrganizationData) ||
      isNil(organizationTeamsData) ||
      isNull(organizationIcTeamMembers)
    ) {
      return null;
    }
    return getExtendedTeamsWithNames(
      fullOrganizationData,
      organizationTeamsData,
      organizationIcTeamMembers
    );
  }, [fullOrganizationData, organizationTeamsData, organizationIcTeamMembers]);

  const isFetchingOverviewData =
    isFetchingOrganizationTeamsData ||
    isFetchingFullOrganizationData ||
    isFetchingOrganizationTimeRanges;
  const isErrorOverviewData =
    isErrorOrganizationTeamsData ||
    isErrorFullOrganizationData ||
    isErrorOrganizationTimeRanges;

  // page title change
  useEffect(() => {
    document.title = getOverviewPageTitle(displayTitle);
  }, [displayTitle, location]);

  const deepWorkDisplayContent = flags?.["overview-deep-work"] ? (
    <OverviewDeepWorkPage
      availableReportGroups={availableReportGroups}
      availableRoles={availableRoles}
      availableTeams={extendedTeamsWithNames}
      fullOrganizationData={fullOrganizationData}
      isErrorOverviewData={isErrorOverviewData}
      isFetchingOverviewData={isFetchingOverviewData}
      testId={thisTestId}
      userIds={filteredOrganizationUserIds}
      selectedTimeRange={selectedTimeRange}
      orgTimeRanges={timeRangesData}
    />
  ) : null;

  const releaseFrequencyDisplayContent = flags?.[
    "overview-release-frequency"
  ] ? (
    <OverviewReleaseFrequency
      isFetchingOverviewData={isFetchingOverviewData}
      testId={thisTestId}
      userIds={filteredOrganizationUserIds}
      selectedTimeRange={selectedTimeRange}
      orgTimeRanges={timeRangesData}
    />
  ) : null;

  const cycleTimeDisplayContent = flags?.["overview-cycle-time"] ? (
    <OverviewCycleTime
      isFetchingOverviewData={isFetchingOverviewData}
      testId={thisTestId}
      userIds={filteredOrganizationUserIds}
      selectedTimeRange={selectedTimeRange}
      orgTimeRanges={timeRangesData}
    />
  ) : null;

  const overviewDisplayContent = flags?.overview ? (
    <>
      {deepWorkDisplayContent}
      {releaseFrequencyDisplayContent}
      {cycleTimeDisplayContent}
    </>
  ) : (
    <NoPermissions testId={thisTestId} />
  );

  const overviewPageMessage = !!flags?.["overview-page-message"] ? (
    <MessageBlock>
      <HtmlText htmlText={flags?.["overview-page-message"]} />
      <SupportText>
        If you have questions, please don&rsquo;t hesitate to reach out to us at{" "}
        <Email name="overview-message" testId={thisTestId} />
      </SupportText>
    </MessageBlock>
  ) : null;

  return (
    <div className={className} data-testid={thisTestId}>
      {overviewPageMessage}
      <ContentWrapper>
        <TemplateHeader
          content={<Title title={displayTitle} testId={thisTestId} />}
          testId={thisTestId}
        />
        <UiCenteringWrapper>
          <Filters
            availableRoles={availableRoles}
            availableReportGroups={availableReportGroups}
            availableTeams={extendedTeamsWithNames}
            testId={thisTestId}
          />
          {overviewDisplayContent}
        </UiCenteringWrapper>
      </ContentWrapper>
    </div>
  );
};

export default OverviewPage;
