import React, { useEffect, useMemo } from "react";
import styled from "styled-components/macro";

// components
import { Redirect, Route, Switch } from "react-router-dom";
import PageTitle from "../PageTitle/PageTitle";
import SettingsProjectsAndRepos from "../SettingsProjectsAndRepos/SettingsProjectsAndRepos";
import SettingsTeamBuilder from "../SettingsTeamBuilder/SettingsTeamBuilder";
import RouteLinkTabs from "../RouteLinkTabs/RouteLinkTabs";
import TeamCustomizationSettings from "../TeamCustomizationSettings/TeamCustomizationSettings";
import TemplateHeader from "../TemplateHeader/TemplateHeader";
import UiCenteringWrapper from "../UiCenteringWrapper/UiCenteringWrapper";

// constants
import {
  pageIds,
  pageMetadata,
  settingsSectionLabels,
  settingsSections
} from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

// utils
import { getSettingsPageTitle } from "../../utils/settings";

// styled components
const ContentWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;
const Title = styled(PageTitle)`
  margin-right: 1rem;
`;
const Tabs = styled(RouteLinkTabs)`
  padding-bottom: 0.5rem;
  margin: 2rem 0 0.5rem 0;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const SettingsPage = ({
  className,
  testId = "testId"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-settings-page`;
  const location = useLocation();
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const canViewTeamBuilder = flags?.["flexible-teams-settings"];
  const canViewTeamCustomizationSettings =
    flags?.["team-customization-settings"];

  const { getSettingsPath } = useUrlParams();
  // FIXME: we are really close to refactoring our routing to use react router 6
  // nested routing, so rather than set up a whole new thing for settings in
  // urlParams that's going to get ripped out, doing this slightly jank thing
  // here for now
  const selectedSection = location.pathname.split("/")[2];

  // page title change
  useEffect(() => {
    document.title = getSettingsPageTitle();
  }, [location]);

  const allowedSections = useMemo(
    () =>
      [
        settingsSections.TEAM_BUILDER,
        settingsSections.TEAM_SETTINGS,
        settingsSections.PROJECTS_AND_REPOS
      ].filter(section => {
        switch (section) {
          case settingsSections.TEAM_BUILDER:
            return canViewTeamBuilder;
          case settingsSections.TEAM_SETTINGS:
            return canViewTeamCustomizationSettings;
          default:
            return true;
        }
      }),
    [canViewTeamBuilder, canViewTeamCustomizationSettings]
  );

  return flags?.["settings-page"] ? (
    <div className={className} data-testid={thisTestId}>
      <ContentWrapper>
        <TemplateHeader
          content={
            <Title
              title={pageMetadata[pageIds.SETTINGS].title}
              testId={testId}
            />
          }
          testId={testId}
        />
        <UiCenteringWrapper>
          <Tabs
            defaultTabId={selectedSection}
            tabs={allowedSections.map(section => ({
              id: section,
              path: getSettingsPath({
                selectedSection: section
              }),
              title: settingsSectionLabels[section]
            }))}
            testId={thisTestId}
          />
          <Switch>
            <Route
              path={`/${pageIds.SETTINGS}/${settingsSections.TEAM_SETTINGS}`}
            >
              <TeamCustomizationSettings testId={thisTestId} />
            </Route>
            <Route
              exact
              path={`/${pageIds.SETTINGS}/${settingsSections.PROJECTS_AND_REPOS}`}
            >
              <SettingsProjectsAndRepos testId={thisTestId} />
            </Route>
            <Route
              path={`/${pageIds.SETTINGS}(/${settingsSections.TEAM_BUILDER})?`}
            >
              <SettingsTeamBuilder testId={thisTestId} />
            </Route>
          </Switch>
        </UiCenteringWrapper>
      </ContentWrapper>
    </div>
  ) : (
    <Redirect to={`/`} />
  );
};

export default SettingsPage;
