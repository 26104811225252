import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// components
import SupportEmailLink from "../SupportEmailLink/SupportEmailLink";

// utils
import config from "../../env-config";

// styled components
const Container = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.all.white};
  display: grid;
  justify-items: center;
  margin: 0;
  max-width: 60rem;
  min-height: 40rem;
  padding: 5rem;
`;
const ContentWrapper = styled.div`
  text-align: center;
`;
const Header = styled.header`
  font-size: 4rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const SupportText = styled.p`
  margin: 5rem 0 0 0;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const NoAccessClientDashboard = ({
  className,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-no-access-client-dashboard`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <ContentWrapper>
        <Header>We&rsquo;ve moved!</Header>
        <p>
          The Uplevel Dashboard is now at
          <br />
          <a href={config.ORG_DASHBOARD_URL}>{config.ORG_DASHBOARD_URL}</a>.
        </p>
        <SupportText>
          If you have questions, please don&rsquo;t hesitate to reach out to us
          at{" "}
          <SupportEmailLink
            name="no-access-client-dashboard-message"
            testId={thisTestId}
          />
        </SupportText>
      </ContentWrapper>
    </Container>
  );
};

export default NoAccessClientDashboard;
