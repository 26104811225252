// constants
import { timerangeIds } from "../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useUrlParams } from "./useUrlParams";
import { useParams } from "react-router-dom";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";
import { useQuery } from "react-query";

// utils
import { requestAnnotations } from "../utils/work-deep-dive";

type KeyProps = { type: string };
export const useAnnotationsData = (options: KeyProps, canRequest = true) => {
  const { groupId } = useParams<{ groupId: string }>();

  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );

  const { urlParams, workItemsRequestParams } = useUrlParams();

  const requestParams =
    options.type === "retros" &&
    urlParams.timerange.timerangeType === timerangeIds.SPRINTS
      ? {
          ...workItemsRequestParams,
          context: { sprintId: urlParams.timerange.id }
        }
      : workItemsRequestParams;

  return useQuery(
    ["annotations", { ...requestParams, groupId }],
    () =>
      requestAnnotations({
        accessToken,
        tenantId,
        // annotations needs users to be not empty, but the project explorer uses empty in some cases, so override here
        params: { ...requestParams, users: requestParams.allUsers }
      }),
    {
      enabled:
        !!accessToken &&
        !!tenantId &&
        !!workItemsRequestParams.allUsers.length &&
        canRequest
    }
  );
};
