import React, { useEffect } from "react";
import styled, { css } from "styled-components/macro";

// components
import Icon from "../Icon/Icon";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ReactionPills from "../Annotations/ReactionPills";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interface
import { AnnotationsInterface } from "../../interfaces/annotations";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";

// styled components
const ReactIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  margin-right: 0.5rem;
`;
const ReactButton = styled.button<{ userHasSelectedAnnotation: boolean }>`
  background-color: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${
      props.userHasSelectedAnnotation
        ? props.theme.colors.all.wolverine
        : props.theme.colors.all.lightJean
    }`};
  font-size: 1.1rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
`;

// typescript props
type Props = {
  annotations: Array<AnnotationsInterface>;
  className?: string;
  context: any;
  onReact: (
    context: any,
    status: string,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  name: string;
  testId?: string;
};

const QuickReact = ({
  annotations,
  className,
  context,
  onReact,
  name,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-quick-react`;
  const [isOpen, setIsOpen] = useState(false);
  const { trackEvent } = useTracking();
  const user = useSelector((state: AppStateInterface) => state.user);
  const userHasSelectedAnnotation = !!annotations.find(
    (a: AnnotationsInterface) => a.userId === user.id
  );
  function onToggleOverlay(show: any) {
    setIsOpen(show);
    trackEvent({
      label: thisTestId,
      value: `${show ? "opened" : "closed"} quick react for ${name}`
    });
  }
  const numAnnotations = annotations.length;

  useEffect(() => {
    // close the menu on any change to annotations coming in, either added or removed
    setIsOpen(false);
  }, [numAnnotations]);

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      show={isOpen}
      onToggle={onToggleOverlay}
      overlay={
        <Popover id="popover-basic">
          <ReactionPills
            annotations={annotations}
            contextItem={context}
            handleAnnotationClick={onReact}
            showCount={false}
          />
        </Popover>
      }
      rootClose
    >
      <ReactButton
        className={className}
        data-testid={`${thisTestId}-trigger`}
        userHasSelectedAnnotation={userHasSelectedAnnotation}
      >
        <ReactIcon icon="thumbs-up" testId={thisTestId} />
        React
      </ReactButton>
    </OverlayTrigger>
  );
};

export default QuickReact;
