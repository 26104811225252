import React from "react";
import { components } from "react-select";
import styled from "styled-components/macro";

// components
import Icon from "../Icon/Icon";

// styled components
const Arrow = styled(Icon)`
  color: ${props => props.theme.colors.all.storm};
`;

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Arrow
        icon={props.isFocused ? "arrow-up" : "arrow-down"}
        testId="dropdown-indicator"
      />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
