import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { compact, defaults, isNull, sample, uniq } from "lodash";

// components
import DefaultTeamRadio from "../DefaultTeamRadio/DefaultTeamRadio";
import ExistingTeam from "../ExistingTeam/ExistingTeam";
import Modal from "../Modal/Modal";
import NewTeamForm from "../NewTeamForm/NewTeamForm";
import TeamCardModalContent from "../TeamCardModalContent/TeamCardModalContent";

// constants
import { avatarImages } from "../../constants/widgets";

// hooks
import { useFullOrganizationData } from "../../hooks/useFullOrganizationData";
import { useMutation, useQueryClient } from "react-query";
import { useOwnFallbackOrgChartTeam } from "../../hooks/useOwnFallbackOrgChartTeam";
import { useProjectsAndBoardsData } from "../../hooks/useProjectsAndBoardsData";
import { useSelector } from "react-redux";
import { useSprintMetadata } from "../../hooks/useSprintMetadata";
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";
import { useUserSettings } from "../../hooks/useUserSettings";

// interfaces
import { TeamInterface } from "../../interfaces/team";
import { BoardInterface } from "../../interfaces/sprint-metadata";
import { TimerangeMetadataInterface } from "../../interfaces/constants";
import { ProjectInterface } from "../../interfaces/work-items";
import {
  NewUser,
  UserReportWithMetadataInterface
} from "../../interfaces/user";
import { AppStateInterface } from "../../interfaces/app-state";
import { SelectOptionsInterface } from "../../interfaces/controls";

// utils
import {
  getBoard,
  getDefaultActiveSprint,
  getTeamSprintMetadata
} from "../../utils/sprints";
import {
  createOrUpdateTeam,
  deleteTeam,
  getTeamNameFromTeam
} from "../../utils/teams";
import { updateUserSettings } from "../../utils/settings";
import { getSprintTimerangesForBoard } from "../../utils/timeranges";

// styled components
const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  justify-content: space-between;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
`;
const ButtonWrapper = styled.div<{ isNewTeam: boolean }>`
  align-items: baseline;
  display: flex;
  margin: ${props => (props.isNewTeam ? "2.4rem 0 0" : "2.4rem 0")};
`;
const SubmitButton = styled.button`
  background: ${props => props.theme.colors.all.auroraTeal};
  border: 0;
  color: ${props => props.theme.colors.all.white};
  padding: 1rem 4rem;
  margin: 0;
`;
const CancelButton = styled.button`
  background: transparent;
  border: 0;
  color: ${props => props.theme.colors.all.auroraTeal};
  font-size: 1.1rem;
  margin-left: 2rem;
`;
const CreatedByName = styled.i`
  margin-top: auto;
  color: ${props => props.theme.colors.all.storm};
  font-size: 1.1rem;
`;
const Footer = styled.footer<{ isEditing: boolean }>`
  &:not(:empty) {
    margin-top: ${props => (props.isEditing ? "0" : "2.4rem")};
  }
`;

const RESET_STATE = {
  addedTeamMembers: [],
  avatarColor: null,
  avatarIcon: null,
  board: null,
  hasBoardError: false,
  hasProjectError: false,
  hasTeamMembersError: false,
  hasTeamNameError: false,
  project: null,
  shouldUseSprints: false,
  teamMembers: [],
  teamName: null,
  tmpRemoveTeamMembers: []
};

const getInitialState = (
  team: TeamInterface | null
): { [key: string]: any } => {
  return {
    addedTeamMembers: RESET_STATE.addedTeamMembers,
    avatarColor: team?.avatarColor || RESET_STATE.avatarColor,
    avatarIcon: team?.avatarIcon || RESET_STATE.avatarIcon,
    board: RESET_STATE.board,
    hasBoardError: RESET_STATE.hasBoardError,
    hasProjectError: RESET_STATE.hasProjectError,
    hasTeamMembersError: RESET_STATE.hasTeamMembersError,
    hasTeamNameError: RESET_STATE.hasTeamNameError,
    project: RESET_STATE.project,
    shouldUseSprints: team?.useSprints || RESET_STATE.shouldUseSprints,
    teamMembers: team?.teamMembers || RESET_STATE.teamMembers,
    teamName: isNull(team) ? RESET_STATE.teamName : getTeamNameFromTeam(team),
    tmpRemoveTeamMembers: RESET_STATE.tmpRemoveTeamMembers
  };
};

// typescript props
type Props = {
  className?: string;
  team: TeamInterface | null;
  testId?: string;
};

const TeamCard = ({
  className,
  team,
  testId = "testId"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-team-card`;
  const { trackEvent } = useTracking();
  const isNewTeam = isNull(team);
  const modePrefix = isNewTeam ? "create-team" : "update-team";
  const isOrgChartTeam = team?.isOrgChartTeam;
  const defaultProjectId = team?.defaultProjectId;
  const defaultBoardId = team?.defaultBoardId;

  // SELECTORS
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const user = useSelector((state: AppStateInterface) => state.user);
  const displayTenantAlias = useSelector(
    (state: AppStateInterface) => state.session.displayTenantAlias
  );

  // HOOKS
  const { data: projectsAndBoardsData } = useProjectsAndBoardsData();
  const { data: sprintMetadata } = useSprintMetadata(
    { type: `${team?.teamId}-team`, team },
    !!team?.useSprints && !team?.defaultBoardId
  );
  const {
    data: fullOrganizationData,
    isLoading: isLoadingFullOrganizationData
  } = useFullOrganizationData();
  const fullOrganization: Array<UserReportWithMetadataInterface> =
    isLoadingFullOrganizationData || !fullOrganizationData
      ? []
      : fullOrganizationData;
  const { data: userSettings } = useUserSettings();
  const fallbackOrgChartTeam = useOwnFallbackOrgChartTeam();
  const allBoards = projectsAndBoardsData?.boards;
  const derivedTeamBoard = useMemo(
    () =>
      getBoard({
        sprintMetadata,
        allBoards,
        team
      }),
    [team, allBoards, sprintMetadata]
  );
  const isDefault = !!(isNewTeam
    ? false
    : !!userSettings?.defaultTeamId
    ? team?.teamId === userSettings?.defaultTeamId
    : team?.teamId === fallbackOrgChartTeam?.teamId);

  // react-query hooks
  const queryClient = useQueryClient();
  const deleteTeamMutation = useMutation(deleteTeam, {
    onSuccess: () => {
      // Invalidate and refetch all teams queries
      queryClient.invalidateQueries(["teams"]);
    }
  });
  const createUpdateTeamMutation = useMutation(createOrUpdateTeam, {
    onSuccess: updatedTeam => {
      // Invalidate and refetch all teams queries
      queryClient.invalidateQueries(["teams"]);
      // invalidate the board for this team in case the team members changed
      queryClient.invalidateQueries([
        "board",
        { type: `${team?.teamId}-team` }
      ]);
      // for existing teams, set updated team in state until refetched. for null
      // create team, let the state reset.
      if (!!team) {
        setTeamMembers(updatedTeam.teamMembers);
      }
    }
  });
  const userSettingsMutation = useMutation(updateUserSettings, {
    onSuccess: updatedSettings => {
      queryClient.setQueryData("user-settings", () => updatedSettings);
    }
  });

  const {
    data: projectsData,
    isSuccess: isSuccessProjectsData
  } = useProjectsAndBoardsData();

  // STATE VALUES
  // this component should probably just use the reducer for all
  // the state values, consider refactoring
  function updateState(newState: { [key: string]: any }) {
    setAddedTeamMembers(newState.addedTeamMembers);
    setAvatarColor(newState.avatarColor);
    setAvatarIcon(newState.avatarIcon);
    setBoard(newState.board);
    setHasBoardError(newState.hasBoardError);
    setHasProjectError(newState.hasProjectError);
    setHasTeamMembersError(newState.hasTeamMembersError);
    setHasTeamNameError(newState.hasTeamNameError);
    setProject(newState.project);
    setShouldUseSprints(newState.shouldUseSprints);
    setTeamMembers(newState.teamMembers);
    setTeamName(newState.teamName);
    setTmpRemoveTeamMembers(newState.tmpRemoveTeamMembers);
  }

  const INITIAL_STATE = getInitialState(team);
  const [hasTeamNameError, setHasTeamNameError] = useState(false);
  const [hasTeamMembersError, setHasTeamMembersError] = useState(false);
  const [hasProjectError, setHasProjectError] = useState(false);
  const [hasBoardError, setHasBoardError] = useState(false);

  const [projects, setProjects] = useState<Array<ProjectInterface>>([]);
  const [boards, setBoards] = useState<Array<BoardInterface>>([]);
  const [project, setProject] = useState<ProjectInterface | null>(
    INITIAL_STATE.project
  );
  const [board, setBoard] = useState<BoardInterface | null>(
    INITIAL_STATE.board
  );
  const [sprint, setSprint] = useState<TimerangeMetadataInterface | null>(null);
  const [shouldUseSprints, setShouldUseSprints] = useState(
    INITIAL_STATE.shouldUseSprints
  );
  const [displayModal, setDisplayModal] = useState<{
    id: string;
    [key: string]: any;
  } | null>(null);
  const [isEditing, setIsEditing] = useState(isNewTeam);
  const [teamName, setTeamName] = useState<string | null>(
    INITIAL_STATE.teamName
  );
  const [avatarIcon, setAvatarIcon] = useState<string | null>(
    INITIAL_STATE.avatarIcon
  );
  const [avatarColor, setAvatarColor] = useState<string | null>(
    INITIAL_STATE.avatarColor
  );
  const [teamMembers, setTeamMembers] = useState<
    Array<UserReportWithMetadataInterface>
  >(INITIAL_STATE.teamMembers);
  const [addedTeamMembers, setAddedTeamMembers] = useState<
    Array<UserReportWithMetadataInterface | NewUser>
  >(INITIAL_STATE.addedTeamMembers);
  const [tmpRemoveTeamMembers, setTmpRemoveTeamMembers] = useState<
    Array<UserReportWithMetadataInterface>
  >(INITIAL_STATE.tmpRemoveTeamMembers);

  // USE EFFECTS

  // checking if the team has any team members in order to show
  // error ui after attempted submit
  useEffect(() => {
    // don't update this unless there's already an error showing
    if (hasTeamMembersError) {
      setHasTeamMembersError(![...addedTeamMembers, ...teamMembers].length);
    }
  }, [addedTeamMembers, hasTeamMembersError, teamMembers]);

  // set state values from projects data request
  useEffect(() => {
    if (isSuccessProjectsData) {
      const {
        project,
        board,
        sprint,
        projects,
        boards
      } = getTeamSprintMetadata({
        shouldUseSprints,
        projectsData,
        defaultProjectId,
        defaultBoardId,
        isOrgChartTeam,
        derivedTeamBoard
      });
      setProject(project);
      setBoard(board);
      setSprint(sprint);
      setProjects(projects);
      setBoards(boards);
    }
  }, [
    defaultBoardId,
    defaultProjectId,
    derivedTeamBoard,
    isOrgChartTeam,
    isSuccessProjectsData,
    projectsData,
    shouldUseSprints
  ]);

  // HANDLERS
  function onCancelModal() {
    setDisplayModal(null);
    trackEvent({
      label: `flexible-teams-${displayModal}-modal`,
      value: `closed ${displayModal}`
    });
  }

  function onEdit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.persist();
    setIsEditing(true);
    trackEvent({
      e,
      label: `${modePrefix}-edit`,
      value: `clicked to edit ${teamName}`
    });
  }

  function onClearAll(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.persist();
    updateState(RESET_STATE);
    trackEvent({
      e,
      label: `${modePrefix}-clear-all`,
      value: `clicked to clear ${INITIAL_STATE.teamName} values`
    });
  }

  function onCancelEdit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.persist();
    setIsEditing(isNewTeam);
    updateState(INITIAL_STATE);
    trackEvent({
      e,
      label: `${modePrefix}-cancel-edit`,
      value: `clicked to cancel editing ${
        modePrefix === "create-team" ? "new team" : INITIAL_STATE.teamName
      }`
    });
  }

  function onDelete(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.persist();
    setDisplayModal({
      id: `${modePrefix}-delete-team`
    });
    trackEvent({
      e,
      label: `${modePrefix}-delete-team`,
      value: `clicked to delete ${teamName}`
    });
  }

  function onCancelDelete(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.persist();
    setDisplayModal(null);
    trackEvent({
      e,
      label: `${modePrefix}-cancel-delete-team`,
      value: `canceled delete ${teamName}`
    });
  }

  async function onConfirmDelete(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.persist();
    if (!!team) {
      deleteTeamMutation.mutate(
        {
          accessToken,
          tenantId,
          params: {
            teamId: team.teamId
          }
        },
        {
          onSuccess: () => {
            // check to see if the team was default, if so, default to the
            // fallback org chart team
            if (isDefault && !!fallbackOrgChartTeam) {
              userSettingsMutation.mutate({
                accessToken,
                tenantId,
                params: {
                  ...userSettings,
                  defaultTeamId: fallbackOrgChartTeam.teamId
                }
              });
            }
          }
        }
      );
      trackEvent({
        e,
        label: `${modePrefix}-confirm-delete-team`,
        value: `confirmed delete ${teamName}`
      });
      setDisplayModal(null);
    }
  }

  function onChangeTeamName(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    setTeamName(e.target.value);
  }

  function onBlurTeamName(e: React.FocusEvent<HTMLInputElement>) {
    e.persist();
    // don't update this unless there's already an error showing
    if (hasTeamNameError) {
      setHasTeamNameError(!e.target.value);
    }
    trackEvent({
      label: `${modePrefix}-team-name`,
      value: `updated team name to ${e.target.value}`
    });
  }

  function onCreateIcon(avatarIcon: string, avatarColor: string) {
    setAvatarIcon(avatarIcon);
    setAvatarColor(avatarColor);
    trackEvent({
      label: `${modePrefix}-icon`,
      value: `created ${avatarColor} ${avatarIcon} icon`
    });
  }

  function onSelectBoard(selected: SelectOptionsInterface) {
    const board = boards.find(
      (b: BoardInterface) => b.boardId === selected.value
    );

    // don't update this unless there's already an error showing
    if (hasBoardError) {
      setHasBoardError(!board);
    }

    if (!!board) {
      // set board
      setBoard(board);
      // backfill project for the board
      const boardProject = projectsData.projects.find(
        (p: ProjectInterface) => p.projectId === board.projectId
      );
      setProject(boardProject || null);

      if (hasProjectError) {
        setHasProjectError(!boardProject);
      }

      // if we backfilled a project successfully, update the boards dropdown to
      // filter to that project, otherwise leave them alone
      if (!!boardProject) {
        // update boards dropdown to the boards available for the project
        const updatedBoards = projectsData.boards.filter(
          (b: BoardInterface) => b.projectId === boardProject?.projectId
        );
        setBoards(updatedBoards);
      }

      // try to find a default sprint for the selected board
      const defaultSprint = getDefaultActiveSprint(board.sprints);
      if (!!defaultSprint) {
        const sprintTimeranges = getSprintTimerangesForBoard(board);
        const defaultSprintTimerange = sprintTimeranges.find(
          t => t.id === defaultSprint.sprintId
        );
        if (!!defaultSprintTimerange) {
          setSprint(defaultSprintTimerange);
        }
      }
      trackEvent({
        label: `${modePrefix}-board`,
        value: `selected ${board.boardName} board`
      });
    }
  }

  function onSelectProject(selected: SelectOptionsInterface) {
    const project = projects.find(
      (p: ProjectInterface) => p.projectId === selected.value
    );

    // don't update this unless there's already an error showing
    if (hasProjectError) {
      setHasProjectError(!project);
    }

    if (!!project) {
      // set project
      setProject(project);

      // null out board and sprint
      setBoard(null);
      setSprint(null);

      // don't update this unless there's already an error showing
      if (hasBoardError) {
        setHasBoardError(false);
      }

      // update boards dropdown to the boards available for the project
      const filteredBoards = projectsData.boards.filter(
        (b: BoardInterface) => b.projectId === project.projectId
      );
      setBoards(filteredBoards);

      trackEvent({
        label: `${modePrefix}-project`,
        value: `selected ${project.projectName} project`
      });
    }
  }

  function onToggleSprints(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    setShouldUseSprints(!shouldUseSprints);
    trackEvent({
      e,
      label: `${modePrefix}-sprints-toggle`,
      value: `toggled sprints ${!shouldUseSprints ? "on" : "off"}`
    });
  }

  function onUpdateTeamMembers(
    updatedTeamMembers: Array<SelectOptionsInterface>
  ) {
    const teamMembersWithMetadata = compact(
      updatedTeamMembers.map(s => {
        const currentlyAddedTeamMember = addedTeamMembers.find(
          t => t.email === s.value
        );
        if (currentlyAddedTeamMember) {
          return currentlyAddedTeamMember;
        }

        const orgMember = fullOrganization.find(
          (t: UserReportWithMetadataInterface) => t.email === s.value
        );
        if (orgMember) {
          return orgMember;
        }

        return null;
      })
    );
    setAddedTeamMembers(teamMembersWithMetadata);
    trackEvent({
      label: `${modePrefix}-update-team-members`,
      value: `updated team members to ${teamMembersWithMetadata
        .map(t => t.email)
        .join(",")}`
    });
  }

  function onCreateTeamMember(value: string) {
    // create a fake user for team members not in the org
    const newTeamMember = {
      email: value,
      slackAvatar: sample(avatarImages) as string,
      name: value,
      tenantId,
      id: null,
      image: "",
      slackHandle: null,
      slackUrl: null,
      include_in_team_roster: true,
      role: "",
      enabled_roles: [""],
      title: "Pending"
    };
    setAddedTeamMembers(currentTeamMembers => [
      ...currentTeamMembers,
      newTeamMember
    ]);
    trackEvent({
      label: `${modePrefix}-create-team-member`,
      value: `created new team member ${value}`
    });
  }

  function onRemoveTeamMember({
    e,
    teamMember
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    teamMember: UserReportWithMetadataInterface | NewUser;
  }) {
    const isRemovingExistingTeamMember = teamMembers.find(
      t => t.id === teamMember.id
    );

    if (isRemovingExistingTeamMember) {
      setDisplayModal({
        id: `${modePrefix}-remove-team-member`,
        isSelf: teamMember.id === user.id,
        teamMember
      });
      trackEvent({
        e,
        label: `${modePrefix}-remove-team-member`,
        value: `clicked to remove ${teamMember.email}`
      });
    } else {
      // remove team member from added team members list
      setAddedTeamMembers(currentTeamMembers =>
        currentTeamMembers.filter(t => t.email !== teamMember.email)
      );
    }
  }

  function onConfirmRemoveTeamMember({
    e,
    teamMember
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    teamMember: UserReportWithMetadataInterface;
  }) {
    setTmpRemoveTeamMembers(current => [...current, teamMember]);
    // remove team member from current saved team members list
    setTeamMembers(currentTeamMembers =>
      currentTeamMembers.filter(t => t.email !== teamMember.email)
    );
    // remove team member from added team members list
    setAddedTeamMembers(currentTeamMembers =>
      currentTeamMembers.filter(t => t.email !== teamMember.email)
    );
    trackEvent({
      e,
      label: `${modePrefix}-confirm-remove-team-member`,
      value: `confirmed remove ${teamMember.email}`
    });
    setDisplayModal(null);
  }

  function onCancelRemoveTeamMember({
    e,
    teamMember
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    teamMember: UserReportWithMetadataInterface;
  }) {
    setDisplayModal(null);
    trackEvent({
      e,
      label: `${modePrefix}-cancel-remove-team-member`,
      value: `canceled remove ${teamMember.email}`
    });
  }

  function onChangeDefaultTeam(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    // send direct api request, mutation will invalidate and refetch settings
    userSettingsMutation.mutate({
      accessToken,
      tenantId,
      params: defaults(
        {
          defaultTeamId: team?.teamId
        },
        userSettings
      )
    });
    trackEvent({
      e,
      label: `${modePrefix}-default-team`,
      value: `checked to make team ${teamName} default`
    });
  }

  function onConfirmSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    trackEvent({
      e,
      label: `${modePrefix}-confirm-submit`,
      value: `confirmed create team ${teamName}`
    });
    doSubmit(e);
  }

  function onSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.persist();

    const filteredExistingTeamMembers = teamMembers.filter(
      existing =>
        !tmpRemoveTeamMembers.find(removed => removed.email === existing.email)
    );
    const allTeamMembers = [
      ...addedTeamMembers,
      ...filteredExistingTeamMembers
    ];
    let canSubmit = !!teamName && !!allTeamMembers.length;

    if (shouldUseSprints) {
      canSubmit = canSubmit && !!board;
    }

    if (canSubmit) {
      if (isNewTeam) {
        setDisplayModal({
          id: `${modePrefix}-submit`,
          teamName,
          addedTeamMembers,
          newTeamMembers: allTeamMembers.filter(t => !t.id),
          displayTenantAlias
        });
        trackEvent({
          e,
          label: `${modePrefix}-submit`,
          value: `clicked to create team ${teamName}`
        });
      } else {
        doSubmit(e);
      }
    } else {
      setHasTeamNameError(!teamName);
      setHasTeamMembersError(!allTeamMembers.length);
      if (shouldUseSprints) {
        setHasProjectError(!project);
        setHasBoardError(!board);
      }
    }
  }

  function doSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const filteredExistingTeamMembers = teamMembers.filter(
      existing =>
        !tmpRemoveTeamMembers.find(removed => removed.email === existing.email)
    );
    const allTeamMembers = [
      ...addedTeamMembers,
      ...filteredExistingTeamMembers
    ];

    const defaultBoardId = board?.boardId || null;
    const defaultProjectId = project?.projectId || null;
    const useSprints = shouldUseSprints;

    setDisplayModal({
      id: `${modePrefix}-confirm-submit`,
      teamName,
      addedTeamMembers,
      newTeamMembers: allTeamMembers.filter(t => !t.id),
      removedTeamMembers: tmpRemoveTeamMembers,
      displayTenantAlias
    });

    createUpdateTeamMutation.mutate({
      accessToken,
      tenantId,
      params: {
        avatarColor: avatarColor as string,
        avatarIcon: avatarIcon as string,
        defaultBoardId,
        defaultProjectId,
        isOrgChartTeam: !!team?.isOrgChartTeam,
        teamId: team?.teamId || null,
        teamMembers: allTeamMembers,
        teamName: teamName as string,
        tenantId,
        useSprints
      }
    });

    trackEvent({
      e,
      label: `${modePrefix}`,
      value: `completed`
    });

    if (isNewTeam) {
      updateState(RESET_STATE);
    } else {
      setAddedTeamMembers(RESET_STATE.addedTeamMembers);
      setTmpRemoveTeamMembers(RESET_STATE.tmpRemoveTeamMembers);
      setIsEditing(false);
    }
  }

  function onCancelSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setDisplayModal(null);
    trackEvent({
      e,
      label: `${modePrefix}-cancel-submit`,
      value: `canceled create team ${teamName}`
    });
  }

  const canEditExistingTeam =
    flags?.["flexible-teams-editing"] &&
    !team?.isOrgChartTeam &&
    (team?.createdByUser?.id === user.id ||
      !!team?.teamMembers.find(t => t.id === user.id));

  return (
    <Container
      aria-labelledby={isNewTeam ? "header-create-team" : "header-your-teams"}
      className={className}
      data-testid={thisTestId}
    >
      <ContentWrapper>
        {isNewTeam ? (
          <NewTeamForm
            addedTeamMembers={addedTeamMembers}
            avatarColor={avatarColor}
            avatarIcon={avatarIcon}
            board={board}
            boards={boards}
            fullOrganization={fullOrganization}
            hasBoardError={hasBoardError}
            hasProjectError={hasProjectError}
            hasTeamMembersError={hasTeamMembersError}
            hasTeamNameError={hasTeamNameError}
            onBlurTeamName={onBlurTeamName}
            onChangeTeamName={onChangeTeamName}
            onCreateIcon={onCreateIcon}
            onCreateTeamMember={onCreateTeamMember}
            onSelectBoard={onSelectBoard}
            onSelectProject={onSelectProject}
            onUpdateTeamMembers={onUpdateTeamMembers}
            onToggleSprints={onToggleSprints}
            project={project}
            projects={projects}
            shouldUseSprints={shouldUseSprints}
            sprint={sprint}
            teamMembers={teamMembers}
            teamName={teamName}
          />
        ) : (
          <ExistingTeam
            addedTeamMembers={addedTeamMembers}
            avatarColor={avatarColor as string}
            avatarIcon={avatarIcon as string}
            board={board}
            boards={boards}
            canEdit={canEditExistingTeam}
            fullOrganization={fullOrganization}
            hasBoardError={hasBoardError}
            hasProjectError={hasProjectError}
            hasTeamMembersError={hasTeamMembersError}
            hasTeamNameError={hasTeamNameError}
            isEditing={isEditing}
            isOrgChartTeam={!!team?.isOrgChartTeam}
            onBlurTeamName={onBlurTeamName}
            onChangeTeamName={onChangeTeamName}
            onClearAll={onClearAll}
            onCreateIcon={onCreateIcon}
            onCreateTeamMember={onCreateTeamMember}
            onDelete={onDelete}
            onEdit={onEdit}
            onRemoveTeamMember={onRemoveTeamMember}
            onSelectBoard={onSelectBoard}
            onSelectProject={onSelectProject}
            onToggleSprints={onToggleSprints}
            onUpdateTeamMembers={onUpdateTeamMembers}
            project={project}
            projects={projects}
            shouldUseSprints={shouldUseSprints}
            sprint={sprint}
            teamMembers={teamMembers}
            teamName={teamName as string}
          />
        )}
        {isEditing ? (
          <ButtonWrapper isNewTeam={isNewTeam}>
            <SubmitButton
              onClick={onSubmit}
              data-testid={`${modePrefix}-submit-button`}
            >
              {isNewTeam ? "Create Team" : "Save Changes"}
            </SubmitButton>
            <CancelButton
              onClick={onCancelEdit}
              data-testid={`${modePrefix}-cancel-button`}
            >
              Cancel
            </CancelButton>
          </ButtonWrapper>
        ) : null}
      </ContentWrapper>
      <Footer isEditing={isEditing}>
        {!!team?.createdByUser ? (
          <CreatedByName data-heap-redact-text="true">
            Created by: {team.createdByUser.name}
          </CreatedByName>
        ) : null}
        {isNewTeam ? null : (
          <DefaultTeamRadio
            isDefault={isDefault}
            onChangeDefaultTeam={onChangeDefaultTeam}
            teamName={teamName as string}
            testId={testId}
          />
        )}
      </Footer>
      {!!displayModal ? (
        <Modal
          dialogClassName="flexible-teams-modal"
          onHide={onCancelModal}
          popupContent={
            <TeamCardModalContent
              displayModal={displayModal}
              mode={modePrefix}
              onCancelDelete={onCancelDelete}
              onCancelRemoveTeamMember={onCancelRemoveTeamMember}
              onCancelSubmit={onCancelSubmit}
              onConfirmDelete={onConfirmDelete}
              onConfirmRemoveTeamMember={onConfirmRemoveTeamMember}
              onConfirmSubmit={onConfirmSubmit}
              testId={thisTestId}
            />
          }
          testId={testId}
        />
      ) : null}
    </Container>
  );
};

export default TeamCard;
