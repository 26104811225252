import { isUndefined, orderBy, startCase } from "lodash";
import { currentTheme } from "../../themes/currentTheme";

// constants
import {
  chartToolTipConfig,
  cycleTimeStageLabels,
  cycleTimeStages,
  timerangeTypes
} from "../../constants/constants";

// interfaces
import {
  OpMetricData,
  OpMetricSprintRecord,
  OpMetricValueTypes
} from "../../interfaces/ops-metrics";
import { PointEventsOptionsObject } from "highcharts";

// utils
import { getAdjustedTooltipPosition } from "../../utils/chart";
import { splitCycleTimeHours } from "../../utils/cycle-time";
import { getColorForDelta } from "../../utils/color";
import { formatTimestamp } from "../../utils/date";
import { getMetricUnit, getMetricValue } from "../../utils/operational-metrics";

type Props = {
  data: OpMetricData;
  isInverted: boolean;
  metric: OpMetricValueTypes;
  name: OpMetricValueTypes | keyof typeof cycleTimeStages;
};

const generateConfig = ({ data, isInverted, metric, name }: Props) => {
  const sortedData = orderBy(data, ["startDate"], ["asc"]);
  const isCycleTime = metric === "cycleTime";
  const dataToSeries = sortedData.map((record: OpMetricSprintRecord, index) => {
    const basePoint = {
      timerange: {
        start: record.startDate,
        end: record.endDate,
        timerangeType: !!record.sprintId
          ? timerangeTypes.SPRINT
          : timerangeTypes.CUSTOM,
        displayName: record.sprintName,
        id: record.sprintId
      },
      x: index
    };

    const metricValue = getMetricValue(record, metric);
    const metricUnit = getMetricUnit(record, metric);

    let pointValue, tooltipDisplayValue;
    if (metric === "cycleTime") {
      if (isUndefined(metricValue)) {
        pointValue = null;
        tooltipDisplayValue = null;
      } else {
        // convert to days
        pointValue = (metricValue as number) / 24;
        // convert to days,hours; discard any 0 values; create value/unit
        // string, join into string with comma if needed
        const positiveValues = splitCycleTimeHours(
          metricValue as number
        ).filter(record => record.value > 0);
        tooltipDisplayValue = positiveValues.length
          ? positiveValues
              .map(
                record =>
                  `${record.value}${record.unit.charAt(0).toLowerCase()}`
              )
              .join(", ")
          : "0h";
      }
    } else if (metricUnit === "PERCENT") {
      pointValue = (metricValue as number) * 100;
      tooltipDisplayValue = pointValue.toFixed(0);
    } else {
      pointValue = metricValue;
      tooltipDisplayValue = metricValue;
    }

    return {
      ...basePoint,
      y: pointValue,
      unit: metricUnit,
      value: tooltipDisplayValue,
      name:
        isCycleTime && !!cycleTimeStageLabels[name]
          ? cycleTimeStageLabels[name]
          : startCase(name)
    };
  });

  const dataToNumericSeries = dataToSeries.map(record => record.y);

  const seriesDelta =
    (dataToNumericSeries[dataToNumericSeries.length - 1] as number) -
    (dataToNumericSeries[0] as number);
  const color = getColorForDelta(seriesDelta, isInverted);

  return {
    chart: {
      backgroundColor: null,
      borderWidth: 0,
      type: "line",
      margin: [10, 0, 30, 0],
      height: metric === "cycleTime" ? 82 : 114,
      style: {
        overflow: "visible"
      }
      // small optimalization, saves 1-2 ms each sparkline
      // skipClone: true
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: sortedData.map((d, index) => {
        return index === 0 || index === sortedData.length - 1
          ? formatTimestamp({
              format: "MMM",
              timestamp: index === 0 ? d.startDate : d.endDate
            })
          : "";
      }),
      maxPadding: 0.05,
      minPadding: 0.05,
      labels: {
        // don't show the xaxis labels if for the cycle time stages
        enabled: !Object.values(cycleTimeStages).includes(name),
        style: {
          color: currentTheme.colors.all.wolverine,
          fontFamily: currentTheme.fonts.subheader.name,
          fontWeight: currentTheme.fonts.subheader.weights.bold
        },
        y: 20
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false,
      lineColor: currentTheme.colors.all.wolverine,
      tickWidth: 0,
      tickmarkPlacement: "on",
      showFirstLabel: true
    },
    yAxis: {
      maxPadding: 0.02,
      gridLineWidth: 0,
      min: 0,
      endOnTick: true,
      startOnTick: false,
      labels: {
        x: 5,
        y: 5,
        align: "left",
        enabled: true,
        formatter: function() {
          const context = this as any;
          if (context.isLast) {
            return metric === "cycleTime"
              ? `${context.value}d`
              : dataToSeries[0].unit === "PERCENT"
              ? `${context.value}%`
              : context.value;
          }
          if (context.isFirst) {
            return context.value.toString();
          }
          return "";
        }
      },
      title: {
        text: null
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        animation: false,
        color,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            halo: { size: 6 },
            lineWidth: 1
          }
        },
        marker: {
          radius: 2,
          states: {
            hover: {
              radius: 3
            }
          }
        },
        fillOpacity: 0.25
      }
    },
    tooltip: {
      ...chartToolTipConfig,
      positioner: function(
        w: number,
        h: number,
        point: PointEventsOptionsObject
      ) {
        return getAdjustedTooltipPosition({
          currentPosition: (this as any).getPosition(w, h, point),
          windowScroll: window.scrollY
        });
      }
    },
    series: [
      {
        name: "sparkline",
        type: "line",
        data: dataToSeries
      }
    ]
  };
};

export default generateConfig;
