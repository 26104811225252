import React from "react";
import styled from "styled-components/macro";

// components
import EnabledJiraProjects from "../EnabledJiraProjects/EnabledJiraProjects";
import EnabledRepositoriesTable from "../EnabledRepositoriesTable/EnabledRepositoriesTable";
import SettingsDefaultGroupBySelector from "../SettingsDefaultGroupBySelector/SettingsDefaultGroupBySelector";
import SupportEmailLink from "../SupportEmailLink/SupportEmailLink";

// styled components
const Container = styled.div`
  padding-top: 3rem;
`;
const IntroText = styled.p`
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 0rem;
`;
const Subgrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
`;
const Header = styled.h4`
  margin-top: 1rem;
  align-items: center;
  display: flex;
  font-size: inherit;
  font-weight: ${props => props.theme.fonts.primary.weights.book};
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const SettingsProjectsAndRepos = ({ className, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-settings-projects-and-repos`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <IntroText>
        Projects and Repositories are enabled in the Uplevel Connector Hub and
        can be added by reaching out to{" "}
        <SupportEmailLink name="settings" testId={thisTestId} />.
      </IntroText>
      <Grid>
        <div>
          <Header>Change how content is displayed</Header>
          <SettingsDefaultGroupBySelector />
        </div>
        <div>
          <Header>Data your organization provides to Uplevel</Header>
          <Subgrid>
            <EnabledJiraProjects />
            <EnabledRepositoriesTable />
          </Subgrid>
        </div>
      </Grid>
    </Container>
  );
};

export default SettingsProjectsAndRepos;
