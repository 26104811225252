import { captureException } from "@sentry/react";
import api from "./api";

export const requestMeetingClassifications = async ({
  accessToken,
  params,
  tenantId
}) => {
  if (
    !("users" in params) ||
    !("startDate" in params) ||
    !("endDate" in params) ||
    !Array.isArray(params.users)
  ) {
    return captureException(
      `Expected params with keys of "userIds" as value type String[], "startDate" as String, and "endDate" as value type String, but received ${JSON.stringify(
        params
      )}`
    );
  }
  const response = await api.post(`/${tenantId}/meetingclassifier`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const requestMeetingClassifications_privateic = async ({
  accessToken,
  params,
  tenantId
}) => {
  if (
    !("startDate" in params) ||
    !("endDate" in params) ||
    !("userEmail" in params)
  ) {
    return captureException(
      `Expected params with keys of "userEmail", "startDate" and "endDate" as String, but received ${JSON.stringify(
        params
      )}`
    );
  }
  const response = await api.post(
    `/${tenantId}/private-ic/meetingclassifier`,
    params,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return response.data;
};
