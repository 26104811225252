import React, { useState } from "react";
import styled from "styled-components/macro";

// components
import Button from "../Button/Button";
import ContextDrawer from "../ContextDrawer/ContextDrawer";
import EpicFilter from "../EpicFilter/EpicFilter";
import Icon from "../Icon/Icon";

// hooks
import { useTracking } from "../../hooks/useTracking";
import { useRouteMatch } from "react-router-dom";

// styled components
const Sliders = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  margin-right: 1rem;
`;
const Trigger = styled(Button)`
  align-items: center;
  background: ${props => props.theme.colors.all.white};
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  justify-content: center;
  padding: 0.95rem 2rem;
`;
const FilterPanel = styled(EpicFilter)`
  flex: 1;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const EpicFilterDrawerAndTrigger = ({
  className,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-filter-drawer-and-toggle`;
  const { trackEvent } = useTracking();
  const [epicFilterDrawerIsOpen, setEpicFilterDrawerIsOpen] = useState(false);
  const match = useRouteMatch();

  function onClickTrigger(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.persist();
    trackEvent({
      e,
      label: `filter-bar-trigger`,
      value: `clicked open at page ${match.url}`
    });
    setEpicFilterDrawerIsOpen(true);
  }

  function onCloseDrawer(e: React.MouseEvent<MouseEvent>) {
    e.persist();
    trackEvent({
      e,
      label: `filter-bar-unfocus`,
      value: `clicked close at page ${match.url}`
    });
    setEpicFilterDrawerIsOpen(false);
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <Trigger button="unstyled" onClick={onClickTrigger} testId={thisTestId}>
        <Sliders icon="sliders" testId={thisTestId} />
        Filters
      </Trigger>
      <ContextDrawer
        isOpen={epicFilterDrawerIsOpen}
        onCloseDrawer={onCloseDrawer}
        testId={thisTestId}
      >
        <FilterPanel
          onClickClose={() => setEpicFilterDrawerIsOpen(false)}
          testId={thisTestId}
        />
      </ContextDrawer>
    </div>
  );
};

export default EpicFilterDrawerAndTrigger;
