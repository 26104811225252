import { pageIds } from "../constants/constants";

// todo: update this when we land on a url scheme
export const parsePageIdFromPathname = (pathname: string): string => {
  const urlParams = pathname.substring(1).split("/");
  if (
    !!urlParams[2] &&
    !!urlParams[1] &&
    urlParams[0] === pageIds.WORK_DEEP_DIVE
  ) {
    return pageIds.EPIC_DETAILS;
  } else if (!urlParams[0]) {
    return pageIds.START;
  }
  return urlParams[0];
};

export const getPageTitle = (pageTitle?: string): string => {
  const baseTitle = "Uplevel";
  if (pageTitle) {
    return `${pageTitle} - ${baseTitle}`;
  }
  return baseTitle;
};
