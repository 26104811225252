import React, { useState } from "react";
import PropTypes from "prop-types";

import CommentsList from "../CommentsList/CommentsList";
import ReactionPills from "./ReactionPills";
import Icon from "../Icon/Icon";
import styled from "styled-components/macro";
import Modal from "../Modal/Modal";
import { useTracking } from "../../hooks/useTracking";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  margin-right: 0.8rem;
`;
const CommentButton = styled("button")`
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.lightJean}`};
  background-color: ${props => props.theme.colors.all.white};
  font-size: 1.1rem;
  padding: 0.5rem;
  margin-right: 1rem;
`;

const CommentTextArea = styled("textarea")`
  align-self: center;
  width: 100%;
  border: none;
  height: 11rem;
`;

const SubmitButton = styled("input")`
  max-width: 12rem;
  height: 3.2rem;
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.all.storm
      : props.theme.colors.all.auroraTeal};
  align-self: flex-end;
  color: ${props => props.theme.colors.all.white};
  border: none;
  font-size: 1.3rem;
`;

const Subheader = styled.div`
  border-bottom: ${props => `1px solid ${props.theme.colors.all.jean}`};
  font-size: 1.3rem;
  margin-top: ${props => `${!!props.write ? "3.2rem" : "1.6rem"}`};
  padding-bottom: 0.8rem;
  line-height: 1;
`;

const CommentForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 0.8rem;
`;

const AnnotationsModal = ({
  className,
  context,
  annotations,
  teamMembers,
  handleAnnotationClick,
  triggerWriteTextComment,
  setActiveIdToAnnotate,
  isModalOpen,
  headerContent,
  testId = "testId"
}) => {
  const [textComment, setTextComment] = useState("");
  const { trackEvent } = useTracking();
  const thisTestId = `${testId}-annotations-modal`;

  function onShowModal(event, context) {
    trackEvent({
      e: event,
      label: `${thisTestId}-open`,
      value: `opened annotation modal for ${context.id}`
    });
  }

  return (
    <>
      <CommentButton
        className={className}
        data-testid={`${testId}-annotations-modal-trigger`}
        onClick={() => setActiveIdToAnnotate(context.id)}
      >
        <span>
          <CommentIcon icon="comment" testId="thisTestId" className="" />{" "}
          Comment
        </span>
      </CommentButton>
      {isModalOpen === true && (
        <Modal
          dialogClassName="annotation-modal"
          onHide={() => setActiveIdToAnnotate("")}
          onShow={event => onShowModal(event, annotations)}
          popupContent={
            <ModalContainer>
              <ReactionPills
                annotations={annotations}
                contextItem={context}
                handleAnnotationClick={handleAnnotationClick}
              />
              <Subheader>
                <CommentIcon icon="comment" testId="thisTestId" className="" />{" "}
                Comments (
                {
                  annotations.filter(
                    annotation => annotation.userDetailedComment !== ""
                  ).length
                }
                )
              </Subheader>
              <CommentsList
                comments={annotations.filter(
                  annotation => annotation.userDetailedComment !== ""
                )}
                teamMembers={teamMembers}
                testId={thisTestId}
              />
              <Subheader write={true}>Write a comment</Subheader>
              <CommentForm
                onSubmit={event => {
                  triggerWriteTextComment(context, textComment, event);
                  setTextComment("");
                }}
              >
                <label>
                  <CommentTextArea
                    value={textComment}
                    onChange={event => setTextComment(event.target.value)}
                    data-testid={`${testId}-comment-text-area`}
                  />
                </label>
                <SubmitButton
                  disabled={!textComment}
                  type="submit"
                  value="Add Comment"
                  data-testid={`${testId}-submit`}
                />
              </CommentForm>
            </ModalContainer>
          }
          headerContent={headerContent || null}
          testId={thisTestId}
        />
      )}
    </>
  );
};

AnnotationsModal.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string
};
export default AnnotationsModal;
