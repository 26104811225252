import React from "react";
import styled from "styled-components/macro";
import workProgressText from "../../constants/workProgressText.json";

// components
import InfoBook from "../InfoBook/InfoBook";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";

// hooks
import { useSprintSuccessData } from "../../hooks/useSprintSuccessData";

// styled components
const SuccessContent = styled.div`
  align-items: baseline;
  display: flex;
  line-height: 1;
`;
const SuccessValue = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  font-size: 2rem;
`;
const Header = styled.h4`
  align-items: center;
  display: flex;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin-right: 0.5rem;

  &::after {
    content: ":";
  }

  & > div {
    margin: 0.5rem;
  }
`;
const Book = styled(InfoBook)`
  margin: 0 0.5rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const SprintSuccess = ({
  className,
  testId = "testId"
}: Props): React.ReactElement | null => {
  const thisTestId = `${testId}-sprint-success`;
  const { data, isIdle, isLoading } = useSprintSuccessData();
  const textContent = workProgressText.sprint.projectedDone.definition;

  return isIdle || isLoading ? null : (
    <div className={className} data-testid={thisTestId}>
      <TransitionWrapper testId={thisTestId}>
        <SuccessContent>
          <Header>
            <span>Projected done</span>
            <Book
              content={textContent.content}
              header={textContent.header}
              link={textContent.link}
              name={thisTestId}
              testId={thisTestId}
            />
          </Header>
          <div>
            <SuccessValue>{data?.value.toFixed(0)}</SuccessValue>%
          </div>
        </SuccessContent>
      </TransitionWrapper>
    </div>
  );
};

export default SprintSuccess;
