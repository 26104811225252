import { useMemo } from "react";
import { sortBy } from "lodash";

// hooks
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useUrlParams } from "./useUrlParams";

// interfaces
import { DeepDiveData } from "../interfaces/team-deep-dive";
import { AppStateInterface } from "../interfaces/app-state";

// utils
import {
  requestTeamDeepDiveData,
  transformTeamDeepDiveData
} from "../utils/team-deep-dive";
import { UserReportWithMetadataInterface } from "../interfaces/user";

interface HookValues {
  data?: DeepDiveData;
  isLoading: boolean;
  isError: boolean;
}

export const useTeamDeepDiveData: (
  teamMembersToRequest?: Array<UserReportWithMetadataInterface>
) => HookValues = (teamMembersToRequest = []) => {
  const accessToken: string = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const { urlParams } = useUrlParams();
  const sprintId = urlParams.sprintId;
  const endDate = urlParams.endDate;

  const tenantId: number = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const teamId = urlParams.team?.teamId;
  const teamMemberIds = useMemo(() => teamMembersToRequest.map(t => t.id), [
    teamMembersToRequest
  ]);
  const params = useMemo(
    () => ({
      accessToken,
      tenantId,
      params: !!sprintId
        ? {
            users: teamMemberIds,
            endDate,
            endSprintId: sprintId,
            teamId
          }
        : {
            users: teamMemberIds,
            endDate,
            teamId
          }
    }),
    [accessToken, endDate, sprintId, teamMemberIds, tenantId, teamId]
  );

  return useQuery(
    ["team-deep-dive", params],
    () =>
      // TODO: remove this temporary transform on the fetched data once the backend sends updated keys
      requestTeamDeepDiveData(params).then(fetchedData =>
        sortBy(transformTeamDeepDiveData(fetchedData), record => record.dateEnd)
      ),
    {
      enabled:
        !!accessToken && !!tenantId && !!teamMemberIds.length && !!endDate
    }
  );
};
