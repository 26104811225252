import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";
import React, { useState } from "react";

import styled from "styled-components/macro";
import Table from "../Table/Table";
import { isNull } from "lodash";
import Spinner from "../Spinner/Spinner";
import { useBackingData } from "./useBackingData";
import { Repository } from "../../interfaces/repository";
import PaginationStepper from "../PaginationStepper/PaginationStepper";

// styled components
const ContentWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
  padding: 1.2rem 2rem 2rem;
  height: 62rem;
  display: flex;
  flex-direction: column;
`;
const Header = styled.header`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.h1`
  align-items: baseline;
  display: flex;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 2rem;
`;
const StyledTable = styled(Table)`
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.brand.jean}`};
  & thead {
    border-top: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.brand.jean}`};
    border-bottom: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.brand.jean}`};
  }
  & th {
    font-size: ${props => props.theme.fonts.subheader.sizes.xs};
    padding: 0.5rem 0 0.5rem 1rem;
    text-align: left;
  }
  & tbody {
    & tr + tr {
      border-top: ${props =>
        `${props.theme.borders.widths.sm} solid ${props.theme.colors.brand.jean}`};
    }
  }
  & td {
    font-size: ${props => props.theme.fonts.primary.sizes.xs};
    padding-right: 0;
    padding-left: 1rem;
    text-align: left;
    vertical-align: middle;
  }
  & td + td {
    border-left: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.brand.jean}`};
  }
`;
const PaginationStepperWrapper = styled.div`
  margin-bottom: 2rem;
  margin-top: auto;
`;
const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 30rem;
  width: 100%;
`;

type Props = {
  className?: string;
  manualState?: Repository[];
  testId?: string;
};
const EnabledRepositoriesTable = ({
  className,
  testId = "testId",
  manualState
}: Props) => {
  const thisTestId = `${testId}-settings-jiraprj`;
  const [currentPage, _setCurrentPage] = useState(0);

  const [data, isFetchingData, error] = useBackingData(manualState);
  const pageSize = 15;
  const columns = [
    {
      Header: "Repository",
      Cell: ({ cell }: { cell: { value: string } }) => (
        <span data-heap-redact-text="true">{cell.value}</span>
      ),
      accessor: "repositoryName",
      className: "repositoryName"
    },
    {
      Header: "Project",
      Cell: ({ cell }: { cell: { value: string } }) => (
        <span data-heap-redact-text="true">{cell.value}</span>
      ),
      accessor: "projectName",
      className: "projectName"
    }
  ];

  if (error || !data.length) {
    return null;
  }

  let tableData = data?.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  function onChangePaginationStepper({ updatedPage }: { updatedPage: number }) {
    _setCurrentPage(updatedPage);
    tableData = data?.slice(
      updatedPage * pageSize,
      (updatedPage + 1) * pageSize
    );
  }

  let displayContent;
  if (isNull(isFetchingData)) {
    displayContent = (
      <SpinnerWrapper>
        <Spinner testId={testId} />
      </SpinnerWrapper>
    );
  } else {
    displayContent = (
      <ContentWrapper>
        <Header>
          <Title>Enabled Repositories </Title>
        </Header>
        <StyledTable columns={columns} data={tableData} />
        <PaginationStepperWrapper>
          <PaginationStepper
            currentPage={currentPage}
            finalPage={
              data.length < pageSize
                ? 0
                : Math.floor(data.length / pageSize) - 1
            }
            onChange={onChangePaginationStepper}
            name="enabled-repositories-pagination"
            testId={thisTestId}
          />
        </PaginationStepperWrapper>
      </ContentWrapper>
    );
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <TransitionWrapper testId={thisTestId}>
        {displayContent}
      </TransitionWrapper>
    </div>
  );
};

export default EnabledRepositoriesTable;
