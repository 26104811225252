import React, { ReactElement, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { compact, isNull, partition, sortBy } from "lodash";

// components
import Dropdown from "../Dropdown/Dropdown";

// constants
import { roleTypes, urlParamKeys } from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { SelectOptionsInterface } from "../../interfaces/controls";
import { SimpleTeam } from "../../interfaces/team";

// utils
const createRoleOptions = (roles: Array<string> | null) =>
  isNull(roles)
    ? []
    : sortBy(
        roles.map(role => {
          return {
            label: roleTypes[role],
            value: role,
            selected: false
          };
        }),
        "label"
      );
const createReportGroupOptions = (reportGroups: Array<string> | null) =>
  isNull(reportGroups)
    ? []
    : sortBy(
        reportGroups.map(reportGroup => {
          return {
            label: reportGroup,
            value: reportGroup,
            selected: false
          };
        }),
        "reportGroup"
      );
const createTeamOptions = (
  teams: Array<string>,
  availableTeams: Array<SimpleTeam> | null
) =>
  isNull(availableTeams)
    ? []
    : sortBy(
        compact(
          teams.map(teamId => {
            const teamMetadata = availableTeams.find(t => t.teamId === teamId);
            return !!teamMetadata
              ? {
                  label: teamMetadata.teamName,
                  value: teamId,
                  selected: false
                }
              : null;
          })
        ),
        "label"
      );

// styled components
const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem 0;
`;
const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
`;
const Select = styled(Dropdown)`
  align-items: stretch;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
`;

// typescript props
type Props = {
  className?: string;
  availableRoles: Array<string> | null;
  availableReportGroups: Array<string> | null;
  availableTeams: Array<SimpleTeam> | null;
  testId?: string;
};

const OverviewFilters = ({
  className,
  availableRoles,
  availableReportGroups,
  availableTeams,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-overview-filters`;
  const { updateUrlParams, urlParams } = useUrlParams();
  const selectedReportGroups = urlParams.reportGroups;
  const selectedRoles = urlParams.roles;
  const selectedTeams = urlParams.teams;
  const partitionedTeams = useMemo(
    () => partition(availableTeams, t => t.isOrgChartTeam),
    [availableTeams]
  );
  const flexTeams = partitionedTeams[1];
  const selectedPartitionedTeamIds = useMemo(
    () =>
      partitionedTeams.map(teams =>
        teams.filter(t => selectedTeams.includes(t.teamId)).map(t => t.teamId)
      ),
    [partitionedTeams, selectedTeams]
  );

  // ROLES
  const onChangeRole = (selected: Array<SelectOptionsInterface>) => {
    updateUrlParams({
      [urlParamKeys.ROLES]: selected.map(s => s.value)
    });
  };
  const allRoleOptions = useMemo(() => createRoleOptions(availableRoles), [
    availableRoles
  ]);
  const selectedRoleOptions = useMemo(() => createRoleOptions(selectedRoles), [
    selectedRoles
  ]);

  // REPORT GROUPS
  const onChangeReportGroup = (selected: Array<SelectOptionsInterface>) => {
    updateUrlParams({
      [urlParamKeys.REPORT_GROUPS]: selected.map(s => s.value)
    });
  };
  const allReportGroupOptions = useMemo(
    () => createReportGroupOptions(availableReportGroups),
    [availableReportGroups]
  );
  const selectedReportGroupOptions = useMemo(
    () => createReportGroupOptions(selectedReportGroups),
    [selectedReportGroups]
  );

  // TEAMS
  const onChangeOrgTeam = (selected: Array<SelectOptionsInterface>) => {
    updateUrlParams({
      [urlParamKeys.TEAMS]: [
        ...selected.map(s => s.value),
        ...selectedPartitionedTeamIds[1]
      ]
    });
  };
  const onChangeFlexTeam = (selected: Array<SelectOptionsInterface>) => {
    updateUrlParams({
      [urlParamKeys.TEAMS]: [
        ...selected.map(s => s.value),
        ...selectedPartitionedTeamIds[0]
      ]
    });
  };
  const [allOrgTeamOptions, allFlexTeamOptions] = useMemo(
    () =>
      partitionedTeams.map(teams =>
        createTeamOptions(
          teams.map(t => t.teamId),
          availableTeams
        )
      ),
    [partitionedTeams, availableTeams]
  );
  const [selectedOrgTeamOptions, selectedFlexTeamOptions] = useMemo(
    () =>
      selectedPartitionedTeamIds.map(selectedTeamIds =>
        createTeamOptions(selectedTeamIds, availableTeams)
      ),
    [availableTeams, selectedPartitionedTeamIds]
  );

  return (
    <Container className={className} data-testid={thisTestId}>
      <FiltersContainer>
        {!!availableRoles?.length ? (
          <Select
            bordered={true}
            isMulti={true}
            label="Role"
            placeholder="All Roles"
            onChangeMulti={onChangeRole}
            options={allRoleOptions}
            shouldShowLabel={false}
            value={selectedRoleOptions}
            testId={thisTestId}
          />
        ) : null}
        {!!availableReportGroups?.length ? (
          <Select
            bordered={true}
            isMulti={true}
            label="Report Group"
            placeholder="All Report Groups"
            onChangeMulti={onChangeReportGroup}
            options={allReportGroupOptions}
            shouldShowLabel={false}
            value={selectedReportGroupOptions}
            testId={thisTestId}
          />
        ) : null}
        {!!flexTeams.length ? (
          <Select
            bordered={true}
            isMulti={true}
            label="Flex Team"
            placeholder="All Flex Teams"
            onChangeMulti={onChangeFlexTeam}
            options={allFlexTeamOptions}
            shouldShowLabel={false}
            value={selectedFlexTeamOptions}
            testId={thisTestId}
          />
        ) : null}
        <Select
          bordered={true}
          isMulti={true}
          label="Manager"
          placeholder="All Managers"
          onChangeMulti={onChangeOrgTeam}
          options={allOrgTeamOptions}
          shouldShowLabel={false}
          value={selectedOrgTeamOptions}
          testId={thisTestId}
        />
      </FiltersContainer>
    </Container>
  );
};

export default OverviewFilters;
