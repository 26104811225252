// utils
import { requestUserInfo } from "../../utils/user";

type Props = {
  accessToken: string;
  tenantId: number;
};
export default async function fetchUserData({ accessToken, tenantId }: Props) {
  return await requestUserInfo({
    accessToken,
    tenantId
  });
}
