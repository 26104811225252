import { captureException } from "@sentry/react";
import api from "./api";

export const requestStoryPoints = async ({ accessToken, params, tenantId }) => {
  if (!("startDate" in params) || !("endDate" in params)) {
    return captureException(
      `Expected params with keys of "startDate" as String, and "endDate" as value type String, but received ${JSON.stringify(
        params
      )}`
    );
  }
  const response = await api.post(`/${tenantId}/storypointsbyuser`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const requestStoryPoints_privateic = async ({
  accessToken,
  params,
  tenantId
}) => {
  if (
    !("startDate" in params) ||
    !("endDate" in params) ||
    !("userEmail" in params)
  ) {
    return captureException(
      `Expected params with keys of "userEmail", "startDate" and "endDate" as String, but received ${JSON.stringify(
        params
      )}`
    );
  }
  const response = await api.post(
    `/${tenantId}/private-ic/storypointsbyuser`,
    params,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return response.data;
};
