import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  isEmpty,
  toPairs,
  flatten,
  startCase,
  capitalize,
  sortBy,
  isNil,
  omit,
  omitBy,
  kebabCase
} from "lodash";
import { useSelector } from "react-redux";

// components
import SignalTypeContainer from "../SignalTypeContainer/SignalTypeContainer";
import SlackAvatar from "../SlackAvatar/SlackAvatar";
import Spinner from "../Spinner/Spinner";
import Icon from "../Icon/Icon";
import RouteLink from "../RouteLink/RouteLink";
import Tooltip from "../Tooltip/Tooltip";
import InfoIcon from "../InfoIcon/InfoIcon";
import WarningTriangle from "../WarningTriangle/WarningTriangle";

// types
import {
  UserReportWithMetadataInterface,
  Signal,
  PullRequestSignals
} from "../../interfaces/user";
import { AppStateInterface } from "../../interfaces/app-state";

// constants
import {
  PullRequestStalledOptions,
  workMetadataActivityTypes,
  pageIds,
  Signals,
  signalTypes,
  urlParamKeys,
  teamDeepDiveSections,
  workDeepDiveSections,
  peopleHealthSignals,
  ownerContributorTypes
} from "../../constants/constants";

import informationText from "../../constants/informationText.json";

import { hexToRgb } from "../../utils/color";
import ReportsCard from "../ReportsCard/ReportsCard";
import { partitionSignals } from "../../utils/user";
import { TeamInterface } from "../../interfaces/team";
import { useOrganizationData } from "../../hooks/useOrganizationData";
import { useUrlParams } from "../../hooks/useUrlParams";
import { getFlattenedTeamMembersTreeForTeam } from "../../utils/teams";
import { useUserSettings } from "../../hooks/useUserSettings";

// styled components
const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${props =>
    `${hexToRgb({ hex: props.theme.colors.all.lightJean, opacity: 0.5 })}`};
  width: 26.4rem;
`;
const AvatarWrapper = styled.div`
  margin-right: 1rem;
  max-width: 9.9rem;
`;
const Avatar = styled(SlackAvatar)`
  border-radius: 50%;
  height: 6.3rem;
  width: 6.3rem;
  border: ${props =>
    `${props.theme.borders.widths.md} solid ${props.theme.colors.border}`};
`;
const Name = styled.header`
  font-family: ${props => props.theme.fonts.header.name}, serif;
  font-size: ${props => props.theme.fonts.header.sizes.sm};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
`;
const Title = styled.span`
  font-family: ${props => props.theme.fonts.primary.name}, serif;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin-bottom: 0.5rem;
`;
const SignalContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;
const TileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 1rem;
`;
const NameAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;
const HealthySignal = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
`;
const InlineIcon = styled(Icon)`
  margin-right: 0.5rem;
`;
const PrPill = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: ${props => `${props.theme.colors.all.jean}`};
  border-radius: 16px;
  padding: 0.5rem 0.75rem;
  margin-top: 0.5rem;
  cursor: pointer;
  color: ${props => `${props.theme.colors.all.wolverine}`};
  max-width: 20rem;
`;

const AllocationPill = styled.div<{ allocationPercent: number }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${props =>
    `linear-gradient(to right, ${props.theme.colors.all.iceman} ${
      props.allocationPercent
    }%, 0%, ${props.theme.colors.all.white} ${props.allocationPercent -
      100}%);`};
  border-radius: 16px;
  padding: 0.5rem 0.75rem;
  margin-top: 0.5rem;
  cursor: pointer;
  max-width: 25rem;
  font-family: ${props => props.theme.fonts.primary.name}, serif;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

const AllocationText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PrText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AllocationSection = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const AllocationHeader = styled.div`
  display: inline-flex;
  font-family: ${props => props.theme.fonts.primary.name}, serif;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  border-bottom: ${props => `1px solid ${props.theme.colors.all.storm}`};
  width: 100%;
`;

const AllocationExpander = styled.div`
  font-family: ${props => props.theme.fonts.primary.name}, serif;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  align-self: flex-end;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  margin-top: 0.8rem;
`;
const HealthySignalsValue = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
`;
const AllocationValue = styled.strong`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  margin-right: 0.6154em;
`;

const NoAllocationMsg = styled.div`
  font-family: ${props => props.theme.fonts.primary.name}, serif;
  font-weight: ${props => props.theme.fonts.primary.weights.semiLight};
  font-style: italic;
  font-size: 1.1rem;
  padding-top: 1rem;
`;

const SignalTooltip = styled.div`
  font-size: 1.1rem;
  width: 40rem;
`;
const SignalType = styled(SignalTypeContainer)`
  margin-bottom: 0.5rem;
  width: 100%;
`;
const UnhealthyTooltip = styled(Tooltip)`
  // TODO: revisit our tooltips and see if the inline-block on the trigger is needed
  & > div {
    display: block;
  }
`;
const Link = styled(RouteLink)`
  color: ${props => props.theme.colors.all.wolverine};

  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
    text-decoration: none;
  }
`;

// typescript props
type Props = {
  className?: string;
  person: UserReportWithMetadataInterface;
  isFetchingData?: boolean;
  testId?: string;
};

export const PersonTile = ({
  className,
  isFetchingData = false,
  person,
  testId = "testId"
}: Props): React.ReactElement => {
  const { image, name, title, signals, allocations = [] } = person;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const hasPeopleHealthData = flags?.["people-health-insights"];

  const { getWorkDeepDivePath, getTeamDeepDivePath } = useUrlParams();
  const { data: userSettings } = useUserSettings();
  const displaySignals = omitBy(
    hasPeopleHealthData
      ? !!signals?.ALWAYS_ON?.[0]?.status
        ? signals
        : omit(signals, ["ALWAYS_ON"])
      : omit(signals, peopleHealthSignals),
    (s: Signal | PullRequestSignals | []) => !s[0]?.status
  );

  // organization
  const { data: orgChartRootTeam } = useOrganizationData();

  const [
    isShowingExpandedAllocations,
    setIsShowingExpandedAllocations
  ] = useState(false);

  const prettyPrint = (str: string) => capitalize(startCase(str));

  const NUM_ALLOCATIONS_TO_SHOW = 3;

  const sortedAllocations = sortBy(allocations, "allocation").reverse();
  const allocationsToShow = isShowingExpandedAllocations
    ? sortedAllocations
    : sortedAllocations.slice(0, NUM_ALLOCATIONS_TO_SHOW);

  const [unhealthySignals, healthySignals] = partitionSignals(displaySignals);
  const pullRequests = flatten(
    toPairs(displaySignals).filter(
      signal => signal[0] === workMetadataActivityTypes.PR
    )
  )?.[1] as PullRequestSignals;

  const pullRequestItems = pullRequests
    ? flatten(pullRequests.map(category => category.items))
    : [];

  const pullRequestStatuses = pullRequests?.map(pr => pr.status) || [];

  const alertCount = unhealthySignals.length + pullRequestItems.length;
  function getTeam(userId: number): TeamInterface | undefined {
    const flattenedOrganization = getFlattenedTeamMembersTreeForTeam(
      orgChartRootTeam
    );
    return flattenedOrganization.find(u => u.id === userId)?.team;
  }

  function getSignalEndpoint(signal: string): string | undefined {
    // No link for you if your Feature Flag is off!
    return flags?.[pageIds.TEAM_DEEP_DIVE]
      ? getTeamDeepDivePath({
          querystringParams: {
            [urlParamKeys.SELECTED_USER]: person.email
          },
          selectedSection: teamDeepDiveSections.HEALTH_METRICS,
          selectedMetric: signal
        })
      : undefined;
  }

  return (
    <>
      <Container className={className} data-testid={`${testId}-person-card`}>
        <TileHeader data-heap-redact-text="true">
          <div>
            {alertCount > 0 && (
              <WarningTriangle baseWidth={18} alertCount={alertCount} />
            )}
            <AvatarWrapper>
              <Avatar
                altText={`${name} image`}
                src={image}
                testId={`${testId}-person-card`}
              />
            </AvatarWrapper>
          </div>
          <NameAndTitle>
            <Name>{name}</Name>
            <Title>{title}</Title>
          </NameAndTitle>
        </TileHeader>
        {isFetchingData ? (
          <Spinner testId={testId} />
        ) : (
          <div>
            {// the pr's are each in their own seperate "item" within a signal, so we are flattening them together to treat them equally when mapping to render.
            // Better to do it here so we still have them together as "unhealthy" for the counts to display before
            pullRequests && pullRequests.length > 0 && (
              <SignalType
                content={pullRequests.map(
                  (pr: {
                    status: string;
                    items: Array<{ name: string; url: string }>;
                  }) => {
                    const statusName = PullRequestStalledOptions.find(
                      (option: { name: string; id: string }) => {
                        return option?.id === pr.status;
                      }
                    )?.["name"];
                    // TODO: make this text lookup less hack
                    return pr.items.map(prRecord => {
                      return (
                        <Link
                          to={getWorkDeepDivePath({
                            selectedSection: workDeepDiveSections.PR_WORKFLOW,
                            persistQuerystring: true,
                            querystringParams: {
                              [urlParamKeys.SELECTED_USER]: person.email,
                              [urlParamKeys.CALLOUTS]: pullRequestStatuses,
                              [urlParamKeys.OWNER_CONTRIBUTOR]: [
                                ownerContributorTypes.CONTRIBUTOR,
                                ownerContributorTypes.OWNER
                              ]
                            }
                          })}
                          name="allocation-epic-link"
                          key={kebabCase(prRecord.name)}
                        >
                          <Tooltip
                            placement="top"
                            popupContent={prRecord.name}
                            testId={testId}
                            trigger={
                              <PrPill>
                                <PrText>
                                  <strong>
                                    {statusName && statusName.includes("Stuck")
                                      ? "Stuck: "
                                      : statusName?.includes("MERGED")
                                      ? "No Approval: "
                                      : "High Discussion: "}
                                  </strong>
                                  {prRecord.name}
                                </PrText>
                              </PrPill>
                            }
                          />
                        </Link>
                      );
                    });
                  }
                )}
                header={
                  <span>
                    <InlineIcon icon="pr" />
                    <strong>
                      {pullRequestItems.length === 1 ? "PR" : "PRs"}:{" "}
                      {pullRequestItems.length}{" "}
                    </strong>
                  </span>
                }
                isExpandable={true}
                testId={`person-tile-pr-callouts`}
                type={signalTypes.UNHEALTHY}
              />
            )}
            {!isEmpty(unhealthySignals) &&
              unhealthySignals
                .reverse()
                .map((signal: { key: string; value: Signal }) => {
                  const { key, value } = signal;

                  if (!value[0].status) {
                    return null;
                  }

                  // other healthy signals are easy, we can just pretty print the key and follow it up with it's relevant item status
                  if (value.length > 0) {
                    const popupContent =
                      key === Signals.ALWAYS_ON
                        ? informationText["always-on"]
                        : key === Signals.CONTEXT_SWITCHING
                        ? informationText["context-switching"]
                        : key === Signals.DEEP_WORK
                        ? informationText["deep-work"]
                        : key === Signals.CHAT_INTERRUPTIONS
                        ? informationText["chat-interruptions"]
                        : "";

                    const signalEndpoint = getSignalEndpoint(key);
                    const signalContents = (
                      <SignalContainerHeader>
                        <span>
                          <b>{prettyPrint(key)}</b> -{" "}
                          {prettyPrint(value[0].status)}
                        </span>
                        <Icon icon={"line-arrow-right"} />
                      </SignalContainerHeader>
                    );
                    const signalTypeTrigger = !!signalEndpoint ? (
                      <Link to={signalEndpoint} name="signal-link">
                        {signalContents}
                      </Link>
                    ) : (
                      signalContents
                    );

                    return (
                      <SignalType
                        header={
                          <UnhealthyTooltip
                            placement="top"
                            popupContent={
                              <SignalTooltip>{popupContent}</SignalTooltip>
                            }
                            trigger={signalTypeTrigger}
                            testId={`unhealthy-signal-${key}`}
                          />
                        }
                        testId={`people-tile-other-callouts`}
                        type={signalTypes.UNHEALTHY}
                        key={`unhealthy-signal-${key}`}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            {!isEmpty(healthySignals) && (
              <SignalType
                content={healthySignals.map(
                  (signal: { key: string; value: Signal }) => {
                    if (!signal.value[0].status) {
                      return null;
                    }
                    if (signal.value.length > 0) {
                      const popupContent =
                        signal.key === Signals.ALWAYS_ON
                          ? informationText["always-on"]
                          : signal.key === Signals.CONTEXT_SWITCHING
                          ? informationText["context-switching"]
                          : signal.key === Signals.DEEP_WORK
                          ? informationText["deep-work"]
                          : signal.key === Signals.CHAT_INTERRUPTIONS
                          ? informationText["chat-interruptions"]
                          : "";
                      const signalEndpoint = getSignalEndpoint(signal.key);
                      const signalContents = (
                        <HealthySignal>
                          <Tooltip
                            placement="top"
                            popupContent={
                              <SignalTooltip>{popupContent}</SignalTooltip>
                            }
                            trigger={
                              <>
                                <InlineIcon icon={"circle-check"} />
                                <b>{prettyPrint(signal.key)}</b> -{" "}
                                {prettyPrint(signal.value[0].status)}
                              </>
                            }
                            testId={`healthy-signal-${signal.key}`}
                          />
                        </HealthySignal>
                      );
                      return !!signalEndpoint ? (
                        <Link
                          to={signalEndpoint}
                          name="signal-link"
                          key={signalEndpoint}
                        >
                          {signalContents}
                        </Link>
                      ) : (
                        signalContents
                      );
                    } else {
                      return null;
                    }
                  }
                )}
                header={
                  <span>
                    <HealthySignalsValue>
                      {healthySignals.length}
                    </HealthySignalsValue>{" "}
                    {`Healthy Signal${healthySignals.length > 1 ? "s" : ""}`}
                  </span>
                }
                isExpandable={true}
                testId={`people-tile-healthy-signals`}
                type={signalTypes.HEALTHY}
              />
            )}
          </div>
        )}
        {// flags?.["work-allocation"] && todo: confirm that this flag is no longer needed
        !isFetchingData && (
          <AllocationSection>
            <AllocationHeader>
              Work Allocation %
              <InfoIcon content="work-allocation" testId={testId} />
            </AllocationHeader>
            {!isEmpty(allocationsToShow) ? (
              allocationsToShow.map((epic, index) => {
                return !isNil(epic.epicId) ? (
                  <Link
                    to={getWorkDeepDivePath({
                      groupId: epic.epicId,
                      persistQuerystring: true,
                      querystringParams: {
                        [urlParamKeys.SELECTED_USER]: person.email,
                        [urlParamKeys.SPRINT_WORK_OTHER]: [],
                        [urlParamKeys.GROUP_BY]: userSettings.defaultJiraGroupBy
                      },
                      selectedSection: workDeepDiveSections.PROJECT_WORK
                    })}
                    name="allocation-epic-link"
                    key={`${epic.epicId}-${kebabCase(epic.epicName)}-${index}`}
                  >
                    <AllocationPill
                      key={epic.epicId}
                      allocationPercent={epic.allocation * 100}
                      data-testid={`allocation-pill`}
                      data-heap-redact-text="true"
                    >
                      <AllocationText>
                        <AllocationValue>
                          {(epic.allocation * 100).toFixed(0)}%
                        </AllocationValue>
                        {epic.epicName}
                      </AllocationText>
                      <Icon icon={"line-arrow-right"} />
                    </AllocationPill>
                  </Link>
                ) : null;
              })
            ) : (
              <NoAllocationMsg>
                No work allocation data available
              </NoAllocationMsg>
            )}
            {allocations.length > NUM_ALLOCATIONS_TO_SHOW && (
              <AllocationExpander
                onClick={() =>
                  setIsShowingExpandedAllocations(!isShowingExpandedAllocations)
                }
              >
                {!isShowingExpandedAllocations
                  ? `+${allocations.length - NUM_ALLOCATIONS_TO_SHOW} more`
                  : "hide"}
              </AllocationExpander>
            )}
          </AllocationSection>
        )}
      </Container>
      {!isFetchingData && (
        <ReportsCard person={{ ...person, team: getTeam(person.id) }} />
      )}
    </>
  );
};

export default PersonTile;
