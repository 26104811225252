import React, { useMemo } from "react";
import { DefaultTheme, withTheme } from "styled-components/macro";

// components
import Chart from "../Chart/Chart";
import { ErrorBoundary } from "@sentry/react";

// config
import generateConfig from "./config";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { SprintHealthDataPointInterface } from "../../interfaces/sprint-metadata";

// typescript props
type Props = {
  className?: string;
  data: Array<SprintHealthDataPointInterface>;
  factors: Array<string>;
  isFetchingData?: boolean;
  testId?: string;
  theme: DefaultTheme;
};

const ChartSprintHealthTrendAnalysis = ({
  className,
  data,
  factors,
  isFetchingData = false,
  testId = "testId",
  theme
}: Props) => {
  const thisTestId = `${testId}-chart-sprint-health-trend-analysis`;
  const { trackEvent } = useTracking();

  // TODO: update this to an event for showing tooltip when this
  // bug gets fixed:
  // https://github.com/highcharts/highcharts/issues/13868
  function onChartMouseOver(e: React.MouseEvent<HTMLElement>) {
    e.persist();
    trackEvent({
      e,
      label: "chart-sprint-health-trend-analysis",
      value: "chart moused over"
    });
  }
  function onChartMouseOut(e: React.MouseEvent<HTMLElement>) {
    e.persist();
    trackEvent({
      e,
      label: "chart-sprint-health-trend-analysis",
      value: "chart moused out"
    });
  }

  const chart = useMemo(() => {
    return {
      config: generateConfig({ data, factors, theme }),
      id: "sprint-health-trend-analysis-chart",
      isFetchingData
    };
  }, [data, isFetchingData, theme]);

  return (
    <div
      onMouseEnter={onChartMouseOver}
      onMouseLeave={onChartMouseOut}
      className={className}
      data-testid={thisTestId}
    >
      <ErrorBoundary>
        <Chart
          chart={chart}
          className={className}
          testId={`${thisTestId}-sprint-health-chart-trend-analysis`}
          theme={theme}
        />
      </ErrorBoundary>
    </div>
  );
};

export default withTheme(ChartSprintHealthTrendAnalysis);
