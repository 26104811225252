import React from "react";
import { render } from "react-dom";
import rootReducer from "./reducers/rootReducer";
import { createStore } from "redux";
import { Provider as ReduxProvider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import config from "./env-config";

// components
import { BrowserRouter as Router } from "react-router-dom";
import App from "./components/App/App";
import { QueryClient, QueryClientProvider } from "react-query";

import "./index.css";

// sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ExtraErrorData } from "@sentry/integrations";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

Sentry.init({
  dsn: process.env.NODE_ENV === "development" ? null : config.SENTRY_DSN,
  release: "client-dashboard@" + process.env.REACT_APP_VERSION,
  autoSessionTracking: true,
  environment: process.env.NODE_ENV === "production" ? "production" : "staging",
  integrations: [
    new ExtraErrorData(),
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })
  ],
  normalizeDepth: 10, // increase depth for Redux state debugging

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.75
});

const queryClient = new QueryClient();

const store = createStore(rootReducer, sentryReduxEnhancer);

render(
  <ReduxProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <App />
      </Router>
    </QueryClientProvider>
  </ReduxProvider>,

  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
