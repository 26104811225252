import React, { ReactElement } from "react";
import styled from "styled-components";
import sourceFeatureMap from "../../constants/sourceFeatureMap.json";

// components
import HtmlText from "../HtmlText/HtmlText";
import InfoBook from "../InfoBook/InfoBook";
import Switch from "../Switch/Switch";

// interfaces
import { FeatureTypes } from "../../interfaces/features";
import { TeamCustomizationSettingsInterface } from "../../interfaces/user-settings";

const Header = styled.h4`
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  margin-bottom: 2rem;
`;
const SwitchContainer = styled.li`
  & + & {
    margin-top: 1rem;
  }
`;
const Option = styled(Switch)`
  & .MuiTypography-root {
    margin-left: 1rem;
  }

  & label {
    justify-content: flex-start;
  }
`;
const HtmlDisplayName = styled(HtmlText)`
  display: inline-block;
`;
const Book = styled(InfoBook)`
  margin-left: 1rem;
`;

// typescript props
type Props = {
  className?: string;
  header?: React.ReactNode;
  onChange: (optionKey: keyof TeamCustomizationSettingsInterface) => void;
  options: Array<keyof TeamCustomizationSettingsInterface>;
  settings: Partial<TeamCustomizationSettingsInterface>;
  testId?: string;
};

const TeamCustomizationSettingsSwitches = ({
  className,
  header,
  onChange,
  options,
  settings,
  testId = "testId"
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-team-customization-settings-switches`;
  const featureConfig = sourceFeatureMap as {
    [key in FeatureTypes]: {
      displayName: string;
      switchDisplayName?: string;
      visibility: Array<string>;
      contribution: Array<string>;
    };
  };

  const getSourceContributionText = (source: FeatureTypes) => {
    const { displayName, visibility, contribution } = featureConfig[source];
    // format the array of strings to a prettified single string human readabable english, such that items are comma seperated except the last item, which gets an "and" seperation
    const arrayToPrettyString = (a: string[]) =>
      a.length === 1
        ? a[0]
        : [a.slice(0, -1).join(", "), a.slice(-1)[0]].join(
            a.length < 2 ? "" : " and "
          );
    return `Turning off <b>${displayName}</b> will remove it from <b>${arrayToPrettyString(
      visibility
    )}</b> and no longer count toward your <b>${arrayToPrettyString(
      contribution
    )}</b> score`;
  };

  return (
    <div className={className} data-testid={thisTestId}>
      {!!header ? <Header>{header}</Header> : null}
      <ul>
        {options.map(optionKey => (
          <SwitchContainer key={optionKey}>
            <Option
              isActive={!!settings[optionKey]}
              labelContent={
                <>
                  {!!featureConfig[optionKey].switchDisplayName ? (
                    <HtmlDisplayName
                      htmlText={
                        featureConfig[optionKey].switchDisplayName as string
                      }
                      testId={thisTestId}
                    />
                  ) : (
                    <b>{featureConfig[optionKey].displayName}</b>
                  )}
                  <Book
                    action="hover"
                    content={getSourceContributionText(optionKey)}
                    name={`${optionKey}-info-popup`}
                    testId={`${thisTestId}-${optionKey}`}
                  />
                </>
              }
              name={`${optionKey}-toggle`}
              className={`${optionKey}-toggle`}
              testId={`${thisTestId}-${optionKey}`}
              onChange={() => {
                onChange(optionKey);
              }}
              labelPlacement="end"
              showSwitchStatus={true}
            />
          </SwitchContainer>
        ))}
      </ul>
    </div>
  );
};

export default TeamCustomizationSettingsSwitches;
