import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { get, isEmpty, isNil, snakeCase } from "lodash";

// components
import EpicFilterDrawerAndTrigger from "../EpicFilterDrawerAndTrigger/EpicFilterDrawerAndTrigger";
import EpicsList from "../EpicsList/EpicsList";
import FilterPills from "../FilterPills/FilterPills";
import WorkItemsGroupBySelector from "../WorkItemsGroupBySelector/WorkItemsGroupBySelector";
import UiCenteringWrapper from "../UiCenteringWrapper/UiCenteringWrapper";
import WorkMetadataActivitiesKey from "../WorkMetadataActivitiesKey/WorkMetadataActivitiesKey";

// hooks
import { useSelector } from "react-redux";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import EmptyState from "../EmptyState/EmptyState";
import LoadingStates from "../LoadingStates/LoadingStates";
import { useWorkItemsData } from "../../hooks/useWorkItemsData";
import { GroupsListInterface } from "../../interfaces/work-items";
import { workMetadataActivityTypes } from "../../constants/constants";

// styled components
const Content = styled.div`
  background: ${props => props.theme.colors.all.lightJean};
  padding-top: 1.5rem;
  margin-top: 1rem;
`;
const ActivitiesKey = styled(WorkMetadataActivitiesKey)`
  font-size: ${props => props.theme.fonts.subheader.sizes.xs};
`;
const FilterWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: row wrap;
  margin: 3rem 0;

  & > div,
  > button {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const ProjectWork = ({ className, testId = "testId" }: Props): ReactElement => {
  const thisTestId = `${testId}-epics-base-template`;

  const { data, isError, isFetching, isSuccess } = useWorkItemsData();
  const workItemsData = (data as unknown) as GroupsListInterface;
  const showStoryPoints = workItemsData?.activities.some(d =>
    d.breakdownByStatus.some(t => !isNil(t.storyPoints))
  );

  return (
    <div className={className} data-testid={thisTestId}>
      <Content>
        <UiCenteringWrapper testId={thisTestId}>
          <LoadingStates
            isSpinningState={isFetching && isEmpty(workItemsData?.workMetadata)}
            isNoDataState={
              isError ||
              (!isFetching && isSuccess && isEmpty(workItemsData?.workMetadata))
            }
            noDataContent={
              <EmptyState
                body="Your team doesn't seem to have any data for this date range."
                header="Hmmm."
                testId={testId}
              />
            }
            content={
              <>
                <ActivitiesKey
                  showStoryPoints={showStoryPoints}
                  testId={thisTestId}
                  visibleActivities={[
                    workMetadataActivityTypes.ISSUE,
                    workMetadataActivityTypes.SUB_TASK,
                    workMetadataActivityTypes.BUG,
                    workMetadataActivityTypes.PR
                  ].filter((activityType: string) =>
                    workItemsData?.activities.find(
                      a =>
                        snakeCase(a.activityType).toUpperCase() === activityType
                    )
                  )}
                />
                <FilterWrapper>
                  <WorkItemsGroupBySelector testId={thisTestId} />
                  <EpicFilterDrawerAndTrigger testId={thisTestId} />
                  <FilterPills />
                </FilterWrapper>
                <EpicsList testId={thisTestId} workItemsData={workItemsData} />
              </>
            }
          />
        </UiCenteringWrapper>
      </Content>
    </div>
  );
};

export default ProjectWork;
