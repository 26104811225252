import React from "react";
import styled from "styled-components/macro";
import { currentTheme } from "../../themes/currentTheme";

// components
import Dropdown from "../Dropdown/Dropdown";
import TeamMembersAdderOption from "../TeamMembersAdderOption/TeamMembersAdderOption";

// interfaces
import {
  NewUser,
  UserReportWithMetadataInterface
} from "../../interfaces/user";
import { SelectOptionsInterface } from "../../interfaces/controls";

// utils
const createOptions = (
  teamMembers: Array<UserReportWithMetadataInterface | NewUser>
) =>
  teamMembers.map(o => ({
    label: o.email,
    value: o.email,
    selected: false
  }));

// styled components
const Container = styled.div`
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding: 1rem;
`;
const Label = styled.label`
  font-size: 1.1rem;

  &::after {
    content: ":";
  }
`;

// typescript props
type Props = {
  className?: string;
  addablePeople: Array<UserReportWithMetadataInterface | NewUser>;
  onCreateTeamMember: (value: string) => void;
  onUpdateTeamMembers: (selected: Array<SelectOptionsInterface>) => void;
  teamMembers: Array<UserReportWithMetadataInterface | NewUser>;
  testId?: string;
};

const TeamMembersAdder = ({
  addablePeople,
  className,
  onCreateTeamMember,
  onUpdateTeamMembers,
  teamMembers,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-team-members-adder`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <Label>Add Team Members</Label>
      <Dropdown
        components={{
          DropdownIndicator: () => null,
          Option: TeamMembersAdderOption
        }}
        label="Add Team Members"
        bordered={true}
        isCreatable={true}
        isMulti={true}
        isSearchable={true}
        maxMenuHeight={60}
        onChangeMulti={onUpdateTeamMembers}
        onCreateOption={onCreateTeamMember}
        options={createOptions(addablePeople)}
        placeholder="jsmith@uplevelteam.com"
        shouldShowLabel={false}
        testId={thisTestId}
        value={createOptions(teamMembers)}
        styles={{
          option: (
            styleObj: Record<string, unknown>,
            status: { isFocused?: boolean; isSelected?: boolean }
          ) => ({
            ...styleObj,
            background: status.isSelected
              ? currentTheme.colors.all.marvelMint
              : status.isFocused
              ? currentTheme.colors.dropdown.option.hover
              : "transparent",
            color: currentTheme.colors.all.wolverine,
            fontSize: "1.1rem",
            padding: "0 .5rem"
          }),
          valueContainer: (styleObj: Record<string, unknown>) => {
            return {
              ...styleObj,
              alignItems: "flex-start",
              flexFlow: "column nowrap",
              maxHeight: "5rem",
              overflowY: "scroll"
            };
          },
          multiValueContainer: (styleObj: Record<string, unknown>) => {
            return {
              ...styleObj,
              display: "block"
            };
          },
          multiValueLabel: (styleObj: Record<string, unknown>) => ({
            ...styleObj,
            fontSize: "1.1rem",
            color: currentTheme.colors.all.wolverine,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            whiteSpace: "normal"
          }),
          multiValue: (styleObj: Record<string, unknown>) => {
            return {
              ...styleObj,
              background: "none",
              padding: ".2rem 0",
              whiteSpace: "break-word"
            };
          },
          multiValueRemove: (styleObj: Record<string, unknown>) => ({
            ...styleObj,
            color: currentTheme.colors.all.wolverine,
            padding: ".1rem",
            ":hover": {
              backgroundColor: currentTheme.colors.all.cyclops,
              color: currentTheme.colors.all.white
            }
          })
        }}
      />
    </Container>
  );
};

export default TeamMembersAdder;
