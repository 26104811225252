import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { isUndefined } from "lodash";

// components
import ChartOpsMetricsSparkline from "../ChartOpsMetricsSparkline/ChartOpsMetricsSparkline";
import CycleTimeStageHeader from "../CycleTimeStageHeader/CycleTimeStageHeader";
import CycleTimeValueDisplay from "../CycleTimeValueDisplay/CycleTimeValueDisplay";
import RouteLink from "../RouteLink/RouteLink";

// constants
import { workDeepDiveSections } from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { CycleTimeStageTypes } from "../../interfaces/constants";
import {
  OpMetricData,
  OpMetricSprintRecord
} from "../../interfaces/ops-metrics";

// utils
import {
  getCycleTimeDelta,
  splitCycleTimeHours,
  getValueInHours,
  filterTruthyCycleTimeValues
} from "../../utils/cycle-time";
import {
  getMetricMetadata,
  mapStageData
} from "../../utils/operational-metrics";

// styled components
const Container = styled.li`
  box-sizing: content-box;
  display: flex;
  flex-flow: column nowrap;
  padding: 0 calc(${props => props.theme.grid.gap} / 2);
  width: ${props =>
    `calc((2 * ${props.theme.grid.columnWidth}) + ${props.theme.grid.gap})`};

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  & + & {
    border-left: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  }
`;
const StageHeader = styled(CycleTimeStageHeader)`
  order: 3;
`;
const Footer = styled.footer`
  margin-bottom: 0.8rem;
`;
const Current = styled(CycleTimeValueDisplay)`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  font-size: 3.5rem;

  & > div {
    &:nth-of-type(1) {
      flex-wrap: wrap;
      &:not(:last-of-type) {
        margin-right: 0.8rem;
      }
    }
  }
`;
const PreviousHeader = styled.header`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  &::after {
    content: ":";
    margin-right: 0.8rem;
  }
`;
const Previous = styled.div`
  align-items: baseline;
  display: flex;
`;
const PreviousValue = styled(CycleTimeValueDisplay)`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const Link = styled(RouteLink)`
  color: ${props => props.theme.colors.all.wolverine};

  &,
  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    text-decoration: none;
  }
`;
const Sparkline = styled(ChartOpsMetricsSparkline)`
  margin: 1.6rem 0 0;
`;

// typescript props
type Props = {
  all: OpMetricData;
  className?: string;
  current: OpMetricSprintRecord;
  previous?: OpMetricSprintRecord;
  previousTimerangeHeader: React.ReactNode;
  stageKey: keyof CycleTimeStageTypes;
  testId?: string;
};

const OperationalMetricsCycleTimeBreakoutStage = ({
  all,
  className,
  current,
  previous,
  previousTimerangeHeader,
  stageKey,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-operational-metrics-cycle-time-breakout-stage-${stageKey}`;
  const { getWorkDeepDivePath } = useUrlParams();
  const currentStage = getMetricMetadata(current, "cycleTime")?.[stageKey];
  const currentCycleTime = isUndefined(currentStage)
    ? currentStage
    : splitCycleTimeHours(getValueInHours("HOURS", currentStage.value));
  const currentDisplayStage = !!currentCycleTime?.length
    ? filterTruthyCycleTimeValues(currentCycleTime)
    : null;
  const previousStage = isUndefined(previous)
    ? previous
    : getMetricMetadata(previous, "cycleTime")?.[stageKey];
  const previousCycleTime = isUndefined(previousStage)
    ? previousStage
    : splitCycleTimeHours(getValueInHours("HOURS", previousStage.value));
  const previousDisplayStage = !!previousCycleTime?.length
    ? filterTruthyCycleTimeValues(previousCycleTime)
    : null;
  return (
    <Container className={className} data-testid={thisTestId}>
      <StageHeader stageKey={stageKey} testId={thisTestId} />
      <Link
        name={`operational-metrics-cycle-time-breakout-${stageKey}`}
        to={getWorkDeepDivePath({
          selectedSection: workDeepDiveSections.PR_WORKFLOW
        })}
        testId={`${thisTestId}-${stageKey}`}
      >
        <Current
          delta={getCycleTimeDelta(currentCycleTime, previousCycleTime)}
          display="rollup"
          stage={currentDisplayStage}
          stageKey={stageKey}
          testId={thisTestId}
        />
      </Link>
      <Previous>
        <PreviousHeader>{previousTimerangeHeader}</PreviousHeader>
        <PreviousValue
          display="inlineAbbreviation"
          stage={previousDisplayStage}
          testId={thisTestId}
        />
      </Previous>
      <Footer>
        <Sparkline
          data={mapStageData(all, stageKey)}
          isInverted={true}
          metric="cycleTime"
          name={stageKey}
          testId={thisTestId}
        />
      </Footer>
    </Container>
  );
};

export default OperationalMetricsCycleTimeBreakoutStage;
