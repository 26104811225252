import axios, { AxiosError, AxiosResponse } from "axios";
import config from "../env-config";
import { captureException } from "@sentry/react";
import { ZodSchema } from "zod";

export const errorHandler = (e: AxiosError): Promise<AxiosError> => {
  captureException(e);
  return Promise.reject(e);
};

export const validationHandler = <T>(
  schema: ZodSchema,
  responseToTest: AxiosResponse
): Promise<T> => {
  try {
    return Promise.resolve(schema.parse(responseToTest.data) as T);
  } catch (error) {
    captureException(error);
    if (process.env.NODE_ENV === "development") {
      console.error(error);
    }
    return Promise.reject(error);
  }
};

// blue endpoints
const api = axios.create({
  baseURL: `${config.BLUE_URL}/api`,
  headers: {
    "Content-type": "application/json"
  }
});
api.interceptors.response.use(r => r, errorHandler);

// java-layer endpoints
export const legacyApi = axios.create({
  baseURL: `/api`,
  headers: {
    "Content-type": "application/json"
  }
});
legacyApi.interceptors.response.use(r => r, errorHandler);

// fars endpoints
export const farsApi = axios.create({
  baseURL: `${config.FARS_URL}/api`,
  headers: {
    "Content-type": "application/json"
  }
});
farsApi.interceptors.response.use(r => r, errorHandler);

export default api;
