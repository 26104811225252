import React, { ReactElement, useState } from "react";
import styled from "styled-components/macro";

// components
import AllTeamsMenuPopup from "../AllTeamsMenuPopup/AllTeamsMenuPopup";
import InfoPopupContent from "../InfoPopupContent/InfoPopupContent";
import TriggeredOverlay from "../TriggeredOverlay/TriggeredOverlay";

// interfaces
import { TeamInterface } from "../../interfaces/team";

// styled components
const PopupContent = styled(InfoPopupContent)`
  margin-top: 0.8rem;
  margin-left: 3rem;
`;

const Trigger = styled.span`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

// typescript props
type Props = {
  className?: string;
  onClickTeam: ({
    e,
    team
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    team: TeamInterface;
  }) => void;
  teams: Array<TeamInterface>;
  testId?: string;
};

const AllTeamsMenu = ({
  className,
  onClickTeam,
  teams,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-all-teams-menu`;
  const [showPopup, setShowPopup] = useState(false);
  function onTogglePopup(nextShow: boolean) {
    setShowPopup(nextShow);
  }
  function onClick({
    e,
    team
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    team: TeamInterface;
  }) {
    onClickTeam({ e, team });
    setShowPopup(false);
  }
  return (
    <TriggeredOverlay
      className={className}
      onToggleShow={onTogglePopup}
      overlayContent={
        <PopupContent
          content={
            <AllTeamsMenuPopup
              onClickTeam={onClick}
              teams={teams}
              testId={thisTestId}
            />
          }
          testId={thisTestId}
        />
      }
      overlayPlacement="bottom"
      show={showPopup}
      testId={thisTestId}
      triggerAction="click"
      triggerContent={
        <Trigger data-testid={`${thisTestId}-trigger`}>view all teams</Trigger>
      }
    />
  );
};

export default AllTeamsMenu;
