import React from "react";
import styled from "styled-components/macro";

// components
import Icon from "../Icon/Icon";

// styled components
const Container = styled.div`
  align-items: center;
  display: flex;
  justify-items: flex-start;
`;
const IconWrapper = styled.div`
  padding-right: 0.5rem;
`;
const SortIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
`;
const ChildWrapper = styled.div`
  text-align: left;
`;

// typescript props
type Props = {
  children: React.ReactNode;
  className?: string;
  state: "sorted-asc" | "sorted-desc" | "unsorted";
  testId?: string;
};

const SortableColumnHeader = ({
  children,
  className,
  state = "unsorted",
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-sortable-column-header`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <IconWrapper>
        <SortIcon icon={state} testId={thisTestId} />
      </IconWrapper>
      <ChildWrapper>{children}</ChildWrapper>
    </Container>
  );
};

export default SortableColumnHeader;
