import { get, isEmpty, isNumber, mapValues, sum } from "lodash";
import meetingClassifications from "../../constants/meetingClassifications.json";

const mapUserValues = ({ current, previous, allMeetings = [] }) => {
  const currentTotalHours = sum(Object.values(current));
  const previousTotalHours = sum(Object.values(previous));

  return mapValues(current, (value, key) => {
    let valuePercent;
    if (isNumber(currentTotalHours) && isNumber(value)) {
      valuePercent = !!currentTotalHours
        ? (value / currentTotalHours) * 100
        : 0;
    }

    let deltaPercent;
    if (
      isNumber(previousTotalHours) &&
      isNumber(valuePercent) &&
      isNumber(previous[key])
    ) {
      deltaPercent = valuePercent - (previous[key] / previousTotalHours) * 100;
    }

    const classificationName = get(
      meetingClassifications.find(c => c.value === key),
      "label",
      "Unknown classification"
    );
    return {
      key,
      classificationName,
      value: isNumber(valuePercent) ? valuePercent : null,
      totalHours: currentTotalHours,
      valueHours: value,
      deltaPercent,
      meetings: !isEmpty(allMeetings)
        ? allMeetings
            .filter(m => m.measure === key)
            .map(m => {
              return {
                ...m,
                classificationName,
                timerange: {
                  end: m.endTime,
                  start: m.startTime
                }
              };
            })
        : []
    };
  });
};

export default (current, previous) => {
  return {
    ...current,
    overall: {
      ...current.overall,
      mappedValues: mapUserValues({
        current: current.overall.values,
        previous: previous.overall.values
      })
    },
    resultsByUser: current.resultsByUser.map((r, index) => {
      return {
        ...r,
        mappedValues: mapUserValues({
          current: r.values,
          previous:
            previous.resultsByUser[index] !== undefined
              ? previous.resultsByUser[index].values
              : {},
          allMeetings: r.metaData
        })
      };
    })
  };
};
