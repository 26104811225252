import { useMemo } from "react";
import { useSelector } from "react-redux";

// constants
import { timerangeIds } from "../constants/constants";

// hooks
import { useQuery } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { requestSprintMetadata } from "../utils/sprints";
import { TeamInterface } from "../interfaces/team";

type KeyProps = {
  type: string;
  team: TeamInterface | null;
};
export const useSprintMetadata = (options: KeyProps, canRequest = true) => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const session = useSelector((state: AppStateInterface) => state.session);
  const previous14DaysMetadata =
    session.namedTimeranges[timerangeIds.PREVIOUS_14_DAYS];
  const team = options.team;

  const teamMemberEmails = useMemo(
    () =>
      team?.useSprints && !!team?.teamMembers.length
        ? team.teamMembers.map(t => t.email)
        : [],
    [team]
  );

  const params = useMemo(
    () => ({
      accessToken,
      tenantId,
      params: {
        endDate: previous14DaysMetadata.end,
        projectIds: [],
        startDate: previous14DaysMetadata.start,
        tenantId,
        users: teamMemberEmails
      }
    }),
    [accessToken, previous14DaysMetadata, teamMemberEmails, tenantId]
  );

  return useQuery(
    ["sprint-metadata", { ...params, type: options.type }],
    () => requestSprintMetadata(params),
    {
      enabled:
        !!accessToken &&
        !!tenantId &&
        !!previous14DaysMetadata &&
        !!teamMemberEmails.length &&
        canRequest
    }
  );
};
