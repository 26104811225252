import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import noChartImage from "../ChartImage/no-chart.png";

// components
import ChartPrWorkflow from "../ChartPrWorkflow/ChartPrWorkflow";
import Image from "../Image/Image";
import LoadingStates from "../LoadingStates/LoadingStates";
import PrWorkflowChartKey from "../PrWorkflowChartKey/PrWorkflowChartKey";

// hooks
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

// utils
import { requestCharts } from "../../utils/file";
import { EpicDetailsInterface } from "../../interfaces/work-items";

// styled components
const Container = styled.div`
  background: ${props => props.theme.colors.all.white};
  padding: 2rem;
`;
const Caption = styled.figcaption`
  display: none;
`;
const Disclaimer = styled.small`
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
`;
const InteractiveChartWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
const ChartKey = styled(PrWorkflowChartKey)`
  align-self: flex-end;
`;

// typescript props
type Props = {
  className?: string;
  data?: EpicDetailsInterface;
  testId?: string;
};

const PrWorkflowChartWrapper = ({
  className,
  data,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-pr-workflow-chart-wrapper`;
  const chartKey = "PullRequestActivity";
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const { urlParams } = useUrlParams();
  const activeTeam = urlParams.team;
  const teamMembers = activeTeam?.teamMembers;
  const { data: legacyData, isError, isLoading } = useQuery(
    "pr-workflow-chart",
    () =>
      requestCharts({
        accessToken,
        tenantId,
        chartNames: [chartKey]
      }),
    {
      enabled: !!accessToken && !!tenantId && !flags?.["pr-workflow"]
    }
  );

  return (
    <Container className={className} data-testid={thisTestId}>
      {flags?.["pr-workflow"] ? (
        <LoadingStates
          isNoDataState={!data}
          content={
            <InteractiveChartWrapper>
              <ChartPrWorkflow
                data={data as EpicDetailsInterface}
                timerange={urlParams.timerange}
                testId={thisTestId}
                team={teamMembers}
              />
              <ChartKey testId={thisTestId} />
            </InteractiveChartWrapper>
          }
          testId={thisTestId}
        />
      ) : (
        <>
          <Disclaimer>
            This chart is available for your organization team only.
          </Disclaimer>
          <figure>
            <LoadingStates
              isSpinningState={isLoading}
              isNoDataState={isError || !legacyData?.[chartKey]}
              noDataContent={
                <Image
                  altText="Chart not available"
                  src={noChartImage}
                  testId={testId}
                />
              }
              content={
                <Image
                  altText="PR Workflow chart"
                  src={legacyData?.[chartKey]}
                  testId={testId}
                />
              }
              testId={thisTestId}
            />
            <Caption>Pr Workflow chart</Caption>
          </figure>
        </>
      )}
    </Container>
  );
};

export default PrWorkflowChartWrapper;
