import React from "react";
import styled from "styled-components/macro";
import { findIndex, isEmpty, orderBy } from "lodash";

// components
import Icon from "../Icon/Icon";
import Stepper from "../Stepper/Stepper";

// constants
import { directionTypes, sprintStates } from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { TimerangeMetadataInterface } from "../../interfaces/constants";

// utils
import { formatTimestamp } from "../../utils/date";

// styled components
const StepperContent = styled.div`
  border-left: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  border-right: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  font-family: ${props => props.theme.fonts.primary.name}, sans-serif;
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  padding: 0.5rem 2rem;
  margin: 0;
`;
const StepperIconWrapper = styled.div<{ disabled: boolean }>`
  align-items: center;
  color: ${props =>
    props.disabled
      ? props.theme.colors.all.storm
      : props.theme.colors.all.wolverine};
  display: flex;
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  padding: 0 1rem;
  text-transform: uppercase;
`;
const StepperIcon = styled(Icon)`
  color: currentColor;
`;
const StepperText = styled.span`
  margin: 0rem 1rem;
`;

// typescript props
type Props = {
  className?: string;
  isDisabled: boolean;
  onChange: (timerange: TimerangeMetadataInterface) => void;
  steppableSprints: Array<TimerangeMetadataInterface>;
  testId?: string;
  timerange: TimerangeMetadataInterface;
};

const SprintStepper = ({
  className,
  isDisabled = false,
  onChange,
  steppableSprints,
  testId = "testId",
  timerange
}: Props) => {
  const thisTestId = `${testId}-sprint-stepper`;
  const { state } = timerange;

  const { trackEvent } = useTracking();

  if (isEmpty(steppableSprints)) {
    return null;
  }

  const orderedSprints = orderBy(steppableSprints, ["start"], ["asc"]);
  const currentSprintIndex = findIndex(
    orderedSprints,
    (t: TimerangeMetadataInterface) => t.id === timerange.id
  );

  function onChangeSprint({
    e,
    index,
    direction,
    timeranges
  }: {
    e: React.MouseEvent;
    index: number;
    direction: string;
    timeranges: Array<TimerangeMetadataInterface>;
  }) {
    const updatedTimerange =
      direction === directionTypes.PREV
        ? timeranges[index - 1]
        : timeranges[index + 1];

    onChange(updatedTimerange);
    trackEvent({
      e,
      label: "ui-filter-time-range-change",
      value: `stepped timerange ${direction} to sprint ${
        updatedTimerange.displayName
      } (${formatTimestamp({
        format: "MM/DD/YYYY",
        timestamp: updatedTimerange.start
      })}-${formatTimestamp({
        format: "MM/DD/YYYY",
        timestamp: updatedTimerange.end
      })})`
    });
  }

  const isNextDisabled =
    isDisabled || currentSprintIndex === orderedSprints.length - 1;
  const isPrevDisabled = isDisabled || currentSprintIndex === 0;

  const stepperContent = (
    <StepperContent>
      {state === sprintStates.ACTIVE
        ? "This Sprint"
        : state === sprintStates.FUTURE
        ? "Future Sprint"
        : "Previous Sprint"}
    </StepperContent>
  );
  return (
    <div className={className} data-testid={thisTestId}>
      <Stepper
        content={stepperContent}
        isNextDisabled={isNextDisabled}
        isPrevDisabled={isPrevDisabled}
        nextButton={
          <StepperIconWrapper disabled={isNextDisabled}>
            <StepperText className="visuallyHidden">Next</StepperText>
            <StepperIcon icon="triangle-right" testId={thisTestId} />
          </StepperIconWrapper>
        }
        onChange={(e: React.MouseEvent, direction: string) =>
          onChangeSprint({
            e,
            index: currentSprintIndex,
            direction,
            timeranges: orderedSprints
          })
        }
        prevButton={
          <StepperIconWrapper disabled={isPrevDisabled}>
            <StepperIcon icon="triangle-left" testId={thisTestId} />
            <StepperText className="visuallyHidden">Back</StepperText>
          </StepperIconWrapper>
        }
        testId={thisTestId}
      />
    </div>
  );
};

export default SprintStepper;
