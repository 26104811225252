import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// components
import InfoIcon from "../InfoIcon/InfoIcon";
import WorkProgress from "../WorkProgress/WorkProgress";

// hooks
import { SprintProgressActivityInterface } from "../../interfaces/sprint-metadata";

// styled components
const Container = styled.div`
  width: 100%;
`;
const Header = styled.h3`
  align-items: center;
  display: flex;
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  margin-bottom: 1.3rem;
`;

// typescript props
type Props = {
  className?: string;
  data: Array<SprintProgressActivityInterface>;
  isActiveSprint?: boolean;
  testId?: string;
};

const RetrosSprintCompletion = ({
  className,
  data,
  isActiveSprint,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-sprint-progress-chart-wrapper`;
  const tooltipContent = `retros-sprint-completion${
    !isActiveSprint ? "-final" : ""
  }`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <Header>
        Sprint Completion{" "}
        <InfoIcon
          content={
            tooltipContent as
              | "retros-sprint-completion"
              | "retros-sprint-completion-final"
          }
          testId={thisTestId}
        />
      </Header>
      <WorkProgress data={data} isSprintMode={true} testId={thisTestId} />
    </Container>
  );
};

export default RetrosSprintCompletion;
