import React, { useMemo } from "react";
import { DefaultTheme, withTheme } from "styled-components/macro";
import {
  workDeepDiveSections,
  urlParamKeys,
  urlGlobalParams
} from "../../constants/constants";

// components
import Chart from "../Chart/Chart";

// config
import generateConfig from "./config";

// hooks
import { useTracking } from "../../hooks/useTracking";
import { useHistory } from "react-router-dom";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { SprintProgressActivityInterface } from "../../interfaces/sprint-metadata";

// typescript props
type Props = {
  className?: string;
  data: Array<SprintProgressActivityInterface>;
  isFetchingData?: boolean;
  testId?: string;
  theme: DefaultTheme;
};

const ChartSprintProgress = ({
  className,
  data,
  isFetchingData = false,
  testId = "testId",
  theme
}: Props) => {
  const thisTestId = `${testId}-chart-sprint-progress`;
  const { trackEvent } = useTracking();
  const history = useHistory();
  const { getWorkDeepDivePath } = useUrlParams();

  function onBarMouseOver({
    e,
    pointData
  }: {
    e: React.MouseEvent<HTMLElement>;
    pointData: any;
  }) {
    trackEvent({
      e,
      label: "chart-sprint-progress",
      value: `${pointData.series.name} moused over`
    });
  }

  function onBarClick({
    e,
    pointData
  }: {
    e: React.MouseEvent<HTMLElement>;
    pointData: any;
  }) {
    trackEvent({
      e,
      label: "chart-sprint-progress",
      value: `${pointData.series.name} clicked`
    });
    history.push(
      getWorkDeepDivePath({
        selectedSection: workDeepDiveSections.PROJECT_WORK,
        persistQuerystring: urlGlobalParams,
        querystringParams: {
          [urlParamKeys.STATUS]: [pointData.series.userOptions.status],
          [urlParamKeys.SHOW_RELATED]: false
        }
      })
    );
  }

  const chart = useMemo(() => {
    return {
      config: generateConfig({
        data,
        onBarClick,
        onBarMouseOver,
        theme
      }),
      id: "sprint-progress-chart",
      isFetchingData
    };
  }, [data, isFetchingData, theme]);

  return (
    <div className={className} data-testid={thisTestId}>
      <Chart
        chart={chart}
        className={className}
        testId={`${thisTestId}-sprint-progress-chart`}
        theme={theme}
      />
    </div>
  );
};

export default withTheme(ChartSprintProgress);
