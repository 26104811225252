import { isNil, sortBy, startCase } from "lodash";
import moment from "moment";
import { DefaultTheme } from "styled-components/macro";

// constants
import {
  momentUnitTypes,
  healthFactorsMetadata,
  healthScoreRanges,
  healthScoreTypes
} from "../../constants/constants";

// interfaces
import { SprintHealthDataPointInterface } from "../../interfaces/sprint-metadata";

// utils
import {
  getAdjustedTooltipPosition,
  getSprintHealthFactorsTooltip
} from "../../utils/chart";
import { hexToRgb } from "../../utils/color";
import { createTimestamp, formatTimestamp } from "../../utils/date";

// typescript props
type Props = {
  data: Array<SprintHealthDataPointInterface>;
  factors: Array<string>;
  theme: DefaultTheme;
};

const config = ({ data, factors, theme }: Props) => {
  const series = sortBy(
    factors.map(f => {
      const color = theme.colors.chart.healthFactors[f];
      return {
        name: healthFactorsMetadata[f].title,
        color: color,
        marker: {
          fillColor: color,
          lineColor: color
        },
        data: data.map((d: SprintHealthDataPointInterface) => {
          const value = { ...d.projectHealthFactors, ...d.peopleHealthFactors }[
            f
          ];
          return isNil(value) ? null : value;
        })
      };
    }),
    "name"
  );

  return {
    chart: {
      height: 200,
      marginLeft: 0,
      style: {
        fontFamily: theme.fonts.primary.name,
        fontSize: 11,
        fontWeight: theme.fonts.primary.weights.regular
      }
    },
    xAxis: {
      categories: data.map(d => {
        const isSameMonth = moment(d.startAt).isSame(
          d.completeAt,
          momentUnitTypes.MONTHS
        );
        const start = formatTimestamp({
          format: "M/DD",
          timestamp: createTimestamp(d.startAt)
        });
        const end = formatTimestamp({
          format: isSameMonth ? "DD" : "M/DD",
          timestamp: createTimestamp(d.completeAt || new Date())
        });
        return `${start}-${end}`;
      }),
      lineColor: theme.colors.all.wolverine,
      labels: {
        enabled: true,
        style: {
          color: theme.colors.all.wolverine,
          fontFamily: theme.fonts.subheader.name,
          fontWeight: theme.fonts.subheader.weights.bold
        },
        y: 20
      }
    },
    yAxis: {
      labels: {
        enabled: false
      },
      max: 105,
      min: 0,
      endOnTick: false,
      plotBands: Object.entries(healthScoreRanges).map(([key, range]) => {
        return {
          color: hexToRgb({
            hex: theme.colors.signalHealth[key],
            opacity: 0.2
          }),
          label: {
            text: startCase(key.toLowerCase()),
            verticalAlign: "bottom",
            y: -5
          },
          from: range[0],
          to:
            // to adjust for the ranges in constants being exclusive, restrict the top band to 100
            key === healthScoreTypes.EXCELLENT ? range[1] - 1 : range[1]
        };
      }),
      tickPositions: [
        healthScoreRanges.CONCERNING[0],
        healthScoreRanges.OK[0],
        healthScoreRanges.GOOD[0],
        healthScoreRanges.EXCELLENT[0],
        100
      ],
      title: {
        enabled: false
      }
    },
    legend: {
      align: "right",
      alignColumns: true,
      enabled: true,
      itemDistance: 30,
      itemStyle: {
        fontWeight: theme.fonts.primary.weights.regular
      },
      layout: "horizontal",
      padding: 0,
      symbolHeight: 8,
      symbolWidth: 8,
      verticalAlign: "bottom"
    },
    plotOptions: {
      line: {
        connectNulls: true,
        dataLabels: {
          enabled: false
        },
        marker: {
          symbol: "circle"
        },
        states: {
          hover: {
            opacity: 1
          }
        }
      }
    },
    title: {
      text: undefined
    },
    tooltip: {
      useHTML: true,
      enabled: true,
      backgroundColor: null,
      shadow: false,
      borderWidth: 0,
      borderRadius: 0,
      outside: true,
      positioner: function(w: number, h: number, point: any) {
        return getAdjustedTooltipPosition({
          currentPosition: (this as any).getPosition(w, h, point),
          windowScroll: window.scrollY
        });
      },
      formatter: function() {
        return getSprintHealthFactorsTooltip((this as any).point);
      }
    },
    series
  };
};
export default config;
