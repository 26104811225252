import React from "react";
import styled, { css } from "styled-components/macro";
import { compact, groupBy, isEmpty, kebabCase, uniq } from "lodash";

// constants
import {
  annotationStatusTypes,
  orderedAnnotationStatusTypes,
  pageIds,
  reservedEpics,
  reservedEpicsMetadata,
  StatusIconMap
} from "../../constants/constants";

// components
import Icon from "../Icon/Icon";
import RouteLink from "../RouteLink/RouteLink";
import Tooltip from "../Tooltip/Tooltip";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { AnnotationsInterface } from "../../interfaces/annotations";
import { GroupInterface } from "../../interfaces/work-items";
import { UserReportInterface } from "../../interfaces/user";
import { useUrlParams } from "../../hooks/useUrlParams";

// styled components
const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
const Header = styled.header`
  align-items: center;
  display: flex;
  flex: 1;
`;
const GroupId = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  margin-right: 1rem;
`;
const ReactionsList = styled.ul<{ numListItems: number }>`
  columns: ${props =>
    props.numListItems > 3
      ? Math.ceil(props.numListItems / 2)
      : props.numListItems};
  gap: ${props => props.theme.grid.gap};
`;
const ReactionsListItem = styled.li`
  align-items: flex-end;
  display: flex;
  margin-bottom: 0.5rem;
`;
const ReactionWrapper = styled.span`
  align-items: baseline;
  display: flex;
`;
const ReactionIcon = styled(Icon)<{ statusType: string }>`
  color: ${props => props.theme.colors.all.wolverine};
  display: inline-block;
  height: 1em;
  margin-right: 0.5rem;
  width: 1em;

  // TODO: remove this adjustment when we get FA6 disappointed face in
  ${props => {
    if (props.statusType === annotationStatusTypes.DISAPPOINTED) {
      return css`
        position: relative;
        top: 0.2rem;
      `;
    }
  }}
`;
const NumReactions = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
`;
const TeamList = styled.ul`
  && {
    padding-left: 0;
  }
`;
const TeamMember = styled.li`
  list-style-type: none;
`;
const Arrow = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  line-height: 1;
  margin-left: 1rem;
`;
const EpicLink = styled(RouteLink)`
  align-items: center;
  display: flex;

  && {
    color: inherit;

    &:hover {
      color: ${props => props.theme.colors.all.auroraTeal};
      text-decoration: none;

      & ${Arrow} {
        color: ${props => props.theme.colors.all.auroraTeal};
      }
    }
  }
`;

// typescript props
type Props = {
  annotations?: Array<AnnotationsInterface>;
  className?: string;
  data: GroupInterface;
  teamMembers: Array<UserReportInterface>;
  testId?: string;
};

const WorkMetadataReactionsRollupRow = ({
  annotations,
  className,
  data,
  teamMembers,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-work-metadata-reactions-rollup-row`;
  const { trackEvent } = useTracking();
  const { getWorkDeepDivePath } = useUrlParams();
  const groupedAnnotations = groupBy(
    annotations,
    "userAnnotationCheckinStatus"
  );
  const numReactionIcons = Object.values(groupedAnnotations).filter(
    (annotations: Array<AnnotationsInterface> | undefined) =>
      !isEmpty(annotations)
  ).length;
  const linkPath = getWorkDeepDivePath({
    groupId:
      data.magicRow ===
      reservedEpicsMetadata[reservedEpics.UNLINKED_PRS].magicRowId
        ? reservedEpics.UNLINKED_PRS
        : data.magicRow ===
          reservedEpicsMetadata[reservedEpics.NO_EPIC].magicRowId
        ? reservedEpics.NO_EPIC
        : data.groupId
  });
  const groupName =
    Object.values(reservedEpicsMetadata).find(
      m => m.magicRowId === data.magicRow
    )?.title || data.groupName;

  function onShowTooltip(e: React.MouseEvent<HTMLElement>, statusType: string) {
    trackEvent({
      e,
      label: `${thisTestId}-${kebabCase(statusType)}-tooltip`,
      value: `list of annotators shown for ${data.groupId}`
    });
  }

  return (
    <Container className={className} data-testid={thisTestId}>
      <Header>
        <EpicLink name="retros-project-reactions-link" to={linkPath}>
          {!!data.groupDisplayId ? (
            <GroupId>{data.groupDisplayId}</GroupId>
          ) : null}
          <span data-heap-redact-text="true">{groupName}</span>
          <Arrow
            icon="line-arrow-right"
            testId={`${thisTestId}-${data.groupId}`}
          />
        </EpicLink>
      </Header>
      <ReactionsList numListItems={numReactionIcons}>
        {compact(
          [...orderedAnnotationStatusTypes, annotationStatusTypes.COMMENT].map(
            (statusType: string) => {
              const statusAnnotations =
                groupedAnnotations[
                  statusType === annotationStatusTypes.COMMENT ? "" : statusType
                ];
              return !!statusAnnotations?.length ? (
                <ReactionsListItem key={statusType}>
                  <Tooltip
                    trigger={
                      <ReactionWrapper>
                        <ReactionIcon
                          icon={StatusIconMap[statusType]}
                          statusType={statusType}
                          testId={`${thisTestId}-${statusType}`}
                        />
                        <NumReactions>{statusAnnotations.length}</NumReactions>
                      </ReactionWrapper>
                    }
                    popupContent={
                      <TeamList>
                        {compact(
                          uniq(
                            statusAnnotations.map((a: AnnotationsInterface) => {
                              const teamMember = teamMembers.find(
                                t => t.id === a.userId
                              );
                              return teamMember?.name || a.userEmail;
                            })
                          )
                        )
                          .sort()
                          .map((displayName: string) => (
                            <TeamMember
                              key={kebabCase(displayName)}
                              data-heap-redact-text="true"
                            >
                              {displayName}
                            </TeamMember>
                          ))}
                      </TeamList>
                    }
                    placement="bottom"
                    onShowTooltip={(e: React.MouseEvent<HTMLElement>) =>
                      onShowTooltip(e, statusType)
                    }
                    testId={`${thisTestId}-${statusType}`}
                  />
                </ReactionsListItem>
              ) : null;
            }
          )
        )}
      </ReactionsList>
    </Container>
  );
};

export default WorkMetadataReactionsRollupRow;
