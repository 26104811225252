import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { isEmpty } from "lodash";

// components
import DataDisclaimer from "../DataDisclaimer/DataDisclaimer";
import LoadingStates from "../LoadingStates/LoadingStates";
import SprintHealth from "../SprintHealth/SprintHealth";
import StartWorkProgress from "../StartWorkProgress/StartWorkProgress";
import TeamRoster from "../TeamRoster/TeamRoster";
import TimeRange from "../TimeRange/TimeRange";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";

// hooks
import { useWorkItemsData } from "../../hooks/useWorkItemsData";
import { useSprintHealthData } from "../../hooks/useSprintHealthData";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { GroupsListInterface } from "../../interfaces/work-items";
import { SprintHealthInterface } from "../../interfaces/sprint-metadata";
import { TeamInterface } from "../../interfaces/team";
import { TimerangeMetadataInterface } from "../../interfaces/constants";

// utils
import { getTeamRosterFromGroupsList } from "../../utils/work-items";
import { getFilteredSprintHealthData } from "../../utils/health-metrics";
import { getTeamNameFromTeam } from "../../utils/teams";
import { timerangeTypes } from "../../constants/constants";
import { getIsSprintMode } from "../../utils/sprints";

// styled components
const HeaderWrapper = styled.div`
  align-items: flex-end;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;
const Greeting = styled.h4`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;
const Title = styled.h3`
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const SprintMetadata = styled.h4`
  align-items: center;
  display: flex;
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin-bottom: 0;
`;
const Roster = styled(TeamRoster)`
  border-left: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  margin-left: 1rem;
  padding-left: 1rem;
`;
const Charts = styled.div`
  background-color: ${props => props.theme.colors.all.lightJean};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.1rem;
  margin-top: 2rem;
`;
const WorkProgress = styled(StartWorkProgress)`
  background-color: ${props => props.theme.colors.all.white};
  padding-left: 1.5rem;
  max-width: ${props =>
    `calc(6*${props.theme.grid.columnWidth} + 5*${props.theme.grid.columnWidth})`};
`;
const StyledSprintHealth = styled(SprintHealth)`
  background-color: ${props => props.theme.colors.all.white};
  padding-right: 1.5rem;
  max-width: ${props =>
    `calc(6*${props.theme.grid.columnWidth} + 5*${props.theme.grid.columnWidth})`};
`;
const ChartWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
`;
const Footer = styled.footer`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  color: ${props => props.theme.colors.all.storm};
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

// typescript props
type Props = {
  className?: string;
  activeTeam: TeamInterface;
  testId?: string;
  timerange: TimerangeMetadataInterface;
};

const StartPageSummary = ({
  activeTeam,
  className,
  testId = "testId",
  timerange
}: Props): JSX.Element | null => {
  const thisTestId = `${testId}-start-page-summary`;
  const user = useSelector((state: AppStateInterface) => state.user);
  const isSprintMode = activeTeam.useSprints
    ? getIsSprintMode(timerange)
    : false;

  const {
    data: sprintHealthData,
    isError: isErrorSprintHealthData,
    isLoading: isLoadingSprintHealthData,
    isSuccess: isSuccessSprintHealthData
  } = useSprintHealthData();
  const {
    data,
    isError: isErrorWorkCompletionData,
    isLoading: isLoadingWorkCompletionData,
    isSuccess: isSuccessWorkCompletionData
  } = useWorkItemsData({ options: { type: "retros" } });
  const workCompletionData = (data as unknown) as GroupsListInterface;

  const customizedSprintHealthData = getFilteredSprintHealthData(
    sprintHealthData
  );

  return (
    <div className={className} data-testid={thisTestId}>
      <TransitionWrapper>
        <header>
          <HeaderWrapper data-heap-redact-text="true">
            <hgroup>
              <Greeting>Hi, {user.givenName}</Greeting>
              <Title data-heap-redact-text="true">
                {isSprintMode ? "Sprint" : "Workflow"} Insights:{" "}
                {isSprintMode
                  ? timerange.displayName
                  : getTeamNameFromTeam(activeTeam)}
              </Title>
            </hgroup>
          </HeaderWrapper>
          <SprintMetadata>
            <TimeRange
              config={{ format: { start: "M/DD", end: "M/DD" } }}
              testId={thisTestId}
              timerange={timerange}
            />
            <Roster
              header={isSprintMode ? "Sprint Roster" : "Team Roster"}
              names={
                isSprintMode
                  ? getTeamRosterFromGroupsList(
                      workCompletionData?.workMetadata
                    )
                  : activeTeam?.teamMembers.map(t => t.name)
              }
              testId={thisTestId}
            />
          </SprintMetadata>
        </header>
        <Charts>
          <ChartWrapper>
            <LoadingStates
              isSpinningState={isLoadingSprintHealthData}
              // FIXME: gate this chart until health is ready for non-sprint data
              isNoDataState={
                !isSprintMode ||
                isErrorSprintHealthData ||
                (isSuccessSprintHealthData &&
                  isEmpty(customizedSprintHealthData))
              }
              content={
                <StyledSprintHealth
                  data={customizedSprintHealthData as SprintHealthInterface}
                  sprint={timerange}
                  testId={thisTestId}
                />
              }
              testId={thisTestId}
            />
          </ChartWrapper>
          <ChartWrapper>
            <LoadingStates
              isSpinningState={isLoadingWorkCompletionData}
              isNoDataState={
                isErrorWorkCompletionData ||
                (isSuccessWorkCompletionData &&
                  isEmpty(workCompletionData?.workMetadata))
              }
              content={
                <WorkProgress
                  data={workCompletionData}
                  isSprintMode={isSprintMode}
                  testId={thisTestId}
                />
              }
              testId={thisTestId}
            />
          </ChartWrapper>
        </Charts>
        <Footer>
          <DataDisclaimer testId={thisTestId} />
        </Footer>
      </TransitionWrapper>
    </div>
  );
};

export default StartPageSummary;
