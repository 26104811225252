import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { isEmpty, debounce } from "lodash";

// components
import EpicFilterCheckboxList from "../EpicFilterCheckboxList/EpicFilterCheckboxList";
import InfoIcon from "../InfoIcon/InfoIcon";

import { urlParamKeys, urlParamsValuesLabels } from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";
import { sprintMetadataWorkTypes } from "../../constants/constants";
import {
  EpicDetailsInterface,
  GroupsListInterface,
  ResponseTotalInterface
} from "../../interfaces/work-items";
import {
  UrlParamsHookReturnInterface,
  UrlParamsInterface
} from "../../interfaces/url-params";

// styled components
const Option = styled.span`
  align-items: center;
  display: flex;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  typesOfWork: UrlParamsInterface["typesOfWork"];
  updateUrlParams: UrlParamsHookReturnInterface["updateUrlParams"];
  workItemsData?: EpicDetailsInterface | GroupsListInterface;
};

const TypesOfWorkFilter = ({
  className,
  testId = "testId",
  typesOfWork,
  updateUrlParams,
  workItemsData
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-types-of-work-filter`;
  const { trackEvent } = useTracking();
  const [areFiltersDisabled, setAreFiltersDisabled] = useState(false);
  const typesOfWorkTotals = workItemsData?.typesOfWorkTotals;
  const enabledTypeOfWorkIds = typesOfWorkTotals?.map(t => t.typeOfWork);

  const optionsList = ([
    {
      helpTextKey: "sprint-work",
      id: sprintMetadataWorkTypes.SPRINT_WORK
    },
    {
      helpTextKey: "prs-not-in-sprint",
      id: sprintMetadataWorkTypes.PULL_REQUESTS_NOT_IN_SPRINT
    },
    {
      helpTextKey: "removed-from-sprint",
      id: sprintMetadataWorkTypes.REMOVED_FROM_SPRINT
    },
    {
      helpTextKey: "completed-outside-sprint",
      id: sprintMetadataWorkTypes.COMPLETED_OUTSIDE_SPRINT
    },
    {
      helpTextKey: "other-activities",
      id: sprintMetadataWorkTypes.NOT_ATTACHED_TO_SPRINT
    }
  ] as Array<{
    helpTextKey:
      | "sprint-work"
      | "prs-not-in-sprint"
      | "other-activities"
      | "removed-from-sprint"
      | "completed-outside-sprint";
    id: string;
  }>)
    .filter(({ id }) => enabledTypeOfWorkIds?.includes(id))
    .map(m => {
      return {
        id: m.id,
        isDisabled: areFiltersDisabled,
        isSelected: typesOfWork.includes(m.id),
        name: (
          <Option>
            {urlParamsValuesLabels[m.id]} (
            {typesOfWorkTotals?.find(
              (t: ResponseTotalInterface) => t.typeOfWork === m.id
            )?.total || 0}
            ){" "}
            <InfoIcon
              content={m.helpTextKey}
              testId={`${thisTestId}-${m.id}`}
            />
          </Option>
        )
      };
    });

  // fixing ts error with this:
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedToggle = useMemo(
    () =>
      debounce(updatedTypes => {
        updateUrlParams({
          [urlParamKeys.SPRINT_WORK_TYPES]: updatedTypes
        });
        setAreFiltersDisabled(false);
      }, 500),
    [updateUrlParams]
  );

  function onToggleType(updated: Array<UiFilterSelectionStatusInterface>) {
    const updatedTypes = updated
      .filter((u: UiFilterSelectionStatusInterface) => u.isSelected)
      .map((s: UiFilterSelectionStatusInterface) => s.id);

    setAreFiltersDisabled(true);
    debouncedToggle(updatedTypes);
    trackEvent({
      label: "ui-filter-types-of-work-change",
      value: `changed types of work to ${(isEmpty(updatedTypes)
        ? Object.values(sprintMetadataWorkTypes)
        : updatedTypes
      ).join(",")}`
    });
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <EpicFilterCheckboxList
        header="Sprint"
        list={optionsList}
        name="types-of-work"
        onUpdateSelection={onToggleType}
        testId="types-of-work"
      />
    </div>
  );
};

export default TypesOfWorkFilter;
