import React from "react";
import styled from "styled-components/macro";
import { capitalize, startCase } from "lodash";

// components
import ContentWithIcon from "../ContentWithIcon/ContentWithIcon";
import EpicDetails from "../EpicDetails/EpicDetails";
import EpicFilterDrawerAndTrigger from "../EpicFilterDrawerAndTrigger/EpicFilterDrawerAndTrigger";
import FilterPills from "../FilterPills/FilterPills";
import RouteLink from "../RouteLink/RouteLink";
import UiCenteringWrapper from "../UiCenteringWrapper/UiCenteringWrapper";

// constants
import { groupByTypes, workDeepDiveSections } from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// styled components
const BreadcrumbLink = styled(RouteLink)`
  align-self: center;
  color: ${props => props.theme.colors.all.wolverine};

  &,
  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
  }
`;
const Breadcrumb = styled(ContentWithIcon)`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.medium};
  margin-right: 3rem;
`;
const Content = styled.div`
  margin-top: 2rem;
  background: ${props => props.theme.colors.all.lightJean};
`;
const Header = styled.header`
  align-items: flex-end;
  display: flex;
  flex-flow: row wrap;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.medium};
  margin-bottom: 1rem;

  & > div,
  & > a,
  & > button {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const EpicDetailsBaseTemplate = ({ className, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-epic-details-base-template`;
  const { getWorkDeepDivePath, urlParams } = useUrlParams();
  const groupBy = urlParams.groupBy;
  const epicsListPath = getWorkDeepDivePath({
    persistQuerystring: true,
    querystringParams: { groupDetails: null },
    selectedSection: workDeepDiveSections.PROJECT_WORK
  });

  const breadcrumbString = startCase(
    capitalize(
      `${groupBy === groupByTypes.USER ? "PEOPLE" : groupBy.concat("s")}`
    )
  );
  return (
    <div className={className} data-testid={thisTestId}>
      <Content>
        <UiCenteringWrapper>
          <Header>
            <BreadcrumbLink
              name="epic-details-breadcrumb-link"
              to={epicsListPath}
              testId="epic-details-breadcrumb-link"
            >
              <Breadcrumb
                icon="triangle-left"
                content={breadcrumbString}
                testId={thisTestId}
              />
            </BreadcrumbLink>
            <EpicFilterDrawerAndTrigger testId={thisTestId} />
            <FilterPills />
          </Header>{" "}
        </UiCenteringWrapper>
        <EpicDetails testId={thisTestId} />
      </Content>
    </div>
  );
};

export default EpicDetailsBaseTemplate;
