import React from "react";
import styled from "styled-components/macro";
import { compact, isEmpty } from "lodash";

// components
import HtmlText from "../HtmlText/HtmlText";
import RouteLink from "../RouteLink/RouteLink";
import WorkMetadataReactionsRollupRow from "../WorkMetadataReactionsRollupRow/WorkMetadataReactionsRollupRow";

// constants
import { workDeepDiveSections } from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AnnotationsInterface } from "../../interfaces/annotations";
import { GroupInterface } from "../../interfaces/work-items";

// utils
import { filterAnnotationsForGroup } from "../../utils/sprints";

// styled components
const Header = styled.header`
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  padding-bottom: 1rem;
`;
const Row = styled(WorkMetadataReactionsRollupRow)`
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding: 1rem 0;
`;
const NoReactionsHelpText = styled.section`
  font-size: ${props => props.theme.fonts.header.sizes.xs};
  font-style: italic;
  margin-top: 2rem;
`;
const NoReactionsHtmlText = styled(HtmlText)`
  display: inline;
`;
const WorkDeepDiveLink = styled(RouteLink)`
  display: inline;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  && {
    color: inherit;
  }
`;

// typescript props
type Props = {
  annotations?: Array<AnnotationsInterface>;
  className?: string;
  data: Array<GroupInterface>;
  testId?: string;
};

const WorkMetadataReactionsRollup = ({
  annotations = [],
  className,
  data,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-work-metadata-reactions-rollup`;
  const { getWorkDeepDivePath, urlParams } = useUrlParams();
  const activeTeam = urlParams.team;
  const teamMembers = activeTeam?.teamMembers || [];
  const selectedGroupBy = urlParams.groupBy;
  const workDeepDiveLinkPath = getWorkDeepDivePath({
    selectedSection: workDeepDiveSections.PROJECT_WORK
  });

  return (
    <div className={className} data-testid={thisTestId}>
      <Header>Retro Reactions</Header>
      {data.length && !isEmpty(annotations) ? (
        compact(
          data.map((d: GroupInterface) => {
            const annotationsForGroup = filterAnnotationsForGroup({
              annotations,
              group: d,
              groupBy: selectedGroupBy
            });

            return isEmpty(annotationsForGroup) ? null : (
              <Row
                annotations={annotationsForGroup}
                data={d}
                key={d.groupId}
                teamMembers={teamMembers}
                testId={thisTestId}
              />
            );
          })
        )
      ) : (
        <NoReactionsHelpText>
          <NoReactionsHtmlText
            htmlText={
              "Likes, Questions, and Comments can be added to Retro Insights in the"
            }
            testId={thisTestId}
          />{" "}
          <WorkDeepDiveLink
            name="retros-reactions-rollup-no-reactions-cta"
            to={workDeepDiveLinkPath}
          >
            Project Work page
          </WorkDeepDiveLink>
          <NoReactionsHtmlText
            htmlText={". Click the reaction icons on Epic and Project pages."}
            testId={thisTestId}
          />
        </NoReactionsHelpText>
      )}
    </div>
  );
};

export default WorkMetadataReactionsRollup;
