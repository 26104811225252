import { useEffect, useState } from "react";

// hooks
import { useSelector } from "react-redux";
import { useTeamsData } from "./useTeamsData";
import { useOrganizationData } from "./useOrganizationData";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";
import { TeamInterface } from "../interfaces/team";

// utils
import { getFallbackOrgChartTeamFromTeams } from "../utils/teams";

export const useOwnFallbackOrgChartTeam = () => {
  const [fallbackOrgChartTeam, setFallbackOrgChartTeam] = useState<
    TeamInterface
  >();

  // user
  const user = useSelector((state: AppStateInterface) => state.user);
  const userId = user?.id;

  // own teams
  const { data: ownOrgChartRootTeam } = useOrganizationData();
  const { data: ownTeams } = useTeamsData(
    {
      type: "own-teams",
      teamMembersToRequest: ownOrgChartRootTeam?.teamMembers || [user]
    },
    // wait to fetch teams until we have dependencies lined up
    !!userId
  );

  useEffect(() => {
    if (!!ownTeams?.length) {
      const team =
        ownOrgChartRootTeam ||
        getFallbackOrgChartTeamFromTeams(ownTeams, userId);
      setFallbackOrgChartTeam(team);
    }
  }, [ownTeams, userId]);

  return fallbackOrgChartTeam;
};
