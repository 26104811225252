import React from "react";
import styled, { css, DefaultTheme, withTheme } from "styled-components/macro";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

// components
import DropdownIndicator from "./DropdownIndicator";
import { SelectOptionsInterface } from "../../interfaces/controls";
import { isNull } from "lodash";

//  styled components
const StyledDropdown = styled.div<{ shouldShowLabel?: boolean }>`
  ${props => {
    if (props.shouldShowLabel) {
      return css`
        align-items: baseline;
        display: flex;
      `;
    }
  }}
`;
const StyledLabel = styled.label`
  font-family: ${props => props.theme.fonts.header.name}, sans-serif;
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
`;
const SelectWrapper = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
`;

// typescript props
type Props = {
  bordered?: boolean;
  className?: string;
  components?: any;
  defaultValue?: SelectOptionsInterface;
  formatOptionLabel?: (data: any) => void;
  isClearable?: boolean;
  isCreatable?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  label: string;
  maxMenuHeight?: number;
  menuPlacement?: "auto" | "bottom" | "top";
  menuPosition?: "absolute" | "fixed";
  onChange?: (selected: SelectOptionsInterface) => void;
  onChangeMulti?: (selected: Array<SelectOptionsInterface>) => void;
  onCreateOption?: (value: string) => void;
  options: Array<SelectOptionsInterface>;
  placeholder?: string;
  shouldShowLabel?: boolean;
  styles?: any;
  testId?: string;
  theme: DefaultTheme;
  value?: SelectOptionsInterface | Array<SelectOptionsInterface> | null;
};

const Dropdown = ({
  bordered = false,
  className,
  components = { DropdownIndicator },
  defaultValue,
  formatOptionLabel = data => data.label,
  isClearable = false,
  isCreatable = false,
  isMulti = false,
  isSearchable = true,
  label,
  maxMenuHeight = 300,
  menuPlacement = "bottom",
  menuPosition = "absolute",
  onChange = (selected: SelectOptionsInterface) => null,
  onChangeMulti = (selected: Array<SelectOptionsInterface>) => null,
  onCreateOption,
  options,
  placeholder,
  shouldShowLabel = false,
  testId = "testId",
  theme,
  value,
  styles = {}
}: Props) => {
  const thisTestId = `${testId}-dropdown`;
  const SelectComponent = isCreatable ? CreatableSelect : Select;

  const defaultStyle = {
    control: (styleObj: Record<string, unknown>) => ({
      ...styleObj,
      border: bordered
        ? `${theme.borders.widths.sm} solid ${theme.colors.all.jean}`
        : 0,
      borderRadius: 0,
      boxShadow: "none",
      padding: "0"
    }),
    indicatorSeparator: () => ({}),
    menu: (styleObj: Record<string, unknown>) => ({
      ...styleObj,
      boxShadow: 0,
      border: `${theme.borders.widths.sm} solid ${theme.colors.all.jean}`,
      borderRadius: 0,
      padding: 0
    }),
    option: (
      styleObj: Record<string, unknown>,
      status: { isFocused?: boolean; isSelected?: boolean }
    ) => ({
      ...styleObj,
      background: status.isSelected
        ? theme.colors.all.marvelMint
        : status.isFocused
        ? theme.colors.dropdown.option.hover
        : "transparent",
      color: theme.colors.all.wolverine
    }),
    singleValue: (styleObj: Record<string, unknown>) => ({
      ...styleObj,
      color: theme.colors.dropdown.text
    }),
    valueContainer: (styleObj: Record<string, unknown>) => {
      return {
        ...styleObj,
        alignItems: "flex-start"
      };
    }
  };
  const overrideStyles = { ...defaultStyle, ...styles };

  return (
    <StyledDropdown
      shouldShowLabel={shouldShowLabel}
      className={className}
      data-testid={thisTestId}
    >
      <StyledLabel
        className={shouldShowLabel ? "" : "visuallyHidden"}
        htmlFor={thisTestId}
      >
        {label}
      </StyledLabel>
      <SelectWrapper>
        <SelectComponent
          blurInputOnSelect={true}
          components={components}
          defaultValue={defaultValue}
          formatOptionLabel={formatOptionLabel}
          formatCreateLabel={(value: string) => `Add ${value}`}
          id={thisTestId}
          isClearable={isClearable}
          isMulti={isMulti}
          isSearchable={isSearchable}
          maxMenuHeight={maxMenuHeight}
          menuPlacement={menuPlacement}
          menuPosition={menuPosition}
          name={thisTestId}
          onChange={(
            selected: SelectOptionsInterface | Array<SelectOptionsInterface>
          ) =>
            isMulti
              ? onChangeMulti(
                  isNull(selected)
                    ? []
                    : (selected as Array<SelectOptionsInterface>)
                )
              : onChange(selected as SelectOptionsInterface)
          }
          onCreateOption={onCreateOption}
          options={options}
          placeholder={placeholder}
          styles={overrideStyles}
          value={value}
        />
      </SelectWrapper>
    </StyledDropdown>
  );
};

export default withTheme(Dropdown);
