import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

// components
import Container from "react-bootstrap/Container";

// styled components
const StyledGridContainer = styled(Container)`
  padding: 0;
`;

const GridContainer = ({ children, fluid = true, ...otherProps }) => {
  if (!children) {
    return null;
  }

  return (
    <StyledGridContainer fluid={fluid} {...otherProps}>
      {children}
    </StyledGridContainer>
  );
};

GridContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
  fluid: PropTypes.bool
};

export default GridContainer;
