import React from "react";
import styled, { css } from "styled-components/macro";
import CloseButton from "../CloseButton/CloseButton";
import PopupContent from "../PopupContent/PopupContent";

// styled components
const Container = styled(PopupContent)`
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  overflow-wrap: anywhere;
  word-wrap: anywhere;
`;
const HeaderWrapper = styled.div<{ hasHeaderContent: boolean }>`
  align-items: center;
  display: flex;
  padding: 0.8rem 1.5rem;

  ${props => {
    if (props.hasHeaderContent) {
      return css`
        border-bottom: ${props =>
          `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
        justify-content: space-between;
      `;
    } else {
      return css`
        justify-content: flex-end;
      `;
    }
  }};
`;
const ContentWrapper = styled.div`
  padding: 0.8rem 1.5rem;
`;
const Close = styled(CloseButton)`
  font-size: inherit;
  margin-left: 1em;
`;

// typescript props
type Props = {
  className?: string;
  content: React.ReactNode;
  context?: "click" | "hover";
  header?: React.ReactNode;
  onCancelPopup?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  testId?: string;
};

const InfoPopupContent = ({
  className,
  content,
  context = "hover",
  header,
  onCancelPopup = () => null,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-info-popup-content`;
  const hasHeaderContent = !!header;
  const hasCloseButton = context === "click" && !!onCancelPopup;
  const headerContent =
    hasHeaderContent || hasCloseButton ? (
      <HeaderWrapper hasHeaderContent={hasHeaderContent}>
        {header}
        {hasCloseButton ? (
          <Close onClick={onCancelPopup} testId={thisTestId} />
        ) : null}
      </HeaderWrapper>
    ) : null;

  return (
    <Container
      className={className}
      content={<ContentWrapper>{content}</ContentWrapper>}
      header={headerContent}
      testId={thisTestId}
    />
  );
};

export default InfoPopupContent;
