import { combineReducers } from "redux";

import auth from "./authReducer";
import flags from "./flagsReducer";
import session from "./sessionReducer";
import user from "./userReducer";

export default combineReducers({
  auth,
  flags,
  session,
  user
});
