import React, { useMemo, useState } from "react";
import { isEmpty, debounce } from "lodash";

// components
import EpicFilterCheckboxList from "../EpicFilterCheckboxList/EpicFilterCheckboxList";
import InfoIcon from "../InfoIcon/InfoIcon";

// constants
import {
  reservedEpics,
  reservedProjects,
  urlParamKeys
} from "../../constants/constants";

// hooks
import { useParams } from "react-router-dom";
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";
import {
  EpicDetailsInterface,
  GroupsListInterface,
  ProjectInterface,
  ResponseTotalInterface
} from "../../interfaces/work-items";
import LoadingStates from "../LoadingStates/LoadingStates";
import {
  UrlParamsInterface,
  UrlParamsHookReturnInterface
} from "../../interfaces/url-params";

// typescript props
type Props = {
  className?: string;
  isFetching: boolean;
  isSuccess: boolean;
  projectIds: UrlParamsInterface["projectIds"];
  testId?: string;
  updateUrlParams: UrlParamsHookReturnInterface["updateUrlParams"];
  workItemsData?: EpicDetailsInterface | GroupsListInterface;
};

const ProjectsFilter = ({
  className,
  isFetching,
  isSuccess,
  projectIds,
  testId = "testId",
  updateUrlParams,
  workItemsData
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-projects-selector`;
  const { trackEvent } = useTracking();
  const [areFiltersDisabled, setAreFiltersDisabled] = useState(false);
  const { groupId } = useParams<{
    groupId: string;
  }>();

  const projectTotals = workItemsData?.projectTotals;
  const projects = workItemsData?.projects || [];

  const optionsList = projects.map((p: ProjectInterface) => {
    const projectKey =
      p.projectId === reservedProjects.NO_PROJECT ? "" : ` (${p.projectKey})`;
    return {
      ...p,
      id: p.projectId,
      isDisabled: areFiltersDisabled,
      isSelected: projectIds.includes(p.projectId),
      name: (
        <span data-heap-redact-text="true">
          {p.projectName}
          {projectKey} (
          {projectTotals?.find(
            (t: ResponseTotalInterface) => t.projectId === p.projectId
          )?.total || 0}
          )
        </span>
      )
    };
  });

  // fixing ts error with this:
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedToggle = useMemo(
    () =>
      debounce(updatedProjectIds => {
        updateUrlParams({
          [urlParamKeys.PROJECT_IDS]: updatedProjectIds
        });
        setAreFiltersDisabled(false);
      }, 500),
    [updateUrlParams]
  );

  function onToggleProject({
    allProjects,
    updated
  }: {
    allProjects: Array<ProjectInterface>;
    updated: Array<UiFilterSelectionStatusInterface>;
  }) {
    const updatedProjectIds = updated
      .filter((u: UiFilterSelectionStatusInterface) => u.isSelected)
      .map(p => p.id);
    setAreFiltersDisabled(true);
    debouncedToggle(updatedProjectIds);
    trackEvent({
      label: "ui-filter-projects-change",
      value: `changed projects to ${(isEmpty(updatedProjectIds)
        ? allProjects
        : allProjects.filter(p => updatedProjectIds.includes(p.projectId))
      )
        .map(p => p.projectName)
        .join(",")}`
    });
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <LoadingStates
        isSpinningState={isFetching}
        isNoDataState={
          groupId === reservedEpics.UNLINKED_PRS ||
          (isSuccess && !projects.length)
        }
        noDataContent={null}
        content={
          <EpicFilterCheckboxList
            header={
              <>
                Jira Projects{" "}
                <InfoIcon content="jira-projects" testId={thisTestId} />
              </>
            }
            list={optionsList}
            name="projects"
            onUpdateSelection={updated =>
              onToggleProject({
                allProjects: projects,
                updated
              })
            }
            testId="projects"
          />
        }
        testId={thisTestId}
      />
    </div>
  );
};

export default ProjectsFilter;
