import { actionTypes } from "../constants/actionTypes";

/**
 * return action to store user in state
 * @param {Object} user
 */
export const storeUser = user => {
  return {
    type: actionTypes.STORE_USER,
    payload: { user }
  };
};
