import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

// styled components
const StyledTextInput = styled.input`
  border: 0;
  border-bottom: ${props => props.theme.borders.widths.md} solid
    ${props => props.theme.colors.border};
  padding: 2rem 0 0.1em;
`;

// typescript props
type Props = {
  className?: string;
  id?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
  testId?: string;
};

const TextInput = ({
  className,
  id,
  onBlur = () => null,
  onChange,
  placeholder,
  value,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-text-input`;
  return (
    <StyledTextInput
      className={className}
      data-testid={thisTestId}
      id={id}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      type="text"
      value={value}
    />
  );
};

export default TextInput;
