import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { flatten, isEmpty, isNull } from "lodash";

// components
import ChartPrCadence from "../ChartPrCadence/ChartPrCadence";
import EmptyState from "../EmptyState/EmptyState";
import PrCadenceList from "../PrCadenceList/PrCadenceList";
import Spinner from "../Spinner/Spinner";

// utils
import { useBackingData } from "./useBackingData";
import { legacyRoleTypes } from "../../constants/users";

// styled components
const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 30rem;
  width: 100%;
  background-color: ${props => props.theme.colors.all.white};
`;
const Figure = styled.figure`
  margin-bottom: 3rem;
  width: 100%;
`;

const PrCadenceChartWrapper = ({ testId = "testId", ...props }) => {
  const {
    className,
    accessToken,
    tenantId,
    manualState,
    user,
    teamMembers,
    board,
    timerange
  } = props;
  const thisTestId = `${testId}-pr-cadence-chart-wrapper`;
  const [selectedDateRange, setSelectedDateRange] = useState();
  const [prCadenceData, isFetchingData, error] = useBackingData(
    manualState,
    accessToken,
    tenantId,
    user,
    teamMembers,
    board,
    timerange
  );

  if (error) {
    return null;
  }

  // initial value of isFetchingData. After initial render, isFetchingData spinner
  // will be displayed  as part  of chart.
  if (isNull(isFetchingData) || isFetchingData) {
    return (
      <SpinnerWrapper>
        <Spinner testId={testId} />
      </SpinnerWrapper>
    );
  }

  const dataIsEmpty = isEmpty(prCadenceData.rows);

  let prList = [];
  if (!dataIsEmpty) {
    prList = selectedDateRange
      ? prCadenceData.groupedRows[selectedDateRange.start].prs
      : flatten(Object.values(prCadenceData.groupedRows).map(r => r.prs));
  }

  // we use the same component for both the ic private and team widgets. The data fetcher
  // uses this role check to determine as well
  const emptyStateRole =
    user.enabled_roles.includes(legacyRoleTypes.PRIVATEIC) ||
    user.enabled_roles.includes(legacyRoleTypes.PRIVATEIC_EXEC)
      ? "You don't"
      : "Your team doesn't";

  return (
    <div className={className} data-testid={thisTestId}>
      {dataIsEmpty ? (
        <EmptyState
          body={emptyStateRole.concat(" seem to have any recent data.")}
          header="Hmmm."
          testId={testId}
        />
      ) : (
        <>
          <Figure>
            <ChartPrCadence
              data={prCadenceData}
              isFetchingData={isFetchingData}
              onClickSeries={setSelectedDateRange}
              selectedDateRange={selectedDateRange}
              testId={thisTestId}
            />
            <figcaption className="visuallyHidden">PR Cadence chart</figcaption>
          </Figure>
          {/* todo: add this back when we get final data shape */}
          <PrCadenceList
            list={prList}
            selectedDateRange={selectedDateRange}
            testId={thisTestId}
          />
        </>
      )}
    </div>
  );
};

PrCadenceChartWrapper.propTypes = {
  accessToken: PropTypes.string.isRequired,
  className: PropTypes.string,
  tenantId: PropTypes.number.isRequired,
  testId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  board: PropTypes.object,
  timerange: PropTypes.object
};

export default PrCadenceChartWrapper;
