import React from "react";
import styled from "styled-components/macro";
import { UserReportWithMetadataInterface } from "../../interfaces/user";

// components
import TeamCardConfirmationModalContent from "../TeamCardConfirmationModalContent/TeamCardConfirmationModalContent";
import TeamCardSubmitModalContent from "../TeamCardSubmitModalContent/TeamCardSubmitModalContent";

// styled components
const Header = styled.h3`
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Arial,
    Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.book};
  margin-bottom: 2.4rem;
`;

// typescript props
type Props = {
  className?: string;
  displayModal: { id: string; [key: string]: any };
  mode: string;
  onCancelDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancelRemoveTeamMember: ({
    e,
    teamMember
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    teamMember: UserReportWithMetadataInterface;
  }) => void;
  onCancelSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onConfirmDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onConfirmRemoveTeamMember: ({
    e,
    teamMember
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    teamMember: UserReportWithMetadataInterface;
  }) => void;
  onConfirmSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  testId?: string;
};

const TeamCardModalContent = ({
  className,
  displayModal,
  mode,
  onCancelDelete,
  onCancelRemoveTeamMember,
  onCancelSubmit,
  onConfirmDelete,
  onConfirmRemoveTeamMember,
  onConfirmSubmit,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-team-card-modal-content`;
  const isNewTeam = mode === "create-team";

  let header, modalContent;
  if (displayModal?.id === "create-team-submit") {
    header = "You are about to create a team";
    modalContent = (
      <TeamCardConfirmationModalContent
        content={
          <TeamCardSubmitModalContent
            action="verify"
            addedTeamMembers={displayModal.addedTeamMembers}
            displayTenantAlias={displayModal.displayTenantAlias}
            newTeamMembers={displayModal.newTeamMembers}
            teamName={displayModal.teamName}
            testId={thisTestId}
          />
        }
        name={displayModal.id}
        onCancel={onCancelSubmit}
        onConfirm={onConfirmSubmit}
        testId={thisTestId}
      />
    );
  } else if (
    displayModal?.id === "create-team-confirm-submit" ||
    displayModal?.id === "update-team-confirm-submit"
  ) {
    header = isNewTeam ? (
      <>You&rsquo;ve created a team</>
    ) : (
      "Your team changes have been saved"
    );
    modalContent = (
      <TeamCardSubmitModalContent
        action="confirm"
        addedTeamMembers={displayModal.addedTeamMembers}
        displayTenantAlias={displayModal.displayTenantAlias}
        newTeamMembers={displayModal.newTeamMembers}
        removedTeamMembers={displayModal.removedTeamMembers}
        teamName={displayModal.teamName}
        testId={thisTestId}
      />
    );
  } else if (
    displayModal?.id === "update-team-remove-team-member" &&
    !!displayModal?.teamMember
  ) {
    modalContent = (
      <TeamCardConfirmationModalContent
        content={`Are you sure you want to remove ${
          displayModal.isSelf ? "yourself from this team" : "this team member"
        }?`}
        name={displayModal.id}
        onCancel={e =>
          onCancelRemoveTeamMember({
            e,
            teamMember: displayModal.teamMember
          })
        }
        onConfirm={e =>
          onConfirmRemoveTeamMember({
            e,
            teamMember: displayModal.teamMember
          })
        }
        testId={thisTestId}
      />
    );
  } else if (displayModal?.id === "update-team-delete-team") {
    modalContent = (
      <TeamCardConfirmationModalContent
        content="Are you sure you want to delete this team?"
        name={displayModal.id}
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
        testId={thisTestId}
      />
    );
  }

  return (
    <div className={className} data-testid={thisTestId}>
      {header ? <Header>{header}</Header> : null}
      {modalContent}
    </div>
  );
};

export default TeamCardModalContent;
