import React from "react";
import styled from "styled-components/macro";

// styled components
const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
`;
const Buttons = styled.div`
  align-self: center;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 3rem;
  width: 25%;
`;
const Button = styled.button`
  background: none;
  border: 0;
`;
const ConfirmButton = styled(Button)`
  color: ${props => props.theme.colors.all.auroraTeal};
`;
const CancelButton = styled(Button)`
  color: ${props => props.theme.colors.all.storm};
`;

// typescript props
type Props = {
  className?: string;
  content: React.ReactNode;
  name: string;
  onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onConfirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  testId?: string;
};

const TeamCardConfirmationModalContent = ({
  className,
  content,
  name,
  onCancel,
  onConfirm,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-${name}-team-card-confirmation-modal-content`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <header>{content}</header>
      <Buttons>
        <ConfirmButton
          onClick={onConfirm}
          data-testid={`${thisTestId}-confirm-button`}
        >
          Yes
        </ConfirmButton>
        <CancelButton
          onClick={onCancel}
          data-testid={`${thisTestId}-cancel-button`}
        >
          No
        </CancelButton>
      </Buttons>
    </Container>
  );
};

export default TeamCardConfirmationModalContent;
