import React, { useState } from "react";
import styled from "styled-components/macro";
import { last } from "lodash";

// actions
import { storeOrganization } from "../../actions/sessionActions";

// components
import Icon from "../Icon/Icon";
import OrganizationNavigationMenuCard from "../OrganizationNavigationMenuCard/OrganizationNavigationMenuCard";
import OrganizationNavigationMenuCardsList from "../OrganizationNavigationMenuCardsList/OrganizationNavigationMenuCardsList";
import ScrollContainerWithReset from "../ScrollContainerWithReset/ScrollContainerWithReset";

// hooks
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "../../hooks/useTracking";
import { useQueryClient } from "react-query";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import {
  UserInterface,
  UserReportWithMetadataInterface
} from "../../interfaces/user";
import { useOrganizationData } from "../../hooks/useOrganizationData";
import { useUrlParams } from "../../hooks/useUrlParams";

// constants
const NUM_VISIBLE_LEVELS = 2;

// styled components
const ScrollContainer = styled(ScrollContainerWithReset)`
  max-height: 70vh;
`;
const Container = styled.div`
  background: ${props => props.theme.colors.all.lightJean};
  display: inline-block;
`;
const Header = styled.header`
  background: ${props => props.theme.colors.all.white};
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  margin-bottom: 1rem;
`;
const OrganizationWrapper = styled.div`
  display: flex;
  padding: 1rem 2rem 2rem;
`;
const CardsList = styled(OrganizationNavigationMenuCardsList)`
  width: 26.4rem;
`;
const LineWrapper = styled.div`
  align-items: flex-start;
  border-left: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.wolverine}`};
  margin-left: 1.25rem;
`;
const SecondaryCardsList = styled(CardsList)`
  background: ${props => props.theme.colors.all.lightJean};
  padding: 0 1.25rem;
`;
const Breadcrumb = styled.ul`
  align-items: center;
  display: flex;
  padding: 0 2rem;
`;
const BreadcrumbItem = styled.li`
  & + & {
    margin-left: 1rem;
  }
`;
const BreadcrumbChevron = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  font-size: 1.1rem;
`;
const BreadcrumbName = styled.button`
  border: 0;
  background: none;
  font-size: 1.1rem;
`;

// typescript props
type Props = {
  className?: string;
  onUpdate: () => void;
  testId?: string;
};

const OrganizationNavigationMenu = ({
  className,
  onUpdate,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-organization-navigation-menu`;
  const { updateUrlParams } = useUrlParams();

  // organization
  const { data: orgChartRootTeam } = useOrganizationData();
  const { trackEvent } = useTracking();
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const user = useSelector((state: AppStateInterface) => state.user);
  // massaging the main user into UserReportWithMetadataInterface shape, ugh
  const userWithMetadata = {
    ...user,
    tenantId,
    team: orgChartRootTeam,
    image: "",
    slackHandle: null,
    slackUrl: null,
    include_in_team_roster: true
  };
  const [breadcrumb, setBreadcrumb] = useState<
    Array<UserReportWithMetadataInterface>
  >([userWithMetadata]);
  const [currentBreadcrumbIndex, setCurrentBreadcrumbIndex] = useState<number>(
    0
  );
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  function invalidateQueries() {
    // this is a bit duplicative with ReportsCard, this should be
    // in a hook somewhere central, not a super good place until we're off the
    // legacy app completely
    queryClient.invalidateQueries("projects-and-boards");
    queryClient.invalidateQueries("sprint-metadata");
    queryClient.invalidateQueries("sprint-health");
    queryClient.invalidateQueries("active-board");
    queryClient.invalidateQueries(["teams", { type: "active-users-teams" }]);
  }

  async function onClickRootPerson({
    e,
    person
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    person: UserInterface;
  }) {
    // invalidate the timerange before updating the active team so it can be
    // reset and not have the sprintid persisting in work-items calls
    // dispatch(storeUiFilterParams({ timerange: null }));
    updateUrlParams({ teamLeadId: null, team: orgChartRootTeam }, false);
    invalidateQueries();
    dispatch(
      storeOrganization({
        parentsOfActiveTeam: []
      })
    );
    await trackEvent({
      e,
      label: "organization-navigation-menu",
      value: `clicked ${person.name}`
    });
    await trackEvent({
      e,
      label: "changed-user",
      value: `updated app to ${person.name} (${person.email})`
    });
    onUpdate();
  }

  async function onClickPerson({
    e,
    person,
    activePerson
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    person: UserReportWithMetadataInterface;
    activePerson?: UserReportWithMetadataInterface;
  }) {
    // The root user is never in the org chart.
    //
    // We are adding the person into the breadcrumb, so
    // we remove them first to avoid duplicate breadcrumbs.
    //
    // Duplicate breadcrumbs would otherwise occur if the person is
    // currently active in the menu cards.
    const filteredBreadcrumb = [...breadcrumb].filter(
      u => u.id !== user.id && u.id !== person.id
    );
    const parentsOfActiveTeam =
      activePerson?.id !== person.id
        ? [...filteredBreadcrumb.slice(0, currentBreadcrumbIndex), person]
        : [...filteredBreadcrumb, person];

    invalidateQueries();
    updateUrlParams({ teamLeadId: person.id }, false);

    dispatch(
      storeOrganization({
        parentsOfActiveTeam: parentsOfActiveTeam
      })
    );
    await trackEvent({
      e,
      label: "organization-navigation-menu",
      value: `clicked ${person.name}`
    });
    await trackEvent({
      e,
      label: "changed-user",
      value: `updated app to ${person.name} (${person.email})`
    });
    onUpdate();
  }

  function onClickReportsNav({
    e,
    person,
    updateLevel = false
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    person: UserReportWithMetadataInterface;
    updateLevel?: boolean;
  }) {
    trackEvent({
      e,
      label: "organization-navigation-menu",
      value: `clicked direct reports for ${person.name}`
    });
    if (updateLevel) {
      setBreadcrumb(c => [...c, person]);
      setCurrentBreadcrumbIndex(c => c + 1);
    } else {
      setBreadcrumb(currentBreadcrumb => [
        ...currentBreadcrumb.slice(0, currentBreadcrumbIndex + 1),
        person
      ]);
    }
  }

  function onClickBreadcrumbItem({
    e,
    person,
    index
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    person: UserReportWithMetadataInterface;
    index: number;
  }) {
    trackEvent({
      e,
      label: "organization-navigation-menu",
      value: `clicked breadcrumb item for ${person.name}`
    });
    setCurrentBreadcrumbIndex(!!index ? index - 1 : index);
    setBreadcrumb(currentBreadcrumb => [
      ...currentBreadcrumb.slice(0, index),
      person
    ]);
  }

  const [personWithDisplayedReports, activePerson] = breadcrumb.slice(
    -1 * NUM_VISIBLE_LEVELS
  );

  return (
    <ScrollContainer resetTrigger={last(breadcrumb)?.id}>
      <Container className={className} data-testid={thisTestId}>
        <Header>
          <OrganizationNavigationMenuCard
            onClickPerson={(
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => onClickRootPerson({ e, person: user })}
            onClickReportsNav={(
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => onClickReportsNav({ e, person: userWithMetadata })}
            person={userWithMetadata}
            showDirectReports={false}
            showArrowInline={true}
            testId={thisTestId}
          />
        </Header>
        {breadcrumb.length > NUM_VISIBLE_LEVELS ? (
          <Breadcrumb>
            {breadcrumb.map((b, index) => (
              <BreadcrumbItem key={b.id}>
                <BreadcrumbChevron icon="arrow-left" testId={thisTestId} />
                <BreadcrumbName
                  data-testid={`${b.id}-breadcrumb-item-button`}
                  onClick={e =>
                    onClickBreadcrumbItem({
                      e,
                      person: b,
                      index
                    })
                  }
                >
                  {b.name}
                </BreadcrumbName>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        ) : null}
        <OrganizationWrapper>
          <CardsList
            activePerson={activePerson}
            onClickPerson={onClickPerson}
            onClickReportsNav={onClickReportsNav}
            team={personWithDisplayedReports?.team}
            testId={thisTestId}
          />
          {!!activePerson ? (
            <LineWrapper>
              <SecondaryCardsList
                onClickPerson={onClickPerson}
                onClickReportsNav={({ e, person }) =>
                  onClickReportsNav({
                    e,
                    person,
                    updateLevel: true
                  })
                }
                team={activePerson?.team}
                testId={thisTestId}
              />
            </LineWrapper>
          ) : null}
        </OrganizationWrapper>
      </Container>
    </ScrollContainer>
  );
};

export default OrganizationNavigationMenu;
