import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { isEmpty, isNil, isNull } from "lodash";

// components
import DataDisclaimer from "../DataDisclaimer/DataDisclaimer";
import EmptyState from "../EmptyState/EmptyState";
import InfoIcon from "../InfoIcon/InfoIcon";
import OverviewDeepWorkByGroup from "../OverviewDeepWorkByGroup/OverviewDeepWorkByGroup";
import RouteLink from "../RouteLink/RouteLink";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";
import OverviewSummary from "../OverviewSummary/OverviewSummary";
import OverviewDeepWorkTrendlineTooltip from "../OverviewDeepWorkTrendlineTooltip/OverviewDeepWorkTrendlineTooltip";

// constants
import { momentUnitTypes, namedTimerangeIds } from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useOrganizationDeepWorkData } from "../../hooks/useOrganizationDeepWorkData";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import {
  OrgDeepWorkByGroup,
  OrgByTime,
  OrgByTimeItems,
  OrgDeepWorkByUser,
  OrgTimeRange,
  OrgTimeRanges,
  OrgTeamItems,
  Roles
} from "../../interfaces/organization";
import { UserReportWithUplevelRole } from "../../interfaces/user";

// utils
import {
  getOrgDeepWorkByGroup,
  getMappedSummaryData,
  getDisplayTrendlinePoints
} from "../../utils/organization-data";
import moment from "moment";

// styled components
const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
`;
const Header = styled.header`
  display: flex;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  align-items: center;
  margin-bottom: ${props => props.theme.grid.gap};
`;
const MainContent = styled.div`
  min-height: 70rem;
`;
const EmptyStateWrapper = styled(TransitionWrapper)`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: inherit;
`;
const Footer = styled.footer`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  gap: 10rem;
  margin-bottom: 1rem;
`;
const Benchmark = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.all.storm};
  display: flex;
`;
const BenchmarkLine = styled.div`
  border-top: ${props =>
    `${props.theme.borders.widths.sm} dashed ${props.theme.colors.all.storm}`};
  width: 3rem;
  margin-right: 1rem;
`;

// typescript props
type Props = {
  availableReportGroups: Array<string> | null;
  availableRoles: Array<Roles> | null;
  availableTeams: OrgTeamItems | null;
  className?: string;
  fullOrganizationData?: Array<UserReportWithUplevelRole>;
  isErrorOverviewData: boolean;
  isFetchingOverviewData: boolean;
  testId?: string;
  userIds: Array<number> | null;
  selectedTimeRange?: OrgTimeRange;
  orgTimeRanges?: OrgTimeRanges;
};

const OverviewDeepWorkPage = ({
  availableReportGroups,
  availableRoles,
  availableTeams,
  className,
  fullOrganizationData,
  isErrorOverviewData,
  isFetchingOverviewData,
  testId = "testId",
  userIds,
  selectedTimeRange,
  orgTimeRanges
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-overview-deep-work-page`;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const { getOverviewPath, urlParams } = useUrlParams();
  // TODO: if we decide to move the filter reset link somewhere else, probably
  // move this to a prop instead of having the urlparams hook
  const groupBy = urlParams.groupBy;
  const urlParamsTeams = urlParams.teams;

  const {
    data: summaryData,
    isError: isErrorSummaryData,
    isFetching: isFetchingSummaryData,
    isSuccess: isSuccessSummaryData
  } = useOrganizationDeepWorkData(
    {
      type: "year",
      userIds: userIds as Array<number>
    },
    !isEmpty(userIds) && !isNil(selectedTimeRange)
  );
  // create a sorted array of dates with extended metadata
  const mappedSummaryData: OrgByTimeItems | null = useMemo(() => {
    if (
      isNil(orgTimeRanges) ||
      isNil(summaryData) ||
      Object.values(summaryData).every(v => isNull(v))
    ) {
      return null;
    }

    return getMappedSummaryData(summaryData as OrgByTime, orgTimeRanges);
  }, [summaryData, orgTimeRanges]);

  // get the current and previous trendline points to select for data display
  const [previous, current] = useMemo(() => {
    if (isNil(mappedSummaryData) || isNil(selectedTimeRange)) {
      return [];
    }
    return getDisplayTrendlinePoints(mappedSummaryData, selectedTimeRange);
  }, [mappedSummaryData, selectedTimeRange]);
  const {
    data: byGroupData,
    isError: isErrorByGroupData,
    isFetching: isFetchingByGroupData,
    isSuccess: isSuccessByGroupData
  } = useOrganizationDeepWorkData(
    {
      endDate: moment(selectedTimeRange?.endDate)
        .add(1, momentUnitTypes.DAYS)
        .format("YYYY-MM-DD"),
      type: namedTimerangeIds.PREVIOUS_14_DAYS
    },
    !!selectedTimeRange
  );

  // group by-user data based on selected groupBy
  const groupedByGroupData: OrgDeepWorkByGroup | null = useMemo(() => {
    if (
      isNil(fullOrganizationData) ||
      isNil(userIds) ||
      isNil(availableTeams) ||
      isNil(byGroupData) ||
      isNil(urlParamsTeams)
    ) {
      return null;
    }

    const typedData = byGroupData as OrgDeepWorkByUser;
    const groupedData = getOrgDeepWorkByGroup({
      allUsers: fullOrganizationData,
      teams: availableTeams,
      data: typedData,
      groupBy,
      urlParamsTeams,
      userIds
    });

    if (isEmpty(groupedData)) {
      return null;
    }

    return {
      ...typedData,
      data: groupedData
    };
  }, [
    availableTeams,
    byGroupData,
    fullOrganizationData,
    groupBy,
    urlParamsTeams,
    userIds
  ]);

  const hasNoRawSummaryData = useMemo(() => {
    return (
      isErrorSummaryData ||
      (isSuccessSummaryData &&
        (isNil(summaryData) ||
          Object.values(summaryData as OrgByTime).every(v => isNull(v))))
    );
  }, [isErrorSummaryData, isSuccessSummaryData, summaryData]);
  const hasNoSummaryData = useMemo(() => {
    return !userIds?.length || isNull(mappedSummaryData);
  }, [mappedSummaryData, userIds]);
  const hasNoRawByGroupData = useMemo(() => {
    return (
      isErrorByGroupData ||
      (isSuccessByGroupData &&
        (byGroupData as OrgDeepWorkByUser).data.every(d => isNull(d.value)))
    );
  }, [byGroupData, isErrorByGroupData, isSuccessByGroupData]);
  const hasNoByGroupData = useMemo(() => isNull(groupedByGroupData), [
    groupedByGroupData
  ]);

  const isFetchingRawChartsData =
    isFetchingSummaryData || isFetchingByGroupData;
  const isFetchingAnyData = isFetchingRawChartsData || isFetchingOverviewData;

  const hasNoFetchedData =
    !isFetchingAnyData && hasNoRawSummaryData && hasNoRawByGroupData;
  const hasNoFilteredData =
    !isFetchingAnyData && hasNoSummaryData && hasNoByGroupData;
  const isErrorAllData = isErrorSummaryData && isErrorByGroupData;

  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        <h3>Deep Work</h3>
        <InfoIcon content={"deep-work"} />
      </Header>
      <MainContent>
        {!isErrorAllData && (hasNoFetchedData || hasNoFilteredData) ? (
          <EmptyStateWrapper>
            <EmptyState
              body={
                hasNoFetchedData ? (
                  <>There doesn&rsquo;t seem to be any data available.</>
                ) : (
                  <>
                    <p>
                      There doesn&rsquo;t seem to be any data for the filters
                      that are applied.
                    </p>
                    <p>
                      Click{" "}
                      <RouteLink
                        name={`${thisTestId}-no-data`}
                        to={getOverviewPath({
                          persistQuerystring: false
                        })}
                        testId={thisTestId}
                      >
                        here
                      </RouteLink>{" "}
                      to reset all filters.
                    </p>
                  </>
                )
              }
              header="Hmmm."
              testId={testId}
            />
          </EmptyStateWrapper>
        ) : (
          <ChartGrid>
            <OverviewSummary
              all={mappedSummaryData}
              benchmark={flags?.["deep-work-benchmarks"]}
              current={current}
              isEmptyData={hasNoSummaryData}
              isError={isErrorSummaryData}
              isFetching={isFetchingSummaryData || isFetchingOverviewData}
              previous={previous}
              testId={thisTestId}
              tooltip={<OverviewDeepWorkTrendlineTooltip />}
              measure={"deep-work"}
            />
            <OverviewDeepWorkByGroup
              availableReportGroups={availableReportGroups}
              availableRoles={availableRoles}
              availableTeams={availableTeams}
              data={groupedByGroupData}
              benchmark={flags?.["deep-work-benchmarks"]}
              groupBy={groupBy}
              isEmptyData={hasNoByGroupData}
              isError={isErrorByGroupData || isErrorOverviewData}
              isFetching={isFetchingAnyData}
              testId={thisTestId}
            />
          </ChartGrid>
        )}
      </MainContent>
      <Footer>
        {!isFetchingAnyData &&
        (hasNoFetchedData || hasNoFilteredData) ? null : (
          <Benchmark>
            <BenchmarkLine>
              <span className="visuallyHidden">visible dashed line</span>
            </BenchmarkLine>
            <small>Uplevel Benchmark</small>
            <InfoIcon content="overview-benchmark" />
          </Benchmark>
        )}
        <DataDisclaimer testId={thisTestId} />
      </Footer>
    </div>
  );
};

export default OverviewDeepWorkPage;
