import React, { ReactElement, useMemo } from "react";
import { DefaultTheme, withTheme } from "styled-components/macro";

// components
import Chart from "../Chart/Chart";
import OperationalMetricsSparklineTooltip from "../OperationalMetricsSparklineTooltip/OperationalMetricsSparklineTooltip";

// constantss
import { cycleTimeStages } from "../../constants/constants";

// interfaces
import { OpMetricData, OpMetricValueTypes } from "../../interfaces/ops-metrics";

// config
import generateConfig from "./config";

// typescript props
type Props = {
  className?: string;
  data: OpMetricData;
  isInverted?: boolean;
  name: OpMetricValueTypes | keyof typeof cycleTimeStages;
  metric: OpMetricValueTypes;
  testId?: string;
  theme: DefaultTheme;
};

const ChartOpsMetricsSparkline = ({
  className,
  data,
  isInverted = false,
  name,
  metric,
  testId = "testId",
  theme
}: Props): ReactElement => {
  const thisTestId = `${testId}-chart-ops-metrics-sparkline-${name}`;

  const chart = useMemo(() => {
    return {
      config: generateConfig({ data, isInverted, metric, name }),
      id: `chart-ops-metrics-sparkline-${name}`
    };
  }, [data, isInverted, metric, name]);

  return (
    <div className={className} data-testid={thisTestId}>
      <Chart
        chart={chart}
        testId={thisTestId}
        theme={theme}
        tooltipComponent={<OperationalMetricsSparklineTooltip />}
      />
    </div>
  );
};

export default withTheme(ChartOpsMetricsSparkline);
