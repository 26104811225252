import React from "react";
import PropTypes from "prop-types";
import styled, {
  createGlobalStyle,
  ThemeProvider
} from "styled-components/macro";
import { currentTheme } from "../../themes/currentTheme";

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.uiBackground};
    font-size: 10px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    background: transparent;
    color: ${props => props.theme.colors.text.body};
    font-family: ${props =>
      props.theme.fonts.primary.name}, Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    font-weight: ${props => props.theme.fonts.primary.weights.regular};

    // i don't know what this is exactly or where it comes from but sometimes
    // after the okta flow has resolved, we get this empty iframe that covers
    // the entire ui and makes it so that you can't interact with it until you
    // reload the page. i haven't seen it on the auth0 flow so eventually okta
    // will go away but hopefully it's not important. 😬
    & > iframe {
      z-index: -1 !important;
    }
  }

  a {
    &,
    &:hover,
    &:active,
    &:visited {
      color: ${props => props.theme.colors.link.color};
    }
  }

  ul, ol, dl {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  /*
    this is repeated in a couple of places un-DRY-ly.
    todo: figure out the best way to propagate this with styled components
  */
  .visuallyHidden {
    /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
`;

// styled components
const StyledUplevelUi = styled.div`
  margin: 0 auto;
`;

const UplevelUi = ({ children, testId, theme = currentTheme }) => {
  if (!children || !testId) {
    return null;
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <StyledUplevelUi data-testid={`${testId}-uplevel-ui`}>
        <GlobalStyle />
        {children}
      </StyledUplevelUi>
    </ThemeProvider>
  );
};
UplevelUi.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  testId: PropTypes.string.isRequired,
  theme: PropTypes.object
};
export default UplevelUi;
