import React from "react";
import styled from "styled-components/macro";
import { isEmpty, isNull, kebabCase, startCase } from "lodash";

// components
import ChartCycleTime from "../ChartCycleTime/ChartCycleTime";
import CycleTimeValueDisplay from "../CycleTimeValueDisplay/CycleTimeValueDisplay";
import Icon from "../Icon/Icon";
import InfoIcon from "../InfoIcon/InfoIcon";
import RouteLink from "../RouteLink/RouteLink";

// constants
import {
  workDeepDiveSections,
  reservedEpicsMetadata,
  signalTypes
} from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { ActivityInterface, GroupInterface } from "../../interfaces/work-items";

// utils
import { rollupCycleTime } from "../../utils/cycle-time";

//styled components
const Header = styled.header`
  align-items: flex-start;
  display: flex;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.brand.jean}`};
`;
const Title = styled.h3`
  align-items: baseline;
  display: flex;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  line-height: 1;
  margin-bottom: 1rem;
`;
const CycleTimeRow = styled.div`
  align-items: baseline;
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.brand.jean}`};
  padding-top: 1rem;
  padding-bottom: 1rem;
`;
const Chart = styled(ChartCycleTime)`
  font-size: 1.1rem;

  &:not(:empty) {
    margin-left: 1rem;
  }
`;
const Arrow = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  line-height: 1;
  margin-left: 1rem;
`;
const EpicLink = styled(RouteLink)`
  align-items: center;
  display: flex;
  flex: 2;

  && {
    color: inherit;

    &:hover {
      color: ${props => props.theme.colors.all.auroraTeal};
      text-decoration: none;

      & ${Arrow} {
        color: ${props => props.theme.colors.all.auroraTeal};
      }
    }
  }
`;

type Props = {
  className?: string;
  groupedWorkItems?: GroupInterface[];
  testId?: string;
  sectionType?: string;
};

const CycleTimeByGroupTable = ({
  className,
  groupedWorkItems = [],
  testId = "testId",
  sectionType = signalTypes.HEALTHY
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-cycle-time-by-group-table`;
  const { trackEvent } = useTracking();
  const { urlParams, getWorkDeepDivePath } = useUrlParams();
  const groupBy = urlParams.groupBy;

  function onShowCycleTimeTooltip({
    e,
    group
  }: {
    e: React.MouseEvent<HTMLElement>;
    group: GroupInterface;
  }) {
    trackEvent({
      e,
      label: `cycle-time-by-group-table-cycle-time-mouse-over`,
      value: `mouse over ${group.groupName} cycle time chart`
    });
  }

  function getOrDefault({ o, def }: { o: any | undefined; def: any }) {
    return !o ? def : o;
  }
  const title = `${startCase(groupBy.toLowerCase())}${
    sectionType === signalTypes.HEALTHY
      ? "s with Shorter Cycle Times"
      : "s with Longer Cycle Times"
  }`;
  const infoText =
    sectionType === signalTypes.HEALTHY
      ? "cycle-time-healthy"
      : "cycle-time-unhealthy";

  const cycleTimesInSection = groupedWorkItems
    .filter((group: GroupInterface) => {
      return group.activities.find(
        (a: ActivityInterface) => !isEmpty(a.cycleTime)
      );
    })
    .map((group: GroupInterface) => {
      const cycleTime = getOrDefault({
        o: group?.activities.find(
          (a: ActivityInterface) => !isEmpty(a.cycleTime)
        )?.cycleTime,
        def: null
      });

      // filter out cycle times we have no data for, but
      // allow display of cycle times that are all 0 values
      if (isNull(cycleTime)) {
        return null;
      }

      const totalCycleTime = rollupCycleTime(cycleTime);
      const daysInCycleTime = totalCycleTime.find(c => c.unit === "DAYS")
        ?.value;

      if (
        sectionType === signalTypes.HEALTHY &&
        daysInCycleTime &&
        daysInCycleTime >= 3
      )
        return null;
      if (
        sectionType === signalTypes.UNHEALTHY &&
        (!daysInCycleTime || daysInCycleTime < 3)
      )
        return null;

      const linkPath = getWorkDeepDivePath({
        selectedSection: workDeepDiveSections.PR_WORKFLOW
      });

      const header = !!group.magicRow
        ? Object.values(reservedEpicsMetadata).find(
            m => m.magicRowId === group.magicRow
          )?.title
        : group.groupName;
      const rowId = `${group.groupId}-${kebabCase(group.groupName)}`;
      return (
        <CycleTimeRow key={rowId}>
          <EpicLink name="retros-cycle-time-link" to={linkPath}>
            <header data-heap-redact-text="true">{header}</header>
            <Arrow icon="line-arrow-right" testId={`${thisTestId}-${rowId}`} />
          </EpicLink>
          <CycleTimeValueDisplay stage={totalCycleTime} testId={thisTestId} />
          <Chart
            cycleTime={cycleTime}
            onMouseOver={(e: React.MouseEvent<HTMLElement>) =>
              onShowCycleTimeTooltip({ e, group })
            }
          />
        </CycleTimeRow>
      );
    })
    .filter(x => x);
  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        <Title>{title}</Title>
        <InfoIcon content={infoText} />
      </Header>
      {cycleTimesInSection.length ? (
        cycleTimesInSection
      ) : (
        <CycleTimeRow>None</CycleTimeRow>
      )}
    </div>
  );
};

export default CycleTimeByGroupTable;
