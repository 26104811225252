import React from "react";
import styled from "styled-components/macro";

// components
import CloseButton from "../CloseButton/CloseButton";
import OtherSprintItemsFilter from "../OtherSprintItemsFilter/OtherSprintItemsFilter";
import OwnerContributorFilter from "../OwnerContributorFilter/OwnerContributorFilter";
import ProjectsFilter from "../ProjectsFilter/ProjectsFilter";
import PullRequestCalloutsFilter from "../PullRequestCalloutsFilter/PullRequestCalloutsFilter";
import RepositoryFilter from "../RepositoryFilter/RepositoryFilter";
import StatusFilter from "../StatusFilter/StatusFilter";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";
import TypesOfWorkFilter from "../TypesOfWorkFilter/TypesOfWorkFilter";
import WorkItemCalloutsFilter from "../WorkItemCalloutsFilter/WorkItemCalloutsFilter";
import WorkItemTypeFilter from "../WorkItemTypeFilter/WorkItemTypeFilter";

// constants
import { groupByTypes, workDeepDiveSections } from "../../constants/constants";

// hooks
import { useRouteMatch } from "react-router-dom";
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";

// utils
import { getIsSprintMode } from "../../utils/sprints";
import { useWorkItemsData } from "../../hooks/useWorkItemsData";

// styled components
const Container = styled.div`
  background: ${props => props.theme.colors.all.lightJean};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const FilterWrapper = styled.div`
  & + & {
    margin-top: 2rem;
  }
`;
const FilterHeader = styled.header`
  align-items: center;
  background: ${props => props.theme.colors.all.white};
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 2rem;
`;
const Close = styled(CloseButton)`
  font-size: 1.5rem;
  padding: 1.5rem 2rem;
`;
const Title = styled.h4`
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin: 0;
`;
const DoneButton = styled.button`
  align-self: flex-end;
  background: ${props => props.theme.colors.all.auroraTeal};
  border: 0;
  color: ${props => props.theme.colors.all.white};
  margin-top: 3rem;
  padding: 1rem 4rem;
`;

// typescript props
type Props = {
  className?: string;
  onClickClose: () => void;
  testId?: string;
};

const EpicFilter = ({
  className,
  onClickClose,
  testId = "testId"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-epic-filter`;
  const { trackEvent } = useTracking();
  const match = useRouteMatch();

  const { updateUrlParams, urlParams, workDeepDiveParams } = useUrlParams();
  const { data: workItemsData, isFetching, isSuccess } = useWorkItemsData({
    options: { type: workDeepDiveParams.selectedSection }
  });
  const isSprintMode = getIsSprintMode(urlParams.timerange);
  const isGroupedByUser = urlParams.groupBy === groupByTypes.USER;
  const isPRWorkflowTab =
    workDeepDiveParams.selectedSection === workDeepDiveSections.PR_WORKFLOW;

  function onClickCloseButton(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.persist();
    trackEvent({
      e,
      label: `filter-bar-x-close-button`,
      value: `clicked x close at page ${match.url}`
    });
    onClickClose();
  }

  function onClickDoneButton(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.persist();
    trackEvent({
      e,
      label: `filter-bar-done-button`,
      value: `clicked done at page ${match.url}`
    });
    onClickClose();
  }

  return (
    <Container className={className} data-testid={thisTestId}>
      <TransitionWrapper>
        <FilterHeader>
          <Title>Filters</Title>
          <Close onClick={onClickCloseButton} testId={thisTestId} />
        </FilterHeader>
        <ContentWrapper>
          {isSprintMode ? (
            <FilterWrapper>
              <TypesOfWorkFilter
                typesOfWork={urlParams.typesOfWork}
                testId={thisTestId}
                updateUrlParams={updateUrlParams}
                workItemsData={workItemsData}
              />
            </FilterWrapper>
          ) : null}
          {isSprintMode && !isGroupedByUser && !isPRWorkflowTab ? (
            <FilterWrapper>
              <OtherSprintItemsFilter
                otherSprintItems={urlParams.otherSprintItems}
                testId={thisTestId}
                updateUrlParams={updateUrlParams}
                workItemsData={workItemsData}
              />
            </FilterWrapper>
          ) : null}
          <FilterWrapper>
            <OwnerContributorFilter
              ownerContributor={urlParams.ownerContributor}
              testId={thisTestId}
              updateUrlParams={updateUrlParams}
              useAlternativeLabels={isPRWorkflowTab}
            />
          </FilterWrapper>
          {!isPRWorkflowTab ? (
            <FilterWrapper>
              <WorkItemCalloutsFilter
                callouts={urlParams.callouts}
                isSprintMode={isSprintMode}
                testId={thisTestId}
                updateUrlParams={updateUrlParams}
                workItemsData={workItemsData}
              />
            </FilterWrapper>
          ) : null}
          <FilterWrapper>
            <PullRequestCalloutsFilter
              callouts={urlParams.callouts}
              testId={thisTestId}
              updateUrlParams={updateUrlParams}
              workItemsData={workItemsData}
            />
          </FilterWrapper>
          {!isPRWorkflowTab ? (
            <FilterWrapper>
              <WorkItemTypeFilter
                testId={thisTestId}
                updateUrlParams={updateUrlParams}
                workItemTypes={urlParams.workItemTypes}
                workItemsData={workItemsData}
              />
            </FilterWrapper>
          ) : null}
          <FilterWrapper>
            <StatusFilter
              status={urlParams.status}
              testId={thisTestId}
              updateUrlParams={updateUrlParams}
              workItemsData={workItemsData}
            />
          </FilterWrapper>
          <FilterWrapper>
            <RepositoryFilter
              isFetching={isFetching}
              isSuccess={isSuccess}
              testId={thisTestId}
              repositories={urlParams.repositories}
              updateUrlParams={updateUrlParams}
              workItemsData={workItemsData}
            />
          </FilterWrapper>
          <FilterWrapper>
            <ProjectsFilter
              testId={thisTestId}
              isFetching={isFetching}
              isSuccess={isSuccess}
              projectIds={urlParams.projectIds}
              updateUrlParams={updateUrlParams}
              workItemsData={workItemsData}
            />
          </FilterWrapper>
          <DoneButton
            onClick={onClickDoneButton}
            data-testid={`${thisTestId}-done-button`}
          >
            Done
          </DoneButton>
        </ContentWrapper>
      </TransitionWrapper>
    </Container>
  );
};

export default EpicFilter;
