import moment from "moment";

// constants
import { momentUnitTypes } from "../constants/constants";

// hooks
import { useUrlParams } from "./useUrlParams";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { formatTimestamp } from "../utils/date";
import {
  requestMeetingTime,
  requestMeetingTime_privateic
} from "../utils/meeting-time";
import { UserReportWithMetadataInterface } from "../interfaces/user";

export const useMeetingLengthData = ({
  canRequest = true,
  canViewTeamData = true,
  override,
  selectedUser,
  teamMembers
}: {
  canRequest: boolean;
  canViewTeamData: boolean;
  override: { title: string; overrideLabel: string } | null;
  selectedUser: string;
  teamMembers: Array<UserReportWithMetadataInterface>;
}) => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );

  const { urlParams } = useUrlParams();
  const startDate = urlParams.startDate;
  // adding a day to make end date inclusive
  const endDate = urlParams.endDate;
  let params: {
    startDate: string;
    endDate: string;
    override?: { title: string; overrideLabel: string };
  } = {
    startDate,
    endDate
  };
  if (!!override) {
    params = { ...params, override };
  }

  return useQuery(
    [
      "meeting-length",
      { startDate, endDate, teamMembers, selectedUser, override }
    ],
    () => {
      if (canViewTeamData) {
        return requestMeetingTime({
          accessToken,
          tenantId,
          params: {
            ...params,
            selectedUser,
            users: teamMembers.map(t => t.email)
          }
        });
      } else {
        return requestMeetingTime_privateic({
          accessToken,
          tenantId,
          params: {
            ...params,
            tenantId,
            startDate: formatTimestamp({
              format: "YYYY-MM-DD",
              timestamp: params.startDate
            }),
            endDate: formatTimestamp({
              format: "YYYY-MM-DD",
              timestamp: moment(params.endDate).add(1, momentUnitTypes.DAYS)
            }),
            userEmail: selectedUser
          }
        });
      }
    },
    {
      enabled:
        !!accessToken &&
        !!tenantId &&
        !!selectedUser &&
        !!teamMembers?.length &&
        canRequest
    }
  );
};
