import { AxiosResponse } from "axios";
import api from "./api";

// constants
import { pageMetadata, pageIds } from "../constants/constants";

// interfaces
import { JiraProjectDto } from "../interfaces/project-tracking-models";
import { Repository } from "../interfaces/repository";
import { UserSettingsInterface } from "../interfaces/user-settings";

// utils
import { getPageTitle } from "./router";

// typescript props
type BaseProps = {
  accessToken: string;
  tenantId: number;
  params?: {
    defaultTeamId: string;
    defaultJiraGroupBy: string;
  };
};

export const getSettingsPageTitle = (): string => {
  return getPageTitle(pageMetadata[pageIds.SETTINGS].title);
};

export const requestUserSettings = async ({
  accessToken,
  tenantId
}: BaseProps): Promise<UserSettingsInterface> => {
  const response: AxiosResponse<UserSettingsInterface> = await api.get<
    UserSettingsInterface
  >(`/${tenantId}/user-settings`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const updateUserSettings = async ({
  accessToken,
  params,
  tenantId
}: BaseProps): Promise<UserSettingsInterface> => {
  const response: AxiosResponse<UserSettingsInterface> = await api.put<
    UserSettingsInterface
  >(`/${tenantId}/user-settings`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const requestEnabledRepositories = async ({
  accessToken,
  tenantId
}: BaseProps): Promise<Array<Repository>> => {
  const response: AxiosResponse<Array<Repository>> = await api.get<
    Array<Repository>
  >(`/${tenantId}/enabled-repositories`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const requestEnabledJiraProjectsData = async ({
  accessToken,
  tenantId
}: BaseProps): Promise<Array<JiraProjectDto>> => {
  const response: AxiosResponse<Array<JiraProjectDto>> = await api.get<
    Array<JiraProjectDto>
  >(`/${tenantId}/enabled-jira-projects`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return response.data;
};
