import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// components
import ChartCycleTime from "../ChartCycleTime/ChartCycleTime";
import Icon from "../Icon/Icon";
import TeamList from "../TeamList/TeamList";

// constants
import {
  calloutMetadata,
  prActivityStatusLabels,
  prComplexityLevels
} from "../../constants/constants";

// interfaces
import { PrWorkflowChartStatusPoint } from "../../interfaces/charts";
import { WorkItemInterface } from "../../interfaces/work-items";
import { UserReportWithMetadataInterface } from "../../interfaces/user";
import { get, last } from "lodash";

// styled components
const Container = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  width: 40rem;
  display: flex;
`;
const ColWrapper = styled.dl`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const DefinitionWrapper = styled.div`
  align-items: baseline;
  display: flex;
  margin-top: 1rem;
`;
const DefinitionWrapperStacked = styled(DefinitionWrapper)`
  display: block !important;
`;
const Term = styled.dt`
  align-items: baseline;
  display: flex;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  line-height: 1em;

  &::after {
    content: ":";
    display: block;
    margin-right: 0.5em;
    margin-bottom: 0.5rem;
  }
`;
const Definition = styled.dd`
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  line-height: 1em;
  margin: 0;
  white-space: break-spaces;
  margin-bottom: 0.5rem;
`;
const Warning = styled(Icon)`
  height: 1em;
  line-height: 1em;
  margin-right: 0.5em;
`;
const CycleTime = styled(ChartCycleTime)`
  margin: 1rem 0;
`;
const Tickets = styled.ul`
  margin: 1rem 0;
`;
const Callout = styled.li`
  align-items: flex-start;
  display: flex;

  & + & {
    margin-top: 0.5rem;
  }
`;

// typescript props
type Props = {
  className?: string;
  point: PrWorkflowChartStatusPoint;
  testId?: string;
  workItems: Array<WorkItemInterface>;
  team: UserReportWithMetadataInterface[] | undefined;
};

const PrWorkflowChartStatusTooltip = ({
  className,
  point,
  testId = "testId",
  workItems,
  team
}: Props): ReactElement => {
  const thisTestId = `${testId}-pr-workflow-chart-status-tooltip`;
  const invertedWorkItem = workItems.find(w => w.id === point.workItem.id);
  const related = invertedWorkItem?.childWorkItems;
  const status = last(point.workItem.statusHistory)?.status || "";

  return (
    <Container className={className} data-testid={thisTestId}>
      <ColWrapper>
        <DefinitionWrapper>
          <Term>Status</Term>
          <Definition>{prActivityStatusLabels[status]}</Definition>
        </DefinitionWrapper>
        {!!point.workItem.callouts.length ? (
          <DefinitionWrapper>
            <Term className="visuallyHidden">Callouts</Term>
            <Definition>
              <ul>
                {point.workItem.callouts.map(c => (
                  <Callout key={c}>
                    <Warning icon="warning-outlined" testId={thisTestId} />
                    {calloutMetadata[c]}
                  </Callout>
                ))}
              </ul>
            </Definition>
          </DefinitionWrapper>
        ) : null}
        <DefinitionWrapperStacked>
          {!!point.workItem.owners.length && !!team ? (
            <>
              <Term>Authored by</Term>
              <TeamList
                team={team}
                userIds={point.workItem.owners.map(i => i.uplevelUserId)}
                rowCount={3}
              />
            </>
          ) : (
            <span>"There are no assigned contributors"</span>
          )}
        </DefinitionWrapperStacked>
        {!!point.workItem.cycleTime ? (
          <DefinitionWrapperStacked>
            <Term>Cycle time</Term>
            <Definition>
              <CycleTime
                cycleTime={point.workItem.cycleTime}
                testId={thisTestId}
              />
            </Definition>
          </DefinitionWrapperStacked>
        ) : null}
      </ColWrapper>
      <ColWrapper>
        {!!point.workItem.complexity ? (
          <DefinitionWrapper>
            <Term>Complexity</Term>
            <Definition>
              {get(prComplexityLevels, point.workItem.complexity)}
            </Definition>
          </DefinitionWrapper>
        ) : null}
        <ColWrapper>
          {!!point.workItem.contributors.length && !!team ? (
            <DefinitionWrapperStacked>
              <Term>Reviewers</Term>
              <TeamList
                team={team}
                userIds={point.workItem.contributors.map(i => i.uplevelUserId)}
                rowCount={3}
              />
            </DefinitionWrapperStacked>
          ) : null}
        </ColWrapper>
        {!!related?.length ? (
          <DefinitionWrapperStacked>
            <Term>Related Jira ticket(s)</Term>
            <Definition>
              <Tickets>
                {related.map(w => (
                  <li key={w.id}>
                    {w.displayId}: {w.name}
                  </li>
                ))}
              </Tickets>
            </Definition>
          </DefinitionWrapperStacked>
        ) : null}
      </ColWrapper>
    </Container>
  );
};

export default PrWorkflowChartStatusTooltip;
