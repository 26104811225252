import React from "react";

// components
import Icon from "../Icon/Icon";

// constants
import { workMetadataActivityTypes } from "../../constants/constants";

// typescript props
type Props = {
  className?: string;
  testId?: string;
  type: string;
};

const WorkMetadataIcon = ({ className, testId = "testId", type }: Props) => {
  const thisTestId = `${testId}-work-metadata-type-icon`;
  if (!Object.values(workMetadataActivityTypes).includes(type)) {
    return null;
  }

  return (
    <Icon
      icon={
        type === workMetadataActivityTypes.SUB_TASK
          ? "subtask"
          : type === workMetadataActivityTypes.PR
          ? "pr"
          : type.toLowerCase()
      }
      className={className}
      testId={thisTestId}
    />
  );
};

export default WorkMetadataIcon;
