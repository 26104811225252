import { useMemo } from "react";

// hooks
import { useSelector } from "react-redux";
import { useQuery, UseQueryResult } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";
import { OrgByTime } from "../interfaces/organization";

// utils
import { requestOrganizationReleaseFrequencyData } from "../utils/organization-data";

export const useOrganizationReleaseFrequencyData = (
  options: {
    endDate?: string;
    type: "year";
    userIds?: Array<number>;
  },
  canRequest = true
): UseQueryResult<OrgByTime> => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const params = useMemo(
    () => ({
      accessToken,
      endDate: options.endDate,
      tenantId,
      userIds: options.userIds
    }),
    [accessToken, options, tenantId]
  );

  return useQuery(
    ["organization-release-frequency", params],
    () => requestOrganizationReleaseFrequencyData(params),
    {
      enabled: !!accessToken && !!tenantId && canRequest,
      refetchOnWindowFocus: false
    }
  );
};
