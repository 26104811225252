import React from "react";
import styled from "styled-components/macro";
import { NIL } from "uuid";

// components
import WorkMetadataDetailsTeamList from "../WorkMetadataDetailsTeamList/WorkMetadataDetailsTeamList";
import WorkMetadataIssuesSummary from "../WorkMetadataIssuesSummary/WorkMetadataIssuesSummary";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { EpicDetailsInterface } from "../../interfaces/work-items";
import Icon from "../Icon/Icon";

// styled components
const Header = styled.header`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.h1`
  align-items: baseline;
  display: flex;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 2rem;
`;
const EpicId = styled.span`
  border-right: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  margin-right: 1rem;
  padding-right: 1rem;
`;
const TeamListRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 3rem;
`;
const Link = styled.a`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.medium};
  &,
  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
  }
`;
const LinkIcon = styled(Icon)`
  color: inherit;
  font-size: 1.1rem;
  margin-right: 0.5rem;
`;

// typescript props
type Props = {
  className?: string;
  data: EpicDetailsInterface;
  testId?: string;
};

const WorkMetadataDetailsSummary = ({
  className,
  data,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-work-metadata-details-summary`;
  const { trackEvent } = useTracking();

  function onClickEpicLink({
    e,
    url
  }: {
    e: React.MouseEvent<HTMLElement>;
    url: string;
  }) {
    e.persist();
    trackEvent({
      e,
      label: `${thisTestId}-view-in-jira-link-click`,
      value: `clicked, url: ${url}`
    });
  }

  const {
    activities,
    contributors,
    workCategoryId,
    workCategoryName,
    workCategoryUrl,
    owners
  } = data;

  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        <Title data-heap-redact-text="true">
          {!!workCategoryId && workCategoryId !== NIL ? (
            <EpicId>{workCategoryId}</EpicId>
          ) : null}
          {workCategoryName}
        </Title>
        {!!workCategoryUrl && (
          <Link
            data-testid={`${thisTestId}-view-in-jira-link`}
            href={workCategoryUrl}
            id={`${thisTestId}-view-in-jira-link`}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              onClickEpicLink({ e, url: workCategoryUrl });
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <LinkIcon icon="external-link" testId={thisTestId} />
            View in Jira
          </Link>
        )}
      </Header>
      <WorkMetadataIssuesSummary data={activities} testId={thisTestId} />
      <TeamListRow>
        <WorkMetadataDetailsTeamList
          header="Owners"
          list={owners}
          testId={`${thisTestId}-owners`}
        />
        <WorkMetadataDetailsTeamList
          header="Contributors"
          list={contributors}
          testId={`${thisTestId}-contributors`}
        />
      </TeamListRow>
    </div>
  );
};

export default WorkMetadataDetailsSummary;
