import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { isEmpty, debounce } from "lodash";

// components
import EpicFilterCheckboxList from "../EpicFilterCheckboxList/EpicFilterCheckboxList";
import InfoIcon from "../InfoIcon/InfoIcon";
import WorkMetadataIcon from "../WorkMetadataIcon/WorkMetadataIcon";

// constants
import {
  urlParamKeys,
  urlParamsValuesLabels,
  workMetadataActivityTypes
} from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";
import {
  EpicDetailsInterface,
  GroupsListInterface,
  ResponseTotalInterface
} from "../../interfaces/work-items";
import {
  UrlParamsInterface,
  UrlParamsHookReturnInterface
} from "../../interfaces/url-params";

// styled components
const WorkTypeIcon = styled(WorkMetadataIcon)`
  margin-right: 0.5rem;
`;
const Option = styled.span`
  align-items: center;
  display: flex;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  updateUrlParams: UrlParamsHookReturnInterface["updateUrlParams"];
  workItemTypes: UrlParamsInterface["workItemTypes"];
  workItemsData?: EpicDetailsInterface | GroupsListInterface;
};

const WorkItemTypeFilter = ({
  className,
  testId = "testId",
  updateUrlParams,
  workItemTypes,
  workItemsData
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-work-item-type-filter`;
  const { trackEvent } = useTracking();
  const [areFiltersDisabled, setAreFiltersDisabled] = useState(false);
  const workItemTypeTotals = workItemsData?.workItemTypeTotals;

  const shouldDisplayWorkItemType = (workItemType: string) =>
    workItemTypeTotals?.find(
      (t: ResponseTotalInterface) => t.workItem === workItemType
    );

  const optionsList = ([
    {
      helpTextKey: "issue",
      id: workMetadataActivityTypes.ISSUE
    },

    {
      helpTextKey: "sub-task",
      id: workMetadataActivityTypes.SUB_TASK
    },
    {
      helpTextKey: "bug",
      id: workMetadataActivityTypes.BUG
    },
    {
      helpTextKey: "pull-request",
      id: workMetadataActivityTypes.PR
    }
  ] as Array<{
    name: string;
    helpTextKey: "issue" | "sub-task" | "bug" | "pull-request";
    id: string;
  }>)
    .filter(m => shouldDisplayWorkItemType(m.id))
    .map(m => {
      return {
        id: m.id,
        isDisabled: areFiltersDisabled,
        isSelected: workItemTypes.includes(m.id),
        name: (
          <Option>
            <WorkTypeIcon type={m.id} testId={`${thisTestId}-${m.id}`} />
            {urlParamsValuesLabels[m.id]} (
            {workItemTypeTotals?.find(
              (t: ResponseTotalInterface) => t.workItem === m.id
            )?.total || 0}
            )
            <InfoIcon
              content={m.helpTextKey}
              testId={`${thisTestId}-${m.id}`}
            />
          </Option>
        )
      };
    });

  // fixing ts error with this:
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedToggle = useMemo(
    () =>
      debounce(updatedTypes => {
        updateUrlParams({
          [urlParamKeys.WORK_ITEM_TYPES]: updatedTypes
        });
        setAreFiltersDisabled(false);
      }, 500),
    [updateUrlParams]
  );

  function onToggleType(updated: Array<UiFilterSelectionStatusInterface>) {
    const updatedTypes = updated
      .filter((u: UiFilterSelectionStatusInterface) => u.isSelected)
      .map((s: UiFilterSelectionStatusInterface) => s.id);

    setAreFiltersDisabled(true);
    debouncedToggle(updatedTypes);
    trackEvent({
      label: "ui-filter-work-item-type-change",
      value: `changed work item type to ${(isEmpty(updatedTypes)
        ? Object.values(workMetadataActivityTypes)
        : updatedTypes
      ).join(",")}`
    });
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <EpicFilterCheckboxList
        header="Work Type"
        list={optionsList}
        name="work-item-type"
        onUpdateSelection={onToggleType}
        testId="work-item-type"
      />
    </div>
  );
};

export default WorkItemTypeFilter;
