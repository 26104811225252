import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components/macro";
import chevron from "../../images/chevron.svg";

// components
import Tooltip from "../Tooltip/Tooltip";

// interfaces
import { GroupInterface } from "../../interfaces/work-items";
import {
  reservedEpicsMetadata,
  reservedEpics
} from "../../constants/constants";

// styled components
const Container = styled.div`
  cursor: pointer;
`;
const ChevronContainer = styled.div`
  background-color: ${props => props.theme.colors.all.white};
`;
const MinHeightWrapper = styled.div`
  background-image: url(${chevron});
  background-position: 100% 50%;
  background-repeat: no-repeat;
  padding-right: 4rem;
  align-items: center;
  display: flex;
  height: 7rem;
  padding-left: 2rem;
`;
const EpicId = styled.h4`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin-bottom: 0;
  text-transform: uppercase;
`;
const EpicName = styled.h3`
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const StyledTooltip = styled(Tooltip)`
  line-height: 1;
`;
const PopupContent = styled.div`
  max-width: 20rem;
  word-wrap: anywhere;
  overflow-wrap: anywhere;
`;

// typescript props
type Props = {
  className?: string;
  metadata: GroupInterface;
  testId?: string;
};

const WorkMetadataEpic = ({
  className,
  metadata,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-work-metadata-epic`;

  // create an element ref to track the text content width for the title
  const textElementRef = useRef<HTMLHeadingElement>(null);

  // state to track whether a tooltip should appear on hover
  const [hoverStatus, setHover] = useState(false);

  // event to compare whether the text content is "overflowing" its container
  const compareSize = () => {
    const compare =
      textElementRef?.current && textElementRef?.current?.parentElement
        ? textElementRef.current?.scrollWidth >=
          textElementRef.current?.parentElement?.scrollWidth
        : false;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount" effect
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount" effect
  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  const { groupDisplayId, groupName, magicRow } = metadata;

  const displayGroupName = (
    <EpicName
      ref={textElementRef}
      data-testid={`${thisTestId}-display-group-name`}
    >
      {magicRow === reservedEpicsMetadata[reservedEpics.UNLINKED_PRS].magicRowId
        ? reservedEpicsMetadata[reservedEpics.UNLINKED_PRS].title
        : magicRow === reservedEpicsMetadata[reservedEpics.NO_EPIC].magicRowId
        ? reservedEpicsMetadata[reservedEpics.NO_EPIC].title
        : groupName}
    </EpicName>
  );

  return (
    <Container
      className={className}
      data-testid={thisTestId}
      data-heap-redact-text="true"
    >
      <ChevronContainer>
        <MinHeightWrapper>
          <div>
            {groupDisplayId && <EpicId>{groupDisplayId}</EpicId>}
            {hoverStatus ? (
              <StyledTooltip
                placement="top"
                popupContent={<PopupContent>{groupName}</PopupContent>}
                testId={thisTestId}
                trigger={displayGroupName}
              />
            ) : (
              displayGroupName
            )}
          </div>
        </MinHeightWrapper>
      </ChevronContainer>
    </Container>
  );
};

export default WorkMetadataEpic;
