import React from "react";
import styled, { css } from "styled-components/macro";
import { sortBy } from "lodash";

// components
import AvatarIconCreator from "../AvatarIconCreator/AvatarIconCreator";
import SprintsMetadataSelector from "../SprintsMetadataSelector/SprintsMetadataSelector";
import TeamCardTeamMembers from "../TeamCardTeamMembers/TeamCardTeamMembers";
import TeamMembersAdder from "../TeamMembersAdder/TeamMembersAdder";
import TextInput from "../TextInput/TextInput";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";

// interfaces
import { BoardInterface } from "../../interfaces/sprint-metadata";
import { TimerangeMetadataInterface } from "../../interfaces/constants";
import { ProjectInterface } from "../../interfaces/work-items";
import {
  NewUser,
  UserReportWithMetadataInterface
} from "../../interfaces/user";
import { SelectOptionsInterface } from "../../interfaces/controls";

// styled components
const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  font-size: 1.1rem;
  min-height: 0;
`;
const FieldWrapper = styled.div`
  align-items: center;
  display: flex;

  & + & {
    margin-top: 0.4rem;
  }
`;
const TeamMembersFieldWrapper = styled(FieldWrapper)`
  align-items: flex-start;
  flex-flow: column nowrap;
  min-height: 0;
`;
const Label = styled.label`
  align-items: center;
  display: flex;
  flex: 1;
  margin: 0;
`;
const Icon = styled(AvatarIconCreator)`
  font-size: 3rem;
  margin-right: 1rem;
`;
const TeamNameWrapper = styled.div`
  flex: 1;
`;
const NameInput = styled(TextInput)<{ hasTeamNameError: boolean }>`
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  flex: 1;
  padding: 0.8rem;

  ${props => {
    if (props.hasTeamNameError) {
      return css`
        border: ${props.theme.borders.widths.sm} solid
          ${props.theme.colors.all.cyclops};
      `;
    }
  }}
`;
const Sprints = styled(SprintsMetadataSelector)`
  flex: 1;
`;
const Adder = styled(TeamMembersAdder)<{ hasTeamMembersError: boolean }>`
  width: 100%;

  ${props => {
    if (props.hasTeamMembersError) {
      return css`
        border: ${props.theme.borders.widths.sm} solid
          ${props.theme.colors.all.cyclops};
      `;
    }
  }}
`;
const TeamMembers = styled(TeamCardTeamMembers)`
  overflow-y: scroll;
  width: 100%;

  &:not(:empty) {
    margin-top: 1.6rem;
  }
`;
const Error = styled.strong`
  color: ${props => props.theme.colors.all.cyclops};
  display: block;
  font-size: 1.1rem;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin-top: 0.4rem;
`;

// typescript props
type Props = {
  addedTeamMembers: Array<UserReportWithMetadataInterface | NewUser>;
  avatarColor: string | null;
  avatarIcon: string | null;
  board: BoardInterface | null;
  boards: Array<BoardInterface>;
  className?: string;
  fullOrganization: Array<UserReportWithMetadataInterface>;
  hasBoardError: boolean;
  hasProjectError: boolean;
  hasTeamMembersError: boolean;
  hasTeamNameError: boolean;
  onBlurTeamName: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChangeTeamName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCreateIcon: (avatarIcon: string, avatarColor: string) => void;
  onCreateTeamMember: (value: string) => void;
  onSelectBoard: (selected: SelectOptionsInterface) => void;
  onSelectProject: (selected: SelectOptionsInterface) => void;
  onToggleSprints: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdateTeamMembers: (selected: Array<SelectOptionsInterface>) => void;
  project: ProjectInterface | null;
  projects: Array<ProjectInterface>;
  shouldUseSprints: boolean;
  sprint: TimerangeMetadataInterface | null;
  teamMembers: Array<UserReportWithMetadataInterface>;
  teamName: string | null;
  testId?: string;
};

const NewTeamForm = ({
  addedTeamMembers,
  avatarColor,
  avatarIcon,
  className,
  board,
  boards,
  fullOrganization,
  hasBoardError,
  hasProjectError,
  hasTeamMembersError,
  hasTeamNameError,
  onBlurTeamName,
  onChangeTeamName,
  onCreateTeamMember,
  onSelectBoard,
  onSelectProject,
  onCreateIcon,
  onToggleSprints,
  onUpdateTeamMembers,
  project,
  projects,
  shouldUseSprints,
  sprint,
  teamMembers,
  teamName,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-new-team-form`;
  return (
    <Form className={className} data-testid={thisTestId}>
      <legend className="visuallyHidden">Create a new team</legend>
      <FieldWrapper>
        <Icon
          avatarColor={avatarColor}
          avatarIcon={avatarIcon}
          onCreateIcon={onCreateIcon}
          testId={thisTestId}
        />
        <TeamNameWrapper>
          <Label htmlFor="team-name-input">
            <span className="visuallyHidden">Team Name</span>
            <NameInput
              hasTeamNameError={hasTeamNameError}
              id="team-name-input"
              onBlur={onBlurTeamName}
              onChange={onChangeTeamName}
              placeholder="Team Name"
              testId={thisTestId}
              value={teamName || ""}
            />
          </Label>
          {hasTeamNameError ? (
            <TransitionWrapper>
              <Error>Please enter a Team Name</Error>
            </TransitionWrapper>
          ) : null}
        </TeamNameWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <Sprints
          board={board}
          boards={boards}
          hasBoardError={hasBoardError}
          hasProjectError={hasProjectError}
          isEditing={true}
          onSelectBoard={onSelectBoard}
          onSelectProject={onSelectProject}
          onToggleSprints={onToggleSprints}
          project={project}
          projects={projects}
          shouldUseSprints={shouldUseSprints}
          sprint={sprint}
          testId={thisTestId}
        />
      </FieldWrapper>
      <TeamMembersFieldWrapper>
        <Adder
          addablePeople={fullOrganization}
          hasTeamMembersError={hasTeamMembersError}
          onCreateTeamMember={onCreateTeamMember}
          onUpdateTeamMembers={onUpdateTeamMembers}
          teamMembers={addedTeamMembers}
          testId={thisTestId}
        />
        <TeamMembers
          teamMembers={sortBy([...addedTeamMembers, ...teamMembers], t =>
            t.name.toLowerCase()
          )}
          testId={thisTestId}
        />
        {hasTeamMembersError ? (
          <TransitionWrapper>
            <Error>Add members to create a Team</Error>
          </TransitionWrapper>
        ) : null}
      </TeamMembersFieldWrapper>
    </Form>
  );
};

export default NewTeamForm;
