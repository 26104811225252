import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { pick } from "lodash";

// components
import TeamCustomizationSettingsSwitches from "../TeamCustomizationSettingsSwitches/TeamCustomizationSettingsSwitches";

// interfaces
import { TeamCustomizationSettingsInterface } from "../../interfaces/user-settings";
import { FeatureTypes } from "../../interfaces/features";

// constants
import { customizationSettingsKeys } from "../../constants/constants";
const sourceCodeCustomizationSettingsKeys: Array<FeatureTypes> = [
  customizationSettingsKeys.PR_REPOS,
  customizationSettingsKeys.PRS_MERGED_NO_APPROVAL,
  customizationSettingsKeys.PRS_MERGED_NO_REVIEWERS,
  customizationSettingsKeys.STUCK_PRS
];

// styled components
const Header = styled.header`
  margin-bottom: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  onChangeSwitch: (optionKey: keyof TeamCustomizationSettingsInterface) => void;
  settings: Partial<TeamCustomizationSettingsInterface> | null;
  testId?: string;
};

const SourceCodeSettings = ({
  className,
  onChangeSwitch,
  settings,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-source-code-settings`;
  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        With <b>source code,</b> my team wants to see Insights on...
      </Header>
      <TeamCustomizationSettingsSwitches
        onChange={onChangeSwitch}
        options={sourceCodeCustomizationSettingsKeys}
        settings={pick(settings, sourceCodeCustomizationSettingsKeys)}
        testId="team-customizations-source-code-switches"
      />
    </div>
  );
};

export default SourceCodeSettings;
