export const storyPointsListTypes = [
  "WITH_STORY_POINTS",
  "WITHOUT_STORY_POINTS"
].reduce((result, key) => {
  result[key] = key.toLowerCase();
  return result;
}, {});

export const meetingClassificationTypes = [
  "OFFICE_HOURS",
  "MANAGER_IC_ONE_ON_ONES",
  "OTHER_MEETING_EVENTS",
  "OUT_OF_OFFICE",
  "PRODUCT_RELEASE_REVIEWS",
  "RECRUITING",
  "BLOCKED_FOR_WORK",
  "SCRUM",
  "SOCIAL_COMPANY_EVENTS",
  "TRAINING_SESSIONS",
  "WORKING_SESSIONS_PROJECT_MEETINGS",
  "NOT_A_MEETING"
].reduce((result, key) => {
  result[key] = key;
  return result;
}, {});

export const STRING_TRUNCATION_LENGTH = 30;
export const DEFAULT_NUM_WEEKS_DATA_RANGE = 12;
export const TEAM_AVERAGE_ID = "team-average";
export const MAX_NUM_AVATARS = 4;
