import React from "react";
import styled from "styled-components/macro";

// styled components
const StyledContainer = styled.div`
  font-family: ${props => props.theme.fonts.primary.name};
  text-align: center;
`;
const StyledMaxWidthContainer = styled.div`
  margin: 0 auto;
  max-width: 40rem;
`;
const StyledHeader = styled.header`
  font-family: ${props => props.theme.fonts.header.name};
  font-size: 10rem;
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
`;
const StyledBody = styled.div<{ hasHeader: boolean }>`
  font-size: ${props =>
    props.hasHeader
      ? props.theme.fonts.primary.sizes.sm
      : props.theme.fonts.primary.sizes.md};
  font-weight: ${props =>
    props.hasHeader
      ? props.theme.fonts.primary.weights.regular
      : props.theme.fonts.primary.weights.bold};
`;

// typescript props
type Props = {
  body: React.ReactNode;
  className?: string;
  header?: React.ReactNode;
  testId?: string;
};

const EmptyState = ({ body, className, header, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-empty-state`;
  if (!body && !header) {
    return null;
  }

  return (
    <StyledContainer className={className} data-testid={thisTestId}>
      <StyledMaxWidthContainer>
        {!!header && <StyledHeader>{header}</StyledHeader>}
        <StyledBody hasHeader={!!header}>{body}</StyledBody>
      </StyledMaxWidthContainer>
    </StyledContainer>
  );
};

export default EmptyState;
