import moment from "moment";
import { compact, isEmpty, isEqual, max } from "lodash";
import { momentUnitTypes } from "../../constants/constants";

// utils
import {
  getMeetingTimeTooltip,
  getAdjustedTooltipPosition
} from "../../utils/chart";
import { formatTimestamp } from "../../utils/date";

const config = ({
  data,
  onClickSeries,
  selectedDate,
  theme,
  selectedTeamMember,
  selectedUser,
  canViewTeam
}) => {
  const isViewingTeam = selectedUser === "team";
  const events = {
    select: function(event) {
      event.preventDefault();
      const { target } = event;
      onClickSeries(target.category);
    },
    unselect: function(event) {
      event.preventDefault();
      onClickSeries(null);
    }
  };

  const teamData = data.teamAverages.map((t, index) => ({
    y: t.meetingTime,
    x: index,
    selected: isEqual(selectedDate, t.date)
  }));

  const individualData = data.userValues.map((t, index) => ({
    y: t.meetingTime,
    x: index,
    selected: isEqual(selectedDate, t.date)
  }));

  const teamConfig = {
    allowPointSelect: true,
    cursor: "pointer",
    name: "Team Time in Meetings per day",
    type: isViewingTeam ? "line" : "column",
    point: {
      events
    },
    data: teamData,
    id: "team"
  };

  const individualConfig = {
    allowPointSelect: true,
    cursor: "pointer",
    name: `${selectedTeamMember?.name} Time in Meetings per day`,
    type: "line",
    point: {
      events
    },
    data: individualData,
    id: "team-member"
  };

  return {
    chart: {
      style: {
        fontFamily: theme.fonts.primary.name,
        fontSize: 15
      }
    },
    title: {
      text: ""
    },
    xAxis: [
      {
        categories: data.userValues.map(u => u.date),
        tickmarkPlacement: "on",
        lineColor: theme.colors.all.wolverine,
        lineWidth: 2,
        labels: {
          y: 24,
          formatter: function() {
            // the axis should display Monday-Friday ranges, instead of Sunday-Saturday as reflected by
            // the actual data, which has the weekend meetings already stripped out.
            return `${formatTimestamp({
              format: "M/D",
              timestamp: this.value
            })}`;
          },
          style: {
            fontFamily: `${theme.fonts.subheader.name},monospace`,
            fontWeight: theme.fonts.subheader.weights.bold
          }
        }
      }
    ],
    yAxis: [
      {
        gridLineWidth: 2,
        min: 0,
        max: 9,
        title: {
          enabled: false
        },
        tickInterval: 2,
        labels: {
          format: `{value} hrs`,
          style: {
            fontFamily: `${theme.fonts.subheader.name},monospace`,
            fontWeight: theme.fonts.subheader.weights.bold
          }
        }
      }
    ],
    legend: {
      align: "right",
      enabled: true,
      itemDistance: 30,
      itemStyle: {
        fontWeight: theme.fonts.primary.weights.book
      },
      layout: "horizontal",
      verticalAlign: "bottom",
      symbolHeight: 8,
      symbolWidth: 8,
      symbolPadding: 8,
      // a bit odd, but radius here implicitly applies to just column type series and not line series
      symbolRadius: 0
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      backgroundColor: null,
      shadow: false,
      borderWidth: 0,
      borderRadius: 0,
      outside: true,
      positioner: function(w, h, point) {
        return getAdjustedTooltipPosition({
          currentPosition: this.getPosition(w, h, point),
          windowScroll: window.scrollY
        });
      },
      formatter: function() {
        return getMeetingTimeTooltip(this.point, selectedTeamMember);
      }
    },
    plotOptions: {
      line: {
        connectNulls: true,
        lineColor: theme.colors.all.nightcrawler,
        marker: {
          enabled: true,
          symbol: "circle",
          fillColor: theme.colors.all.nightcrawler,
          lineColor: theme.colors.all.nightcrawler,
          states: {
            select: {
              fillColor: theme.colors.all.nightcrawler,
              lineColor: theme.colors.all.nightcrawler
            }
          }
        }
      },
      column: {
        borderColor: theme.colors.all.storm,
        maxPointWidth: 20,
        color: theme.colors.all.storm,
        states: {
          select: {
            color: theme.colors.all.storm,
            borderColor: theme.colors.all.storm
          }
        }
      }
    },
    series: isViewingTeam
      ? [teamConfig]
      : canViewTeam
      ? [teamConfig, individualConfig]
      : [individualConfig]
  };
};
export default config;
