import { isNil } from "lodash";
import { currentTheme } from "../themes/currentTheme";

/**
 * translate hex code to rgb(a) tuples for css
 * https://css-tricks.com/converting-color-spaces-in-javascript/
 *
 * @param {Object} parameters - hexcode parameters
 * @param {String} hex - hex code
 * @param {Number} opacity - desired opacity for rgba value
 *
 */
export const hexToRgb = ({ hex, opacity }) => {
  let r = 0,
    g = 0,
    b = 0;

  r = "0x" + hex[1] + hex[2];
  g = "0x" + hex[3] + hex[4];
  b = "0x" + hex[5] + hex[6];

  if (!isNil(opacity)) {
    return `rgba(${+r}, ${+g}, ${+b}, ${opacity})`;
  }
  return `rgb(${+r}, ${+g}, ${+b})`;
};

export const bgToFgColor = bg =>
  currentTheme.colors.reversed.includes(bg)
    ? currentTheme.colors.all.white
    : currentTheme.colors.all.wolverine;

export const getColorForDelta = (delta, isInverted) => {
  if (!delta) {
    return currentTheme.colors.all.storm;
  }
  if (delta < 0) {
    return isInverted
      ? currentTheme.colors.all.petra
      : currentTheme.colors.all.cyclops;
  }
  return isInverted
    ? currentTheme.colors.all.cyclops
    : currentTheme.colors.all.petra;
};
