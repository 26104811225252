import crypto from "crypto";
import { partition, toPairs, kebabCase, set } from "lodash";

// utils
import api from "./api";
import { workMetadataActivityTypes } from "../constants/constants";

/**
 * create encrypted user key
 * @param {String} email
 */
export const createUserKey = email =>
  crypto
    .createHash("sha256")
    .update(email.toLowerCase())
    .digest("hex");

export const createFlagUserMetadata = user => ({
  tenant: kebabCase(user.tenantAlias.toLowerCase()),
  enabled_roles: user.enabled_roles || []
});

export const createAnalyticsUserMetadata = user => {
  let metadata = {
    tenant: kebabCase(user.tenantAlias.toLowerCase())
  };
  (user.enabled_roles || []).forEach(role => set(metadata, role, true));
  return metadata;
};

/**
 * request authenticated user from level9
 * @param {Object} params
 */
export const requestUser = async ({ accessToken, tenantId } = {}) => {
  const response = await api.get(`/${tenantId}/user`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return response.data;
};

/**
 * request user's team
 * @param {Object} params
 */
// todo: remove this method once the dashboard container is removed
export const requestUserInfo = async ({ accessToken, tenantId } = {}) => {
  const response = await api.get(`/${tenantId}/user`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const requestUserTileData = async ({
  accessToken,
  tenantId,
  params
}) => {
  const response = await api.post(`/${tenantId}/user-tiles`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const requestUserAllocationsData = async ({
  accessToken,
  tenantId,
  params
}) => {
  const response = await api.post(`/${tenantId}/user-allocations`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const getEmailsForUsers = users => users.map(u => u.email);

export const partitionSignals = signals => {
  return partition(
    toPairs(signals)
      .filter(signal => signal[0] !== workMetadataActivityTypes.PR)
      .map(signal => {
        return {
          key: signal[0],
          value: signal[1]
        };
      }),
    signalGroup =>
      // signalGroup.key === workMetadataActivityTypes.PR ||
      // FIXME: TS complaining about using the constants declared on alwaysOnRiskTypes, figure out why
      (signalGroup.key === "DEEP_WORK" &&
        signalGroup.value[0].status === "LOW") ||
      (signalGroup.key !== "DEEP_WORK" &&
        signalGroup.value[0].status === "HIGH") ||
      signalGroup.value[0].status === "MORE" ||
      signalGroup.value[0].status === "ABOVE_NORMAL"
  );
};

export const requestOrgChart = async ({ accessToken, tenantId, params }) => {
  const response = await api.post(`/${tenantId}/org-chart/v2`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const requestFullOrganization = async ({ accessToken, tenantId }) => {
  const response = await api.get(`/${tenantId}/users/by-tenant-id`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return response.data;
};
