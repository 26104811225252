import { isEmpty } from "lodash";
import { useMemo } from "react";

// hooks
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { requestTeams } from "../utils/teams";

type KeyProps = {
  type: "own-teams" | "active-users-teams";
  teamMembersToRequest: Array<{ email: string }>;
};
export const useTeamsData = (options: KeyProps, canRequest = true) => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const teamMembersToRequest = options.teamMembersToRequest;
  const teamMemberEmails = useMemo(
    () =>
      isEmpty(teamMembersToRequest) ||
      !teamMembersToRequest.every(t => !!t.email)
        ? []
        : teamMembersToRequest.map(t => t.email),
    [teamMembersToRequest]
  );
  const params = useMemo(
    () => ({
      accessToken,
      tenantId,
      params: {
        users: teamMemberEmails as Array<string>
      }
    }),
    [accessToken, teamMemberEmails, tenantId]
  );

  return useQuery(
    ["teams", { ...params, type: options.type }],
    () => requestTeams(params),
    {
      staleTime: Infinity,
      enabled:
        !!accessToken && !!tenantId && !!teamMemberEmails.length && canRequest
    }
  );
};
