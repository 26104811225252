import React, { ReactElement, useEffect } from "react";
import styled from "styled-components/macro";

// components
import SupportEmailLink from "../SupportEmailLink/SupportEmailLink";

// hooks
import { useTracking } from "../../hooks/useTracking";

// styled components
const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
`;
const ContentWrapper = styled.div`
  max-width: 25rem;
`;
const Header = styled.h1`
  font-size: 8rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  margin: 0;
`;
const Body = styled.p`
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  margin: 0;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const UserWithNoUplevelAccountPage = ({
  className,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-user-with-no-account-page`;
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent({
      e: {
        type: "page-load"
      },
      label: "user-with-no-uplevel-account-page-load",
      value: `loaded`
    });
  }, []);

  return (
    <Container className={className} data-testid={thisTestId}>
      <ContentWrapper>
        <Header>Hello!</Header>
        <Body>
          It looks like you don&rsquo;t have an Uplevel account. Please contact
          support to get an account.{" "}
        </Body>
        <SupportEmailLink name="user-with-no-account" testId={thisTestId} />
      </ContentWrapper>
    </Container>
  );
};

export default UserWithNoUplevelAccountPage;
