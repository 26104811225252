import { UserInterface } from "../interfaces/user";
import * as LDClient from "launchdarkly-js-client-sdk";
import {
  teamDeepDiveSections,
  groupByTypes,
  workDeepDiveSections,
  Signals
} from "../constants/constants";
import {
  TeamDeepDiveParams,
  WorkDeepDiveParams,
  UrlParamsInterface
} from "../interfaces/url-params";

type Props = {
  user: UserInterface;
  flags: LDClient.LDFlagSet;
  teamDeepDiveParams?: TeamDeepDiveParams;
  workDeepDiveParams?: WorkDeepDiveParams;
  urlParams: UrlParamsInterface;
};
export const getSelectableTeamMembers = ({
  user,
  flags,
  teamDeepDiveParams,
  workDeepDiveParams,
  urlParams
}: Props): Array<number> => {
  if (
    (workDeepDiveParams?.selectedSection ===
      workDeepDiveSections.PROJECT_WORK &&
      urlParams?.groupBy === groupByTypes.USER) ||
    workDeepDiveParams?.selectedSection === workDeepDiveSections.OPS_METRICS
  )
    return [];

  if (
    teamDeepDiveParams?.selectedSection ===
      teamDeepDiveSections.PEOPLE_HEALTH &&
    teamDeepDiveParams.selectedMetric === "OVERALL_TEAM_HEALTH"
  )
    return [];

  return (
    (((teamDeepDiveParams?.selectedSection ===
      teamDeepDiveSections.HEALTH_METRICS &&
      teamDeepDiveParams?.selectedMetric === Signals.ALWAYS_ON) ||
      teamDeepDiveParams?.selectedSection ===
        teamDeepDiveSections.MEETING_INSIGHTS) &&
    // TODO: confirm this is the flag result for the roles set and create flag for more generic use
    !flags?.["transparent-data-for-peers"]
      ? urlParams?.team?.teamMembers
          .filter(u => u.id === user.id)
          .map(u => u.id)
      : urlParams?.team?.teamMembers.map(u => u.id)) || []
  );
};
