import { actionTypes } from "../constants/actionTypes";

/**
 * reducer for chart related state actions
 */
export default (state = null, action = {}) => {
  switch (action.type) {
    case actionTypes.STORE_FLAGS:
      return state
        ? {
            ...state,
            ...action.payload.flags
          }
        : action.payload.flags;
    default:
      return state;
  }
};
