import React, { useMemo } from "react";
import styled from "styled-components/macro";

// components
import Table from "../Table/Table";

// interfaces
import {
  TableRowInterface,
  WorkItemLeveledInterface
} from "../../interfaces/work-items";
import { ColumnInterface } from "react-table";

// styled components
const IssuesTable = styled(Table)`
  width: 100%;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};

  & thead {
    border-bottom: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  }

  & th {
    font-size: 1.1rem;
    font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
    padding: 0 0.8rem 0.8rem 0;
    text-align: left;
    vertical-align: bottom;
  }

  & tbody {
    & tr + tr {
      border-top: ${props =>
        `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    }
  }

  & td {
    font-size: ${props => props.theme.fonts.primary.sizes.xs};
    font-weight: ${props => props.theme.fonts.primary.weights.regular};
    padding: 0.8rem;
    text-align: left;
    vertical-align: middle;
  }

  & td + td {
    border-left: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: inherit;
  }
`;

// typescript props
type Props = {
  allowSorting?: boolean;
  className?: string;
  columns: Array<ColumnInterface>;
  getRowProps?: (row: TableRowInterface) => any;
  hiddenColumns?: Array<string>;
  list?: WorkItemLeveledInterface[];
  manuallySort?: boolean;
  sortBy?: Array<{ id: string; desc: boolean }>;
  testId?: string;
};

const WorkMetadataIssuesList = ({
  allowSorting = true,
  className,
  columns,
  hiddenColumns = [],
  getRowProps,
  list,
  manuallySort = false,
  sortBy = [],
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-work-metadata-issues-list`;
  const initialState = useMemo(
    () => ({
      hiddenColumns,
      sortBy
    }),
    [hiddenColumns, sortBy]
  );
  return !!list?.length ? (
    <IssuesTable
      className={className}
      columns={columns}
      data-testid={thisTestId}
      data={list}
      testId={thisTestId}
      // react-table specific props below
      allowSorting={allowSorting}
      getRowProps={getRowProps}
      initialState={initialState}
      manuallySort={manuallySort}
    />
  ) : null;
};

export default WorkMetadataIssuesList;
