import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { get } from "lodash";

// components
import { Drawer } from "@material-ui/core";
import HomeLinkWithDropDown from "../HomeLinkWithDropdown/HomeLinkWithDropDown";
import Icon from "../Icon/Icon";
import RouteLink from "../RouteLink/RouteLink";
import SidebarLinks from "../SidebarLinks/SidebarLinks";
import SlackAvatar from "../SlackAvatar/SlackAvatar";
import UplevelLogoWords from "../../images/Horz_Color-Rev_Lg.png";

// constants
import { pageIds, pageMetadata } from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";
import { useHistory, useLocation } from "react-router-dom";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import {
  UserInterface,
  UserReportWithMetadataInterface
} from "../../interfaces/user";

// utils
import { parsePageIdFromPathname } from "../../utils/router";

const StyledDrawer = styled(Drawer)`
  z-index: 1;

  & .MuiDrawer-paper {
    background-color: ${props => props.theme.colors.all.wolverine};
    border: none;
    color: white;
    padding: 0.8rem 0 0;
    width: ${props => props.theme.grid.sidebar};
  }
`;

const GlobalStyle = createGlobalStyle`
  .teamPopup {
    font-family: ${props =>
      props.theme.fonts.primary.name}, Arial, Helvetica, sans-serif;
    font-size: 1.1rem;
    padding: .25rem 0;
  }
  .teamMember {
    line-height: 1.2;
  }
`;

const Header = styled.header`
  padding: 0 1.2rem 0 0.8rem;
`;

const SidebarLinksWrapper = styled.nav`
  flex: 1;
`;

const BorderWrapper = styled.div`
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.storm}`};
  border-top: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.storm}`};
  display: flex;
  flex-flow: column nowrap;
`;

const UserButton = styled.button<{ isTeamLead: boolean }>`
  align-items: center;
  background: 0;
  border: 0;
  display: flex;
  flex-direction: row;
  padding-bottom: 1.6rem;
  padding-left: ${props => (props.isTeamLead ? "0.8rem" : "0")};
  padding-right: 0;
  padding-top: 1.6rem;
  text-align: left;
`;

const StyledSlackAvatar = styled(SlackAvatar)`
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: ${props => `.2rem solid ${props.theme.colors.all.white}`};
`;

const Name = styled.h2`
  color: ${props => props.theme.colors.all.white};
  flex: 1;
  font-weight: ${props => props.theme.fonts.primary.weights.medium};
  font-size: 1.2rem;
  margin-left: 0.75rem;
`;

const Footer = styled.footer`
  padding: 0 0 1rem 0;
`;
const ResourceLinksList = styled.ul`
  margin: 0 2rem 5rem 0.8rem;
`;
const ResourceLinksListItem = styled.li`
  & + & {
    margin-top: 1.5rem;
  }
`;
const ResourceLink = styled.a`
  display: block;

  &:hover {
    text-decoration: none;
  }
`;
const ResourceLinkHeader = styled.h3`
  align-items: baseline;
  display: flex;
  color: ${props => props.theme.colors.all.white};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;
const ResourceLinkIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.marvelMint};
  margin-left: 0.8rem;
`;
const ResourceLinkText = styled.p`
  color: ${props => props.theme.colors.all.white};
  font-size: 1.2rem;
`;

const UplevelLogo = styled("img")`
  width: 140px;
  height: auto;
`;

const PrivacyLink = styled.a`
  color: ${props => props.theme.colors.all.storm};
  display: block;
  font-size: 1rem;
  margin-left: 1.2rem;

  &:hover {
    color: ${props => props.theme.colors.link.hover.color};
    background: ${props => props.theme.colors.link.hover.background};
    text-decoration: none;
  }
`;

const SettingsLink = styled(RouteLink)`
  display: block;
`;
const CogIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.white};
  padding-left: 1.2rem;
`;

export const Sidebar: React.FunctionComponent = () => {
  const thisTestId = "sidebar";
  const user = useSelector((state: AppStateInterface) => get(state, "user"));
  const flags = useSelector((state: AppStateInterface) => get(state, "flags"));
  const { pathname } = useLocation();
  const currentPageId = parsePageIdFromPathname(pathname);
  const isOverviewPage = currentPageId === pageIds.OVERVIEW;
  const { getStartPath, urlParams } = useUrlParams();
  const { teamLead } = urlParams;
  const isZoomedIn = !!teamLead;
  const { trackEvent } = useTracking();
  const history = useHistory();

  function onClickResourceLink(e: React.MouseEvent<HTMLElement>) {
    trackEvent({
      e,
      label: (e.currentTarget as HTMLElement).id,
      value: "link clicked"
    });
  }

  function onClickUser({
    e,
    resetState,
    user
  }: {
    e: React.MouseEvent<HTMLButtonElement>;
    resetState: boolean;
    user: UserReportWithMetadataInterface | UserInterface | null;
  }) {
    if (!!user) {
      history.push(
        getStartPath({
          persistQuerystring: !resetState
        })
      );

      e.persist();
      e.preventDefault();
      trackEvent({
        e,
        label: "sidebar-user-click",
        value: `clicked ${user.name}`
      });
    }
  }

  const { slackAvatar, name } = user;

  return (
    <StyledDrawer variant="permanent" anchor="left">
      <GlobalStyle />
      <Header>
        <HomeLinkWithDropDown
          currentPageId={currentPageId}
          user={user}
          activeTeamLead={teamLead}
        />
        <BorderWrapper>
          <UserButton
            onClick={e =>
              onClickUser({
                e,
                user,
                resetState: isOverviewPage || !!teamLead
              })
            }
            isTeamLead={false}
            data-testid={`${thisTestId}-user`}
          >
            <StyledSlackAvatar
              src={slackAvatar}
              altText={`${name} image`}
              testId={`${name} image`}
            />
            <Name>{name}</Name>
          </UserButton>
        </BorderWrapper>
        {!!teamLead ? (
          <BorderWrapper>
            <UserButton
              onClick={e =>
                onClickUser({ e, user: teamLead, resetState: isOverviewPage })
              }
              isTeamLead={true}
              data-testid={`${thisTestId}-team-lead`}
            >
              <StyledSlackAvatar
                src={teamLead.slackAvatar}
                altText={`${teamLead.name} image`}
                testId={`${teamLead.name} image`}
              />
              <Name>{teamLead.name}</Name>
            </UserButton>
          </BorderWrapper>
        ) : null}
      </Header>
      <SidebarLinksWrapper>
        <SidebarLinks
          currentPageId={currentPageId}
          pages={[
            pageIds.OVERVIEW,
            pageIds.TEAM_HOME,
            pageIds.TEAM_DEEP_DIVE,
            pageIds.WORK_DEEP_DIVE,
            pageIds.RETROS,
            pageIds.REPORTS,
            pageIds.LABS
          ]}
          pathname={pathname}
          isZoomedIn={isZoomedIn}
        />
      </SidebarLinksWrapper>
      <Footer>
        <ResourceLinksList aria-label="Resource Links">
          <ResourceLinksListItem>
            <ResourceLink
              href="https://uplevelteam.notion.site/Teams-Dashboards-1da8618809b54268bae5ae95bea406d3"
              id="sidebar-resource-advice-link"
              onClick={onClickResourceLink}
              rel="noopener noreferrer"
              target="_blank"
              data-testid="sidebar-resource-advice-link"
            >
              <ResourceLinkHeader>
                Uplevel Resources
                <ResourceLinkIcon
                  icon="external-link"
                  testId="sidebar-resource-advice-link"
                />
              </ResourceLinkHeader>
              <ResourceLinkText>
                Get the most out of the product with advice from our team.
              </ResourceLinkText>
            </ResourceLink>
          </ResourceLinksListItem>
        </ResourceLinksList>

        {flags?.["settings-page"] ? (
          <SettingsLink
            name="sidebar-settings-click"
            to={`/settings`}
            testId="sidebar-settings-link"
          >
            <CogIcon icon="cog" />
            <span className="visuallyHidden">
              {pageMetadata[pageIds.SETTINGS].title}
            </span>
          </SettingsLink>
        ) : null}

        <RouteLink
          name="sidebar-logo-click"
          to={getStartPath({
            persistQuerystring: currentPageId !== pageIds.OVERVIEW
          })}
          testId="sidebar-logo-link"
        >
          <UplevelLogo src={UplevelLogoWords} />
        </RouteLink>
        <PrivacyLink
          href="http://d1gs0u4wfcjnt0.cloudfront.net/Uplevel%20Privacy%20Policy.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          © {new Date().getFullYear()} | Privacy Policy
        </PrivacyLink>
      </Footer>
    </StyledDrawer>
  );
};

export default Sidebar;
