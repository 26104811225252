import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { isNull, sample } from "lodash";
import { currentTheme } from "../../themes/currentTheme";

// components
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import { TEAM_ICONS } from "../Icon/Icon";

// hooks
import { useTracking } from "../../hooks/useTracking";

// utils
const createIcon = () => sample(Object.keys(TEAM_ICONS)) as string;
const createColor = () => sample(currentTheme.colors.avatarIcons) as string;

// styled components
const Container = styled.button`
  border: 0;
  background: none;
  padding: 0;
  position: relative;
`;
const DisplayIcon = styled(AvatarIcon)`
  font-size: 1em;
`;
const ShuffleIcon = styled(AvatarIcon)`
  border-width: 0.1rem;
  bottom: 0;
  right: -0.5rem;
  font-size: 0.5em;
  position: absolute;
`;

// typescript props
type Props = {
  avatarColor: string | null;
  avatarIcon: string | null;
  className?: string;
  onCreateIcon: (avatarIcon: string, avatarColor: string) => void;
  testId?: string;
};

const AvatarIconCreator = ({
  avatarColor,
  avatarIcon,
  className,
  onCreateIcon,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-avatar-icon-creator`;
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (isNull(avatarColor) && isNull(avatarIcon)) {
      onCreateIcon(createIcon(), createColor());
    }
  }, [avatarColor, avatarIcon]);

  function onClickCreator(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.persist();
    e.preventDefault();
    const updatedIcon = createIcon();
    const updatedColor = createColor();
    onCreateIcon(updatedIcon, updatedColor);

    trackEvent({
      e,
      label: "avatar-icon-creator",
      value: `created ${updatedColor} ${updatedIcon} icon`
    });
  }

  return isNull(avatarColor) && isNull(avatarIcon) ? null : (
    <Container
      className={className}
      data-testid={thisTestId}
      onClick={onClickCreator}
    >
      <DisplayIcon
        avatarColor={avatarColor as string}
        avatarIcon={avatarIcon as string}
        testId={thisTestId}
      />
      <ShuffleIcon
        avatarColor={currentTheme.colors.all.white}
        avatarIcon="shuffle"
        testId={thisTestId}
      />
    </Container>
  );
};

export default AvatarIconCreator;
