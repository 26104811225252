import React from "react";
import styled from "styled-components/macro";

// components
import SelectableInputsList from "../SelectableInputsList/SelectableInputsList";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { RadioCheckboxToggleHandlerInterface } from "../../interfaces/controls";
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";

// styled components
const Container = styled.div`
  background: ${props => props.theme.colors.all.lightJean};
`;
const Header = styled.h4`
  align-items: center;
  display: flex;
  font-size: inherit;
  font-weight: ${props => props.theme.fonts.primary.weights.book};
`;

const CheckboxWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
  padding: 2rem;
`;
const Checkboxes = styled(SelectableInputsList)`
  font-size: ${props => props.theme.fonts.header.sizes.xs};
  line-height: 1.8;
`;

// typescript props
type Props = {
  className?: string;
  header: React.ReactNode;
  list: Array<UiFilterSelectionStatusInterface>;
  onUpdateSelection: (updated: Array<UiFilterSelectionStatusInterface>) => void;
  name: string;
  testId?: string;
};

const EpicFilterCheckboxList = ({
  className,
  header,
  list,
  name,
  onUpdateSelection,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-epic-filter-checkbox-list`;
  const { trackEvent } = useTracking();

  function onToggleSelection({
    e,
    option
  }: RadioCheckboxToggleHandlerInterface) {
    trackEvent({
      e,
      label: `${name}-epic-filter`,
      value: `${option.label} toggled ${e.target.checked ? "on" : "off"}`
    });
    onUpdateSelection(
      list.map(l => {
        return {
          ...l,
          isSelected: option.value === l.id ? e.target.checked : l.isSelected
        };
      })
    );
  }

  const listOptions = list.map(l => {
    return {
      disabled: !!l.isDisabled,
      label: l.name,
      selected: l.isSelected,
      value: l.id
    };
  });

  return (
    <Container className={className} data-testid={thisTestId}>
      <Header>{header}</Header>
      <CheckboxWrapper>
        <Checkboxes
          name={name}
          options={listOptions}
          onToggleListItem={onToggleSelection}
          testId={thisTestId}
        />
      </CheckboxWrapper>
    </Container>
  );
};

export default EpicFilterCheckboxList;
