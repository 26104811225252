import React, { useState } from "react";
import styled from "styled-components/macro";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";

// components
import { DateRangePicker } from "react-date-range";
import TimeRangeSelectorLabel from "../TimeRangeSelectorLabel/TimeRangeSelectorLabel";
import TimeRangeSelectorSprintLabel from "../TimeRangeSelectorSprintLabel/TimeRangeSelectorSprintLabel";
import UiFilterPopupWrapper from "../UiFilterPopupWrapper/UiFilterPopupWrapper";

// constants
import {
  momentUnitTypes,
  pageIds,
  timerangeTypes,
  timerangeTypesMetadata
} from "../../constants/constants";

// hooks
import { useParams } from "react-router-dom";

// interfaces
import { TimerangeMetadataInterface } from "../../interfaces/constants";
import { getTimestampForDate } from "../../utils/date";

// styled components
const Container = styled.div`
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: inline-block;
  position: relative;
`;
const ReactDateRangePicker = styled(DateRangePicker)`
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  z-index: 1;

  // internal styles
  & .rdrInputRanges {
    display: none;
  }

  & .rdrCalendarWrapper,
  & .rdrDefinedRangesWrapper {
    font-size: inherit;
  }

  & .rdrDefinedRangesWrapper {
    border-right: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    color: ${props => props.theme.colors.all.wolverine};
    height: 52rem;
    overflow-y: auto;

    & button {
      color: inherit !important;
    }
  }

  & .rdrStaticRangeLabel {
    transition: 0.2s all;
    padding: 1rem 0.5rem 1rem 1rem;
  }

  & .rdrStaticRange {
    border-bottom: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};

    &:hover {
      & .rdrStaticRangeLabel {
        background: ${props => props.theme.colors.all.marvelMint};
      }
    }
  }

  & .rdrStaticRangeSelected {
    background: ${props => props.theme.colors.all.marvelMint};
    font-weight: ${props => props.theme.fonts.subheader.weights.black};
  }

  & .rdrDateDisplayWrapper {
    background: ${props => props.theme.colors.all.jean};
    border-bottom: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  }

  & .rdrDateDisplay {
    margin-right: 5rem;
  }

  & .rdrDateDisplayItem {
    border: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    border-radius: 1.5em;
    box-shadow: none;
    color: ${props => props.theme.colors.all.wolverine};
    font-size: ${props => props.theme.fonts.primary.sizes.xs};

    & input {
      color: inherit;
    }
  }

  & .rdrMonthAndYearWrapper {
    font-size: ${props => props.theme.fonts.primary.sizes.xs};
  }

  & .rdrMonthAndYearPickers select {
    &:hover {
      background-color: none !important;
    }
  }

  & .rdrMonthName {
    color: ${props => props.theme.colors.all.wolverine};
    font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
    font-size: ${props => props.theme.fonts.primary.sizes.xs};
  }

  & .rdrWeekDays {
    color: ${props => props.theme.colors.all.storm};
  }

  & .rdrWeekDay {
    color: inherit;
  }

  & .rdrSelected,
  & .rdrInRange,
  & .rdrStartEdge,
  & .rdrEndEdge {
    background: ${props => props.theme.colors.all.marvelMint};
  }

  & .rdrDay {
    &.rdrDayPassive {
      & span {
        color: ${props => props.theme.colors.all.jean} !important;
      }
    }
    &.rdrDayDisabled {
      background: ${props => props.theme.colors.all.white} !important;
      & span {
        color: ${props => props.theme.colors.all.jean} !important;
      }
    }
  }

  & .rdrDayToday {
    & .rdrDayNumber span {
      font-weight: ${props => props.theme.fonts.primary.weights.black};
      color: ${props => props.theme.colors.all.wolverine} !important;
    }
  }

  & .rdrDayNumber {
    font-weight: ${props => props.theme.fonts.primary.weights.regular};
    & span {
      color: ${props => props.theme.colors.all.wolverine} !important;
    }
    & span::after {
      background: ${props => props.theme.colors.all.wolverine} !important;
    }
  }

  & .rdrNextPrevButton {
    &,
    &:hover {
      background: none;
    }

    &.rdrPprevButton i {
      border-width: 8px 10px 8px 8px;
      border-color: transparent ${props => props.theme.colors.all.auroraTeal}
        transparent transparent;
    }
    &.rdrNextButton i {
      border-width: 8px 8px 8px 10px;
      border-color: transparent transparent transparent
        ${props => props.theme.colors.all.auroraTeal};
    }
  }
`;

// typescript props
type Props = {
  className?: string;
  onCancel: () => void;
  onSubmit: (selection: TimerangeMetadataInterface) => void;
  selectionTimeranges: Array<TimerangeMetadataInterface>;
  testId?: string;
  timerange: TimerangeMetadataInterface;
};

type ReactDateRangeInterface = {
  id: string;
  endDate: Date;
  startDate: Date;
  key: string;
};

const TimeRangeSelector = ({
  className,
  onCancel,
  onSubmit,
  selectionTimeranges,
  testId = "testId",
  timerange
}: Props) => {
  const thisTestId = `${testId}-time-range-selector`;
  const { pageId } = useParams<{ pageId: string }>();
  const [currentSelection, setCurrentSelection] = useState<
    ReactDateRangeInterface
  >({
    id: timerange.id,
    endDate: moment(timerange.end, moment.ISO_8601).toDate(),
    startDate: moment(timerange.start, moment.ISO_8601).toDate(),
    key: "selection"
  });

  function onClickSubmit({
    allTimeranges,
    selection
  }: {
    allTimeranges: Array<TimerangeMetadataInterface>;
    selection: ReactDateRangeInterface;
  }) {
    const matchedRange = allTimeranges.find(
      (t: TimerangeMetadataInterface) => t.id === selection.id
    );

    // if this is a named timerange and  the timerange hasn't been customized
    // by adjusting via the calendar picker
    if (
      matchedRange?.end === selection.endDate.toISOString() &&
      matchedRange?.start === selection.startDate.toISOString()
    ) {
      onSubmit(matchedRange);
    } else {
      onSubmit({
        // use the 'custom' metadata type since the selection either doesn't
        // exactly match a named date range anymore due to changed start/end dates
        // or a matching date range couldn't be found from the passed date ranges
        ...timerangeTypesMetadata[timerangeTypes.CUSTOM],
        // add timestamped start and end attributes
        start: getTimestampForDate(
          moment(selection.startDate).startOf(momentUnitTypes.DAYS)
        ),
        end: getTimestampForDate(
          moment(selection.endDate).endOf(momentUnitTypes.DAYS)
        )
      });
    }
  }

  return (
    <Container className={className} data-testid={thisTestId}>
      <UiFilterPopupWrapper
        onCancel={onCancel}
        onSubmit={() =>
          onClickSubmit({
            allTimeranges: selectionTimeranges,
            selection: currentSelection
          })
        }
        testId={thisTestId}
      >
        <ReactDateRangePicker
          months={2}
          // should not be able to select into the future, or today as we have a 24 hour latency
          maxDate={new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)}
          onChange={({ selection }: { selection: ReactDateRangeInterface }) => {
            const matchedRange = selectionTimeranges.find(
              (t: TimerangeMetadataInterface) => t.id === selection.id
            );

            if (pageId === pageIds.RETROS) {
              // if this is a named timerange and  the timerange hasn't been customized
              // by adjusting via the calendar picker -- otherwise, don't allow selection, because
              // retros page can't support custom timeranges right now
              if (
                matchedRange?.end === selection.endDate.toISOString() &&
                matchedRange?.start === selection.startDate.toISOString()
              ) {
                setCurrentSelection(selection);
              }
            } else {
              setCurrentSelection(selection);
            }
          }}
          ranges={[currentSelection]}
          showPreview={false}
          staticRanges={selectionTimeranges.map(
            (option: TimerangeMetadataInterface) => {
              const { end, start, id, timerangeType } = option;

              return {
                label:
                  timerangeType === timerangeTypes.SPRINTS ? (
                    <TimeRangeSelectorSprintLabel
                      testId={id}
                      timerange={option}
                    />
                  ) : (
                    <TimeRangeSelectorLabel testId={id} timerange={option} />
                  ),
                range: () => ({
                  id,
                  // create date objects because that's what the library accepts
                  endDate: moment(end, moment.ISO_8601).toDate(),
                  startDate: moment(start, moment.ISO_8601).toDate()
                }),
                isSelected: () => currentSelection.id === id
              };
            }
          )}
          direction="horizontal"
        />
      </UiFilterPopupWrapper>
    </Container>
  );
};

export default TimeRangeSelector;
