import React from "react";
import styled from "styled-components/macro";
import { capitalize, snakeCase, sortBy, startCase } from "lodash";
import InformationText from "../../constants/informationText.json";

// components
import Icon from "../Icon/Icon";
import StoryPoints from "../StoryPoints/StoryPoints";
import WorkMetadataCycleTimeSummary from "../WorkMetadataCycleTimeSummary/WorkMetadataCycleTimeSummary";
import WorkMetadataIcon from "../WorkMetadataIcon/WorkMetadataIcon";

// constants
import {
  reservedEpics,
  workMetadataActivityTypes,
  workMetadataStatusTypes
} from "../../constants/constants";

// hooks
import { useParams } from "react-router-dom";

// interfaces
import { ActivityInterface } from "../../interfaces/work-items";

// styled components
const ActivitiesWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  border-top: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;
const AllIssuesList = styled.ul`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(4, 1fr);
`;
const AllIssuesListItem = styled.li`
  padding: 2rem;

  & + & {
    border-left: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  }
`;
const Header = styled.h4`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;
const IssueScore = styled.p`
  font-family: ${props => props.theme.fonts.subheader.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.header.sizes.xl};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
`;
const BreakdownListItem = styled.li`
  align-items: center;
  display: flex;
  text-align: center;
  & + & {
    margin-top: 1rem;
  }
`;
const BreakdownScore = styled.span`
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  margin-right: 1rem;
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
`;
const BreakdownStatus = styled.span`
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  text-transform: uppercase;
  margin-left: 1rem;
`;
const TypeIcon = styled(WorkMetadataIcon)`
  margin-right: 2rem;
`;
const UnlinkedPrsHelpText = styled.section`
  font-size: ${props => props.theme.fonts.header.sizes.xs};
  max-width: 28rem;
  padding: 2rem 0;
`;
const UnlinkedPrsHeader = styled.h4`
  font-family: ${props => props.theme.fonts.header.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.header.sizes.xs};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
  margin-bottom: 1rem;
`;
const InfoIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  margin-right: 0.5rem;
`;
const CycleTime = styled(WorkMetadataCycleTimeSummary)`
  margin: 3rem 0;
  width: 50%;
`;

// typescript props
type Props = {
  className?: string;
  data: Array<ActivityInterface>;
  testId?: string;
};

const WorkMetadataIssuesSummary = ({
  className,
  data,
  testId = "testId"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-work-metadata-details-issues-summary`;
  const { groupId } = useParams<{ groupId: string }>();
  const isUnlinkedPrs = groupId === reservedEpics.UNLINKED_PRS;

  const workToDisplay = isUnlinkedPrs
    ? data.filter(
        item =>
          snakeCase(item.activityType).toUpperCase() ===
          workMetadataActivityTypes.PR
      )
    : data;
  const categoriesToDisplay = isUnlinkedPrs
    ? [workMetadataActivityTypes.PR]
    : [
        workMetadataActivityTypes.ISSUE,
        workMetadataActivityTypes.SUB_TASK,
        workMetadataActivityTypes.BUG,
        workMetadataActivityTypes.PR
      ];

  const cycleTime =
    workToDisplay.find(
      item =>
        snakeCase(item.activityType).toUpperCase() ===
        workMetadataActivityTypes.PR
    )?.cycleTime || null;

  const activityFromCategory = (
    category: string,
    work: Array<ActivityInterface>
  ) =>
    work.find(
      (activity: ActivityInterface) =>
        snakeCase(activity.activityType).toUpperCase() === category
    );

  return (
    <div className={className} data-testid={thisTestId}>
      <ActivitiesWrapper>
        <AllIssuesList>
          {categoriesToDisplay
            .filter(c => !!activityFromCategory(c, workToDisplay))
            .map((activityKey: string) => {
              const activity = activityFromCategory(activityKey, workToDisplay);

              const title =
                activityKey === workMetadataActivityTypes.BUG
                  ? "Bugs"
                  : activityKey === workMetadataActivityTypes.ISSUE
                  ? "Issues"
                  : activityKey === workMetadataActivityTypes.PR
                  ? "PRs"
                  : "Subtasks";

              const breakdown = activity?.breakdownByStatus || [];
              return (
                <AllIssuesListItem key={activityKey}>
                  <Header>
                    <TypeIcon type={activityKey} testId={thisTestId} />
                    {title}
                  </Header>
                  <IssueScore>{activity?.count ?? 0}</IssueScore>
                  <ul>
                    {sortBy(breakdown, "status").map(b => {
                      return (
                        <BreakdownListItem key={b.status}>
                          <BreakdownScore>{b.count}</BreakdownScore>
                          <StoryPoints storyPoints={b.storyPoints} />
                          <BreakdownStatus>
                            {b.status === workMetadataStatusTypes.OPEN
                              ? "To do"
                              : b.status ===
                                workMetadataStatusTypes.CLOSED_NOT_MERGED
                              ? "Closed/not merged"
                              : capitalize(startCase(b.status))}
                          </BreakdownStatus>
                        </BreakdownListItem>
                      );
                    })}
                  </ul>
                </AllIssuesListItem>
              );
            })}
        </AllIssuesList>
        {isUnlinkedPrs ? (
          <UnlinkedPrsHelpText>
            <UnlinkedPrsHeader>
              <InfoIcon icon="info" className="info-icon" />
              Link your PRs to Jira
            </UnlinkedPrsHeader>
            <p>{InformationText["linking-prs"]}</p>
          </UnlinkedPrsHelpText>
        ) : null}
      </ActivitiesWrapper>
      <CycleTime cycleTime={cycleTime} display="rollup" testId={thisTestId} />
    </div>
  );
};

export default WorkMetadataIssuesSummary;
