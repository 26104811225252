import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { isEmpty, debounce, union, isNil } from "lodash";

// actions

// components
import EpicFilterCheckboxList from "../EpicFilterCheckboxList/EpicFilterCheckboxList";
import InfoIcon from "../InfoIcon/InfoIcon";

// constants
import {
  urlParamKeys,
  pullRequestCalloutTypes,
  workItemCalloutTypes,
  urlParamsValuesLabels
} from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";
import {
  EpicDetailsInterface,
  GroupsListInterface,
  ResponseTotalInterface
} from "../../interfaces/work-items";
import {
  UrlParamsHookReturnInterface,
  UrlParamsInterface
} from "../../interfaces/url-params";

// styled components
const Option = styled.span`
  align-items: center;
  display: flex;
`;

// typescript props
type Props = {
  callouts: UrlParamsInterface["callouts"];
  className?: string;
  isSprintMode: boolean;
  testId?: string;
  updateUrlParams: UrlParamsHookReturnInterface["updateUrlParams"];
  workItemsData?: EpicDetailsInterface | GroupsListInterface;
};

const WorkItemCalloutsFilter = ({
  callouts,
  className,
  isSprintMode,
  testId = "testId",
  updateUrlParams,
  workItemsData
}: Props): React.ReactElement | null => {
  const thisTestId = `${testId}-sprint-callouts-filter`;
  const { trackEvent } = useTracking();
  const [areFiltersDisabled, setAreFiltersDisabled] = useState(false);
  const calloutTotals = workItemsData?.calloutTotals;
  const pullRequestCallouts = callouts.filter((c: string) =>
    Object.values(pullRequestCalloutTypes).includes(c)
  );
  const enabledCalloutIds = calloutTotals?.map(c => c.callout);
  const optionsList = ([
    {
      helpTextKey: "sprint-callout-added-mid-sprint",
      id: workItemCalloutTypes.ADDED_MID_SPRINT,
      isVisible: isSprintMode
    },
    {
      helpTextKey: "sprint-callout-open-multiple-sprints",
      id: workItemCalloutTypes.OPEN_MULTIPLE_SPRINTS,
      isVisible: isSprintMode
    },
    {
      helpTextKey: "work-items-callout-null-story-points",
      id: workItemCalloutTypes.NULL_STORY_POINTS,
      isVisible: !isNil(
        calloutTotals?.find(
          (t: ResponseTotalInterface) =>
            t.callout === workItemCalloutTypes.NULL_STORY_POINTS
        )?.total
      )
    }
  ] as Array<{
    name: string;
    helpTextKey:
      | "sprint-callout-added-mid-sprint"
      | "sprint-callout-open-multiple-sprints"
      | "work-items-callout-null-story-points";
    id: string;
    isVisible: boolean;
  }>)
    .filter(m => m.isVisible && enabledCalloutIds?.includes(m.id))
    .map(m => {
      return {
        id: m.id,
        isDisabled: areFiltersDisabled,
        isSelected: callouts.includes(m.id),
        name: (
          <Option>
            {urlParamsValuesLabels[m.id]} (
            {calloutTotals?.find(
              (t: ResponseTotalInterface) => t.callout === m.id
            )?.total || 0}
            ){" "}
            <InfoIcon
              content={m.helpTextKey}
              testId={`${thisTestId}-${m.id}`}
            />
          </Option>
        )
      };
    });

  // fixing ts error with this:
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedToggle = useMemo(
    () =>
      debounce(updatedTypes => {
        updateUrlParams({
          [urlParamKeys.CALLOUTS]: updatedTypes
        });
        setAreFiltersDisabled(false);
      }, 500),
    [updateUrlParams]
  );

  function onToggleType(
    updated: Array<UiFilterSelectionStatusInterface>,
    retainedCallouts: Array<string>
  ) {
    const updatedTypes = updated
      .filter((u: UiFilterSelectionStatusInterface) => u.isSelected)
      .map((s: UiFilterSelectionStatusInterface) => s.id as string);

    setAreFiltersDisabled(true);
    debouncedToggle(union(updatedTypes, retainedCallouts));

    trackEvent({
      label: "ui-filter-work-item-callouts-change",
      value: `changed work item callouts to ${(isEmpty(updatedTypes)
        ? Object.values(workItemCalloutTypes)
        : updatedTypes
      ).join(",")}`
    });
  }

  return optionsList.length ? (
    <div className={className} data-testid={thisTestId}>
      <EpicFilterCheckboxList
        header="Work Item Callouts"
        list={optionsList}
        name="work-item-callouts"
        onUpdateSelection={updated =>
          onToggleType(updated, pullRequestCallouts)
        }
        testId="work-item-callouts"
      />
    </div>
  ) : null;
};

export default WorkItemCalloutsFilter;
