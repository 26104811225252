import React, { useMemo } from "react";
import styled from "styled-components/macro";

// components
import EpicFilterDrawerAndTrigger from "../EpicFilterDrawerAndTrigger/EpicFilterDrawerAndTrigger";
import FilterPills from "../FilterPills/FilterPills";
import EmptyState from "../EmptyState/EmptyState";
import InformationText from "../../constants/informationText.json";
import { invertPRWorkItems } from "../../utils/work-items";
import PRRepositorySummaryList from "../PRRepositorySummaryList/PRRepositorySummaryList";
import PrWorkflowChartWrapper from "../PrWorkflowChartWrapper/PrWorkflowChartWrapper";

// hooks
import { useWorkItemsData } from "../../hooks/useWorkItemsData";

// interfaces
import { EpicDetailsInterface } from "../../interfaces/work-items";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import LoadingStates from "../LoadingStates/LoadingStates";

// styled components
const FilterWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: row wrap;
  background: ${props => props.theme.colors.all.white};
  padding-left: 2rem;

  & > div,
  > button {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  > button:not(:last-child) {
    border: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  }
`;
const StyledFilter = styled(EpicFilterDrawerAndTrigger)`
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;
const StyledEmptyState = styled(EmptyState)`
  background: ${props => props.theme.colors.all.white};
  padding-top: 5rem;
  padding-bottom: 30rem;
  > div {
    max-width: 50rem;
  }
  text-align: left;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};
export const PRWorkflow = ({ className, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-pr-workflow`;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const { data, isError, isLoading, isSuccess } = useWorkItemsData({
    canRequest: flags?.["pr-workflow"],
    options: { type: "pr-workflow" }
  });
  const hasData = (data as EpicDetailsInterface)?.workItems?.length;
  const parsedData = useMemo(() => {
    if (!!data) {
      return {
        ...data,
        workItems: invertPRWorkItems((data as EpicDetailsInterface).workItems)
      };
    }
  }, [data]);

  return (
    <div className={className} data-testid={thisTestId}>
      <LoadingStates
        isSpinningState={flags?.["pr-workflow"] && isLoading}
        isNoDataState={
          flags?.["pr-workflow"] && (isError || (isSuccess && !data))
        }
        content={
          flags?.["pr-workflow"] ? (
            <>
              <FilterWrapper>
                <StyledFilter />
                <FilterPills />
              </FilterWrapper>
              {hasData ? (
                <>
                  <PrWorkflowChartWrapper
                    data={parsedData as EpicDetailsInterface}
                  />
                  <PRRepositorySummaryList
                    data={parsedData as EpicDetailsInterface}
                  />
                </>
              ) : (
                <StyledEmptyState
                  body={InformationText["pr-workflow-no-data"]}
                />
              )}
            </>
          ) : (
            <PrWorkflowChartWrapper />
          )
        }
        testId={thisTestId}
      />
    </div>
  );
};

export default PRWorkflow;
