import { actionTypes } from "../constants/actionTypes";

/**
 * return action to store access token in state
 * @param {String} authAccessToken
 */
export const storeAuthAccessToken = authAccessToken => {
  return {
    type: actionTypes.STORE_AUTH_ACCESS_TOKEN,
    payload: { authAccessToken }
  };
};

/**
 * return action to store auth params in state
 * @param {Object} authParams
 */
export const storeAuthParams = authParams => {
  return {
    type: actionTypes.STORE_AUTH_PARAMS,
    payload: { authParams }
  };
};
