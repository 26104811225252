import React, { ReactElement } from "react";
import styled, { css } from "styled-components/macro";
import { capitalize, isNil } from "lodash";

// components
import ValueWithDirectionality from "../ValueWithDirectionality/ValueWithDirectionality";

// interfaces
import { CycleTimeStageInterface } from "../../interfaces/work-items";
import { CycleTimeStageTypes } from "../../interfaces/constants";
import { getCycleTimeStageInHours } from "../../utils/cycle-time";
import CycleTimeStageHeader from "../CycleTimeStageHeader/CycleTimeStageHeader";

// styled components
const ValuesContainer = styled.div<{ display: displayTypes }>`
  align-items: baseline;
  display: inline-flex;
`;
const NumberAndUnit = styled.span<{
  display: displayTypes;
  stageKey?: keyof CycleTimeStageTypes;
}>`
  white-space: nowrap;
  &:not(:only-of-type):first-of-type {
    ${props => {
      if (props.display === "inline") {
        return css`
          &::after {
            content: ", ";
          }
        `;
      } else {
        return css`
          margin-right: 0.25em;
        `;
      }
    }};
  }

  ${props => {
    if (props.display === "breakoutBlock" && !!props.stageKey) {
      return css`
        display: block;
        padding: 0.25rem 1rem;
        min-width: 4rem;
        color: ${props.theme.colors.chart.cycleTime[
          props.stageKey as keyof CycleTimeStageTypes
        ].color};
        background: ${props.theme.colors.chart.cycleTime[
          props.stageKey as keyof CycleTimeStageTypes
        ].backgroundColor};
      `;
    }
  }}
`;
const Sign = styled.span`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const Number = styled.span<{ display: displayTypes }>`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  font-size: 1em;
  font-weight: ${props =>
    props.display === "inline" || props.display === "breakoutBlock"
      ? props.theme.fonts.primary.weights.regular
      : props.theme.fonts.primary.weights.bold};
  line-height: 1;
`;
const NA = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  font-size: 1em;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  line-height: 1;
`;
const Unit = styled.span<{ display: displayTypes }>`
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  ${props => {
    if (
      props.display === "breakoutBlock" ||
      props.display === "inlineAbbreviation"
    ) {
      return css`
        text-transform: lowercase;
      `;
    }
  }};
`;
const Delta = styled(ValueWithDirectionality)<{ mode: displayTypes }>`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  white-space: nowrap;
  ${props => {
    if (props.mode === "inline") {
      return css`
        margin-left: 0.5em;
      `;
    }
  }};
`;
const MissingStage = styled.span`
  display: inline-block;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  line-height: 1.2;
`;

// typescript props
type displayTypes =
  | "breakout"
  | "breakoutBlock"
  | "inline"
  | "inlineAbbreviation"
  | "rollup";
type Props = {
  className?: string;
  stage: Array<CycleTimeStageInterface> | null;
  delta?:
    | (CycleTimeStageInterface & {
        direction: string;
        isInverted?: boolean;
      })
    | null;
  display?: displayTypes;
  stageKey?: keyof CycleTimeStageTypes;
  testId?: string;
};

const CycleTimeValueDisplay = ({
  className,
  stage,
  delta,
  display = "inline",
  stageKey,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-cycle-time-value-display`;
  const stageCycleTimeInHours = !!stage && getCycleTimeStageInHours(stage);
  return (
    <div className={className} data-testid={thisTestId}>
      {display === "breakout" && !!stageKey ? (
        <CycleTimeStageHeader stageKey={stageKey} testId={thisTestId} />
      ) : null}
      <ValuesContainer display={display}>
        {isNil(stage) ? (
          !!stageKey ? (
            <NumberAndUnit
              display={display}
              stageKey={stageKey as keyof CycleTimeStageTypes}
            >
              <MissingStage>
                No PRs
                {display === "breakoutBlock" ? null : (
                  <>
                    <br />
                    in this stage
                  </>
                )}
              </MissingStage>
            </NumberAndUnit>
          ) : (
            <NumberAndUnit display={display}>
              <NA>N/A</NA>
            </NumberAndUnit>
          )
        ) : stage.every(s => s.value === 0) && !display.startsWith("inline") ? (
          <NumberAndUnit
            display={display}
            stageKey={stageKey as keyof CycleTimeStageTypes}
          >
            <Sign>&lt;</Sign>
            <Number display={display}>1</Number>
            <Unit display={display}>
              {display === "breakoutBlock" ? "H" : "Hour"}
            </Unit>
          </NumberAndUnit>
        ) : (
          stage.map((number: CycleTimeStageInterface) => {
            const displayUnit =
              display === "breakoutBlock" ||
              display === "inlineAbbreviation" ? (
                number.unit.substring(0, 1)
              ) : (
                <>
                  {" "}
                  {capitalize(
                    stageCycleTimeInHours < 1 && display !== "inline"
                      ? number.unit.substring(0, number.unit.length - 1)
                      : number.value === 1
                      ? number.unit.substring(0, number.unit.length - 1)
                      : number.unit
                  )}
                </>
              );
            return (
              <NumberAndUnit
                display={display}
                stageKey={stageKey as keyof CycleTimeStageTypes}
                key={`${stageKey}-${number.unit}`}
              >
                <Number display={display}>{number.value}</Number>
                <Unit display={display}>{displayUnit}</Unit>
              </NumberAndUnit>
            );
          })
        )}
      </ValuesContainer>
      {isNil(delta) ? null : (
        <Delta
          {...delta}
          mode={display}
          unit={delta.unit === "DAYS" ? "d" : "h"}
        />
      )}
    </div>
  );
};

export default CycleTimeValueDisplay;
