import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components/macro";
import { isEmpty, omit } from "lodash";

// components
import ChartPackedBubble from "../ChartPackedBubble/ChartPackedBubble";

// config
import generateConfig from "./config";

// styled components
const Container = styled(ChartPackedBubble)`
  width: 100%;

  & g {
    cursor: pointer;
  }

  & .highcharts-legend {
    & .highcharts-point {
      stroke-width: 0;
    }
  }

  & .marker {
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    align-content: center;

    & header {
      line-height: 1;
      order: 2;
      text-align: center;
      white-space: initial;
      font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
        sans-serif;
      font-size: ${props => props.theme.fonts.primary.sizes.sm};
      font-weight: ${props => props.theme.fonts.primary.weights.regular};
      margin-top: 0.5rem;

      & span {
        margin: 0 auto;
      }
    }

    & .value {
      font-family: ${props => props.theme.fonts.subheader.name}, monospace;
      font-size: 3rem;
      font-weight: ${props => props.theme.fonts.subheader.weights.bold};
      line-height: 1;
      text-align: center;
      order: 1;
    }
  }
`;

// exporting for targeting in tests
export const ChartMeetingClassifications = ({
  className,
  data = {},
  isFetchingData = false,
  onSelectClassificationId = () => {
    // do nothing
  },
  selectedTeamMember = {},
  testId = "testId",
  theme,
  override,
  ...props
}) => {
  const thisTestId = `${testId}-meeting-classifications-chart`;
  const [config, setConfig] = useState(
    generateConfig({
      data: [],
      onSelectClassificationId,
      selectedTeamMember,
      theme
    })
  );

  const memoizedData = useMemo(() => {
    return JSON.stringify(omit(data, ["NOT_A_MEETING"]));
  }, [data, `${override?.title}${override?.overrideLabel}`]);
  const memoizedTheme = useMemo(() => JSON.stringify(theme), [theme]);
  const memoizedTeamMember = useMemo(() => JSON.stringify(selectedTeamMember), [
    selectedTeamMember
  ]);

  useEffect(() => {
    setConfig(
      generateConfig({
        data: JSON.parse(memoizedData),
        onSelectClassificationId,
        selectedTeamMember: JSON.parse(memoizedTeamMember),
        theme: JSON.parse(memoizedTheme)
      })
    );
  }, [memoizedData, memoizedTheme, memoizedTeamMember]);

  return (
    <Container
      chart={{
        config,
        id: "meeting-classifications-chart",
        isFetchingData
      }}
      className={className}
      testId={thisTestId}
      theme={theme}
    />
  );
};

ChartMeetingClassifications.propTypes = {
  data: PropTypes.object,
  isFetchingData: PropTypes.bool,
  onSelectClassificationId: PropTypes.func,
  selectedTeamMember: PropTypes.object,
  testId: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default withTheme(ChartMeetingClassifications);
