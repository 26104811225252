import { AxiosResponse } from "axios";
import api from "./api";
import { inRange, isNull, isUndefined, partition, uniq } from "lodash";

// constants
import { NIL } from "uuid";
import {
  groupByTypes,
  reservedEpics,
  reservedEpicsMetadata,
  healthScoreRanges,
  sprintStates,
  timerangeTypes
} from "../constants/constants";

// interfaces
import {
  BoardInterface,
  ProjectsAndBoardsResponse,
  SprintHealthInterface,
  SprintInterface,
  SprintSuccessInterface
} from "../interfaces/sprint-metadata";
import { AnnotationsInterface } from "../interfaces/annotations";
import { GroupInterface, ProjectInterface } from "../interfaces/work-items";
import { TeamInterface } from "../interfaces/team";
import { getSprintTimerangesForBoard } from "./timeranges";
import { TimerangeMetadataInterface } from "../interfaces/constants";

// typescript props
type BaseProps = {
  accessToken: string;
  tenantId: number;
};

type JiraBoardsProps = BaseProps & {
  params: {
    endDate: string;
    projectIds: Array<string>;
    startDate: string;
    tenantId: number;
    users: Array<string>;
  };
};

type SprintHealthProps = BaseProps & {
  params: {
    sprintIds: Array<string>;
    teamId: string | null;
  };
};

type SprintSuccessProps = BaseProps & {
  params: {
    sprintId: string;
    teamId: string;
  };
};

export const requestSprintMetadata = async ({
  accessToken,
  params,
  tenantId
}: JiraBoardsProps): Promise<Array<BoardInterface>> => {
  const response: AxiosResponse<Array<BoardInterface>> = await api.post<
    Array<BoardInterface>
  >(`/${tenantId}/project-explorer/sprint-metadata`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const requestProjectsAndBoards = async ({
  accessToken,
  tenantId
}: BaseProps): Promise<ProjectsAndBoardsResponse> => {
  const response: AxiosResponse<ProjectsAndBoardsResponse> = await api.get<
    ProjectsAndBoardsResponse
  >(`/${tenantId}/projects-and-boards/get-all`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const requestSprintHealth = async ({
  accessToken,
  params,
  tenantId
}: SprintHealthProps): Promise<SprintHealthInterface> => {
  const response: AxiosResponse<SprintHealthInterface> = await api.post<
    SprintHealthInterface
  >(`/${tenantId}/project-explorer/sprint-health`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};
export const requestSprintSuccess = async ({
  accessToken,
  params,
  tenantId
}: SprintSuccessProps): Promise<SprintSuccessInterface> => {
  const response: AxiosResponse<SprintSuccessInterface> = await api.get<
    SprintSuccessInterface
  >(`/${tenantId}/sprint-success/v1/sprint-id/${params.sprintId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: { teamId: params.teamId }
  });
  return response.data;
};

export const getDefaultActiveSprint = (sprints?: Array<SprintInterface>) => {
  if (!sprints?.length) {
    return null;
  }
  return sprints.find(s => s.state === sprintStates.ACTIVE) || sprints[0];
};

export const getSprintRangeString = (value: number) => {
  return Object.keys(healthScoreRanges)[
    Object.values(healthScoreRanges).findIndex(r => inRange(value, r[0], r[1]))
  ];
};

export const filterSprintHealthFactorsToRange = (
  factors: Array<[string, number | null]>,
  range: Array<number>
): Array<string> => {
  return factors
    .filter(e => !isNull(e[1]) && inRange(e[1], range[0], range[1]))
    .map(e => e[0]);
};

export const filterAnnotationsForGroup = ({
  annotations,
  group,
  groupBy
}: {
  annotations: Array<AnnotationsInterface>;
  group: GroupInterface;
  groupBy: string;
}) => {
  const filteredAnnotations = annotations.filter(a => {
    const selectAttribute =
      groupBy === groupByTypes.PROJECT ? "projectId" : "epicId";
    return a[selectAttribute] === group.groupId;
  });

  // do some extra filtering for magic rows
  if (group.groupId === NIL) {
    const [nilProjectId, uuidProjectId] = partition(
      filteredAnnotations,
      a => a.projectId === NIL
    );

    // annotations for unlinked PRS on the epics side and for work not tied to
    // project on the projects side will have nil project ids
    return group.magicRow ===
      reservedEpicsMetadata[reservedEpics.UNLINKED_PRS].magicRowId ||
      // this is hacky but apparently the work not tied to project group doesn't return a magic row id??
      group.groupName.toLowerCase() === "work not tied to project"
      ? nilProjectId
      : uuidProjectId;
  }

  return filteredAnnotations;
};

export const getBoard = ({
  sprintMetadata,
  allBoards,
  boardId,
  team
}: {
  sprintMetadata?: Array<BoardInterface>;
  allBoards?: Array<BoardInterface>;
  boardId?: string | null;
  team?: TeamInterface | null;
}): BoardInterface | null | undefined => {
  if (!!team && !isUndefined(allBoards)) {
    const needsSprintMetadata = !!team?.useSprints && !team?.defaultBoardId;
    if (
      !needsSprintMetadata ||
      (needsSprintMetadata && !isUndefined(sprintMetadata))
    ) {
      const board =
        allBoards?.find((b: BoardInterface) => b.boardId === boardId) ||
        allBoards?.find(
          (b: BoardInterface) => b.boardId === team?.defaultBoardId
        ) ||
        sprintMetadata?.[0];

      if (!!team?.useSprints && !!board) {
        // remove sprints that don't have truthy start dates or have future status
        const filteredSprints = board.sprints.filter(
          (s: SprintInterface) => !!s.startAt && s.state !== sprintStates.FUTURE
        );
        return !!filteredSprints.length
          ? {
              ...board,
              sprints: filteredSprints
            }
          : null;
      }
      return null;
    }
  }
};

export const getTeamSprintMetadata = ({
  shouldUseSprints,
  projectsData,
  defaultProjectId,
  defaultBoardId,
  isOrgChartTeam,
  derivedTeamBoard
}: {
  shouldUseSprints: boolean;
  projectsData: {
    projects: Array<ProjectInterface>;
    boards: Array<BoardInterface>;
  };
  defaultProjectId?: string;
  defaultBoardId?: string;
  isOrgChartTeam?: boolean;
  derivedTeamBoard?: BoardInterface | null;
}) => {
  let projects: Array<ProjectInterface> = [];
  let boards: Array<BoardInterface> = [];
  let board: BoardInterface | null = null;
  let project: ProjectInterface | null = null;
  let sprint: TimerangeMetadataInterface | null = null;

  if (shouldUseSprints) {
    const projectIdsWithBoards = uniq(
      projectsData.boards.map((b: BoardInterface) => b.projectId)
    );
    projects = projectsData.projects.filter((p: ProjectInterface) =>
      projectIdsWithBoards.includes(p.projectId)
    );
    boards = projectsData.boards;

    if (!!defaultProjectId) {
      const defaultProject = projects.find(
        (p: ProjectInterface) => p.projectId === defaultProjectId
      );
      if (!!defaultProject) {
        project = defaultProject;
        const defaultBoards = boards.filter(
          (b: BoardInterface) => b.projectId === defaultProjectId
        );
        if (!!defaultBoards.length) {
          boards = defaultBoards;
        }
      }
    }

    if (!!defaultBoardId || (isOrgChartTeam && !!derivedTeamBoard)) {
      const defaultBoard =
        isOrgChartTeam && !!derivedTeamBoard
          ? derivedTeamBoard
          : boards.find((b: BoardInterface) => b.boardId === defaultBoardId);
      if (!!defaultBoard) {
        board = defaultBoard;
        const defaultProject = projects.find(
          (p: ProjectInterface) => p.projectId === defaultBoard.projectId
        );
        if (!!defaultProject) {
          project = defaultProject;
        }
        const defaultSprint = getDefaultActiveSprint(defaultBoard.sprints);
        if (!!defaultSprint) {
          const sprintTimeranges = getSprintTimerangesForBoard(defaultBoard);
          const defaultSprintTimerange = sprintTimeranges.find(
            t => t.id === defaultSprint.sprintId
          );
          if (!!defaultSprintTimerange) {
            sprint = defaultSprintTimerange;
          }
        }
      }
    }
  }
  return { project, board, sprint, projects, boards };
};

export const getIsSprintMode = (
  timerange: TimerangeMetadataInterface
): boolean => timerange.timerangeType === timerangeTypes.SPRINTS;

export const getIsActiveSprint = (
  timerange: TimerangeMetadataInterface
): boolean => timerange.state === sprintStates.ACTIVE;
