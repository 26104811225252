import React from "react";
import styled, { css } from "styled-components/macro";
import checkedAsset from "../../images/checked.svg";
import uncheckedLightAsset from "../../images/unchecked-light.svg";
import uncheckedDarkAsset from "../../images/unchecked-dark.svg";

// interfaces
import { RadioCheckboxOptionsInterface } from "../../interfaces/controls";
// styled components
const HiddenInput = styled.input`
  margin-right: -1rem;
  opacity: 0;
`;
const Label = styled.label`
  display: flex;
  align-items: flex-start;
  margin: 0;
  vertical-align: middle;
`;
const DisplayInput = styled.span<{
  checked: boolean;
  variation: "light" | "dark";
}>`
  flex: 0 0 2.45rem;
  height: 1.95rem;
  margin-right: 1rem;
  position: relative;
  z-index: 1;

  ${HiddenInput}:focus + & {
    outline: 5px auto -webkit-focus-ring-color;
  }

  ${props => {
    const bgImage = props.checked
      ? checkedAsset
      : props.variation === "light"
      ? uncheckedLightAsset
      : uncheckedDarkAsset;
    return css`
      background: url(${bgImage}) no-repeat;
    `;
  }};
`;
const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  line-height: 1.3;
`;

// typescript props
type Props = {
  className?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
  variation?: "light" | "dark";
} & RadioCheckboxOptionsInterface;

const CustomCheckboxInput = ({
  className,
  disabled = false,
  label,
  name,
  onChange,
  selected = false,
  testId = "testId",
  value,
  variation = "dark"
}: Props) => {
  return (
    <Label className={className} htmlFor={testId} data-testid={testId}>
      <HiddenInput
        disabled={disabled}
        checked={selected}
        data-testid={`${testId}-actual-input`}
        id={testId}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={value}
      />
      <DisplayInput
        aria-checked={selected}
        aria-disabled={disabled}
        aria-labelledby={testId}
        checked={selected}
        role="checkbox"
        tabIndex={-1}
        variation={variation}
      />
      <TextWrapper data-testid={`${testId}-label-text`}>{label}</TextWrapper>
    </Label>
  );
};

export default CustomCheckboxInput;
