import React, { useState } from "react";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { get } from "lodash";
import {
  orderedAnnotationStatusTypes,
  StatusIconMap
} from "../../constants/constants";

import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";

import ReactionTooltip from "./ReactionTooltip.js";
import { useUrlParams } from "../../hooks/useUrlParams";

const PillRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.25rem;
`;
const ReactionPill = styled.div`
  background-color: ${props => props.theme.colors.all.white};
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  border: ${props =>
    props.isSelected
      ? `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.wolverine}`
      : "none"};
  border-radius: 3rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.25rem;
  cursor: pointer;
  .reaction-pill-content {
    display: flex;
    justify-content: center;
    margin-right: ${props => (props.showCount ? "0.25rem" : "0rem")};
    & :hover {
      color: ${props => props.theme.colors.all.auroraTeal};
    }
  }
  .reaction-count {
    font-size: 1.3rem;
    font-family: "IBM Plex Mono";
  }
`;

const ReactionIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  width: 2rem;
  font-size: ${props => (props.showCount ? "1.3rem" : "2rem")};
  height: ${props => (props.showCount ? "1.3rem" : "inherit")};
  align-self: center;
`;

const ReactionPills = ({
  annotations,
  contextItem,
  handleAnnotationClick,
  showCount = true
}) => {
  const { urlParams } = useUrlParams();
  const activeTeam = urlParams.team;
  const teamMembers = activeTeam?.teamMembers || [];

  const userId = useSelector(state => get(state, "user.id"));
  const [hoverIconParty, setHoverIconParty] = useState(StatusIconMap.CELEBRATE);
  const [hoverIconDisappointed, setHoverIconDisappointed] = useState(
    StatusIconMap.DISAPPOINTED
  );
  // FIXME: this is a TERRIBLE hack to accomodate design wanting custom images on hover for some of these icons which are not available in current FontAwesome version. Remove once migrated to FontAwesome v6
  const handleHover = (status, isMouseEnter, e) => {
    e.preventDefault();
    if (status === StatusIconMap.CELEBRATE) {
      setHoverIconParty(
        `${StatusIconMap.CELEBRATE}${isMouseEnter ? "-aurora" : ""}`
      );
    } else {
      setHoverIconDisappointed(
        `${StatusIconMap.DISAPPOINTED}${isMouseEnter ? "-aurora" : ""}`
      );
    }
  };

  return (
    <PillRow>
      {orderedAnnotationStatusTypes.map((statusEntry, index) => {
        const annotationCheckins = annotations.filter(
          annotation => annotation.userAnnotationCheckinStatus === statusEntry
        );
        const iconKey = StatusIconMap[statusEntry];

        const statusCount = annotationCheckins.length;

        const isAuroraImage =
          iconKey === StatusIconMap.CELEBRATE ||
          iconKey === StatusIconMap.DISAPPOINTED;

        const iconToShow =
          iconKey === StatusIconMap.CELEBRATE
            ? hoverIconParty
            : iconKey === StatusIconMap.DISAPPOINTED
            ? hoverIconDisappointed
            : iconKey;

        const pillToDisplay = (
          <ReactionPill
            isSelected={annotationCheckins.some(anno => anno.userId === userId)}
            showCount={showCount}
            key={index}
            onClick={event =>
              handleAnnotationClick(contextItem, statusEntry, event)
            }
            onMouseEnter={e =>
              isAuroraImage && handleHover(StatusIconMap[statusEntry], true, e)
            }
            onMouseLeave={e =>
              isAuroraImage && handleHover(StatusIconMap[statusEntry], false, e)
            }
            data-testid="reaction-pill"
          >
            <ReactionIcon
              showCount={showCount}
              className="reaction-pill-content"
              icon={iconToShow}
            />{" "}
            {showCount ? (
              <span className="reaction-pill-content reaction-count">
                {statusCount}
              </span>
            ) : null}
          </ReactionPill>
        );
        return showCount && statusCount > 0 ? (
          <Tooltip
            placement="top"
            testId="annotations-modal-pill"
            key={index}
            trigger={pillToDisplay}
            popupContent={
              <ReactionTooltip
                annotations={annotationCheckins}
                teamMembers={teamMembers}
              />
            }
          />
        ) : (
          pillToDisplay
        );
      })}
    </PillRow>
  );
};

export default ReactionPills;
