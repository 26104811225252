import { useMemo } from "react";

// constants
import { namedTimerangeIds } from "../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useQuery, UseQueryResult } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";
import { OrgByTime, OrgDeepWorkByUser } from "../interfaces/organization";

// utils
import { requestOrganizationDeepWorkData } from "../utils/organization-data";

export const useOrganizationDeepWorkData = (
  options: {
    endDate?: string;
    type: "year" | typeof namedTimerangeIds.PREVIOUS_14_DAYS;
    userIds?: Array<number>;
  },
  canRequest = true
): UseQueryResult<OrgDeepWorkByUser | OrgByTime> => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const params = useMemo(
    () => ({
      accessToken,
      endDate: options.endDate,
      tenantId,
      type: options.type,
      userIds: options.userIds
    }),
    [accessToken, options, tenantId]
  );

  return useQuery(
    ["organization-deep-work", params],
    () => requestOrganizationDeepWorkData(params),
    {
      enabled: !!accessToken && !!tenantId && canRequest,
      refetchOnWindowFocus: false
    }
  );
};
