import React from "react";
import styled from "styled-components/macro";

// styled components
const Container = styled.div<{ transition: "fade-in" }>`
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: ${props => `${props.transition} 1s linear`};
`;

// typescript props
type Props = {
  children: React.ReactNode;
  className?: string;
  testId?: string;
  transition?: "fade-in";
};

const TransitionWrapper = ({
  children,
  className,
  testId = "testId",
  transition = "fade-in"
}: Props) => {
  const thisTestId = `${testId}-transition-wrapper`;
  return (
    <Container
      className={className}
      data-testid={thisTestId}
      transition={transition}
    >
      {children}
    </Container>
  );
};

export default TransitionWrapper;
