import {
  DeepDiveDataRecord,
  PeopleMetrics,
  SelectablePeopleMetrics,
  UserDeepDiveData,
  ContextSwitchingRecord
} from "../interfaces/team-deep-dive";
import {
  sum,
  toPairs,
  mean,
  isNull,
  omitBy,
  isUndefined,
  mapValues
} from "lodash";
import { UserReportWithMetadataInterface } from "../interfaces/user";
import {
  FilteredSprintHealthInterface,
  SprintHealthDataPointInterface,
  SprintHealthInterface
} from "../interfaces/sprint-metadata";

export const getPersonScore = (
  personRecord: UserDeepDiveData,
  metric: PeopleMetrics,
  factor?: keyof ContextSwitchingRecord
): number | null => {
  if (metric === "ALWAYS_ON") {
    const alwaysOnIndex = {
      NORMAL: 1,
      ABOVE_NORMAL: 3,
      HIGH: 5
    };
    return alwaysOnIndex[personRecord?.["ALWAYS_ON"]] || 1;
  }
  if (metric === "CONTEXT_SWITCHING") {
    const contextSwitchingIndex = {
      LESS: 0.165,
      ABOUT_THE_SAME: 0.5,
      MORE: 0.825
    };
    if (!!factor) {
      const contextSwitchingFactor =
        personRecord?.["CONTEXT_SWITCHING"]?.[factor];
      const contextSwitchingFactorScore = isNull(contextSwitchingFactor)
        ? null
        : contextSwitchingFactor?.["bucket"];
      return isNull(contextSwitchingFactorScore)
        ? null
        : !!contextSwitchingFactorScore
        ? contextSwitchingIndex[contextSwitchingFactorScore] || 0
        : 0;
    } else {
      return 0;
    }
  }

  // Slack interruptions are returned as decimal of an hour; convert to minutes if necessary
  const multiplier = metric === "CHAT_INTERRUPTIONS" ? 60 : 1;
  // get the mean for the metrics with days as a subdivision

  const metricToCalculate =
    metric === "DEEP_WORK"
      ? personRecord[metric]?.HOURS?.DETAILS || {}
      : personRecord[metric];

  const numbersToMean = toPairs(metricToCalculate)
    .filter(
      (record: [string, string | number]) => typeof record[1] === "number"
    )
    .map((record: [string, string | number]): number => {
      // explicitly cast as number, TS doesn't recognize the prior type filtering
      const value = record[1] as number;
      return value;
    });

  return mean(numbersToMean || []) * multiplier || 0;
};

export const getNumberDataSeries = (
  record: DeepDiveDataRecord,
  selectedMetric: SelectablePeopleMetrics,
  chartView: UserReportWithMetadataInterface | "team",
  metricSeries: PeopleMetrics,
  factor?: keyof ContextSwitchingRecord
) => {
  function getAveragePeopleScoresOfPresent(): number | null {
    const scores = record.peopleScores.map(personRecord => {
      return getPersonScore(personRecord, metricSeries, factor);
    });

    if (scores.every(s => isNull(s))) {
      return null;
    }
    const filteredScores = scores.filter(it => it != null);
    return sum(filteredScores) / (filteredScores.length || 1);
  }

  const metricValue =
    selectedMetric === "OVERALL_TEAM_HEALTH"
      ? record.teamScores[metricSeries]
      : chartView === "team"
      ? getAveragePeopleScoresOfPresent()
      : record.peopleScores.find(
          personRecord => personRecord.user === chartView.id
        )
      ? getPersonScore(
          record.peopleScores.find(
            personRecord => personRecord.user === chartView.id
          ) || {
            // some record are not returned in the response, so rather than have an undefined key, we hydrate all possible keys here and provide falsy values instead
            user: 0,
            DEEP_WORK: {
              HOURS: {
                TOTAL: "NORMAL",
                DETAILS: {}
              },
              CALLOUT_DETAILS: {}
            },
            CHAT_INTERRUPTIONS: {},
            ALWAYS_ON: "NORMAL",
            CONTEXT_SWITCHING: {
              TOTAL: {
                bucket: "ABOUT_THE_SAME"
              },
              CHAT_CHANNELS_WITH_INTERRUPTIONS: {
                bucket: "ABOUT_THE_SAME",
                details: {
                  count: 0
                }
              },
              "MEETINGS_WITH_2+_TEAMS": {
                bucket: "ABOUT_THE_SAME",
                details: { "": 0 }
              },
              PR_REPOS: {
                bucket: "ABOUT_THE_SAME",
                details: {
                  repos: []
                }
              },
              JIRA_EPICS: {
                bucket: "ABOUT_THE_SAME",
                details: { "": "" }
              },
              TOP_PRIORITY_BUGS: {
                bucket: "ABOUT_THE_SAME",
                details: { "": { summary: "", parent: "", name: "" } }
              }
            }
          },
          metricSeries,
          factor
        )
      : 0;
  return [record.dateEnd, metricValue];
};

export const getFilteredSprintHealthData = (
  data?: SprintHealthInterface
): FilteredSprintHealthInterface | undefined => {
  if (isUndefined(data)) {
    return data;
  }
  // filter any null sprints
  const filtered = omitBy(data, sprint =>
    isNull(sprint)
  ) as FilteredSprintHealthInterface;
  return mapValues(filtered, sprint => ({
    ...sprint,
    // filter any null factors from projectHealthFactors
    projectHealthFactors: omitBy(sprint.projectHealthFactors, f => isNull(f))
  }));
};
