import { currentTheme } from "../../themes/currentTheme";
import {
  flattenDeep,
  groupBy as lodashGroupBy,
  mapValues,
  min,
  reverse,
  sortBy
} from "lodash";

// interfaces
import {
  Benchmark,
  OrgDeepWorkByGroup,
  OrgDeepWorkByUserDataRecord
} from "../../interfaces/organization";
import { TooltipPositionerPointObject } from "highcharts";

// utils
import { median } from "../../utils/math";
import { getAdjustedTooltipPosition } from "../../utils/chart";

// constants
import {
  chartToolTipConfig,
  groupByTypes,
  iconCrossProfessorX,
  iconPlusMagneto,
  iconStarRogue,
  orderedRoles
} from "../../constants/constants";
import { hexToRgb } from "../../utils/color";

const NUM_VISIBLE_ROWS = 10;
// NB, these are mimicking tableau functionality, we may decide to move these
// out if other charts need that style
const icons = [
  "circle",
  "square",
  `url(${iconPlusMagneto})`,
  `url(${iconCrossProfessorX})`,
  `url(${iconStarRogue})`,
  "diamond",
  "triangle",
  "triangle-down",
  "circle",
  "square",
  "triangle",
  "triangle-down"
];
const ramp = [
  currentTheme.colors.all.beast,
  currentTheme.colors.all.petra,
  currentTheme.colors.all.magneto,
  currentTheme.colors.all.professorX,
  currentTheme.colors.all.rogue,
  currentTheme.colors.all.banshee,
  currentTheme.colors.all.iceman,
  currentTheme.colors.all.cyclops,
  currentTheme.colors.all.nightcrawler,
  currentTheme.colors.all.thunderbird,
  currentTheme.colors.all.pixie,
  currentTheme.colors.all.magma,
  currentTheme.colors.all.sunfire,
  currentTheme.colors.all.juggernaut,
  currentTheme.colors.all.phoenix,
  currentTheme.colors.all.archangel,
  currentTheme.colors.all.gambit,
  currentTheme.colors.all.jubilee
];

// types
type ExtendedUserRecord = OrgDeepWorkByUserDataRecord & {
  sortOrder: number;
  groupName: string;
};

export const mapAndSortDataByMedianValue = (
  data: Record<string, Array<OrgDeepWorkByUserDataRecord>>
): Array<{
  sortOrder: number;
  groupName: string;
  teamMembers: Array<ExtendedUserRecord>;
  medianDeepWorkValue: number;
}> => {
  const mappedTeamData = Object.values(
    mapValues(data, (teamMembers, groupName) => ({
      groupName,
      teamMembers,
      medianDeepWorkValue: median(
        teamMembers.map(t => t.value as number)
      ) as number
    }))
  );
  return reverse(sortBy(mappedTeamData, "medianDeepWorkValue")).map(
    (team, index) => {
      return {
        ...team,
        sortOrder: index,
        teamMembers: team.teamMembers.map(m => ({
          ...m,
          sortOrder: index,
          groupName: team.groupName
        }))
      };
    }
  );
};

export const makeRolesConfig = (
  roles: Array<string>
): Array<Highcharts.PointMarkerOptionsObject & { role: string }> => {
  return roles.map((role, index) => ({
    role,
    symbol: icons[index],
    fillColor: ramp[index],
    fillOpacity: 0.75
  }));
};

const config = ({
  data,
  title,
  benchmark,
  groupBy
}: {
  data: OrgDeepWorkByGroup;
  title: string;
  groupBy: typeof groupByTypes[keyof typeof groupByTypes];
  benchmark?: Benchmark;
}): Highcharts.Options => {
  const sortedData = mapAndSortDataByMedianValue(data.data);
  const allDataPoints = flattenDeep(sortedData.map(d => d.teamMembers));
  const dataPointsGroupedByRole = lodashGroupBy(allDataPoints, "role");
  const dataRoles = Object.keys(dataPointsGroupedByRole);
  const orderedDataRoles = orderedRoles.filter(role =>
    dataRoles.includes(role)
  );
  const rolesConfig = makeRolesConfig(orderedDataRoles);
  const series = orderedDataRoles.map(role => {
    const points = dataPointsGroupedByRole[role];
    const config = rolesConfig.find(c => c.role === role);
    return {
      name: role,
      id: role,
      fillColor: config?.fillColor,
      color: config?.fillColor,
      marker: config || {},
      data: points.map(p => ({
        endDate: data.endDate,
        marker: config,
        startDate: data.startDate,
        role: p.role,
        teamLeadsName: p.teamLeadsName,
        x: p.value,
        y: p.sortOrder
      })),
      type: "scatter"
    } as Highcharts.SeriesOptionsType;
  });

  return {
    accessibility: { description: title },
    chart: {
      height: 600,
      marginLeft: 125,
      spacingTop: 20,
      style: {
        fontFamily: currentTheme.fonts.primary.name,
        overflow: "visible"
      }
    },
    xAxis: [
      {
        accessibility: {
          description: "Average daily hours of deep work from 0h to 9h"
        },
        offset: 20,
        min: 0,
        max: 9,
        maxPadding: 0.1,
        lineColor: currentTheme.colors.all.wolverine,
        labels: {
          format: "{value}h",
          style: {
            color: currentTheme.colors.all.wolverine,
            fontFamily: currentTheme.fonts.subheader.name
          },
          y: 30
        },
        tickmarkPlacement: "on",
        tickColor: currentTheme.colors.all.wolverine,
        plotLines: [
          {
            color: currentTheme.colors.all.storm,
            dashStyle: "Dash",
            width: 2,
            value: benchmark?.mid
          }
        ],
        plotBands: [
          {
            color: hexToRgb({
              hex: currentTheme.colors.all.iceman,
              opacity: 0.25
            }),
            from: benchmark?.low,
            to: benchmark?.high
          }
        ]
      }
    ],
    yAxis: [
      {
        type: "category",
        accessibility: {
          description: `Team members grouped by ${groupBy}`
        },
        categories: sortedData.map(d => d.groupName),
        labels: {
          align: "right",
          enabled: true,
          step: 1,
          style: {
            color: currentTheme.colors.all.wolverine,
            fontSize: "1.1rem",
            fontFamily: currentTheme.fonts.subheader.name,
            textAlign: "right",
            textOverflow: "wrap"
          },
          useHTML: true
        },
        min: 0,
        // min and max are 0 indexed so this will show up to NUM_VISIBLE_ROWS
        // rows before we start scrolling
        max: min([sortedData.length - 1, NUM_VISIBLE_ROWS - 1]),
        reversed: true,
        scrollbar: {
          barBackgroundColor: currentTheme.colors.all.storm,
          barBorderRadius: 10,
          barBorderWidth: 0,
          buttonBackgroundColor: "transparent",
          buttonBorderWidth: 0,
          enabled: sortedData.length > NUM_VISIBLE_ROWS,
          minWidth: 3,
          rifleColor: currentTheme.colors.all.storm,
          trackBackgroundColor: currentTheme.colors.all.lightJean,
          trackBorderRadius: 10,
          trackBorderWidth: 0
        },
        tickmarkPlacement: "on",
        title: { text: null }
      }
    ],
    legend: {
      enabled: false
    },
    plotOptions: {
      scatter: {
        jitter: { y: 0.1 },
        marker: { radius: 5 },
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    title: {
      text: undefined
    },
    tooltip: {
      ...chartToolTipConfig,
      positioner: function(
        w: number,
        h: number,
        point: TooltipPositionerPointObject
      ) {
        return getAdjustedTooltipPosition({
          currentPosition: (this as any).getPosition(w, h, point),
          windowScroll: window.scrollY
        });
      }
    },
    series
  };
};

export default config;
