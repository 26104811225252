import React, { useState, useMemo, useCallback } from "react";
import styled from "styled-components/macro";
import classNames from "classnames";
import {
  countBy,
  get,
  has,
  isEmpty,
  isNil,
  kebabCase,
  snakeCase,
  startCase,
  toPairs
} from "lodash";
import moment from "moment";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// components
import AnnotationsModal from "../Annotations/AnnotationsModal";
import ChartCycleTime from "../ChartCycleTime/ChartCycleTime";
import Icon from "../Icon/Icon";
import ReactionPills from "../Annotations/ReactionPills";
import ReactionTooltip from "../Annotations/ReactionTooltip";
import RouteLink from "../RouteLink/RouteLink";
import ShowRelatedItemsSelector from "../ShowRelatedItemsSelector/ShowRelatedItemsSelector";
import Time from "../Time/Time";
import Tooltip from "../Tooltip/Tooltip";
import WorkMetadataIcon from "../WorkMetadataIcon/WorkMetadataIcon";
import WorkMetadataIssuesList from "../WorkMetadataIssuesList/WorkMetadataIssuesList";
import WorkMetadataTeamList from "../WorkMetadataTeamList/WorkMetadataTeamList";

// constants
import {
  calloutMetadata,
  reservedEpics,
  workMetadataActivityTypes,
  StatusIconMap,
  urlParamKeys,
  workDeepDiveSections,
  prComplexityLevels
} from "../../constants/constants";

// hooks
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";
import { useMutation, useQueryClient } from "react-query";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import {
  PrsByRepositoryRow,
  TableCellInterface,
  TableCellListColumnInterface,
  TableRowInterface,
  WorkItemInterface,
  WorkItemLeveledInterface
} from "../../interfaces/work-items";
import { AnnotationsInterface } from "../../interfaces/annotations";
import { Column, ColumnInstance } from "react-table";

// utils
import { createTimestamp } from "../../utils/date";
import {
  writeAnnotations,
  removeAnnotations
} from "../../utils/work-deep-dive";

// styled components
const Container = styled.div``;
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;
const Header = styled.h2`
  padding-top: 1rem;
  font-family: ${props => props.theme.fonts.header.name}, Arial, Helvetica,
    sans-serif;
  font-size: 1.8rem;
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
`;
const StyledShowRelatedItems = styled(ShowRelatedItemsSelector)`
  margin-left: 1rem;
`;
const ContentWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
  padding: 1.2rem 2rem;
`;
const IssueLink = styled.a`
  align-items: center;
  display: flex;
`;
const ExternalLink = styled.a`
  color: ${props => props.theme.colors.all.wolverine};
  font-weight: ${props => props.theme.fonts.primary.weights.book};
  align-items: center;
  display: flex;
  padding: 1rem 0;
`;
const LinkIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.storm};
  margin-left: 0.8rem;
`;
const IssueIconWrapper = styled.div<{
  hasCallouts: boolean;
  hasChildWithCallouts: boolean;
  level: number;
}>`
  align-items: stretch;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin-right: 1rem;
  padding-left: ${props => `calc(2rem * ${props.level})`};
`;
const IconBackgroundColor = styled.div<{
  hasCallouts: boolean;
  hasChildWithCallouts: boolean;
}>`
  align-items: center;
  background-color: ${props =>
    props.hasChildWithCallouts
      ? props.theme.colors.all.rogue
      : props.hasCallouts
      ? props.theme.colors.all.jubilee
      : "transparent"};
  display: flex;
`;
const Title = styled.header`
  line-height: 1.4;
  margin-bottom: 0.4rem;
`;
const PrSubstatus = styled.div`
  color: ${props => props.theme.colors.all.wolverine};
  align-items: center;
  display: flex;
  font-size: 1.1.rem;
  font-style: italic;
  font-weight: ${props => props.theme.fonts.primary.weights.light};
  margin-bottom: 0.8rem;
`;
const ArrowIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.storm};
  font-size: inherit;
  margin: 0 0.4rem;
`;
const DisplayId = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  white-space: nowrap;
`;
const StoryPoints = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
`;
const Updated = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
`;
const Status = styled.span`
  display: inline-block;
  line-height: 1.4;
  text-transform: uppercase;
`;
const CalloutListItem = styled.li`
  & + & {
    margin-top: 0.3rem;
  }
`;
const Callout = styled.strong`
  color: ${props => props.theme.colors.all.wolverine};
  font-style: italic;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  line-height: 1.4;
`;

const ReactionColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 7rem;
  flex-wrap: wrap-reverse;
`;
const ReactionIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  width: 1.1rem;
`;
const ReactionSet = styled.span`
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
`;
const ReactionCount = styled.span`
  margin-left: 0.5rem;
`;
const ReactButtons = styled.div`
  display: flex;
  flex-direction: row;
`;
const UserAnnotationsCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const ReactButton = styled("button")<{ hasReaction: boolean }>`
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${
      !!props.hasReaction
        ? props.theme.colors.all.wolverine
        : props.theme.colors.all.lightJean
    }`};
  background-color: ${props => props.theme.colors.all.white};
  font-size: 1.1rem;
  padding: 0.8rem;
  margin-right: 0.8rem;
`;
const ReactIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  margin-right: 0.8rem;
`;
const WorkHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  line-height: 1;
`;

const WorkTypeIcon = styled(WorkMetadataIcon)`
  width: 1.3rem;
  margin-right: 0.8rem;
`;

const WorkDisplayId = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  font-size: 2rem;
  margin-right: 1.6rem;
`;

const WorkTitle = styled.span`
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.subheader.weights.regular};
`;
const InternalLink = styled(RouteLink)`
  align-items: center;
  display: flex;
  &,
  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
    text-decoration: none;
  }
`;
const IssuesList = styled(WorkMetadataIssuesList)`
  border-spacing: 0;

  & th {
    background: ${props => props.theme.colors.all.white};

    &:last-child {
      padding-right: 0.8rem;
    }

    & div {
      white-space: nowrap;
    }

    & > div {
      line-height: 1;
    }
  }

  & tr {
    padding: 0;
    &:hover {
      background: ${props => props.theme.colors.all.lightJean};
    }

    &:hover {
      background: ${props => props.theme.colors.all.lightJean};

      &:not(.highlightedRow) {
        & td.activityType {
          background: ${props => props.theme.colors.all.lightJean};
        }
      }
    }

    &.highlightedRow {
      background: ${props => props.theme.colors.all.jubilee};

      & > .activityType {
        background: transparent;
      }
    }

    &.subRow {
      border-top: ${props =>
        `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    }
  }

  & td {
    min-height: 4rem;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      padding-right: 0.8rem;
    }

    &.activityType {
      background: ${props => props.theme.colors.all.white};
      height: 4rem;
      padding: 0;
      width: 5.6rem;
    }

    &.displayId {
      width: 6.4rem;
    }

    &.storyPoints {
      width: 6.4rem;
    }

    &.updated,
    &.status {
      width: 7.2rem;
    }

    &.callouts {
      width: 17.6rem;
    }

    &.owners {
      width: 5.6rem;
    }

    &.contributors {
      width: 9.6rem;
    }

    &.annotations {
      width: 24rem;
      & .reaction-set {
        padding: 0.25rem;
      }
    }
  }
`;

// typescript props
type Props = {
  className?: string;
  data: Array<WorkItemLeveledInterface>;
  testId?: string;
  showHeader?: boolean;
  manuallySort?: boolean;
  customColumns?: Array<{ id: string; position: number }>;
};

const EpicDetailsIssuesList = ({
  className,
  data,
  testId = "testId",
  showHeader = true,
  manuallySort = true,
  customColumns
}: Props) => {
  const thisTestId = `${testId}-epic-details-issues-list`;

  const { trackEvent } = useTracking();

  const { groupId } = useParams<{ groupId: string }>();
  const isUnlinkedPrs = groupId === reservedEpics.UNLINKED_PRS;

  const flags = useSelector((state: AppStateInterface) => state.flags);
  const hasAnnotations = flags?.["annotations"];

  const tenantId = useSelector(state => get(state, "auth.authParams.tenantId"));
  const accessToken = useSelector(state => get(state, "auth.authAccessToken"));

  const {
    updateUrlParams,
    urlParams,
    workItemsRequestParams,
    workDeepDiveParams,
    getWorkDeepDivePath
  } = useUrlParams();
  const activeTeam = useMemo(() => urlParams.team, [urlParams]);
  const teamMembers = useMemo(() => activeTeam?.teamMembers || [], [
    activeTeam
  ]);
  const sort = useMemo(() => urlParams.groupDetails.sort, [urlParams]);

  const userId = useSelector(state => get(state, "user.id"));

  // react-query hooks
  const queryClient = useQueryClient();
  // the result of useMutation is NOT stable but its attributes are, so it needs
  // to be deconstructed if intending to pass as a dependency
  // https://github.com/tannerlinsley/react-query/issues/1858
  const { mutate: writeAnnotationsMutate } = useMutation(writeAnnotations, {
    onSuccess: () => {
      // Invalidate annotations
      queryClient.invalidateQueries("annotations");
    },
    onError: (err: string) => console.error(err)
  });
  const { mutate: removeAnnotationsMutate } = useMutation(removeAnnotations, {
    onSuccess: () => {
      // Invalidate annotations
      queryClient.invalidateQueries("annotations");
    },
    onError: (err: string) => console.error(err)
  });

  const [activeIdToAnnotate, setActiveIdToAnnotate] = useState("");

  const triggerWriteAnnotation = useCallback(
    async (
      workItem: WorkItemInterface,
      annotationStatus: string,
      event: React.SyntheticEvent,
      isText = false
    ) => {
      event.persist();
      const annotation = {
        parentId: workItem.epicId || "",
        workItemId: workItem.id,
        workItemAltId:
          workItem.workItem === workMetadataActivityTypes.PR
            ? workItem.url
            : workItem.displayId,
        workItemType: workItem.workItem,
        userAnnotationCheckinStatus: isText ? "" : annotationStatus,
        userAnnotationPainLevel: 0,
        userDetailedComment: isText ? annotationStatus : "",
        annotationContext: workItem.status,
        userId: userId,
        timestamp: new Date().toString()
      };
      const writeParams = {
        ...workItemsRequestParams,
        annotation,
        groupId: workItem.epicId || groupId,
        projectId: workItem.projectId
      };

      event.preventDefault();
      writeAnnotationsMutate({
        accessToken,
        params: writeParams,
        tenantId
      });

      trackEvent({
        e: event,
        label: "write-work-item-annotation",
        value: `${
          isText ? "writing a text comment" : "reacting"
        } with a value of ${annotationStatus} on work item ${workItem.id}`
      });
    },
    [
      accessToken,
      groupId,
      tenantId,
      trackEvent,
      workItemsRequestParams,
      userId,
      writeAnnotationsMutate
    ]
  );

  const triggerRemoveAnnotation = useCallback(
    async (
      existingAnnotation: AnnotationsInterface,
      event: React.SyntheticEvent
    ) => {
      event.preventDefault();
      removeAnnotationsMutate({
        accessToken,
        params: {
          annotationId: existingAnnotation.annotationId
        },
        tenantId
      });
    },
    [accessToken, tenantId, removeAnnotationsMutate]
  );

  const handleAnnotationClick = useCallback(
    (
      workItem: WorkItemLeveledInterface,
      annotationStatus: string,
      event: React.SyntheticEvent
    ) => {
      event.persist();
      const existingAnnotation = workItem.annotations?.find(
        (anno: AnnotationsInterface) =>
          anno.userId === userId &&
          anno.userAnnotationCheckinStatus === annotationStatus &&
          anno.workItemId === workItem.id
      );
      if (existingAnnotation) {
        triggerRemoveAnnotation(existingAnnotation, event);
      } else {
        triggerWriteAnnotation(workItem, annotationStatus, event);
      }
    },
    [triggerRemoveAnnotation, triggerWriteAnnotation, userId]
  );

  const triggerWriteTextComment = useCallback(
    (
      workItem: WorkItemInterface,
      textComment: string,
      event: React.SyntheticEvent
    ) => {
      triggerWriteAnnotation(workItem, textComment, event, true);
    },
    [triggerWriteAnnotation]
  );
  const onClickIssueLink = useCallback(
    ({
      e,
      issue
    }: {
      e: React.MouseEvent<HTMLElement>;
      issue: WorkItemLeveledInterface;
    }) => {
      e.persist();
      trackEvent({
        e,
        label: `${thisTestId}-issue-link-click`,
        value: `clicked ${issue.name}, url: ${issue.url}`
      });
    },
    [thisTestId, trackEvent]
  );

  const onClickHeader = useCallback(
    ({
      e,
      column
    }: {
      e: React.MouseEvent<HTMLElement>;
      column: ColumnInstance;
    }) => {
      let sortType,
        updatedSortedColumns: Array<{
          id: string;
          desc: boolean;
        }>;

      // order of sorting clicks is unsorted -> asc -> desc -> unsorted
      if (column.isSorted) {
        if (column.isSortedDesc) {
          updatedSortedColumns = [];
          sortType = "unsorted";
        } else {
          updatedSortedColumns = [{ id: column.id, desc: true }];
          sortType = "sort-desc";
        }
      } else {
        updatedSortedColumns = [{ id: column.id, desc: false }];
        sortType = "sort-asc";
      }

      e.persist();
      trackEvent({
        e,
        label: `epic-details-issues-list-${groupId}-${column.id}-header-sort`,
        value: `clicked ${column.id} header to ${sortType}`
      });
      updateUrlParams({
        [urlParamKeys.GROUP_DETAILS]: {
          [urlParamKeys.SORT]: updatedSortedColumns
        }
      });
    },
    [groupId, updateUrlParams, trackEvent]
  );

  const onShowCycleTimeTooltip = useCallback(
    ({
      e,
      issue
    }: {
      e: React.MouseEvent<HTMLElement>;
      issue: WorkItemInterface;
    }) => {
      e.persist();
      trackEvent({
        e,
        label: `epic-details-issues-list-cycle-time-mouse-over`,
        value: `mouse over ${issue.name} cycle time chart`
      });
    },
    [trackEvent]
  );

  const baseColumns: Array<any> = useMemo(() => {
    return [
      {
        accessor: "workItem",
        onClickHeader,
        Header: "Type",
        Cell: ({ cell }: { cell: TableCellInterface }) => {
          const {
            url,
            workItem,
            level,
            hasChildWithCallouts,
            callouts,
            repository
          } = cell.row.original;
          const activityType = snakeCase(workItem).toUpperCase();
          const link =
            workItem === workMetadataActivityTypes.PR ? (
              <InternalLink
                data-testid={`${thisTestId}-${kebabCase(
                  cell.row.original.name
                )}`}
                name={thisTestId}
                to={getWorkDeepDivePath({
                  selectedSection: workDeepDiveSections.PR_WORKFLOW,
                  persistQuerystring: true,
                  querystringParams: {
                    [urlParamKeys.REPOSITORIES]: !repository ? [] : [repository]
                  }
                })}
              >
                <LinkIcon icon="external-link" testId={thisTestId} />
              </InternalLink>
            ) : url ? (
              <IssueLink
                data-testid={`${thisTestId}-${kebabCase(
                  cell.row.original.name
                )}`}
                href={url}
                onClick={e => {
                  onClickIssueLink({ e, issue: cell.row.original });
                }}
                rel="noopener noreferrer"
                target="_blank"
                data-heap-redact-attributes="href"
              >
                <LinkIcon icon="external-link" testId={thisTestId} />
                <span className="visuallyHidden">{url}</span>
              </IssueLink>
            ) : null;
          return (
            <IssueIconWrapper
              hasCallouts={!!callouts.length}
              hasChildWithCallouts={hasChildWithCallouts}
              level={level}
              data-heap-redact-text="true"
            >
              <IconBackgroundColor
                hasCallouts={!!callouts.length}
                hasChildWithCallouts={hasChildWithCallouts}
              >
                <WorkMetadataIcon
                  type={
                    !!level && activityType === workMetadataActivityTypes.ISSUE
                      ? workMetadataActivityTypes.SUB_TASK
                      : activityType
                  }
                  testId={thisTestId}
                />
              </IconBackgroundColor>
              {link}
            </IssueIconWrapper>
          );
        },
        className: "activityType"
      },
      {
        accessor: "displayId",
        onClickHeader,
        Header: "#",
        Cell: ({ cell }: { cell: TableCellInterface }) => {
          const { workItem } = cell.row.original;
          return (
            <DisplayId>
              {workItem === workMetadataActivityTypes.PR
                ? `#${cell.value}`
                : cell.value}
            </DisplayId>
          );
        },
        className: "displayId"
      },
      {
        accessor: "name",
        onClickHeader,
        Header: "Title",
        Cell: ({ cell }: { cell: TableCellInterface }) => {
          const {
            cycleTime,
            originBranch,
            repository,
            targetBranch,
            url
          } = cell.row.original;
          // TODO: fixing a singular bug here (UL-5376), but if we see more
          // instances of this in other fields, we should probably investigate
          // unescaping unicode more universally at the ingestion level
          // adapted from here:
          // https://stackoverflow.com/questions/7885096/how-do-i-decode-a-string-with-escaped-unicode
          const name = cell.value.replace(/\\u([\d\w]{4})/gi, (match, group) =>
            decodeURIComponent(String.fromCharCode(parseInt(group, 16)))
          );

          const isPrDetails = customColumns?.find(i => i.id === "id");

          return (
            <>
              {isPrDetails ? (
                <ExternalLink
                  data-testid={`${thisTestId}-${kebabCase(name)}`}
                  href={url}
                  rel="noopener noreferrer"
                  target="_blank"
                  data-heap-redact-attributes="href"
                >
                  <span>{name}</span>
                  <LinkIcon icon="external-link" testId={thisTestId} />
                </ExternalLink>
              ) : (
                <Title data-heap-redact-text="true">{name}</Title>
              )}
              {!customColumns?.find(i => i.id === "id") && (
                <>
                  {repository && targetBranch && originBranch ? (
                    <PrSubstatus data-heap-redact-text="true">
                      <span>
                        {repository}: {targetBranch}
                      </span>
                      <ArrowIcon icon="line-arrow-left" testId={thisTestId} />
                      <span>{originBranch}</span>
                    </PrSubstatus>
                  ) : null}
                  <ChartCycleTime
                    cycleTime={cycleTime}
                    onMouseOver={(e: React.MouseEvent<HTMLElement>) =>
                      onShowCycleTimeTooltip({ e, issue: cell.row.original })
                    }
                    testId={`${thisTestId}-${cell.row.original.id}`}
                  />
                </>
              )}
            </>
          );
        },
        className: "title"
      },
      {
        accessor: "storyPoints",
        onClickHeader,
        Header: "Stry Pts",
        Cell: ({ cell }: { cell: TableCellInterface }) => (
          <StoryPoints>{cell.value}</StoryPoints>
        ),
        className: "storyPoints"
      },
      {
        accessor: "updatedAt",
        onClickHeader,
        Header: "Updated",
        Cell: ({ cell }: { cell: TableCellInterface }) => {
          return (
            <Updated>
              <Time
                format={
                  moment(cell.value).isSameOrAfter(moment().subtract(1, "year"))
                    ? "M/DD"
                    : "M/DD/YY"
                }
                timestamp={createTimestamp(cell.value)}
                testId={thisTestId}
              />
            </Updated>
          );
        },
        className: "updated"
      },
      {
        accessor: "statusDisplayText",
        onClickHeader,
        Header: `Status`,
        Cell: ({ cell }: { cell: TableCellInterface }) => (
          <Status>{startCase(cell.value)}</Status>
        ),
        className: "status"
      },
      {
        accessor: (row: PrsByRepositoryRow | TableRowInterface) =>
          has(row, "original")
            ? get(row, "original.callouts").length
            : get(row, "callouts").length,
        id: "callouts",
        onClickHeader,
        Header: "Callouts",
        Cell: ({ cell }: { cell: TableCellInterface }) => {
          const { displayId, callouts } = cell.row.original;
          return !!callouts.length ? (
            <ul>
              {callouts
                .map(calloutId => calloutMetadata[calloutId] as string)
                .sort()
                .map(callout => {
                  return (
                    <CalloutListItem key={`${displayId}-${kebabCase(callout)}`}>
                      <Callout>{callout}</Callout>
                    </CalloutListItem>
                  );
                })}
            </ul>
          ) : null;
        },
        className: "callouts"
      },
      {
        accessor: (row: PrsByRepositoryRow | TableRowInterface) =>
          has(row, "original")
            ? get(row, "original.owners[0].displayName")
            : get(row, "owners[0].displayName"),
        id: "owners",
        onClickHeader,
        Header:
          workDeepDiveParams.selectedSection ===
          workDeepDiveSections.PR_WORKFLOW
            ? "Author(s)"
            : "Owner(s)",
        Cell: ({ cell }: { cell: TableCellListColumnInterface }) => {
          const list = has(cell, "row.original")
            ? get(cell, "row.original.owners")
            : get(cell, "row.owners");
          return (
            <WorkMetadataTeamList
              list={list}
              maxNumAvatars={2}
              testId={thisTestId}
            />
          );
        },
        className: "owners"
      },
      {
        accessor: (row: PrsByRepositoryRow | TableRowInterface) =>
          has(row, "original")
            ? get(row, "original.contributors[0].displayName")
            : get(row, "contributors[0].displayName"),
        id: "contributors",
        onClickHeader,
        Header:
          workDeepDiveParams.selectedSection ===
          workDeepDiveSections.PR_WORKFLOW
            ? "Reviewers"
            : "Contributor(s)",
        Cell: ({ cell }: { cell: TableCellListColumnInterface }) => {
          const list = has(cell, "row.original")
            ? get(cell, "row.original.contributors")
            : get(cell, "row.contributors");
          return (
            <WorkMetadataTeamList
              list={list}
              maxNumAvatars={2}
              testId={thisTestId}
            />
          );
        },
        className: "contributors"
      },
      {
        accessor: "annotations",
        className: "annotations",
        Header: "Retro Reactions",
        Cell: ({
          cell
        }: {
          cell: TableCellInterface & {
            value: AnnotationsInterface[];
          };
        }) => {
          return (
            <UserAnnotationsCell>
              <ReactButtons>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={
                    <Popover id="popover-basic">
                      <ReactionPills
                        annotations={cell.value}
                        contextItem={cell.row.original}
                        showCount={false}
                        handleAnnotationClick={(
                          row: WorkItemLeveledInterface,
                          status: string,
                          event: React.SyntheticEvent
                        ) => {
                          handleAnnotationClick(row, status, event);
                        }}
                      />
                    </Popover>
                  }
                >
                  <div>
                    <ReactButton
                      hasReaction={
                        !isEmpty(
                          cell.value.filter(
                            anno =>
                              anno.userId === userId &&
                              anno.userDetailedComment === ""
                          )
                        )
                      }
                    >
                      <ReactIcon icon="thumbs-up" />
                      React
                    </ReactButton>
                  </div>
                </OverlayTrigger>
                <AnnotationsModal
                  context={cell.row.original}
                  annotations={cell.value}
                  teamMembers={teamMembers}
                  isModalOpen={activeIdToAnnotate === cell.row.original.id}
                  headerContent={
                    <WorkHeader>
                      <WorkTypeIcon
                        type={
                          snakeCase(
                            cell.row.original.workItem
                          ).toUpperCase() === workMetadataActivityTypes.ISSUE
                            ? workMetadataActivityTypes.SUB_TASK
                            : cell.row.original.workItem
                        }
                        testId={thisTestId}
                      />
                      <WorkDisplayId>
                        {cell.row.original.displayId}
                      </WorkDisplayId>
                      <WorkTitle>{cell.row.original.name}</WorkTitle>
                    </WorkHeader>
                  }
                  setActiveIdToAnnotate={(id: string) =>
                    setActiveIdToAnnotate(id)
                  }
                  handleAnnotationClick={(
                    row: WorkItemLeveledInterface,
                    status: string,
                    event: React.SyntheticEvent
                  ) => {
                    handleAnnotationClick(row, status, event);
                  }}
                  triggerWriteTextComment={(
                    row: WorkItemInterface,
                    textComment: string,
                    event: React.SyntheticEvent
                  ) => triggerWriteTextComment(row, textComment, event)}
                />
              </ReactButtons>
              <ReactionColumn>
                {toPairs(
                  countBy(cell.value, item => item.userAnnotationCheckinStatus)
                ).map((entry: [string, number]) => {
                  const iconToShow =
                    Object.entries(StatusIconMap).filter(
                      icon => icon[0] === entry[0]
                    )?.[0]?.[1] || "";
                  return (
                    iconToShow && (
                      <Tooltip
                        placement="top"
                        testId="annotations-modal-pill"
                        className="reaction-set"
                        key={entry[0]}
                        trigger={
                          <ReactionSet>
                            <ReactionIcon icon={iconToShow} />{" "}
                            <ReactionCount>{entry[1]}</ReactionCount>
                          </ReactionSet>
                        }
                        popupContent={
                          <ReactionTooltip
                            annotations={cell.value.filter(
                              anno =>
                                anno.userAnnotationCheckinStatus === entry[0]
                            )}
                            teamMembers={teamMembers}
                          />
                        }
                      />
                    )
                  );
                })}
                <div className="reaction-set">
                  <ReactionSet>
                    <ReactionIcon icon="comment" testId={thisTestId} />
                    <ReactionCount>
                      {
                        cell.value.filter(
                          annotation => annotation.userDetailedComment !== ""
                        ).length
                      }
                    </ReactionCount>
                  </ReactionSet>
                </div>
              </ReactionColumn>
            </UserAnnotationsCell>
          );
        }
      }
    ];
  }, [
    activeIdToAnnotate,
    customColumns,
    flags,
    getWorkDeepDivePath,
    handleAnnotationClick,
    onClickHeader,
    onClickIssueLink,
    onShowCycleTimeTooltip,
    workDeepDiveParams.selectedSection,
    thisTestId,
    triggerWriteTextComment,
    userId,
    teamMembers
  ]);

  const additionalColumns: Array<any> = useMemo(() => {
    return [
      {
        accessor: (row: PrsByRepositoryRow): string =>
          row.targetBranch as string,
        id: "id",
        onClickHeader,
        Header: "Branch / Cycle Time",
        Cell: ({ cell }: { cell: TableCellInterface }) => {
          const { cycleTime, originBranch, targetBranch } = cell.row.original;
          return (
            <>
              <Title data-heap-redact-text="true">
                {targetBranch && originBranch ? (
                  <PrSubstatus data-heap-redact-text="true">
                    <span>{targetBranch}</span>
                    <ArrowIcon icon="line-arrow-left" testId={thisTestId} />
                    <span>{originBranch}</span>
                  </PrSubstatus>
                ) : null}
              </Title>
              <ChartCycleTime
                cycleTime={cycleTime}
                onMouseOver={(e: React.MouseEvent<HTMLElement>) =>
                  onShowCycleTimeTooltip({ e, issue: cell.row.original })
                }
                testId={`${thisTestId}-${cell.row.original.id}`}
              />
            </>
          );
        },
        className: "id"
      },
      {
        accessor: "openedAt",
        onClickHeader,
        Header: "Opened",
        Cell: ({ cell }: { cell: TableCellInterface }) => {
          return (
            <Updated>
              <Time
                format={
                  moment(cell.value).isSameOrAfter(moment().subtract(1, "year"))
                    ? "M/DD"
                    : "M/DD/YY"
                }
                timestamp={createTimestamp(cell.value)}
                testId={thisTestId}
              />
            </Updated>
          );
        },
        className: "updated"
      },
      {
        accessor: (row: PrsByRepositoryRow): number =>
          row.complexity === "COMPLEX_TO_REVIEW" ? 1 : 0,
        id: "complexity",
        onClickHeader,
        Header: `Complexity`,
        Cell: ({ cell }: { cell: TableCellInterface }) => {
          return (
            <span>
              {
                prComplexityLevels[
                  cell.row.original.complexity as
                    | "NOT_COMPLEX_TO_REVIEW"
                    | "COMPLEX_TO_REVIEW"
                ]
              }
            </span>
          );
        },
        className: "status"
      },
      {
        accessor: (row: PrsByRepositoryRow): string =>
          (row.childWorkItems[0]?.name || "No Jira Ticket").toLowerCase(),
        id: "childWorkItems",
        onClickHeader,
        Header: `Related Jira Ticket(s)`,
        Cell: ({ cell }: { cell: TableCellInterface }) => {
          const relatedTickets = cell.row.original?.childWorkItems.filter(
            t => !!t.name
          );

          return relatedTickets?.length ? (
            <ul>
              {relatedTickets.map(t => {
                return (
                  <li key={t.id}>
                    {t.url ? (
                      <ExternalLink
                        data-testid={`${thisTestId}-${kebabCase(t.name)}`}
                        href={t.url}
                        rel="noopener noreferrer"
                        target="_blank"
                        data-heap-redact-attributes="href"
                      >
                        <span>{t.name}</span>
                        <LinkIcon icon="external-link" testId={thisTestId} />
                      </ExternalLink>
                    ) : (
                      <span>{t.name}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          ) : (
            <span>No Jira Tickets</span>
          );
        },
        className: "related-ticket"
      }
    ].filter(column => {
      return customColumns?.filter(col => col.id === column.accessor);
    });
  }, [customColumns, flags, onClickHeader, onShowCycleTimeTooltip, thisTestId]);

  const columns: Array<Column> = useMemo(() => {
    const combinedColumns = baseColumns;
    additionalColumns.forEach(col => {
      const columnToPush = customColumns?.find(
        i => i.id === (col.id || col.accessor)
      );
      if (columnToPush) {
        baseColumns.splice(columnToPush.position, 0, col);
      }
    });
    return combinedColumns;
  }, [additionalColumns, baseColumns, customColumns]);

  const hiddenColumns = useMemo(() => {
    const columns = [];
    if (!data.some(d => !isNil(d.storyPoints))) {
      columns.push("storyPoints");
    }
    if (!hasAnnotations) {
      columns.push("annotations");
    }
    if (
      workDeepDiveParams.selectedSection === workDeepDiveSections.PR_WORKFLOW
    ) {
      columns.push("storyPoints");
      columns.push("annotations");
      columns.push("displayId");
      columns.push("workItem");
      columns.push("updatedAt");
    }
    return columns;
  }, [data, hasAnnotations, workDeepDiveParams]);

  return (
    <Container className={className} data-testid={thisTestId}>
      <ContentWrapper>
        {showHeader && (
          <HeaderContainer>
            <Header>
              {!isUnlinkedPrs && "Jira Tickets and Associated "} PRs
            </Header>
            <StyledShowRelatedItems />
          </HeaderContainer>
        )}
        <IssuesList
          columns={columns}
          getRowProps={(row: TableRowInterface) => ({
            className: classNames({
              subRow: row.original.level > 0,
              highlightedRow: !!row.original.callouts.length
            })
          })}
          hiddenColumns={hiddenColumns}
          list={data}
          manuallySort={manuallySort}
          sortBy={sort}
          testId={thisTestId}
        />
      </ContentWrapper>
    </Container>
  );
};

export default EpicDetailsIssuesList;
