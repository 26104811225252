import React from "react";
import styled from "styled-components/macro";

// components
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// hooks
import { PopupPlacements } from "../../interfaces/controls";

// styled components
const Button = styled.button`
  background: 0;
  border: none;
  margin: 0;
  padding: 0;
  line-height: 1em;
`;
// this is mostly unnecessary but OverlayTrigger wants an element that can
// accept refs
const OverlayWrapper = styled.div`
  display: block;
`;

// typescript props
type Props = {
  className?: string;
  overlayContent: React.ReactNode;
  overlayPlacement?: PopupPlacements;
  onToggleShow: (nextShow: boolean) => void;
  show: boolean;
  testId?: string;
  triggerAction?:
    | "hover"
    | "click"
    | "focus"
    | Array<"hover" | "click" | "focus">;
  triggerContent: React.ReactNode;
};

const TriggeredOverlay = ({
  className,
  overlayContent,
  overlayPlacement = "bottom",
  show,
  onToggleShow,
  triggerAction = ["hover", "focus"],
  triggerContent,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-triggered-overlay`;
  return (
    <OverlayTrigger
      trigger={triggerAction}
      placement={overlayPlacement}
      overlay={<OverlayWrapper>{overlayContent}</OverlayWrapper>}
      onToggle={onToggleShow}
      rootClose={true}
      rootCloseEvent="mousedown"
      show={show}
    >
      <Button className={className} data-testid={`${thisTestId}-trigger`}>
        {triggerContent}
      </Button>
    </OverlayTrigger>
  );
};

export default TriggeredOverlay;
