import { defaults } from "lodash";

// constants
import { groupByTypes } from "../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { requestUserSettings } from "../utils/settings";

export const useUserSettings = () => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const userHasUplevelAccount = useSelector(
    (state: AppStateInterface) => state.session.userHasUplevelAccount
  );

  return useQuery(
    "user-settings",
    () =>
      requestUserSettings({
        accessToken,
        tenantId
      }).then(userSettings =>
        // supply defaults after fetching user's settings
        defaults(userSettings, {
          defaultJiraGroupBy: groupByTypes.EPIC
        })
      ),
    {
      staleTime: Infinity,
      enabled: !!accessToken && !!tenantId && !!userHasUplevelAccount
    }
  );
};
