import React from "react";
import styled from "styled-components/macro";

// components
import HtmlText from "../HtmlText/HtmlText";
import Icon from "../Icon/Icon";
import InfoBook from "../InfoBook/InfoBook";
import InfoPopup from "../InfoPopup/InfoPopup";

// constants
import informationText from "../../constants/peopleHealthUnderstandability.json";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { SelectablePeopleMetrics } from "../../interfaces/team-deep-dive";
import { healthFactorsMetadata } from "../../constants/constants";

const Container = styled.div`
  display: flex;
`;
const PopupContent = styled.div`
  font-size: ${props => props.theme.fonts.header.sizes.xs};
`;
const PopupHeader = styled.h4`
  font-family: ${props => props.theme.fonts.header.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.header.sizes.xs};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
  margin: 0;
`;
const StyledInfoPopup = styled(InfoPopup)`
  margin-left: 0.8rem;
`;
const Book = styled(InfoBook)`
  margin-left: 0.8rem;
`;
const LinkIcon = styled(Icon)`
  color: inherit;
  margin-right: 0.5rem;
`;
const LearnLink = styled.a`
  align-items: center;
  display: flex;
  && {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    text-decoration: none;

    & ${LinkIcon} {
      color: inherit;
    }
  }
`;

type Props = {
  className?: string;
  testId?: string;
  selectedMetric:
    | SelectablePeopleMetrics
    | "MEETING_DISTRIBUTION"
    | "MEETING_LENGTH";
  canSeeAlwaysOnData?: boolean;
};

export const InfoPopups = ({
  className,
  testId = "testId",
  selectedMetric,
  canSeeAlwaysOnData
}: Props) => {
  const thisTestId = `${testId}-info-popups`;
  const { trackEvent } = useTracking();
  function onClickLearnLink(e: React.MouseEvent<HTMLElement>) {
    trackEvent({
      e,
      label: `${testId}-learn-more-link`,
      value: "link clicked"
    });
  }

  const isMeetingPopup =
    selectedMetric === "MEETING_DISTRIBUTION" ||
    selectedMetric === "MEETING_LENGTH";
  return (
    <Container>
      <StyledInfoPopup
        action={isMeetingPopup ? "hover" : "click"}
        header={
          <PopupHeader>
            {isMeetingPopup
              ? `Who can see this chart?`
              : `Why ${
                  selectedMetric === "CHAT_INTERRUPTIONS" ? "are" : "is"
                } ${healthFactorsMetadata[selectedMetric]?.title} important?`}
          </PopupHeader>
        }
        content={
          <PopupContent>
            <HtmlText
              htmlText={informationText[selectedMetric].why.text}
              testId={testId}
            />
            {informationText[selectedMetric].why.link.enabled && (
              <LearnLink
                href={informationText[selectedMetric].why.link.url}
                onClick={onClickLearnLink}
                rel="noopener noreferrer"
                target="_blank"
                data-testid={`${testId}-${selectedMetric}-learn-more-link`}
              >
                <LinkIcon icon="external-link" testId={testId} />
                {`Read more about ${informationText[selectedMetric].why.link.text}`}
              </LearnLink>
            )}
          </PopupContent>
        }
        name={selectedMetric}
        testId={testId}
      />
      <Book
        content={
          selectedMetric === "OVERALL_TEAM_HEALTH" && !canSeeAlwaysOnData
            ? informationText[selectedMetric].what.textNoAlwaysOn
            : informationText[selectedMetric].what.text
        }
        header={`How ${
          selectedMetric === "CHAT_INTERRUPTIONS" ? "are" : "is"
        } ${healthFactorsMetadata[selectedMetric]?.title} defined?`}
        link={
          informationText[selectedMetric].what.link.enabled
            ? {
                ...informationText[selectedMetric].what.link,
                content: `Read more about ${informationText[selectedMetric].what.link.text}`
              }
            : null
        }
        name={selectedMetric}
        testId={thisTestId}
      />
    </Container>
  );
};

export default InfoPopups;
