import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components/macro";

// components
import TextBlock from "../TextBlock/TextBlock";

// styled components
const StyledHeader = styled.div`
  font-family: ${props => props.theme.fonts.header.name}, "Helvetica Neue",
    "Aria", sans-serif;
  font-weight: ${props => props.theme.fonts.header.weights.extrabold};
  margin: 0;
  padding: 0;
`;

// exporting to target in tests
export const Header = ({
  children,
  className,
  color,
  hasBorder = false,
  header,
  isUppercase = false,
  size,
  style,
  testId,
  theme,
  weight
}) => {
  if (!children || !header || !testId) {
    return null;
  }

  return (
    <StyledHeader
      as={`h${header}`}
      data-testid={`${testId}-header`}
      style={style}
    >
      <TextBlock
        className={className}
        color={color || theme.colors.text.strong}
        hasBorder={hasBorder}
        isUppercase={isUppercase}
        size={size}
        testId={testId}
        weight={weight || "extraBold"}
      >
        {children}
      </TextBlock>
    </StyledHeader>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  hasBorder: PropTypes.bool,
  isUppercase: PropTypes.bool,
  header: PropTypes.oneOf(["1", "2", "3", "4", "5", "6"]).isRequired,
  size: PropTypes.string,
  style: PropTypes.object,
  testId: PropTypes.string.isRequired,
  weight: PropTypes.string
};
export default withTheme(Header);
