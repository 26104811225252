import React from "react";
import styled from "styled-components/macro";
import { capitalize, kebabCase, startCase } from "lodash";

// components
import Icon from "../Icon/Icon";
import InfoIcon from "../InfoIcon/InfoIcon";
import SignalTypeContainer from "../SignalTypeContainer/SignalTypeContainer";

// constants
import { signalTypes, healthFactorsMetadata } from "../../constants/constants";
import { getSprintRangeString } from "../../utils/sprints";

// styled components
const Container = styled(SignalTypeContainer)`
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  border-left-color: transparent;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;
const FactorsListItem = styled.li`
  align-items: flex-start;
  display: flex;
  & + & {
    margin-top: 0.5rem;
  }
`;
const InlineIcon = styled(Icon)`
  margin-right: 0.5rem;
  height: 1em;
`;
const FactorsHeader = styled.header`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const FactorName = styled.header`
  align-items: baseline;
  display: flex;
  font-weight: bold;
`;
const Score = styled.div`
  align-items: baseline;
  display: flex;
`;

// typescript props
type Props = {
  className?: string;
  factors: { [key: string]: number };
  header: React.ReactNode;
  sectionType: string;
  testId?: string;
};

const RetrosFactorsList = ({
  className,
  factors,
  header,
  sectionType,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-factors-list`;
  const factorsArray = Object.entries(factors).sort();
  const isUnhealthyFactors = sectionType === signalTypes.UNHEALTHY;

  return (
    <Container
      className={className}
      header={
        <FactorsHeader>
          {header}
          <span>
            :{" "}
            {`${factorsArray.length} ${
              isUnhealthyFactors ? "low" : "positive"
            } score${factorsArray.length === 1 ? "" : "s"}`}
          </span>
        </FactorsHeader>
      }
      content={
        !!factorsArray.length ? (
          <ul>
            {factorsArray.map((f: [string, number]) => {
              const factorSlug = `retros-factor-${kebabCase(f[0])}` as
                | "retros-factor-always-on"
                | "retros-factor-context-switching"
                | "retros-factor-deep-work"
                | "retros-factor-issues-added"
                | "retros-factor-issues-carryover"
                | "retros-factor-issues-closed"
                | "retros-factor-issues-with-descriptions"
                | "retros-factor-issues-with-story-points"
                | "retros-factor-jira-bugs"
                | "retros-factor-prs-merged-no-approval"
                | "retros-factor-prs-no-reviewers"
                | "retros-factor-prs-stuck"
                | "retros-factor-chat-interruptions";
              const factorMetadata = healthFactorsMetadata[f[0]];

              return (
                <FactorsListItem key={factorSlug}>
                  <InlineIcon
                    icon={
                      isUnhealthyFactors ? "warning-outlined" : "circle-check"
                    }
                    testId={factorSlug}
                  />
                  <div>
                    <FactorName>
                      {factorMetadata.title}
                      <InfoIcon content={factorSlug} testId={factorSlug} />
                    </FactorName>
                    <Score>
                      {capitalize(startCase(getSprintRangeString(f[1])))}
                    </Score>
                  </div>
                </FactorsListItem>
              );
            })}
          </ul>
        ) : null
      }
      isExpandable={!!factorsArray.length}
      testId={thisTestId}
      type={isUnhealthyFactors ? signalTypes.UNHEALTHY : signalTypes.HEALTHY}
    />
  );
};

export default RetrosFactorsList;
