import { getMeetingClassificationsTooltipHtml } from "../../utils/chart";
import { sortBy, uniqBy } from "lodash";
import { bgToFgColor } from "../../utils/color";

const config = ({ data = {}, selectedTeamMember = {}, theme }) => {
  const allClassifications = Object.values(data);
  const allValuesAreTheSame = uniqBy(allClassifications, "value").length === 1;
  const colors = theme.colors.chart.meetingClassifications;
  const series = [
    ...sortBy(allClassifications, "key").map(d => {
      const bg = colors[d.key];
      return {
        name: d.classificationName,
        clip: false,
        color: bg,
        dataLabels: {
          color: bgToFgColor(bg)
        },
        data: [d]
      };
    })
  ];
  return {
    chart: {
      style: {
        fontFamily: theme.fonts.primary.name
      }
    },
    title: null,
    tooltip: {
      formatter: function() {
        return getMeetingClassificationsTooltipHtml({
          ...this.point,
          ...selectedTeamMember
        });
      }
    },
    legend: {
      align: "right",
      enabled: true,
      itemMarginBottom: 5,
      itemMarginTop: 5,
      itemStyle: {
        fontSize: theme.fonts.primary.sizes.xs,
        fontWeight: theme.fonts.primary.weights.regular,
        textOverflow: undefined
      },
      itemWidth: 175,
      layout: "vertical",
      padding: 3,
      verticalAlign: "top"
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          useHTML: true,
          allowOverlap: true,
          formatter: function() {
            const point = this.point;

            // radius was determined by finding width of largest non-wrapping text manually
            if (point.radius < 61) {
              return `<div class="marker"><div class="value" style="font-size: ${point.value +
                10}px">${point.value.toFixed(1)}%</div></div>`;
            }
            return `<div class="marker"><header><span>${
              point.classificationName
            }</span></header><div class="value">${point.value.toFixed(
              1
            )}%</div></div>`;
          },
          style: {
            color: theme.colors.all.wolverine,
            textOutline: "none"
          }
        }
      },
      packedbubble: {
        animation: false,
        minSize: 35,
        maxSize: allValuesAreTheSame ? 100 : 200,
        draggable: false,
        layoutAlgorithm: {
          enableSimulation: false,
          gravitationalConstant: 0.02,
          maxIterations: 300,
          maxSpeed: 2
        },
        marker: {
          fillOpacity: 1,
          lineColor: theme.colors.border,
          lineWidth: 2
        }
      }
    },
    series
  };
};
export default config;
