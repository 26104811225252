import { isNil, isUndefined } from "lodash";
import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// components
import HtmlText from "../HtmlText/HtmlText";
import Icon from "../Icon/Icon";
import InfoPopup from "../InfoPopup/InfoPopup";

// styled components
const PopupContent = styled.div`
  font-size: ${props => props.theme.fonts.header.sizes.xs};
`;
const PopupHeader = styled.h4`
  font-family: ${props => props.theme.fonts.header.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.header.sizes.xs};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
  margin: 0;
`;
const LinkIcon = styled(Icon)`
  color: inherit;
  margin-right: 0.5rem;
`;
const LearnLink = styled.a`
  align-items: center;
  display: flex;
  && {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    text-decoration: none;

    & ${LinkIcon} {
      color: inherit;
    }
  }
`;

// typescript props
type Actions = "hover" | "click";
type Props = {
  action?: Actions;
  className?: string;
  content: string;
  header?: React.ReactNode;
  link?: {
    content: React.ReactNode;
    url: string;
  } | null;
  name: string;
  testId?: string;
};

const InfoBook = ({
  action = "click",
  className,
  content,
  header,
  link,
  name,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-info-book`;
  return (
    <InfoPopup
      action={action}
      header={
        isUndefined(header) ? header : <PopupHeader>{header}</PopupHeader>
      }
      content={
        <PopupContent>
          <HtmlText htmlText={content} testId={testId} />
          {isNil(link) ? null : (
            <LearnLink
              href={link.url}
              rel="noopener noreferrer"
              target="_blank"
              data-testid={`${thisTestId}-${name}-learn-more-link`}
            >
              <LinkIcon icon="external-link" testId={thisTestId} />
              {link.content}
            </LearnLink>
          )}
        </PopupContent>
      }
      name={name}
      icon="book"
      className={className}
      testId={thisTestId}
    />
  );
};

export default InfoBook;
