import React from "react";
import styled from "styled-components/macro";
import { chunk, isNull, isUndefined, partition } from "lodash";

// components
import EmptyState from "../EmptyState/EmptyState";
import LoadingStates from "../LoadingStates/LoadingStates";
import SettingsTeamBuilderGrid from "../SettingsTeamBuilderGrid/SettingsTeamBuilderGrid";

// hooks
import { useTeamsData } from "../../hooks/useTeamsData";
import { useSelector } from "react-redux";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { TeamInterface } from "../../interfaces/team";
import { useOrganizationData } from "../../hooks/useOrganizationData";

// constants
const COLS_PER_ROW = 4;

// styled components
const Container = styled.div`
  padding: 3rem 0;
`;
const Grid = styled(SettingsTeamBuilderGrid)`
  & + & {
    margin-top: 5rem;
  }
`;
const CardHeader = styled.h2`
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  margin: 0;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const SettingsTeamBuilder = ({ className, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-settings-team-builder`;
  const user = useSelector((state: AppStateInterface) => state.user);
  const { data: ownOrgChartRootTeam } = useOrganizationData();
  const { data: ownTeams, isError, isLoading } = useTeamsData(
    {
      type: "own-teams",
      teamMembersToRequest: ownOrgChartRootTeam?.teamMembers || [user]
    },
    // wait to fetch teams until we have dependencies lined up
    !!user?.id
  );
  const [orgChartTeams, flexTeams] = partition(
    ownTeams,
    (t: TeamInterface) => t.isOrgChartTeam
  );

  return (
    <Container className={className} data-testid={thisTestId}>
      <LoadingStates
        isSpinningState={isLoading}
        isNoDataState={isError}
        noDataContent={
          <EmptyState
            body="There was an error fetching your teams."
            header="Hmmm."
            testId={testId}
          />
        }
        content={
          <>
            {!!orgChartTeams ? (
              <Grid
                header={
                  <CardHeader id="header-org-teams">
                    Your Organization Teams
                  </CardHeader>
                }
                teams={chunk(orgChartTeams, COLS_PER_ROW)}
                testId={thisTestId}
              />
            ) : null}
            {!!flexTeams ? (
              <Grid
                header={
                  <>
                    <CardHeader id="header-create-team">
                      Create a new team
                    </CardHeader>
                    <CardHeader id="header-flex-teams">Your Teams</CardHeader>
                  </>
                }
                teams={chunk([null, ...flexTeams], COLS_PER_ROW)}
                testId={thisTestId}
              />
            ) : null}
          </>
        }
        testId={thisTestId}
      />
    </Container>
  );
};

export default SettingsTeamBuilder;
