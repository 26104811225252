import { compact, flattenDeep, isNull, partition } from "lodash";

export default ({ data, userDetails }) => {
  // create flat array of all tickets with owner names injected
  const allTicketsWithOwnerNames = flattenDeep(
    compact(
      data.resultsByUser.map(result => {
        const { metaData, userId } = result;
        const user = userDetails.find(u => u.id === userId);

        // if metadata is null, this user doesn't have any
        // tickets assigned, so return null for the entire user
        // so that they can be compacted out of the tickets array
        //
        // changing the team can call the parser before new data is fetched,
        // in this case user will be undefined and should return null
        if (isNull(metaData) || !user) {
          return null;
        }

        // map through every issue and inject the owner's name, then
        // return the mapped array
        return metaData.map(m => ({
          ...m,
          owner: user.name
        }));
      })
    )
  );

  // partition the tickets into 2 arrays based on whether story points is null
  // 0 is a valid story points value!!
  const [
    ticketsWithoutStoryPoints,
    ticketsWithStoryPoints
  ] = partition(allTicketsWithOwnerNames, t => isNull(t.storyPoints));

  return {
    ...data,
    ticketsWithStoryPoints,
    ticketsWithoutStoryPoints
  };
};
