// hooks
import { useSelector } from "react-redux";
import { useQuery, UseQueryResult } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { requestFullOrganization } from "../utils/user";
import {
  UserReportWithMetadataInterface,
  UserReportWithUplevelRole
} from "../interfaces/user";

export const useFullOrganizationData = (): UseQueryResult<Array<
  UserReportWithUplevelRole
>> => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const userHasUplevelAccount = useSelector(
    (state: AppStateInterface) => state.session.userHasUplevelAccount
  );

  // all members of organization
  return useQuery(
    "full-organization",
    () =>
      requestFullOrganization({
        accessToken,
        tenantId
      }) as Promise<Array<UserReportWithMetadataInterface>>,
    {
      enabled: !!accessToken && !!tenantId && !!userHasUplevelAccount,
      refetchOnWindowFocus: false
    }
  );
};
