import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

// components
import BorderedBox from "../BorderedBox/BorderedBox";

// styled components
const StyledTextBlock = styled.div`
  color: ${props => props.color || props.theme.colors.text.body};
  font-size: ${props =>
    props.theme.fonts.primary.sizes[props.size] ||
    props.theme.fonts.primary.sizes.md};
  font-weight: ${props =>
    props.theme.fonts.primary.weights[props.weight] ||
    props.theme.fonts.primary.weights.regular};
  margin: 0;
  padding: 0;

  ${props =>
    props.isUppercase &&
    `
      text-transform: uppercase;
    `}
`;

const TextBlock = ({
  children,
  className,
  color,
  hasBorder = false,
  isUppercase = false,
  size,
  testId,
  weight,
  ...otherProps
}) => {
  if (!children || !testId) {
    return null;
  }

  const props = {
    ...otherProps,
    className,
    color,
    "data-testid": `${testId}-text-block`,
    isUppercase,
    size,
    weight
  };

  return (
    <StyledTextBlock {...props}>
      {hasBorder ? (
        <BorderedBox location="bottom" testId={testId}>
          {children}
        </BorderedBox>
      ) : (
        children
      )}
    </StyledTextBlock>
  );
};

TextBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  color: PropTypes.string,
  hasBorder: PropTypes.bool,
  isUppercase: PropTypes.bool,
  size: PropTypes.oneOf(["lg", "md", "sm", "xs"]),
  testId: PropTypes.string.isRequired,
  weight: PropTypes.oneOf([
    "extraLight",
    "light",
    "regular",
    "bold",
    "extraBold",
    "black"
  ])
};
export default TextBlock;
