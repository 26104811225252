import React, { ReactElement } from "react";
import styled from "styled-components";
import { isNil } from "lodash";

const Container = styled.div`
  background: ${props => props.theme.colors.all.jean};
  border-radius: 40%;
  height: 2rem;
  width: 2rem;
  text-align: center;
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  font-size: ${props => props.theme.fonts.subheader.sizes.xs};
`;

type Props = {
  testId?: string;
  className?: string;
  storyPoints?: number;
};

export const StoryPoints = ({
  testId = "testId",
  className,
  storyPoints
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-story-points`;
  return isNil(storyPoints) ? null : (
    <Container className={className} data-testid={thisTestId}>
      {storyPoints}
    </Container>
  );
};

export default StoryPoints;
