import React from "react";
import styled from "styled-components/macro";

// components
import SlackAvatar from "../SlackAvatar/SlackAvatar";
import { UserReportInterface } from "../../interfaces/user";

// styled components
const Container = styled.section`
  border: ${props =>
    `${props.theme.borders.widths.md} solid ${props.theme.colors.border}`};
  display: flex;
  padding: 1rem;
  width: 25rem;
`;
const AvatarWrapper = styled.div`
  margin-right: 1rem;
  max-width: 9.9rem;
`;
const Avatar = styled(SlackAvatar)`
  border-radius: 50%;
  height: 6.3rem;
  width: 6.3rem;
  border: ${props => `.2rem solid ${props.theme.colors.border}`};
`;
const Name = styled.header`
  font-family: ${props => props.theme.fonts.header.name}, serif;
  font-size: ${props => props.theme.fonts.header.sizes.sm};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
  margin-bottom: 0.5rem;
`;

// typescript props
type Props = {
  className?: string;
  metadata?: React.ReactNode;
  person: UserReportInterface;
  testId?: string;
};

export const PersonCard = ({
  className,
  metadata = null,
  person,
  testId = "testId"
}: Props) => {
  const { image, name } = person;

  return (
    <Container className={className} data-testid={`${testId}-person-card`}>
      <AvatarWrapper>
        <Avatar
          altText={`${name} image`}
          src={image}
          testId={`${testId}-person-card`}
        />
      </AvatarWrapper>
      <div data-heap-redact-text="true">
        <Name>{name}</Name>
        {metadata}
      </div>
    </Container>
  );
};

export default PersonCard;
