import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// components
import ChartOperationalMetricsCycleTime from "../ChartOperationalMetricsCycleTime/ChartOperationalMetricsCycleTime";
import OperationalMetricsCycleTimeBreakoutStage from "../OperationalMetricsCycleTimeBreakoutStage/OperationalMetricsCycleTimeBreakoutStage";

// constants
import { cycleTimeStages } from "../../constants/constants";

// interfaces
import { CycleTimeStageTypes } from "../../interfaces/constants";
import { CycleTimeInterface } from "../../interfaces/work-items";
import {
  OpMetricData,
  OpMetricSprintRecord
} from "../../interfaces/ops-metrics";

// utils
import { getMetricMetadata } from "../../utils/operational-metrics";

// styled components
const Container = styled.div`
  position: relative;
`;
const BreakoutContainer = styled.ul`
  display: flex;
`;

const ProgressBar = styled.figure`
  width: 100%;
`;
const Chart = styled(ChartOperationalMetricsCycleTime)`
  width: 100%;
`;

// typescript props
type Props = {
  all: OpMetricData;
  className?: string;
  current: OpMetricSprintRecord;
  previous?: OpMetricSprintRecord;
  previousTimerangeHeader: React.ReactNode;
  testId?: string;
};

const OperationalMetricsCycleTimeBreakout = ({
  all,
  className,
  current,
  previous,
  previousTimerangeHeader,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-ops-metrics-cycle-time-breakout`;
  const currentCycleTime = getMetricMetadata(
    current,
    "cycleTime"
  ) as CycleTimeInterface;
  return (
    <Container className={className} data-testid={thisTestId}>
      <BreakoutContainer>
        {([
          cycleTimeStages.DEV,
          cycleTimeStages.WAITING_FOR_REVIEW,
          cycleTimeStages.REVIEW,
          cycleTimeStages.RELEASE
        ] as Array<keyof CycleTimeStageTypes>).map(stageKey => (
          <OperationalMetricsCycleTimeBreakoutStage
            key={stageKey}
            all={all}
            current={current}
            previous={previous}
            previousTimerangeHeader={previousTimerangeHeader}
            stageKey={stageKey}
            testId={thisTestId}
          />
        ))}
      </BreakoutContainer>
      <ProgressBar>
        <Chart data={currentCycleTime} testId={thisTestId} />
        <figcaption className="visuallyHidden">
          Operational metrics cycle time chart
        </figcaption>
      </ProgressBar>
    </Container>
  );
};

export default OperationalMetricsCycleTimeBreakout;
