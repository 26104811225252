import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { capitalize, compact, isNil, pick } from "lodash";

// components
import Button from "../Button/Button";
import InfoIcon from "../InfoIcon/InfoIcon";
import Icon from "../Icon/Icon";
import InfoPopupContent from "../InfoPopupContent/InfoPopupContent";
import TriggeredOverlay from "../TriggeredOverlay/TriggeredOverlay";

// constants
import {
  overviewHealthTypes,
  healthFactorsMetadata,
  healthScoreRanges,
  healthScoreTypes
} from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { SprintHealthDataPointInterface } from "../../interfaces/sprint-metadata";
import { AppStateInterface } from "../../interfaces/app-state";

// utils
import { getSprintRangeString } from "../../utils/sprints";

// styled
const Header = styled.h3`
  align-items: center;
  display: flex;
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  margin-bottom: 2rem;
`;
const LinkList = styled.ul`
  align-items: center;
  display: flex;
`;
const LinkListItem = styled.li<{ isActive: boolean }>`
  border-bottom: ${props =>
    `${props.theme.borders.widths.md} solid ${
      props.isActive ? props.theme.colors.all.wolverine : "transparent"
    }`};
  font-weight: ${props =>
    props.theme.fonts.primary.weights[props.isActive ? "bold" : "regular"]};
  padding: 0;

  & + & {
    margin-left: 2rem;
  }
`;
const Link = styled(Button)<{ isActive: boolean }>`
  font-weight: ${props =>
    props.theme.fonts.primary.weights[props.isActive ? "demiBold" : "regular"]};
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    & > span {
      color: inherit;
    }
  }
`;
const SprintHealthFactorsContainer = styled.div`
  margin-top: 1rem;
  height: 12.8rem;
`;
const SprintHealthFactorsGrid = styled.div<{
  hasPeopleHealthData: boolean;
  sprintHealthFactorSet: string;
}>`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(
    ${props => (props.sprintHealthFactorSet === "projects" ? 3 : 2)},
    1fr
  );
  grid-template-rows: repeat(
    ${props =>
      props.sprintHealthFactorSet === "projects"
        ? 3
        : props.sprintHealthFactorSet === "overview"
        ? 1
        : 2},
    1fr
  );
  grid-auto-flow: column;

  ${props => {
    if (props.sprintHealthFactorSet === "overview") {
      return props.hasPeopleHealthData
        ? css`
            grid-auto-flow: row;

            & div:first-child {
              grid-column: 1 / 3;
            }
          `
        : css`
            grid-auto-flow: row;

            & div {
              grid-column: 1 / 3;
            }
          `;
    }
  }}
`;
const SprintHealthPill = styled.div<{ sprintHealthScoreType: string }>`
  display: flex;
  background-color: ${props =>
    props.theme.colors.signalHealth[props.sprintHealthScoreType]};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  height: 3rem;
`;
const Overlay = styled(TriggeredOverlay)`
  align-items: center;
  display: flex;
  flex: 1;
`;
const HealthPillWrapper = styled.div`
  flex: 1;
`;
const StyledIcon = styled(Icon)`
  margin-left: 1rem;
  color: ${props => props.theme.colors.all.wolverine};
`;
const TooltipText = styled.div`
  white-space: pre-wrap;
`;
const SprintHealthFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.wolverine}`};
  padding-top: 1rem;
  margin-top: auto;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const FooterPill = styled.div<{ sprintHealthScoreType: string }>`
  margin-left: 1rem;
  margin-right: 1rem;
  height: ${props => props.theme.fonts.primary.sizes.xs};
  width: ${props => props.theme.fonts.primary.sizes.xs};
  background-color: ${props =>
    props.theme.colors.signalHealth[props.sprintHealthScoreType]};
`;

// typescript props
type Props = {
  className?: string;
  data?: SprintHealthDataPointInterface | null;
  isActiveSprint?: boolean;
  testId?: string;
};

const RetrosSprintHealthFactors = ({
  className,
  data,
  isActiveSprint,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-retros-sprint-health-factors`;
  const [activePill, setActivePill] = useState<string | null>(null);
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const hasPeopleHealthData = flags?.["people-health-insights"];
  const [sprintHealthFactorSet, setSprintHealthFactorSet] = useState(
    hasPeopleHealthData ? "overview" : "projects"
  );
  const { trackEvent } = useTracking();

  function onClickFactorSet(
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    factorSet: string
  ) {
    setSprintHealthFactorSet(factorSet);
    trackEvent({
      e,
      label: `retros-sprint-health-factors-${factorSet}`,
      value: `clicked`
    });
  }

  if (isNil(data)) {
    return null;
  }

  const chartData = (sprintHealthFactorSet === "overview"
    ? pick(data, Object.values(overviewHealthTypes))
    : sprintHealthFactorSet === "people"
    ? data.peopleHealthFactors
    : data.projectHealthFactors) as {
    [key: string]: number;
  };
  const sortedAndFilteredChartData: Array<Array<
    string | number
  >> = (sprintHealthFactorSet === "overview"
    ? compact([
        hasPeopleHealthData
          ? [
              overviewHealthTypes.AVERAGE_HEALTH,
              chartData[overviewHealthTypes.AVERAGE_HEALTH]
            ]
          : null,
        hasPeopleHealthData
          ? [
              overviewHealthTypes.PEOPLE_HEALTH,
              chartData[overviewHealthTypes.PEOPLE_HEALTH]
            ]
          : null,
        [
          overviewHealthTypes.PROJECT_HEALTH,
          chartData[overviewHealthTypes.PROJECT_HEALTH]
        ]
      ])
    : Object.entries(chartData).sort((a, b) => b[1] - a[1])
  ).filter(d => !isNil(d[1]));

  function getHealthPillText(sprintHealthFactorSet: string, key: string) {
    if (
      sprintHealthFactorSet !== "overview" ||
      key === overviewHealthTypes.AVERAGE_HEALTH
    )
      return healthFactorsMetadata[key].title;

    const signals = Object.values(
      (key === overviewHealthTypes.PEOPLE_HEALTH
        ? data?.peopleHealthFactors
        : data?.projectHealthFactors) || {}
    ).filter((s: number | null) => !isNil(s));
    const healthySignals = signals.filter(
      score => score && score >= healthScoreRanges[healthScoreTypes.GOOD][0]
    );

    const factorSet =
      key === overviewHealthTypes.PEOPLE_HEALTH ? "people" : "projects";
    return (
      <>
        {capitalize(factorSet).concat(
          " (",
          healthySignals.length.toString(),
          "/",
          signals.length.toString(),
          " healthy signals)"
        )}
        <StyledIcon icon={"line-arrow-right"} />
      </>
    );
  }
  function getTooltipText(score: number) {
    return (
      <TooltipText>
        {capitalize(getSprintRangeString(score).toLowerCase())}
      </TooltipText>
    );
  }

  function onShowTooltip(nextShow: boolean) {
    trackEvent({
      label: "retro-sprint-health",
      value: `${!!nextShow ? "showed" : "hid"} sprint health from hover`
    });
  }

  const tooltipContent = `retros-sprint-health${
    !isActiveSprint ? "-final" : ""
  }`;

  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        Sprint Health{" "}
        <InfoIcon
          content={
            tooltipContent as
              | "retros-sprint-health"
              | "retros-sprint-health-final"
          }
          testId={thisTestId}
        />
      </Header>
      <nav aria-label="Sprint health factors options">
        <LinkList>
          {compact([
            hasPeopleHealthData ? "overview" : null,
            hasPeopleHealthData ? "people" : null,
            "projects"
          ]).map((factorSetId: string) => {
            const isActive = factorSetId === sprintHealthFactorSet;
            return (
              <LinkListItem isActive={isActive} key={factorSetId}>
                <Link
                  button="unstyled"
                  isActive={isActive}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => onClickFactorSet(e, factorSetId)}
                  testId={`sprint-health-factor-set-${factorSetId}`}
                >
                  {capitalize(factorSetId)}
                </Link>
              </LinkListItem>
            );
          })}
        </LinkList>
      </nav>
      <SprintHealthFactorsContainer>
        <SprintHealthFactorsGrid
          hasPeopleHealthData={hasPeopleHealthData}
          sprintHealthFactorSet={sprintHealthFactorSet}
        >
          {sortedAndFilteredChartData.map(entry => {
            const key = entry[0] as string;
            const score = entry[1] as number;

            return (
              <SprintHealthPill
                sprintHealthScoreType={getSprintRangeString(score)}
                key={key}
              >
                <Overlay
                  key={`${thisTestId}-${key}`}
                  overlayContent={
                    <InfoPopupContent
                      content={getTooltipText(score)}
                      testId={`${thisTestId}-${key}-overlay-content`}
                    />
                  }
                  show={activePill === key}
                  onToggleShow={onShowTooltip}
                  triggerContent={
                    <HealthPillWrapper
                      onClick={(
                        e: React.MouseEvent<HTMLElement, MouseEvent>
                      ) => {
                        const factorSet =
                          key === overviewHealthTypes.AVERAGE_HEALTH
                            ? "overview"
                            : key === overviewHealthTypes.PEOPLE_HEALTH
                            ? "people"
                            : "projects";
                        return sprintHealthFactorSet === "overview"
                          ? onClickFactorSet(e, factorSet)
                          : null;
                      }}
                      onMouseOver={() => setActivePill(key)}
                      onMouseOut={() => setActivePill(null)}
                      data-testid={`${key}-health-pill-wrapper`}
                    >
                      {getHealthPillText(sprintHealthFactorSet, key)}
                    </HealthPillWrapper>
                  }
                  testId={`${thisTestId}-${key}`}
                />
              </SprintHealthPill>
            );
          })}
        </SprintHealthFactorsGrid>
      </SprintHealthFactorsContainer>
      <SprintHealthFooter>
        {Object.values(healthScoreTypes).map(type => (
          <>
            <FooterPill sprintHealthScoreType={type} />
            {capitalize(type.toLowerCase())}
          </>
        ))}
      </SprintHealthFooter>
    </div>
  );
};

export default RetrosSprintHealthFactors;
