import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import EmptyState from "../EmptyState/EmptyState";
import Icon from "../Icon/Icon";

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const NoPermissions = ({
  className,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-no-permissions`;
  return (
    <div className={className} data-testid={thisTestId}>
      <EmptyState
        body={
          <>
            Sorry, you currently don&rsquo;t have permissions to view this data.
          </>
        }
        header={<Icon icon="lock" testId={thisTestId} />}
        testId={thisTestId}
      />
    </div>
  );
};

export default NoPermissions;
