import React from "react";
import { components } from "react-select";
import styled from "styled-components/macro";

// components
import Icon from "../Icon/Icon";

// styled components
const PlusIcon = styled(Icon)`
  display: none;
`;
const Container = styled(components.Option)`
  &:hover {
    & ${PlusIcon} {
      display: block;
    }
  }
`;
const ContentWrapper = styled.span`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
const ChildrenWrapper = styled.span`
  flex: 1;
`;

const TeamMembersAdderOption = (props: any) => {
  const thisTestId = "team-members-adder-option";
  return (
    <Container {...props}>
      <ContentWrapper>
        <ChildrenWrapper>{props.children}</ChildrenWrapper>

        <PlusIcon icon="plus" testId={thisTestId} />
      </ContentWrapper>
    </Container>
  );
};

export default TeamMembersAdderOption;
