import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import spinner from "../../images/spinner.svg";

const StyledSpinner = styled.div``;

const Spinner = ({ testId, ...otherProps }) => {
  if (!testId) {
    return null;
  }

  // from https://codepen.io/aurer/pen/jEGbA
  return (
    <StyledSpinner data-testid={`${testId}-spinner`} {...otherProps}>
      <img src={spinner} alt="spinner" />
    </StyledSpinner>
  );
};

Spinner.propTypes = {
  testId: PropTypes.string.isRequired
};
export default Spinner;
