// note: env variables from the docker container MUST begin with REACT_APP_ in order
// to be picked up here
const config = {
  FARS_URL: process.env.REACT_APP_FARS_URL || "https://fart.uplevelteam.com",
  BLUE_URL:
    process.env.REACT_APP_BLUE_SERVICE_URL ||
    "https://staging.blue.uplevelteam.com",
  LAUNCH_DARKLY_CLIENT_ID:
    process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || "5d2d1807ff7d4306f6437cac",
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID,
  HEAP_ID: process.env.REACT_APP_HEAP_ID || 1370320208,
  SENTRY_DSN:
    process.env.SENTRY_DSN ||
    "https://0dc2fa4719ef46fcac6bd0602d5259d8@o4504006232768512.ingest.sentry.io/4504006238142464",
  ORG_DASHBOARD_URL:
    process.env.REACT_APP_ORG_DASHBOARD_URL ||
    "https://staging.web-frontend.uplevelteam.com"
};
export default config;
