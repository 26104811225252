import { actionTypes } from "../constants/actionTypes";

/**
 * store session params
 * @param {Object} params
 */
export const storeSessionParams = params => {
  return {
    type: actionTypes.STORE_SESSION_PARAMS,
    payload: params
  };
};

/**
 * store session login timestamp
 * @param {String} loginTimestamp
 */
export const storeSessionLoginTimestamp = loginTimestamp => {
  return {
    type: actionTypes.STORE_SESSION_LOGIN_TIMESTAMP,
    payload: { loginTimestamp }
  };
};

/**
 * store session document referrer
 * @param {String} referrer
 */
export const storeSessionReferrer = referrer => {
  return {
    type: actionTypes.STORE_SESSION_REFERRER,
    payload: { referrer }
  };
};

/**
 * store session uuid
 * @param {String} uuid
 */
export const storeuuid = uuid => {
  return {
    type: actionTypes.STORE_SESSION_UUID,
    payload: { uuid }
  };
};

/**
 * store organization
 * @returns {{payload: {organization: *}, type: *}}
 */
export const storeOrganization = organization => {
  return {
    type: actionTypes.STORE_ORGANIZATION,
    payload: { organization }
  };
};
