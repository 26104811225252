import api from "./api";

// typescript props
type BaseParams = {
  startDate: string;
  endDate: string;
  override?: { title: string; overrideLabel: string };
};

type MeetingTimeParams = {
  accessToken: string;
  tenantId: number;
  params: BaseParams & {
    selectedUser: string;
    users: Array<string>;
  };
};

type MeetingTimePrivateIcParams = {
  accessToken: string;
  tenantId: number;
  params: BaseParams & {
    tenantId: number;
    userEmail: string;
  };
};

export const requestMeetingTime_privateic = async ({
  accessToken,
  params,
  tenantId
}: MeetingTimePrivateIcParams): Promise<any> => {
  const response = await api.post(
    `/${tenantId}/private-ic/meetingtime`,
    params,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return response.data;
};

export const requestMeetingTime = async ({
  accessToken,
  params,
  tenantId
}: MeetingTimeParams): Promise<any> => {
  const response = await api.post(`/${tenantId}/meetingtime`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};
