import React, { ReactElement } from "react";
import { OrgByTimeBlock } from "../../interfaces/organization";
import {
  filterTruthyCycleTimeValues,
  splitCycleTimeHours
} from "../../utils/cycle-time";
import { round } from "lodash";
import styled from "styled-components/macro";
import CycleTimeValueDisplay from "../CycleTimeValueDisplay/CycleTimeValueDisplay";

// styled components
const Current = styled(CycleTimeValueDisplay)`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  font-size: ${props => props.theme.fonts.primary.sizes.xl};
  margin-left: 1rem;

  & > div > span {
    align-items: baseline;
    display: flex;
    gap: 2rem;

    & > span {
      line-height: inherit;
    }
  }
`;

const SelectedPeriodText = styled.div`
  display: flex;
  align-items: baseline;
  margin: 1rem 0 0 1rem;
`;
const SelectedPeriodValue = styled.b`
  font-family: ${props => props.theme.fonts.subheader.name};
  font-size: ${props => props.theme.fonts.subheader.sizes.xl};
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  measure: "deep-work" | "cycle-time" | "release-frequency";
  current?: OrgByTimeBlock;
  isDaysScale?: boolean;
};
const OverviewSummaryCurrentValue = ({
  className,
  testId = "testId",
  measure,
  current,
  isDaysScale
}: Props): ReactElement => {
  const thisTestId = `${testId}-overview-summary-current-value`;
  if (!current?.value)
    return (
      <SelectedPeriodText>No data for the period selected</SelectedPeriodText>
    );

  if (measure === "cycle-time") {
    return (
      <Current
        display="rollup"
        stage={filterTruthyCycleTimeValues(
          splitCycleTimeHours(current.value, isDaysScale)
        ).map(t => ({ ...t, value: round(t.value, 1) }))}
        testId={thisTestId}
      />
    );
  }

  return (
    <SelectedPeriodText>
      <SelectedPeriodValue>
        {current.value.toFixed(measure === "deep-work" ? 1 : 0)}
      </SelectedPeriodValue>
      &emsp;{measure === "deep-work" ? "Hours" : "Releases"}
    </SelectedPeriodText>
  );
};

export default OverviewSummaryCurrentValue;
