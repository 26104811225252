import React from "react";
import styled from "styled-components/macro";
import { FeedbackEventParams } from "../../interfaces/labs";
import HtmlText from "../HtmlText/HtmlText";

import FeedbackForm from "../FeedbackForm/FeedbackForm";

const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 2rem;
`;

const ChartTitle = styled.h3`
  align-items: center;
  display: flex;
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  margin-bottom: 1rem;
  padding: 1rem 1rem 1rem 0;
`;

const ChartIframe = styled.iframe`
  width: 100%;
  height: 500px;
  align-self: center;
`;

const ChartFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  align-items: flex-start;
`;

const DescriptionHeader = styled.h4`
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const NewFlag = styled("span")`
  color: ${props => props.theme.colors.all.auroraTeal};
  display: flex;
  font-size: 1.25rem;
  margin-left: 0.25rem;
  align-self: flex-start;
`;

type Props = {
  title: string;
  descriptionHeader: string;
  descriptionBody: string;
  url: string;
  displayIsNew: boolean;
  triggerWriteFeedback: (triggerArgs: FeedbackEventParams) => void;
};

const LabsChart = ({
  title,
  descriptionHeader,
  descriptionBody,
  url,
  displayIsNew,
  triggerWriteFeedback
}: Props): JSX.Element => {
  return (
    <FrameContainer>
      <TitleContainer>
        <ChartTitle>{title}</ChartTitle>
        {displayIsNew ? <NewFlag>New!</NewFlag> : null}
      </TitleContainer>
      <ChartIframe title={title} src={url} />
      <ChartFooter>
        <Description>
          <DescriptionHeader>{descriptionHeader}</DescriptionHeader>
          <HtmlText
            htmlText={descriptionBody}
            testId={`${title}-description-body`}
          />
        </Description>
        <FeedbackForm
          testId={title}
          triggerWriteFeedback={triggerWriteFeedback}
          descriptionHeader={descriptionHeader}
        />
      </ChartFooter>
    </FrameContainer>
  );
};

export default LabsChart;
