import React from "react";
import styled from "styled-components/macro";

// components
import UiCenteringWrapper from "../UiCenteringWrapper/UiCenteringWrapper";

// styled components
const Container = styled.header`
  background: ${props => props.theme.colors.all.white};
  padding: 1.6rem 0;
`;

// typescript props
type Props = {
  className?: string;
  content: React.ReactNode;
  testId?: string;
};

const TemplateHeader = ({ className, content, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-template-header`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <UiCenteringWrapper testId={testId}>{content} </UiCenteringWrapper>
    </Container>
  );
};

export default TemplateHeader;
