import React, { ReactElement } from "react";

// components
import { Redirect } from "react-router-dom";
import EpicDetailsBaseTemplate from "../EpicDetailsBaseTemplate/EpicDetailsBaseTemplate";

// hooks
import { useSelector } from "react-redux";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const EpicDetailsPage = ({
  className,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-epic-details-page`;
  const flags = useSelector((state: AppStateInterface) => state.flags);

  return flags?.["work-deep-dive"] ? (
    <div className={className} data-testid={thisTestId}>
      <EpicDetailsBaseTemplate testId={thisTestId} />
    </div>
  ) : (
    <Redirect to={`/`} />
  );
};

export default EpicDetailsPage;
