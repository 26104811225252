import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { isNull, isUndefined } from "lodash";
import config from "../../env-config";

// components
import { Divider, List, ListItem } from "@material-ui/core";
import Icon from "../Icon/Icon";
import RouteLink from "../RouteLink/RouteLink";

// constants
import {
  pageIds,
  pageMetadata,
  urlGlobalParams
} from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import * as LDClient from "launchdarkly-js-client-sdk";
import { PageMetadataInterface } from "../../interfaces/constants";
import { AppStateInterface } from "../../interfaces/app-state";
import { parsePageIdFromPathname } from "../../utils/router";

// styled components
const Beaker = styled(Icon)`
  color: ${props => props.theme.colors.all.marvelMint};
`;
const SidebarList = styled(List)`
  & .MuiList-root {
    display: flex;
    flex-flow: column nowrap;
  }

  & .MuiList-padding {
    padding: 0;
  }

  & .MuiButton-label {
    text-align: left;
    color: white;
    font-size: 1rem;
  }

  & .MuiListItem-button {
    align-items: stretch;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;

    &:hover {
      color: ${props => props.theme.colors.all.wolverine};
      background-color: ${props =>
        props.theme.colors.all.marvelMint} !important;
      & .LinkFlag {
        color: ${props => props.theme.colors.all.wolverine};
      }
    }
  }

  & .Mui-selected {
    background-color: ${props => props.theme.colors.all.storm} !important;
    box-shadow: inset -4px 0px ${props => props.theme.colors.all.marvelMint};
  }
`;
const SidebarLink = styled(RouteLink)<{ isZoomedIn: boolean }>`
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.book};
  color: inherit !important;
  padding-top: 0.8rem;
  padding-right: 1.2rem;
  padding-bottom: 0.8rem;
  padding-left: ${props => (props.isZoomedIn ? "1.6rem" : "0.8rem")};
  text-decoration: none !important;
  text-transform: capitalize;

  &.active {
    color: ${props => props.theme.colors.all.marvelMint};
  }
  &:hover {
    & ${Beaker} {
      color: ${props => props.theme.colors.all.wolverine};
    }
  }
`;
const UplevelWebLink = styled.a<{ isZoomedIn: boolean }>`
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.book};
  color: inherit !important;
  padding-top: 0.8rem;
  padding-right: 1.2rem;
  padding-bottom: 0.8rem;
  padding-left: ${props => (props.isZoomedIn ? "1.6rem" : "0.8rem")};
  text-decoration: none !important;
  text-transform: capitalize;

  &.active {
    color: ${props => props.theme.colors.all.marvelMint};
  }
  &:hover {
    & ${Beaker} {
      color: ${props => props.theme.colors.all.wolverine};
    }
  }
`;
const LinkTitle = styled.span`
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  flex: 1;
`;
const LinkFlag = styled("span")`
  color: ${props => props.theme.colors.all.marvelMint};
  font-size: 1rem;
  font-style: italic;
  margin-right: 1rem;
`;
const LinkChildrenList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 0;
  padding-left: 0;
`;
const LinkChildrenListItem = styled.li<{
  isZoomedIn: boolean;
  selected: boolean;
}>`
  padding-left: ${props => (props.isZoomedIn ? "1.6rem" : "0.8rem")};
  color: ${props =>
    props.selected
      ? props.theme.colors.all.marvelMint
      : props.theme.colors.all.jean};

  &:hover {
    color: ${props => props.theme.colors.all.wolverine};
    background-color: ${props => props.theme.colors.all.marvelMint} !important;
    & .LinkFlag {
      color: ${props => props.theme.colors.all.wolverine};
    }
  }

  & ${SidebarLink} {
    padding: 0;
  }
`;
const ListDivider = styled(Divider)`
  background-color: ${props => props.theme.colors.all.storm} !important;
  height: 0.1rem !important;
  margin: 0.8rem !important;
`;

// utils
const pageIsAllowed = (
  pageMetadata: PageMetadataInterface,
  flags: LDClient.LDFlagSet | null
): boolean => {
  // if flags is null, they haven't resolved yet in state. return false until
  // they do.
  // TODO: this situation is being reflected in sentry errors but
  // it's unclear how it's occurring because we literally do a check for whether
  // flags are null in order to ungate the UI. this needs more investigation!!
  if (isNull(flags)) {
    return false;
  }
  return isUndefined(pageMetadata.flag)
    ? isUndefined(pageMetadata.path)
      ? false
      : // if we're not flagging a defined path, it should be safe to always display
      isUndefined(flags?.[pageMetadata.path])
      ? true
      : flags?.[pageMetadata.path]
    : !!flags?.[pageMetadata.flag];
};

type Props = {
  className?: string;
  currentPageId: string;
  pages: Array<string>;
  pathname: string;
  isZoomedIn: boolean;
  testId?: string;
};

const SidebarLinks = ({
  className,
  currentPageId,
  isZoomedIn,
  pathname,
  testId = "testId",
  pages
}: Props): ReactElement => {
  const thisTestId = `${testId}-sidebar-links`;
  const {
    teamDeepDiveParams,
    getPathForPageId,
    workDeepDiveParams,
    urlParams
  } = useUrlParams();
  const activeTeam = urlParams.team;
  const activeBoard = urlParams.board;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const allowedFeatures = pages
    .filter((pageId: string) => {
      const page = pageMetadata[pageId];
      const isAllowed = pageIsAllowed(page, flags);
      if (pageId === pageIds.RETROS) {
        return isAllowed && !!activeTeam?.useSprints && !!activeBoard;
      }
      return isAllowed;
    })
    .map((pageId: string) => {
      return { ...pageMetadata[pageId], pageId };
    });
  const enabledFeatures = (isZoomedIn
    ? allowedFeatures.filter(
        page => page.pageId !== pageIds.REPORTS && page.pageId !== pageIds.LABS
      )
    : allowedFeatures
  ).map(p => p.pageId);

  return (
    <div className={className} data-testid={thisTestId}>
      <SidebarList>
        {(allowedFeatures as Array<{
          pageId: string;
          path: string;
          title: string;
          children?: Array<string>;
          flag?: string;
        }>).map((page, index) => {
          const linkId = `sidebar-${page.path}`;
          const isEnabled = enabledFeatures.includes(page.pageId);
          const children = (page.children || []).filter(c =>
            pageIsAllowed(pageMetadata[c], flags)
          );
          const params =
            currentPageId === pageIds.WORK_DEEP_DIVE
              ? workDeepDiveParams
              : currentPageId === pageIds.TEAM_DEEP_DIVE
              ? teamDeepDiveParams
              : null;

          const linkContent = (
            <>
              <LinkTitle>
                {page.pageId === pageIds.LABS ? (
                  <>
                    <Beaker icon="beaker" testId={thisTestId} /> {page.title}
                  </>
                ) : (
                  page.title
                )}
              </LinkTitle>
              {flags?.newFeatures?.[page.path] ? (
                <LinkFlag className="LinkFlag">New! </LinkFlag>
              ) : flags?.beta?.[page.path] ? (
                <LinkFlag className="LinkFlag">Beta </LinkFlag>
              ) : null}
            </>
          );
          const linkWrapper =
            page.pageId === pageIds.OVERVIEW ? (
              <UplevelWebLink
                isZoomedIn={isZoomedIn}
                href={config.ORG_DASHBOARD_URL}
              >
                {linkContent}
              </UplevelWebLink>
            ) : (
              <SidebarLink
                isZoomedIn={isZoomedIn}
                name={linkId}
                to={getPathForPageId(page.pageId, {
                  persistQuerystring: urlGlobalParams
                })}
                testId={linkId}
                type="nav"
              >
                {linkContent}
              </SidebarLink>
            );

          return (
            <li key={linkId}>
              {!!index ? <ListDivider light /> : null}
              <ListItem
                button
                divider
                selected={pathname.includes(page.path)}
                disableGutters={true}
                key={page.pageId}
                disabled={!isEnabled}
              >
                {linkWrapper}
              </ListItem>
              {!!children.length ? (
                <LinkChildrenList>
                  {children.map(c => {
                    const childLinkId = `sidebar-${page.path}-${c}`;
                    return (
                      <LinkChildrenListItem
                        key={childLinkId}
                        isZoomedIn={isZoomedIn}
                        selected={params?.selectedSection === c}
                      >
                        <SidebarLink
                          isZoomedIn={isZoomedIn}
                          name={childLinkId}
                          to={getPathForPageId(page.pageId, {
                            persistQuerystring: urlGlobalParams,
                            selectedSection: c
                          })}
                          testId={childLinkId}
                          type="nav"
                        >
                          {pageMetadata[c].title}
                        </SidebarLink>
                      </LinkChildrenListItem>
                    );
                  })}
                </LinkChildrenList>
              ) : null}
            </li>
          );
        })}
      </SidebarList>
    </div>
  );
};

export default SidebarLinks;
