import React from "react";
import PropTypes from "prop-types";
import { sample } from "lodash";
import { avatarImages } from "../../constants/widgets";

// components
import Image from "../Image/Image";

const DefaultAvatar = ({ className, testId }) => {
  if (!testId) {
    return null;
  }

  return (
    <Image
      className={className}
      altText="default avatar"
      src={sample(avatarImages)}
      testId={`${testId}-default-avatar`}
    />
  );
};

DefaultAvatar.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string.isRequired
};
export default DefaultAvatar;
