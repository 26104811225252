import { legacyApi } from "./api";

// typescript props
type ReportsProps = {
  accessToken: string;
  tenantId: number;
  charts: string[];
};

export const requestLabsData = async ({
  accessToken,
  tenantId,
  charts
}: ReportsProps): Promise<any> => {
  const response = await legacyApi.get(`/${tenantId}/labs`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: { files: charts.join(",") }
  });
  return response.data;
};
