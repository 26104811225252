// interfaces
import {
  ListInterface,
  ListResponseInterface
} from "../../interfaces/reports-list";
import { UserReportInterface } from "../../interfaces/user";

export default (
  data: ListResponseInterface,
  team: Array<UserReportInterface>
): Array<ListInterface> => {
  return team.map(t => {
    const url = data[t.email];
    return {
      user: t,
      url
    };
  });
};
