import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

// hooks
import { useHistory } from "react-router-dom";
import { useTracking } from "../../hooks/useTracking";

// typescript props
type Props = {
  children: React.ReactNode;
  className?: string;
  name: string;
  to: string;
  testId?: string;
  type?: "default" | "nav";
};

const RouteLink = ({
  children,
  className,
  name,
  testId = "testId",
  to,
  type = "default"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-route-link`;
  const [tracked, setTracked] = useState(false);
  const history = useHistory();
  const { trackEvent } = useTracking();

  function onClickRouteLink(e: React.MouseEvent<HTMLAnchorElement>) {
    e.persist();
    e.preventDefault();
    trackEvent({
      e,
      label: `${name}-click`,
      value: `followed route link to ${to}`
    });
    setTracked(true);
  }

  useEffect(() => {
    if (tracked) {
      history.push(to);
      setTracked(false);
    }
  }, [tracked]);

  const DisplayLink = type === "nav" ? NavLink : Link;

  return (
    <DisplayLink
      className={className}
      data-testid={thisTestId}
      id={name}
      onClick={onClickRouteLink}
      to={to}
    >
      {children}
    </DisplayLink>
  );
};

export default RouteLink;
