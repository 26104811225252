import React from "react";
import styled from "styled-components/macro";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";
import UiCenteringWrapper from "../../components/UiCenteringWrapper/UiCenteringWrapper";
import PageTitle from "../../components/PageTitle/PageTitle";
import TemplateHeader from "../../components/TemplateHeader/TemplateHeader";
import EmptyState from "../EmptyState/EmptyState";
import LabsChart from "./LabsChart";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import MANIFESTJSON from "../../labs.json";
import { useBackingData } from "./useBackingData";
import { pageIds } from "../../constants/constants";
import { FeedbackEventParams } from "../../interfaces/labs";

type ManifestChart = {
  title: string;
  displayIsNew: boolean;
  externalPage: string;
  descriptionHeader: string;
  descriptionBody: string;
  feedbackId: string;
};

type Props = {
  manualState?: {
    charts: Array<{ id: string; url: string }>;
    isFetching?: boolean;
    error?: boolean;
    manualManifest?: {
      labsHeaderDescription: string;
      experiments: ManifestChart[];
    };
  };
};

const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Container = styled.div`
  padding: 2rem 0;
  ul,
  ol,
  dl {
    list-style-type: initial;
    padding: 0px 0px 0px 40px;
    margin: 0px 0px 10px;
  }
`;
const ContentWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled(PageTitle)`
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding-bottom: 0.8rem;
`;
const StyledTemplateHeader = styled(TemplateHeader)`
  margin-bottom: 2rem;
  font-size: 2rem;
`;

const HeaderBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2rem;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  align-items: flex-start;
`;

const LabsPage: ({ manualState }: Props) => JSX.Element = ({
  manualState
}: Props): JSX.Element => {
  const MANIFEST = manualState?.manualManifest || MANIFESTJSON;
  const { charts, error, sendFeedback } = useBackingData(MANIFEST, manualState);

  const triggerWriteFeedback = ({
    comment,
    rating,
    widgetName,
    question,
    event
  }: FeedbackEventParams): void => {
    event.preventDefault();

    sendFeedback({
      widgetName,
      question,
      rating,
      comment
    });
  };
  return (
    <TransitionWrapper>
      <UiCenteringWrapper>
        <Container>
          <ContentWrapper>
            <FrameContainer>
              <StyledTemplateHeader
                content={<Title title="Uplevel Labs" testId={pageIds.LABS} />}
                testId={pageIds.LABS}
              />
              <HeaderBody>
                <Description>
                  <p>{MANIFEST.labsHeaderDescription}</p>
                </Description>
                <FeedbackForm
                  testId={"labs-page"}
                  triggerWriteFeedback={triggerWriteFeedback}
                  descriptionHeader=""
                />
              </HeaderBody>
            </FrameContainer>
          </ContentWrapper>
          {!error ? (
            <FrameContainer>
              {MANIFEST.experiments.map(
                (record: ManifestChart): JSX.Element | null => {
                  const chartToDisplay:
                    | {
                        id: string;
                        url: string;
                      }
                    | undefined = charts.find(
                    (
                      chart:
                        | {
                            id: string;
                            url: string;
                          }
                        | undefined
                    ): boolean => chart?.id === record.externalPage
                  );
                  if (chartToDisplay) {
                    return (
                      <ContentWrapper key={chartToDisplay.id}>
                        <LabsChart
                          title={record.title}
                          descriptionHeader={record.descriptionHeader}
                          descriptionBody={record.descriptionBody}
                          url={chartToDisplay?.url}
                          displayIsNew={record.displayIsNew}
                          triggerWriteFeedback={triggerWriteFeedback}
                        />
                      </ContentWrapper>
                    );
                  }
                  return null;
                }
              )}
            </FrameContainer>
          ) : error ? (
            <ContentWrapper>
              <EmptyState
                header={"Uh Oh!"}
                body={"We couldn't find the charts you were looking for"}
              />
            </ContentWrapper>
          ) : null}
        </Container>
      </UiCenteringWrapper>
    </TransitionWrapper>
  );
};

export default LabsPage;
