import React from "react";
import styled from "styled-components/macro";
import moment from "moment";

export const ReactionList = styled.ul`
  list-style-type: none;
  padding-left: 0 !important;
  margin: 0;
`;

export const ReactionTimestamp = styled.time`
  color: ${props => props.theme.colors.all.storm};
  text-align: right;
  padding-left: 1rem;
`;

export const ReactionLineItem = styled.li`
  font-size: 1.1rem;
  list-style-type: none;
  margin: 0;
  display: "flex";
  justify-content: space-between;
`;

const ReactionTooltip = ({ annotations, teamMembers }) => {
  return (
    <ReactionList>
      {annotations.map((anno, index) => {
        const userName =
          teamMembers.find(member => member.id === anno.userId)?.["name"] ||
          anno.userEmail;
        return (
          <ReactionLineItem key={index} data-heap-redact-text="true">
            {userName}{" "}
            <ReactionTimestamp datetime={anno.timestamp}>
              {moment(anno.timestamp).fromNow()}
            </ReactionTimestamp>
          </ReactionLineItem>
        );
      })}
    </ReactionList>
  );
};

export default ReactionTooltip;
