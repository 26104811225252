import { isMobile } from "react-device-detect";

// constants
import { actionTypes } from "../constants/actionTypes";
import {
  timerangeIds,
  timerangeTypes,
  timerangeTypesMetadata
} from "../constants/constants";
import {
  getCurrentWeekTimerange,
  getPreviousNumDaysTimerange,
  getPreviousWeekTimerange
} from "../utils/date";

const namedTimeranges = {
  [timerangeIds.YESTERDAY]: {
    ...timerangeTypesMetadata[timerangeTypes.DAYS],
    ...getPreviousNumDaysTimerange(1),
    displayName: "Yesterday",
    id: timerangeIds.YESTERDAY
  },
  // this is a fake timerange meant only as a placeholder until the actual
  // timerange is set so that the ui can be unlocked.
  [timerangeIds.TODAY]: {
    ...timerangeTypesMetadata[timerangeTypes.DAYS],
    ...getPreviousNumDaysTimerange(),
    displayName: "Today",
    id: timerangeIds.TODAY
  },
  [timerangeIds.LAST_WEEK]: {
    ...timerangeTypesMetadata[timerangeTypes.WEEKS],
    ...getPreviousWeekTimerange(),
    displayName: "Last Week",
    id: timerangeIds.LAST_WEEK
  },
  [timerangeIds.THIS_WEEK]: {
    ...timerangeTypesMetadata[timerangeTypes.WEEKS],
    ...getCurrentWeekTimerange(),
    displayName: "This Week",
    id: timerangeIds.THIS_WEEK
  },
  [timerangeIds.PREVIOUS_14_DAYS]: {
    ...timerangeTypesMetadata[timerangeTypes.DAYS],
    ...getPreviousNumDaysTimerange(14),
    displayName: "Previous 14 Days",
    id: timerangeIds.PREVIOUS_14_DAYS
  },
  [timerangeIds.PREVIOUS_30_DAYS]: {
    ...timerangeTypesMetadata[timerangeTypes.DAYS],
    ...getPreviousNumDaysTimerange(30),
    displayName: "Previous 30 Days",
    id: timerangeIds.PREVIOUS_30_DAYS
  },
  [timerangeIds.PREVIOUS_90_DAYS]: {
    ...timerangeTypesMetadata[timerangeTypes.DAYS],
    ...getPreviousNumDaysTimerange(90),
    displayName: "Previous 90 Days",
    id: timerangeIds.PREVIOUS_90_DAYS
  }
};

/**
 * reducer for session related state actions
 */
export default (
  state = {
    deviceType: isMobile ? "mobile" : "desktop",
    referrer: document.referrer || window.location.href,
    username: "unauthenticated-user",
    namedTimeranges,
    organization: { parentsOfActiveTeam: [] }
  },
  action = {}
) => {
  switch (action.type) {
    case actionTypes.STORE_SESSION_PARAMS:
      return {
        ...state,
        ...action.payload
      };
    case actionTypes.STORE_ORGANIZATION:
      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload.organization
        }
      };
    default:
      return state;
  }
};
