import React from "react";
import styled from "styled-components/macro";
import { isUndefined, snakeCase } from "lodash";

// components
import WorkMetadataIcon from "../WorkMetadataIcon/WorkMetadataIcon";

// interfaces
import { ActivityInterface } from "../../interfaces/work-items";

// constants
import { workMetadataActivityTypes } from "../../constants/constants";
import StoryPoints from "../StoryPoints/StoryPoints";

// styled components
const List = styled.ul`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, 8rem);
  justify-items: start;
  justify-content: space-between;
`;
const ListItem = styled.li`
  align-items: center;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  align-items: center;
  justify-content: center;
`;
const Score = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
`;

// typescript props
type Props = {
  className?: string;
  activities: Array<ActivityInterface>;
  testId?: string;
};

const WorkMetadataActivities = ({
  className,
  activities,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-work-metadata-activities`;

  return (
    <div className={className} data-testid={thisTestId}>
      <List>
        {[
          workMetadataActivityTypes.ISSUE,
          workMetadataActivityTypes.SUB_TASK,
          workMetadataActivityTypes.BUG,
          workMetadataActivityTypes.PR
        ].map((activityType: string) => {
          const activity = activities.find(
            a => snakeCase(a.activityType).toUpperCase() === activityType
          );
          return (
            <ListItem key={activityType}>
              {isUndefined(activity) ? null : (
                <>
                  <WorkMetadataIcon
                    type={snakeCase(activity.activityType).toUpperCase()}
                    testId={thisTestId}
                  />
                  <Score>{activity.count}</Score>
                  <StoryPoints storyPoints={activity.storyPoints} />
                </>
              )}
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default WorkMetadataActivities;
