import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";

const getBorder = props => {
  const borderWidth = props.theme.borders.widths.md;
  const borderColor = props.color || props.theme.colors.borderedBox.border;
  const style = `${borderWidth} solid ${borderColor}`;
  const padding = ".5em";

  switch (props.location) {
    case "top":
      return css`
        border-top: ${style};
        padding-top: ${padding};
      `;
    case "right":
      return css`
        border-right: ${style};
        padding-right: ${padding};
      `;
    case "bottom":
      return css`
        border-bottom: ${style};
        padding-bottom: ${padding};
      `;
    case "left":
      return css`
        border-left: ${style};
        padding-left: ${padding};
      `;
    case "top-bottom":
      return css`
        border-bottom: ${style};
        border-top: ${style};
        padding-bottom: ${padding};
        padding-top: ${padding};
      `;
    case "left-right":
      return css`
        border-left: ${style};
        border-right: ${style};
        padding-left: ${padding};
        padding-right: ${padding};
      `;
    default:
      // "all"
      return css`
        border: ${style};
        padding: ${padding};
      `;
  }
};

// styled components
const StyledBorderedBox = styled.div`
  ${props => getBorder(props)}
`;

const BorderedBox = ({ children, color, location, testId, ...otherProps }) => {
  if (!children || !testId) {
    return null;
  }

  const props = {
    ...otherProps,
    color,
    location
  };

  return (
    <StyledBorderedBox {...props} data-testid={`${testId}-bordered-box`}>
      {children}
    </StyledBorderedBox>
  );
};

BorderedBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  color: PropTypes.string,
  location: PropTypes.oneOf([
    "top",
    "right",
    "bottom",
    "left",
    "top-bottom",
    "left-right",
    "all"
  ]),
  testId: PropTypes.string.isRequired
};

export default BorderedBox;
