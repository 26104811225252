import React, { ReactElement, useMemo } from "react";
import styled from "styled-components/macro";
import { difference, pick } from "lodash";

// components
import TeamCustomizationSettingsSwitches from "../TeamCustomizationSettingsSwitches/TeamCustomizationSettingsSwitches";

// interfaces
import { TeamCustomizationSettingsInterface } from "../../interfaces/user-settings";
import { FeatureTypes } from "../../interfaces/features";

// constants
import {
  customizationSettingsKeys,
  sprintCustomizationSettingsKeys
} from "../../constants/constants";
const jiraCustomizationSettingsKeys: Array<FeatureTypes> = [
  customizationSettingsKeys.JIRA_EPICS,
  customizationSettingsKeys.SUBTASKS,
  customizationSettingsKeys.TICKETS_ADDED_MID_SPRINT,
  customizationSettingsKeys.TICKETS_REMOVED_MID_SPRINT,
  customizationSettingsKeys.TICKETS_CLOSED,
  customizationSettingsKeys.TICKETS_DESCRIBED,
  customizationSettingsKeys.TICKETS_NOT_COMPLETED,
  customizationSettingsKeys.TICKETS_CARRIED_OVER
];
export const storyPointsCustomizationSettingsKeys: Array<FeatureTypes> = [
  customizationSettingsKeys.STORY_POINTS
];
export const bugsCustomizationSettingsKeys: Array<FeatureTypes> = [
  customizationSettingsKeys.BUGS_IN_SPRINT,
  customizationSettingsKeys.TOP_PRIORITY_BUGS
];

// styled components
const Header = styled.header`
  margin-bottom: 2rem;
`;
const SettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 6rem;
`;
const Issues = styled(TeamCustomizationSettingsSwitches)`
  grid-row: 1 / span 2;
`;

// typescript props
type Props = {
  className?: string;
  onChangeSwitch: (optionKey: keyof TeamCustomizationSettingsInterface) => void;
  showHealthFactorsCustomizations: boolean;
  settings: Partial<TeamCustomizationSettingsInterface> | null;
  teamUsesSprints: boolean;
  testId?: string;
};

const JiraWorkSettings = ({
  className,
  onChangeSwitch,
  showHealthFactorsCustomizations,
  settings,
  teamUsesSprints,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-jira-work-settings`;
  const jiraOptionsKeys: Array<FeatureTypes> = useMemo(
    () =>
      showHealthFactorsCustomizations
        ? teamUsesSprints
          ? jiraCustomizationSettingsKeys
          : difference(
              jiraCustomizationSettingsKeys,
              sprintCustomizationSettingsKeys
            )
        : [customizationSettingsKeys.SUBTASKS],
    [showHealthFactorsCustomizations, teamUsesSprints]
  );

  const storyOptionsKeys: Array<FeatureTypes> = useMemo(
    () =>
      showHealthFactorsCustomizations
        ? storyPointsCustomizationSettingsKeys
        : [customizationSettingsKeys.STORY_POINTS],
    [showHealthFactorsCustomizations]
  );

  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        With <b>Jira,</b> my team wants to see Insights on...
      </Header>
      <SettingsWrapper>
        <Issues
          header="Issues"
          onChange={onChangeSwitch}
          options={jiraOptionsKeys}
          settings={pick(settings, jiraOptionsKeys)}
          testId="team-customizations-issues-switches"
        />
        <TeamCustomizationSettingsSwitches
          header="Story Points"
          onChange={onChangeSwitch}
          options={storyOptionsKeys}
          settings={pick(settings, storyOptionsKeys)}
          testId="team-customizations-story-points-switches"
        />
        {showHealthFactorsCustomizations ? (
          <TeamCustomizationSettingsSwitches
            header="Bugs"
            onChange={onChangeSwitch}
            options={bugsCustomizationSettingsKeys}
            settings={pick(settings, bugsCustomizationSettingsKeys)}
            testId="team-customizations-bugs-switches"
          />
        ) : null}
      </SettingsWrapper>
    </div>
  );
};

export default JiraWorkSettings;
