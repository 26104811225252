import React from "react";
import styled from "styled-components/macro";

// components
import TeamCard from "../TeamCard/TeamCard";

// interfaces
import { TeamInterface } from "../../interfaces/team";

// constants
const COLS_PER_ROW = 4;

// styled components
const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-bottom: 1.8rem;
`;
const Grid = styled.div`
  width: 100%;
`;
const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(${COLS_PER_ROW}, 1fr);
  grid-template-rows: 50rem;
  grid-auto-rows: 50rem;

  & + & {
    border-top: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    margin-top: 1.6rem;
    padding-top: 1.6rem;
  }
`;
const TeamCardCol = styled(TeamCard)`
  padding-right: 1rem;

  &:not(:last-child) {
    border-right: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    margin-right: 1rem;
  }
`;

// typescript props
type Props = {
  className?: string;
  header?: React.ReactNode;
  teams: Array<Array<TeamInterface | null>>;
  testId?: string;
};

const SettingsTeamBuilderGrid = ({
  className,
  header,
  teams,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-settings-team-builder-grid`;
  return (
    <Grid className={className} data-testid={thisTestId}>
      {header ? <Header>{header}</Header> : null}
      {teams.map((row, index) => (
        <GridRow key={`row-${index}`}>
          {row.map((team: TeamInterface | null) => {
            return (
              <TeamCardCol
                key={team?.teamId || "create-team"}
                team={team}
                testId={`${thisTestId}-${team?.teamId}`}
              />
            );
          })}
        </GridRow>
      ))}
    </Grid>
  );
};

export default SettingsTeamBuilderGrid;
