import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { isEmpty, debounce } from "lodash";

// actions

// components
import EpicFilterCheckboxList from "../EpicFilterCheckboxList/EpicFilterCheckboxList";
import InfoIcon from "../InfoIcon/InfoIcon";

import { urlParamKeys, urlParamsValuesLabels } from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";
import { workMetadataStatusTypes } from "../../constants/constants";
import {
  EpicDetailsInterface,
  GroupsListInterface,
  ResponseTotalInterface
} from "../../interfaces/work-items";
import {
  UrlParamsHookReturnInterface,
  UrlParamsInterface
} from "../../interfaces/url-params";

// styled components
const Option = styled.span`
  align-items: center;
  display: flex;
`;

// typescript props
type Props = {
  className?: string;
  status: UrlParamsInterface["status"];
  testId?: string;
  updateUrlParams: UrlParamsHookReturnInterface["updateUrlParams"];
  workItemsData?: EpicDetailsInterface | GroupsListInterface;
};

const StatusFilter = ({
  className,
  status,
  testId = "testId",
  updateUrlParams,
  workItemsData
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-types-of-work-filter`;
  const { trackEvent } = useTracking();
  const [areFiltersDisabled, setAreFiltersDisabled] = useState(false);
  const statusTotals = workItemsData?.statusTotals;

  const optionsList = ([
    {
      helpTextKey: "open",
      id: workMetadataStatusTypes.OPEN
    },
    {
      helpTextKey: "in-progress",
      id: workMetadataStatusTypes.IN_PROGRESS
    },
    {
      helpTextKey: "done",
      id: workMetadataStatusTypes.DONE
    }
  ] as Array<{
    id: string;
    helpTextKey: "open" | "in-progress" | "done";
  }>).map(m => {
    return {
      id: m.id,
      isDisabled: areFiltersDisabled,
      isSelected: status.includes(m.id),
      name: (
        <Option>
          {urlParamsValuesLabels[m.id]} (
          {statusTotals?.find((t: ResponseTotalInterface) => t.status === m.id)
            ?.total || 0}
          ){" "}
          <InfoIcon content={m.helpTextKey} testId={`${thisTestId}-${m.id}`} />
        </Option>
      )
    };
  });

  // fixing ts error with this:
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedToggle = useMemo(
    () =>
      debounce(updatedStatus => {
        updateUrlParams({
          [urlParamKeys.STATUS]: updatedStatus
        });
        setAreFiltersDisabled(false);
      }, 500),
    [updateUrlParams]
  );

  function onToggleStatus(updated: Array<UiFilterSelectionStatusInterface>) {
    const updatedStatus = updated
      .filter((u: UiFilterSelectionStatusInterface) => u.isSelected)
      .map((s: UiFilterSelectionStatusInterface) => s.id);

    setAreFiltersDisabled(true);
    debouncedToggle(updatedStatus);
    trackEvent({
      label: "ui-filter-status-change",
      value: `changed status to ${(isEmpty(updatedStatus)
        ? optionsList.map(o => o.id)
        : updatedStatus
      ).join(",")}`
    });
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <EpicFilterCheckboxList
        header="Status"
        list={optionsList}
        name="status"
        onUpdateSelection={onToggleStatus}
        testId="status"
      />
    </div>
  );
};

export default StatusFilter;
