import React, { Component, useEffect } from "react";
import ErrorLevelMedium from "../../components/ErrorLevels/ErrorLevelMedium";
import { ErrorBoundary } from "@sentry/react";
import styled from "styled-components/macro";
import { useTracking } from "../../hooks/useTracking";

const StyledContainer = styled("div")`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`;

const UnknownUser = () => {
  const { trackEvent } = useTracking();

  // BEWARE: adding trackEvent to dependency array will result in infinite loop
  useEffect(() => {
    trackEvent({
      e: { type: "page-load" },
      label: "page-load",
      value: `unknown-user`
    });
  }, []);
  return (
    <StyledContainer>
      <ErrorBoundary>
        <ErrorLevelMedium
          message="It looks like we couldn't recognize you!"
          testId="unknown-user-error-message"
        />
      </ErrorBoundary>
    </StyledContainer>
  );
};

export default UnknownUser;
