import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";

// components
import Chart from "../Chart/Chart";

// config
import generateConfig from "./config";

// exporting for targeting in tests
export const ChartPrCadence = ({
  className,
  data = {},
  isFetchingData = false,
  onClickSeries = () => {
    // do nothing
  },
  selectedDateRange,
  testId = "testId",
  theme
}) => {
  const configParams = useMemo(() => {
    return {
      data,
      onClickSeries,
      selectedDateRange,
      theme
    };
  }, [data, onClickSeries, selectedDateRange, theme]);

  return (
    <Chart
      chart={{
        config: generateConfig(configParams),
        id: "pr-cadence-chart",
        isFetchingData
      }}
      className={className}
      testId={`${testId}-pr-cadence-chart`}
    />
  );
};

ChartPrCadence.propTypes = {
  data: PropTypes.array,
  isFetchingData: PropTypes.bool,
  onClickSeries: PropTypes.func.isRequired,
  selectedDateRange: PropTypes.object,
  testId: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default withTheme(ChartPrCadence);
