import React from "react";
import styled from "styled-components/macro";

// components
import ReportsList from "../ReportsList/ReportsList";
import PageTitle from "../../components/PageTitle/PageTitle";
import TemplateHeader from "../../components/TemplateHeader/TemplateHeader";
import UiCenteringWrapper from "../../components/UiCenteringWrapper/UiCenteringWrapper";

// constants
import { pageIds, pageMetadata } from "../../constants/constants";

// styled components
const ContentWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;
const Title = styled(PageTitle)`
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding-bottom: 0.8rem;
`;
const StyledTemplateHeader = styled(TemplateHeader)`
  margin-bottom: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const ReportsBaseTemplate = ({ className, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-reports-base-template`;

  return (
    <div className={className} data-testid={thisTestId}>
      <ContentWrapper>
        <StyledTemplateHeader
          content={
            <Title
              title={pageMetadata[pageIds.REPORTS].title}
              testId={pageIds.REPORTS}
            />
          }
          testId={pageIds.REPORTS}
        />
        <UiCenteringWrapper>
          <ReportsList testId={thisTestId} />
        </UiCenteringWrapper>
      </ContentWrapper>
    </div>
  );
};

export default ReportsBaseTemplate;
