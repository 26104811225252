import React from "react";
import PropTypes from "prop-types";

// components
import Header from "../Header/Header";
import TextBlock from "../TextBlock/TextBlock";

const ErrorLevelMedium = ({ message, testId }) => {
  if (!message || !testId) {
    return null;
  }

  return (
    <div>
      <Header
        header="3"
        size="lg"
        style={{ marginBottom: "2rem" }}
        testId={testId}
        weight="bold"
      >
        Yikes.
      </Header>
      <TextBlock
        size="sm"
        style={{ marginBottom: "2rem" }}
        testId={`${testId}-error-message`}
      >
        {message}
      </TextBlock>
      <TextBlock
        size="sm"
        style={{ marginBottom: "2rem" }}
        testId={`${testId}-reload-message`}
      >
        We&rsquo;ve noted it and are investigating!
      </TextBlock>
    </div>
  );
};

ErrorLevelMedium.propTypes = {
  message: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired
};
export default ErrorLevelMedium;
