import { groupBy, mapValues } from "lodash";

export default (data, weekRanges) => {
  const groupedRows = groupBy(
    data.rows,
    d =>
      weekRanges.find(w => w.start <= d.mergedDtm && w.end > d.mergedDtm)?.start
  );
  return {
    ...data,
    groupedRows: mapValues(groupedRows, prs => {
      const sorted = prs.sort((p1, p2) =>
        (p1.mergedDtm || "").localeCompare(p2.mergedDtm)
      );
      const reviewTimesSorted = prs
        .map(pr => pr.reviewTimeHours)
        .filter(m => !!m)
        .sort((a, b) => a - b);

      const medianIndex = Math.floor(reviewTimesSorted.length / 2);
      const medianReviewTimeHours = !reviewTimesSorted.length
        ? 0
        : reviewTimesSorted.length % 2 === 0
        ? (reviewTimesSorted[medianIndex - 1] +
            reviewTimesSorted[medianIndex]) /
          2.0
        : reviewTimesSorted[medianIndex];

      return {
        medianReviewTimeInHours: medianReviewTimeHours,
        numMerged: prs.length,
        prs: sorted
      };
    }),
    weekRanges
  };
};
