import React, { useMemo, useState } from "react";
import { debounce, isEmpty } from "lodash";

// components
import LoadingStates from "../LoadingStates/LoadingStates";
import EpicFilterCheckboxList from "../EpicFilterCheckboxList/EpicFilterCheckboxList";
import InfoIcon from "../InfoIcon/InfoIcon";

// constants
import { urlParamKeys } from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import {
  EpicDetailsInterface,
  GroupsListInterface,
  ResponseTotalInterface
} from "../../interfaces/work-items";
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";
import {
  UrlParamsInterface,
  UrlParamsHookReturnInterface
} from "../../interfaces/url-params";

// typescript props
type Props = {
  className?: string;
  isFetching: boolean;
  isSuccess: boolean;
  repositories: UrlParamsInterface["repositories"];
  testId?: string;
  updateUrlParams: UrlParamsHookReturnInterface["updateUrlParams"];
  workItemsData?: EpicDetailsInterface | GroupsListInterface;
};

const RepositoryFilter = ({
  className,
  isFetching,
  isSuccess,
  testId = "testId",
  repositories,
  updateUrlParams,
  workItemsData
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-projects-selector`;
  const { trackEvent } = useTracking();
  const [areFiltersDisabled, setAreFiltersDisabled] = useState(false);
  const repositoryTotals = workItemsData?.repositoryTotals;
  const repositoriesList =
    repositoryTotals?.map(
      (t: ResponseTotalInterface) => t.repository as string
    ) || [];

  const optionsList = repositoriesList.map(r => {
    return {
      id: r,
      isDisabled: areFiltersDisabled,
      isSelected: repositories?.includes(r),
      name: (
        <span data-heap-redact-text="true">
          {r} (
          {repositoryTotals?.find(
            (t: ResponseTotalInterface) => t.repository === r
          )?.total || 0}
          )
        </span>
      )
    };
  });

  // fixing ts error with this:
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedToggle = useMemo(
    () =>
      debounce(updatedRepositories => {
        updateUrlParams({
          [urlParamKeys.REPOSITORIES]: updatedRepositories
        });
        setAreFiltersDisabled(false);
      }, 500),
    [updateUrlParams]
  );

  function onToggleRepositories({
    allRepositories,
    updated
  }: {
    allRepositories: Array<string>;
    updated: Array<UiFilterSelectionStatusInterface>;
  }) {
    const updatedRepositories = updated
      .filter((u: UiFilterSelectionStatusInterface) => u.isSelected)
      .map(r => r.id);
    setAreFiltersDisabled(true);
    trackEvent({
      label: "ui-filter-repositories-change",
      value: `changed repositories to ${(isEmpty(updatedRepositories)
        ? allRepositories
        : allRepositories.filter(r => updatedRepositories?.includes(r))
      ).join(",")}`
    });
    debouncedToggle(updatedRepositories);
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <LoadingStates
        isSpinningState={isFetching}
        isNoDataState={isSuccess && !repositoriesList.length}
        noDataContent={null}
        content={
          <EpicFilterCheckboxList
            header={
              <>
                Repositories{" "}
                <InfoIcon content="repositories" testId={thisTestId} />
              </>
            }
            list={optionsList}
            name="repositories"
            onUpdateSelection={updated =>
              onToggleRepositories({
                allRepositories: repositoriesList,
                updated
              })
            }
            testId="repositories"
          />
        }
        testId={thisTestId}
      />
    </div>
  );
};

export default RepositoryFilter;
