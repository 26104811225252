import React, { useMemo } from "react";
import { compact, isArray, omit, pick, reject } from "lodash";
import styled from "styled-components/macro";

// components
import Icon from "../Icon/Icon";

// hooks
import { useWorkItemsData } from "../../hooks/useWorkItemsData";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { UrlParamsInterface } from "../../interfaces/url-params";

// utils
import { hexToRgb } from "../../utils/color";

// constants
import {
  ownerContributorAlternativeLabels,
  workDeepDiveSections,
  urlGlobalParams,
  urlParamsValuesLabels,
  workItemsUrlParamKeys
} from "../../constants/constants";
import { useTracking } from "../../hooks/useTracking";
import { getUrlParamsDefaultValues } from "../../utils/url-params";
const filterPillsParams = [
  workItemsUrlParamKeys.SHOW_RELATED,
  workItemsUrlParamKeys.SPRINT_WORK_TYPES,
  workItemsUrlParamKeys.USERS,
  workItemsUrlParamKeys.SPRINT_WORK_OTHER,
  workItemsUrlParamKeys.OWNER_CONTRIBUTOR,
  workItemsUrlParamKeys.CALLOUTS,
  workItemsUrlParamKeys.WORK_ITEM_TYPES,
  workItemsUrlParamKeys.STATUS,
  workItemsUrlParamKeys.PROJECT_IDS,
  workItemsUrlParamKeys.REPOSITORIES
];

// styled components
const Pill = styled.button`
  align-items: center;
  background: ${props => {
    return hexToRgb({ hex: props.theme.colors.all.white, opacity: 0.5 });
  }};
  border-radius: 5rem;
  border: 0;
  display: flex;
  font-size: 1.1rem;
  line-height: 1.4rem;
  padding: 0.5rem 0.8rem;
`;
const X = styled(Icon)`
  align-items: center;
  color: ${props => props.theme.colors.all.wolverine};
  display: flex;
  margin-left: 0.8rem;
`;
const ClearFilters = styled.button`
  background: 0;
  border: 0;
  font-size: 1.1rem;
  margin: 0;
  padding-bottom: 0.2rem;
`;

const FilterPills = () => {
  const thisTestId = "filter-pills";
  const { trackEvent } = useTracking();
  const { updateUrlParams, urlParams, workDeepDiveParams } = useUrlParams();
  const { data } = useWorkItemsData({
    options: { type: workDeepDiveParams.selectedSection }
  });
  const enabledDataIds = [
    ...(data?.calloutTotals.map(t => t.callout) || []),
    ...(data?.typesOfWorkTotals.map(t => t.typeOfWork) || [])
  ];

  // pick the filter pill params off the url params, then make an entries array
  // of it. foreach through the entries, expanding any array values so that
  // each value of the array gets its own tuple with the key.
  const flattenedParamValues: Array<Array<string | boolean>> = useMemo(() => {
    const result: Array<Array<string | boolean>> = [];
    Object.entries(pick(urlParams, filterPillsParams)).forEach(entry => {
      const [key, paramValue] = entry as Array<
        string | boolean | Array<string>
      >;
      if (isArray(paramValue)) {
        paramValue.map((v: string) => result.push([key as string, v]));
      } else {
        result.push([key as string, paramValue]);
      }
    });
    return result;
  }, [urlParams]);

  function getDisplayValue(
    key: keyof UrlParamsInterface,
    paramValue: string | boolean
  ) {
    if (key === workItemsUrlParamKeys.PROJECT_IDS) {
      const project = data?.projects.find(p => p.projectId === paramValue);
      return !!project ? project.projectName : paramValue;
    }
    if (
      key === workItemsUrlParamKeys.OWNER_CONTRIBUTOR &&
      workDeepDiveParams.selectedSection === workDeepDiveSections.PR_WORKFLOW
    ) {
      return ownerContributorAlternativeLabels[paramValue as string];
    }
    return (
      urlParamsValuesLabels[key] ||
      urlParamsValuesLabels[paramValue as string] ||
      paramValue
    );
  }

  function onClickPill({
    e,
    key,
    value
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    key: keyof UrlParamsInterface;
    value: string | boolean;
  }) {
    const currentValue = urlParams[key];
    updateUrlParams({
      [key]: isArray(currentValue)
        ? reject(currentValue, c => c === value)
        : null
    });
    trackEvent({
      e,
      label: "filter-pills",
      value: `clicked to remove ${value} filter value`
    });
  }

  function onClickClearFilters(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    updateUrlParams(
      omit(
        getUrlParamsDefaultValues({ timerange: urlParams.timerange }),
        urlGlobalParams
      ),
      urlGlobalParams
    );
    trackEvent({
      e,
      label: "filter-pills-clear-filters",
      value: `clicked`
    });
  }

  const displayPills = compact(
    flattenedParamValues.map(entry => {
      const [key, paramValue] = entry as Array<
        keyof UrlParamsInterface | string | boolean
      >;
      if (
        !paramValue ||
        (key === workItemsUrlParamKeys.PROJECT_IDS && !data) ||
        ((key === workItemsUrlParamKeys.CALLOUTS ||
          key === workItemsUrlParamKeys.SPRINT_WORK_TYPES) &&
          !enabledDataIds.includes(paramValue as string))
      ) {
        return null;
      }
      const pillId = `filter-pills-${key}-${paramValue}`;
      return (
        <Pill
          key={pillId}
          data-testid={pillId}
          onClick={e =>
            onClickPill({
              e,
              key: key as keyof UrlParamsInterface,
              value: paramValue
            })
          }
          data-heap-redact-text={
            key === workItemsUrlParamKeys.PROJECT_IDS ? "true" : null
          }
        >
          {getDisplayValue(
            key as keyof UrlParamsInterface,
            paramValue as string | boolean
          )}
          <X icon="close-solid" testId={thisTestId} />
        </Pill>
      );
    })
  );

  const clearFilters = !!displayPills.length ? (
    <ClearFilters
      onClick={e => onClickClearFilters(e)}
      data-testid="filter-pills-clear-filters"
      key="filter-pills-clear-filters"
    >
      Clear filters
    </ClearFilters>
  ) : null;

  return <>{[...displayPills, clearFilters]}</>;
};

export default FilterPills;
