import React from "react";
import PropTypes from "prop-types";
import styled, { createGlobalStyle, css } from "styled-components/macro";

// components
import BootstrapModal from "react-bootstrap/Modal";
import BootstrapModalBody from "react-bootstrap/ModalBody";
import BootstrapModalHeader from "react-bootstrap/ModalHeader";
import CloseButton from "../CloseButton/CloseButton";

const GlobalStyle = createGlobalStyle`
  .modal-dialog {
    ${props => {
      const borderString = `${props.theme.borders.widths.md} solid ${props.theme.colors.border}`;

      return css`
        border: ${borderString};
      `;
    }};
    max-width: 120rem;
  }

  .modal-content {
    border-radius: 0;
  }

  .meeting-classification-override-modal,
  .flexible-teams-modal {
    align-items: center;
    display: flex;
    height: 100vh;
    border: 0;
    margin: auto;
    max-width: 64rem;

    & .modal-header {
      padding: 2rem 2rem 0;
    }

    & .modal-body {
      padding: 0 2rem 2rem;
    }

    & .modal-content {
      border: 0;
    }
  }

  .annotation-modal .modal-content {
    background-color: ${props => props.theme.colors.all.lightJean};
  }

  .annotation-modal.modal-dialog {
    max-width: 79.2rem;
    border: none;
    .modal-body {
      padding: 3.2rem 2.4rem;
    }
    .modal-header {
      padding: 3.2rem 2.4rem 0 2.4rem;
      align-items: flex-end;
    }
  }

  .customization-modal.modal-dialog {
    max-width: 66rem;
    border: none;
    .modal-body {
      padding: 3.2rem 2.4rem;
    }
    .modal-header {
      padding: 3.2rem 2.4rem 0 2.4rem;
      align-items: flex-end;
    }
  }

  &.ui-filter-overlay-modal {
    display: none;
  }
`;

// styled components
// FIXME: hacky fix for react - bootstrap passing non - dom
// attributes through to actual dom
// https://gitter.im/styled-components/styled-components?at=5941015fe531dbc905e05a93
// prefer to fix this by fixing the styling here, maybe adding a wrapper so that
// we're not styling the actual react-boostrap components? or maybe not having
// to bespoke style them but using modal as-is?
const StyledBootstrapModalHeader = styled(({ hasHeaderContent, ...rest }) => (
  <BootstrapModalHeader {...rest} />
))`
  border: 0;
  display: flex;
  justify-content: ${props =>
    props.hasHeaderContent ? "space-between" : "flex-end"};
  padding-right: 1.5rem;
  padding-top: 3.2rem;
  padding-bottom: 0;

  &:empty {
    padding: 0;
  }
`;

const Modal = ({
  allowClose = true,
  dialogClassName,
  onHide = () => {
    // do nothing
  },
  onShow = () => {
    // do nothing
  },
  popupContent,
  headerContent = null,
  testId
}) => {
  if (!popupContent || !testId) {
    return null;
  }
  return (
    <div data-testid={`${testId}-modal`}>
      <GlobalStyle />
      <BootstrapModal
        dialogClassName={dialogClassName}
        onHide={onHide}
        // onShow doesn't seem to emit an event right now
        // so faking one for the moment
        onShow={() =>
          onShow({ type: "load", target: { id: `${testId}-modal` } })
        }
        show={true}
      >
        <StyledBootstrapModalHeader hasHeaderContent={!!headerContent}>
          {headerContent}
          {allowClose && <CloseButton onClick={onHide} testId={testId} />}
        </StyledBootstrapModalHeader>
        <BootstrapModalBody>{popupContent}</BootstrapModalBody>
      </BootstrapModal>
    </div>
  );
};

Modal.propTypes = {
  allowClose: PropTypes.bool,
  dialogClassName: PropTypes.string,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  popupContent: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  testId: PropTypes.string.isRequired
};
export default Modal;
