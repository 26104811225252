import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { isNil } from "lodash";

// components
import ChartOverviewDeepWorkScatterplot from "../ChartOverviewDeepWorkScatterplot/ChartOverviewDeepWorkScatterplot";
import EmptyState from "../EmptyState/EmptyState";
import InfoIcon from "../InfoIcon/InfoIcon";
import LoadingStates from "../LoadingStates/LoadingStates";
import OverviewGroupBySelector from "../OverviewGroupBySelector/OverviewGroupBySelector";
import TimeRange from "../TimeRange/TimeRange";

// interfaces
import {
  Benchmark,
  OrgDeepWorkByGroup,
  OrgTeamItems
} from "../../interfaces/organization";
import { formatTimestamp } from "../../utils/date";

// styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.header`
  display: flex;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  align-items: center;
  justify-content: space-between;
`;
const EmptyStateWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 51rem;
`;
const PrimaryTitle = styled.h4`
  align-items: center;
  display: flex;
`;
const HeaderText = styled.span`
  font-family: ${props => props.theme.fonts.header.name}, sans-serif;
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
`;
const Subtitle = styled.h4`
  align-items: center;
  display: flex;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;
const Selector = styled(OverviewGroupBySelector)`
  align-self: flex-end;
`;

// typescript props
type Props = {
  className?: string;
  availableReportGroups: Array<string> | null;
  availableRoles: Array<string> | null;
  availableTeams: OrgTeamItems | null;
  benchmark?: Benchmark;
  data: OrgDeepWorkByGroup | null;
  groupBy: string;
  isEmptyData: boolean;
  isError: boolean;
  isFetching: boolean;
  testId?: string;
};

const OverviewDeepWorkByGroup = ({
  className,
  availableReportGroups,
  availableRoles,
  availableTeams,
  benchmark,
  data,
  groupBy,
  isEmptyData,
  isError,
  isFetching,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-overview-deep-work-by-group`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <Header>
        <PrimaryTitle>
          <HeaderText>Daily Trend</HeaderText>
          <InfoIcon content={"overview-by-group"} />
        </PrimaryTitle>
        <Subtitle>
          {isNil(data) ? null : (
            <TimeRange
              config={{
                format: { start: "MM/DD/YYYY", end: "MM/DD/YYYY" }
              }}
              timerange={{
                start: data.startDate,
                end: data.endDate
              }}
              testId={thisTestId}
            />
          )}
        </Subtitle>
      </Header>
      <Selector
        availableReportGroups={availableReportGroups}
        availableRoles={availableRoles}
        availableTeams={availableTeams}
        testId={thisTestId}
      />
      <LoadingStates
        isSpinningState={isFetching}
        isNoDataState={isError || isEmptyData || isNil(data)}
        noDataContent={
          <EmptyStateWrapper>
            <EmptyState
              body={
                isError ? (
                  "We ran into an error while trying to fetch your data."
                ) : (
                  <>
                    There doesn&rsquo;t seem to be any data for this date range
                    with the filters that are applied.
                  </>
                )
              }
              header={isError ? "Uh Oh!" : "Hmmm."}
              testId={testId}
            />
          </EmptyStateWrapper>
        }
        content={
          isError || isEmptyData || isNil(data) ? null : (
            <ChartOverviewDeepWorkScatterplot
              benchmark={benchmark}
              data={data}
              groupBy={groupBy}
              testId={thisTestId}
              title={`Average daily hours of deep work for ${formatTimestamp({
                format: "MM/DD/YYYY",
                timestamp: data.startDate
              })}-${formatTimestamp({
                format: "MM/DD/YYYY",
                timestamp: data.startDate
              })}`}
            />
          )
        }
        testId={thisTestId}
      />
    </Container>
  );
};

export default OverviewDeepWorkByGroup;
