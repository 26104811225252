import React from "react";
import styled from "styled-components/macro";

// components
import ContentWithIcon from "../ContentWithIcon/ContentWithIcon";
import { workMetadataActivityTypes } from "../../constants/constants";
import StoryPoints from "../StoryPoints/StoryPoints";

// styled components
const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  font-family: ${props => props.theme.fonts.primary.name}, sans-serif;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  justify-content: flex-start;
`;
const Activity = styled(ContentWithIcon)`
  font-size: 1.1rem;
  white-space: nowrap;

  & + & {
    margin-left: 2rem;
  }
`;
const StoryPointsContainer = styled.div`
  display: flex;
  > div {
    margin: 0 0.5rem 0 2rem;
  }
  font-size: 1.1rem;
  align-items: center;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  visibleActivities?: Array<string>;
  showStoryPoints?: boolean;
};

const WorkMetadataActivitiesKey = ({
  className,
  testId = "testId",
  visibleActivities = [
    workMetadataActivityTypes.BUG,
    workMetadataActivityTypes.ISSUE,
    workMetadataActivityTypes.SUB_TASK,
    workMetadataActivityTypes.PR
  ],
  showStoryPoints = true
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-work-metadata-activities-key`;

  const activities = [
    {
      content: "Issues",
      icon: "issue",
      isVisible: visibleActivities.includes(workMetadataActivityTypes.ISSUE)
    },
    {
      content: "Subtasks",
      icon: "subtask",
      isVisible: visibleActivities.includes(workMetadataActivityTypes.SUB_TASK)
    },
    {
      content: "Bugs",
      icon: "bug",
      isVisible: visibleActivities.includes(workMetadataActivityTypes.BUG)
    },
    {
      content: "PRs",
      icon: "pr",
      isVisible: visibleActivities.includes(workMetadataActivityTypes.PR)
    }
  ];

  return (
    <Container className={className} data-testid={thisTestId}>
      {activities
        .filter(a => a.isVisible)
        .map(a => (
          <Activity
            content={a.content}
            icon={a.icon}
            testId={thisTestId}
            key={a.icon}
          />
        ))}
      {showStoryPoints && (
        <StoryPointsContainer>
          <StoryPoints storyPoints={0} />
          Story Points
        </StoryPointsContainer>
      )}
    </Container>
  );
};

export default WorkMetadataActivitiesKey;
