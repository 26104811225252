import { actionTypes } from "../constants/actionTypes";

/**
 * return action to store chart in state
 * @param {Object} flags
 */
export const storeFlags = flags => {
  return {
    type: actionTypes.STORE_FLAGS,
    payload: { flags }
  };
};
