import React from "react";
import { isUndefined } from "lodash";

// components
import { Redirect } from "react-router-dom";
import LoadingStates from "../LoadingStates/LoadingStates";
import UserWithNoUplevelAccountPage from "../UserWithNoUplevelAccountPage/UserWithNoUplevelAccountPage";

// hooks
import { useInitialData } from "../../hooks/useInitialData";
import { useSelector } from "react-redux";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

// typescript props
type Props = {
  children: React.ReactNode;
};

const DataLoadWrapper = ({ children }: Props) => {
  const [uiIsReady, hasInvalidRoles] = useInitialData();
  const userHasUplevelAccount = useSelector(
    (state: AppStateInterface) => state.session?.userHasUplevelAccount
  );

  // if came back with invalid roles, bounce to the unknown user route
  if (uiIsReady && hasInvalidRoles) {
    return <Redirect to="/unknown-user" />;
  }

  if (!isUndefined(userHasUplevelAccount) && !userHasUplevelAccount) {
    return <UserWithNoUplevelAccountPage />;
  }

  return (
    <LoadingStates
      isSpinningState={!uiIsReady}
      content={children}
      testId="data-load-wrapper"
    />
  );
};

export default DataLoadWrapper;
