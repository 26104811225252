import React from "react";
import styled, { css } from "styled-components/macro";

// components
import Icon from "../Icon/Icon";

// interfaces
import SlackAvatar from "../SlackAvatar/SlackAvatar";
import { UserReportWithMetadataInterface } from "../../interfaces/user";

// styled components
const Button = styled.button`
  align-items: center;
  background: ${props => props.theme.colors.all.white};
  border: 0;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  text-align: left;
  width: 100%;
`;
const PersonButton = styled(Button)`
  align-items: flex-start;
`;
const PersonWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
  padding: 0.75rem 1rem;
`;
const DirectReportsButton = styled(Button)<{ isActive: boolean }>`
  padding: 0.5rem 1rem;

  ${props => {
    if (props.isActive) {
      return css`
        background: ${props.theme.colors.all.jean};
        box-shadow: inset -${props.theme.borders.widths.lg} 0 ${props.theme.colors.all.auroraTeal};
      `;
    }
  }}
`;
const Metadata = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  padding-top: 0.5rem;
`;
const Avatar = styled(SlackAvatar)`
  border-radius: 50%;
  border: ${props => `.2rem solid ${props.theme.colors.all.wolverine}`};
  height: 3rem;
  margin-right: 1rem;
  width: 3rem;
`;
const Name = styled.header`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  line-height: ${props => props.theme.fonts.primary.sizes.xs};
`;
const InlineArrow = styled(Icon)`
  margin-left: 1rem;
`;
const Title = styled.p`
  font-size: 1.1rem;
  margin: 0;
`;
const Footer = styled.footer`
  border-top: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;
const DirectReports = styled.div`
  flex: 1;
  font-size: 1.1rem;
`;
const TeamIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  margin-right: 1rem;
`;
const Chevron = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  font-size: 1.1rem;
`;

// typescript props
type Props = {
  className?: string;
  isActive?: boolean;
  onClickPerson: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickReportsNav: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  person: UserReportWithMetadataInterface;
  showArrowInline?: boolean;
  showDirectReports?: boolean;
  testId?: string;
};

const OrganizationNavigationMenuCard = ({
  className,
  isActive = false,
  onClickPerson,
  onClickReportsNav,
  person,
  showArrowInline = false,
  showDirectReports = true,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-organization-navigation-menu-card`;
  const hasDirectReports = !!person.team?.teamMembers.length;

  const personMetadata = (
    <>
      <Metadata>
        <Avatar
          altText={person.name}
          src={person.slackAvatar}
          testId={`${thisTestId}-${person.id}`}
        />
        <div data-heap-redact-text="true">
          <Name>
            {person.name}
            {showArrowInline ? (
              <InlineArrow icon="line-arrow-right" testId={thisTestId} />
            ) : null}
          </Name>
          <Title>{person.title}</Title>
        </div>
      </Metadata>
      {showArrowInline ? null : hasDirectReports ? (
        <Icon icon="line-arrow-right" testId={thisTestId} />
      ) : null}
    </>
  );

  return (
    <div className={className} data-testid={thisTestId}>
      {hasDirectReports ? (
        <PersonButton
          data-testid={`${thisTestId}-${person.id}-person-button`}
          onClick={onClickPerson}
        >
          {personMetadata}
        </PersonButton>
      ) : (
        <PersonWrapper>{personMetadata}</PersonWrapper>
      )}
      {showDirectReports && hasDirectReports ? (
        <Footer>
          <DirectReportsButton
            isActive={isActive}
            onClick={onClickReportsNav}
            data-testid={`${thisTestId}-${person.id}-reports-nav-button`}
          >
            <DirectReports>
              <TeamIcon icon="team" testId={thisTestId} />
              {
                person.team?.teamMembers.filter(t => t.id !== person.id).length
              }{" "}
              Direct Reports
            </DirectReports>
            <Chevron
              icon={isActive ? "arrow-left" : "arrow-right"}
              testId={thisTestId}
            />
          </DirectReportsButton>
        </Footer>
      ) : null}
    </div>
  );
};

export default OrganizationNavigationMenuCard;
