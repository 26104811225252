import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { Route, Switch, useLocation } from "react-router-dom";

// components
import MeetingClassificationsChartWrapper from "../MeetingClassificationsChartWrapper/MeetingClassificationsChartWrapper";
import MeetingTimeChartWrapper from "../MeetingTimeChartWrapper/MeetingTimeChartWrapper";
import RouteLinkTabs from "../RouteLinkTabs/RouteLinkTabs";

// constants
import {
  teamDeepDiveSections,
  meetingInsightsMeasureLabels,
  meetingInsightsMeasurePathParams,
  pageIds
} from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import { omit } from "lodash";

// styled components
const MeetingInsightsTabs = styled(RouteLinkTabs)`
  margin: ${props => props.theme.grid.gap} 0;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const MeetingInsights = ({
  className,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-meeting-insights`;
  const location = useLocation();
  const { teamDeepDiveParams, getTeamDeepDivePath, urlParams } = useUrlParams();
  const selectedUser = teamDeepDiveParams.selectedUser;
  const selectedMetric =
    meetingInsightsMeasurePathParams[teamDeepDiveParams.selectedMetric];
  const user = useSelector((state: AppStateInterface) => state.user);
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const canViewMeetingLengthTab = flags?.["team-deep-dive-meeting-length"];

  const displayParams = canViewMeetingLengthTab
    ? meetingInsightsMeasurePathParams
    : omit(meetingInsightsMeasurePathParams, ["MEETING_LENGTH"]);
  const tabs = Object.values(displayParams).map(metric => {
    return {
      id: metric,
      path: getTeamDeepDivePath({
        selectedSection: teamDeepDiveSections.MEETING_INSIGHTS,
        selectedMetric: metric
      }),
      title: meetingInsightsMeasureLabels[metric]
    };
  });

  return (
    <div className={className} data-testid={thisTestId}>
      <MeetingInsightsTabs
        defaultTabId={selectedMetric}
        tabs={tabs}
        testId="meeting-insights-metrics"
      />
      <Switch>
        {canViewMeetingLengthTab ? (
          <Route
            exact
            path={`/${pageIds.TEAM_DEEP_DIVE}/${teamDeepDiveSections.MEETING_INSIGHTS}/${meetingInsightsMeasurePathParams.MEETING_LENGTH}`}
          >
            <MeetingTimeChartWrapper user={user} selectedUser={selectedUser} />
          </Route>
        ) : null}
        <Route
          path={`/${pageIds.TEAM_DEEP_DIVE}/${teamDeepDiveSections.MEETING_INSIGHTS}(/${meetingInsightsMeasurePathParams.MEETING_DISTRIBUTION})?`}
        >
          <MeetingClassificationsChartWrapper
            accessToken={accessToken}
            tenantId={tenantId}
            location={location}
            user={user}
            timerange={urlParams.timerange}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default MeetingInsights;
