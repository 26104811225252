import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import ChartPeopleHealth from "../ChartPeopleHealth/ChartPeopleHealth";
import MetricCalloutTile from "../MetricCalloutTile/MetricCalloutTile";
import {
  DeepDiveData,
  PeopleMetrics,
  SelectablePeopleMetrics
} from "../../interfaces/team-deep-dive";
import ErrorLevelMedium from "../ErrorLevels/ErrorLevelMedium";
import { ErrorBoundary } from "@sentry/react";
import {
  healthFactorsMetadata,
  peopleHealthSignals,
  Signals,
  SOURCES_DESCRIPTION_MAP,
  teamDeepDiveSections,
  urlParamKeys
} from "../../constants/constants";
import { UserReportWithMetadataInterface } from "../../interfaces/user";
import RouteLinkTabs from "../RouteLinkTabs/RouteLinkTabs";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import informationText from "../../constants/peopleHealthUnderstandability.json";
import InfoPopups from "../InfoPopups/InfoPopups";
import { useUrlParams } from "../../hooks/useUrlParams";
import { useHistory } from "react-router-dom";
import LoadingStates from "../LoadingStates/LoadingStates";
import EmptyState from "../EmptyState/EmptyState";
import { getSelectableTeamMembers } from "../../utils/people-picker";
import InformationText from "../../constants/informationText.json";

const Chart = styled.figure`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
`;

const StyledMetricIcon = styled(Icon)`
  font-size: 1.1rem;
  &:hover {
    color: ${props => props.theme.colors.brand.auroraTeal};
  }
`;

const ChartTitle = styled.div`
  position: relative;
  margin-bottom: 4.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding-bottom: 1.6rem;
`;

const TooltipPopup = styled.div`
  display: flex;
  flex-direction: column;
`;

const SourcesList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const SourceIcon = styled(Icon)`
  margin-right: 0.8rem;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  color: ${props => props.theme.colors.all.wolverine};
`;

const MetricTileContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const PeopleHealthTabs = styled(RouteLinkTabs)`
  padding-top: ${props => props.theme.grid.gap};
`;
const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
`;
const StyledEmptyState = styled(EmptyState)`
  > div {
    max-width: 100rem;
  }
`;
const DisclaimerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;
const Disclaimer = styled.em`
  color: ${props => props.theme.colors.all.storm};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

type Props = {
  data?: DeepDiveData;
  isFetchingData: boolean;
  error: boolean;
  theme: any;
  selectedUser: UserReportWithMetadataInterface | "team";
};

const PeopleHealth = ({
  data,
  isFetchingData,
  error,
  selectedUser,
  theme
}: Props): JSX.Element => {
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const user = useSelector((state: AppStateInterface) => state.user);
  const {
    teamDeepDiveParams,
    getTeamDeepDivePath,
    urlParams,
    updateUrlParams
  } = useUrlParams();
  const history = useHistory();
  const selectedMetric = teamDeepDiveParams.selectedMetric as SelectablePeopleMetrics;
  const selectedSection = teamDeepDiveParams.selectedSection as string;

  const selectableTeamMembers = getSelectableTeamMembers({
    user,
    flags,
    teamDeepDiveParams: {
      ...teamDeepDiveParams,
      selectedMetric: selectedMetric
    },
    urlParams
  });

  const canViewSelectedData =
    (selectedUser === "team" &&
      (flags?.["transparent-data-for-peers"] ||
        selectedMetric !== Signals.ALWAYS_ON)) ||
    (selectedUser !== "team" &&
      selectableTeamMembers.includes(selectedUser.id));
  const setSelectedMetric = (
    updatedSelectedMetric: SelectablePeopleMetrics
  ) => {
    const teamDeepDivePath = getTeamDeepDivePath({
      selectedSection: teamDeepDiveSections.HEALTH_METRICS,
      selectedMetric: updatedSelectedMetric
    });
    history.push(teamDeepDivePath);
  };
  const setSelectedUser = (updatedSelectedUser: string) => {
    updateUrlParams({
      [urlParamKeys.SELECTED_USER]: updatedSelectedUser
    });
  };
  const showTabs =
    teamDeepDiveParams.selectedSection !== teamDeepDiveSections.PEOPLE_HEALTH;
  const showMetricTiles = flags?.["team-deep-dive-metric-callouts"];

  // FIXME: replace these with true redirects
  useEffect(() => {
    // If one person is selected, the People Health metric no longer applies.  It applies to teams.
    //  Move players to Deep Work if they are in People Health when they select an individual.
    if (selectedMetric === "OVERALL_TEAM_HEALTH" && selectedUser !== "team") {
      setSelectedUser("team");
    }

    if (
      selectedMetric === "OVERALL_TEAM_HEALTH" &&
      selectedSection === "health-metrics"
    ) {
      setSelectedMetric("DEEP_WORK");
    }
  }, [selectedSection, selectedMetric, selectedUser]);

  const selectableMetricOptions = peopleHealthSignals.map(metric => {
    return {
      title: healthFactorsMetadata[metric]?.title,
      path: getTeamDeepDivePath({
        selectedSection:
          selectedSection === teamDeepDiveSections.HEALTH_METRICS
            ? teamDeepDiveSections.HEALTH_METRICS
            : teamDeepDiveSections.PEOPLE_HEALTH,
        selectedMetric: metric
      }),
      id: metric,
      isDisabled:
        metric === Signals.ALWAYS_ON &&
        !(
          urlParams.team?.teamMembers?.find(m => m.id === user.id) ||
          flags?.["team-deep-dive-metric-callouts"]
        )
    };
  });

  return error ? (
    <ErrorBoundary>
      <ErrorLevelMedium
        message={"Something went wrong!"}
        testId="people-health"
      />
    </ErrorBoundary>
  ) : (
    <>
      {showTabs && (
        <PeopleHealthTabs
          defaultTabId={selectedMetric}
          tabs={selectableMetricOptions}
          testId={"people-health-metrics"}
        />
      )}
      {canViewSelectedData ? (
        <LoadingStates
          isSpinningState={isFetchingData}
          content={
            <>
              <DisclaimerContainer>
                <Tooltip
                  popupContent={
                    <TooltipPopup>
                      <span>Sources:</span>
                      <SourcesList>
                        {informationText[selectedMetric].sources.map(source => {
                          return (
                            <span key={source}>
                              <SourceIcon
                                icon={SOURCES_DESCRIPTION_MAP[source].icon}
                              />
                              {SOURCES_DESCRIPTION_MAP[source].displayText}
                            </span>
                          );
                        })}
                      </SourcesList>
                    </TooltipPopup>
                  }
                  placement={"top"}
                  trigger={
                    <StyledMetricIcon icon="ruler-triangle" color="#666" />
                  }
                  testId="people-health-ruler-tooltip"
                />
                {selectedMetric === "OVERALL_TEAM_HEALTH" && (
                  <Disclaimer>
                    {InformationText["team-insight-disclaimer"]}
                  </Disclaimer>
                )}
                {selectedMetric === Signals.ALWAYS_ON && (
                  <Disclaimer>
                    This insight is available for managers and yourself.
                  </Disclaimer>
                )}
              </DisclaimerContainer>
              <Chart>
                <ChartPeopleHealth
                  data={data}
                  isFetchingData={isFetchingData}
                  selectedMetric={selectedMetric}
                  chartView={selectedUser}
                  canSeeAlwaysOnData={flags?.["transparent-data-for-peers"]}
                />
              </Chart>
              <ChartTitle>
                {healthFactorsMetadata[selectedMetric]?.title}
                {selectedUser !== "team" ? `: ${selectedUser.name}` : ``}
                <InfoPopups
                  selectedMetric={selectedMetric}
                  canSeeAlwaysOnData={false}
                />
              </ChartTitle>
              {showMetricTiles && (
                <MetricTileContainer>
                  {selectableMetricOptions.map(metric => {
                    return (
                      <MetricCalloutTile
                        chartView={selectedUser}
                        metricTitle={metric.id as PeopleMetrics}
                        data={data}
                        key={metric.id}
                      />
                    );
                  })}
                </MetricTileContainer>
              )}
            </>
          }
        />
      ) : (
        <StyledEmptyState
          body={InformationText["people-picker-privacy"]}
          header={<StyledIcon icon={"lock"} />}
          testId={"always-on-empty-state"}
        />
      )}
    </>
  );
};

export default withTheme(PeopleHealth);
