import { chunk, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components/macro";
import { directionTypes } from "../../constants/constants";

// components
import Icon from "../Icon/Icon";
import Stepper from "../Stepper/Stepper";

// styled components
const List = styled.ul<{ numVisibleItems: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.numVisibleItems}, 1fr);
  justify-items: center;
`;
const NextArrowWrapper = styled.div`
  padding: 0 0 0 2rem;
`;
const PrevArrowWrapper = styled.div`
  padding: 0 2rem 0 0;
`;
const Arrow = styled(Icon)<{ disabled: boolean }>`
  color: ${props =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors.stepper.indicators};
  font-size: 2.5rem;
  line-height: 1;
`;
const VisibleItems = styled.div`
  flex: 1;
  padding: 0;
  text-align: center;
`;

export const DEFAULT_NUM_VISIBLE_ITEMS = 10;

// typescript props
type Props = {
  className?: string;
  activeIndex: number;
  items: Array<React.ReactNode>;
  numVisibleItems?: number;
  testId?: string;
};

const PagedStepper = ({
  className,
  activeIndex,
  items,
  numVisibleItems = DEFAULT_NUM_VISIBLE_ITEMS,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-paged-stepper`;

  // adding this in case numVisibleItems of 0 is passed for some reason so that
  // we aren't dividing by 0
  const reconciledNumVisibleItems =
    numVisibleItems || DEFAULT_NUM_VISIBLE_ITEMS;
  const chunked: Array<Array<React.ReactNode>> = chunk(
    items,
    reconciledNumVisibleItems
  );
  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(
    Math.floor(activeIndex / reconciledNumVisibleItems)
  );
  const currentChunk = useMemo(() => chunked[currentVisibleIndex], [
    chunked,
    currentVisibleIndex
  ]);

  useEffect(() => {
    setCurrentVisibleIndex(Math.floor(activeIndex / reconciledNumVisibleItems));
  }, [activeIndex, reconciledNumVisibleItems]);

  function onChange(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    direction: string
  ) {
    if (direction === directionTypes.PREV) {
      setCurrentVisibleIndex(
        currentVisibleIndex ? currentVisibleIndex - 1 : currentVisibleIndex
      );
    }
    if (direction === directionTypes.NEXT) {
      const updatedIndex = currentVisibleIndex + 1;
      setCurrentVisibleIndex(
        updatedIndex >= chunked.length - 1 ? chunked.length - 1 : updatedIndex
      );
    }
  }

  if (isEmpty(items)) {
    return null;
  }

  const visibleItems = (
    <List numVisibleItems={numVisibleItems}>
      {currentChunk?.map((c, index) => (
        <li key={`${thisTestId}-${index}`}>{c}</li>
      ))}
    </List>
  );
  const isNextDisabled = currentVisibleIndex >= chunked.length - 1;
  const isPrevDisabled = currentVisibleIndex === 0;
  const displayContent =
    items.length <= reconciledNumVisibleItems ? (
      visibleItems
    ) : (
      <Stepper
        content={<VisibleItems>{visibleItems}</VisibleItems>}
        isNextDisabled={isNextDisabled}
        isPrevDisabled={isPrevDisabled}
        nextButton={
          <NextArrowWrapper>
            <Arrow
              disabled={isNextDisabled}
              icon="triangle-right"
              testId={thisTestId}
            />
          </NextArrowWrapper>
        }
        onChange={onChange}
        prevButton={
          <PrevArrowWrapper>
            <Arrow
              disabled={isPrevDisabled}
              icon="triangle-left"
              testId={thisTestId}
            />
          </PrevArrowWrapper>
        }
        testId={thisTestId}
      />
    );

  return (
    <div className={className} data-testid={thisTestId}>
      {displayContent}
    </div>
  );
};

export default PagedStepper;
