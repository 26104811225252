import React from "react";
import styled from "styled-components/macro";
import { capitalize, isEmpty, partition } from "lodash";
import { NIL } from "uuid";

// components
import PaginationStepper from "../PaginationStepper/PaginationStepper";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";
import WorkMetadataEpicsList from "../WorkMetadataEpicsList/WorkMetadataEpicsList";
import LoadingStates from "../LoadingStates/LoadingStates";
import EmptyState from "../EmptyState/EmptyState";

// constants
import { groupByTypes, urlParamKeys } from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";
import { useWorkItemsData } from "../../hooks/useWorkItemsData";

// interfaces
import { GroupsListInterface } from "../../interfaces/work-items";

// styled components
const Container = styled.div`
  min-height: 30rem;
`;
const ListHeader = styled.h2`
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-size: 1.5rem;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;
const ListHeaderTitle = styled.span`
  font-family: ${props => props.theme.fonts.header.name}, Arial, Helvetica,
    sans-serif;
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
`;
const NoWorkEpic = styled(WorkMetadataEpicsList)`
  margin-bottom: 4rem;
`;
const PaginationStepperWrapper = styled.div`
  margin-top: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  workItemsData: GroupsListInterface;
};

export const EpicsList = ({
  className,
  testId = "testId",
  workItemsData
}: Props) => {
  const thisTestId = `${testId}-epics-list`;
  const { updateUrlParams, urlParams } = useUrlParams();
  const groupBy = urlParams?.groupBy;

  function onChangePage({ updatedPage }: { updatedPage: number }) {
    updateUrlParams({
      [urlParamKeys.GROUPS_LIST]: { [urlParamKeys.PAGE]: updatedPage }
    });
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  const [noWorkEpic, standardEpics] = partition(
    workItemsData?.workMetadata || [],
    e => e.groupId === NIL
  );

  return !noWorkEpic.length && !standardEpics.length ? (
    <EmptyState
      body="Your team doesn't seem to have any data for this date range."
      header="Hmmm."
      testId={testId}
    />
  ) : (
    <Container className={className} data-testid={thisTestId}>
      <TransitionWrapper testId={thisTestId}>
        {!!noWorkEpic.length ? (
          <div>
            <ListHeader>
              <ListHeaderTitle>Items Unlinked to Epics</ListHeaderTitle>
            </ListHeader>
            <NoWorkEpic list={noWorkEpic} testId={thisTestId} />
          </div>
        ) : null}
        {!!standardEpics.length ? (
          <>
            <ListHeader>
              <ListHeaderTitle>
                {`Work by ${capitalize(
                  groupBy === groupByTypes.USER ? "PERSON" : groupBy
                )}`}
              </ListHeaderTitle>{" "}
              ({standardEpics.length} results)
            </ListHeader>
            <WorkMetadataEpicsList list={standardEpics} testId={thisTestId} />
            <PaginationStepperWrapper>
              <PaginationStepper
                currentPage={workItemsData?.page}
                finalPage={workItemsData?.finalPage}
                name="epics-list-pagination"
                onChange={onChangePage}
                testId={thisTestId}
              />
            </PaginationStepperWrapper>
          </>
        ) : null}
      </TransitionWrapper>
    </Container>
  );
};

export default EpicsList;
