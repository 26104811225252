import { kebabCase } from "lodash";
import React from "react";
import styled from "styled-components/macro";

// components
import EmptyState from "../EmptyState/EmptyState";
import WorkMetadata from "../WorkMetadata/WorkMetadata";

// interfaces
import { GroupInterface } from "../../interfaces/work-items";

// styled components
const ListHeader = styled.header`
  font-family: ${props => props.theme.fonts.primary.name}, sans-serif;
  font-size: 1.1rem;
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  margin-bottom: 1rem;
`;
const ColHeaders = styled.ul`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    align-items: end;
    display: grid;
    grid-template-columns: ${props =>
      `minmax(calc(2 * (${props.theme.grid.columnWidth} + ${props.theme.grid.gap})), 1fr)
      minmax(calc(3 * (${props.theme.grid.columnWidth} + ${props.theme.grid.gap}) + ${props.theme.grid.columnWidth}), 1fr)
      1fr
      1fr`};
    justify-items: center;
    word-break: break-word;
  }
`;
const ColHeader = styled.li`
  display: flex;
  justify-content: center;
  justify-self: center;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    text-align: center;
  }
`;
const Metadata = styled(WorkMetadata)`
  & + & {
    margin-top: 0.4rem;
  }
`;

// typescript props
type Props = {
  className?: string;
  list: Array<GroupInterface>;
  testId?: string;
};

const WorkMetadataEpicsList = ({
  className,
  list,
  testId = "testId"
}: Props): React.ReactElement | null => {
  const thisTestId = `${testId}-work-metadata-epics-list`;
  return !!list.length ? (
    <div className={className} data-testid={thisTestId}>
      <ListHeader>
        <ColHeaders>
          <ColHeader>
            <span className="visuallyHidden">Epic</span>
          </ColHeader>
          <ColHeader>Activity</ColHeader>
          <ColHeader>Owners</ColHeader>
          <ColHeader>Contributors</ColHeader>
        </ColHeaders>
      </ListHeader>
      <div>
        {list.map(e => (
          <Metadata
            key={`${kebabCase(e.groupName)}-${e.groupId}`}
            metadata={e}
            testId={thisTestId}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default WorkMetadataEpicsList;
