import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { isEmpty, kebabCase, reject } from "lodash";

// components
import BorderedBox from "../BorderedBox/BorderedBox";
import EmptyState from "../EmptyState/EmptyState";
import Table from "../Table/Table";

// constants
import { storyPointsListTypes } from "../../constants/components";

// styled components
const StyledContainer = styled.div`
  font-family: ${props => props.theme.fonts.primary.name};
  text-align: left;
`;
const StyledHeader = styled.header`
  margin-bottom: 2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
`;
const StyledScrollingContainer = styled.div`
  max-height: 30rem;
  overflow: auto;
`;
const StyledTable = styled(Table)`
  width: 100%;

  & th.title {
    width: 50%;
  }
`;
const StyledListContainer = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

const StoryPointsList = ({
  className,
  date,
  list = [],
  listType = storyPointsListTypes.WITH_STORY_POINTS,
  testId = "testId",
  ...otherProps
}) => {
  const thisTestId = `${testId}-story-points-list`;

  const isWithStoryPointsList =
    listType === storyPointsListTypes.WITH_STORY_POINTS;

  let displayContent;
  if (isEmpty(list)) {
    displayContent = isWithStoryPointsList ? (
      <EmptyState
        body={`Your team had zero recent Stories or Tasks with story points${
          date ? ` on ${date}` : ""
        }.`}
        header="Uh oh."
        testId={thisTestId}
      />
    ) : (
      <EmptyState
        body={`Your team had zero recent Stories or Tasks without story points${
          date ? ` on ${date}` : ""
        }. Time for a round of high fives. Keep it up!`}
        header="Cheers!"
        testId={thisTestId}
      />
    );
  } else {
    let columns = [
      {
        accessor: "displayId",
        className: "displayId",
        Header: "Ticket",
        Cell: ({ cell }) => {
          return (
            <span data-heap-redact-text="true">
              {cell.row.original.url ? (
                <a
                  data-testid={`${thisTestId}-${kebabCase(cell.value)}`}
                  href={cell.row.original.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  data-heap-redact-attributes="href"
                >
                  {cell.value}
                </a>
              ) : (
                <span>{cell.value}</span>
              )}
            </span>
          );
        }
      },
      {
        accessor: "storyPoints",
        className: "storyPoints",
        Header: "SP"
      },
      {
        accessor: "title",
        Cell: ({ cell }) => (
          <span data-heap-redact-text="true">{cell.value}</span>
        ),
        className: "title",
        Header: "Title"
      },
      {
        accessor: "owner",
        Cell: ({ cell }) => (
          <span data-heap-redact-text="true">{cell.value}</span>
        ),
        className: "owner",
        Header: "Owner"
      }
    ];

    const ticketString =
      list.length === 1 ? `${list.length} Ticket` : `${list.length} Tickets`;
    const header = `${ticketString} ${
      isWithStoryPointsList ? "With" : "Without"
    } Story Points${date ? ` on ${date}` : ""}`;
    if (!isWithStoryPointsList) {
      columns = reject(columns, c => c.accessor === "storyPoints");
    }

    displayContent = (
      <StyledListContainer>
        <StyledHeader>
          <BorderedBox location="bottom" testId={thisTestId}>
            {header}
          </BorderedBox>
        </StyledHeader>
        <StyledScrollingContainer>
          <StyledTable
            caption={header}
            columns={columns}
            data={list}
            testId={testId}
          />
        </StyledScrollingContainer>
      </StyledListContainer>
    );
  }

  return (
    <StyledContainer className={className} data-testid={thisTestId}>
      {displayContent}
    </StyledContainer>
  );
};

StoryPointsList.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  list: PropTypes.array,
  listType: PropTypes.oneOf(Object.values(storyPointsListTypes)).isRequired,
  testId: PropTypes.string.isRequired
};

export default StoryPointsList;
