import React, { useRef } from "react";
import styled from "styled-components/macro";

// components
import Modal from "../Modal/Modal";
import Overlay from "react-bootstrap/Overlay";
import UiFilterPopupTrigger from "../UiFilterPopupTrigger/UiFilterPopupTrigger";

// styled components
const PositionedChild = styled.div<{ hasModalBg: boolean }>`
  top: -${props => props.theme.borders.widths.sm} !important;
  /*
    bumping this up a bit so that it either rises above the sidebar and the
    overlapping avatars in the work metadata team lists or rises above the modal
    bg if specified by props
  */
  z-index: ${props => (props.hasModalBg ? 1100 : 10)};
`;

// typescript props
type Props = {
  children: React.ReactNode;
  className?: string;
  hasModalBg?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  onCancelOverlay: () => void;
  onTriggerOverlay: () => void;
  testId?: string;
  triggerContent: React.ReactNode;
};
const UiFilterOverlay = ({
  children,
  className,
  hasModalBg = false,
  isActive = false,
  isDisabled = false,
  onCancelOverlay,
  onTriggerOverlay,
  testId = "testId",
  triggerContent
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-ui-filter-overlay`;
  const target = useRef(null);
  return (
    <div className={className} data-testid={thisTestId}>
      <div ref={target}>
        <UiFilterPopupTrigger
          content={triggerContent}
          isActive={isActive}
          isDisabled={isDisabled}
          onClick={onTriggerOverlay}
          testId={thisTestId}
        />
      </div>
      {/* this is a bit scorched-earth but i'm not sure why the prop change doesn't seem to be triggering the show/hide behavior in the overlay component anymore. this seems to solve the problem without tearing this component apart. */}
      {isActive ? (
        <Overlay
          onHide={onCancelOverlay}
          placement="bottom-start"
          rootClose={true}
          rootCloseEvent="click"
          show={isActive}
          target={target.current}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <>
              <PositionedChild {...props} hasModalBg={hasModalBg}>
                {children}
              </PositionedChild>
              {hasModalBg ? (
                // inserting a fake modal here so we can have the ability to show
                // the screened modal bg while retaining the ability for the
                // overlay popup to be relatively positioned to its trigger
                <Modal
                  allowClose={false}
                  dialogClassName="ui-filter-overlay-modal"
                  onHide={() => null}
                  onShow={() => null}
                  popupContent={<div></div>}
                  testId={thisTestId}
                />
              ) : null}
            </>
          )}
        </Overlay>
      ) : null}
    </div>
  );
};

export default UiFilterOverlay;
