import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Overlay from "react-bootstrap/Overlay";

// styled components
const StyledTooltip = styled.div``;
const StyledTooltipTrigger = styled.div`
  cursor: pointer;
  display: inline-block;
`;
const StyledTooltipPopup = styled.div`
  background: #fff;
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding: 1rem;
  /* ludicrous zindex for popping above modal */
  z-index: 2000;

  & p {
    margin-bottom: 0;
  }

  & ul {
    list-style-type: initial;
    padding-left: 2rem;
    margin: 0;
  }
`;

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.target = React.createRef();
  }

  setShow = show => {
    this.setState({ show });
  };

  render() {
    const {
      className,
      onShowTooltip,
      placement,
      popupContent,
      testId,
      trigger
    } = this.props;
    if (!popupContent || !testId || !trigger) {
      return null;
    }

    return (
      <StyledTooltip className={className} data-testid={`${testId}-tooltip`}>
        <StyledTooltipTrigger
          ref={this.target}
          onMouseEnter={e => {
            this.setShow(true);
            onShowTooltip(e);
          }}
          onMouseLeave={() => this.setShow(false)}
        >
          {trigger}
        </StyledTooltipTrigger>
        <Overlay
          target={this.target.current}
          show={this.state.show}
          placement={placement}
        >
          {props => (
            <StyledTooltipPopup {...props}>{popupContent}</StyledTooltipPopup>
          )}
        </Overlay>
      </StyledTooltip>
    );
  }
}

Tooltip.defaultProps = {
  onShowTooltip: () => {
    // do nothing
  },
  placement: "right"
};

Tooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  popupContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  trigger: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  onShowTooltip: PropTypes.func,
  testId: PropTypes.string.isRequired
};

export default Tooltip;
