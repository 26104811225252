import React from "react";
import parser from "html-react-parser";

// typescript props
type Props = {
  className?: string;
  testId?: string;
  htmlText: string;
};

const HtmlText = ({ className, htmlText, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-html-text`;
  if (!htmlText) {
    return null;
  }

  return (
    <div className={className} data-testid={thisTestId}>
      {parser(htmlText)}
    </div>
  );
};

export default HtmlText;
