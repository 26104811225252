import { currentTheme } from "../../themes/currentTheme";
import { orderBy, some, uniqBy } from "lodash";
import moment from "moment";

// interfaces
import {
  ActivityHistoryInterface,
  StatusHistoryInterface,
  WorkItemInterface
} from "../../interfaces/work-items";
import {
  AxisLabelsFormatterContextObject,
  PointEventsOptionsObject
} from "highcharts";

// constants
import {
  chartToolTipConfig,
  cycleTimeStages,
  prActivityStatusTypes,
  timerangeTypes
} from "../../constants/constants";

// utils
import { getAdjustedTooltipPosition } from "../../utils/chart";
import { CycleTimeStageTypes } from "../../interfaces/constants";
const NUM_VISIBLE = 10;
// svg in base64, pr-activity-chevron-* in /src/images
const chevronApproved =
  "data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSIxMiIKICBoZWlnaHQ9IjE0IgogIHZpZXdCb3g9IjAgMCAxMiAxNCIKICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCj4KICA8cGF0aCBkPSJNMCAwSDhMMTIgN0w4IDE0SDBMNCA3TDAgMFoiIGZpbGw9IiMzY2I0NGIiIHN0cm9rZT0iIzExMTExMSIgLz4KPC9zdmc+Cg==";
const chevronCommented =
  "data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSIxMiIKICBoZWlnaHQ9IjE0IgogIHZpZXdCb3g9IjAgMCAxMiAxNCIKICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCj4KICA8cGF0aCBkPSJNMCAwSDhMMTIgN0w4IDE0SDBMNCA3TDAgMFoiIGZpbGw9IiNmMDMyZTYiIHN0cm9rZT0iIzExMTExMSIgLz4KPC9zdmc+Cg==";
const chevronUpdate =
  "data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSIxMiIKICBoZWlnaHQ9IjE0IgogIHZpZXdCb3g9IjAgMCAxMiAxNCIKICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCj4KICA8cGF0aCBkPSJNMCAwSDhMMTIgN0w4IDE0SDBMNCA3TDAgMFoiIGZpbGw9IiMwMDgwODAiIHN0cm9rZT0iIzExMTExMSIgLz4KPC9zdmc+Cg==";

// typescript props
type Props = {
  data: Array<WorkItemInterface>;
  timerangeType: string;
  end: string;
  start: string;
};

type PointData = {
  // no need to push a marker or history for the fake termination points
  events?: Record<string, unknown>;
  marker?: Record<string, unknown>;
  workItem?: WorkItemInterface;
  history?: StatusHistoryInterface | ActivityHistoryInterface;
  x: number;
  y: number;
  color?: string;
};

const config = ({
  data,
  end,
  start,
  timerangeType
}: Props): Record<string, unknown> => {
  const startDate = start;
  const endDate = moment.min([moment(end), moment()]).toISOString();

  const sortedData = orderBy(data, ["repository", "originBranch"]);
  const filteredData = sortedData.filter(d => !!d.statusHistory);
  const repos = uniqBy(sortedData, "repository").map(d => d.repository);
  const firstChartDateValue = moment(startDate).valueOf();
  const lastChartDateValue = moment(endDate).valueOf();
  const today = moment();

  const series = filteredData.map((d, index) => {
    const statusHistory = d.statusHistory as Array<StatusHistoryInterface>;
    const statusHistoryData: Array<PointData> = statusHistory
      .map(h => {
        const color =
          h.status === prActivityStatusTypes.MERGED
            ? currentTheme.colors.all.nightcrawler
            : h.status === prActivityStatusTypes.WITHDRAWN
            ? currentTheme.colors.all.cyclops
            : currentTheme.colors.chart.cycleTime[
                h.status as keyof CycleTimeStageTypes
              ]?.backgroundColor;
        return {
          // pass along the main data object so it can be accessed from the tooltip
          workItem: d,
          history: h,
          color,
          marker: {
            lineWidth: 0.5,
            lineColor: currentTheme.colors.all.wolverine,
            fillColor:
              // note that we override the release color for the marker
              // since it happens at the end of the release cycle
              h.status === cycleTimeStages.RELEASE
                ? currentTheme.colors.all.thunderbird
                : color
          },
          // datetime axis needs to use epoch milliseconds
          x: moment(h.startAt).valueOf(),
          y: index
        };
      })
      .reverse();

    // if pr is still open (ie at least one of the the status history events is
    // not a terminating event), add a fake terminating point at the end date
    // of the chart, otherwise the line won't extend to the right properly
    if (
      !some(
        statusHistory,
        h =>
          h.status === cycleTimeStages.RELEASE ||
          h.status === prActivityStatusTypes.WITHDRAWN
      )
    ) {
      statusHistoryData.push({
        events: {
          mouseOver: function() {
            return false;
          }
        },
        marker: {
          fillColor: "transparent"
        },
        // datetime axis needs to use epoch milliseconds
        x: moment(endDate).valueOf(),
        y: index,
        color:
          currentTheme.colors.chart.cycleTime[
            cycleTimeStages.RELEASE as keyof CycleTimeStageTypes
          ]?.backgroundColor
      });
    }

    const activityHistoryData: Array<PointData> = ((d.activityHistory ||
      []) as Array<ActivityHistoryInterface>)
      .map(h => ({
        // pass along the main data object so it can be accessed from the tooltip
        workItem: d,
        history: h,
        marker: {
          symbol: `url(${
            h.activity === prActivityStatusTypes.APPROVED
              ? chevronApproved
              : h.activity === prActivityStatusTypes.CHANGES_REQUESTED ||
                h.activity === prActivityStatusTypes.COMMENTED
              ? chevronCommented
              : chevronUpdate
          })`
        },
        // datetime axis needs to use epoch milliseconds
        x: moment(h.activityAt).valueOf(),
        y: index
      }))
      .reverse();

    const orderedStatusHistory = statusHistoryData.sort((p1, p2) => {
      if (p1.x === p2.x) {
        if (
          (p1.history as StatusHistoryInterface).status ===
          cycleTimeStages.RELEASE
        )
          return 1;
        if (
          (p2.history as StatusHistoryInterface).status ===
          cycleTimeStages.RELEASE
        )
          return -1;
      }
      return p1.x - p2.x;
    });
    return {
      data: [
        ...orderedStatusHistory,
        ...orderBy(activityHistoryData, ["x"], ["asc"])
      ],
      zoneAxis: "x",
      zones: orderedStatusHistory
        .filter((d, idx) => idx < orderedStatusHistory.length)
        .map((d, idx) => {
          return {
            value: orderedStatusHistory[idx + 1]?.x,
            color: d.color
          };
        })
    };
  });

  return {
    chart: {
      height: 600,
      marginLeft: 200,
      marginTop: 25,
      panning: {
        enabled: true,
        type: "x"
      },
      panKey: "shift",
      style: {
        cursor: "crosshair",
        fontFamily: currentTheme.fonts.primary.name,
        fontWeight: currentTheme.fonts.primary.weights.regular
      },
      type: "spline",
      zoomType: "x"
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      spline: {
        cursor: "pointer",
        lineWidth: 3,
        marker: { enabled: true, radius: 8, symbol: "circle" },
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          }
        },
        stickyTracking: false
      }
    },
    time: { useUTC: false },
    title: { text: "" },
    xAxis: [
      {
        dateTimeLabelFormats: {
          week: "%m/%d",
          day: "%m/%d",
          hour: "%l%P",
          minute: "%l:%M%P"
        },
        endDateOnTick: false,
        gridLineColor: currentTheme.colors.all.jean,
        gridLineWidth: 1,
        labels: {
          // replace today's date with the word "today" rather than the default format label
          formatter: function(this: AxisLabelsFormatterContextObject) {
            const defaultLabel = this.axis.defaultLabelFormatter.call(this);
            return today.format("MM/DD") === defaultLabel
              ? '<div class="todayLabel">today</div>'
              : defaultLabel;
          },
          style: {
            color: currentTheme.colors.all.wolverine,
            fontFamily: `${currentTheme.fonts.subheader.name}, monospace`,
            fontSize: currentTheme.fonts.subheader.sizes.sm
          }
        },
        lineColor: currentTheme.colors.all.wolverine,
        lineWidth: 1,
        min: firstChartDateValue,
        max: lastChartDateValue,
        plotLines:
          timerangeType === timerangeTypes.SPRINTS
            ? [
                {
                  color: currentTheme.colors.all.wolverine,
                  dashStyle: "shortdash",
                  label: {
                    rotation: 0,
                    text: "Sprint Start",
                    verticalAlign: "top",
                    x: -2,
                    y: -10
                  },
                  value: firstChartDateValue,
                  width: 1,
                  zIndex: 3
                }
              ]
            : [],
        startDateOnTick: false,
        tickWidth: 0,
        tickmarkPlacement: "on",
        type: "datetime"
      },
      // second x-axis to make wolverine line at the top of the chart
      {
        lineColor: currentTheme.colors.all.wolverine,
        lineWidth: 1,
        opposite: true
      }
    ],
    yAxis: {
      categories: sortedData.map(
        d => `<span class="repository">${d.repository}</span><br>${d.name}`
      ),
      gridLineWidth: 0,
      labels: {
        align: "right",
        style: {
          color: currentTheme.colors.all.wolverine
        }
      },
      // alternate bands of grey/white based on chunked repo indices
      plotBands: sortedData.map((d, index) => {
        const repoIndex = repos.indexOf(d.repository as string);
        return {
          color:
            repoIndex % 2 === 0
              ? currentTheme.colors.all.lightJean
              : currentTheme.colors.all.white,
          from: index - 0.5,
          to: index + 1.5
        };
      }),
      min: 0,
      max: filteredData.length < NUM_VISIBLE ? filteredData.length - 1 : 9,
      reversed: true,
      scrollbar: {
        barBackgroundColor: currentTheme.colors.all.storm,
        barBorderRadius: 10,
        barBorderWidth: 0,
        buttonBackgroundColor: "transparent",
        buttonBorderWidth: 0,
        enabled: true,
        minWidth: 3,
        rifleColor: currentTheme.colors.all.storm,
        trackBackgroundColor: currentTheme.colors.all.lightJean,
        trackBorderRadius: 10,
        trackBorderWidth: 0
      },
      tickLength: 0,
      tickmarkPlacement: "on",
      title: {
        enabled: false
      }
    },
    tooltip: {
      ...chartToolTipConfig,
      positioner: function(
        w: number,
        h: number,
        point: PointEventsOptionsObject
      ) {
        return getAdjustedTooltipPosition({
          currentPosition: (this as any).getPosition(w, h, point),
          windowScroll: window.scrollY
        });
      }
    },
    series
  };
};

export default config;
