import React, { ReactElement, useCallback, useMemo } from "react";
import styled from "styled-components";
import moment from "moment";

import {
  uniqBy,
  flatten,
  isNil,
  groupBy,
  mapValues,
  orderBy,
  isUndefined,
  max,
  sortBy,
  isEmpty
} from "lodash";

// components
import ChartCycleTime from "../ChartCycleTime/ChartCycleTime";
import EpicDetailsIssuesList from "../EpicDetailsIssuesList/EpicDetailsIssuesList";
import Icon from "../Icon/Icon";
import Table from "../Table/Table";
import Tooltip from "../Tooltip/Tooltip";
import WorkMetadataIcon from "../WorkMetadataIcon/WorkMetadataIcon";
import WorkMetadataTeamList from "../WorkMetadataTeamList/WorkMetadataTeamList";

// constants
import {
  momentUnitTypes,
  urlParamKeys,
  workMetadataActivityTypes
} from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import {
  CycleTimeInterface,
  EpicDetailsInterface,
  TableCellInterface,
  TableRowInterface,
  TeamMemberInterface,
  PrsByRepositoryRow
} from "../../interfaces/work-items";

// utils
import { getMedianCycleTime } from "../../utils/cycle-time";
import { ColumnInterface } from "react-table";

// styled components
const Container = styled.div`
  padding: 0 2rem;
`;

const RepositoryTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 3rem;

  & > thead tr {
    position: relative;
    top: 8rem;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }
  & > tbody > tr {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    & td:last-child {
      grid-column: 1 / 9;
    }
    &:hover {
      background: ${props => props.theme.colors.all.white};
    }
    background: ${props => props.theme.colors.all.white};
    border-bottom: ${props => `3rem solid ${props.theme.colors.all.lightJean}`};
    z-index: 1;
    position: relative;
  }

  & th {
    background: ${props => props.theme.colors.all.lightJean};
    font-size: ${props => props.theme.fonts.primary.sizes.xs};

    & div {
      white-space: nowrap;
    }

    & > div {
      line-height: 1;
    }
  }

  & td {
    &:hover {
      color: ${props => props.theme.colors.all.auroraTeal};
      font-weight: ${props => props.theme.fonts.primary.weights.bold};
      text-decoration: none;
      cursor: pointer;
    }
  }

  & td,
  & th {
    &.repository {
      width: 25rem;
    }
    &.openPRs {
      width: 15rem;
    }
    &.cycleTime {
      width: 22rem;
    }
    &.lastUpdated {
      width: 12rem;
    }
    &.callouts {
      width: 8rem;
    }
    &.authors {
      width: 15rem;
    }
    &.reviewers {
      width: 12rem;
    }
    &.expander {
      padding-left: 0;
    }
    &.detailsTable {
      padding-bottom: 0;

      & > div {
        padding: 0;
      }

      & a {
        &:visited {
          color: ${props => props.theme.colors.all.wolverine};
        }
        &:hover {
          color: ${props => props.theme.colors.all.aurora};
        }
      }

      &:hover {
        color: ${props => props.theme.colors.all.wolverine};
        font-weight: ${props => props.theme.fonts.primary.weights.regular};
        text-decoration: none;
        cursor: default;
      }
      & thead tr:first-of-type {
        margin-top: 1rem;
        top: -1rem;
      }
      & th {
        background: ${props => props.theme.colors.all.white};
        position: sticky;
        top: 0;
        font-size: 1.1rem;
      }
      & tr {
        background: ${props => props.theme.colors.all.white};
        border-bottom: 0;
      }
      & tbody > tr {
        &.highlightedRow {
          background: ${props => props.theme.colors.all.jubilee};

          & > .activityType {
            background: transparent;
          }
        }
        &:hover {
          cursor: default;
          background: ${props => props.theme.colors.all.lightJean};
          color: ${props => props.theme.colors.all.wolverine};
          &:not(.highlightedRow) {
            & td.activityType {
              background: ${props => props.theme.colors.all.lightJean};
            }
          }
        }
        padding: 0;
        position: relative;
        & td:last-child {
          grid-column: initial;
        }
        z-index: 1;

        .related-ticket {
          vertical-align: middle;

          & a {
            align-items: center;
            display: flex;
            justify-content: space-between;
          }
        }
        & td {
          min-height: 4rem;
          &:hover {
            color: ${props => props.theme.colors.all.wolverine};
            font-weight: ${props => props.theme.fonts.primary.weights.regular};
            cursor: default;
          }
        }
      }
    }
  }
`;

const CellContainer = styled.span`
  min-height: 6.4rem;
  padding: 0.8rem;
  border-right: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  align-items: center;
  word-break: break-all;
`;
const LastUpdatedContainer = styled.div`
  flex-direction: column;
`;
const WorkIcon = styled(WorkMetadataIcon)`
  margin-right: 0.8rem;
`;
const AlertTriangle = styled(Icon)`
  color: ${props => props.theme.colors.all.rogue};
`;
const DefinitionWrapper = styled.div`
  align-items: baseline;
  display: flex;
`;
const CalloutLabel = styled.dt`
  font-weight: ${props => props.theme.fonts.primary.weights.regular};

  &::after {
    content: ":";
  }
`;
const CalloutContent = styled.div`
  display: flex;
`;
const ExpandIconContainer = styled(CellContainer)`
  border: 0;
`;
const ExpandIcon = styled(Icon)`
  color: inherit;
`;
const DetailsTableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  border-top: ${props => `0.2rem solid ${props.theme.colors.all.jean}`};
  overflow: auto;
  max-height: 40rem;
`;
const NumericText = styled.div`
  font-family: ${props => props.theme.fonts.subheader.name};
  font-weight: ${props => props.theme.fonts.subheader.weights.bold};
`;
const NumCallouts = styled(NumericText)`
  margin-left: 1rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  data?: EpicDetailsInterface;
  manualDateMillis?: number; // for snapshots since the current date will be changing
};

export const PRRepositorySummaryList = ({
  className,
  testId = "testId",
  data
}: Props): ReactElement => {
  const thisTestId = `${testId}-repository-summary-list`;
  const { urlParams, updateUrlParams } = useUrlParams();
  const visibleRepositories = useMemo(() => urlParams.visibleRepositories, [
    urlParams
  ]);
  const pullRequests = useMemo(
    () =>
      !!data?.workItems?.length
        ? data.workItems
            .filter(i => i.workItem === workMetadataActivityTypes.PR)
            .map(pr => ({
              ...pr,
              level: 0,
              rootWorkItemId: null
            }))
        : [],
    [data]
  );

  const memoizedGetTeamMembers = useCallback(
    (teamMembersArrays: Array<Array<TeamMemberInterface>>) => {
      return uniqBy(flatten(teamMembersArrays), "displayName").sort((m1, m2) =>
        m1.displayName.localeCompare(m2.displayName)
      );
    },
    []
  );

  const pullRequestsByRepository = useMemo(
    () =>
      orderBy(
        Object.values(
          mapValues(
            groupBy(pullRequests, "repository"),
            (pullRequests, repository) => ({
              authors: memoizedGetTeamMembers(
                pullRequests.map(pr => pr.owners)
              ),
              callouts: flatten(pullRequests.map(pr => pr.callouts)),
              medianCycleTime: getMedianCycleTime(
                pullRequests
                  ?.filter(pr => !isEmpty(pr.cycleTime) && !isNil(pr.cycleTime))
                  .map(pr => pr.cycleTime as CycleTimeInterface)
              ),
              pullRequests,
              repository,
              reviewers: memoizedGetTeamMembers(
                pullRequests.map(pr => pr.contributors)
              )
            })
          )
        ),
        ["hoursSinceLastUpdated"],
        ["asc"]
      ),
    [memoizedGetTeamMembers, pullRequests]
  );

  const onClickRollupCell = useCallback(
    ({
      e,
      row,
      column
    }: {
      e: React.MouseEvent<HTMLElement>;
      row: TableRowInterface;
      column: ColumnInterface;
    }) => {
      const repository = row.original.repository as string;
      updateUrlParams({
        [urlParamKeys.VISIBLE_REPOSITORIES]: !visibleRepositories
          ? [repository]
          : visibleRepositories.includes(repository)
          ? visibleRepositories.filter(r => r !== repository)
          : [...visibleRepositories, repository]
      });
    },
    [updateUrlParams, visibleRepositories]
  );

  const columns = useMemo(() => {
    const childTableCustomColumns = [
      { id: "id", position: 3 },
      { id: "openedAt", position: 4 },
      { id: "complexity", position: 9 },
      { id: "childWorkItems", position: 10 }
    ];
    return [
      {
        accessor: (row: PrsByRepositoryRow) =>
          row.repository || "Unknown Repository",
        Header: "Repo name",
        Cell: ({ cell }: { cell: TableCellInterface }) => {
          return <CellContainer>{cell.value}</CellContainer>;
        },
        className: "repository",
        id: "repository",
        onClickCell: onClickRollupCell
      },
      {
        accessor: (row: PrsByRepositoryRow) =>
          row.pullRequests.filter(pr => pr.closedAt === null).length,
        Header: "Open PRs",
        Cell: ({
          cell
        }: {
          cell: { value: number; row: { original: PrsByRepositoryRow } };
        }) => {
          return (
            <CellContainer>
              <WorkIcon type={workMetadataActivityTypes.PR} />
              <NumericText>
                {`${cell.value} / ${cell.row.original.pullRequests.length}`}
              </NumericText>
            </CellContainer>
          );
        },
        className: "openPRs",
        id: "openPRs",
        onClickCell: onClickRollupCell
      },
      {
        accessor: (row: PrsByRepositoryRow) =>
          !!row.medianCycleTime
            ? Object.values(row.medianCycleTime).reduce(
                (total, stage) =>
                  isUndefined(stage)
                    ? total
                    : total +
                      (stage.unit === "DAYS" ? stage.value * 24 : stage.value),
                0
              )
            : 0,
        Header: "PR Median Cycle Time",
        Cell: ({
          cell
        }: {
          cell: { value: number; row: { original: PrsByRepositoryRow } };
        }) => (
          <CellContainer>
            <ChartCycleTime cycleTime={cell.row.original.medianCycleTime} />
          </CellContainer>
        ),
        className: "cycleTime",
        id: "cycleTime",
        onClickCell: onClickRollupCell
      },
      {
        accessor: (row: PrsByRepositoryRow) => {
          const lastUpdatedDate = max(
            flatten(
              row.pullRequests.map(pr =>
                pr.activityHistory?.map(a => a.activityAt)
              )
            )
          );

          return moment().diff(
            !!lastUpdatedDate ? Date.parse(lastUpdatedDate) : Date.now(),
            momentUnitTypes.HOURS
          );
        },
        Header: "Last Updated",
        Cell: ({
          cell
        }: {
          cell: { value: number; row: { original: PrsByRepositoryRow } };
        }) => (
          <CellContainer>
            <LastUpdatedContainer>
              <NumericText>
                {cell.value > 24
                  ? `${Math.floor(cell.value / 24)}`
                  : `${cell.value}`}
              </NumericText>
              {cell.value > 24 ? `days ago` : `hours ago`}
            </LastUpdatedContainer>
          </CellContainer>
        ),
        className: "lastUpdated",
        id: "lastUpdated",
        onClickCell: onClickRollupCell
      },
      {
        accessor: (row: PrsByRepositoryRow) => row.callouts.length,
        Header: "Callouts",
        Cell: ({
          cell
        }: {
          cell: { value: Array<string>; row: { original: PrsByRepositoryRow } };
        }) => {
          const body = (
            <CalloutContent>
              <AlertTriangle icon="warning" />
              <NumCallouts data-testid={`num-callouts-${cell.value}`}>
                {cell.value}
              </NumCallouts>
            </CalloutContent>
          );
          return !!cell.value ? (
            <CellContainer>
              <Tooltip
                placement="top"
                popupContent={
                  <dl data-testid="callouts-tooltip">
                    {sortBy(
                      Object.entries(
                        groupBy(cell.row.original.callouts, c =>
                          c?.includes("STUCK")
                            ? "Stuck"
                            : c?.includes("MERGED")
                            ? "No Approval"
                            : "High Discussion"
                        )
                      ),
                      e => e[0]
                    ).map(([key, value]) => (
                      <DefinitionWrapper key={key}>
                        <CalloutLabel>{key}</CalloutLabel>
                        <dd>
                          <NumCallouts>{value.length}</NumCallouts>
                        </dd>
                      </DefinitionWrapper>
                    ))}
                  </dl>
                }
                trigger={body}
                testId={`pr-callouts-${cell.row.original.repository}`}
              />
            </CellContainer>
          ) : (
            <CellContainer>{body}</CellContainer>
          );
        },
        className: "callouts",
        id: "callouts",
        onClickCell: onClickRollupCell
      },
      {
        accessor: (row: PrsByRepositoryRow) => row.authors[0]?.displayName,
        Header: "Author(s)",
        Cell: ({
          cell
        }: {
          cell: {
            value: Array<TeamMemberInterface>;
            row: { original: PrsByRepositoryRow };
          };
        }) => (
          <CellContainer>
            <WorkMetadataTeamList
              list={cell.row.original.authors}
              maxNumAvatars={3}
              testId={thisTestId}
            />
          </CellContainer>
        ),
        className: "authors",
        id: "authors",
        onClickCell: onClickRollupCell
      },
      {
        accessor: (row: PrsByRepositoryRow) => row.reviewers[0]?.displayName,
        Header: "Reviewer(s)",
        Cell: ({
          cell
        }: {
          cell: {
            value: Array<TeamMemberInterface>;
            row: { original: PrsByRepositoryRow };
          };
        }) => (
          <CellContainer>
            <WorkMetadataTeamList
              list={cell.row.original.reviewers}
              maxNumAvatars={4}
              testId={thisTestId}
            />
          </CellContainer>
        ),
        className: "reviewers",
        id: "reviewers",
        onClickCell: onClickRollupCell
      },
      {
        accessor: "repository",
        Cell: ({ cell }: { cell: TableCellInterface }) => (
          <ExpandIconContainer>
            <ExpandIcon
              icon={
                visibleRepositories?.includes(cell.value)
                  ? "compress-arrows"
                  : "expand-arrows"
              }
            />
          </ExpandIconContainer>
        ),
        className: "expander",
        id: "expander",
        disableSortBy: true,
        onClickCell: onClickRollupCell
      },
      {
        accessor: "repository",
        Cell: ({
          cell
        }: {
          cell: {
            value: string;
            row: { original: PrsByRepositoryRow };
          };
        }) =>
          visibleRepositories?.includes(cell.row.original.repository) ? (
            <DetailsTableContainer>
              <EpicDetailsIssuesList
                data={cell.row.original.pullRequests}
                customColumns={childTableCustomColumns}
                showHeader={false}
                manuallySort={false}
              />
            </DetailsTableContainer>
          ) : null,
        className: "detailsTable",
        id: "detailsTable",
        disableSortBy: true
      }
    ];
  }, [onClickRollupCell, thisTestId, visibleRepositories]);

  return (
    <Container>
      <RepositoryTable
        className={className}
        columns={columns}
        data-testid={thisTestId}
        data={pullRequestsByRepository}
        testId={thisTestId}
        allowSorting={true}
        manuallySort={false}
      />
    </Container>
  );
};

export default PRRepositorySummaryList;
