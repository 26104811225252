import React from "react";

import { Route } from "react-router-dom";
import LoadingStates from "../LoadingStates/LoadingStates";
import { withAuthenticationRequired } from "@auth0/auth0-react";

// typescript props
type Props = any;

// this is copied from auth0's recommended react implementation
// https://auth0.com/blog/complete-guide-to-react-user-authentication/
const SecureRoute = ({ component, ...args }: Props) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => (
        <LoadingStates
          isSpinningState={true}
          content={null}
          testId="secure-route"
        />
      )
    })}
    {...args}
  />
);

export default SecureRoute;
