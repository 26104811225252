import React, { useState } from "react";
import styled from "styled-components/macro";

// actions
import { storeOrganization } from "../../actions/sessionActions";

// components
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import RouteLink from "../RouteLink/RouteLink";

// hooks
import { useHistory } from "react-router-dom";
import { useTracking } from "../../hooks/useTracking";
import { useDispatch, useSelector } from "react-redux";
import { useOrganizationData } from "../../hooks/useOrganizationData";

// interfaces
import { UserInterface, UserReportInterface } from "../../interfaces/user";
import { AppStateInterface } from "../../interfaces/app-state";
import { useUrlParams } from "../../hooks/useUrlParams";
import { pageIds } from "../../constants/constants";

// styled components
const Container = styled.div`
  background-color: ${props => props.theme.colors.all.wolverine};
  position: relative;
`;

const TenantAlias = styled.h3`
  align-items: baseline;
  display: flex;
  font-size: ${props => props.theme.fonts.subheader.sizes.sm};
  color: ${props => props.theme.colors.all.white};
  font-weight: ${props => props.theme.fonts.primary.weights.medium};
  line-height: 1.8;
`;

const HomeIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.jean};
  font-size: 1.1rem;
  margin-right: 0.75rem;
`;

type Props = {
  className?: string;
  currentPageId?: string;
  testId?: string;
  user: UserInterface;
  activeTeamLead?: UserReportInterface | null;
};

const DropDownContainer = styled.div`
  background-color: ${props => props.theme.colors.all.white};
  position: absolute;
  z-index: 100;
`;

const StyledRouteLink = styled.button`
  color: ${props => props.theme.colors.all.wolverine};
  &,
  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
  }
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  border: 0;
  background-color: ${props => props.theme.colors.all.white};
  margin-left: 0.5rem;
  text-align: left;

  & + & {
    border-top: ${props => `.2rem solid ${props.theme.colors.all.storm}`};
  }
`;

const StyledName = styled.div`
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  font-color: ${props => props.theme.colors.all.wolverine};
`;

const StyledButton = styled(Button)`
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
  }
  margin-right: 0.5rem;
`;

const HomeLinkWithDropDown = ({
  className,
  currentPageId,
  testId = "testId",
  user,
  activeTeamLead
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-home-link-with-drop-down`;
  const [homeDropDownIsOpen, _setHomeDropDownIsOpen] = useState(false);
  // organization
  const { data: orgChartRootTeam } = useOrganizationData();
  const displayTenantAlias = useSelector(
    (state: AppStateInterface) => state.session.displayTenantAlias
  );
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getStartPath } = useUrlParams();
  const isZoomedIn = !!activeTeamLead;
  const isOverviewPage = currentPageId === pageIds.OVERVIEW;

  if (!isZoomedIn) {
    return (
      <Container className={className} data-testid={thisTestId}>
        <RouteLink
          name={"home-link"}
          to={getStartPath({
            persistQuerystring: !isOverviewPage
          })}
        >
          <TenantAlias>
            <HomeIcon icon="house" testId="sidebar" />
            {displayTenantAlias}
          </TenantAlias>
        </RouteLink>
      </Container>
    );
  }

  function onClickDropdown(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    isOpen: boolean
  ) {
    _setHomeDropDownIsOpen(isOpen);
    trackEvent({
      e,
      label: `homeDropDown`,
      value: `clicked ${isOpen ? "open" : "closed"}`
    });
  }

  function onClickHome(
    e: React.MouseEvent<HTMLButtonElement>,
    isOverviewPage: boolean,
    isLoggedInUser: boolean
  ) {
    _setHomeDropDownIsOpen(false);

    if (isLoggedInUser && orgChartRootTeam?.teamMembers) {
      dispatch(
        storeOrganization({
          parentsOfActiveTeam: []
        })
      );
    }

    history.push(
      getStartPath({
        persistQuerystring: !isOverviewPage && !isLoggedInUser
      })
    );

    e.persist();
    e.preventDefault();
    trackEvent({
      e,
      label: "home-link-click",
      value: `followed route link to /`
    });
  }

  return (
    <Container className={className} data-testid={thisTestId}>
      <TenantAlias>
        <StyledButton
          button="unstyled"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onClickDropdown(e, !homeDropDownIsOpen)
          }
          testId={`homeDropDown`}
        >
          <HomeIcon icon="house" testId={"sidebar"} />
          <HomeIcon
            icon={homeDropDownIsOpen ? "arrow-up" : "arrow-down"}
            testId={"sidebar"}
          />
        </StyledButton>

        {displayTenantAlias}
      </TenantAlias>
      {homeDropDownIsOpen ? (
        <DropDownContainer>
          <StyledRouteLink
            id={"home-link"}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              onClickHome(e, isOverviewPage, true)
            }
          >
            <StyledName data-heap-redact-text="true">{user.name}</StyledName>
          </StyledRouteLink>
          <StyledRouteLink
            id={"home-link"}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              onClickHome(e, isOverviewPage, false)
            }
          >
            <StyledName data-heap-redact-text="true">
              {activeTeamLead?.name}
            </StyledName>
          </StyledRouteLink>
        </DropDownContainer>
      ) : null}
    </Container>
  );
};

export default HomeLinkWithDropDown;
