import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { getTeamNameFromTeam } from "../../utils/teams";
import { min } from "lodash";

// components
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import { TeamInterface } from "../../interfaces/team";

const MAX_COLUMNS = 4;
const MAX_TEAMS_PER_COLUMN = 10;

// styled components
const Container = styled.div`
  max-height: 36rem;
  overflow-y: auto;
`;
const List = styled.ul<{ numTeams: number }>`
  columns: ${props =>
    `${min([Math.ceil(props.numTeams / MAX_TEAMS_PER_COLUMN), MAX_COLUMNS])}`};
  gap: 2rem;
`;
const ListItem = styled.li`
  // this is some black wizardry for columns -- we will probably refine this
  // menu, so using some hackery to get this to look nice for now
  // https://stackoverflow.com/questions/5314726/css-multi-column-layout-of-list-items-doesnt-align-properly-in-chrome
  -webkit-column-break-inside: avoid;
  height: 2.4em;
  margin-bottom: 0.5em;
  &:after {
    content: "";
    display: block;
  }
`;
const NameWrapperButton = styled.button`
  align-items: center;
  background: none;
  border: 0;
  color: ${props => props.theme.colors.all.wolverine};
  display: flex;
  flex: 1;

  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
  }
`;
const Name = styled.span`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  margin-left: 0.5em;
  max-width: 20rem;
  text-align: left;
  // truncate really long team names
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const Icon = styled(AvatarIcon)`
  font-size: 3rem;
`;
// typescript props
type Props = {
  className?: string;
  onClickTeam: ({
    e,
    team
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    team: TeamInterface;
  }) => void;
  teams: Array<TeamInterface>;
  testId?: string;
};

const AllTeamsMenuPopup = ({
  className,
  onClickTeam,
  teams,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-all-teams-menu-popup`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <List numTeams={teams.length}>
        {teams.map(t => (
          <ListItem>
            <NameWrapperButton
              // FIXME: replace this button with a real route link both
              // here and in the flex nav
              onClick={e => onClickTeam({ e, team: t })}
              data-testid={`${t.teamId}-button`}
              key={t.teamId}
            >
              <Icon
                avatarColor={t.avatarColor as string}
                avatarIcon={t.avatarIcon as string}
                testId={thisTestId}
              />
              <Name>{getTeamNameFromTeam(t)}</Name>
            </NameWrapperButton>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default AllTeamsMenuPopup;
