import React, { useState } from "react";
import styled from "styled-components/macro";
import Icon from "../Icon/Icon";
import { currentTheme } from "../../themes/currentTheme";
import { FeedbackEventParams } from "../../interfaces/labs";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 40%;
`;

const CommentForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 0.8rem;
  width: 100%;
`;

const CommentTextArea = styled("textarea")`
  align-self: center;
  width: 100%;
  border: none;
  height: 11rem;
  border: ${props => `1px solid ${props.theme.colors.all.lightJean}`};
`;

const SubmitButton = styled("input")`
  max-width: 12rem;
  height: 3.2rem;
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.all.storm
      : props.theme.colors.all.auroraTeal};
  align-self: flex-end;
  color: ${props => props.theme.colors.all.white};
  border: none;
  font-size: 1.3rem;
`;

const StarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const StarMessage = styled.span`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const Stars = styled.div`
  display: flex;
  flex-direction: row;
`;

const StarIcon = styled(Icon)`
  cursor: pointer;
`;

const SentMessage = styled.span`
  align-self: center;
  margin: 0 auto;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;

type Props = {
  testId: string;
  descriptionHeader: string;
  triggerWriteFeedback: (triggerArgs: FeedbackEventParams) => void;
};

const FeedbackForm = ({
  testId,
  triggerWriteFeedback,
  descriptionHeader
}: Props): JSX.Element => {
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const [isSent, setIsSent] = useState(false);

  return (
    <Container>
      <StarContainer>
        <StarMessage>Was this helpful?</StarMessage>
        <Stars>
          {Array.from({ length: 5 }, (_, i) => i + 1).map(ratingNumber => {
            return (
              <div
                onClick={() => setRating(ratingNumber)}
                data-testid={`${testId}-star-button-${ratingNumber}`}
                key={ratingNumber}
              >
                <StarIcon
                  icon={ratingNumber <= rating ? "star" : "starOutline"}
                  color={currentTheme.colors.all.auroraTeal}
                  testId={`star-${ratingNumber}`}
                />
              </div>
            );
          })}
        </Stars>
      </StarContainer>
      <CommentForm
        onSubmit={event => {
          triggerWriteFeedback({
            comment,
            rating: rating.toString(),
            event,
            widgetName: testId,
            question: descriptionHeader
          });
          setComment("");
          setRating(0);
          setIsSent(true);
        }}
      >
        <label>
          <CommentTextArea
            value={comment}
            onChange={event => setComment(event.target.value)}
            data-testid={`${testId}-comment-text-area`}
          />
        </label>
        <SubmitButton
          disabled={!comment && rating === 0}
          type="submit"
          value="Submit"
          data-testid={`${testId}-submit`}
        />
      </CommentForm>
      {isSent && <SentMessage>Thank you for your feedback!</SentMessage>}
    </Container>
  );
};

export default FeedbackForm;
