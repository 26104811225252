import React, { useMemo } from "react";
import styled from "styled-components/macro";

// components
import GroupBySelector from "../GroupBySelector/GroupBySelector";

// constants
import { groupByTypes } from "../../constants/constants";
import { SimpleTeamInterface } from "../../interfaces/team";
import { groupBy, partition } from "lodash";

// typescript props
type Props = {
  className?: string;
  availableReportGroups: Array<string> | null;
  availableRoles: Array<string> | null;
  availableTeams: Array<SimpleTeamInterface> | null;
  testId?: string;
};

const OverviewGroupBySelector = ({
  className,
  availableReportGroups,
  availableRoles,
  availableTeams,
  testId = "testId"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-overview-group-by-selector`;
  const partitionedTeams = useMemo(
    () => partition(availableTeams, t => t.isOrgChartTeam),
    [availableTeams]
  );
  const flexTeams = partitionedTeams[1];
  return (
    <div className={className} data-testid={thisTestId}>
      <label>
        <GroupBySelector
          options={[
            groupByTypes.ROLE,
            groupByTypes.REPORT_GROUP,
            groupByTypes.FLEX_TEAM,
            groupByTypes.MANAGER
          ].filter(o => {
            if (o === groupByTypes.FLEX_TEAM) {
              return !!flexTeams.length;
            }
            if (o === groupByTypes.REPORT_GROUP) {
              return !!availableReportGroups?.length;
            }
            if (o === groupByTypes.ROLE) {
              return !!availableRoles?.length;
            }
            return true;
          })}
          testId={thisTestId}
        />
      </label>
    </div>
  );
};

export default OverviewGroupBySelector;
