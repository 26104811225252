import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { isNull, kebabCase, every } from "lodash";
import { Route, Switch, useLocation } from "react-router-dom";

// components
import PageTitle from "../PageTitle/PageTitle";
import Icon from "../Icon/Icon";
import InformationText from "../../constants/informationText.json";
import HtmlText from "../HtmlText/HtmlText";
import ErrorLevelMedium from "../ErrorLevels/ErrorLevelMedium";
import { ErrorBoundary } from "@sentry/react";
import FlexibleTeamsNavigation from "../FlexibleTeamsNavigation/FlexibleTeamsNavigation";
import JiraWorkSettings from "../JiraWorkSettings/JiraWorkSettings";
import LoadingStates from "../LoadingStates/LoadingStates";
import SourceCodeSettings from "../SourceCodeSettings/SourceCodeSettings";
import ChatSettings from "../ChatSettings/ChatSettings";
import CalendarSettings from "../CalendarSettings/CalendarSettings";
import RouteLinkTabs from "../RouteLinkTabs/RouteLinkTabs";
import Tooltip from "../Tooltip/Tooltip";
import Modal from "../Modal/Modal";
import RouteLink from "../RouteLink/RouteLink";

// constants
import {
  pageIds,
  settingsSections,
  teamSettingsSections,
  teamSettingsSectionsLabels
} from "../../constants/constants";

// hooks
import { useActiveUsersTeams } from "../../hooks/useActiveUsersTeams";
import { useCustomizationData } from "../../hooks/useCustomizationData";
import { useProjectsAndBoardsData } from "../../hooks/useProjectsAndBoardsData";
import { useSprintMetadata } from "../../hooks/useSprintMetadata";
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";

// interfaces
import { TeamInterface } from "../../interfaces/team";
import { TeamCustomizationSettingsInterface } from "../../interfaces/user-settings";

// utils
import { getBoard, getTeamSprintMetadata } from "../../utils/sprints";
import { getTeamNameFromTeam } from "../../utils/teams";

// styled components
const Container = styled.div`
  min-height: 40rem;
  margin: 3rem 0 2rem 0;
`;
const Overlay = styled.div<{ isDisabled: boolean }>`
  pointer-events: ${props => (props.isDisabled ? "none" : "auto")};
  opacity: ${props => (props.isDisabled ? "0.5" : "1")};
`;
const InfoText = styled(HtmlText)`
  width: 60rem;
  margin: 1rem 0;
`;
const InternalTeamPickerContainer = styled.div`
  margin: 3rem 0 1rem 0;
`;
const Teams = styled(FlexibleTeamsNavigation)`
  padding-top: 1rem;
`;
const TeamTitle = styled.div`
  display: flex;
  margin-top: 3rem;
`;
const TeamLength = styled.div`
  margin-left: 1rem;
  font-family: ${props => props.theme.fonts.subheader.name};
`;
const StyledIcon = styled(Icon)`
  margin-left: 1rem;
  color: ${props => props.theme.colors.all.wolverine};
  display: flex;
  align-items: center;
`;
const MAX_MEMBERS_PER_COLUMN = 10;
const TeamList = styled.ul<{ rosterLength: number }>`
  && {
    padding-left: 0;
  }
  columns: ${props =>
    `${Math.ceil(props.rosterLength / MAX_MEMBERS_PER_COLUMN)}`};
  gap: 2rem;
`;
const StyledListItem = styled.li`
  list-style-type: none;
`;
const StyledTabs = styled(RouteLinkTabs)`
  margin-top: 1rem;
`;
const TabContent = styled.div`
  margin: 3rem 0;
`;
const TeamJiraContainer = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  display: flex;
  padding: 1rem 0 1rem 0;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;
const DisclaimerText = styled(HtmlText)`
  margin-left: auto;
  font-style: italic;
  color: ${props => props.theme.colors.all.storm};
`;
const JiraItem = styled.div`
  margin-left: 1rem;
  margin-right: 2rem;
`;
const SaveChangesText = styled.div`
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  color: ${props => props.theme.colors.all.cyclops};
  min-height: 2rem;
  margin: 1rem 0;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const SaveButtonContainer = styled.div`
  display: flex;
  padding: 2rem 0 3rem 0;
`;
const SaveButton = styled.button<{ disabled: boolean }>`
  background: ${props =>
    props.disabled
      ? props.theme.colors.button.disabled.default.bg
      : props.theme.colors.button.default.default.bg};
  border: 0;
  color: ${props =>
    props.disabled
      ? props.theme.colors.button.disabled.default.fg
      : props.theme.colors.button.default.default.fg};
  margin-left: auto;

  &:hover {
    background-color: ${props =>
      props.disabled
        ? props.theme.colors.button.disabled.hover.bg
        : props.theme.colors.button.default.hover.bg};
    color: ${props =>
      props.disabled
        ? props.theme.colors.button.disabled.hover.fg
        : props.theme.colors.button.default.hover.fg};
  }
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  padding: 1rem 3rem;
`;

const LargeText = styled.div`
  font-size: 10rem;
  font-weight: bold;
`;

const StyledModal = styled(Modal)`
  width: 20rem !important;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  margin-top: -2rem;
  font-family: ${props => props.theme.fonts.header.name};
  font-size: ${props => props.theme.fonts.header.sizes.lg};
  padding-bottom: 2rem;
  line-height: 1;
`;

const BodyText = styled.div`
  padding: 1rem 0;
  font-family: ${props => props.theme.fonts.primary.name};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;

const GoBackButton = styled.button`
  background: ${props =>
    props.disabled
      ? props.theme.colors.button.disabled.default.bg
      : props.theme.colors.button.default.default.bg};
  border: 0;
  color: ${props =>
    props.disabled
      ? props.theme.colors.button.disabled.default.fg
      : props.theme.colors.button.default.default.fg};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  padding: 1rem 3rem;
`;

const TurnOffButton = styled.div`
  color: ${props => props.theme.colors.all.storm};
  align-self: flex-end;
  cursor: pointer;
  margin-left: auto;
  padding: 1rem 3rem;
`;

const FactorLists = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  font-family: ${props => props.theme.fonts.primary.name};
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.all.storm};
  width: 50%;
`;

const FactorItem = styled.div`
  margin-top: 1rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

type LocalSettings = Partial<TeamCustomizationSettingsInterface>;

export const TeamCustomizationSettings = ({
  className,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-team-customization-settings`;
  const { trackEvent } = useTracking();
  const { data: activeUsersTeams } = useActiveUsersTeams();
  const displayTeams = useMemo(() => {
    return activeUsersTeams?.filter(team => !team.isOrgChartTeam) || [];
  }, [activeUsersTeams]);
  // need to get the default team before using it as the useState default, forces the calculation rather than waiting inside useState to potentially have a falsy value (if not fulfilled)
  const defaultTeam = displayTeams[0];
  const [activeTeam, setActiveTeam] = useState<TeamInterface>(defaultTeam);
  const activeTeamId = activeTeam?.teamId;
  const teamUsesSprints = activeTeam?.useSprints;
  const { data: projectsData } = useProjectsAndBoardsData();
  const { data: sprintMetadata } = useSprintMetadata(
    { type: `${activeTeamId}-team`, team: activeTeam },
    !activeTeam?.defaultBoardId
  );
  const userHasNoFlexTeams = every(activeUsersTeams, ["isOrgChartTeam", true]);
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const showHealthFactorsCustomizations =
    flags?.["health-factors-customization-settings"];
  // FIXME: we are really close to refactoring our routing to use react router 6
  // nested routing, so rather than set up a whole new thing for settings in
  // urlParams that's going to get ripped out, doing this slightly jank thing
  // here for now
  const location = useLocation();
  const selectedSection = location.pathname.split("/")[3];
  const { getSettingsPath } = useUrlParams();

  const { queryData, queryMutation } = useCustomizationData(activeTeamId);
  const savedSettings = useMemo(() => queryData?.data?.[0]?.settings, [
    queryData
  ]);
  const [settingsLocal, setSettingsLocal] = useState<LocalSettings | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allProjectFactorsDisabled, setAllProjectFactorsDisabled] = useState(
    false
  );
  const [allContextFactorsDisabled, setAllContextFactorsDisabled] = useState(
    false
  );
  const isSavable = useMemo(() => {
    if (!!savedSettings && !isNull(settingsLocal)) {
      return Object.entries(settingsLocal).every(entry => {
        const key = entry[0] as keyof LocalSettings;
        const value = entry[1];
        return value === !!savedSettings[key];
      });
    }
    return true;
  }, [savedSettings, settingsLocal]);

  useEffect(() => {
    setSettingsLocal(null);
  }, [activeTeamId]);

  useEffect(() => {
    if (!!savedSettings) {
      setSettingsLocal(savedSettings);
    }
  }, [savedSettings]);

  const derivedTeamBoard = getBoard({
    sprintMetadata,
    allBoards: projectsData.boards,
    team: activeTeam
  });

  const { project, board, sprint } = getTeamSprintMetadata({
    shouldUseSprints: activeTeam?.useSprints,
    projectsData,
    defaultProjectId: activeTeam?.defaultProjectId,
    defaultBoardId: activeTeam?.defaultBoardId,
    isOrgChartTeam: activeTeam?.isOrgChartTeam,
    derivedTeamBoard
  });

  function onShowTooltip(e: React.MouseEvent<HTMLElement>) {
    trackEvent({
      e,
      label: "team-roster",
      value: `showed team roster from hover`
    });
  }

  function onChangeSwitch(optionKey: keyof TeamCustomizationSettingsInterface) {
    setSettingsLocal({
      ...settingsLocal,
      [optionKey]: !settingsLocal?.[optionKey]
    });
  }
  function handleReturnToTeamBuilder(e: any) {
    e.preventDefault();
    window.location.reload();
  }

  function handleSaveChanges(e: any) {
    e.preventDefault();
    if (!!settingsLocal) {
      // subfactors factors of Project Health, if all are disabled, warn the user
      const projectFactors: Array<keyof TeamCustomizationSettingsInterface> = [
        "ticketsAddedMidsprint",
        "prsMergedNoReviewers",
        "prsMergedNoApproval",
        "ticketsDescribed",
        "stuckPrs",
        "ticketsCarriedOver",
        "bugsInSprint",
        "storyPoints",
        "ticketsClosed"
      ];
      // likewise as above for Context Switching
      const contextSwitchingFactors: Array<keyof TeamCustomizationSettingsInterface> = [
        "topPriorityBugs",
        "prRepos",
        "meetingsWithManyTeams",
        "jiraEpics",
        "chatChannelsInterrupted"
      ];
      const projectsDisabled = projectFactors.every(
        factor => !settingsLocal?.[factor]
      );
      setAllProjectFactorsDisabled(projectsDisabled);

      const contextSwitchingDisabled = contextSwitchingFactors.every(
        factor => !settingsLocal?.[factor]
      );
      setAllContextFactorsDisabled(contextSwitchingDisabled);

      contextSwitchingDisabled || projectsDisabled
        ? setIsModalOpen(true)
        : !!settingsLocal && queryMutation.mutate(settingsLocal);
    }
  }

  const allowedSections = useMemo(
    () =>
      [
        teamSettingsSections.JIRA_WORK,
        teamSettingsSections.SOURCE_WORK,
        teamSettingsSections.CHAT,
        teamSettingsSections.CALENDAR
      ].filter(section =>
        section === teamSettingsSections.JIRA_WORK
          ? true
          : showHealthFactorsCustomizations
      ),
    [showHealthFactorsCustomizations]
  );

  return (
    <Container className={className}>
      {userHasNoFlexTeams ? (
        <>
          <LargeText>Hey!</LargeText>
          <div>To customize metrics please create a flex team under the</div>
          <RouteLink
            name="no-flex-teams-link"
            to={getSettingsPath({
              selectedSection: settingsSections.TEAM_BUILDER
            })}
          >
            Team Builder Tab
          </RouteLink>
        </>
      ) : (
        <>
          <PageTitle title={"Customize Team Settings"} testId={testId} />
          <InfoText htmlText={InformationText["team-customizations"]} />
          <InternalTeamPickerContainer>
            <div>Choose Team:</div>
            <Teams
              teams={displayTeams}
              onClickTeam={({ team }: { team: TeamInterface }) =>
                setActiveTeam(team)
              }
              selectedTeam={activeTeam}
            />
            <Tooltip
              className={className}
              onShowTooltip={onShowTooltip}
              testId={testId}
              trigger={
                <TeamTitle data-testid={`${thisTestId}-team-roster`}>
                  <b>{getTeamNameFromTeam(activeTeam)}</b>
                  <TeamLength>{` (${activeTeam?.teamMembers?.length})`}</TeamLength>
                  <StyledIcon icon={"eye"} />
                </TeamTitle>
              }
              popupContent={
                <TeamList rosterLength={activeTeam?.teamMembers?.length}>
                  {activeTeam?.teamMembers.map(m => (
                    <StyledListItem
                      key={kebabCase(m.name)}
                      data-heap-redact-text="true"
                    >
                      {m.name}
                    </StyledListItem>
                  ))}
                </TeamList>
              }
              placement="bottom"
            />
          </InternalTeamPickerContainer>
        </>
      )}
      <Overlay isDisabled={userHasNoFlexTeams}>
        <TeamJiraContainer>
          {board && (
            <>
              <b>Board:</b>
              <JiraItem>{board.boardName}</JiraItem>
            </>
          )}
          {project && (
            <>
              <b>Project:</b>
              <JiraItem>{project.projectName}</JiraItem>
            </>
          )}
          {sprint && (
            <>
              <b>Recent Active Sprint:</b>
              <JiraItem>{sprint.displayName}</JiraItem>
            </>
          )}
          <DisclaimerText
            htmlText={InformationText["customization-update-disclaimer"]}
          />
        </TeamJiraContainer>
        <LoadingStates
          isSpinningState={
            (queryData.isLoading || queryData.isFetching) &&
            isNull(settingsLocal)
          }
          isNoDataState={
            queryData.isError ||
            (!queryData?.data?.length && isNull(settingsLocal))
          }
          noDataContent={
            <ErrorBoundary>
              <ErrorLevelMedium
                message="Something went wrong fetching your settings"
                testId="error-customization-switches"
              />
            </ErrorBoundary>
          }
          content={
            <>
              <StyledTabs
                defaultTabId={selectedSection}
                className="settings-tab"
                tabs={allowedSections.map(section => ({
                  path: getSettingsPath({
                    selectedSection: settingsSections.TEAM_SETTINGS,
                    selectedMetric: section
                  }),
                  title: teamSettingsSectionsLabels[section],
                  id: section,
                  isDisabled: false
                }))}
              />
              <TabContent>
                <Switch>
                  <Route
                    exact
                    path={`/${pageIds.SETTINGS}/${settingsSections.TEAM_SETTINGS}/${teamSettingsSections.SOURCE_WORK}`}
                  >
                    <SourceCodeSettings
                      onChangeSwitch={onChangeSwitch}
                      settings={
                        settingsLocal as TeamCustomizationSettingsInterface
                      }
                      testId={thisTestId}
                    />
                  </Route>
                  <Route
                    path={`/${pageIds.SETTINGS}/${settingsSections.TEAM_SETTINGS}/${teamSettingsSections.CHAT}`}
                  >
                    <ChatSettings
                      onChangeSwitch={onChangeSwitch}
                      settings={
                        settingsLocal as TeamCustomizationSettingsInterface
                      }
                      testId={thisTestId}
                    />{" "}
                  </Route>
                  <Route
                    path={`/${pageIds.SETTINGS}/${settingsSections.TEAM_SETTINGS}/${teamSettingsSections.CALENDAR}`}
                  >
                    <CalendarSettings
                      onChangeSwitch={onChangeSwitch}
                      settings={
                        settingsLocal as TeamCustomizationSettingsInterface
                      }
                      testId={thisTestId}
                    />
                  </Route>
                  <Route
                    exact
                    path={`/${pageIds.SETTINGS}/${settingsSections.TEAM_SETTINGS}(/${teamSettingsSections.JIRA_WORK})?`}
                  >
                    <JiraWorkSettings
                      onChangeSwitch={onChangeSwitch}
                      showHealthFactorsCustomizations={
                        showHealthFactorsCustomizations
                      }
                      settings={
                        settingsLocal as TeamCustomizationSettingsInterface
                      }
                      teamUsesSprints={teamUsesSprints}
                      testId={thisTestId}
                    />
                  </Route>
                </Switch>
              </TabContent>
            </>
          }
          testId={thisTestId}
        />
        <footer>
          <SaveChangesText>
            {!isSavable && <em>changes must be saved</em>}
          </SaveChangesText>
          <SaveButtonContainer>
            <SaveButton
              disabled={isSavable}
              onClick={e => handleSaveChanges(e)}
              data-testid={`${testId}-save-button`}
            >
              Save Changes
            </SaveButton>
          </SaveButtonContainer>
        </footer>
      </Overlay>
      {isModalOpen && (
        <StyledModal
          allowClose={true}
          dialogClassName="modal-dialog-centered-settings-warning customization-modal"
          popupContent={
            <ModalContainer>
              <Header>Are you sure you want to do this?!</Header>
              <BodyText>
                {`You have turned off all all the factors that make up ${
                  allProjectFactorsDisabled && allContextFactorsDisabled
                    ? "Project Health, Context Switching"
                    : allProjectFactorsDisabled
                    ? "Project Health"
                    : "Context Switching"
                }. If you continue you will no longer get insights on
                ${
                  allProjectFactorsDisabled && allContextFactorsDisabled
                    ? "Project Health, Context Switching"
                    : allProjectFactorsDisabled
                    ? "Project Health"
                    : "Context Switching"
                }.`}
              </BodyText>
              <div>
                {allProjectFactorsDisabled && (
                  <FactorLists>
                    <Column>
                      {[
                        "Tickets Added",
                        "Tickets With Descriptions",
                        "Tickets Closed",
                        "Tickets Carried Over"
                      ].map(factor => {
                        return <FactorItem key={factor}>{factor}</FactorItem>;
                      })}
                    </Column>
                    <Column>
                      {[
                        "Story Points",
                        "Bugs in Sprint",
                        "Stuck PRs",
                        "PRs Merged Without Approval",
                        "PRs Merged Without Reviewers"
                      ].map(factor => {
                        return <FactorItem key={factor}>{factor}</FactorItem>;
                      })}
                    </Column>
                  </FactorLists>
                )}
                {allContextFactorsDisabled && (
                  <FactorLists>
                    <Column>
                      {[
                        "Meetings with 2+ Teams",
                        "PR Repos",
                        "Top Priority Bugs",
                        "Chat Channels with Interruptions",
                        "Jira Epics"
                      ].map(factor => {
                        return <FactorItem key={factor}>{factor}</FactorItem>;
                      })}
                    </Column>
                  </FactorLists>
                )}
              </div>
              <SaveButtonContainer>
                <TurnOffButton
                  onClick={() => {
                    queryMutation.mutate(settingsLocal);
                    setIsModalOpen(false);
                  }}
                >
                  Turn Off
                </TurnOffButton>
                <GoBackButton
                  onClick={() => setIsModalOpen(false)}
                  data-testid={`${testId}-save-button`}
                >
                  Go Back
                </GoBackButton>
              </SaveButtonContainer>
            </ModalContainer>
          }
          onHide={() => setIsModalOpen(false)}
          testId={"settings-warning-modal"}
        />
      )}
    </Container>
  );
};

export default TeamCustomizationSettings;
