import React from "react";
import styled from "styled-components/macro";

// styled components
const Container = styled.span<{ value: string }>`
  align-items: center;
  display: inline-flex;
  font-family: ${props => props.theme.fonts.primary.name}, monospace;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

// typescript props
type Props = {
  className?: string;
  label: React.ReactNode;
  value: string;
  testId?: string;
};

const GroupByLabel = ({
  className,
  label,
  value,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-group-by-label`;
  return (
    <Container className={className} data-testid={thisTestId} value={value}>
      {label}
    </Container>
  );
};

export default GroupByLabel;
