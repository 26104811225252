import { kebabCase } from "lodash";
import React, { useState } from "react";
import styled from "styled-components/macro";

// components
import DotMenu from "../DotMenu/DotMenu";
import PopupContent from "../PopupContent/PopupContent";

// hooks
import { useTracking } from "../../hooks/useTracking";

// styled components
const Menu = styled(PopupContent)`
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding: 1rem;
`;
const MenuListItem = styled.li`
  display: flex;
`;
const MenuButton = styled.button`
  background: none;
  border: 0;
  flex: 1;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  text-align: left;
`;

// typescript props
type Props = {
  className?: string;
  isEditing: boolean;
  onClearAll: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  teamName: string;
  testId?: string;
};

const TeamCardDotMenu = ({
  className,
  isEditing,
  onClearAll,
  onDelete,
  onEdit,
  teamName,
  testId = "testId"
}: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const { trackEvent } = useTracking();
  const nameSlug = kebabCase(teamName);
  const thisTestId = `${testId}-${nameSlug}-team-card-dot-menu`;

  function onTogglePopup(nextShow: boolean) {
    setShowPopup(nextShow);
    trackEvent({
      label: `${nameSlug}-team-card-dot-menu`,
      value: nextShow ? "shown" : "hidden"
    });
  }

  function onClickEdit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setShowPopup(false);
    trackEvent({
      label: `${nameSlug}-team-card-dot-menu-edit`,
      value: "clicked"
    });
    onEdit(e);
  }

  function onClickClearAll(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setShowPopup(false);
    trackEvent({
      label: `${nameSlug}-team-card-dot-menu-clear-all`,
      value: "clicked"
    });
    onClearAll(e);
  }

  function onClickDelete(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setShowPopup(false);
    trackEvent({
      label: `${nameSlug}-team-card-dot-menu-delete`,
      value: "clicked"
    });
    onDelete(e);
  }

  return (
    <DotMenu
      className={className}
      content={
        <Menu
          content={
            <ul data-testid={`${thisTestId}-options`}>
              <MenuListItem>
                <MenuButton
                  onClick={onClickEdit}
                  data-testid={`${thisTestId}-edit-button`}
                >
                  Edit
                </MenuButton>
              </MenuListItem>
              {isEditing ? (
                <MenuListItem>
                  <MenuButton
                    onClick={onClickClearAll}
                    data-testid={`${thisTestId}-clear-all-button`}
                  >
                    Clear All
                  </MenuButton>
                </MenuListItem>
              ) : null}
              <MenuListItem>
                <MenuButton
                  onClick={onClickDelete}
                  data-testid={`${thisTestId}-delete-button`}
                >
                  Delete Team
                </MenuButton>
              </MenuListItem>
            </ul>
          }
          testId={nameSlug}
        />
      }
      onToggle={onTogglePopup}
      showPopup={showPopup}
      testId={thisTestId}
    />
  );
};

export default TeamCardDotMenu;
