import React from "react";
import styled from "styled-components/macro";
import { compact, isEmpty, partition } from "lodash";

// components
import CrossTeamAvatar from "../CrossTeamAvatar/CrossTeamAvatar";
import SlackAvatar from "../SlackAvatar/SlackAvatar";
import Tooltip from "../Tooltip/Tooltip";

// constants
import { MAX_NUM_AVATARS } from "../../constants/components";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { TeamMemberInterface } from "../../interfaces/work-items";
import { UserReportWithMetadataInterface } from "../../interfaces/user";

// styled components
const Container = styled.div`
  display: flex;
  align-items: center;
`;
const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
`;
const ListItem = styled.li<{ order: number }>`
  margin-right: -1rem;
  position: relative;
  z-index: ${props => props.order};
`;
const StyledSlackAvatar = styled(SlackAvatar)`
  border: ${props => `.2rem solid ${props.theme.colors.border}`};
  border-radius: 50% 50%;
  height: 3rem;
  width: 3rem;
`;
const CrossteamHeader = styled.h5`
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const CrossTeamWrapper = styled.div`
  font-size: 1.6rem;
  position: relative;
`;
const StyledCrossTeamAvatar = styled(CrossTeamAvatar)`
  font-size: 1em;
`;
const CrossTeamNumber = styled.span`
  background: ${props => props.theme.colors.all.wolverine};
  border-radius: 50% 50%;
  color: ${props => props.theme.colors.all.white};
  font-size: 0.55em;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  height: 1.5em;
  padding: 0 0.25em;
  position: absolute;
  right: -0.5em;
  text-align: center;
  top: -0.25em;
  width: 1.5em;
`;
const Extras = styled.span`
  margin-left: 2rem;
`;
const ExtrasNameList = styled.ul`
  max-width: 20rem;
`;

// typescript props
type Props = {
  className?: string;
  list: Array<TeamMemberInterface>;
  maxNumAvatars?: number;
  testId?: string;
};

const WorkMetadataTeamList = ({
  className,
  list,
  maxNumAvatars = MAX_NUM_AVATARS,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-work-metadata-team-list`;
  const { urlParams } = useUrlParams();
  const activeTeam = urlParams.team;
  const teamMembers = activeTeam?.teamMembers || [];
  const findUserInActiveTeamMembers = (
    activeTeamMembers: Array<UserReportWithMetadataInterface>,
    user: TeamMemberInterface
  ): UserReportWithMetadataInterface | undefined =>
    activeTeamMembers.find(
      a => a.id === user.uplevelUserId
    ) as UserReportWithMetadataInterface;

  let displayContent;
  if (!isEmpty(list)) {
    const [team, crossteam] = partition(
      list,
      l => !!findUserInActiveTeamMembers(teamMembers, l)
    );

    // in-team members
    const maxNumTeamAvatars = crossteam.length
      ? maxNumAvatars - 1
      : maxNumAvatars;
    const teamDisplayContent = team.slice(0, maxNumTeamAvatars).map(t => {
      const teamMember = findUserInActiveTeamMembers(
        teamMembers,
        t
      ) as UserReportWithMetadataInterface;
      return {
        teamMemberId: t.uplevelUserId.toString(),
        popupContent: t.displayName,
        trigger: (
          <StyledSlackAvatar
            altText={t.displayName}
            src={teamMember?.slackAvatar}
            testId={`${thisTestId}-${teamMember?.id}`}
          />
        )
      };
    });

    // crossteam members
    const crossteamDisplayContent = crossteam.length
      ? {
          teamMemberId: "crossteam",
          displayName: "Cross-Team Collaborators",
          popupContent: (
            <div>
              <CrossteamHeader>
                Cross-Team
                <br />
                Collaborators
              </CrossteamHeader>
              <ul>
                {crossteam.map((l, index) => (
                  <li key={l.uplevelUserId || index}>{l.displayName}</li>
                ))}
              </ul>
            </div>
          ),
          trigger: (
            <CrossTeamWrapper
              data-testid={`${thisTestId}-crossteam-collaborators`}
            >
              <StyledCrossTeamAvatar testId={thisTestId} />
              {crossteam.length > 1 ? (
                <CrossTeamNumber>{crossteam.length}</CrossTeamNumber>
              ) : null}
            </CrossTeamWrapper>
          )
        }
      : null;

    // extras
    const extras = team.slice(maxNumTeamAvatars);

    displayContent = (
      <>
        <List>
          {compact([...teamDisplayContent, crossteamDisplayContent]).map(
            (l, index, fullArray) => (
              <ListItem key={l.teamMemberId} order={fullArray.length - index}>
                <Tooltip
                  placement="bottom"
                  popupContent={l.popupContent}
                  testId={thisTestId}
                  trigger={l.trigger}
                />
              </ListItem>
            )
          )}
        </List>
        {!!extras.length && (
          <Tooltip
            placement="bottom"
            popupContent={
              <ExtrasNameList>
                {extras.map(l => (
                  <li key={l.uplevelUserId} data-heap-redact-text="true">
                    {l.displayName}
                  </li>
                ))}
              </ExtrasNameList>
            }
            testId={thisTestId}
            trigger={<Extras>+{extras.length}</Extras>}
          />
        )}
      </>
    );
  }

  return (
    <Container className={className} data-testid={thisTestId}>
      {displayContent}
    </Container>
  );
};

export default WorkMetadataTeamList;
