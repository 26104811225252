import React, { ReactElement, useEffect } from "react";
import styled from "styled-components/macro";

// components
import HtmlText from "../HtmlText/HtmlText";
import StartPageSummary from "../StartPageSummary/StartPageSummary";
import SupportEmailLink from "../SupportEmailLink/SupportEmailLink";
import YourTeam from "../YourTeam/YourTeam";

// constants
import { timerangeIds } from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { TeamInterface } from "../../interfaces/team";

// utils
import { getTeamHomePageTitle } from "../../utils/team-home";

// styled components
const MessageBlock = styled.div`
  margin-top: 2rem;
  background: ${props => props.theme.colors.all.auroraTeal};
  color: ${props => props.theme.colors.all.white};
  padding: 2rem;
`;
const Email = styled(SupportEmailLink)`
  && {
    color: ${props => props.theme.colors.all.white};
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
    &:hover {
      color: ${props => props.theme.colors.all.marvelMint};
    }
  }
`;
const SupportText = styled.p`
  margin: 0;
`;
const Summary = styled(StartPageSummary)`
  margin-top: 2rem;
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding: 2rem;
`;
const Team = styled(YourTeam)`
  margin-top: 2rem;
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding: 2rem;
`;

// typescript props
type Props = {
  testId?: string;
};

const TeamHomePage = ({ testId = "testId" }: Props): ReactElement => {
  const thisTestId = `${testId}-team-home-page`;
  const location = useLocation();

  const { urlParams } = useUrlParams();
  const activeTeam = urlParams.team;
  const timerange = urlParams.timerange;
  const flags = useSelector((state: AppStateInterface) => state.flags);

  // TODO: remove this logic block when kanban v1 is released
  const shouldShowSummary =
    (flags?.["kanban-mode"] as boolean) ||
    (!!activeTeam?.useSprints &&
      timerange.timerangeType === timerangeIds.SPRINTS);

  // page title change
  useEffect(() => {
    document.title = getTeamHomePageTitle();
  }, [location]);

  return (
    <div data-testid={thisTestId}>
      {!!flags?.["start-page-message"] ? (
        <MessageBlock>
          <HtmlText htmlText={flags?.["start-page-message"]} />
          <SupportText>
            If you have questions, please don&rsquo;t hesitate to reach out to
            us at <Email name="team-home-message" testId={thisTestId} />
          </SupportText>
        </MessageBlock>
      ) : null}
      {shouldShowSummary ? (
        <Summary
          activeTeam={activeTeam as TeamInterface}
          testId={thisTestId}
          timerange={timerange}
        />
      ) : null}
      <Team testId={thisTestId} />
    </div>
  );
};

export default TeamHomePage;
