import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { pick } from "lodash";

// components
import TeamCustomizationSettingsSwitches from "../TeamCustomizationSettingsSwitches/TeamCustomizationSettingsSwitches";

// interfaces
import { TeamCustomizationSettingsInterface } from "../../interfaces/user-settings";
import { FeatureTypes } from "../../interfaces/features";

// constants
import { customizationSettingsKeys } from "../../constants/constants";
const chatCustomizationSettingsKeys: Array<FeatureTypes> = [
  customizationSettingsKeys.CHAT_CHANNELS_INTERRUPTED
];

// styled components
const Header = styled.header`
  margin-bottom: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  onChangeSwitch: (optionKey: keyof TeamCustomizationSettingsInterface) => void;
  settings: Partial<TeamCustomizationSettingsInterface> | null;
  testId?: string;
};

const ChatSettings = ({
  className,
  onChangeSwitch,
  settings,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-chat-settings`;
  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        With <b>chat,</b> my team wants to see Insights on...
      </Header>
      <TeamCustomizationSettingsSwitches
        onChange={onChangeSwitch}
        options={chatCustomizationSettingsKeys}
        settings={pick(settings, chatCustomizationSettingsKeys)}
        testId="team-customizations-chat-switches"
      />
    </div>
  );
};

export default ChatSettings;
