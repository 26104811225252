import React, { ReactElement, useEffect } from "react";
import styled from "styled-components/macro";
import { isEmpty, isNull } from "lodash";

// components
import { Redirect } from "react-router-dom";
import EmptyState from "../EmptyState/EmptyState";
import WorkItemsGroupBySelector from "../WorkItemsGroupBySelector/WorkItemsGroupBySelector";
import RetrosRangeFactorsContainer from "../RetrosRangeFactorsContainer/RetrosRangeFactorsContainer";
import RetrosSummary from "../RetrosSummary/RetrosSummary";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";
import UiCenteringWrapper from "../UiCenteringWrapper/UiCenteringWrapper";
import LoadingStates from "../LoadingStates/LoadingStates";

// constants
import {
  groupByTypes,
  signalTypes,
  healthScoreRanges,
  timerangeTypes
} from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSprintHealthData } from "../../hooks/useSprintHealthData";
import { useWorkItemsData } from "../../hooks/useWorkItemsData";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { TimerangeMetadataInterface } from "../../interfaces/constants";

// utils
import { getRetrosPageTitle } from "../../utils/retros";
import { useAnnotationsData } from "../../hooks/useAnnotationsData";
import { useUrlParams } from "../../hooks/useUrlParams";
import { GroupsListInterface } from "../../interfaces/work-items";
import { getIsSprintMode } from "../../utils/sprints";
import RouteLink from "../RouteLink/RouteLink";

// styled components
const RangeFactors = styled(RetrosRangeFactorsContainer)`
  background: ${props => props.theme.colors.all.white};
  margin-top: 3rem;
  padding: 2rem;
`;
const FactorsHeader = styled.h3`
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.brand.jean}`};
  padding-bottom: 1rem;
`;
const GroupBy = styled(WorkItemsGroupBySelector)`
  margin-bottom: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const RetrosPage = ({ className, testId = "testId" }: Props): ReactElement => {
  const thisTestId = `${testId}-retros-page`;
  const location = useLocation();

  const flags = useSelector((state: AppStateInterface) => state.flags);
  const { getSettingsPath, urlParams } = useUrlParams();
  const sprint = urlParams.timerange as TimerangeMetadataInterface;
  const groupBy = urlParams.groupBy;
  const activeTeam = urlParams.team;
  const isOrgChartTeam = activeTeam?.isOrgChartTeam;
  const shouldUseSprints = activeTeam?.useSprints;
  const board = urlParams.board;
  const canViewRetros = flags?.["sprint-retros"];
  // org chart teams have their useSprints attribute set to true by default but
  // if we attempt to find a board for the team and come up empty, then we
  // should assume that the team doesn't *actually* use sprints and the cake is
  // a lie. this is the same logic as in the toggle for the sprints on the team
  // cards.
  const teamActuallyUsesSprints = isOrgChartTeam
    ? shouldUseSprints && !!board
    : shouldUseSprints;

  const {
    data,
    isError: isErrorSprintCompletionData,
    isLoading: isLoadingSprintCompletionData
  } = useWorkItemsData({
    options: {
      type: "retros"
    }
  });
  const sprintCompletionData = (data as unknown) as GroupsListInterface;

  const {
    data: sprintHealthData,
    isError: isErrorSprintHealthData,
    isLoading: isLoadingSprintHealthData
  } = useSprintHealthData();

  const {
    data: annotationsData,
    isError: isErrorAnnotationsData,
    isLoading: isLoadingAnnotationsData
  } = useAnnotationsData(
    {
      type: "retros"
    },
    sprint.timerangeType === timerangeTypes.SPRINTS
  );

  // page title change
  useEffect(() => {
    document.title = getRetrosPageTitle();
  }, [location]);

  return canViewRetros ? (
    <div className={className} data-testid={thisTestId}>
      <UiCenteringWrapper>
        <GroupBy testId={thisTestId} />{" "}
      </UiCenteringWrapper>
      <LoadingStates
        isSpinningState={
          isLoadingAnnotationsData ||
          isLoadingSprintCompletionData ||
          isLoadingSprintHealthData
        }
        isNoDataState={
          !getIsSprintMode(sprint) ||
          groupBy === groupByTypes.USER ||
          !teamActuallyUsesSprints ||
          isEmpty(sprintHealthData) ||
          isErrorAnnotationsData ||
          isErrorSprintCompletionData ||
          isErrorSprintHealthData ||
          !sprintHealthData?.[sprint.id]
        }
        noDataContent={
          <TransitionWrapper testId={thisTestId}>
            {!teamActuallyUsesSprints ? (
              <EmptyState
                body={
                  <>
                    It looks like this team does not have an active sprint for
                    the selected time period.{" "}
                    {activeTeam?.isOrgChartTeam ? null : (
                      <>
                        If you believe this is an error, please ensure a Sprint
                        is assigned in the{" "}
                        <RouteLink
                          name="kanban-team-message"
                          to={getSettingsPath()}
                          testId="kanban-team-message"
                        >
                          Team Builder
                        </RouteLink>
                        .
                      </>
                    )}
                  </>
                }
                header="Hello!"
                testId={thisTestId}
              />
            ) : !getIsSprintMode(sprint) || groupBy === groupByTypes.USER ? (
              <EmptyState
                body="To get Sprint Retro data, make sure you have a sprint selected and have not grouped by Person."
                header="Hey!"
                testId={thisTestId}
              />
            ) : (
              <EmptyState
                body="Your team doesn't seem to have any data for this sprint."
                header="Hmmm."
                testId={thisTestId}
              />
            )}
          </TransitionWrapper>
        }
        content={
          !!sprintCompletionData && !!sprintHealthData ? (
            <TransitionWrapper testId={thisTestId}>
              <RetrosSummary
                annotations={annotationsData}
                sprint={sprint}
                sprintCompletionData={sprintCompletionData}
                sprintHealthFactorsData={sprintHealthData}
                testId={thisTestId}
              />
              <RangeFactors
                annotations={isNull(annotationsData) ? [] : annotationsData}
                header={<FactorsHeader>Things going well</FactorsHeader>}
                sectionType={signalTypes.HEALTHY}
                sprintCompletionData={sprintCompletionData}
                sprintHealthFactorsData={sprintHealthData}
                range={[
                  healthScoreRanges.GOOD[0],
                  healthScoreRanges.EXCELLENT[1]
                ]}
                sprint={sprint}
                testId="retros-went-well"
              />
              <RangeFactors
                annotations={isNull(annotationsData) ? [] : annotationsData}
                header={<FactorsHeader>Things to look into</FactorsHeader>}
                sectionType={signalTypes.UNHEALTHY}
                sprintCompletionData={sprintCompletionData}
                sprintHealthFactorsData={sprintHealthData}
                range={healthScoreRanges.CONCERNING}
                sprint={sprint}
                testId="retros-look-into"
              />
            </TransitionWrapper>
          ) : null
        }
        testId="sprint-retros"
      />
    </div>
  ) : (
    <Redirect to={`/`} />
  );
};

export default RetrosPage;
