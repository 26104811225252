import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

// components
import Chart from "../Chart/Chart";

// config
import generateConfig from "./config";

// exporting for targeting in tests
export const ChartPackedBubble = ({
  className,
  testId = "testId",
  theme,
  ...props
}) => {
  const thisTestId = `${testId}-packed-bubble-chart`;
  const chart = get(props, "chart", {});
  const config = get(props, "chart.config", {});

  return (
    <Chart
      chart={{
        ...chart,
        config: generateConfig({ config, theme })
      }}
      className={className}
      testId={thisTestId}
    />
  );
};

ChartPackedBubble.propTypes = {
  chart: PropTypes.shape({
    config: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired
  }),
  testId: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default ChartPackedBubble;
