import {
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isNull,
  isUndefined,
  mapValues,
  pick
} from "lodash";
import querystring from "query-string";
import shortUUID, { UUID } from "short-uuid";
import urlon from "urlon";

// constants
import {
  groupByTypes,
  namedTimerangeIds,
  pageIds,
  timerangeIds,
  timerangeTypesMetadata,
  urlObjectParams,
  urlParamKeys,
  urlParamsBaseDefaultValues,
  urlParamsKanbanDefaultValues,
  urlParamsSprintDefaultValues,
  urlUserSettingsParams,
  urlUuidParams,
  workItemCalloutTypes,
  workMetadataActivityTypes
} from "../constants/constants";

// interfaces
import { TimerangeMetadataInterface } from "../interfaces/constants";
import { BoardInterface } from "../interfaces/sprint-metadata";
import { UserReportWithMetadataInterface } from "../interfaces/user";
import { TeamInterface } from "../interfaces/team";
import { QuerystringParamsInterface } from "../interfaces/url-params";
import { WorkItemsRequestParamsInterface } from "../interfaces/work-items";

// utils
import { getEmailsForUsers } from "./user";
import { getDefaultActiveSprint, getIsSprintMode } from "./sprints";
import { getSprintTimerangesForBoard } from "./timeranges";

const uuidTranslator = shortUUID();

export const arrayifyUrlParam = (value: string) => {
  if (isArray(value)) {
    return value.sort();
  }
  return [value];
};

export const getUrlParamsDefaultValues = ({
  pageId,
  timerange
}: {
  pageId?: string;
  timerange?: TimerangeMetadataInterface;
}) => {
  const base =
    !!timerange && getIsSprintMode(timerange)
      ? urlParamsSprintDefaultValues
      : urlParamsKanbanDefaultValues;
  return !!pageId && pageId === pageIds.OVERVIEW
    ? {
        ...base,
        groupBy: groupByTypes.ROLE
      }
    : base;
};

export const getAllUsers = (activeTeam?: TeamInterface | null) =>
  (activeTeam?.teamMembers
    ? getEmailsForUsers(activeTeam.teamMembers)
    : urlParamsBaseDefaultValues.allUsers
  ).sort();

export const getBoardId = (board?: BoardInterface | null) =>
  board?.boardId || null;

export const getCallouts = (parsedQuerystring: any) =>
  arrayifyUrlParam(
    parsedQuerystring.callouts || urlParamsBaseDefaultValues.callouts
  );

export const getReportGroups = (parsedQuerystring: any) =>
  arrayifyUrlParam(
    parsedQuerystring.reportGroups || urlParamsBaseDefaultValues.reportGroups
  );

export const getGroupBy = ({
  defaultJiraGroupBy,
  parsedQuerystring,
  pageId
}: {
  defaultJiraGroupBy?: string;
  parsedQuerystring: any;
  pageId?: string;
}) =>
  // for overview section, ignore defaultJiraGroupBy from settings, because WDD values won't apply there
  parsedQuerystring.groupBy ||
  (!!pageId && pageId === pageIds.OVERVIEW ? null : defaultJiraGroupBy) ||
  getUrlParamsDefaultValues({ pageId }).groupBy;

export const getGroupDetails = (parsedQuerystring: any) =>
  parsedQuerystring.groupDetails || urlParamsBaseDefaultValues.groupDetails;

export const getGroupsList = (parsedQuerystring: any) =>
  parsedQuerystring.groupsList || urlParamsBaseDefaultValues.groupsList;

export const getOtherSprintItems = (parsedQuerystring: any) =>
  arrayifyUrlParam(
    parsedQuerystring.otherSprintItems ||
      urlParamsBaseDefaultValues.otherSprintItems
  );

export const getOwnerContributor = (
  parsedQuerystring: any,
  timerange: TimerangeMetadataInterface
) =>
  arrayifyUrlParam(
    parsedQuerystring.ownerContributor ||
      getUrlParamsDefaultValues({ timerange }).ownerContributor
  );

export const getProjectIds = (parsedQuerystring: any) =>
  arrayifyUrlParam(
    parsedQuerystring.projectIds || urlParamsBaseDefaultValues.projectIds
  );

export const getRepositories = (parsedQuerystring: any) =>
  arrayifyUrlParam(
    parsedQuerystring.repositories || urlParamsBaseDefaultValues.repositories
  );

export const getRoles = (parsedQuerystring: any) =>
  arrayifyUrlParam(parsedQuerystring.roles || urlParamsBaseDefaultValues.roles);

export const getTeams = (parsedQuerystring: any) =>
  arrayifyUrlParam(parsedQuerystring.teams || urlParamsBaseDefaultValues.teams);

export const getVisibleRepositories = (parsedQuerystring: any) =>
  arrayifyUrlParam(
    parsedQuerystring.visibleRepositories ||
      urlParamsBaseDefaultValues.visibleRepositories
  );

export const getQuerystringParamsToSet = ({
  defaultTeam,
  namedTimeranges,
  pageId,
  paramsFromUi,
  parsedQuerystring,
  persistQuerystring,
  timerange,
  userSettings
}: {
  defaultTeam: TeamInterface | null;
  namedTimeranges: { [key: string]: TimerangeMetadataInterface };
  pageId?: string;
  paramsFromUi: QuerystringParamsInterface;
  parsedQuerystring: any;
  persistQuerystring: Array<string> | boolean;
  timerange: TimerangeMetadataInterface;
  userSettings?: { [key: string]: string };
}) => {
  // deep clone because we may be deleting some attributes
  let paramsToSet = JSON.parse(
    JSON.stringify(
      !!persistQuerystring
        ? {
            ...(isArray(persistQuerystring) && !isEmpty(persistQuerystring)
              ? pick(parsedQuerystring, persistQuerystring)
              : parsedQuerystring),
            ...paramsFromUi
          }
        : paramsFromUi
    )
  );
  let defaultValues = getUrlParamsDefaultValues({ pageId, timerange });

  const paramsFromUiKeys = Object.keys(paramsFromUi);
  // do a merge for page specific attributes since they're objects and not primitives
  if (paramsFromUiKeys.includes(urlParamKeys.GROUPS_LIST)) {
    paramsToSet.groupsList = {
      ...defaultValues.groupsList,
      ...(!!parsedQuerystring.groupsList ? parsedQuerystring.groupslist : {}),
      ...paramsFromUi.groupsList
    };
  }
  if (paramsFromUiKeys.includes(urlParamKeys.GROUP_DETAILS)) {
    paramsToSet.groupDetails = {
      ...defaultValues.groupDetails,
      ...(!!parsedQuerystring.groupDetails
        ? parsedQuerystring.groupDetails
        : {}),
      ...paramsFromUi.groupDetails
    };
  }

  // if selected team is current default team, remove from qs
  if (paramsFromUiKeys.includes(urlParamKeys.TEAM)) {
    if (paramsFromUi.team?.teamId === defaultTeam?.teamId) {
      delete paramsToSet.teamId;
    } else {
      // otherwise, we want to add teamid to the querystring
      paramsToSet = {
        ...paramsToSet,
        teamId: paramsFromUi.team?.teamId
      };
    }
    // remove team from update params
    delete paramsToSet.team;
  }

  if (
    paramsFromUiKeys.includes(urlParamKeys.TIMERANGE) &&
    !!paramsFromUi.timerange
  ) {
    defaultValues = getUrlParamsDefaultValues({
      pageId,
      timerange: paramsFromUi.timerange
    });

    // if this is a static named timerange
    if (Object.values(timerangeIds).includes(paramsFromUi.timerange.id)) {
      const namedTimerange = namedTimeranges[paramsFromUi.timerange.id];

      // use the id from the static timerange
      paramsToSet = {
        ...paramsToSet,
        timerangeId: paramsFromUi.timerange.id
      };

      // if this is not one of the timeranges with a static start/end or if
      // it's one of the custom ones, add the start/end time from the update
      if (
        isEmpty(namedTimerange) ||
        paramsFromUi.timerange.start !== namedTimerange?.start ||
        paramsFromUi.timerange.end !== namedTimerange?.end
      ) {
        paramsToSet = {
          ...paramsToSet,
          endDate: paramsFromUi.timerange.end,
          startDate: paramsFromUi.timerange.start
        };
      }

      // remove the sprint id and board id from the querystring so they doesn't persist
      delete paramsToSet.sprintId;
      delete paramsToSet.boardId;
    } else {
      // if it's a sprint timerange, use both the sprint id and the board id
      paramsToSet = {
        ...paramsToSet,
        sprintId: paramsFromUi.timerange.id,
        boardId: paramsFromUi.timerange.boardId
      };
      // remove any static timerange values from the querystring so they don't persist
      delete paramsToSet.timerangeId;
      delete paramsToSet.endDate;
      delete paramsToSet.startDate;
    }

    // remove timerange from update params
    delete paramsToSet.timerange;
  }

  Object.entries(paramsToSet).forEach(([key, value]) => {
    if (!isUndefined(userSettings)) {
      const staticDefaultValue =
        defaultValues[key as keyof typeof urlParamsBaseDefaultValues];
      // if the key is one of the ones controlled by usersettings, try to use
      // the user's default as a comparator if available except when the pageid
      // is "overview", then the static default value
      const defaultValue = Object.keys(urlUserSettingsParams).includes(key)
        ? (!!pageId && pageId === pageIds.OVERVIEW
            ? null
            : userSettings[urlUserSettingsParams[key] as string]) ||
          staticDefaultValue
        : staticDefaultValue;
      // if the updated value matches the default value, remove it from the querystring
      if (isEqual(defaultValue, value)) {
        delete paramsToSet[key];
      }
    }

    // if the updated value is being set to null, remove it from the querystring
    if (isNull(value)) {
      delete paramsToSet[key];
    }

    // if we haven't already deleted the key as a default value
    if (Object.keys(paramsToSet).includes(key)) {
      // if the updated value is an object (currently groupsList and
      // groupDetails), stringify it before the whole querystring is stringified
      if (urlObjectParams.includes(key)) {
        paramsToSet[key] = urlon.stringify(value);
      }

      // if the key is a uuid property, shorten value before it gets stringified
      if (urlUuidParams.includes(key)) {
        paramsToSet[key] = isArray(value)
          ? value.map(v => uuidTranslator.fromUUID(v))
          : uuidTranslator.fromUUID(value as UUID);
      }
    }
  });
  return paramsToSet;
};

export const getShowRelatedItems = (parsedQuerystring: any) =>
  isNil(parsedQuerystring.showRelatedItems)
    ? urlParamsBaseDefaultValues.showRelatedItems
    : parsedQuerystring.showRelatedItems;

export const getSprintId = (
  timerange: TimerangeMetadataInterface
): string | null => (getIsSprintMode(timerange) ? timerange.id : null);

export const getSprintRetrosRequestParamsFromWorkItemsRequestParams = (
  workItemsRequestParams: WorkItemsRequestParamsInterface
): WorkItemsRequestParamsInterface => {
  const paramsToRetain = pick(workItemsRequestParams, [
    urlParamKeys.ALL_USERS,
    urlParamKeys.END_DATE,
    urlParamKeys.EPIC_ID,
    urlParamKeys.GROUP_BY,
    urlParamKeys.SPRINT_ID,
    urlParamKeys.SPRINT_WORK_OTHER,
    urlParamKeys.START_DATE,
    urlParamKeys.TENANT_ID,
    urlParamKeys.TEAM_ID
  ]);
  const staticDefaults = {
    ...pick(urlParamsSprintDefaultValues, [
      urlParamKeys.CALLOUTS,
      urlParamKeys.OWNER_CONTRIBUTOR,
      urlParamKeys.PROJECT_IDS,
      urlParamKeys.REPOSITORIES,
      urlParamKeys.SHOW_RELATED,
      urlParamKeys.SPRINT_WORK_TYPES,
      urlParamKeys.STATUS,
      urlParamKeys.WORK_ITEM_TYPES,
      urlParamKeys.USERS
    ]),
    [urlParamKeys.PAGE]: urlParamsSprintDefaultValues.groupsList.page,
    [urlParamKeys.SORT]: urlParamsSprintDefaultValues.groupDetails.sort
  };
  return {
    ...staticDefaults,
    ...paramsToRetain
  } as WorkItemsRequestParamsInterface;
};

export const getWorkItemsRequestParamsPRWorkflow = (
  workItemsRequestParams: WorkItemsRequestParamsInterface
): WorkItemsRequestParamsInterface => {
  return {
    ...workItemsRequestParams,
    [urlParamKeys.WORK_ITEM_TYPES]: [workMetadataActivityTypes.PR],
    [urlParamKeys.GROUP_BY]: groupByTypes.NONE,
    [urlParamKeys.SHOW_RELATED]: false,
    [urlParamKeys.SPRINT_WORK_OTHER]: [],
    [urlParamKeys.CALLOUTS]:
      workItemsRequestParams.callouts?.filter(
        c =>
          c !== workItemCalloutTypes.ADDED_MID_SPRINT &&
          c !== workItemCalloutTypes.OPEN_MULTIPLE_SPRINTS
      ) || []
  };
};

export const getStatus = (
  parsedQuerystring: any,
  timerange: TimerangeMetadataInterface
) =>
  arrayifyUrlParam(
    parsedQuerystring.status || getUrlParamsDefaultValues({ timerange }).status
  );

export const getTeamId = (parsedQuerystring: any) =>
  parsedQuerystring.teamId || null;

export const getTeamLead = ({
  fullOrganizationData,
  teamLeadId
}: {
  fullOrganizationData: Array<UserReportWithMetadataInterface> | undefined;
  teamLeadId: number | null;
}) =>
  !!fullOrganizationData && !!teamLeadId
    ? fullOrganizationData.find(
        (u: UserReportWithMetadataInterface) => u.id === teamLeadId
      ) || null
    : null;

export const getTeamLeadId = (parsedQuerystring: any) =>
  !!parsedQuerystring.teamLeadId
    ? parseInt(parsedQuerystring.teamLeadId, 10)
    : null;

export const getTimerange = ({
  allSprintTimeranges,
  board,
  endDate,
  namedTimeranges,
  startDate,
  team,
  timerangeId
}: {
  allSprintTimeranges: Array<TimerangeMetadataInterface>;
  board: BoardInterface | null;
  endDate: string | null;
  namedTimeranges: { [key: string]: TimerangeMetadataInterface };
  startDate: string | null;
  team: TeamInterface | null;
  timerangeId: string | null;
}): TimerangeMetadataInterface => {
  const previous14DaysMetadata = namedTimeranges[timerangeIds.PREVIOUS_14_DAYS];
  const foundSprint = allSprintTimeranges.find(
    (s: TimerangeMetadataInterface) => s.id === timerangeId
  );

  // if we can locate the url sprintid in available sprints, use that
  if (!!foundSprint) {
    return foundSprint;
  }
  // if the timerangeid is one of our known non-sprint ids
  else if (!!timerangeId && Object.values(timerangeIds).includes(timerangeId)) {
    const namedTimerange = namedTimeranges[timerangeId];
    // if it's a static timerange, use that
    if (!!namedTimerange) {
      return namedTimerange;
    } else if (!!startDate && !!endDate) {
      return {
        ...timerangeTypesMetadata[timerangeId],
        start: startDate,
        end: endDate
      };
    }
  }
  // line up all dependencies and make sure sprint metadata and projects data
  // and active board hooks are not loading and have data settled
  else if (!!team && !isUndefined(board)) {
    // make sure if a team says it uses sprints to have a board available
    // before trying to activate a sprint rather than trusting it on its
    // throne of lies
    if (
      team.useSprints &&
      // if a board is definitively not available it will return null
      !isNull(board)
    ) {
      const defaultSprint = getDefaultActiveSprint(board.sprints);
      // this is mostly just a belt and suspenders check for typescript
      // because default active sprint takes the first sprint in the array if
      // it can't find active, but just in case there's an empty array of
      // sprints on a board
      if (!!defaultSprint) {
        const sprintTimeranges = getSprintTimerangesForBoard(board);
        const defaultSprintTimerange = sprintTimeranges.find(
          t => t.id === defaultSprint.sprintId
        );

        // only do this if the active board does not match the current active timerange, because we've switched boards without affirmatively choosing
        // a new timerange so we need to use the default timerange for the
        // board
        if (!!defaultSprintTimerange) {
          return defaultSprintTimerange;
        }
      }
    } else {
      if (isNull(board)) {
        return previous14DaysMetadata;
      }
    }
  }

  // this is a fake timerange meant only as a placeholder until the actual
  // timerange is set so that the ui can be unlocked.
  return namedTimeranges[namedTimerangeIds.TODAY];
};

export const getTypesOfWork = (
  parsedQuerystring: any,
  timerange: TimerangeMetadataInterface
) =>
  arrayifyUrlParam(
    parsedQuerystring.typesOfWork ||
      getUrlParamsDefaultValues({ timerange }).typesOfWork
  );

export const getUsers = (parsedQuerystring: any) =>
  arrayifyUrlParam(parsedQuerystring.users || urlParamsBaseDefaultValues.users);

export const getWorkItemTypes = (parsedQuerystring: any) =>
  arrayifyUrlParam(
    parsedQuerystring.workItemTypes || urlParamsBaseDefaultValues.workItemTypes
  );

export const getSelectedUser = (parsedQuerystring: any) =>
  isNil(parsedQuerystring.selectedUser)
    ? urlParamsBaseDefaultValues.selectedUser
    : parsedQuerystring.selectedUser;

export const parseQuerystring = (qs: string) => {
  return mapValues(
    querystring.parse(qs, {
      arrayFormat: "bracket-separator",
      arrayFormatSeparator: "|",
      parseBooleans: true
    }),
    (value, key) => {
      if (!isNull(value) && urlUuidParams.includes(key)) {
        return isArray(value)
          ? (value as Array<string>).map(v => uuidTranslator.toUUID(v))
          : uuidTranslator.toUUID(value as string);
      }
      if (urlObjectParams.includes(key)) {
        return urlon.parse(value);
      }
      return value;
    }
  );
};
