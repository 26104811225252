import React, { useCallback } from "react";
import { debounce } from "lodash";

// components
import Switch from "../Switch/Switch";

// constants
import { urlParamKeys } from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";
import styled from "styled-components/macro";
import InfoIcon from "../InfoIcon/InfoIcon";

const Label = styled.div`
  display: flex;
  margin-left: 0.5rem;
`;

const StyledInfo = styled(InfoIcon)`
  margin-left: 0.5rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

const ShowRelatedItemsSelector = ({ className, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-show-related-items-selector`;
  const { trackEvent } = useTracking();
  const { urlParams, updateUrlParams } = useUrlParams();
  const showRelatedItems = urlParams.showRelatedItems;

  const debouncedToggle = useCallback(
    debounce(updated => {
      updateUrlParams({
        [urlParamKeys.SHOW_RELATED]: updated
      });
    }, 500),
    []
  );

  function onToggleStatus(e: React.ChangeEvent<HTMLInputElement>) {
    const updatedShowRelatedItems = !showRelatedItems;
    debouncedToggle(updatedShowRelatedItems);
    trackEvent({
      e,
      label: "ui-filter-show-related-items-selector-change",
      value: `changed showRelatedItems to ${updatedShowRelatedItems}`
    });
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <Switch
        isActive={showRelatedItems}
        labelContent={
          <Label>
            Show related issues and prs
            <StyledInfo content="show-related-items" />
          </Label>
        }
        name="show-related-items-selector"
        onChange={onToggleStatus}
        testId={thisTestId}
        labelPlacement="end"
      />
    </div>
  );
};

export default ShowRelatedItemsSelector;
