import { actionTypes } from "../constants/actionTypes";

/**
 * reducer for auth related state actions
 */
export default (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.STORE_AUTH_ACCESS_TOKEN:
      return {
        ...state,
        ...action.payload
      };
    case actionTypes.STORE_AUTH_PARAMS:
      return {
        ...state,
        authParams: {
          ...state.authParams,
          ...action.payload.authParams
        }
      };
    default:
      return state;
  }
};
