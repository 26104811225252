import { compact, isNull, isString, isUndefined } from "lodash";
import { CycleTimeStageTypes } from "../interfaces/constants";
import {
  OpMetric,
  OpMetricData,
  OpMetricSprintRecord,
  OpMetricUnit,
  OpMetricValueTypes
} from "../interfaces/ops-metrics";
import { CycleTimeInterface } from "../interfaces/work-items";
import { sanitizeValue } from "./work-items";

export const getCycleTimes = (
  data?: OpMetricData
): Array<CycleTimeInterface> => {
  if (!!data) {
    return data.map(sprintRecord => {
      return sprintRecord.data.find(
        dataRecord => dataRecord.name === "cycleTime"
      )?.metadata as CycleTimeInterface;
    });
  }
  return [];
};

export const getMetric = (
  data: OpMetricSprintRecord,
  metric: OpMetricValueTypes
): OpMetric | undefined =>
  data.data.find(dataRecord => dataRecord.name === metric);

export const getMetricValue = (
  data: OpMetricSprintRecord,
  metric: OpMetricValueTypes
): OpMetric["value"] | undefined =>
  data.data.find(dataRecord => dataRecord.name === metric)?.value;

export const getMetricMetadata = (
  data: OpMetricSprintRecord,
  metric: OpMetricValueTypes
): CycleTimeInterface | undefined =>
  data.data.find(dataRecord => dataRecord.name === metric)?.metadata;

export const getMetricUnit = (
  data: OpMetricSprintRecord,
  metric: OpMetricValueTypes
): OpMetricUnit | undefined =>
  data.data.find(dataRecord => dataRecord.name === metric)?.unit;

export const getPercentageDelta = (
  current: number,
  previous: number
): number | null => {
  if (previous === 0) {
    return null;
  }

  return (current - previous) / previous;
};

export const getOperationalMetric = (
  metric: OpMetricValueTypes,
  data?: OpMetricSprintRecord
): OpMetric | null => {
  if (isUndefined(data)) {
    return null;
  }
  const raw = getMetric(data, metric);

  return isUndefined(raw) ? null : raw;
};

export const getOperationalMetricDelta = (
  current: OpMetric | null,
  previous: OpMetric | null
): number | null => {
  if (isNull(current) || isNull(previous)) {
    return null;
  }
  const currentValue = current.value as number;
  const unitIsPercent = current.unit === "PERCENT";
  const previousValue = previous.value as number;

  // doing multiply and rounding here because the math doesn't work out quite
  // right in the UI if you calculate with raw numbers and then multiply/round
  // after the fact for UI display. it's muddying things a bit but trying to err
  // on the side of math being truthful if you're trying to figure it out by
  // looking at UI values.
  return unitIsPercent
    ? getPercentageDelta(
        Math.round(currentValue * 100),
        Math.round(previousValue * 100)
      )
    : currentValue - previousValue;
};

export const mapStageData = (
  data: OpMetricData,
  stageKey: keyof CycleTimeStageTypes
): OpMetricData =>
  data.map(d => ({
    ...d,
    data: compact(
      d.data.map(metric => {
        const stage = (metric.metadata as CycleTimeInterface | undefined)?.[
          stageKey
        ];
        return !!stage
          ? {
              ...metric,
              // reassign the individual stage unit/value into the main cycleTime unit/value
              unit: stage.unit,
              value: stage.value
            }
          : null;
      })
    )
  }));

export const getMetricBoxUnit = (
  metric: Omit<OpMetricValueTypes, "cycleTime">,
  isSprintMode: boolean,
  isActiveSprint: boolean,
  isPrevious: boolean
): string => {
  switch (metric) {
    case "releaseCount":
      return "Releases";
    case "bugAllocation":
      return "% Bugs";
    case "complexPrs":
      return "% High Complexity";
    // completionRate
    default:
      if (isSprintMode) {
        return isPrevious
          ? "% Actual Done"
          : isActiveSprint
          ? "% Projected Done"
          : "% Actual Done";
      } else {
        return "Done";
      }
  }
};

export const getMetricBoxDisplayValue = (
  value: string | number | null,
  isPercentage: boolean
): string | number => {
  const sanitized = sanitizeValue(value);
  // "N/A"
  if (isString(sanitized)) {
    return sanitized;
  }
  return Math.round(isPercentage ? sanitized * 100 : sanitized);
};
