import { get, isEmpty, isEqual, isFinite, max } from "lodash";
import { formatTimestamp } from "../../utils/date";
import {
  getPrCadenceTooltip,
  getAdjustedTooltipPosition
} from "../../utils/chart";

export default ({ data, onClickSeries, selectedDateRange, theme }) => {
  const chartColors = theme.colors.chart.prCadence;
  const { groupedRows, weekRanges } = data;

  let series = [];
  let yAxisMax;

  if (!isEmpty(groupedRows)) {
    const events = {
      select: function(event) {
        event.preventDefault();
        const { target } = event;
        onClickSeries(target.category);
      },
      unselect: function(event) {
        event.preventDefault();
        onClickSeries(null);
      }
    };

    const columnData = weekRanges.map((r, index) => {
      return {
        x: index,
        y: groupedRows?.[`${r.start}`]?.numMerged || null,
        selected: isEqual(r, selectedDateRange)
      };
    });
    const lineData = weekRanges.map((r, index) => {
      const result = get(groupedRows, `${r.start}`, {});
      return {
        x: index,
        // using isFinite because median could be 0
        y: isFinite(result.medianReviewTimeInHours)
          ? result.medianReviewTimeInHours
          : null,
        selected: isEqual(r, selectedDateRange)
      };
    });

    series = [
      {
        allowPointSelect: true,
        name: "Number of PR's Merged",
        type: "column",
        data: columnData,
        point: {
          events
        }
      },
      {
        allowPointSelect: true,
        cursor: "pointer",
        name: "Median Review Time (hrs)",
        color: chartColors.marker.fill,
        marker: {
          fillColor: chartColors.marker.fill,
          lineColor: chartColors.marker.fill
        },
        type: "line",
        yAxis: 1,
        data: lineData,
        point: {
          events
        }
      }
    ];

    yAxisMax = max([...columnData, ...lineData].map(d => d.y));
  }

  return {
    chart: {
      zoomType: "xy",
      style: {
        fontFamily: theme.fonts.subheader.name,
        fontSize: 15
      }
    },
    title: {
      text: ""
    },
    xAxis: [
      {
        categories: weekRanges,
        tickWidth: 3,
        tickmarkPlacement: "on",
        labels: {
          y: 30,
          fontFamily: theme.fonts.subheader.name,
          formatter: function() {
            return `${formatTimestamp({
              format: "M/D",
              timestamp: this.value.start
            })}-${formatTimestamp({
              format: "M/D",
              timestamp: this.value.end
            })}`;
          }
        },
        min: -0.5,
        max: 5.5
      }
    ],
    yAxis: [
      {
        max: yAxisMax,
        gridLineWidth: 3,
        title: {
          text: "Number of PRs Merged",
          style: {
            fontFamily: theme.fonts.primary.name,
            fontWeight: theme.fonts.primary.weights.book,
            color: theme.colors.all.wolverine
          }
        }
      },
      {
        max: yAxisMax,
        gridLineWidth: 3,
        title: {
          text: "Median Review Time (hrs)",
          style: {
            fontFamily: theme.fonts.primary.name,
            fontWeight: theme.fonts.primary.weights.book,
            color: theme.colors.all.wolverine
          }
        },
        opposite: true
      }
    ],
    legend: {
      align: "right",
      enabled: true,
      itemDistance: 30,
      itemStyle: {
        fontFamily: theme.fonts.primary.name,
        fontWeight: theme.fonts.primary.weights.book
      },
      layout: "horizontal",
      verticalAlign: "bottom",
      width: 0,
      symbolHeight: 8,
      symbolWidth: 8,
      symbolPadding: 8,
      // a bit odd, but radius here implicitly applies to just column type series and not line series
      symbolRadius: 0
    },
    tooltip: {
      shared: true,
      enabled: true,
      useHTML: true,
      backgroundColor: null,
      shadow: false,
      borderWidth: 0,
      borderRadius: 0,
      outside: true,
      positioner: function(w, h, point) {
        return getAdjustedTooltipPosition({
          currentPosition: this.getPosition(w, h, point),
          windowScroll: window.scrollY
        });
      },
      formatter: function() {
        return getPrCadenceTooltip(this.points);
      }
    },
    plotOptions: {
      line: {
        connectNulls: true,
        marker: {
          symbol: "circle"
        },
        states: {
          hover: {
            opacity: theme.charts.series.opacity.full,
            lineWidth: "4px",
            lineWidthPlus: 0
          },
          inactive: {
            animation: {
              duration: 0
            }
          }
        }
      },
      column: {
        color: chartColors.column.fill,
        pointPlacement: "on",
        maxPointWidth: 20,
        states: {
          hover: {
            enabled: false
          },
          select: {
            borderColor: chartColors.column.stroke,
            color: chartColors.column.fill
          }
        }
      },
      series: {
        pointPlacement: "on",
        point: {
          events: {
            select: function() {
              onClickSeries(this.category);
            }
          }
        },
        marker: {
          states: {
            select: {
              fillColor: chartColors.marker.fill,
              lineColor: chartColors.marker.stroke
            }
          }
        }
      }
    },
    series
  };
};
