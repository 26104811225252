import React, { useState } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { AnnotationsInterface } from "../../interfaces/annotations";
import SlackAvatar from "../SlackAvatar/SlackAvatar";
import CrossTeamAvatar from "../CrossTeamAvatar/CrossTeamAvatar";
import { UserReportInterface } from "../../interfaces/user";
import { useTracking } from "../../hooks/useTracking";
import { truncate } from "lodash";

const CommentBlock = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 1.6rem;
`;
const StyledSlackAvatar = styled(SlackAvatar)`
  border: ${props => `.2rem solid ${props.theme.colors.border}`};
  border-radius: 50% 50%;
  height: 3rem;
  width: 3rem;
  align-self: flex-start;
`;
const StyledCrossTeamAvatar = styled(CrossTeamAvatar)`
  height: 3rem;
  width: 3rem;
`;
const UserCommentText = styled.div`
  background-color: ${props => props.theme.colors.all.white};
  font-size: 1.3rem;
  border-radius: 1rem;
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  flex: 1;
  margin-left: 1.6rem;
  padding: 1.6rem;
`;
const CommentTimestamp = styled.time`
  color: ${props => props.theme.colors.all.storm};
  margin-left: 1.6rem;
`;
const Name = styled.span`
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const CommentText = styled.p`
  margin: 0;
`;
const Expander = styled.div`
  font-family: ${props => props.theme.fonts.primary.name}, serif;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  color: ${props => props.theme.colors.all.storm};
  cursor: pointer;
  &:hover {
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
  }
  margin-top: 0.8rem;
`;

const MAXIMUM_CHARACTER_LIMIT = 140;

// typescript props
type Props = {
  className?: string;
  annotation: AnnotationsInterface;
  teamMember?: UserReportInterface;
  testId?: string;
};

const UserCommentBlock = ({
  className,
  annotation,
  teamMember,
  testId = "testId"
}: Props) => {
  const [showFullComment, setShowFullComment] = useState(false);
  const { trackEvent } = useTracking();

  function onToggleExpander(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setShowFullComment(!showFullComment);
    trackEvent({
      e,
      label: `${testId}-comment-toggle`,
      value: `clicked to ${
        !showFullComment
          ? "show full text for large comment"
          : "hide full text for large comment"
      }`
    });
  }

  const commentText = annotation.userDetailedComment
    ? annotation.userDetailedComment.length <= MAXIMUM_CHARACTER_LIMIT ||
      showFullComment
      ? annotation.userDetailedComment
      : truncate(annotation.userDetailedComment, {
          length: MAXIMUM_CHARACTER_LIMIT
        })
    : null;

  return (
    <CommentBlock>
      {teamMember?.slackAvatar ? (
        <StyledSlackAvatar
          altText={teamMember?.name}
          src={teamMember?.slackAvatar}
          testId={`${testId}-${teamMember.id}`}
        />
      ) : (
        <StyledCrossTeamAvatar testId={testId} />
      )}
      <UserCommentText>
        <header>
          <Name data-heap-redact-text="true">
            {teamMember?.name || annotation.userEmail}
          </Name>
          <CommentTimestamp dateTime={annotation.timestamp}>
            {moment(annotation.timestamp).fromNow()}
          </CommentTimestamp>
        </header>
        <CommentText>{commentText}</CommentText>
        {annotation.userDetailedComment &&
          annotation.userDetailedComment?.length > MAXIMUM_CHARACTER_LIMIT && (
            <Expander onClick={onToggleExpander}>
              {!showFullComment ? "...more" : "hide"}
            </Expander>
          )}
      </UserCommentText>
    </CommentBlock>
  );
};

export default UserCommentBlock;
