import { useSelector } from "react-redux";

// hooks
import { useQuery } from "react-query";
import { useUrlParams } from "./useUrlParams";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { requestUserAllocationsData } from "../utils/user";

export const useAllocationsData = (canRequest = true) => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const { urlParams } = useUrlParams();
  const sprintId = urlParams.sprintId;
  const startDate = urlParams.startDate;
  const endDate = urlParams.endDate;
  const users = urlParams.allUsers;
  const teamId = urlParams.teamId;

  return useQuery(
    ["allocations", { endDate, sprintId, startDate, users, teamId }],
    () =>
      requestUserAllocationsData({
        accessToken,
        tenantId,
        params: {
          startDate,
          endDate,
          sprintId,
          tenantId,
          users,
          teamId
        }
      }).then(data => data.allocations),
    {
      enabled:
        !!accessToken &&
        !!tenantId &&
        !!users?.length &&
        !!startDate &&
        !!endDate &&
        !!teamId &&
        canRequest
    }
  );
};
