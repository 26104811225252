import { merge } from "lodash";

export default ({ config }) => {
  return merge(
    {},
    {
      credits: {
        enabled: false
      }
    },
    config
  );
};
