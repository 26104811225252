import React from "react";
import styled from "styled-components/macro";

// styled components
const Container = styled.span<{ measure: string }>`
  align-items: center;
  display: inline-flex;
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;

  &::before {
    background: ${props =>
      props.theme.colors.chart.meetingClassifications[props.measure]};
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 1.5rem;
    margin-right: 1rem;
    width: 1.5rem;
    flex: 0 0 1.5rem;
  }
`;

// typescript props
type Props = {
  className?: string;
  label: React.ReactNode;
  measure: string;
  testId?: string;
};

const MeetingClassificationLabel = ({
  className,
  label,
  measure,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-meeting-classification-label`;
  return (
    <Container className={className} data-testid={thisTestId} measure={measure}>
      {label}
    </Container>
  );
};

export default MeetingClassificationLabel;
