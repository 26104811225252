import React from "react";
import styled, { css } from "styled-components/macro";
import { isEmpty } from "lodash";

// components
import CustomCheckboxInput from "../CustomCheckboxInput/CustomCheckboxInput";

// interfaces
import {
  RadioCheckboxOptionsInterface,
  RadioCheckboxToggleHandlerInterface
} from "../../interfaces/controls";
import CustomRadioInput from "../CustomRadioInput/CustomRadioInput";

// styled components
const Container = styled.ul<{ columns: number | undefined }>`
  ${props => {
    if (props.columns) {
      return css`
        display: grid;
        grid-template-columns: repeat(${props.columns}, 1fr);
        gap: 0.5rem;
      `;
    }
  }}
`;
const InputsListWrapper = styled.div`
  padding: 0.5rem 0 0 3.5rem;
`;

// typescript props
type Props = {
  className?: string;
  columns?: number | undefined;
  name: string;
  onChange: ({ e, option }: RadioCheckboxToggleHandlerInterface) => void;
  options: Array<RadioCheckboxOptionsInterface>;
  testId?: string;
  type?: "checkbox" | "radio";
};

const InputsList = ({
  className,
  columns = 1,
  name,
  onChange,
  options,
  testId = "testId",
  type = "checkbox"
}: Props) => {
  const thisTestId = `${testId}-inputs-list`;
  if (isEmpty(options)) {
    return null;
  }

  return (
    <Container columns={columns} className={className} data-testid={thisTestId}>
      {options.map(option => {
        const { disabled, selected, label, value, children } = option;
        const id = `${name}-${value.toString()}`;

        return (
          <li key={id}>
            {type === "checkbox" ? (
              <CustomCheckboxInput
                disabled={disabled}
                selected={selected}
                label={label}
                name={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange({ e, option })
                }
                testId={id}
                value={value}
              />
            ) : (
              <CustomRadioInput
                disabled={disabled}
                selected={selected}
                label={label}
                name={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange({ e, option })
                }
                testId={id}
                value={value}
              />
            )}
            {children && (
              <InputsListWrapper>
                <InputsList
                  name={name}
                  onChange={onChange}
                  options={children}
                  testId={id}
                  type={type}
                />
              </InputsListWrapper>
            )}
          </li>
        );
      })}
    </Container>
  );
};

export default InputsList;
