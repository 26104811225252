import React from "react";
import { inRange, partition, lowerCase, capitalize, isNil, get } from "lodash";
import styled from "styled-components/macro";
import {
  DeepDiveData,
  PeopleMetrics,
  DeepDiveDataRecord
} from "../../interfaces/team-deep-dive";
import SignalTypeContainer from "../SignalTypeContainer/SignalTypeContainer";
import WarningTriangle from "../WarningTriangle/WarningTriangle";
import SlackAvatar from "../SlackAvatar/SlackAvatar";
import { hexToRgb } from "../../utils/color";
import {
  teamDeepDiveSections,
  healthFactorsMetadata,
  healthScoreRanges,
  Signals,
  signalTypes,
  urlParamKeys
} from "../../constants/constants";
import { useUrlParams } from "../../hooks/useUrlParams";
import { UserReportWithMetadataInterface } from "../../interfaces/user";
import InfoPopups from "../InfoPopups/InfoPopups";
import MetricCalloutDetails from "../MetricCalloutDetails/MetricCalloutDetails";
import RouteLink from "../RouteLink/RouteLink";
import MetricTileContextSwitchingDetails from "../MetricTileContextSwitchingDetails/MetricTileContextSwitchingDetails";
import MetricTileDeepWorkDetails from "../MetricTileDeepWorkDetails/MetricTileDeepWorkDetails";
import { getSelectableTeamMembers } from "../../utils/people-picker";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  background-color: ${props =>
    `${hexToRgb({ hex: props.theme.colors.all.lightJean, opacity: 0.5 })}`};
  width: 26.4rem;
  flex-wrap: wrap;
  margin-bottom: auto;

  & + & {
    margin-left: ${props => props.theme.grid.gap};
  }
`;

const Name = styled.header`
  font-family: ${props => props.theme.fonts.header.name}, serif;
  font-size: ${props => props.theme.fonts.header.sizes.sm};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
`;

const MetricCircle = styled.div<{ metricType: string }>`
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 50%;
  background-color: ${props =>
    props.theme.colors.chart.healthFactors[props.metricType] || "white"};
`;

const MetricCircleWrapper = styled.div`
  margin-right: 1rem;
  margin-left: 0.5rem;
  max-width: 9.9rem;
`;

const SectionHeader = styled.header`
  font-family: ${props => props.theme.fonts.primary.name}, serif;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  border-bottom: ${props => `1px solid ${props.theme.colors.all.jean}`};
`;

const SectionContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;
`;

const TileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 1rem;
  align-items: center;
`;

const AlertTriangle = styled(WarningTriangle)`
  margin-top: -0.5rem;
`;

const SignalType = styled(SignalTypeContainer)`
  margin-bottom: 0.5rem;
  width: 100%;
`;

const SignalInDropdown = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
`;

const SignalDefinition = styled.span<{ signal: string }>`
  background-color: ${props => props.theme.colors.signalHealth[props.signal]};
  margin: 1rem 0;
  padding: 0 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  font-family: ${props => props.theme.fonts.primary.name}, serif;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;

const SignalNotInDropdown = styled.div<{ healthy: boolean }>`
  margin-bottom: 0.5rem;
  width: 100%;
  padding: 1rem 0 1rem 1rem;
  background-color: ${props => `${props.theme.colors.all.white}`};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  box-shadow: ${props =>
    `inset 5px 0px ${
      props.healthy
        ? props.theme.colors.all.marvelMint
        : props.theme.colors.all.rogue
    }`};
`;

const Avatar = styled(SlackAvatar)`
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.border}`};
`;

const TeamMemberName = styled.strong`
  margin-left: 1rem;
`;

const DeepDiveLink = styled(RouteLink)`
  align-items: center;
  display: flex;
  &,
  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
    text-decoration: none;
  }
`;
type Props = {
  metricTitle: PeopleMetrics;
  data?: DeepDiveData;
  chartView: UserReportWithMetadataInterface | "team";
  testId?: string;
};
const MetricCalloutTile = ({
  metricTitle,
  data,
  chartView,
  testId = "testId"
}: Props): JSX.Element | null => {
  const thisTestId = `${testId}-metric-callout-tile`;
  const { getTeamDeepDivePath, urlParams, teamDeepDiveParams } = useUrlParams();
  const user = useSelector((state: AppStateInterface) => get(state, "user"));
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const selectableTeamMembers = getSelectableTeamMembers({
    user,
    flags,
    teamDeepDiveParams: {
      ...teamDeepDiveParams,
      selectedMetric: metricTitle
    },
    urlParams
  });
  const visibleTeamMembers = urlParams.team?.teamMembers.filter(
    m =>
      selectableTeamMembers.includes(m.id) &&
      (chartView === "team" || chartView.id === m.id)
  );
  const mostRecentData = data?.sort((a, b) =>
    b.dateEnd.localeCompare(a.dateEnd)
  )?.[0];
  const sanitizedMostRecentData: DeepDiveDataRecord | null = !!mostRecentData?.peopleScores
    ? {
        ...mostRecentData,
        peopleScores: mostRecentData?.peopleScores?.filter(person => {
          return !(metricTitle === "ALWAYS_ON" && !person.ALWAYS_ON);
        })
      }
    : null;

  if (!sanitizedMostRecentData || !visibleTeamMembers?.length) return null;
  function scoreToSignal(score?: number | undefined | null) {
    // truthy checks of score return false when score is 0, so we check explicitly for undefined
    if (score === undefined || score === null) return undefined;
    return Object.keys(healthScoreRanges)[
      Object.values(healthScoreRanges).findIndex(r =>
        inRange(score, r[0], r[1])
      )
    ];
  }
  const metricSignal = scoreToSignal(
    sanitizedMostRecentData?.teamScores?.[metricTitle]
  );

  if (!metricSignal) {
    return null;
  }

  const [
    unhealthyPeopleIds,
    healthyPeopleIds
  ] = partition(sanitizedMostRecentData?.peopleScores, peopleScore =>
    metricTitle === "DEEP_WORK"
      ? peopleScore.DEEP_WORK.HOURS?.["TOTAL"] === "LOW"
      : metricTitle === "ALWAYS_ON"
      ? peopleScore.ALWAYS_ON && peopleScore.ALWAYS_ON !== "NORMAL"
      : metricTitle === "CONTEXT_SWITCHING"
      ? (!isNil(peopleScore.CONTEXT_SWITCHING["TOTAL"]) &&
          peopleScore.CONTEXT_SWITCHING["TOTAL"]["bucket"]) === "MORE"
      : metricTitle === "CHAT_INTERRUPTIONS"
      ? peopleScore.CHAT_INTERRUPTIONS["TOTAL"] === "HIGH"
      : true
  );

  const unhealthyPeople =
    visibleTeamMembers?.filter(m => {
      return unhealthyPeopleIds.map(u => u.user).includes(m.id);
    }) || [];

  const healthyPeople =
    visibleTeamMembers?.filter(m => {
      return healthyPeopleIds.map(u => u.user).includes(m.id);
    }) || [];
  function getPathFromEmail(email: string) {
    return getTeamDeepDivePath({
      querystringParams: {
        [urlParamKeys.SELECTED_USER]: email
      },
      selectedMetric: metricTitle,
      selectedSection: teamDeepDiveSections.HEALTH_METRICS
    });
  }

  const shouldShowTeamMetricSignal = !!metricSignal && chartView === "team";
  return (
    <Container>
      <TileHeader>
        {!!unhealthyPeople.length && (
          <AlertTriangle baseWidth={14} alertCount={unhealthyPeople.length} />
        )}
        <MetricCircleWrapper>
          <MetricCircle metricType={metricTitle} />
        </MetricCircleWrapper>

        <Name>{healthFactorsMetadata[metricTitle]?.title}</Name>
        <InfoPopups selectedMetric={metricTitle} />
      </TileHeader>
      <SectionContainer>
        {shouldShowTeamMetricSignal && (
          <div>
            <SectionHeader>Health Score</SectionHeader>
            <SignalDefinition signal={metricSignal || ""}>
              {metricSignal}
            </SignalDefinition>
          </div>
        )}
        {unhealthyPeople.map(teamMember => {
          return (
            <SignalNotInDropdown healthy={false} key={teamMember.id}>
              <DeepDiveLink
                name={thisTestId}
                to={getPathFromEmail(teamMember.email)}
              >
                <Avatar
                  altText={`${teamMember.name} image`}
                  src={teamMember.slackAvatar}
                  testId={`${teamMember.name}-healthy-metric-avatar`}
                />
                <TeamMemberName data-heap-redact-text="true">
                  {teamMember.name}
                </TeamMemberName>
                {unhealthyPeopleIds
                  ?.filter(u => u.user === teamMember.id)
                  ?.map(u =>
                    metricTitle === Signals.DEEP_WORK
                      ? u.DEEP_WORK.HOURS?.["TOTAL"]
                      : metricTitle === Signals.ALWAYS_ON
                      ? u.ALWAYS_ON
                      : metricTitle === Signals.CONTEXT_SWITCHING
                      ? u.CONTEXT_SWITCHING["TOTAL"]?.bucket
                      : metricTitle === Signals.CHAT_INTERRUPTIONS
                      ? u.CHAT_INTERRUPTIONS["TOTAL"]
                      : ""
                  )
                  ?.filter(s => !isNil(s))
                  ?.map(
                    s =>
                      `: ${capitalize(
                        lowerCase((s as string | number).toString())
                      )}`
                  )}
              </DeepDiveLink>
            </SignalNotInDropdown>
          );
        })}
        {!!healthyPeople.length && chartView !== "team" && (
          <SignalNotInDropdown healthy={true} key={chartView.id}>
            <DeepDiveLink
              name={thisTestId}
              to={getPathFromEmail(chartView.email)}
            >
              <Avatar
                altText={`${chartView.name} image`}
                src={chartView.slackAvatar}
                testId={`${chartView.name}-healthy-metric-avatar`}
              />
              <TeamMemberName data-heap-redact-text="true">
                {chartView.name}
              </TeamMemberName>
              {healthyPeopleIds
                ?.filter(u => u.user === chartView.id)
                ?.map(u =>
                  metricTitle === Signals.DEEP_WORK
                    ? u.DEEP_WORK.HOURS?.["TOTAL"]
                    : metricTitle === Signals.ALWAYS_ON
                    ? u.ALWAYS_ON || "Normal"
                    : metricTitle === Signals.CONTEXT_SWITCHING
                    ? u.CONTEXT_SWITCHING["TOTAL"]?.bucket
                    : metricTitle === Signals.CHAT_INTERRUPTIONS
                    ? u.CHAT_INTERRUPTIONS["TOTAL"]
                    : ""
                )
                ?.filter(s => !isNil(s))
                ?.map(
                  s =>
                    `: ${capitalize(
                      lowerCase((s as string | number).toString())
                    )}`
                )}
            </DeepDiveLink>
          </SignalNotInDropdown>
        )}
        {chartView !== "team" && metricTitle === "DEEP_WORK" && (
          <MetricTileDeepWorkDetails
            metricTitle={metricTitle}
            chartView={chartView}
            mostRecentData={mostRecentData}
            testId={thisTestId}
          />
        )}
        {!!healthyPeople.length && chartView === "team" && (
          <SignalType
            content={healthyPeople.map(teamMember => {
              return (
                <SignalInDropdown key={teamMember.id}>
                  <DeepDiveLink
                    name={thisTestId}
                    to={getPathFromEmail(teamMember.email)}
                  >
                    <Avatar
                      altText={`${teamMember.name} image`}
                      src={teamMember.slackAvatar}
                      testId={`${teamMember.name}-healthy-metric-avatar`}
                    />
                    <TeamMemberName data-heap-redact-text="true">
                      {teamMember.name}
                    </TeamMemberName>
                  </DeepDiveLink>
                </SignalInDropdown>
              );
            })}
            header={
              <span>{`${healthyPeople.length} Healthy Team Member${
                healthyPeople.length === 1 ? "" : "s"
              }`}</span>
            }
            isExpandable={true}
            type={signalTypes.HEALTHY}
          />
        )}
        <MetricTileContextSwitchingDetails
          metricTitle={metricTitle}
          chartView={chartView}
          mostRecentData={mostRecentData}
        />
        <MetricCalloutDetails
          metricTitle={metricTitle}
          chartView={chartView}
          data={data}
        />
      </SectionContainer>
    </Container>
  );
};

export default MetricCalloutTile;
