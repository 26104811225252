import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

// hooks
import { useOrganizationData } from "./useOrganizationData";
import { useUrlParams } from "./useUrlParams";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { getFlattenedTeamMembersTreeForTeam } from "../utils/teams";

export const useCanAccessTeamData = (): boolean => {
  const [isAccessibleTeam, setIsAccessibleTeam] = useState(false);
  const user = useSelector((state: AppStateInterface) => state.user);
  const userId = user?.id;
  const { data: usersOrgChartRootTeam } = useOrganizationData();
  const { urlParams } = useUrlParams();

  const activeTeam = urlParams.team;
  const activeTeamsTeamLeadId = activeTeam?.orgChartTeamLeadId;
  const activeTeamMemberIds = useMemo(
    () => activeTeam?.teamMembers.map(t => t.id),
    [activeTeam]
  );
  const organizationTeamMemberIds = useMemo(() => {
    if (!!usersOrgChartRootTeam) {
      return getFlattenedTeamMembersTreeForTeam(usersOrgChartRootTeam)?.map(
        t => t.id
      );
    }
  }, [usersOrgChartRootTeam]);

  const orgChartTeamLeadIsInUsersOrganization =
    !!activeTeamsTeamLeadId &&
    !!organizationTeamMemberIds?.includes(activeTeamsTeamLeadId);
  const containsTeamMemberInUsersOrganization = !!organizationTeamMemberIds?.some(
    id =>
      activeTeamMemberIds?.includes(id) || activeTeam?.createdByUser?.id === id
  );
  const userIsOnTeam = !!activeTeamMemberIds?.includes(userId);
  const userCreatedTeam = activeTeam?.createdByUser?.id === userId;

  // allow access if the team meets one of these criteria, otherwise do not allow access
  useEffect(() => {
    setIsAccessibleTeam(
      orgChartTeamLeadIsInUsersOrganization ||
        containsTeamMemberInUsersOrganization ||
        userIsOnTeam ||
        userCreatedTeam
    );
  }, [
    orgChartTeamLeadIsInUsersOrganization,
    containsTeamMemberInUsersOrganization,
    userCreatedTeam,
    userIsOnTeam
  ]);

  return isAccessibleTeam;
};
