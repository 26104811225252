import { useEffect, useMemo, useState } from "react";

import { ErrorInterface } from "../../interfaces/error";
import { Repository } from "../../interfaces/repository";
import { requestEnabledRepositories } from "../../utils/settings";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import { captureException, withScope } from "@sentry/react";

export const useBackingData = (
  manualState?: Repository[]
): [Repository[], boolean, ErrorInterface | null] => {
  const [data, _setData] = useState<Repository[]>([]);
  const [isFetchingData, _setIsFetchingData] = useState<boolean>(true);
  const [error, _setError] = useState<ErrorInterface | null>(null);
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const requestProps = useMemo(() => {
    return {
      accessToken,
      tenantId
    };
  }, [accessToken, tenantId]);

  useEffect(() => {
    (async () => {
      if (manualState) {
        _setData(manualState);
      } else {
        try {
          if (!error) {
            const responseData = await requestEnabledRepositories(requestProps);
            _setData(responseData);
          }
        } catch (e) {
          _setError(e);
          withScope(scope => {
            scope.setContext("request-enabled-repositories", requestProps);
            captureException(e);
          });
        }
      }
      _setIsFetchingData(false);
    })();
  }, [manualState, requestProps, error]);

  return [data, isFetchingData, error];
};
