import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { startCase, capitalize } from "lodash";

// interfaces
import { CycleTimeStageTypes } from "../../interfaces/constants";

// styled components
const Container = styled.header<{
  stageKey: keyof CycleTimeStageTypes;
}>`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};

  &::before {
    content: "";
    display: inline-block;
    height: 0.8em;
    margin-right: 0.8em;
    width: 0.8em;
    background: ${props =>
      props.theme.colors.chart.cycleTime[props.stageKey].backgroundColor};
  }
`;

// typescript props
type Props = {
  className?: string;
  stageKey: keyof CycleTimeStageTypes;
  testId?: string;
};

const CycleTimeStageHeader = ({
  className,
  stageKey,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-cycle-time-stage-header`;
  return (
    <Container
      className={className}
      data-testid={thisTestId}
      stageKey={stageKey as keyof CycleTimeStageTypes}
    >
      {startCase(capitalize(stageKey))}
    </Container>
  );
};

export default CycleTimeStageHeader;
