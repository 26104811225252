import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import Icon from "../Icon/Icon";

// hooks
import { useTracking } from "../../hooks/useTracking";

// styled components
const HeaderWrapper = styled.header`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;
const Arrow = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  font-size: 1em;
`;
export const ContentWrapper = styled.div<{ isOpen: boolean }>`
  ${props => {
    if (props.isOpen) {
      return css`
        height: auto;
        opacity: 1;
        overflow: visible;
      `;
    } else {
      return css`
        height: 0;
        opacity: 0; // to make invisible for tests
        overflow: hidden;
      `;
    }
  }}
`;

// typescript props
type Props = {
  className?: string;
  children: React.ReactNode;
  defaultIsOpen?: boolean;
  header: React.ReactNode;
  testId?: string;
};

const Expander = ({
  children,
  className,
  header,
  defaultIsOpen = false,
  testId = "testId"
}: Props) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const thisTestId = `${testId}-expander`;
  const { trackEvent } = useTracking();

  function onToggle(e: React.MouseEvent<HTMLDivElement>) {
    e.persist();
    setIsOpen(!isOpen);
    trackEvent({
      e,
      label: `${thisTestId}-click`,
      value: isOpen ? "close" : "open"
    });
  }

  if (!children || !header) {
    return null;
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <HeaderWrapper
        id={`${thisTestId}-header`}
        onClick={onToggle}
        data-testid={`${thisTestId}-header-wrapper`}
      >
        {header}
        {isOpen ? (
          <Arrow icon="arrow-up" testId={thisTestId} />
        ) : (
          <Arrow icon="arrow-down" testId={thisTestId} />
        )}
      </HeaderWrapper>
      <ContentWrapper isOpen={isOpen}>{children}</ContentWrapper>
    </div>
  );
};

export default Expander;
