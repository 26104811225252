import React, { ReactElement, useMemo } from "react";

// components
import Chart from "../Chart/Chart";
import OverviewDeepWorkScatterplotTooltip from "../OverviewDeepWorkScatterplotTooltip/OverviewDeepWorkScatterplotTooltip";

// config
import generateConfig from "./config";

// interfaces
import { Benchmark, OrgDeepWorkByGroup } from "../../interfaces/organization";
import { groupByTypes } from "../../constants/constants";

// typescript props
type Props = {
  benchmark?: Benchmark;
  className?: string;
  data: OrgDeepWorkByGroup;
  groupBy: typeof groupByTypes[keyof typeof groupByTypes];
  isFetchingData?: boolean;
  testId?: string;
  title: string;
};

const ChartOverviewDeepWorkScatterplot = ({
  benchmark,
  className,
  data,
  groupBy,
  isFetchingData,
  testId = "testId",
  title
}: Props): ReactElement => {
  const thisTestId = `${testId}-chart-overview-deep-work-scatterplot`;
  const chart = useMemo(() => {
    return {
      config: generateConfig({ data, title, benchmark, groupBy }),
      id: "overview-deep-work-scatterplot-chart",
      isFetchingData
    };
  }, [data, benchmark, isFetchingData, groupBy, title]);

  return (
    <figure className={className} data-testid={thisTestId}>
      <Chart
        chart={chart}
        className={className}
        testId={thisTestId}
        tooltipComponent={<OverviewDeepWorkScatterplotTooltip />}
      />
      <p className="visuallyHidden highcharts-description">{title}</p>
    </figure>
  );
};

export default ChartOverviewDeepWorkScatterplot;
