import { formatTimestamp } from "../../utils/date";

export default ({ data, onSelectDate, selectedDate, theme }) => {
  let categories = [];
  let seriesData = [];
  let companyAverageData;

  data.forEach((date, index) => {
    const { measures, overall, startDate } = date;
    seriesData.push({
      x: index,
      y: overall.values[measures[0]],
      selected: selectedDate === startDate
    });
    categories.push(startDate);
    if (date.startDate === selectedDate) {
      companyAverageData = overall.baselineValues[measures[0]];
    }
  });

  const series = [
    {
      name: "With story points",
      data: seriesData,
      type: "line"
    }
  ];

  return {
    chart: {
      marginRight: 22,
      marginTop: 22,
      style: {
        fontFamily: theme.fonts.subheader.name,
        fontWeight: theme.fonts.subheader.weights.regular
      }
    },
    xAxis: {
      categories,
      labels: {
        formatter: function() {
          return formatTimestamp({ format: "M/D", timestamp: this.value });
        },
        style: {
          fontSize: "14px"
        },
        y: 40
      },
      tickWidth: 1,
      tickmarkPlacement: "on"
    },
    yAxis: {
      max: 100,
      tickInterval: 10,
      title: {
        enabled: false
      },
      labels: {
        step: 2,
        format: "{value}%",
        style: {
          fontSize: "14px"
        },
        x: -30
      },
      plotLines: [
        {
          value: companyAverageData,
          dashStyle: "shortdot",
          width: 2,
          color: theme.colors.chart.storyPoints.marker.stroke,
          label: {
            style: {
              textTransform: "uppercase"
            },
            text: "Company Avg.",
            align: "right",
            x: 0,
            y: 15
          }
        }
      ]
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      line: {
        connectNulls: true,
        marker: {
          symbol: "circle",
          fillColor: theme.colors.chart.storyPoints.marker.fill,
          lineColor: theme.colors.chart.storyPoints.marker.fill
        },
        color: theme.colors.chart.storyPoints.marker.fill,
        states: {
          hover: {
            opacity: theme.charts.series.opacity.full,
            lineWidth: "4px",
            lineWidthPlus: 0
          },
          inactive: {
            animation: {
              duration: 0
            }
          }
        }
      },
      series: {
        pointPlacement: "on",
        point: {
          events: {
            select: function() {
              onSelectDate(this.category);
            }
          }
        },
        marker: {
          states: {
            select: {
              fillColor: theme.colors.chart.storyPoints.marker.fill,
              lineColor: theme.colors.chart.storyPoints.marker.stroke
            }
          }
        }
      }
    },
    series
  };
};
