import React from "react";
import { Drawer } from "@material-ui/core";
import styled from "styled-components/macro";

// styled components
const Container = styled(Drawer)`
  & .MuiDrawer-paper {
    width: 40rem;
  }
`;

// typescript props
type Props = {
  children: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  onCloseDrawer?: (e: React.MouseEvent<MouseEvent>) => void;
  testId?: string;
};

const ContextDrawer = ({
  children,
  className,
  isOpen = false,
  onCloseDrawer = () => null,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-context-drawer`;
  return (
    <Container
      anchor="left"
      className={className}
      data-testid={thisTestId}
      onClose={onCloseDrawer}
      open={isOpen}
    >
      {children}
    </Container>
  );
};

export default ContextDrawer;
