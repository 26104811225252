import React from "react";
import styled, { css } from "styled-components/macro";

// components
import Icon from "../Icon/Icon";

// styled components
const Container = styled.button`
  align-items: baseline;
  background: ${props => props.theme.colors.all.lightJean};
  border: 0;
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
`;
const Arrow = styled(Icon)<{ isDisabled: boolean }>`
  color: ${props =>
    props.isDisabled
      ? props.theme.colors.all.storm
      : props.theme.colors.all.wolverine};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

// typescript props
type Props = {
  className?: string;
  content: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isActive?: boolean;
  isDisabled?: boolean;
  testId?: string;
};

const UiFilterPopupTrigger = ({
  className,
  content,
  onClick,
  isActive = false,
  isDisabled = false,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-ui-filter-popup-trigger`;
  return (
    <Container
      className={className}
      disabled={isDisabled}
      onClick={onClick}
      data-testid={thisTestId}
    >
      {content}
      <Arrow
        isDisabled={isDisabled}
        icon={isActive ? "arrow-up" : "arrow-down"}
        testId={thisTestId}
      />
    </Container>
  );
};

export default UiFilterPopupTrigger;
