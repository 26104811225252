import React, { ReactElement } from "react";

// hooks
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

const StartPage = (): ReactElement => {
  const { getOverviewPath, getTeamHomePath } = useUrlParams();
  const flags = useSelector((state: AppStateInterface) => state.flags);

  return (
    <Redirect
      to={
        flags?.["show-overview-for-start-page"]
          ? getOverviewPath({ persistQuerystring: false })
          : getTeamHomePath()
      }
    />
  );
};

export default StartPage;
