import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { flattenDeep, get, isEmpty, isNil } from "lodash";
import teamAverageImage from "../../images/team.png";

// components
import ChartMeetingClassifications from "../ChartMeetingClassifications/ChartMeetingClassifications";
import EmptyState from "../EmptyState/EmptyState";
import MeetingListSummary from "../MeetingListSummary/MeetingListSummary";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";
import { useBackingData } from "./useBackingData";

// utils

// constants
import { TEAM_AVERAGE_ID } from "../../constants/components";
import LoadingStates from "../LoadingStates/LoadingStates";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";
import InfoPopups from "../InfoPopups/InfoPopups";
import { meetingInsightsMeasurePathParams } from "../../constants/constants";

// styled components
const Figure = styled.figure`
  min-height: 40rem;
`;
const Caption = styled.figcaption`
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const Title = styled.div`
  display: flex;
`;

export const MeetingClassificationsChartWrapper = ({
  className = "",
  testId = "testId",
  ...props
}) => {
  const { accessToken, tenantId, user, timerange } = props;
  const { urlParams, teamDeepDiveParams } = useUrlParams();
  const teamMembers = urlParams.team?.teamMembers;
  const selectedUserValue = urlParams.selectedUser || "team";
  const selectedUser =
    urlParams.selectedUser && selectedUserValue !== "team"
      ? teamMembers.find(t => t.email === selectedUserValue)
      : {
          id: TEAM_AVERAGE_ID,
          name: "Team Average",
          slackAvatar: teamAverageImage
        };

  const thisTestId = `${testId}-meeting-classifications-chart-wrapper`;
  const [overrideOption, setOverrideOption] = useState(null);

  const [meetingClassificationsData, isFetchingData, error] = useBackingData(
    accessToken,
    tenantId,
    teamMembers,
    user,
    overrideOption,
    timerange
  );

  function handleOverride(newValue) {
    setOverrideOption(newValue);
  }

  if (isFetchingData || isNil(meetingClassificationsData)) {
    return (
      <LoadingStates
        isSpinningState={isFetchingData}
        isNoDataState={isNil(meetingClassificationsData)}
        noDataContent={
          <EmptyState
            body={
              <span>
                It looks like we don&rsquo;t have data for this team.
                <a href="mailto:support@uplevelteam.com">Contact us</a> if this
                is a mistake and we&rsquo;ll get this fixed.
              </span>
            }
            header="Uh oh."
            testId={thisTestId}
          />
        }
      />
    );
  }

  let selfDataMeetings = [];
  let selfData = meetingClassificationsData.resultsByUser.find(
    r => r.userId === user.id
  );
  if (!isEmpty(selfData)) {
    selfDataMeetings = flattenDeep(
      Object.values(get(selfData, "mappedValues", {})).map(
        classification => classification.meetings
      )
    );
  }
  const selectedUserData =
    selectedUserValue === "team"
      ? meetingClassificationsData.overall
      : meetingClassificationsData.resultsByUser.find(
          r => selectedUserValue === r.user
        ) ||
        meetingClassificationsData.resultsByUser.find(
          r => r.userId === user.id
        );

  return (
    <TransitionWrapper>
      <div className={className} data-testid={thisTestId}>
        <Figure>
          <ChartMeetingClassifications
            data={
              !!selectedUser
                ? selectedUserData.mappedValues
                : meetingClassificationsData.overall.mappedValues
            }
            selectedTeamMember={selectedUser}
            isFetchingData={isFetchingData}
            testId={thisTestId}
            handleOverride={handleOverride}
            override={overrideOption}
          />
          <Title>
            <Caption>Meeting Distribution</Caption>
            <InfoPopups selectedMetric={teamDeepDiveParams.selectedMetric} />
          </Title>
        </Figure>
        {!!selfDataMeetings.length ? (
          <MeetingListSummary
            meetings={selfDataMeetings}
            name="meeting-classifications-chart"
            handleOverride={handleOverride}
            testId={thisTestId}
            overrideOption={overrideOption}
            error={error}
          />
        ) : null}
      </div>
    </TransitionWrapper>
  );
};

MeetingClassificationsChartWrapper.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string,
  user: PropTypes.object.isRequired,
  timerange: PropTypes.object
};
export default MeetingClassificationsChartWrapper;
