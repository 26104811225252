import { useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  requestTeamsCustomizations,
  updateTeamCustomizations
} from "../utils/teams";
import { AppStateInterface } from "../interfaces/app-state";

export const useCustomizationData = (teamId: string) => {
  const tenantId: number = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const accessToken: string = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );

  const teamParams = useMemo(() => {
    return {
      accessToken,
      tenantId,
      params: {
        teamIds: [teamId]
      }
    };
  }, [accessToken, teamId, tenantId]);

  const customizationParams = useMemo(() => {
    return {
      accessToken,
      tenantId,
      params: {
        teamSettings: {
          teamId,
          settings: {
            storyPoints: true,
            subtasks: true
          }
        }
      }
    };
  }, [accessToken, teamId, tenantId]);

  const queryClient = useQueryClient();

  const queryData = useQuery(
    ["team-customization-data", teamParams],
    () => requestTeamsCustomizations(teamParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!accessToken && !!tenantId && !!teamId
    }
  );

  const queryMutation = useMutation(
    (newSettings: any) =>
      updateTeamCustomizations({
        ...customizationParams,
        params: {
          teamSettings: {
            teamId,
            settings: newSettings
          }
        }
      }),
    {
      onSuccess: updatedSettings => {
        queryClient.invalidateQueries("team-customization-data");
        queryClient.setQueryData(
          "team-customization-data",
          () => updatedSettings
        );
      }
    }
  );

  return { queryData, queryMutation };
};
