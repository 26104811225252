import { useMemo } from "react";

// hooks
import { useSelector } from "react-redux";
import { useQuery, UseQueryResult } from "react-query";
import { useUrlParams } from "./useUrlParams";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { requestOperationalMetrics } from "../utils/work-deep-dive";
import { OpMetricData } from "../interfaces/ops-metrics";

export const useOperationalMetricsData = (
  canRequest = true
): UseQueryResult<OpMetricData> => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const { urlParams } = useUrlParams();
  const { timerange, sprintId, teamId } = urlParams;
  const { start: startDate, end: endDate } = timerange;

  const params = useMemo(
    () => ({
      startDate,
      endDate,
      teamId: teamId || null,
      sprintId: sprintId || null
    }),
    [startDate, endDate, sprintId, teamId]
  );

  return useQuery(
    ["operational-metrics", { ...params }],
    () =>
      requestOperationalMetrics({
        accessToken,
        tenantId,
        params
      }),
    {
      enabled: !!accessToken && !!tenantId && canRequest,
      refetchOnWindowFocus: false
    }
  );
};
