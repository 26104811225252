import { useCallback } from "react";

// constants
import { authProviders } from "../constants/constants";

// hooks
import { useOktaAuth } from "@okta/okta-react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

export const useAuthProvider = (): {
  getAccessToken: () => Promise<string>;
  getUser: () => Promise<{ [key: string]: string }>;
  getUserGivenName: () => Promise<string>;
  getIsAuthenticated: () => Promise<boolean>;
  isAuth0: boolean;
} => {
  const okta = useOktaAuth();
  const auth0 = useAuth0();
  const authProvider = useSelector(
    (state: AppStateInterface) => state.auth.authParams.authProvider
  );
  const isAuth0 = authProvider === authProviders.AUTH0;

  const getIsAuthenticated = useCallback(() => {
    return (isAuth0
      ? Promise.resolve(auth0?.isAuthenticated)
      : okta?.oktaAuth.isAuthenticated()
    ).then((response: boolean) => response);
  }, [isAuth0, okta, auth0]);

  const getAccessToken = useCallback(async () => {
    return isAuth0
      ? await auth0?.getAccessTokenSilently()
      : await okta?.oktaAuth.getAccessToken();
  }, [isAuth0, okta, auth0]);

  const getUser = useCallback(async () => {
    return isAuth0 ? auth0?.user : await okta?.oktaAuth.getUser();
  }, [isAuth0, okta, auth0]);

  const getUserGivenName = useCallback(async () => {
    const user = await getUser();
    return user.given_name || user.name;
  }, [getUser]);

  return {
    getAccessToken,
    getUser,
    getUserGivenName,
    getIsAuthenticated,
    isAuth0
  };
};
