import {
  capitalize,
  compact,
  isNull,
  isNumber,
  round,
  sample,
  startCase
} from "lodash";
import { avatarImages } from "../constants/widgets";

// constants
import {
  directionalityTypes,
  overviewHealthTypes,
  healthFactorsMetadata,
  Signals
} from "../constants/constants";

// utils
import { formatTimestamp } from "./date";
import { getSprintRangeString } from "../utils/sprints";
import { hexToRgb } from "../utils/color";
import { filterTruthyCycleTimeValues, splitCycleTimeHours } from "./cycle-time";

export const getSlackAvatarHtml = ({ slackAvatar, width }) => {
  return `<span class="chart-slackAvatar" style="width: ${width}px; height: ${width}px;"><img class="avatar" src="${slackAvatar ||
    sample(avatarImages)}" alt="" height="${width}" width="${width}" /></span>`;
};

export const getMeetingClassificationsTooltipHtml = pointData => {
  const {
    classificationName,
    deltaPercent,
    name,
    slackAvatar,
    value,
    valueHours
  } = pointData;

  let indicatorDirection = "";
  if (isNumber(deltaPercent) && deltaPercent !== 0) {
    indicatorDirection =
      deltaPercent > 0
        ? directionalityTypes.POSITIVE
        : directionalityTypes.NEGATIVE;
  }

  const slackAvatarHtml = getSlackAvatarHtml({
    slackAvatar,
    width: 50
  });

  return `<div class="chart-tooltip chart-tooltip-meeting-classifications"><div class="chart-tooltip-header">${slackAvatarHtml}${name}</div><div class="chart-tooltip-subheader">${classificationName}</div><div>${valueHours.toFixed(
    1
  )} ${valueHours === 1 ? "hr" : "hrs"} | ${value.toFixed(1)}%${
    isNumber(deltaPercent)
      ? ` (<span class="${indicatorDirection}">${Math.abs(deltaPercent).toFixed(
          1
        )}%</span>)`
      : ""
  }</div>`;
};

export const getPrCadenceTooltip = pointData => {
  return `<div class="pr-cadence-chart-tooltip chart-tooltip"><div class="chart-tooltip-header">${formatTimestamp(
    {
      format: "M/D",
      timestamp: pointData[0].x.start
    }
  )}&ndash;${formatTimestamp({
    format: "M/D",
    timestamp: pointData[0].x.end
  })}</div><ul><li><div>Number PRs merged</div><div class="prsColor colorKey">${
    pointData[0].y
  } prs</div></li><li><div>Median review time (hrs)</div><div class="reviewTimeColor colorKey colorKeyCircle">${pointData[1].y.toFixed(
    1
  )} hrs</div></li></ul></div>`;
};

export const getMeetingTimeTooltip = (pointData, selectedTeamMember) => {
  const isTeamSeriesTooltip = pointData.series.userOptions.id === "team";
  const isLineSeriesTooltip = pointData.series.userOptions.type === "line";

  const colorKeyClassnames = isLineSeriesTooltip
    ? "userMeetingTimeColor colorKey colorKeyCircle"
    : "teamMeetingTimeColor colorKey";
  return `<div class="meeting-time-chart-tooltip chart-tooltip"><div class="chart-tooltip-header">${formatTimestamp(
    {
      format: "M/D",
      timestamp: pointData.category
    }
  )}</div><ul><li><div>${
    isTeamSeriesTooltip ? "Team average" : selectedTeamMember?.name
  } meeting time per day</div><div class="${colorKeyClassnames}">${pointData.y.toFixed(
    1
  )} hrs</div></li></ul></div>`;
};

// use this only when you're using outside: true since then the tooltip is positioned
// to the scroll container and not inside the bounds of the chart
export const getAdjustedTooltipPosition = ({
  currentPosition,
  windowScroll
}) => {
  return {
    ...currentPosition,
    y: currentPosition.y - windowScroll
  };
};

export const getSprintHealthTooltip = pointData => {
  const seriesName = pointData.series.name;

  let overallScore, factorsList;
  if (
    seriesName ===
    healthFactorsMetadata[overviewHealthTypes.AVERAGE_HEALTH].title
  ) {
    overallScore = `Average of Team and Project Health: ${capitalize(
      getSprintRangeString(pointData.averageHealth)
    )}`;
  } else {
    const factorValue =
      seriesName ===
      healthFactorsMetadata[overviewHealthTypes.PEOPLE_HEALTH].title
        ? pointData.peopleHealth
        : pointData.projectHealth;

    overallScore = `${seriesName}: ${capitalize(
      getSprintRangeString(factorValue)
    )}`;
    const factorsData =
      seriesName ===
      healthFactorsMetadata[overviewHealthTypes.PEOPLE_HEALTH].title
        ? pointData.peopleHealthFactors
        : pointData.projectHealthFactors;
    factorsList = compact(
      Object.entries(factorsData).map(([k, v]) => {
        return isNull(v)
          ? null
          : `<div><dt>${startCase(capitalize(k))}: </dt><dd>${capitalize(
              getSprintRangeString(v)
            )}</dd></div>`;
      })
    ).join("");
  }

  return `<div class="sprint-health-chart-tooltip chart-tooltip"><div class="chart-tooltip-header"><h3>${
    pointData.sprintName
  } <span style="margin-left: 1rem;">${
    pointData.category
  }</span></h3><h2>${overallScore}</h2></div>${
    factorsList ? `<dl>${factorsList}</dl>` : ""
  }</div>`;
};

export const getSprintHealthFactorsTooltip = pointData => {
  const seriesName = pointData.series.name;
  const strippedName = /\(/.test(seriesName)
    ? seriesName.substring(0, seriesName.indexOf(" ("))
    : seriesName;

  return `<div class="sprint-health-trend-analysis-chart-tooltip chart-tooltip"><div>${strippedName}</div>${startCase(
    getSprintRangeString(pointData.y).toLowerCase()
  )}</div>`;
};

export const getPlotBands = (
  healthScoreRanges,
  scoreTypes,
  theme,
  metricType
) => {
  function getAlwaysOnText(key) {
    return key === "HIGH"
      ? "High<br>60+ min avg. overtime"
      : key === "ABOVE_NORMAL"
      ? "Above Normal<br>30-60 min avg. overtime"
      : "Sustainable<br>Working Hours<br><30 min avg. overtime";
  }

  return Object.entries(healthScoreRanges).map(([key, range]) => {
    return {
      color: hexToRgb({
        hex:
          metricType === "ALWAYS_ON"
            ? theme.colors.chart.alwaysOnRiskTrend.marker.fill[key]
            : metricType === "CONTEXT_SWITCHING"
            ? theme.colors.relativeHealth[key]
            : theme.colors.signalHealth[key],
        opacity:
          metricType === "ALWAYS_ON"
            ? theme.colors.chart.alwaysOnRiskTrend.marker.opacity[key]
            : 0.2
      }),
      label: {
        text:
          metricType === "ALWAYS_ON"
            ? getAlwaysOnText(key)
            : startCase(key.toLowerCase()),
        verticalAlign: metricType === "ALWAYS_ON" ? "middle" : "bottom",
        y: metricType === "ALWAYS_ON" ? 0 : -5,
        textAlign: "left"
      },
      from: range[0],
      to: range[1]
    };
  });
};

export const getBandTickPositions = (scoreRanges, metricType) => {
  return [
    metricType === "ALWAYS_ON"
      ? 6
      : metricType === "CONTEXT_SWITCHING"
      ? 1
      : 101,
    ...Object.entries(scoreRanges).map(([key, range]) => {
      return range[0];
    })
  ].sort((a, b) => a - b);
};

export const getPeopleHealthBucketTooltip = (teamMembers, seriesColor) => {
  return `<div class="chart-tooltip people-health-chart-tooltip">
    <div style="display: flex; flex-direction: column;">
      <div style="display: flex; flex-direction: row; margin-bottom: 1rem; align-items: center;">
        <div style="height: 1.0rem; width: 1.0rem; border-radius: 50%; margin-right: 1rem; background-color: ${seriesColor ||
          "white"};"></div>
        <span>${
          (teamMembers[0].selectedMetric === Signals.CONTEXT_SWITCHING &&
            teamMembers[0].selectedSubMetric) ||
          teamMembers[0].selectedMetric === "OVERALL_TEAM_HEALTH"
            ? teamMembers[0].selectedSubMetric
            : healthFactorsMetadata[teamMembers[0].selectedMetric]["title"]
        }</span>
      </div>
    ${teamMembers
      .map(t => {
        const slackAvatarHtml = getSlackAvatarHtml({
          slackAvatar: t.slackAvatar,
          width: 35
        });

        const getAlwaysOnDisplay = t => {
          return t.value === 3
            ? "Above Normal"
            : t.value === 5
            ? "High"
            : "Normal";
        };
        const displayValue =
          t.selectedMetric === Signals.ALWAYS_ON ||
          t.selectedSubMetric === "Always On"
            ? getAlwaysOnDisplay(t)
            : t.selectedMetric === Signals.CONTEXT_SWITCHING ||
              (t.selectedMetric === "OVERALL_TEAM_HEALTH" &&
                t.selectedSubMetric === "Context Switching")
            ? t.value < 0.166
              ? "Less"
              : t.value > 0.5
              ? "More"
              : "About the Same"
            : !!t.value
            ? t.selectedMetric === "OVERALL_TEAM_HEALTH" &&
              t.selectedSubMetric === "Chat Interruptions"
              ? `${capitalize(t.value.toLowerCase())}`
              : `${t.value.toFixed(1)} ${
                  t.selectedMetric === "CHAT_INTERRUPTIONS" ||
                  t.selectedSubMetric === "Chat Interruptions"
                    ? "min"
                    : "hrs"
                }`
            : "0.0";

        return `<span style="margin-bottom: 0.5rem; font-weight: normal">${slackAvatarHtml}${t.name} - ${displayValue}</span>`;
      })
      .join("")}
    </div>
  </div>`;
};

export const getPeopleHealthContextSwitchingTooltip = (
  title,
  itemsToShow,
  seriesColor
) => {
  return `<div class="chart-tooltip people-health-chart-tooltip">
      <div style="display: flex; flex-direction: column;">
        <div style="display: flex; flex-direction: row; margin-bottom: 1rem; align-items: center;"><div style="height: 1.0rem; width: 1.0rem; border-radius: 50%; margin-right: 1rem; background-color: ${seriesColor ||
          "white"};"></div>${title}</div>${itemsToShow
    .map(
      item =>
        `<span style="margin-bottom: 0.5rem; font-weight: normal">${item}</span>`
    )
    .join("")}
      </div>
    </div>`;
};

export const getDeepWorkTooltipDisplay = (
  displayRecords,
  currentAverage,
  rollingAverage = 0
) => {
  return `<div class="chart-tooltip people-health-chart-tooltip">
    <div style="display: flex; flex-direction: column;">
    <div>Deep Work</div>

    ${displayRecords
      .map(user => {
        const slackAvatarHtml = getSlackAvatarHtml({
          slackAvatar: user.slackAvatar,
          width: 35
        });
        return `
        <span style="margin-bottom: 0.5rem; font-weight: normal">${slackAvatarHtml}${user.name} - ${user.bucket}</span>`;
      })
      .join("")}
    ${`<span style="margin-bottom: 0.5rem; font-weight: normal">Current Average Deep Work Time ${currentAverage.toFixed(
      1
    )} hours/workday</span>`}
    ${
      !!rollingAverage
        ? `<span style="margin-bottom: 0.5rem; font-weight: normal">Rolling Average Deep Work Time ${rollingAverage.toFixed(
            1
          )} hours/workday</span>`
        : ""
    }
      </div>
      </div>`;
};

export const getOverviewTrendlineYAxisLabelFormatter = (
  measure,
  isFirst,
  isLast,
  isDaysScale,
  value
) => {
  if (measure === "cycle-time") {
    if (!isFirst && !isLast) {
      return "";
    }
    const rollup = filterTruthyCycleTimeValues(
      splitCycleTimeHours(value, isDaysScale)
    ).map(t => ({ ...t, value: round(t.value, 1) }));
    return rollup
      .map(t => `${t.value}${t.unit === "DAYS" ? "d" : "h"}`)
      .join(",");
  }
  return isFirst || isLast ? value.toString() : "";
};
