import React from "react";
import styled from "styled-components/macro";

// components
import Icon from "../Icon/Icon";

// styled components
const StyledContainer = styled.div`
  color: inherit;
  align-items: center;
  display: flex;
`;
const StyledIcon = styled(Icon)`
  color: inherit;
  height: 100%;
  line-height: 1;
  margin-right: 0.5em;
`;
const StyledContent = styled.div`
  flex: 1;
`;

// typescript props
type Props = {
  className?: string;
  content: React.ReactNode;
  icon: string;
  iconColor?: string;
  testId?: string;
};

const ContentWithIcon = ({
  className,
  content,
  icon,
  iconColor,
  testId = "testId"
}: Props) => {
  return (
    <StyledContainer
      className={className}
      data-testid={`${testId}-content-with-icon`}
    >
      <StyledIcon color={iconColor} icon={icon} testId={testId} />
      <StyledContent>{content}</StyledContent>
    </StyledContainer>
  );
};

export default ContentWithIcon;
