import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { compact, kebabCase, sortBy, startCase, get, uniq } from "lodash";
import informationText from "../../constants/informationText.json";

// components
import AnnotationsModal from "../Annotations/AnnotationsModal";
import Button from "../Button/Button";
import CommentsList from "../CommentsList/CommentsList";
import Icon from "../Icon/Icon";
import InfoIcon from "../InfoIcon/InfoIcon";
import QuickReact from "../QuickReact/QuickReact";
import TimeRange from "../TimeRange/TimeRange";
import Tooltip from "../Tooltip/Tooltip";

// constants
import {
  annotationStatusTypes,
  orderedAnnotationStatusTypes,
  StatusIconMap
} from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AnnotationsInterface } from "../../interfaces/annotations";
import { AppStateInterface } from "../../interfaces/app-state";
import { TimerangeMetadataInterface } from "../../interfaces/constants";
import { UserReportWithMetadataInterface } from "../../interfaces/user";

// utils
import {
  removeAnnotations,
  writeAnnotations
} from "../../utils/work-deep-dive";

// styled components
const Header = styled.header`
  margin-bottom: 2rem;
`;
const Title = styled.h3`
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  margin: 0;
`;
const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
`;
const ReactionsRow = styled.div`
  align-items: center;
  display: flex;
`;
const ReactionsSummary = styled.ul`
  align-items: center;
  display: flex;

  &:not(:empty) {
    margin-right: 3rem;
  }
`;
const Reaction = styled.li`
  color: ${props => props.theme.colors.all.wolverine};
  & + & {
    margin-left: 1.5rem;
  }
`;
const ReactionTrigger = styled(Button)<{ userHasSelectedAnnotation: boolean }>`
  align-items: center;
  display: flex;
  padding: 0.4rem 1.6rem;
  border: ${props =>
    props.userHasSelectedAnnotation
      ? `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.wolverine}`
      : "0"};
  border-radius: 1.6rem;
`;
const ReactionTotal = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: 0;
  margin-left: 0.5rem;
`;
const ReactionIcon = styled(Icon)<{ icon: string }>`
  color: inherit;
  font-size: 1.6rem;
  height: 1em;
  width: 1em;
  position: relative;
  top: -0.2rem;

  ${props => {
    if (
      props.icon === StatusIconMap[annotationStatusTypes.CELEBRATE] ||
      props.icon === StatusIconMap[annotationStatusTypes.DISAPPOINTED]
    ) {
      return css`
        top: 0;
      `;
    }
  }}
`;
const CommentIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
`;
const CommentsListHeader = styled.header`
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  margin-bottom: 1.6rem;
  padding-bottom: 0.5rem;
`;
const SprintMetadata = styled.h4`
  align-items: center;
  display: flex;
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin: 0 1rem 0 0;
  padding-right: 1rem;
`;
const SprintName = styled.span`
  margin-right: 1rem;
`;
const CallToAction = styled.p`
  color: ${props => props.theme.colors.all.storm};
  font-size: 80%;
  margin: 0.5rem 0 1rem;
`;
const TeamList = styled.ul`
  && {
    padding-left: 0;
  }
`;
const TeamMember = styled.li`
  list-style-type: none;
`;

// typescript props
type Props = {
  annotations?: Array<AnnotationsInterface>;
  className?: string;
  sprint: TimerangeMetadataInterface;
  testId?: string;
};

const RetrosSprintReactionsSummary = ({
  annotations = [],
  className,
  sprint,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-retros-sprint-reactions-summary`;
  const [isModalOpen, setIsModalOpen] = useState(false);
  // react-query hooks
  const queryClient = useQueryClient();
  const writeAnnotationsMutation = useMutation(writeAnnotations, {
    onSuccess: () => {
      // Invalidate annotations
      queryClient.invalidateQueries("annotations");
    }
  });
  const removeAnnotationsMutation = useMutation(removeAnnotations, {
    onSuccess: () => {
      // Invalidate annotations
      queryClient.invalidateQueries("annotations");
    }
  });

  const { trackEvent } = useTracking();
  const retroReactionId = "retros-sprint-reactions-summary";

  const sprintReactions = annotations.filter(
    (a: AnnotationsInterface) => a.workItemId === retroReactionId
  );
  const commentReactions = sortBy(
    sprintReactions.filter(
      (a: AnnotationsInterface) => !!a.userDetailedComment
    ),
    "timestamp"
  );
  const sprintContext = { sprintId: sprint.id, id: sprint.id };

  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const user = useSelector((state: AppStateInterface) => state.user);
  const { urlParams } = useUrlParams();
  const teamMembers = urlParams.team?.teamMembers || [];

  // TODO: this is all mostly copy-pasta from EpicDetailsIssuesList -- figure out extracting these into a hook
  async function onReact(
    context: { [key: string]: string },
    status: string,
    e: React.SyntheticEvent
  ) {
    e.persist();
    e.preventDefault();

    // only do this check for existing annotations for emoji reactions, not
    // existing comments
    const existingAnnotation = annotations.find(
      (a: AnnotationsInterface) =>
        a.userId === user.id &&
        orderedAnnotationStatusTypes.includes(status) &&
        a.userAnnotationCheckinStatus === status
    );

    if (!!existingAnnotation) {
      try {
        removeAnnotationsMutation.mutate({
          accessToken,
          params: {
            annotationId: existingAnnotation.annotationId
          },
          tenantId
        });
        trackEvent({
          e,
          label: "retros-sprint-reactions-summary-reaction",
          value: `unreacted to ${sprint.displayName} with a value of ${status}`
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      const isTextComment = !Object.values(annotationStatusTypes).includes(
        status
      );

      const annotation = {
        userAnnotationCheckinStatus: isTextComment ? "" : status,
        userAnnotationPainLevel: 0,
        userDetailedComment: isTextComment ? status : "",
        workItemAltId: "",
        workItemId: retroReactionId,
        workItemType: "UI Component"
      };

      try {
        writeAnnotationsMutation.mutate({
          accessToken,
          params: {
            users: [user.email],
            context,
            annotation
          },
          tenantId
        });
        trackEvent({
          e,
          label: "retros-sprint-reactions-summary-reaction",
          value: `${isTextComment ? "commented on" : "reacted to"} ${
            sprint.displayName
          } with a value of ${status}`
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  function onShowTooltip(e: React.MouseEvent<HTMLElement>, statusType: string) {
    trackEvent({
      e,
      label: `${thisTestId}-${kebabCase(statusType)}-tooltip`,
      value: `list of annotators shown for sprint summary status ${statusType}`
    });
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <Header>
        <TitleWrapper>
          <Title>Sprint Reactions</Title>
          <InfoIcon content="sprint-reactions" testId={thisTestId} />
        </TitleWrapper>
        <CallToAction>
          <em>{informationText["retros-sprint-reactions-cta"]}</em>
        </CallToAction>
        <ReactionsRow>
          <ReactionsSummary>
            {compact(
              orderedAnnotationStatusTypes.map(reactionKey => {
                const reactions = sprintReactions.filter(
                  a => a.userAnnotationCheckinStatus === reactionKey
                );
                const userHasSelectedAnnotation = !!reactions.find(
                  (a: AnnotationsInterface) => a.userId === user.id
                );
                return reactions.length ? (
                  <Reaction key={reactionKey}>
                    <Tooltip
                      trigger={
                        <ReactionTrigger
                          button="unstyled"
                          onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => onReact(sprintContext, reactionKey, e)}
                          testId={`${thisTestId}-${reactionKey}`}
                          userHasSelectedAnnotation={userHasSelectedAnnotation}
                        >
                          <ReactionIcon
                            icon={
                              StatusIconMap[
                                reactionKey as
                                  | "CELEBRATE"
                                  | "THUMBS_UP"
                                  | "QUESTION"
                                  | "DISAPPOINTED"
                                  | "ANGRY"
                              ]
                            }
                            testId={thisTestId}
                          />
                          <span className="visuallyHidden">
                            {startCase(reactionKey)}
                          </span>
                          <ReactionTotal>{reactions.length}</ReactionTotal>
                        </ReactionTrigger>
                      }
                      popupContent={
                        <TeamList>
                          {compact(
                            uniq(
                              reactions.map((a: AnnotationsInterface) => {
                                const teamMember = teamMembers.find(
                                  (t: UserReportWithMetadataInterface) =>
                                    t.id === a.userId
                                );
                                return teamMember?.name || a.userEmail;
                              })
                            )
                          )
                            .sort()
                            .map((displayName: string) => (
                              <TeamMember
                                key={kebabCase(displayName)}
                                data-heap-redact-text="true"
                              >
                                {displayName}
                              </TeamMember>
                            ))}
                        </TeamList>
                      }
                      placement="bottom"
                      onShowTooltip={(e: React.MouseEvent<HTMLElement>) =>
                        onShowTooltip(e, reactionKey)
                      }
                      testId={`${thisTestId}-${reactionKey}`}
                    />
                  </Reaction>
                ) : null;
              })
            )}
          </ReactionsSummary>
          <QuickReact
            annotations={sprintReactions}
            context={sprintContext}
            onReact={onReact}
            name={kebabCase(sprint.displayName as string)}
            testId={thisTestId}
          />
          <AnnotationsModal
            context={sprintContext}
            annotations={sprintReactions}
            teamMembers={teamMembers}
            handleAnnotationClick={onReact}
            triggerWriteTextComment={onReact}
            isModalOpen={isModalOpen}
            setActiveIdToAnnotate={(id: string) => setIsModalOpen(!!id)}
            headerContent={
              <SprintMetadata>
                <SprintName data-heap-redact-text="true">
                  {sprint.displayName}
                </SprintName>
                <TimeRange
                  config={{
                    format: { start: "M/DD", end: "M/DD" }
                  }}
                  testId={thisTestId}
                  timerange={sprint}
                />
              </SprintMetadata>
            }
          />
        </ReactionsRow>
      </Header>
      {commentReactions.length ? (
        <section>
          <CommentsListHeader>
            <CommentIcon icon="comment" testId={thisTestId} /> Comments (
            {commentReactions.length})
          </CommentsListHeader>
          <CommentsList
            comments={commentReactions}
            maxVisibleComments={1}
            teamMembers={teamMembers}
            testId={thisTestId}
          />
        </section>
      ) : null}
    </div>
  );
};

export default RetrosSprintReactionsSummary;
