import { currentTheme } from "../../themes/currentTheme";

// constants
import { cycleTimeStages } from "../../constants/constants";

// interfaces
import { CycleTimeInterface } from "../../interfaces/work-items";
import { CycleTimeStageTypes } from "../../interfaces/constants";

// utils
import { getCycleTimeStageInHours } from "../../utils/cycle-time";

const config = (data: CycleTimeInterface) => {
  const totalCycleTimeInHours = getCycleTimeStageInHours(Object.values(data));
  const series = ([
    cycleTimeStages.DEV,
    cycleTimeStages.WAITING_FOR_REVIEW,
    cycleTimeStages.REVIEW,
    cycleTimeStages.RELEASE
  ] as Array<keyof CycleTimeStageTypes>)
    .reverse()
    .map(stageKey => {
      const stage = data[stageKey];
      const stageTimeInHours = !!stage
        ? stage?.unit === "DAYS"
          ? stage.value * 24
          : stage.value
        : 0;
      const color =
        currentTheme.colors.chart.cycleTime[
          stageKey as keyof CycleTimeStageTypes
        ].backgroundColor;
      return {
        name: stageKey,
        borderColor: color,
        color,
        data: !!totalCycleTimeInHours
          ? [stageTimeInHours / totalCycleTimeInHours]
          : [null]
      };
    });

  return {
    chart: {
      height: 48,
      margin: 0,
      type: "bar"
    },
    xAxis: { visible: false },
    yAxis: { visible: false },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        },
        stacking: "percent",
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          }
        }
      }
    },
    legend: { enabled: false },
    tooltip: {
      enabled: false
    },
    title: {
      text: undefined
    },
    series
  };
};
export default config;
