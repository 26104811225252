import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { isNull } from "lodash";

// components
import Tooltip from "../Tooltip/Tooltip";
import WorkMetadataCycleTimeSummary from "../WorkMetadataCycleTimeSummary/WorkMetadataCycleTimeSummary";

// constants
import { cycleTimeStages } from "../../constants/constants";

// interfaces
import { CycleTimeInterface } from "../../interfaces/work-items";
import CycleTimeValueDisplay from "../CycleTimeValueDisplay/CycleTimeValueDisplay";
import { CycleTimeStageTypes } from "../../interfaces/constants";

// styled components
const CycleTimeSummary = styled(WorkMetadataCycleTimeSummary)`
  & ul {
    list-style-type: none;
    padding: 0;
  }
`;
const StageList = styled.ul`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

// typescript props
type Props = {
  className?: string;
  cycleTime: CycleTimeInterface | null;
  onMouseOver?: (e: React.MouseEvent<HTMLElement>) => void;
  testId?: string;
};

const ChartCycleTime = ({
  className,
  cycleTime,
  onMouseOver,
  testId = "testId"
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-chart-cycle-time`;
  return isNull(cycleTime) ? null : (
    <Tooltip
      onShowTooltip={onMouseOver}
      placement="top"
      popupContent={
        <CycleTimeSummary cycleTime={cycleTime} testId={thisTestId} />
      }
      trigger={
        <StageList className={className} data-testid={thisTestId}>
          {([
            cycleTimeStages.DEV,
            cycleTimeStages.WAITING_FOR_REVIEW,
            cycleTimeStages.REVIEW,
            cycleTimeStages.RELEASE
          ] as Array<keyof CycleTimeStageTypes>).map(stageKey => {
            const stage = cycleTime[stageKey];
            return !!stage ? (
              <li key={stageKey}>
                <CycleTimeValueDisplay
                  display="breakoutBlock"
                  stage={[stage]}
                  stageKey={stageKey}
                  testId={thisTestId}
                />
              </li>
            ) : null;
          })}
        </StageList>
      }
      testId={thisTestId}
    />
  );
};

export default ChartCycleTime;
