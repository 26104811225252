import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

// components
import Chart from "../Chart/Chart";

// config
import generateConfig from "./config";

// exporting for targeting in tests
export const ChartSpline = ({ chart, className, testId, theme }) => {
  if (isEmpty(chart) || !testId) {
    return null;
  }

  return (
    <Chart
      chart={{
        ...chart,
        config: generateConfig({
          config: chart.config,
          theme
        })
      }}
      className={className}
      testId={`${testId}-spline-chart`}
      theme={theme}
    />
  );
};

ChartSpline.propTypes = {
  chart: PropTypes.shape({
    config: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired
  }),
  testId: PropTypes.string.isRequired
};

export default ChartSpline;
