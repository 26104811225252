import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// interfaces
import TimeRange from "../TimeRange/TimeRange";
import { OperationalMetricsSparklinePoint } from "../../interfaces/charts";
import { getIsSprintMode } from "../../utils/sprints";

// styled components
const Container = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const Header = styled.header`
  align-items: baseline;
  display: flex;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const TimerangeDisplayName = styled.span`
  &:not(:empty) {
    margin-right: 1rem;
  }
`;
const ValueContainer = styled.div``;

// typescript props
type Props = {
  context?: {
    point: OperationalMetricsSparklinePoint;
  };
};

const OperationalMetricsSparklineTooltip = ({
  context
}: Props): ReactElement | null => {
  if (!context?.point) {
    return null;
  }
  const {
    value,
    unit,
    timerange,
    name
  } = context.point as OperationalMetricsSparklinePoint;
  const thisTestId = `${name}-operational-metrics-sparkline-tooltip`;
  return (
    <Container
      className={`${name}-operational-metrics-sparkline-tooltip-container`}
      data-testid={thisTestId}
    >
      <Header>
        <TimerangeDisplayName>
          {getIsSprintMode(timerange) ? `${timerange.displayName}` : ""}
        </TimerangeDisplayName>
        {!!timerange.start && !!timerange.end ? (
          <TimeRange
            config={{ format: { end: "MM/DD", start: "MM/DD" } }}
            timerange={timerange}
            testId={thisTestId}
          />
        ) : null}
      </Header>
      <ValueContainer>
        <span>
          {value}
          {unit === "PERCENT" ? "%" : ""}
        </span>{" "}
        &mdash; {name}
      </ValueContainer>
    </Container>
  );
};

export default OperationalMetricsSparklineTooltip;
