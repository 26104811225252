// utils
import { formatTimestamp } from "../../utils/date";
import {
  requestMeetingClassifications,
  requestMeetingClassifications_privateic
} from "../../utils/meeting-classifications";

export default async function fetchMeetingClassificationsData(params = {}) {
  const {
    accessToken,
    endDate,
    startDate,
    tenantId,
    user,
    override,
    team,
    canViewFullTeam
  } = params;

  if (!canViewFullTeam) {
    return await requestMeetingClassifications_privateic({
      accessToken,
      tenantId,
      params: {
        tenantId: parseInt(tenantId, 10),
        startDate: formatTimestamp({
          format: "YYYY-MM-DD",
          timestamp: startDate
        }),
        endDate: formatTimestamp({ format: "YYYY-MM-DD", timestamp: endDate }),
        userEmail: user.email,
        override
      }
    });
  } else {
    return await requestMeetingClassifications({
      accessToken,
      tenantId,
      params: {
        tenantId,
        startDate,
        endDate,
        users: team.map(t => t.email),
        override
      }
    });
  }
}
