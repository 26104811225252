import React from "react";
import {
  BucketTypes,
  DeepDiveDataRecord,
  JiraP1Bugs,
  PeopleMetrics
} from "../../interfaces/team-deep-dive";
import { UserReportWithMetadataInterface } from "../../interfaces/user";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import styled from "styled-components";
import { flatten } from "lodash";
import {
  contextSwitchingFactors,
  contextSwitchingFactorsMetadata,
  teamDeepDiveSections,
  groupByTypes,
  meetingInsightsMeasurePathParams,
  workDeepDiveSections,
  Signals,
  signalTypes,
  urlParamKeys,
  workMetadataActivityTypes
} from "../../constants/constants";
import { useUrlParams } from "../../hooks/useUrlParams";
import SignalTypeContainer from "../SignalTypeContainer/SignalTypeContainer";
import RouteLink from "../RouteLink/RouteLink";
import SlackAvatar from "../SlackAvatar/SlackAvatar";

const Container = styled.div`
  width: 100%;
`;
const SectionHeader = styled.header`
  font-family: ${props => props.theme.fonts.primary.name}, serif;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  border-bottom: ${props => `1px solid ${props.theme.colors.all.jean}`};
  margin: 1.9rem 0 1rem 0;
`;
const SignalType = styled(SignalTypeContainer)`
  margin-bottom: 0.5rem;
  width: 100%;
`;

const SignalInDropdown = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
`;

const SignalNotInDropdown = styled.div<{ healthy: boolean }>`
  margin-bottom: 0.5rem;
  width: 100%;
  padding: 1rem 0 1rem 1rem;
  background-color: ${props => `${props.theme.colors.all.white}`};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  box-shadow: ${props =>
    `inset 5px 0px ${
      props.healthy
        ? props.theme.colors.all.marvelMint
        : props.theme.colors.all.rogue
    }`};
`;

const DeepDiveLink = styled(RouteLink)`
  align-items: center;
  display: flex;
  &,
  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
    text-decoration: none;
  }
`;
const Avatar = styled(SlackAvatar)`
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.border}`};
`;

const TeamMemberName = styled.strong`
  margin-left: 1rem;
`;
const ContextSwitchingFactorCircle = styled.div<{ factor: string }>`
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
  border-radius: 50%;
  background-color: ${props =>
    props.theme.colors.chart.healthFactors[props.factor]};
`;
const ContextSwitchingTitle = styled.div`
  display: flex;
  align-items: center;
`;
const DataText = styled.b`
  margin-right: 1rem;
  font-family: ${props => props.theme.fonts.subheader.name};
  font-size: ${props => props.theme.fonts.subheader.sizes.sm};
  font-weight: ${props => props.theme.fonts.subheader.weights.bold};
`;
const DataSection = styled.div`
  background-color: ${props => props.theme.colors.all.white};
  height: 100%;
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

// using an internal type to make parsing simpler as the original type has the user id in a separate node
type ContextSwitchingDetailsType = {
  id: number;
  factor: string;
  score: {
    bucket: BucketTypes;
    details:
      | Record<string, number>
      | Record<string, string>
      | Record<string, JiraP1Bugs>
      | {
          repos: Array<string>;
        };
  } | null;
};
type Props = {
  testId?: string;
  className?: string;
  metricTitle: PeopleMetrics;
  mostRecentData?: DeepDiveDataRecord;
  chartView: UserReportWithMetadataInterface | "team";
};

export const MetricTileContextSwitchingDetails = ({
  metricTitle,
  mostRecentData,
  chartView,
  testId = "testId"
}: Props): JSX.Element | null => {
  const thisTestId = `${testId}-metric-callout-tile-context-switching-details`;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const {
    getWorkDeepDivePath,
    getTeamDeepDivePath,
    urlParams
  } = useUrlParams();
  const activeTeam = urlParams.team?.teamMembers.filter(
    m => chartView === "team" || m.id === chartView.id
  );
  if (
    metricTitle !== Signals.CONTEXT_SWITCHING ||
    !flags?.["team-deep-dive-context-switching-details"] ||
    !mostRecentData
  ) {
    return null;
  }
  const unhealthyContextSwitchingData: ContextSwitchingDetailsType[] = flatten(
    mostRecentData.peopleScores
      .filter(s => chartView === "team" || s.user === chartView.id)
      .map(s => [
        {
          id: s.user,
          factor: contextSwitchingFactors.CONTEXT_SWITCHING_JIRA_EPICS,
          score: s.CONTEXT_SWITCHING?.JIRA_EPICS
        },
        {
          id: s.user,
          factor: contextSwitchingFactors.CONTEXT_SWITCHING_TOP_PRIORITY_BUGS,
          score: s.CONTEXT_SWITCHING?.TOP_PRIORITY_BUGS
        },
        {
          id: s.user,
          factor: contextSwitchingFactors.CONTEXT_SWITCHING_MEETINGS,
          score: s.CONTEXT_SWITCHING?.["MEETINGS_WITH_2+_TEAMS"]
        },
        {
          id: s.user,
          factor: contextSwitchingFactors.CONTEXT_SWITCHING_CHAT_CHANNELS,
          score: s.CONTEXT_SWITCHING?.CHAT_CHANNELS_WITH_INTERRUPTIONS
        },
        {
          id: s.user,
          factor: contextSwitchingFactors.CONTEXT_SWITCHING_PR_REPOS,
          score: s.CONTEXT_SWITCHING?.PR_REPOS
        }
      ])
  ).filter(({ score }) => score?.bucket === "MORE");
  if (!unhealthyContextSwitchingData.length) return null;

  function parseJiraP1Bugs(
    details: Record<string, JiraP1Bugs>,
    user: UserReportWithMetadataInterface
  ) {
    return Object.entries(details)
      .sort((b1, b2) => b1[1].summary.localeCompare(b2[1].summary))
      .map(([, b]) => {
        const jiraP1BugsPath = getWorkDeepDivePath({
          groupId: b.parent,
          persistQuerystring: false,
          querystringParams: {
            [urlParamKeys.SELECTED_USER]: user.email,
            [urlParamKeys.WORK_ITEM_TYPES]: [workMetadataActivityTypes.BUG],
            [urlParamKeys.GROUP_BY]: groupByTypes.EPIC,
            [urlParamKeys.SPRINT_WORK_TYPES]: []
          }
        });
        return (
          <DeepDiveLink
            name={thisTestId}
            to={jiraP1BugsPath}
            key={jiraP1BugsPath}
            data-heap-redact-text="true"
          >
            {b.summary}
          </DeepDiveLink>
        );
      });
  }
  function parseJiraEpics(
    details: Record<string, string>,
    user: UserReportWithMetadataInterface
  ) {
    return Object.entries(details)
      .sort((e1, e2) => e1[1].localeCompare(e2[1]))
      .map(([id, name]) => {
        const jiraEpicsPath = getWorkDeepDivePath({
          groupId: id,
          persistQuerystring: false,
          querystringParams: {
            [urlParamKeys.SELECTED_USER]: user.email,
            [urlParamKeys.SPRINT_WORK_TYPES]: []
          }
        });
        return (
          <DeepDiveLink
            name={thisTestId}
            to={jiraEpicsPath}
            key={jiraEpicsPath}
            data-heap-redact-text="true"
          >
            {name}
          </DeepDiveLink>
        );
      });
  }
  function parseSlackChannelInterruptions(
    details: Record<string, number>,
    user: UserReportWithMetadataInterface
  ) {
    const chatInterruptionsPath = getTeamDeepDivePath({
      querystringParams: {
        [urlParamKeys.SELECTED_USER]: user.email
      },
      selectedMetric: Signals.CHAT_INTERRUPTIONS,
      selectedSection: teamDeepDiveSections.HEALTH_METRICS
    });
    return Object.entries(details).map(([, v]) => {
      return (
        <DeepDiveLink
          name={thisTestId}
          to={chatInterruptionsPath}
          key={chatInterruptionsPath}
        >
          <DataSection>
            <ContextSwitchingFactorCircle
              factor={contextSwitchingFactors.CONTEXT_SWITCHING_CHAT_CHANNELS}
              data-heap-redact-text="true"
            />
            <b>
              {
                contextSwitchingFactorsMetadata[
                  contextSwitchingFactors.CONTEXT_SWITCHING_CHAT_CHANNELS
                ].title
              }
            </b>
            <DataText>{v}</DataText>
          </DataSection>
        </DeepDiveLink>
      );
    });
  }
  function parseMeetings(details: Record<string, number>) {
    const meetingsPath = getTeamDeepDivePath({
      selectedMetric: meetingInsightsMeasurePathParams.MEETING_DISTRIBUTION,
      selectedSection: teamDeepDiveSections.MEETING_INSIGHTS
    });
    const topMeetingTypes = Object.entries(details)
      .filter(([, v]) => v > 0.1)
      .sort((d1, d2) => d2[1] - d1[1])
      .map(([k, v]) => {
        return {
          key:
            k === "UNSPECIFIED_WORKING_SESSION"
              ? "Unspecified Working Session"
              : k,
          val: v
        };
      });
    const remainingMeetingPct =
      1 -
      topMeetingTypes.map(({ key, val }) => val).reduce((m1, m2) => m1 + m2);
    const meetingTypesToDisplay =
      Object.entries(details).length === topMeetingTypes.length
        ? topMeetingTypes
        : [
            ...topMeetingTypes,
            { key: "Other Meeting Types", val: remainingMeetingPct }
          ];

    return meetingTypesToDisplay.map(d => {
      return (
        <DeepDiveLink name={thisTestId} to={meetingsPath} key={meetingsPath}>
          <DataSection data-heap-redact-text="true">
            <DataText>{(d.val * 100).toFixed(0)}%</DataText>
            {d.key}
          </DataSection>
        </DeepDiveLink>
      );
    });
  }
  function parsePRRepos(
    repos: Array<string>,
    user: UserReportWithMetadataInterface
  ) {
    return repos
      .sort((r1, r2) => r1.localeCompare(r2))
      .map(r => {
        const repo = r.includes("/") ? r.split("/")[1] : r;
        const reposPath = getWorkDeepDivePath({
          selectedSection: workDeepDiveSections.PR_WORKFLOW,
          persistQuerystring: true,
          querystringParams: {
            [urlParamKeys.SELECTED_USER]: user.email,
            [urlParamKeys.REPOSITORIES]: [repo],
            [urlParamKeys.SPRINT_WORK_TYPES]: []
          }
        });
        return (
          <DeepDiveLink
            name={thisTestId}
            to={reposPath}
            key={reposPath}
            data-heap-redact-text="true"
          >
            {r}
          </DeepDiveLink>
        );
      });
  }

  // if we are viewing for the team, the contents will be the people with more context switching in
  // each category

  function getTeamDetails(
    contextSwitchingFactor: string,
    unhealthyData: ContextSwitchingDetailsType[]
  ) {
    const unhealthyUsers = unhealthyData
      .filter(({ factor }) => factor === contextSwitchingFactor)
      .map(({ id }) => activeTeam?.find(m => m.id === id));

    if (!unhealthyUsers.length) return null;
    return (
      <SignalType
        key={`${contextSwitchingFactor}-team`}
        content={unhealthyUsers.map(teamMember => {
          const contextSwitchingPath = getTeamDeepDivePath({
            querystringParams: {
              [urlParamKeys.SELECTED_USER]: teamMember?.email
            },
            selectedMetric: Signals.CONTEXT_SWITCHING,
            selectedSection: teamDeepDiveSections.HEALTH_METRICS
          });
          return !!teamMember ? (
            <SignalInDropdown key={teamMember.id}>
              <DeepDiveLink name={thisTestId} to={contextSwitchingPath}>
                <Avatar
                  altText={`${teamMember.name} image`}
                  src={teamMember.slackAvatar}
                  testId={`${teamMember.name}-healthy-metric-avatar`}
                />
                <TeamMemberName data-heap-redact-text="true">
                  {teamMember.name}
                </TeamMemberName>
                {": More"}
              </DeepDiveLink>
            </SignalInDropdown>
          ) : null;
        })}
        header={
          <ContextSwitchingTitle>
            <ContextSwitchingFactorCircle factor={contextSwitchingFactor} />
            <b>{`${contextSwitchingFactorsMetadata[contextSwitchingFactor].title}`}</b>
          </ContextSwitchingTitle>
        }
        isExpandable={true}
        type={signalTypes.UNHEALTHY}
      />
    );
  }

  function getPersonDetails(
    contextSwitchingFactor: string,
    unhealthyData: ContextSwitchingDetailsType[],
    user: UserReportWithMetadataInterface
  ) {
    // if we are viewing for an individual, the contents will be the details of the category
    const detailData:
      | Record<string, number>
      | Record<string, string>
      | Record<string, JiraP1Bugs>
      | { repos: Array<string> }
      | undefined = unhealthyData?.find(
      ({ factor }) => factor === contextSwitchingFactor
    )?.score?.details;

    if (!detailData) return null;

    const detailListData =
      contextSwitchingFactor ===
      contextSwitchingFactors.CONTEXT_SWITCHING_TOP_PRIORITY_BUGS
        ? parseJiraP1Bugs(detailData as Record<string, JiraP1Bugs>, user)
        : contextSwitchingFactor ===
          contextSwitchingFactors.CONTEXT_SWITCHING_JIRA_EPICS
        ? parseJiraEpics(detailData as Record<string, string>, user)
        : contextSwitchingFactor ===
          contextSwitchingFactors.CONTEXT_SWITCHING_CHAT_CHANNELS
        ? parseSlackChannelInterruptions(
            detailData as Record<string, number>,
            user
          )
        : contextSwitchingFactor ===
          contextSwitchingFactors.CONTEXT_SWITCHING_MEETINGS
        ? parseMeetings(detailData as Record<string, number>)
        : contextSwitchingFactor ===
          contextSwitchingFactors.CONTEXT_SWITCHING_PR_REPOS
        ? parsePRRepos(detailData.repos as Array<string>, user)
        : [];

    return contextSwitchingFactor ===
      contextSwitchingFactors.CONTEXT_SWITCHING_CHAT_CHANNELS ? (
      <SignalNotInDropdown
        healthy={false}
        key={`${contextSwitchingFactor}-${user.id}`}
      >
        {detailListData[0]}
      </SignalNotInDropdown>
    ) : (
      <SignalType
        content={detailListData.map((d, index) => {
          return !!d ? (
            <SignalInDropdown
              key={`${contextSwitchingFactor}-${user.id}-${index}`}
            >
              {d}
            </SignalInDropdown>
          ) : null;
        })}
        header={
          <ContextSwitchingTitle>
            <ContextSwitchingFactorCircle factor={contextSwitchingFactor} />
            <b>{`${contextSwitchingFactorsMetadata[contextSwitchingFactor].title}`}</b>
          </ContextSwitchingTitle>
        }
        isExpandable={true}
        type={signalTypes.UNHEALTHY}
      />
    );
  }

  // note: if you don't have the key on the container then an odd bug occurs where data for a person stays in
  // the list when toggling a different person or the team
  return (
    <Container
      key={`context-switching-details-${
        chartView === "team" ? chartView : chartView.id
      }`}
    >
      <SectionHeader>Context Switching Factors</SectionHeader>
      {Object.entries(contextSwitchingFactors).map(([, v]) =>
        chartView === "team"
          ? getTeamDetails(v, unhealthyContextSwitchingData)
          : getPersonDetails(v, unhealthyContextSwitchingData, chartView)
      )}
    </Container>
  );
};

export default MetricTileContextSwitchingDetails;
