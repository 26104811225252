import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import SlackAvatar from "../SlackAvatar/SlackAvatar";
import { take } from "lodash";

import { UserReportWithMetadataInterface } from "../../interfaces/user";

const StyledSlackAvatar = styled(SlackAvatar)`
  border: ${props => `.2rem solid ${props.theme.colors.border}`};
  border-radius: 50% 50%;
  height: 3rem;
  width: 3rem;
  margin-right: 0.5rem;
`;

const UserRow = styled.dd`
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  line-height: 1em;
  margin: 0;
  white-space: break-spaces;
  margin-bottom: 0.5rem;
`;

type Props = {
  className?: string;
  testId?: string;
  team: UserReportWithMetadataInterface[];
  userIds: number[];
  rowCount?: number;
};

const TeamList = ({
  className,
  testId,
  team,
  userIds,
  rowCount
}: Props): ReactElement => {
  return (
    <ul
      className={!!className ? `${className}-team-list` : "team-list"}
      data-testid={testId}
    >
      {take(userIds, rowCount || team.length).map(o => {
        const user = team.find(u => u.id === o);

        return (
          !!user && (
            <li key={o}>
              <UserRow>
                <StyledSlackAvatar
                  src={user?.image || null}
                  className="team-list"
                  altText={user?.name}
                />
                {user.name}
              </UserRow>
            </li>
          )
        );
      })}
    </ul>
  );
};

export default TeamList;
