import React, { ReactElement } from "react";
import { OrgByTimeBlock } from "../../interfaces/organization";
import CycleTimeValueDisplay from "../CycleTimeValueDisplay/CycleTimeValueDisplay";
import {
  filterTruthyCycleTimeValues,
  splitCycleTimeHours
} from "../../utils/cycle-time";
import { round } from "lodash";
import styled from "styled-components/macro";
// styled components

const Previous = styled.div`
  align-items: baseline;
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  margin: 0 0 3rem 1rem;
`;
const Previous14Days = styled.div`
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  margin: 0 0 3rem 1rem;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  measure: "deep-work" | "cycle-time" | "release-frequency";
  previous?: OrgByTimeBlock;
  isDaysScale?: boolean;
};
const OverviewSummaryPreviousValue = ({
  className,
  testId = "testId",
  measure,
  previous,
  isDaysScale
}: Props): ReactElement => {
  const thisTestId = `${testId}-overview-summary-previous-value`;
  if (!previous?.value)
    return <Previous14Days>Previous 14 Days: No Data</Previous14Days>;

  if (measure === "cycle-time") {
    return (
      <Previous>
        <header>Previous 14 Days:</header>&nbsp;
        <CycleTimeValueDisplay
          display="rollup"
          stage={filterTruthyCycleTimeValues(
            splitCycleTimeHours(previous.value, isDaysScale)
          ).map(t => ({ ...t, value: round(t.value, 1) }))}
          testId={thisTestId}
        />
      </Previous>
    );
  }
  return (
    <Previous14Days>
      Previous 14 Days:{" "}
      {previous.value.toFixed(measure === "deep-work" ? 1 : 0)}{" "}
      {measure === "deep-work" ? "Hours" : "Releases"}
    </Previous14Days>
  );
};

export default OverviewSummaryPreviousValue;
