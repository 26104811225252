import { isNil } from "lodash";
import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// components
import RouteLink from "../RouteLink/RouteLink";
import StoryPoints from "../StoryPoints/StoryPoints";
import WorkMetadataIcon from "../WorkMetadataIcon/WorkMetadataIcon";

// constants
import {
  urlParamKeys,
  workDeepDiveSections,
  workMetadataActivityTypes
} from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { SprintProgressActivityInterface } from "../../interfaces/sprint-metadata";

// styled components
const Container = styled.ul`
  display: inline-grid;
  flex: 1;
  grid-template-columns: repeat(2, min-content);
  grid-template-rows: 2rem;
  grid-auto-rows: 2rem;
  column-gap: calc(${props => props.theme.grid.gap} / 2);
  row-gap: 0.5rem;
`;
const RollupValue = styled.li`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  font-size: ${props => props.theme.fonts.subheader.sizes.xs};
`;
const WorkIcon = styled(WorkMetadataIcon)`
  margin-right: 0.8rem;
`;
const WorkDeepDiveLink = styled(RouteLink)`
  align-items: center;
  display: flex;

  &,
  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
    text-decoration: none;
  }
`;
const StyledStoryPoints = styled(StoryPoints)`
  margin-left: 1rem;
`;
// typescript props
type ValueOf<T> = T[keyof T];
type Props = {
  activityTypes: Array<ValueOf<typeof workMetadataActivityTypes>>;
  category: SprintProgressActivityInterface;
  className?: string;
  testId?: string;
};

const WorkProgressBreakout = ({
  activityTypes,
  className,
  category,
  testId = "testId"
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-work-progress-breakout`;
  const { getWorkDeepDivePath } = useUrlParams();
  const activitiesWithValues = activityTypes.filter(
    a => !isNil(category.breakdownByActivityType[a])
  );

  return !!activitiesWithValues.length ? (
    <Container className={className} data-testid={thisTestId}>
      {activitiesWithValues.map((activityTypeKey: string) => {
        const {
          count: numItems,
          storyPoints
        } = category.breakdownByActivityType[activityTypeKey];
        const path = getWorkDeepDivePath({
          querystringParams: {
            [urlParamKeys.STATUS]: [category.status],
            [urlParamKeys.SHOW_RELATED]: false,
            [urlParamKeys.WORK_ITEM_TYPES]: [activityTypeKey]
          },
          selectedSection: workDeepDiveSections.PROJECT_WORK
        });
        return (
          <RollupValue key={`${category.status}-${activityTypeKey}`}>
            <WorkDeepDiveLink name={thisTestId} to={path}>
              <WorkIcon type={activityTypeKey} />
              {numItems || 0}
              <StyledStoryPoints storyPoints={storyPoints} />
            </WorkDeepDiveLink>
          </RollupValue>
        );
      })}
    </Container>
  ) : null;
};

export default WorkProgressBreakout;
