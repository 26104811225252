import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { get, isEmpty, isUndefined } from "lodash";
import moment from "moment";

// components
import CrossTeamAvatar from "../CrossTeamAvatar/CrossTeamAvatar";
import SlackAvatar from "../SlackAvatar/SlackAvatar";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { PrWorkflowChartActivityPoint } from "../../interfaces/charts";
import { prActivityStatusLabels } from "../../constants/constants";

// styled components
const Container = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;
const Header = styled.header`
  margin-bottom: 1em;
`;
const Slack = styled(SlackAvatar)`
  border: ${props => `.2rem solid ${props.theme.colors.border}`};
  border-radius: 50% 50%;
  height: 3rem;
  width: 3rem;
`;
const CrossTeam = styled(CrossTeamAvatar)`
  font-size: 1em;
`;
const Time = styled.time`
  margin-left: 1em;
`;

// typescript props
type Props = {
  className?: string;
  point: PrWorkflowChartActivityPoint;
  testId?: string;
};

const PrWorkflowChartActivityTooltip = ({
  className,
  point,
  testId = "testId"
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-pr-workflow-chart-activity-tooltip`;
  const { urlParams } = useUrlParams();
  const history = point.history;

  if (isUndefined(history)) {
    return null;
  }

  const relatedUser = (urlParams.team?.teamMembers || []).find(
    a => a.id === history.userId
  );
  const time = history.activityAt;
  const activity = history.activity;

  return (
    <Container className={className} data-testid={thisTestId}>
      {!!activity ? <Header>{prActivityStatusLabels[activity]}</Header> : null}
      <div>
        {!!relatedUser ? (
          <Slack
            altText={relatedUser.name}
            src={relatedUser.slackAvatar}
            testId={`${thisTestId}-${relatedUser.id}`}
          />
        ) : (
          <CrossTeam testId={thisTestId} />
        )}
        {!!time ? (
          <Time dateTime={time}>{moment(time).format("MM/DD HH:mm")}</Time>
        ) : null}
      </div>
    </Container>
  );
};

export default PrWorkflowChartActivityTooltip;
