import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { isNil } from "lodash";

// components
import EmptyState from "../EmptyState/EmptyState";
import LoadingStates from "../LoadingStates/LoadingStates";

// constants

// interfaces
import {
  Benchmark,
  OrgByTimeItem,
  OrgByTimeItems
} from "../../interfaces/organization";
import ChartOverviewTrendline from "../ChartOverviewTrendline/ChartOverviewTrendline";
import OverviewSummaryCurrentValue from "../OverviewSummaryCurrentValue/OverviewSummaryCurrentValue";
import OverviewSummaryPreviousValue from "../OverviewSummaryPreviousValue/OverviewSummaryPreviousValue";

// styled components
const Container = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.isError ? "flex-start" : "space-between")};
`;
const EmptyStateWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 60rem;
`;
const Header = styled.header`
  display: flex;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  align-items: center;
`;
const HeaderText = styled.h4`
  font-family: ${props => props.theme.fonts.header.name}, sans-serif;
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
`;

// typescript props
type Props = {
  className?: string;
  all: OrgByTimeItems | null;
  benchmark?: Benchmark;
  current?: OrgByTimeItem;
  isEmptyData: boolean;
  isError: boolean;
  isFetching: boolean;
  previous?: OrgByTimeItem;
  testId?: string;
  tooltip: React.ReactElement;
  isDaysScale?: boolean;
  measure: "deep-work" | "cycle-time" | "release-frequency";
};

const OverviewSummary = ({
  className,
  all,
  benchmark,
  current,
  isEmptyData,
  isError,
  isFetching,
  previous,
  testId = "testId",
  tooltip,
  isDaysScale,
  measure
}: Props): ReactElement => {
  const thisTestId = `${testId}-overview-summary`;
  return (
    <Container className={className} data-testid={thisTestId} isError={isError}>
      <Header>
        <HeaderText>1 yr Trend</HeaderText>
      </Header>
      <LoadingStates
        isSpinningState={isFetching}
        isNoDataState={isError || isEmptyData || isNil(all)}
        noDataContent={
          <EmptyStateWrapper>
            <EmptyState
              body={
                isError ? (
                  "We ran into an error while trying to fetch your data."
                ) : (
                  <>
                    There doesn&rsquo;t seem to be any data for this date range
                    with the filters that are applied.
                  </>
                )
              }
              header={isError ? "Uh Oh!" : "Hmmm."}
              testId={testId}
            />
          </EmptyStateWrapper>
        }
        content={
          isError || isEmptyData || isNil(all) ? null : (
            <>
              <OverviewSummaryCurrentValue
                measure={measure}
                current={current}
                isDaysScale={isDaysScale}
              />
              <OverviewSummaryPreviousValue
                measure={measure}
                previous={previous}
                isDaysScale={isDaysScale}
              />
              <ChartOverviewTrendline
                current={current}
                data={all}
                benchmark={benchmark}
                testId={thisTestId}
                title="1 yr Trend"
                tooltip={tooltip}
                isDaysScale={isDaysScale}
                measure={measure}
              />
            </>
          )
        }
        testId={thisTestId}
      />
    </Container>
  );
};

export default OverviewSummary;
