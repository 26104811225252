import { useMemo } from "react";
import { findIndex, take } from "lodash";

// constants
import { SPRINT_HEALTH_CHART_NUM_SPRINTS } from "../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useUrlParams } from "./useUrlParams";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";
import { SprintInterface } from "../interfaces/sprint-metadata";

// utils
import { requestSprintHealth } from "../utils/sprints";

export const useSprintHealthData = (canRequest = true) => {
  const { urlParams } = useUrlParams();
  const sprint = urlParams.timerange;
  const board = urlParams.board;
  const teamId = urlParams.teamId;

  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );

  const params = useMemo(() => {
    if (!!board && !!sprint) {
      const sprints = board.sprints;
      const currentSprintIndex = findIndex(
        sprints,
        (t: SprintInterface) => t.sprintId === sprint.id
      );
      const sprintIds =
        currentSprintIndex < 0
          ? []
          : take(
              sprints.slice(currentSprintIndex),
              SPRINT_HEALTH_CHART_NUM_SPRINTS
            ).map(s => s.sprintId);

      return {
        sprintIds,
        teamId
      };
    }
    return {
      sprintIds: [],
      teamId: ""
    };
  }, [board, sprint, teamId]);

  return useQuery(
    ["sprint-health", params],
    () =>
      requestSprintHealth({
        accessToken,
        tenantId,
        params
      }),
    {
      enabled:
        !!accessToken &&
        !!tenantId &&
        !!params.sprintIds.length &&
        canRequest &&
        !!teamId
    }
  );
};
