import React from "react";
import styled from "styled-components/macro";
import { defaults } from "lodash";

// components
import InfoIcon from "../InfoIcon/InfoIcon";
import UiFilterSelectableInputsList from "../UiFilterSelectableInputsList/UiFilterSelectableInputsList";

// constants
import { groupByTypes } from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useTracking } from "../../hooks/useTracking";
import { useQueryClient, useMutation } from "react-query";
import { useUserSettings } from "../../hooks/useUserSettings";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";

// utils
import { updateUserSettings } from "../../utils/settings";

// styled components

const Container = styled.div`
  background: ${props => props.theme.colors.all.white};
  padding: 1rem;
  margin-top: 1rem;
  min-width: 30rem;
`;
const FormHeader = styled.header`
  font-family: ${props => props.theme.fonts.primary.name}, monospace;
  margin-bottom: 1rem;
  display: flex;
`;

type Props = {
  className?: string;
  testId?: string;
};

const SettingsDefaultGroupBySelector = ({
  className,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-settings-default-group-by-selector`;
  const { trackEvent } = useTracking();

  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );

  // react-query hooks
  const queryClient = useQueryClient();
  const { data: userSettings } = useUserSettings();
  const userSettingsMutation = useMutation(updateUserSettings, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries("user-settings");
    }
  });

  const optionsList = ([
    {
      name: `Epic`,
      id: groupByTypes.EPIC
    },
    {
      name: `Project`,
      id: groupByTypes.PROJECT
    }
  ] as Array<{
    name: string;
    id: string;
  }>).map(m => {
    return {
      id: m.id,
      isSelected: userSettings.defaultJiraGroupBy === m.id,
      name: m.name
    };
  });

  const onChange = (updated: Array<UiFilterSelectionStatusInterface>) => {
    const updatedType = updated.find(
      (u: UiFilterSelectionStatusInterface) =>
        u.isSelected && u.id !== userSettings.defaultJiraGroupBy
    );
    if (updatedType) {
      // send direct api request, mutation will invalidate and refetch settings
      // and useeffect will set groupby from settings
      userSettingsMutation.mutate({
        accessToken,
        tenantId,
        params: defaults({ defaultJiraGroupBy: updatedType.id }, userSettings)
      });
      trackEvent({
        label: "settings-default-group-by-change",
        value: `settings-default-group-by changed to ${updatedType.id}`
      });
    }
  };

  return (
    <Container className={className} data-testid={thisTestId}>
      <FormHeader>
        Aggregate Jira content by:{" "}
        <InfoIcon content="settings-default-group-by" />
      </FormHeader>
      <UiFilterSelectableInputsList
        list={optionsList}
        name="settings-default-group-by"
        type="radio"
        showSelectAll={false}
        onUpdateSelection={onChange}
      />
    </Container>
  );
};

export default SettingsDefaultGroupBySelector;
