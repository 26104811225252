import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

// styled components
const StyledPipedList = styled.ul`
  align-items: center;
  display: flex;
  list-style-type: none;
`;
const StyledListItem = styled.li`
  flex: 0 0 auto;

  & + & {
    border-left-width: 0.2rem;
    border-left-style: solid;
    margin-left: 1em;
    padding-left: 1em;
  }
`;

const PipedList = ({ list, testId, ...otherProps }) => {
  if (!list || !testId) {
    return null;
  }

  return (
    <StyledPipedList data-testid={`${testId}-piped-list`} {...otherProps}>
      {list.map((listItem, index) => (
        <StyledListItem key={`${testId}-piped-list-item-${index}`}>
          {listItem}
        </StyledListItem>
      ))}
    </StyledPipedList>
  );
};

PipedList.propTypes = {
  list: PropTypes.array.isRequired,
  testId: PropTypes.string.isRequired
};
export default PipedList;
