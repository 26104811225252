import { snakeCase } from "lodash";
import * as ConstantsInterfaces from "../interfaces/constants";
import { FeatureTypes } from "../interfaces/features";
import {
  KanbanProjectHealthMetricsInterface,
  MetricsInterface,
  PeopleHealthMetricsInterface,
  SprintProjectHealthMetricsInterface
} from "../interfaces/metrics";
import { Roles } from "../interfaces/organization";

export const breakpointTypes: ConstantsInterfaces.BreakpointTypesInterface = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl"
};

export const responseTotalTypes: ConstantsInterfaces.ResponseTotalTypesInterface = {
  CALLOUT_TOTALS: "calloutTotals",
  OTHER_SPRINT_ITEMS_TOTALS: "otherSprintItemsTotals",
  PROJECT_TOTALS: "projectTotals",
  STATUS_TOTALS: "statusTotals",
  TYPES_OF_WORK_TOTALS: "typesOfWorkTotals",
  USER_TOTALS: "userTotals",
  WORK_ITEM_TYPE_TOTALS: "workItemTypeTotals"
};

export const directionTypes: ConstantsInterfaces.DirectionTypesInterface = {
  NEXT: "next",
  PREV: "prev"
};

export const momentUnitTypes: ConstantsInterfaces.MomentUnitTypesInterface = {
  HOURS: "hours",
  DAYS: "days",
  MONTHS: "months",
  WEEKS: "weeks",
  YEARS: "years"
};

export const STRING_TRUNCATION_LENGTH = 30;

export const sprintMetadataWorkTypes: ConstantsInterfaces.SprintMetadataWorkTypesInterface = {
  SPRINT_WORK: "SPRINT_WORK",
  NOT_ATTACHED_TO_SPRINT: "NOT_ATTACHED_TO_SPRINT",
  PULL_REQUESTS_NOT_IN_SPRINT: "PULL_REQUESTS_NOT_IN_SPRINT",
  REMOVED_FROM_SPRINT: "REMOVED_FROM_SPRINT",
  COMPLETED_OUTSIDE_SPRINT: "COMPLETED_OUTSIDE_SPRINT"
};

export const sprintOptions: ConstantsInterfaces.SprintOptionsInterface = {
  UNASSIGNED: { id: "UNASSIGNED", label: "Unassigned" },
  OTHER_TEAMS: { id: "OTHER_TEAMS", label: "Other teams" }
};

export const textStringFormatTypes: ConstantsInterfaces.FormatTypes = {
  HTML: "html",
  MARKDOWN: "markdown"
};

// generic types of timerange metadata objects
export const timerangeTypes: ConstantsInterfaces.TimerangeTypesInterface = {
  CUSTOM: "custom",
  DAYS: "days",
  WEEKS: "weeks",
  SPRINTS: "sprints"
};

// named timeranges that correspond to specific date ranges relative to now
export const namedTimerangeIds: ConstantsInterfaces.TimerangeIdsInterface = {
  LAST_WEEK: "last-week",
  PREVIOUS_14_DAYS: "previous-14-days",
  PREVIOUS_30_DAYS: "previous-30-days",
  PREVIOUS_90_DAYS: "previous-90-days",
  THIS_WEEK: "this-week",
  YESTERDAY: "yesterday",
  TODAY: "today"
};

// all possible timerange ids, including generic timeranges constructed once the
// user starts stepping back and forth
export const timerangeIds: ConstantsInterfaces.TimerangeIdsInterface = {
  ...namedTimerangeIds,
  CUSTOM: "custom",
  DAYS: "days",
  WEEKS: "weeks",
  SPRINTS: "sprints"
};

// metadata for generic timerange types
export const timerangeTypesMetadata: ConstantsInterfaces.TimerangeTypesMetadataInterface = {
  [timerangeTypes.CUSTOM]: {
    timerangeType: timerangeTypes.CUSTOM,
    id: timerangeIds.CUSTOM,
    displayName: "Custom"
  },
  [timerangeTypes.DAYS]: {
    timerangeType: timerangeTypes.DAYS,
    id: timerangeIds.DAYS,
    displayName: "Days"
  },
  [timerangeTypes.WEEKS]: {
    timerangeType: timerangeTypes.WEEKS,
    id: timerangeIds.WEEKS,
    displayName: "Weeks"
  },
  [timerangeTypes.SPRINTS]: {
    timerangeType: timerangeTypes.SPRINTS,
    id: timerangeIds.SPRINTS,
    displayName: "Sprints"
  }
};

export const reservedEpics: ConstantsInterfaces.ReservedEpicsInterface = {
  NO_EPIC: "work-not-tied-to-epic",
  UNLINKED_PRS: "unlinked-prs"
};

export const reservedEpicsMetadata: ConstantsInterfaces.ReservedEpicsMetadataInterface = {
  [reservedEpics.NO_EPIC]: {
    magicRowId: "ISSUES_NOT_LINKED_TO_EPICS",
    title: "Work not tied to epic"
  },
  [reservedEpics.UNLINKED_PRS]: {
    magicRowId: "PULL_REQUESTS_NOT_LINKED_TO_JIRA",
    title: "PRs not linked to Jira"
  }
};

export const reservedProjects: ConstantsInterfaces.ReservedProjectsInterface = {
  NO_PROJECT: "00000000-0000-0000-0000-000000000000"
};

export const workMetadataStatusTypes: ConstantsInterfaces.WorkMetadataStatusTypesInterface = {
  CLOSED_NOT_MERGED: "CLOSED_NOT_MERGED",
  DONE: "DONE",
  OPEN: "OPEN",
  DRAFT: "DRAFT",
  IN_PROGRESS: "IN_PROGRESS",
  TODO: "TODO"
};

export const prActivityStatusTypes: ConstantsInterfaces.PrActivityStatusTypesInterface = {
  APPROVED: "APPROVED",
  CHANGES_REQUESTED: "CHANGES_REQUESTED",
  COMMENTED: "COMMENTED",
  COMMITS_ADDED: "COMMITS_ADDED",
  IN_PROGRESS: "IN_PROGRESS",
  MERGED: "MERGED",
  WITHDRAWN: "CLOSED_NOT_MERGED"
};

export const prActivityStatusLabels: { [key: string]: string } = {
  [prActivityStatusTypes.APPROVED]: "Approved",
  [prActivityStatusTypes.CHANGES_REQUESTED]: "Changed Requested",
  [prActivityStatusTypes.COMMENTED]: "Comment",
  [prActivityStatusTypes.COMMITS_ADDED]: "Code Update",
  [prActivityStatusTypes.WITHDRAWN]: "Withdrawn",
  IN_PROGRESS: "In-progress",
  MERGED: "Merged",
  OPENED: "PR Opened",
  WAITING_FOR_REVIEW: "Waiting for Review",
  DEV: "Dev",
  REVIEW: "Review",
  RELEASE: "Release"
};

export const workMetadataActivityResponseTypes: ConstantsInterfaces.WorkMetadataActivityResponseTypesInterface = {
  BUG: "Bug",
  ISSUE: "Issue",
  PR: "Pull Request",
  SUB_TASK: "Sub-task"
};

export const workMetadataActivityTypes: ConstantsInterfaces.WorkMetadataActivityTypesInterface = {
  BUG: "BUG",
  ISSUE: "ISSUE",
  PR: "PULL_REQUEST",
  SUB_TASK: "SUB_TASK"
};

export const pageIds: ConstantsInterfaces.PageIdsInterface = {
  START: "start",
  REPORTS: "reports",
  WORK_DEEP_DIVE: "work-deep-dive",
  EPIC_DETAILS: "epic-details",
  SETTINGS: "settings",
  RETROS: "sprint-retros",
  LABS: "labs",
  TEAM_DEEP_DIVE: "team-deep-dive",
  OVERVIEW: "overview",
  TEAM_HOME: "team-home"
};

export const teamDeepDiveSections: ConstantsInterfaces.PageSectionsInterface = {
  PEOPLE_HEALTH: "people-health",
  HEALTH_METRICS: "health-metrics",
  MEETING_INSIGHTS: "meeting-insights"
};

export const teamDeepDiveSectionLabels = {
  [teamDeepDiveSections.HEALTH_METRICS]: "Health Metrics",
  [teamDeepDiveSections.PEOPLE_HEALTH]: "People Health",
  [teamDeepDiveSections.MEETING_INSIGHTS]: "Meeting Insights"
};

export const teamDeepDiveMeasurePathParams: ConstantsInterfaces.PageSectionsInterface = {
  OVERALL_TEAM_HEALTH: "overall-team-health",
  DEEP_WORK: "deep-work",
  ALWAYS_ON: "always-on",
  CHAT_INTERRUPTIONS: "chat-interruptions",
  CONTEXT_SWITCHING: "context-switching"
};

export const meetingInsightsMeasurePathParams: ConstantsInterfaces.PageSectionsInterface = {
  MEETING_DISTRIBUTION: "meeting-distribution",
  MEETING_LENGTH: "meeting-length"
};

export const meetingInsightsMeasureLabels = {
  [meetingInsightsMeasurePathParams.MEETING_DISTRIBUTION]:
    "Meeting Distribution",
  [meetingInsightsMeasurePathParams.MEETING_LENGTH]: "Meeting Length"
};

export const overviewSections: ConstantsInterfaces.PageSectionsInterface = {
  DEEP_WORK: "deep-work"
};

export const overviewSectionLabels = {
  [overviewSections.DEEP_WORK]: "Deep Work"
};

export const settingsSections: ConstantsInterfaces.PageSectionsInterface = {
  PROJECTS_AND_REPOS: "projects-and-repos",
  TEAM_BUILDER: "team-builder",
  TEAM_SETTINGS: "team-settings"
};

export const settingsSectionLabels = {
  [settingsSections.PROJECTS_AND_REPOS]: "Projects & Repos",
  [settingsSections.TEAM_BUILDER]: "Team Builder",
  [settingsSections.TEAM_SETTINGS]: "Team Settings"
};

export const teamSettingsSections: ConstantsInterfaces.PageSectionsInterface = {
  CALENDAR: "calendar",
  CHAT: "chat",
  SOURCE_WORK: "source-work",
  JIRA_WORK: "jira-work"
};

export const teamSettingsSectionsLabels = {
  [teamSettingsSections.CALENDAR]: "Calendar",
  [teamSettingsSections.CHAT]: "Chat",
  [teamSettingsSections.JIRA_WORK]: "Jira Work",
  [teamSettingsSections.SOURCE_WORK]: "Source Work"
};

export const workDeepDiveSections: ConstantsInterfaces.PageSectionsInterface = {
  OPS_METRICS: "operational-metrics",
  PROJECT_WORK: "project-work",
  PR_WORKFLOW: "pr-workflow",
  PR_FREQUENCY: "pr-frequency",
  STORY_POINTS: "story-points"
};

export const workDeepDiveSectionLabels = {
  [workDeepDiveSections.OPS_METRICS]: "Operational Metrics",
  [workDeepDiveSections.PROJECT_WORK]: "Project Work",
  [workDeepDiveSections.PR_WORKFLOW]: "PR Workflow",
  [workDeepDiveSections.PR_FREQUENCY]: "PR Frequency",
  [workDeepDiveSections.STORY_POINTS]: "Story Points"
};

export const pageMetadata: ConstantsInterfaces.AppPagesInterface = {
  [pageIds.START]: { title: "Dashboard" },
  [pageIds.WORK_DEEP_DIVE]: {
    path: pageIds.WORK_DEEP_DIVE,
    title: "Work Deep Dive",
    children: [
      workDeepDiveSections.OPS_METRICS,
      workDeepDiveSections.PROJECT_WORK,
      workDeepDiveSections.PR_WORKFLOW
    ]
  },
  [workDeepDiveSections.OPS_METRICS]: {
    path: workDeepDiveSections.OPS_METRICS,
    title: workDeepDiveSectionLabels[workDeepDiveSections.OPS_METRICS],
    flag: "project-explorer-ops-metrics"
  },
  [workDeepDiveSections.PROJECT_WORK]: {
    path: workDeepDiveSections.PROJECT_WORK,
    title: workDeepDiveSectionLabels[workDeepDiveSections.PROJECT_WORK]
  },
  [workDeepDiveSections.PR_WORKFLOW]: {
    path: workDeepDiveSections.PR_WORKFLOW,
    title: workDeepDiveSectionLabels[workDeepDiveSections.PR_WORKFLOW]
  },
  [pageIds.EPIC_DETAILS]: { title: "Epic" },
  [pageIds.REPORTS]: {
    path: "reports",
    title: "1:1 Reports"
  },
  [pageIds.SETTINGS]: {
    path: "settings",
    title: "Settings"
  },
  [pageIds.RETROS]: {
    path: "sprint-retros",
    title: "Sprint Retros"
  },
  [pageIds.LABS]: {
    path: "labs",
    title: "Labs"
  },
  [pageIds.OVERVIEW]: {
    path: "overview",
    title: "Organization Overview",
    flag: "uplevel-web"
  },
  [pageIds.TEAM_HOME]: {
    path: "team-home",
    title: "Team Home"
  },
  [pageIds.TEAM_DEEP_DIVE]: {
    path: pageIds.TEAM_DEEP_DIVE,
    title: "Team Deep Dive",
    children: [
      teamDeepDiveSections.PEOPLE_HEALTH,
      teamDeepDiveSections.HEALTH_METRICS,
      teamDeepDiveSections.MEETING_INSIGHTS
    ]
  },
  [teamDeepDiveSections.PEOPLE_HEALTH]: {
    path: teamDeepDiveSections.PEOPLE_HEALTH,
    title: teamDeepDiveSectionLabels[teamDeepDiveSections.PEOPLE_HEALTH]
  },
  [teamDeepDiveSections.HEALTH_METRICS]: {
    path: teamDeepDiveSections.HEALTH_METRICS,
    title: teamDeepDiveSectionLabels[teamDeepDiveSections.HEALTH_METRICS]
  },
  [teamDeepDiveSections.MEETING_INSIGHTS]: {
    path: teamDeepDiveSections.MEETING_INSIGHTS,
    title: teamDeepDiveSectionLabels[teamDeepDiveSections.MEETING_INSIGHTS]
  }
};

export const workItemCalloutTypes: ConstantsInterfaces.WorkItemCalloutTypesInterface = {
  ADDED_MID_SPRINT: "ADDED_MID_SPRINT",
  OPEN_MULTIPLE_SPRINTS: "OPEN_MULTIPLE_SPRINTS",
  NULL_STORY_POINTS: "NULL_STORY_POINTS"
};

export const pullRequestCalloutTypes: ConstantsInterfaces.PullRequestCalloutTypesInterface = {
  STUCK: "STUCK",
  STALLED_PULL_REQUEST_NO_COMMENTS: "STALLED_PULL_REQUEST_NO_COMMENTS",
  STALLED_PULL_REQUEST_FEW_COMMENTS: "STALLED_PULL_REQUEST_FEW_COMMENTS",
  STALLED_PULL_REQUEST_MANY_COMMENTS: "STALLED_PULL_REQUEST_MANY_COMMENTS",
  STALLED_PULL_REQUEST_APPROVED_NOT_MERGED:
    "STALLED_PULL_REQUEST_APPROVED_NOT_MERGED",
  PULL_REQUEST_MERGED_WITHOUT_APPROVAL: "PULL_REQUEST_MERGED_WITHOUT_APPROVAL",
  LOW_COMPLEXITY_HIGH_COMMENTS: "LOW_COMPLEXITY_HIGH_COMMENTS"
};

export const PullRequestStalledOptions = [
  {
    name: `Stuck: no comments`,
    id: pullRequestCalloutTypes.STALLED_PULL_REQUEST_NO_COMMENTS
  },
  {
    name: `Stuck: few comments`,
    id: pullRequestCalloutTypes.STALLED_PULL_REQUEST_FEW_COMMENTS
  },
  {
    name: `Stuck: many comments`,
    id: pullRequestCalloutTypes.STALLED_PULL_REQUEST_MANY_COMMENTS
  },
  {
    name: `Stuck: approved but not merged`,
    id: pullRequestCalloutTypes.STALLED_PULL_REQUEST_APPROVED_NOT_MERGED
  }
];

export const calloutMetadata: ConstantsInterfaces.CalloutMetadataInterface = {
  ADDED_MID_SPRINT: "This item was added mid-sprint",
  OPEN_MULTIPLE_SPRINTS: "Carry-over from previous sprint",
  STALLED_PULL_REQUEST_NO_COMMENTS: "This PR may be stuck and has no comments",
  STALLED_PULL_REQUEST_FEW_COMMENTS:
    "This PR may be stuck and has few comments",
  STALLED_PULL_REQUEST_MANY_COMMENTS:
    "This PR may be stuck and has many comments",
  STALLED_PULL_REQUEST_APPROVED_NOT_MERGED:
    "This PR may be stuck, pr was approved but is not yet merged",
  PULL_REQUEST_MERGED_WITHOUT_APPROVAL: "Merged without approval",
  LOW_COMPLEXITY_HIGH_COMMENTS: "Surprising comment activity",
  NULL_STORY_POINTS: "Unestimated story"
};

export const groupByTypes: ConstantsInterfaces.GroupByTypes = {
  EPIC: "EPIC",
  FLEX_TEAM: "FLEX_TEAM",
  MANAGER: "MANAGER",
  NONE: "NONE",
  PROJECT: "PROJECT",
  REPORT_GROUP: "REPORT_GROUP",
  ROLE: "ROLE",
  USER: "USER"
};

export const ownerContributorTypes: ConstantsInterfaces.OwnerContributorTypes = {
  OWNER: "OWNER",
  CONTRIBUTOR: "CONTRIBUTOR"
};

export const ownerContributorAlternativeLabels = {
  [ownerContributorTypes.OWNER]: "Author",
  [ownerContributorTypes.CONTRIBUTOR]: "Reviewer(s)"
};

export const cycleTimeStages: ConstantsInterfaces.CycleTimeStageTypes = {
  DEV: "DEV",
  WAITING_FOR_REVIEW: "WAITING_FOR_REVIEW",
  REVIEW: "REVIEW",
  RELEASE: "RELEASE"
};

export const cycleTimeStageLabels: {
  [key: string]: string;
} = {
  DEV: "Median First Commit to PR",
  WAITING_FOR_REVIEW: "Median Waiting for Review",
  REVIEW: "Median In Review",
  RELEASE: "Median Waiting for Release"
};

export const directionalityTypes: ConstantsInterfaces.DirectionalityTypesInterface = {
  POSITIVE: "positive",
  NEGATIVE: "negative"
};

export const healthScoreTypes: ConstantsInterfaces.SprintHealthScoreTypesInterface = {
  EXCELLENT: "EXCELLENT",
  GOOD: "GOOD",
  OK: "OK",
  CONCERNING: "CONCERNING"
};

export const alwaysOnScoreRanges = {
  NORMAL: [0, 2],
  ABOVE_NORMAL: [2, 4],
  HIGH: [4, 6]
};

export const contextSwitchingScoreTypes = {
  LESS: "LESS",
  ABOUT_THE_SAME: "ABOUT_THE_SAME",
  MORE: "MORE"
};

export const contextSwitchingScoreRanges = {
  LESS: [0, 0.33],
  ABOUT_THE_SAME: [0.33, 0.66],
  MORE: [0.66, 1]
};

export const alwaysOnScoreTypes = {
  NORMAL: "NORMAL",
  ABOVE_NORMAL: "ABOVE_NORMAL",
  HIGH: "HIGH"
};

export const healthScoreRanges: ConstantsInterfaces.SprintHealthScoreRangesInterface = {
  [healthScoreTypes.EXCELLENT]: [85, 101],
  [healthScoreTypes.GOOD]: [70, 85],
  [healthScoreTypes.OK]: [55, 70],
  [healthScoreTypes.CONCERNING]: [0, 55]
};

export const PeopleHealthScoreRanges: ConstantsInterfaces.SprintHealthScoreRangesInterface = {
  [healthScoreTypes.EXCELLENT]: [80, 101],
  [healthScoreTypes.GOOD]: [60, 80],
  [healthScoreTypes.OK]: [40, 60],
  [healthScoreTypes.CONCERNING]: [0, 40]
};

export const retrosSectionTypes: ConstantsInterfaces.RetrosSectionTypesInterface = {
  WENT_WELL: "went-well",
  LOOK_INTO: "look-into",
  STAYED_SAME: "stayed-same"
};

export const SPRINT_HEALTH_CHART_NUM_SPRINTS = 6;

export const sprintStates: ConstantsInterfaces.SprintStatesInterface = {
  ACTIVE: "active",
  CLOSED: "closed",
  FUTURE: "future"
};

export const Signals: ConstantsInterfaces.SignalsInterface = {
  ALWAYS_ON: "ALWAYS_ON",
  CONTEXT_SWITCHING: "CONTEXT_SWITCHING",
  DEEP_WORK: "DEEP_WORK",
  ISSUES_ADDED: "ISSUES_ADDED",
  ISSUES_CARRYOVER: "ISSUES_CARRYOVER",
  ISSUES_CLOSED: "ISSUES_CLOSED",
  ISSUES_WITH_DESCRIPTIONS: "ISSUES_WITH_DESCRIPTIONS",
  ISSUES_WITH_STORY_POINTS: "ISSUES_WITH_STORY_POINTS",
  JIRA_BUGS: "JIRA_BUGS",
  PRS_MERGED_NO_APPROVAL: "PRS_MERGED_NO_APPROVAL",
  PRS_NO_REVIEWERS: "PRS_NO_REVIEWERS",
  PRS_STUCK: "PRS_STUCK",
  SLACK_INTERRUPTIONS: "SLACK_INTERRUPTIONS",
  CHAT_INTERRUPTIONS: "CHAT_INTERRUPTIONS"
};

export const contextSwitchingFactors: ConstantsInterfaces.SignalsInterface = {
  CONTEXT_SWITCHING_TOTAL: "CONTEXT_SWITCHING_TOTAL",
  CONTEXT_SWITCHING_TOP_PRIORITY_BUGS: "CONTEXT_SWITCHING_TOP_PRIORITY_BUGS",
  CONTEXT_SWITCHING_MEETINGS: "CONTEXT_SWITCHING_MEETINGS",
  CONTEXT_SWITCHING_SLACK_CHANNELS: "CONTEXT_SWITCHING_SLACK_CHANNELS",
  CONTEXT_SWITCHING_CHAT_CHANNELS: "CONTEXT_SWITCHING_CHAT_CHANNELS",
  CONTEXT_SWITCHING_PR_REPOS: "CONTEXT_SWITCHING_PR_REPOS",
  CONTEXT_SWITCHING_JIRA_EPICS: "CONTEXT_SWITCHING_JIRA_EPICS"
};

export const overviewHealthTypes: ConstantsInterfaces.OverviewHealthTypes = {
  AVERAGE_HEALTH: "averageHealth",
  PROJECT_HEALTH: "projectHealth",
  PEOPLE_HEALTH: "peopleHealth"
};

export const peopleHealthSignals: Array<string> = [
  Signals.DEEP_WORK,
  Signals.CHAT_INTERRUPTIONS,
  Signals.CONTEXT_SWITCHING,
  Signals.ALWAYS_ON
];

export const projectHealthSignals: Array<string> = [
  Signals.ISSUES_ADDED,
  Signals.ISSUES_CARRYOVER,
  Signals.ISSUES_CLOSED,
  Signals.ISSUES_WITH_DESCRIPTIONS,
  Signals.ISSUES_WITH_STORY_POINTS,
  Signals.JIRA_BUGS,
  Signals.PRS_MERGED_NO_APPROVAL,
  Signals.PRS_NO_REVIEWERS,
  Signals.PRS_STUCK
];

export const healthFactorsMetadata: ConstantsInterfaces.SprintHealthFactorsMetadata = {
  [overviewHealthTypes.AVERAGE_HEALTH]: { title: "Average Sprint Health" },
  [overviewHealthTypes.PROJECT_HEALTH]: { title: "Project Health" },
  [overviewHealthTypes.PEOPLE_HEALTH]: { title: "People Health" },
  OVERALL_TEAM_HEALTH: { title: "Overall Team Health" },
  [Signals.ALWAYS_ON]: { title: "Always On" },
  [Signals.CONTEXT_SWITCHING]: { title: "Context Switching" },
  [Signals.DEEP_WORK]: { title: "Deep Work" },
  [Signals.ISSUES_ADDED]: { title: "Tickets Added" },
  [Signals.ISSUES_CARRYOVER]: {
    title: "Tickets Carried Over"
  },
  [Signals.ISSUES_CLOSED]: { title: "Tickets Closed" },
  [Signals.ISSUES_WITH_DESCRIPTIONS]: {
    title: "Tickets With Descriptions"
  },
  [Signals.ISSUES_WITH_STORY_POINTS]: {
    title: "Tickets With Story Points"
  },
  [Signals.JIRA_BUGS]: { title: "Bugs in Sprint" },
  [Signals.PRS_MERGED_NO_APPROVAL]: {
    title: "PRs Merged Without Approval"
  },
  [Signals.PRS_NO_REVIEWERS]: { title: "PRs Without Reviewers" },
  [Signals.PRS_STUCK]: { title: "Stuck PRs" },
  [Signals.CHAT_INTERRUPTIONS]: { title: "Chat Interruptions" },
  MEETING_DISTRIBUTION: {
    title: "Meeting Distribution"
  },
  MEETING_LENGTH: { title: "Meeting Length" }
};

export const contextSwitchingFactorsMetadata: ConstantsInterfaces.SprintHealthFactorsMetadata = {
  [contextSwitchingFactors.CONTEXT_SWITCHING_TOTAL]: {
    title: "Context Switching"
  },
  [contextSwitchingFactors.CONTEXT_SWITCHING_TOP_PRIORITY_BUGS]: {
    title: "Top Priority Bugs"
  },
  [contextSwitchingFactors.CONTEXT_SWITCHING_MEETINGS]: {
    title: "Meetings with 2+ teams"
  },
  [contextSwitchingFactors.CONTEXT_SWITCHING_CHAT_CHANNELS]: {
    title: "Chat Channels with Interruptions"
  },
  [contextSwitchingFactors.CONTEXT_SWITCHING_PR_REPOS]: { title: "PR Repos" },
  [contextSwitchingFactors.CONTEXT_SWITCHING_JIRA_EPICS]: {
    title: "Jira Epics"
  }
};

export const signalTypes: ConstantsInterfaces.SignalTypes = {
  HEALTHY: "healthy",
  UNHEALTHY: "unhealthy"
};

export const annotationStatusTypes: ConstantsInterfaces.AnnotationStatusTypesInterface = {
  CELEBRATE: "CELEBRATE",
  THUMBS_UP: "THUMBS_UP",
  QUESTION: "QUESTION",
  DISAPPOINTED: "DISAPPOINTED",
  ANGRY: "ANGRY",
  COMMENT: "COMMENT"
};

export const orderedAnnotationStatusTypes: Array<string> = [
  annotationStatusTypes.CELEBRATE,
  annotationStatusTypes.THUMBS_UP,
  annotationStatusTypes.DISAPPOINTED,
  annotationStatusTypes.ANGRY,
  annotationStatusTypes.QUESTION
];

export const StatusIconMap = {
  [annotationStatusTypes.CELEBRATE]: "party-horn",
  [annotationStatusTypes.THUMBS_UP]: "thumbs-up",
  [annotationStatusTypes.QUESTION]: "question",
  [annotationStatusTypes.DISAPPOINTED]: "disappointed",
  [annotationStatusTypes.ANGRY]: "angry",
  [annotationStatusTypes.COMMENT]: "comment"
};

export const positiveSignals = [
  annotationStatusTypes.CELEBRATE,
  annotationStatusTypes.THUMBS_UP
];

export const negativeSignals = [
  annotationStatusTypes.ANGRY,
  annotationStatusTypes.DISAPPOINTED
];

export const neutralSignals = [
  annotationStatusTypes.COMMENT,
  annotationStatusTypes.QUESTION
];

export const SUPPORT_EMAIL_ADDRESS = "support@uplevelteam.com";

export const workItemsUrlParamKeys: ConstantsInterfaces.UrlParamKeysInterface = {
  ALL_USERS: "allUsers",
  CALLOUTS: "callouts",
  END_DATE: "endDate",
  GROUP_BY: "groupBy",
  OWNER_CONTRIBUTOR: "ownerContributor",
  PAGE: "page",
  PROJECT_IDS: "projectIds",
  SHOW_RELATED: "showRelatedItems",
  SORT: "sort",
  SPRINT_ID: "sprintId",
  SPRINT_WORK_OTHER: "otherSprintItems",
  SPRINT_WORK_TYPES: "typesOfWork",
  START_DATE: "startDate",
  STATUS: "status",
  USERS: "users",
  WORK_ITEM_TYPES: "workItemTypes",
  REPOSITORIES: "repositories"
};

export const querystringParamsKeys: ConstantsInterfaces.UrlParamKeysInterface = {
  ...workItemsUrlParamKeys,
  BOARD_ID: "boardId",
  GROUPS_LIST: "groupsList",
  GROUP_DETAILS: "groupDetails",
  TIMERANGE_ID: "timerangeId",
  TEAM_ID: "teamId",
  TEAM_LEAD_ID: "teamLeadId",
  SELECTED_USER: "selectedUser",
  VISIBLE_REPOSITORIES: "visibleRepositories"
};

export const urlParamKeys: ConstantsInterfaces.UrlParamKeysInterface = {
  ...querystringParamsKeys,
  BOARD: "board",
  REPORT_GROUPS: "reportGroups",
  EPIC_ID: "epicId",
  ROLES: "roles",
  // the current team
  TEAM: "team",
  // teams being filtered
  TEAMS: "teams",
  TEAM_LEAD: "teamLead",
  TENANT_ID: "tenantId",
  TIMERANGE: "timerange"
};

export const urlArrayParams: Array<string> = [
  urlParamKeys.CALLOUTS,
  urlParamKeys.CUSTOM_GROUPS,
  urlParamKeys.OWNER_CONTRIBUTOR,
  urlParamKeys.PROJECT_IDS,
  urlParamKeys.ROLES,
  urlParamKeys.SPRINT_WORK_OTHER,
  urlParamKeys.SPRINT_WORK_TYPES,
  urlParamKeys.STATUS,
  urlParamKeys.TEAMS,
  urlParamKeys.USERS,
  urlParamKeys.WORK_ITEM_TYPES,
  urlParamKeys.REPOSITORIES,
  urlParamKeys.VISIBLE_REPOSITORIES
];

export const urlBooleanParams: Array<string> = [urlParamKeys.SHOW_RELATED];

export const urlUuidParams: Array<string> = [
  urlParamKeys.BOARD_ID,
  urlParamKeys.PROJECT_IDS,
  urlParamKeys.SPRINT_ID,
  urlParamKeys.TEAM_ID,
  urlParamKeys.TEAMS
];

export const urlObjectParams: Array<string> = [
  urlParamKeys.GROUP_DETAILS,
  urlParamKeys.GROUPS_LIST
];

export const urlUserSettingsParams: { [key: string]: string } = {
  [urlParamKeys.GROUP_BY]: "defaultJiraGroupBy"
};

// params that could affect more than just the work-items, mostly the params set
// from the global nav -- teams, zoomed in team lead, timerange/sprint related
// values
export const urlGlobalParams: Array<string> = [
  urlParamKeys.BOARD_ID,
  urlParamKeys.END_DATE,
  urlParamKeys.SPRINT_ID,
  urlParamKeys.START_DATE,
  urlParamKeys.TEAM_ID,
  urlParamKeys.TEAM_LEAD_ID,
  urlParamKeys.TIMERANGE_ID
];

export const SOURCES = {
  CALENDAR: "CALENDAR",
  REPOSITORIES: "REPOSITORIES",
  JIRA: "JIRA",
  MESSAGING: "MESSAGING"
};

export const SOURCES_DESCRIPTION_MAP = {
  [SOURCES.CALENDAR]: {
    displayText: "Calendar",
    icon: "calendar"
  },
  [SOURCES.REPOSITORIES]: {
    displayText: "Repositories",
    icon: "code-merge"
  },
  [SOURCES.MESSAGING]: {
    displayText: "Messaging",
    icon: "comments-alt"
  },
  [SOURCES.JIRA]: {
    displayText: "Jira",
    icon: "jira"
  }
};

export const urlParamsBaseDefaultValues = {
  allUsers: [],
  callouts: [],
  reportGroups: [],
  groupBy: groupByTypes.EPIC,
  groupsList: { page: 0 },
  groupDetails: { page: 0, sort: [] },
  otherSprintItems: [],
  ownerContributor: [],
  projectIds: [],
  repositories: [],
  roles: [],
  showRelatedItems: false,
  sprintId: null,
  status: [],
  teams: [],
  typesOfWork: [],
  users: [],
  workItemTypes: [],
  selectedUser: null,
  visibleRepositories: []
};

export const urlParamsSprintDefaultValues = {
  ...urlParamsBaseDefaultValues,
  typesOfWork: [
    sprintMetadataWorkTypes.SPRINT_WORK,
    sprintMetadataWorkTypes.PULL_REQUESTS_NOT_IN_SPRINT
  ]
};

export const urlParamsKanbanDefaultValues = {
  ...urlParamsBaseDefaultValues,
  ownerContributor: [ownerContributorTypes.OWNER]
};

export const authProviders: { [key: string]: string } = {
  OKTA: "okta",
  AUTH0: "auth0"
};

export const urlParamsValuesLabels = {
  [groupByTypes.EPIC]: "Epic",
  [groupByTypes.FLEX_TEAM]: "Flex Team",
  [groupByTypes.NONE]: "None",
  [groupByTypes.MANAGER]: "Manager",
  [groupByTypes.PROJECT]: "Project",
  [groupByTypes.REPORT_GROUP]: "Report Group",
  [groupByTypes.ROLE]: "Role",
  [groupByTypes.USER]: "Person",
  [ownerContributorTypes.CONTRIBUTOR]: "Contributor(s)",
  [ownerContributorTypes.OWNER]: "Owner",
  [pullRequestCalloutTypes.LOW_COMPLEXITY_HIGH_COMMENTS]:
    "Low complexity & highly discussed",
  [pullRequestCalloutTypes.PULL_REQUEST_MERGED_WITHOUT_APPROVAL]:
    "Merged without approval",
  [pullRequestCalloutTypes.STUCK]: "Stuck",
  [workItemCalloutTypes.ADDED_MID_SPRINT]: "Added mid-sprint",
  [workItemCalloutTypes.OPEN_MULTIPLE_SPRINTS]: "Carry-over",
  [workItemCalloutTypes.NULL_STORY_POINTS]: "Un-estimated work",
  [sprintMetadataWorkTypes.NOT_ATTACHED_TO_SPRINT]: "Other activity by my team",
  [sprintMetadataWorkTypes.PULL_REQUESTS_NOT_IN_SPRINT]:
    reservedEpicsMetadata[reservedEpics.UNLINKED_PRS].title,
  [sprintMetadataWorkTypes.REMOVED_FROM_SPRINT]:
    "Items removed from the sprint",
  [sprintMetadataWorkTypes.SPRINT_WORK]: "Sprint work",
  [sprintMetadataWorkTypes.COMPLETED_OUTSIDE_SPRINT]:
    "Items completed outside of the sprint",
  [sprintOptions.OTHER_TEAMS.id]: sprintOptions.OTHER_TEAMS.label,
  [sprintOptions.UNASSIGNED.id]: sprintOptions.UNASSIGNED.label,
  [workItemsUrlParamKeys.SHOW_RELATED]: "Show related items",
  [workMetadataActivityTypes.BUG]: "Bug",
  [workMetadataActivityTypes.ISSUE]: "Issue",
  [workMetadataActivityTypes.PR]: "PR",
  [workMetadataActivityTypes.SUB_TASK]: "Subtask",
  [workMetadataStatusTypes.DONE]: "Done",
  [workMetadataStatusTypes.IN_PROGRESS]: "In progress",
  [workMetadataStatusTypes.OPEN]: "To-do"
};

export const prComplexityLevels = {
  NOT_COMPLEX_TO_REVIEW: "Low",
  COMPLEX_TO_REVIEW: "High"
};

// *************************
// CHART
// *************************
export const chartToolTipConfig = {
  useHTML: true,
  enabled: true,
  backgroundColor: "transparent",
  shadow: false,
  borderWidth: 0,
  borderRadius: 0,
  outside: true
};

export const iconStarRogue =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT4KIDxnIGNsYXNzPSJsYXllciI+CiAgPHRpdGxlPkxheWVyIDE8L3RpdGxlPgogIDxwYXRoIGQ9Im03LjQ1LDAuNTNsMi4zNyw0LjQ4bDQuOTgsMC44NmwtMy41MywzLjYzbDAuNzIsNWwtNC41NSwtMi4yM2wtNC41MywyLjIzbDAuNzEsLTVsLTMuNTIsLTMuNjNsNC45OCwtMC44NmwyLjM2LC00LjQ4bDAuMDEsMHoiIGZpbGw9IiNmZmUxMTkiIGlkPSJzdmdfMSIvPgogPC9nPgo8L3N2Zz4=";
export const iconPlusMagneto =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT4KIDxnIGNsYXNzPSJsYXllciI+CiAgPHRpdGxlPkxheWVyIDE8L3RpdGxlPgogIDxwYXRoIGQ9Im0wLjAxLDMuOTdsMy45NSwwbDAsLTMuOTZsNC4wNiwwbDAsMy45NmwzLjk2LDBsMCw0LjA2bC0zLjk2LDBsMCwzLjk2bC00LjA2LDBsMCwtMy45NmwtMy45NSwwbDAsLTQuMDZ6IiBmaWxsPSIjZTZiZWZmIiBpZD0ic3ZnXzMiLz4KIDwvZz4KPC9zdmc+";
export const iconCrossProfessorX =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT4KIDxnIGNsYXNzPSJsYXllciI+CiAgPHRpdGxlPkxheWVyIDE8L3RpdGxlPgogIDxwYXRoIGQ9Im0wLjA2LDRsMy45NSwwbDAsLTMuOTZsNC4wNiwwbDAsMy45NmwzLjk2LDBsMCw0LjA2bC0zLjk2LDBsMCwzLjk2bC00LjA2LDBsMCwtMy45NmwtMy45NSwwbDAsLTQuMDZ6IiBmaWxsPSIjODA4MDAwIiBpZD0ic3ZnXzMiIHRyYW5zZm9ybT0icm90YXRlKDQ1IDYuMDQwNjggNi4wMzQwNykiLz4KIDwvZz4KPC9zdmc+";

export const customizationSettingsKeys: { [key: string]: FeatureTypes } = {
  TICKETS_ADDED_MID_SPRINT: "ticketsAddedMidsprint",
  TICKETS_REMOVED_MID_SPRINT: "ticketsRemovedMidsprint",
  TICKETS_NOT_COMPLETED: "ticketsNotCompleted",
  TICKETS_CLOSED: "ticketsClosed",
  TICKETS_CARRIED_OVER: "ticketsCarriedOver",
  JIRA_EPICS: "jiraEpics",
  SUBTASKS: "subtasks",
  TICKETS_DESCRIBED: "ticketsDescribed",
  STORY_POINTS: "storyPoints",
  BUGS_IN_SPRINT: "bugsInSprint",
  TOP_PRIORITY_BUGS: "topPriorityBugs",
  MEETINGS_WITH_MANY_TEAMS: "meetingsWithManyTeams",
  PR_REPOS: "prRepos",
  PRS_MERGED_NO_APPROVAL: "prsMergedNoApproval",
  PRS_MERGED_NO_REVIEWERS: "prsMergedNoReviewers",
  STUCK_PRS: "stuckPrs",
  CHAT_CHANNELS_INTERRUPTED: "chatChannelsInterrupted"
};

export const sprintCustomizationSettingsKeys: Array<FeatureTypes> = [
  customizationSettingsKeys.TICKETS_ADDED_MID_SPRINT,
  customizationSettingsKeys.TICKETS_ADDED_MID_SPRINT,
  customizationSettingsKeys.TICKETS_REMOVED_MID_SPRINT,
  customizationSettingsKeys.TICKETS_NOT_COMPLETED,
  customizationSettingsKeys.TICKETS_CLOSED,
  customizationSettingsKeys.TICKETS_CARRIED_OVER
];

export const orderedRoles = [
  "Dev",
  "Senior Dev",
  "Contractor",
  "Customer Engagement",
  "Design",
  "Front-line Manager",
  "Leadership",
  "Other",
  "Product Acceptance",
  "Product/Program",
  "QA",
  "Researcher",
  "Sales",
  "Science",
  "Support Dev",
  "Test Dev"
] as const;

export const roleTypes = orderedRoles.reduce(
  (acc, current) => ({
    ...acc,
    [snakeCase(current).toUpperCase()]: current
  }),
  {} as { [key: string]: Roles }
);

export const managerRoles = [
  roleTypes.FRONT_LINE_MANAGER,
  roleTypes.LEADERSHIP
];

export const teamAvatarIcons = [
  "alicorn",
  "bat",
  "cat",
  "cat-space",
  "cow",
  "crow",
  "deer",
  "deer-rudolph",
  "dog",
  "dove",
  "dragon",
  "duck",
  "elephant",
  "fish",
  "frog",
  "ghost",
  "hippo",
  "honey-badger",
  "horse",
  "horse-head",
  "kiwi",
  "monkey",
  "narwhal",
  "otter",
  "pegasus",
  "pig",
  "rabbit",
  "rabbit-fast",
  "ram",
  "robot",
  "sheep",
  "skull",
  "skull-cow",
  "skull-crossbones",
  "snake",
  "snowman",
  "spider",
  "squirrel",
  "turtle",
  "unicorn",
  "whale"
] as const;

// *************************
// START METRICS CORRALLING WIP
// *************************
// trying to have one comprehensive place where all of the metrics live
export const metrics: MetricsInterface = {
  ALWAYS_ON: "ALWAYS_ON",
  CHAT_INTERRUPTIONS: "CHAT_INTERRUPTIONS",
  CONTEXT_SWITCHING: "CONTEXT_SWITCHING",
  // CONTEXT_SWITCHING_CHAT_CHANNELS: "CONTEXT_SWITCHING_CHAT_CHANNELS",
  // CONTEXT_SWITCHING_JIRA_EPICS: "CONTEXT_SWITCHING_JIRA_EPICS",
  // CONTEXT_SWITCHING_MEETINGS: "CONTEXT_SWITCHING_MEETINGS",
  // CONTEXT_SWITCHING_PR_REPOS: "CONTEXT_SWITCHING_PR_REPOS",
  // CONTEXT_SWITCHING_TOP_PRIORITY_BUGS: "CONTEXT_SWITCHING_TOP_PRIORITY_BUGS",
  // CONTEXT_SWITCHING_TOTAL: "CONTEXT_SWITCHING_TOTAL",
  DEEP_WORK: "DEEP_WORK",
  ISSUES_ADDED: "ISSUES_ADDED",
  ISSUES_CARRYOVER: "ISSUES_CARRYOVER",
  ISSUES_WITH_DESCRIPTIONS: "ISSUES_WITH_DESCRIPTIONS",
  ISSUES_WITH_STORY_POINTS: "ISSUES_WITH_STORY_POINTS",
  MEETING_DISTRIBUTION: "MEETING_DISTRIBUTION",
  MEETING_LENGTH: "MEETING_LENGTH",
  OVERALL_TEAM_HEALTH: "OVERALL_TEAM_HEALTH",
  PRS_MERGED_NO_APPROVAL: "PRS_MERGED_NO_APPROVAL",
  PRS_NO_REVIEWERS: "PRS_NO_REVIEWERS",
  PRS_STUCK: "PRS_STUCK",
  // these 5 are being added
  BUG_ALLOCATION: "BUG_ALLOCATION",
  COMPLETION_RATE: "COMPLETION_RATE",
  PR_COMPLEXITY: "PR_COMPLEXITY",
  PR_CYCLE_TIME: "PR_CYCLE_TIME",
  RELEASE_FREQUENCY: "RELEASE_FREQUENCY",
  SPRINT_COMPLETION_RATE: "SPRINT_COMPLETION_RATE",
  // these 3 are being deprecated/moved
  ISSUES_CLOSED: "ISSUES_CLOSED",
  JIRA_BUGS: "JIRA_BUGS",
  PR_FREQUENCY: "PR_FREQUENCY"
};

export const peopleHealthMetrics: Array<keyof PeopleHealthMetricsInterface> = [
  metrics.DEEP_WORK,
  metrics.CHAT_INTERRUPTIONS,
  metrics.CONTEXT_SWITCHING,
  // TODO: should the context switching sub metrics go in here??
  metrics.ALWAYS_ON
];

export const sprintProjectHealthMetrics: Array<keyof SprintProjectHealthMetricsInterface> = [
  metrics.ISSUES_ADDED,
  metrics.ISSUES_CARRYOVER,
  metrics.ISSUES_CLOSED,
  metrics.ISSUES_WITH_DESCRIPTIONS,
  metrics.ISSUES_WITH_STORY_POINTS,
  metrics.JIRA_BUGS,
  metrics.PRS_MERGED_NO_APPROVAL,
  metrics.PRS_NO_REVIEWERS,
  metrics.PRS_STUCK
];

export const kanbanProjectHealthMetrics: Array<keyof KanbanProjectHealthMetricsInterface> = [
  metrics.ISSUES_CLOSED,
  metrics.ISSUES_WITH_DESCRIPTIONS,
  metrics.ISSUES_WITH_STORY_POINTS,
  metrics.JIRA_BUGS,
  metrics.PRS_MERGED_NO_APPROVAL,
  metrics.PRS_NO_REVIEWERS,
  metrics.PRS_STUCK
];

// *************************
// END METRICS CORALLING WIP
// *************************
