// hooks
import { useSelector } from "react-redux";
import { useUrlParams } from "./useUrlParams";
import { useQuery } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { requestSprintSuccess } from "../utils/sprints";

export const useSprintSuccessData = (canRequest = true) => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const { urlParams } = useUrlParams();
  const sprintId = urlParams.sprintId as string;
  const teamId = urlParams.teamId as string;

  return useQuery(
    ["sprint-success", { sprintId, teamId }],
    () =>
      requestSprintSuccess({
        accessToken,
        tenantId,
        params: { sprintId, teamId }
      }),
    {
      enabled: !!accessToken && !!tenantId && !!sprintId && canRequest,
      refetchOnWindowFocus: false
    }
  );
};
