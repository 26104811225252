import moment from "moment";
import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { momentUnitTypes } from "../../constants/constants";

// components
import TimeRange from "../TimeRange/TimeRange";

// interfaces
import { OrgByTimePoint } from "../../interfaces/charts";

// utils
import { createTimestamp } from "../../utils/date";

// styled components
const Container = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const Header = styled.header`
  margin-bottom: 1.6rem;
`;
const DefinitionWrapper = styled.div`
  align-items: baseline;
  display: flex;
`;
const Term = styled.dt`
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;

// typescript props
type Props = {
  context?: {
    point?: OrgByTimePoint;
  };
};

const OverviewReleaseFrequencyTrendlineTooltip = ({
  context
}: Props): ReactElement | null => {
  if (!context?.point) {
    return null;
  }
  const { y, endDate, startDate } = context.point;
  const thisTestId = `overview-release-frequency-trendline-tooltip`;

  return (
    <Container className={thisTestId} data-testid={thisTestId}>
      <Header>
        <TimeRange
          config={{
            format: { end: "MM/DD/YYYY", start: "MM/DD/YYYY" }
          }}
          timerange={{
            end: endDate,
            start: startDate
          }}
          testId={thisTestId}
        />
      </Header>
      <dl>
        <DefinitionWrapper>
          <Term>Total number of releases:&nbsp;</Term>
          <dd>{y.toFixed(0)}</dd>
        </DefinitionWrapper>
      </dl>
    </Container>
  );
};

export default OverviewReleaseFrequencyTrendlineTooltip;
