import { get } from "lodash";
import { legacyApi } from "./api";

// constants
import { pageIds, pageMetadata } from "../constants/constants";

// utils
import { getPageTitle } from "./router";

// typescript props
type ReportsProps = {
  accessToken: string;
  tenantId: number;
  users: Array<{ email: string }>;
};

export const getReportsPageTitle = () => {
  const reportsPageMetadata = pageMetadata[pageIds.REPORTS];
  return getPageTitle(get(reportsPageMetadata, "title"));
};

export const requestReportsList = async ({
  accessToken,
  tenantId,
  users
}: ReportsProps): Promise<any> => {
  const response = await legacyApi.get(`/${tenantId}/1-on-1-pdfs`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: { users: users.map(user => user.email).join(",") }
  });
  return response.data;
};
