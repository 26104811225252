import React, { ReactElement, useMemo } from "react";
import { currentTheme } from "../../themes/currentTheme";
import styled from "styled-components/macro";

// components
import Chart from "../Chart/Chart";
import PrWorkflowChartSeriesTooltip from "../PrWorkflowChartSeriesTooltip/PrWorkflowChartSeriesTooltip";

// interfaces
import { EpicDetailsInterface } from "../../interfaces/work-items";
import { TimerangeMetadataInterface } from "../../interfaces/constants";

// utils
import generateConfig from "./config";
import { UserReportWithMetadataInterface } from "../../interfaces/user";

// styled components
const Container = styled.div`
  & .repository {
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
  }

  & .todayLabel {
    font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
      sans-serif;
    font-weight: ${props => props.theme.fonts.primary.weights.bold};
  }
`;

// typescript props
type Props = {
  className?: string;
  data: EpicDetailsInterface;
  testId?: string;
  timerange: TimerangeMetadataInterface;
  team: UserReportWithMetadataInterface[] | undefined;
};

const ChartPrWorkflow = ({
  className,
  data,
  testId = "testId",
  timerange,
  team
}: Props): ReactElement => {
  const thisTestId = `${testId}-chart-pr-timeline`;
  const { timerangeType, end, start } = timerange;
  const { workItems } = data;
  const chart = useMemo(() => {
    return {
      config: generateConfig({
        data: workItems,
        timerangeType,
        end,
        start
      }),
      id: "pr-timeline-chart"
    };
  }, [end, start, timerangeType, workItems]);

  return (
    <Container className={className} data-testid={thisTestId}>
      <Chart
        chart={chart}
        className={className}
        testId={thisTestId}
        theme={currentTheme}
        tooltipComponent={
          <PrWorkflowChartSeriesTooltip
            team={team}
            workItems={data.workItems}
          />
        }
      />
    </Container>
  );
};

export default ChartPrWorkflow;
