import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import { isEmpty } from "lodash";

// components
import ChartSpline from "../ChartSpline/ChartSpline";

// config
import generateConfig from "./config";

// exporting for targeting in tests
export const ChartStoryPoints = ({
  className,
  data,
  isFetchingData,
  selectedDate,
  onSelectDate,
  testId,
  theme
}) => {
  if (isEmpty(data) || !onSelectDate || !testId) {
    return null;
  }

  return (
    <ChartSpline
      chart={{
        config: generateConfig({ data, onSelectDate, selectedDate, theme }),
        id: "story-points-chart",
        isFetchingData
      }}
      className={className}
      testId={`${testId}-story-points-chart`}
      theme={theme}
    />
  );
};

ChartStoryPoints.propTypes = {
  data: PropTypes.array.isRequired,
  isFetchingData: PropTypes.bool,
  onSelectDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
  testId: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default withTheme(ChartStoryPoints);
