import { useEffect, useMemo, useRef, useState } from "react";
import config from "../env-config";
import * as LDClient from "launchdarkly-js-client-sdk";
import { v4 as uuidv4 } from "uuid";
import { get, has, isEmpty, isNil, isUndefined } from "lodash";
import { captureException } from "@sentry/react";

// actions
import { storeAuthAccessToken } from "../actions/authActions";
import { storeFlags } from "../actions/flagActions";
import { storeSessionParams } from "../actions/sessionActions";
import { storeUser } from "../actions/userActions";

// constants
import { namedTimerangeIds } from "../constants/constants";

// hooks
import { useDispatch, useSelector } from "react-redux";
import { useUserSettings } from "./useUserSettings";
import { useUrlParams } from "./useUrlParams";
import { useActiveUser } from "./useActiveUser";
import { useAuthProvider } from "./useAuthProvider";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import fetchUserData from "../components/DashboardPage/fetchUserData";
import parseUserData from "../components/DashboardPage/parseUserData";
import fetchUserInfoData from "../components/DashboardPage/fetchUserInfoData";
import { getTimestampForDate } from "../utils/date";
import { sendEvent } from "../utils/uplevelAnalytics";
import {
  createAnalyticsUserMetadata,
  createUserKey,
  createFlagUserMetadata
} from "../utils/user";

export const useInitialData = (): [boolean, boolean] => {
  const [authenticatedUserEmail, setAuthenticatedUserEmail] = useState<
    string
  >();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const {
    getAccessToken,
    getUserGivenName,
    getIsAuthenticated,
    getUser
  } = useAuthProvider();

  useEffect(() => {
    if (isUndefined(isAuthenticated)) {
      getIsAuthenticated().then((response: boolean) =>
        setIsAuthenticated(response)
      );
    }
  }, [isAuthenticated, getIsAuthenticated]);
  useEffect(() => {
    if (isUndefined(authenticatedUserEmail)) {
      getUser().then((response: { [key: string]: string }) =>
        setAuthenticatedUserEmail(response.email)
      );
    }
  }, [getUser, authenticatedUserEmail]);
  const isFullyAuthenticated = !isUndefined(isAuthenticated) && isAuthenticated;

  const [hasInvalidRoles, _setHasInvalidRoles] = useState(false);
  const dispatch = useDispatch();

  // maybe hacky global ts variable?
  // https://mariusschulz.com/blog/declaring-global-variables-in-typescript
  const { heap, hj, initializeHotjar } = window as any;

  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const hasFlags = !isNil(flags);
  const session = useSelector((state: AppStateInterface) => state.session);
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const userHasUplevelAccount = useSelector(
    (state: AppStateInterface) => !!state.session.userHasUplevelAccount
  );

  const hasRequestedUserInfo = useRef(false);
  const hasRequestedUserData = useRef(false);
  const hasRequestedUserGivenName = useRef(false);
  const hasRequestedFlags = useRef(false);
  const hasSentParams = useRef(false);
  const hasInitializedHotjar = useRef(false);
  const hasIdentifiedHotjar = useRef(false);
  const hasInitializedHeap = useRef(false);

  const user = useSelector((state: AppStateInterface) => state.user);
  const userEmail = get(user, "email");
  const userGivenName = get(user, "givenName");
  const userId = get(user, "id");
  const userLdHash = get(user, "ldHash");
  const userEnabledRoles = get(user, "enabled_roles");

  const { urlParams } = useUrlParams();
  const timerange = urlParams.timerange;
  const team = urlParams.team;

  const { isLoading: isLoadingUserSettings } = useUserSettings();
  const activeUser = useActiveUser();
  const activeUserId = activeUser?.id;

  const hasBaselineRenderingData = useMemo(() => {
    return (
      // wait until we have an access token for requests
      !!accessToken &&
      // wait until flags are available so we can reference flags.foo
      hasFlags &&
      // wait until team is available
      !!team &&
      // wait until timerange has been affirmatively changed from fake default
      timerange.id !== namedTimerangeIds.TODAY &&
      // wait until we have the tenant id for requests
      !!tenantId &&
      // wait until we have the baseline user info
      !!activeUserId &&
      // wait until we have fetched user settings
      !isLoadingUserSettings &&
      // wait until we have confirmation of uplevel account
      userHasUplevelAccount
    );
  }, [
    accessToken,
    hasFlags,
    isLoadingUserSettings,
    tenantId,
    timerange,
    activeUserId,
    team,
    userHasUplevelAccount
  ]);

  useEffect(() => {
    (async () => {
      if (isFullyAuthenticated && !accessToken) {
        try {
          const token = await getAccessToken();
          if (!!token) {
            dispatch(storeAuthAccessToken(token));
          }
        } catch (e) {
          captureException(e);
        }
      }
    })();
  }, [isFullyAuthenticated, accessToken, getAccessToken, dispatch]);

  useEffect(() => {
    (async () => {
      if (
        !hasRequestedUserData.current &&
        !userEmail &&
        accessToken &&
        tenantId &&
        !!authenticatedUserEmail
      ) {
        const baseSessionParams = {
          loginTimestamp: getTimestampForDate(new Date()),
          username: authenticatedUserEmail,
          userHasUplevelAccount: false,
          uuid: uuidv4()
        };
        try {
          hasRequestedUserData.current = true;
          const userDataResponse = await fetchUserData({
            accessToken,
            tenantId
          });
          if (!!userDataResponse) {
            const parsedUserData = parseUserData(userDataResponse);
            dispatch(storeUser(parsedUserData));
            // store session params with user email and session id
            dispatch(
              storeSessionParams({
                ...baseSessionParams,
                displayTenantAlias: userDataResponse.tenantAlias,
                username: userDataResponse.email,
                userHasUplevelAccount: true
              })
            );
          }
        } catch (e) {
          hasRequestedUserData.current = true;
          dispatch(storeSessionParams(baseSessionParams));
          captureException(e);
        }
      }
    })();
  }, [
    hasRequestedUserData,
    accessToken,
    dispatch,
    tenantId,
    userEmail,
    authenticatedUserEmail
  ]);

  useEffect(() => {
    (async () => {
      if (
        !hasRequestedUserGivenName.current &&
        isFullyAuthenticated &&
        !userGivenName
      ) {
        try {
          hasRequestedUserGivenName.current = true;
          const givenName = await getUserGivenName();
          if (!!givenName) {
            dispatch(
              storeUser({
                givenName
              })
            );
          }
        } catch (e) {
          hasRequestedUserGivenName.current = false;
          captureException(e);
        }
      }
    })();
  }, [isFullyAuthenticated, getUserGivenName, dispatch, userGivenName]);

  useEffect(() => {
    (async () => {
      try {
        if (
          !hasRequestedUserInfo.current &&
          !userId &&
          accessToken &&
          tenantId &&
          !!userHasUplevelAccount
        ) {
          hasRequestedUserInfo.current = true;
          const userInfoDataResponse = await fetchUserInfoData({
            accessToken,
            tenantId
          });
          if (isEmpty(userInfoDataResponse?.enabled_roles)) {
            _setHasInvalidRoles(true);
          }
          dispatch(storeUser(userInfoDataResponse));
          // store session params with user email and session id
          dispatch(
            storeSessionParams({
              enabled_roles: userInfoDataResponse?.enabled_roles || []
            })
          );
        }
      } catch (e) {
        hasRequestedUserInfo.current = false;
        captureException(e);
      }
    })();
  }, [
    hasRequestedUserInfo,
    accessToken,
    dispatch,
    tenantId,
    userId,
    userHasUplevelAccount
  ]);

  useEffect(() => {
    (async () => {
      if (
        !hasRequestedFlags.current &&
        !hasFlags &&
        !!userEmail &&
        !!userLdHash &&
        !!userEnabledRoles?.length
      ) {
        try {
          hasRequestedFlags.current = true;
          // initialize LD client
          const ldClient = LDClient.initialize(
            config.LAUNCH_DARKLY_CLIENT_ID,
            {
              key: createUserKey(user.email),
              custom: createFlagUserMetadata(user)
            },
            {
              allAttributesPrivate: true, // hide sent user attributes in LD ui
              fetchGoals: false, // don't make an extra request for analytics
              hash: userLdHash, // needed for secure mode
              useReport: true // send user attributes as REPORT request
            }
          );

          // make sure flags are ready to access
          ldClient.on("ready", async () => {
            try {
              // request all flags from LD and store flags in state
              const fetchedFlags = ldClient.allFlags();
              dispatch(storeFlags(fetchedFlags));
              // store session params with flags
              dispatch(
                storeSessionParams({
                  flags: fetchedFlags
                })
              );
            } catch (e) {
              hasRequestedFlags.current = false;
              captureException(e);

              // store empty object in state to replace the null
              // we initialized the flag state with
              dispatch(storeFlags({}));
              // store session params without flags
              dispatch(
                storeSessionParams({
                  flags: {}
                })
              );
            }
          });
        } catch (e) {
          hasRequestedFlags.current = false;
          captureException(e);

          // store empty object in state to replace the null
          // we initialized the flag state with
          dispatch(storeFlags({}));
          // store session params without flags
          dispatch(
            storeSessionParams({
              flags: {}
            })
          );
        }
      }
    })();
  }, [
    hasRequestedFlags,
    dispatch,
    hasFlags,
    userEmail,
    userLdHash,
    userEnabledRoles,
    user
  ]);

  useEffect(() => {
    (async () => {
      if (
        accessToken &&
        tenantId &&
        !hasSentParams.current &&
        has(session, "uuid") &&
        has(session, "enabled_roles") &&
        has(session, "username") &&
        has(session, "flags")
      ) {
        try {
          hasSentParams.current = true;
          await sendEvent({
            accessToken,
            farsUrl: config.FARS_URL,
            tenantId,
            params: {
              session
            }
          });
        } catch (e) {
          hasSentParams.current = false;
          captureException(e);
        }
      }
    })();
  }, [accessToken, hasSentParams, session, tenantId]);

  // hotjar initialization
  useEffect(() => {
    (async () => {
      if (!!flags?.["hotjar"] && !!config.HOTJAR_ID) {
        if (!hasInitializedHotjar.current) {
          try {
            initializeHotjar(config.HOTJAR_ID);
            hasInitializedHotjar.current = true;
          } catch (e) {
            captureException(e);
          }
        }

        if (!hasIdentifiedHotjar.current && !!hj && !!userEmail) {
          try {
            // TODO: add tenant and enabled_roles here when we have a clearer idea of what we want to pass
            hj("identify", createUserKey(userEmail) || null, {
              email: userEmail
            });
            hasIdentifiedHotjar.current = true;
          } catch (e) {
            captureException(e);
          }
        }
      }
    })();
  }, [flags, hj, userEmail, hasInitializedHotjar, initializeHotjar]);

  // heap initialization
  useEffect(() => {
    (async () => {
      if (!!flags?.["heap"] && !!config.HEAP_ID) {
        if (
          !hasInitializedHeap.current &&
          !!heap &&
          !!userEmail &&
          !!userEnabledRoles?.length
        ) {
          const heapConfig = {
            ...createAnalyticsUserMetadata(user),
            email: userEmail
          };
          try {
            heap.load(config.HEAP_ID);
            heap.identify(createUserKey(userEmail));
            heap.addUserProperties(heapConfig);
            hasInitializedHeap.current = true;
          } catch (e) {
            captureException(e);
          }
        }
      }
    })();
  }, [flags, heap, user, userEmail, userEnabledRoles, hasInitializedHeap]);

  return [hasBaselineRenderingData, hasInvalidRoles];
};
