import React, { useMemo } from "react";
import { DefaultTheme, withTheme } from "styled-components/macro";
import styled from "styled-components";

// components
import Chart from "../Chart/Chart";
import { ErrorBoundary } from "@sentry/react";
import Icon from "../Icon/Icon";
import RouteLink from "../RouteLink/RouteLink";

// config
import generateConfig from "./config";

// hooks
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import {
  DeepDiveData,
  SelectablePeopleMetrics
} from "../../interfaces/team-deep-dive";
import { UserReportWithMetadataInterface } from "../../interfaces/user";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import { get, isEmpty } from "lodash";
import { getSelectableTeamMembers } from "../../utils/people-picker";
import { teamDeepDiveSections } from "../../constants/constants";

const ContextSwitchingWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const WarningContainerSection = styled.div`
  margin-top: 1rem;
`;

const SubfactorsList = styled.ul`
  list-style: inside;
  margin-left: 1rem;
`;

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.brand.wolverine};
  margin-right: 1rem;
`;

// typescript props
type Props = {
  className?: string;
  data?: DeepDiveData;
  isFetchingData?: boolean;
  testId?: string;
  theme: DefaultTheme;
  selectedMetric: SelectablePeopleMetrics;
  chartView: UserReportWithMetadataInterface | "team";
  canSeeAlwaysOnData?: boolean;
};

const ChartPeopleHealth = ({
  className,
  data,
  isFetchingData = false,
  testId = "testId",
  selectedMetric,
  chartView,
  theme,
  canSeeAlwaysOnData = false
}: Props) => {
  const thisTestId = `${testId}-chart-people-health`;
  const { urlParams } = useUrlParams();
  const { trackEvent } = useTracking();
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const user = useSelector((state: AppStateInterface) => get(state, "user"));
  const activeTeam = urlParams.team;
  function onChartMouseOver(e: React.MouseEvent<HTMLElement>) {
    e.persist();
    trackEvent({
      e,
      label: "chart-people-health",
      value: "chart moused over"
    });
  }
  function onChartMouseOut(e: React.MouseEvent<HTMLElement>) {
    e.persist();
    trackEvent({
      e,
      label: "chart-people-health",
      value: "chart moused out"
    });
  }
  const selectableTeamMembers = getSelectableTeamMembers({
    user,
    flags,
    teamDeepDiveParams: {
      selectedSection: teamDeepDiveSections.HEALTH_METRICS,
      selectedMetric,
      selectedUser: chartView === "team" ? "team" : chartView.email
    },
    urlParams
  });
  const teamMembers = isEmpty(selectableTeamMembers)
    ? activeTeam?.teamMembers
    : activeTeam?.teamMembers.filter(m => selectableTeamMembers.includes(m.id));

  const isNullContextSwitching = useMemo(() => {
    return (
      selectedMetric === "CONTEXT_SWITCHING" &&
      data?.every(timeRecord => {
        return timeRecord.teamScores["CONTEXT_SWITCHING"] === null;
      })
    );
  }, [selectedMetric, data]);

  const chart = useMemo(() => {
    return {
      config: generateConfig({
        data: data?.sort((d1, d2) => d1.dateEnd.localeCompare(d2.dateEnd)),
        theme,
        selectedMetric,
        chartView,
        teamMembers,
        canSeeAlwaysOnData
      }),
      id: "sprint-health-chart",
      isFetchingData
    };
  }, [
    canSeeAlwaysOnData,
    chartView,
    data,
    isFetchingData,
    teamMembers,
    selectedMetric,
    theme
  ]);

  return (
    <div
      onMouseEnter={onChartMouseOver}
      onMouseLeave={onChartMouseOut}
      className={className}
      data-testid={thisTestId}
    >
      <ErrorBoundary>
        {isNullContextSwitching ? (
          <ContextSwitchingWarningContainer>
            <WarningContainerSection>
              <StyledIcon icon="lock" />
              <span>
                Someone on your team turned off all the metrics that make up
                Context Switching.
              </span>
            </WarningContainerSection>
            <WarningContainerSection>
              <span>
                To get this metric back, turn on any of all of the following
                factors in{" "}
                <RouteLink
                  name="tdd-context-switching-settings-link"
                  to="/settings"
                >
                  Team Settings
                </RouteLink>
                :
              </span>
            </WarningContainerSection>
            <WarningContainerSection>
              <SubfactorsList>
                <li>Chat Channels with Interruptions</li>
                <li>Meetings with 2+ Teams</li>
                <li>PR Repos</li>
                <li>Jira Epics</li>
                <li>Top Priority Bugs</li>
              </SubfactorsList>
            </WarningContainerSection>
          </ContextSwitchingWarningContainer>
        ) : (
          <Chart
            chart={chart}
            className={className}
            testId={`${thisTestId}-sprint-health-chart`}
            theme={theme}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default withTheme(ChartPeopleHealth);
