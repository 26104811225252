import React from "react";
import { isEmpty } from "lodash";
import * as Sentry from "@sentry/react";

// components
import { Route } from "react-router-dom";
import Auth0Wrapper from "../Auth0Wrapper/Auth0Wrapper";
import AuthPage from "../AuthPage/AuthPage";
import OktaWrapper from "../OktaWrapper/OktaWrapper";
import UplevelUi from "../UplevelUi/UplevelUi";

// constants
import { authProviders } from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

const App = () => {
  const authParams = useSelector(
    (state: AppStateInterface) => state.auth.authParams
  );

  // return just the minimal route needed to authenticate if no auth params are
  // in state
  if (isEmpty(authParams?.clientId)) {
    return (
      <UplevelUi testId="client-dashboard">
        <Route>
          <AuthPage />
        </Route>
      </UplevelUi>
    );
  }

  return authParams.authProvider === authProviders.AUTH0 ? (
    <Auth0Wrapper />
  ) : (
    <OktaWrapper />
  );
};

export default Sentry.withProfiler(App);
