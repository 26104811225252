import React, { ReactElement } from "react";
import styled, { css } from "styled-components/macro";
import { isNil, uniq, flatten } from "lodash";

// components
import ChartSprintProgress from "../ChartSprintProgress/ChartSprintProgress";
import WorkMetadataActivitiesKey from "../WorkMetadataActivitiesKey/WorkMetadataActivitiesKey";
import WorkProgressBreakout from "../WorkProgressBreakout/WorkProgressBreakout";

// constants
import {
  workMetadataActivityTypes,
  workMetadataStatusTypes
} from "../../constants/constants";

// interfaces
import { SprintProgressActivityInterface } from "../../interfaces/sprint-metadata";

const Rollup = styled.ul<{ numColumns: number }>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.numColumns}, 1fr)`};
  gap: 4rem;
`;
const RollupCategory = styled.li`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;
const RollupCategoryHeader = styled.header<{
  isSprintMode: boolean;
  rollup: string;
}>`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  margin-bottom: 1rem;
  ${props => {
    if (props.isSprintMode) {
      return css`
        &::before {
          content: "";
          display: block;
          height: 1.5rem;
          margin-right: 1rem;
          width: 1.5rem;
          background: ${props.rollup === workMetadataStatusTypes.OPEN
            ? props.theme.colors.all.jean
            : props.rollup === workMetadataStatusTypes.DONE
            ? props.theme.colors.all.petra
            : props.theme.colors.all.banshee};
        }
      `;
    }
  }};
`;
const KeyWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  border-top: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.wolverine}`};
  margin-top: 2.2rem;
  padding-top: 1rem;
`;
const ChartWrapper = styled.figure`
  margin: 0;
`;

const SecondaryActivitiesHeader = styled.h4`
  margin: 2rem 0 1rem 0;
  padding: 0;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  padding-bottom: 1rem;
`;
const HeaderText = styled.span<{ visible: boolean }>`
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`;

// typescript props
type Props = {
  className?: string;
  data: Array<SprintProgressActivityInterface>;
  isSprintMode: boolean;
  testId?: string;
};

const WorkProgress = ({
  className,
  data,
  isSprintMode,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-sprint-progress-with-breakout`;
  const displayCategories = isSprintMode
    ? data
    : data.filter(d => d.status !== "OPEN");
  return (
    <ChartWrapper className={className} data-testid={thisTestId}>
      {isSprintMode ? (
        <ChartSprintProgress data={data} testId={thisTestId} />
      ) : null}
      <figcaption>
        <Rollup numColumns={displayCategories.length}>
          {displayCategories.map((d, index) => (
            <RollupCategory key={d.status}>
              <RollupCategoryHeader
                isSprintMode={isSprintMode}
                rollup={d.status}
              >
                {d.displayTitle}
              </RollupCategoryHeader>
              <WorkProgressBreakout
                activityTypes={[
                  workMetadataActivityTypes.ISSUE,
                  workMetadataActivityTypes.BUG,
                  workMetadataActivityTypes.SUB_TASK
                ]}
                category={d}
                testId={thisTestId}
              />
              <div>
                <SecondaryActivitiesHeader>
                  <HeaderText visible={!index}>PRs</HeaderText>
                </SecondaryActivitiesHeader>
                <WorkProgressBreakout
                  activityTypes={[workMetadataActivityTypes.PR]}
                  category={d}
                  testId={thisTestId}
                />
              </div>
            </RollupCategory>
          ))}
        </Rollup>
        <KeyWrapper>
          <WorkMetadataActivitiesKey
            testId={thisTestId}
            visibleActivities={uniq(
              flatten(
                displayCategories.map(d =>
                  Object.keys(d.breakdownByActivityType)
                )
              )
            )}
            showStoryPoints={displayCategories.some(d =>
              Object.values(d.breakdownByActivityType).some(
                t => !isNil(t.storyPoints)
              )
            )}
          />
        </KeyWrapper>
      </figcaption>
    </ChartWrapper>
  );
};

export default WorkProgress;
