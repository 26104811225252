import React, { ReactElement } from "react";
import { has } from "lodash";

// components
import PrWorkflowChartStatusTooltip from "../PrWorkflowChartStatusTooltip/PrWorkflowChartStatusTooltip";

// interfaces
import {
  PrWorkflowChartActivityPoint,
  PrWorkflowChartStatusPoint
} from "../../interfaces/charts";
import PrWorkflowChartActivityTooltip from "../PrWorkflowChartActivityTooltip/PrWorkflowChartActivityTooltip";
import { WorkItemInterface } from "../../interfaces/work-items";
import { UserReportWithMetadataInterface } from "../../interfaces/user";

// typescript props
type Props = {
  context?: {
    point: PrWorkflowChartStatusPoint | PrWorkflowChartActivityPoint;
  };
  workItems: Array<WorkItemInterface>;
  team: UserReportWithMetadataInterface[] | undefined;
};

const PrWorkflowChartSeriesTooltip = ({
  context,
  workItems,
  team
}: Props): ReactElement | null => {
  const thisTestId = "pr-workflow-chart-series-tooltip";
  if (!context) {
    return null;
  }

  const point = context.point;
  return !!has(point.history, "status") ? (
    <PrWorkflowChartStatusTooltip
      point={point as PrWorkflowChartStatusPoint}
      workItems={workItems}
      testId={thisTestId}
      team={team}
    />
  ) : (
    <PrWorkflowChartActivityTooltip
      point={point as PrWorkflowChartActivityPoint}
      testId={thisTestId}
    />
  );
};

export default PrWorkflowChartSeriesTooltip;
