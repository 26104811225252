// utils
import { requestUser } from "../../utils/user";

type Props = {
  accessToken: string;
  tenantId: number;
};
export default async function fetchUserData({ accessToken, tenantId }: Props) {
  return await requestUser({
    accessToken,
    tenantId
  });
}
