import React from "react";
import styled from "styled-components/macro";
import { isNull, sortBy } from "lodash";

// components
import Expander from "../Expander/Expander";
import ChartSprintHealthTrendAnalysis from "../ChartSprintHealthTrendAnalysis/ChartSprintHealthTrendAnalysis";

// interfaces
import {
  FilteredSprintHealthInterface,
  SprintHealthDataPointInterface,
  SprintHealthInterface
} from "../../interfaces/sprint-metadata";
import { TimerangeMetadataInterface } from "../../interfaces/constants";

// utils
import { filterSprintHealthFactorsToRange } from "../../utils/sprints";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import { getFilteredSprintHealthData } from "../../utils/health-metrics";

// styled components
const Container = styled.div`
  font-size: 1.1rem;
`;
const StyledExpander = styled(Expander)`
  font-size: inherit;

  & header {
    display: inline-flex;
  }
`;
const HeaderWrapper = styled.span`
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  margin-right: 1rem;
`;

// typescript props
type Props = {
  className?: string;
  data: SprintHealthInterface;
  isFetchingData?: boolean;
  range: Array<number>;
  sprint: TimerangeMetadataInterface;
  testId?: string;
};

const RetrosTrendAnalysis = ({
  className,
  data,
  isFetchingData = false,
  range,
  sprint,
  testId = "testId"
}: Props): React.ReactElement | null => {
  const thisTestId = `${testId}-retros-trend-analysis`;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const thisSprint = data[sprint.id];

  if (isNull(thisSprint)) {
    return null;
  }

  const filteredData = getFilteredSprintHealthData(
    data
  ) as FilteredSprintHealthInterface;

  const sortedData = sortBy(Object.values(filteredData), "startAt");
  const hasPeopleHealthData = flags?.["people-health-insights"];

  const factors = filterSprintHealthFactorsToRange(
    [
      ...Object.entries(
        hasPeopleHealthData ? thisSprint.peopleHealthFactors : {}
      ),
      ...Object.entries(thisSprint.projectHealthFactors)
    ],
    range
  );

  return !!factors.length ? (
    <Container className={className} data-testid={thisTestId}>
      <StyledExpander
        header={<HeaderWrapper>Health Trends</HeaderWrapper>}
        testId={thisTestId}
      >
        <ChartSprintHealthTrendAnalysis
          data={sortedData}
          isFetchingData={isFetchingData}
          factors={factors}
          testId={thisTestId}
        />
      </StyledExpander>
    </Container>
  ) : null;
};

export default RetrosTrendAnalysis;
