import {
  flatten,
  flattenDeep,
  isEmpty,
  isNull,
  isUndefined,
  snakeCase,
  sum,
  uniq,
  sumBy,
  defaults,
  isFinite
} from "lodash";
import { NIL as NIL_UUID } from "uuid";

// constants
import {
  groupByTypes,
  pullRequestCalloutTypes,
  reservedEpics,
  reservedEpicsMetadata,
  sprintMetadataWorkTypes,
  urlParamsBaseDefaultValues,
  workMetadataActivityTypes,
  workMetadataStatusTypes
} from "../constants/constants";

// interfaces
import {
  BreakdownByActivityType,
  SprintProgressActivityInterface
} from "../interfaces/sprint-metadata";
import {
  ActivityInterface,
  WorkItemLeveledInterface,
  WorkItemInterface,
  GroupInterface
} from "../interfaces/work-items";
import { UrlParamsInterface } from "../interfaces/url-params";

export const getWorkItemsActivitiesTotals = (
  activities?: Array<ActivityInterface>
): Array<SprintProgressActivityInterface> => {
  if (!activities) {
    return [];
  }
  const totalWeight = sum(
    activities
      .filter(
        activity =>
          snakeCase(activity.activityType).toUpperCase() !==
          workMetadataActivityTypes.PR
      )
      .map(activity => activity.score)
  );

  return [
    workMetadataStatusTypes.OPEN,
    workMetadataStatusTypes.IN_PROGRESS,
    workMetadataStatusTypes.DONE
  ].map(categoryKey => {
    const breakdownByActivityType = activities.reduce(
      (accumulated: BreakdownByActivityType, current: ActivityInterface) => {
        const activityKey = snakeCase(current.activityType).toUpperCase();
        const item = current.breakdownByStatus.find(
          b => b.status === categoryKey
        );
        accumulated[activityKey] = defaults(item || {}, {
          score: 0,
          count: 0
        });
        return accumulated;
      },
      {}
    );
    const jiraItems = Object.entries(breakdownByActivityType)
      .filter(([k]) => k !== workMetadataActivityTypes.PR)
      .map(([, v]) => v);
    return {
      displayTitle:
        categoryKey === workMetadataStatusTypes.OPEN
          ? "To-Do"
          : categoryKey === workMetadataStatusTypes.DONE
          ? "Done"
          : "In Progress",
      status: categoryKey,
      breakdownByActivityType,
      percentage: (sumBy(jiraItems, "score") / totalWeight) * 100
    };
  });
};

export const flattenWorkItems = ({
  workItems,
  rootWorkItemId,
  level
}: {
  workItems: Array<WorkItemInterface>;
  rootWorkItemId: string | null;
  level: number;
}): Array<WorkItemLeveledInterface> => {
  return flattenDeep(
    workItems.map(workItem => {
      return [
        {
          ...workItem,
          level,
          rootWorkItemId
        }
      ].concat(
        isEmpty(workItem.childWorkItems)
          ? []
          : flattenWorkItems({
              workItems: workItem.childWorkItems,
              rootWorkItemId: isNull(rootWorkItemId)
                ? workItem.id
                : rootWorkItemId,
              level: level + 1
            })
      );
    })
  );
};

export const getWorkItemsRequestParamsCallouts = (callouts: Array<string>) => {
  // replace "stuck" with the actual stalled values to request
  if (callouts.includes(pullRequestCalloutTypes.STUCK)) {
    return [
      ...callouts.filter(c => c !== pullRequestCalloutTypes.STUCK),
      ...Object.values(pullRequestCalloutTypes).filter(c =>
        c.startsWith("STALLED")
      )
    ];
  }
  return callouts;
};

export const getWorkItemsRequestParamsEpicId = ({
  groupBy,
  groupId
}: {
  groupBy: string;
  groupId?: string;
}) =>
  groupBy === groupByTypes.EPIC
    ? !!groupId
      ? Object.values(reservedEpics).includes(groupId)
        ? NIL_UUID
        : groupId
      : null
    : null;

export const getWorkItemsRequestParamsFromUrlParams = (
  urlParams: UrlParamsInterface,
  groupId?: string
) => {
  // deep clone so we can delete values
  const workItemsRequestParams = JSON.parse(JSON.stringify(urlParams));
  const epicId = getWorkItemsRequestParamsEpicId({
    groupBy: workItemsRequestParams.groupBy,
    groupId
  });
  const usersToRequest =
    workItemsRequestParams.groupBy === groupByTypes.USER && !!groupId
      ? urlParams.team?.teamMembers
          .filter(m => m.id === Number(groupId))
          .map(m => m.email) || []
      : urlParams.selectedUser === null || urlParams.selectedUser === "team"
      ? []
      : [urlParams.selectedUser];

  if (isNull(workItemsRequestParams.sprintId)) {
    delete workItemsRequestParams.sprintId;
    delete workItemsRequestParams.otherSprintItems;
    delete workItemsRequestParams.typesOfWork;
  } else {
    workItemsRequestParams.otherSprintItems = getWorkItemsRequestParamsOtherSprintItems(
      {
        groupBy: workItemsRequestParams.groupBy,
        otherSprintItems: workItemsRequestParams.otherSprintItems,
        users: usersToRequest
      }
    );
    workItemsRequestParams.typesOfWork = getWorkItemsRequestParamsTypesOfWork({
      groupId,
      typesOfWork: workItemsRequestParams.typesOfWork
    });
  }
  if (!!epicId) {
    workItemsRequestParams.epicId = epicId;
  }
  if (isUndefined(groupId)) {
    workItemsRequestParams.page = workItemsRequestParams.groupsList.page;
  } else {
    workItemsRequestParams.page =
      workItemsRequestParams.groupDetails?.page ||
      urlParamsBaseDefaultValues.groupDetails.page;
    workItemsRequestParams.sort =
      workItemsRequestParams.groupDetails?.sort?.map(
        (column: { id: string; desc: boolean }) => {
          return {
            column:
              column.id === "name" &&
              workItemsRequestParams.epicId !== reservedEpics.UNLINKED_PRS
                ? "displayId"
                : column.id,
            order: column.desc ? "DESC" : "ASC"
          };
        }
      ) || urlParamsBaseDefaultValues.groupDetails.sort;
  }

  workItemsRequestParams.callouts = getWorkItemsRequestParamsCallouts(
    workItemsRequestParams.callouts
  );
  workItemsRequestParams.projectIds = getWorkItemsRequestParamsProjectIds({
    groupId,
    groupBy: workItemsRequestParams.groupBy,
    projectIds: workItemsRequestParams.projectIds
  });
  workItemsRequestParams.users = usersToRequest;
  workItemsRequestParams.workItemTypes = getWorkItemsRequestParamsWorkItemTypes(
    {
      groupId,
      workItemTypes: workItemsRequestParams.workItemTypes
    }
  );

  delete workItemsRequestParams.board;
  delete workItemsRequestParams.reportGroups;
  delete workItemsRequestParams.boardId;
  delete workItemsRequestParams.groupDetails;
  delete workItemsRequestParams.groupsList;
  delete workItemsRequestParams.roles;
  delete workItemsRequestParams.team;
  delete workItemsRequestParams.teams;
  delete workItemsRequestParams.teamLead;
  delete workItemsRequestParams.teamLeadId;
  delete workItemsRequestParams.timerange;
  delete workItemsRequestParams.timerangeId;
  delete workItemsRequestParams.visibleRepositories;

  return workItemsRequestParams;
};

export const getWorkItemsRequestParamsOtherSprintItems = ({
  groupBy,
  otherSprintItems,
  users
}: {
  groupBy: string;
  otherSprintItems: Array<string>;
  users: Array<string>;
}) =>
  groupBy === groupByTypes.USER
    ? urlParamsBaseDefaultValues.otherSprintItems
    : isEmpty(users) && isEmpty(otherSprintItems)
    ? []
    : otherSprintItems;

export const getWorkItemsRequestParamsProjectIds = ({
  groupId,
  groupBy,
  projectIds
}: {
  groupId?: string;
  groupBy: string;
  projectIds: Pick<UrlParamsInterface, "projectIds">;
}) =>
  !!groupId
    ? groupBy === groupByTypes.EPIC
      ? groupId === reservedEpics.UNLINKED_PRS
        ? [NIL_UUID]
        : projectIds
      : groupBy === groupByTypes.PROJECT
      ? [groupId]
      : projectIds
    : projectIds;

export const getWorkItemsRequestParamsTypesOfWork = ({
  groupId,
  typesOfWork
}: {
  groupId?: string;
  typesOfWork: Pick<UrlParamsInterface, "typesOfWork">;
}) =>
  groupId === reservedEpics.UNLINKED_PRS
    ? [sprintMetadataWorkTypes.NOT_ATTACHED_TO_SPRINT]
    : typesOfWork;

export const getWorkItemsRequestParamsWorkItemTypes = ({
  groupId,
  workItemTypes
}: {
  groupId?: string;
  workItemTypes: Pick<UrlParamsInterface, "workItemTypes">;
}) =>
  groupId === reservedEpics.UNLINKED_PRS
    ? urlParamsBaseDefaultValues.workItemTypes
    : workItemTypes;

function mapWorkItemRecursive(
  workItem: WorkItemInterface,
  parents: Array<WorkItemInterface>
): Array<WorkItemInterface> {
  if (workItem.workItem === "PULL_REQUEST")
    return [{ ...workItem, childWorkItems: parents }];
  if (!workItem.childWorkItems.length) return [];

  return flatten(
    workItem.childWorkItems.map(c =>
      mapWorkItemRecursive(c, [...parents, { ...workItem, childWorkItems: [] }])
    )
  );
}

export const invertPRWorkItems = (
  workItems: Array<WorkItemInterface>
): Array<WorkItemInterface> => {
  if (!workItems.length) return [];
  const workItemsById: Map<string, WorkItemInterface> = flatten(
    workItems.map(wi => mapWorkItemRecursive(wi, []))
  ).reduce<Map<string, WorkItemInterface>>((workItemsById, pr) => {
    workItemsById.set(pr.id, {
      ...pr,
      childWorkItems: uniq(
        // the same PR can show up multiple times with different linked jira tickets, so here
        // we reduce to a single instance of the pr with jira tickets across all instances
        // as child items
        pr.childWorkItems.concat(workItemsById.get(pr.id)?.childWorkItems || [])
      )
    });
    return workItemsById;
  }, new Map<string, WorkItemInterface>());
  return Array.from(workItemsById.values());
};

export const sanitizeValue = (
  valueToShow: number | string | null
): number | string => {
  return isFinite(valueToShow) ||
    (typeof valueToShow === "string" && valueToShow !== "Infinity")
    ? (valueToShow as number | string)
    : "N/A";
};

export const getTeamRosterFromGroupsList = (
  data: Array<GroupInterface> = []
): Array<string> =>
  uniq(
    flatten(
      data
        ?.filter(
          (epic: GroupInterface) =>
            epic.magicRow !==
            reservedEpicsMetadata[reservedEpics.UNLINKED_PRS].magicRowId
        )
        .map(epic => epic.owners)
    ).map(owner => owner.displayName)
  ).sort();
