import React from "react";
import { get, flatten } from "lodash";
import styled from "styled-components/macro";

// components
import Icon from "../Icon/Icon";
import InfoPopup from "../InfoPopup/InfoPopup";
import PersonTile from "../PersonTile/PersonTile";
import RouteLink from "../RouteLink/RouteLink";
import SupportEmailLink from "../SupportEmailLink/SupportEmailLink";

// constants
import { groupByTypes } from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useAllocationsData } from "../../hooks/useAllocationsData";
import { useUserSettings } from "../../hooks/useUserSettings";
import { useUrlParams } from "../../hooks/useUrlParams";
import { useTeamDeepDiveData } from "../../hooks/useTeamDeepDiveData";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import {
  UserReportWithMetadataInterface,
  Allocations
} from "../../interfaces/user";

// utils
import { getTeamNameFromTeam, sortTeam } from "../../utils/teams";
import { UserDeepDiveData } from "../../interfaces/team-deep-dive";

// styled components
const Container = styled.section`
  margin-top: 3rem;
`;
const ContentWrapper = styled.section`
  padding-bottom: 2rem;
`;
const Team = styled.ul`
  display: grid;
  gap: ${props => props.theme.grid.gap};
  grid-template-columns: repeat(4, 1fr);
`;
const StyledTextHeader = styled.h4`
  align-items: flex-end;
  display: flex;
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  margin-bottom: 2rem;
`;
const TitleTextWrapper = styled.div`
  align-items: center;
  display: flex;
`;
const TitleText = styled.span`
  margin-right: 1rem;
`;
const CreateTeamLink = styled(RouteLink)`
  margin-left: auto;
  display: flex;
  color: ${props => props.theme.colors.all.wolverine};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  &.hover {
    color: ${props => props.theme.colors.all.auroraTeal};
  }
`;
const CreateTeamText = styled.div`
  color: inherit;
  vertical-align: baseline;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;
const StyledIcon = styled(Icon)`
  margin-left: 1rem;
  color: inherit;
`;
const PopupHeader = styled.h4`
  font-family: ${props => props.theme.fonts.header.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.header.sizes.xs};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
  margin: 0;
`;
const PopupContent = styled.div`
  font-size: ${props => props.theme.fonts.header.sizes.xs};
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
};

type Signal = {
  status: string | null | number;
  items: string[] | { [key: string]: string }[];
};

type UserTileData = {
  uplevelUserId: number;
  signals: {
    ALWAYS_ON: Signal[];
    CHAT_INTERRUPTIONS: Signal[];
    CONTEXT_SWITCHING: Signal[];
    PULL_REQUEST: Signal[];
    DEEP_WORK: Signal[];
  };
};

const YourTeam = ({
  className,
  testId = "testId"
}: Props): JSX.Element | null => {
  const thisTestId = `${testId}-your-team`;
  const tenantId = useSelector((state: AppStateInterface) =>
    get(state, "auth.authParams.tenantId")
  );
  const user = useSelector((state: AppStateInterface) => state.user);
  const { urlParams } = useUrlParams();
  const activeTeam = urlParams.team;

  const { data: userSettings } = useUserSettings();
  const defaultJiraGroupBy = userSettings?.defaultJiraGroupBy;
  const {
    data: peopleHealthData,
    isLoading: isFetchingTilesData
  } = useTeamDeepDiveData(activeTeam?.teamMembers);
  const {
    data: allocationsData,
    isLoading: isFetchingAllocationsData
  } = useAllocationsData();

  if (!activeTeam?.teamMembers?.length) {
    return null;
  }
  const teamHealthData: UserTileData[] | [] = !!peopleHealthData
    ? peopleHealthData[peopleHealthData.length - 1].peopleScores?.map(
        (person: UserDeepDiveData) => {
          return {
            uplevelUserId: person?.user,
            signals: {
              ALWAYS_ON: [
                {
                  status: person?.ALWAYS_ON || null,
                  items: [""]
                }
              ],
              CONTEXT_SWITCHING: [
                {
                  status: person?.CONTEXT_SWITCHING?.TOTAL?.bucket || null,
                  items: [""]
                }
              ],
              DEEP_WORK: [
                {
                  status: person?.DEEP_WORK?.HOURS?.TOTAL,
                  items: [""]
                }
              ],
              CHAT_INTERRUPTIONS: [
                {
                  status: person?.CHAT_INTERRUPTIONS?.TOTAL,
                  items: [""]
                }
              ],
              PULL_REQUEST:
                Object.entries(person?.PULL_REQUEST || {}).map(
                  ([key, value]) => {
                    return {
                      status: key,
                      items: value
                    };
                  }
                ) || []
            }
          };
        }
      )
    : [];
  // for user tiles, adjoining the signals and allocations with their user for more logical grouping
  const teamWithMetadata = activeTeam.teamMembers.map((member, index) => {
    const memberTileData: UserTileData[] = teamHealthData.filter(
      (memberSignals: { uplevelUserId: number }) =>
        memberSignals?.uplevelUserId === member.id
    );

    const memberAllocationsData: Allocations = (allocationsData || []).find(
      (allocationSet: { user: number }) => allocationSet.user === member.id
    ) || { projects: [], user: 0 };
    const flattenedAllocations =
      memberAllocationsData && memberAllocationsData.projects
        ? defaultJiraGroupBy === groupByTypes.EPIC
          ? flatten(
              memberAllocationsData.projects.map(
                (project: any) => project.epics
              )
            )
          : memberAllocationsData.projects.map((project: any) => {
              return {
                allocation: project.allocation,
                epicId: project.projectId,
                epicName: project.projectName
              };
            })
        : [];
    return {
      ...member,
      signals:
        memberTileData[0] && memberTileData[0].signals
          ? memberTileData[0].signals
          : {},
      tenantId,
      allocations: !!flattenedAllocations ? flattenedAllocations : []
    };
  }) as UserReportWithMetadataInterface[];

  return (
    <Container className={className} data-testid={thisTestId}>
      <StyledTextHeader>
        <TitleTextWrapper>
          <TitleText data-heap-redact-text="true">
            Team Insights: {getTeamNameFromTeam(activeTeam)}
          </TitleText>
          <InfoPopup
            action="click"
            header={<PopupHeader>Whose info am I seeing below?</PopupHeader>}
            content={
              <PopupContent>
                <p>
                  We&rsquo;re pulling project allocation, callouts, and insights
                  for people on your team&mdash;this does not include cross
                  collaborators or other people on your sprint.
                </p>
                <p>
                  Please{" "}
                  <SupportEmailLink
                    content="contact support"
                    name="your-team"
                    testId={thisTestId}
                  />{" "}
                  if there are any changes to your team that are not reflected
                  below.
                </p>
              </PopupContent>
            }
            name="your-team"
            testId={thisTestId}
          />
        </TitleTextWrapper>
        <CreateTeamLink name={"settings-link"} to={"/settings"}>
          <CreateTeamText>create a team</CreateTeamText>
          <StyledIcon icon={"users-cog"} />
        </CreateTeamLink>
      </StyledTextHeader>
      <ContentWrapper>
        <Team>
          {sortTeam(teamWithMetadata, user).map((person, index) => (
            <li key={index}>
              <PersonTile
                person={person}
                isFetchingData={
                  isFetchingTilesData || isFetchingAllocationsData
                }
                testId={person.email}
              />
            </li>
          ))}
        </Team>
      </ContentWrapper>
    </Container>
  );
};

export default YourTeam;
