import React from "react";
import styled, { css } from "styled-components/macro";

// interfaces
import { RadioCheckboxOptionsInterface } from "../../interfaces/controls";

// styled components
const HiddenInput = styled.input`
  margin-right: -1rem;
  opacity: 0;
`;
const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  vertical-align: middle;
`;
const DisplayInput = styled.span<{ checked: boolean }>`
  flex: 0 0 1.75rem;
  height: 1.75rem;
  margin-right: 1rem;
  position: relative;
  align-items: center;
  border-radius: 50%;
  ${HiddenInput}:focus + & {
    outline: 5px auto -webkit-focus-ring-color;
  }
  ${props => {
    return props.checked
      ? css`
          border: ${props => `0.2rem solid ${props.theme.colors.all.storm}`};
          background: ${props => props.theme.colors.all.marvelMint} no-repeat;
        `
      : css`
          background: ${props => props.theme.colors.all.jean} no-repeat;
        `;
  }};
`;
const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  line-height: 1.3;
`;

// typescript props
type Props = {
  className?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
} & RadioCheckboxOptionsInterface;

const CustomRadioInput = ({
  className,
  disabled = false,
  label,
  name,
  onChange,
  selected,
  testId = "testId",
  value
}: Props) => {
  return (
    <Label className={className} htmlFor={testId} data-testid={testId}>
      <HiddenInput
        disabled={disabled}
        checked={selected}
        data-testid={`${testId}-actual-input`}
        id={testId}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <DisplayInput
        aria-checked={selected}
        aria-disabled={disabled}
        aria-labelledby={testId}
        checked={selected}
        role="radio"
        tabIndex={-1}
      />
      <TextWrapper data-testid={`${testId}-label-text`}>{label}</TextWrapper>
    </Label>
  );
};

export default CustomRadioInput;
