import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { kebabCase } from "lodash";

// components
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";

// hooks
import { useTracking } from "../../hooks/useTracking";

const MAX_MEMBERS_PER_COLUMN = 10;

// styled components
const StyledEye = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
  margin-left: 1rem;
`;
const List = styled.ul<{ names: number }>`
  && {
    padding-left: 0;
  }
  columns: ${props => `${Math.ceil(props.names / MAX_MEMBERS_PER_COLUMN)}`};
  gap: 2rem;
`;
const StyledListItem = styled.li`
  list-style-type: none;
`;

// typescript props
type Props = {
  className?: string;
  header: React.ReactNode;
  names?: Array<string>;
  testId?: string;
};

export const TeamRoster = ({
  className,
  header,
  names = [],
  testId = "testId"
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-team-roster`;
  const { trackEvent } = useTracking();

  function onShowTooltip(e: React.MouseEvent<HTMLElement>) {
    e.persist();
    trackEvent({
      e,
      label: "team-roster",
      value: "showed team roster from hover"
    });
  }

  return !!names.length ? (
    <Tooltip
      className={className}
      onShowTooltip={onShowTooltip}
      testId={testId}
      trigger={
        <div data-testid={`${thisTestId}-trigger`}>
          {header}
          {<StyledEye icon="eye" />}
        </div>
      }
      popupContent={
        <List names={names.length}>
          {names.map(name => (
            <StyledListItem key={kebabCase(name)} data-heap-redact-text="true">
              {name}
            </StyledListItem>
          ))}
        </List>
      }
      placement="bottom"
    />
  ) : null;
};

export default TeamRoster;
