import React from "react";
import styled from "styled-components/macro";

// constants
import { sprintStates } from "../../constants/constants";
import { useProjectsAndBoardsData } from "../../hooks/useProjectsAndBoardsData";

// interfaces
import { TimerangeMetadataInterface } from "../../interfaces/constants";
import { BoardInterface } from "../../interfaces/sprint-metadata";

// styled components
const Container = styled.span`
  display: block;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const SprintName = styled.span`
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
`;
const BoardName = styled.span`
  display: block;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  timerange: TimerangeMetadataInterface;
};

const TimeRangeSelectorSprintLabel = ({
  className,
  testId = "testId",
  timerange
}: Props) => {
  const thisTestId = `${testId}-time-range-selector-sprint-label`;
  const { boardId, displayName, state: sprintState } = timerange;
  const { data: projectsAndBoardsData } = useProjectsAndBoardsData();
  const timerangeBoard = projectsAndBoardsData?.boards.find(
    (b: BoardInterface) => b.boardId === boardId
  );

  const labelText = `${
    sprintState === sprintStates.ACTIVE
      ? "This Sprint"
      : sprintState === sprintStates.FUTURE
      ? "Future Sprint"
      : "Previous Sprint"
  } - ${displayName}`;

  return (
    <Container
      className={className}
      data-testid={thisTestId}
      data-heap-redact-text="true"
    >
      <SprintName>{labelText}</SprintName>
      {!!timerangeBoard ? (
        <BoardName>{timerangeBoard.boardName}</BoardName>
      ) : null}
    </Container>
  );
};

export default TimeRangeSelectorSprintLabel;
