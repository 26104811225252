import React from "react";
import styled from "styled-components/macro";

const Triangle = styled.div<{ baseWidth: number }>`
  width: 0;
  height: 0;
  border-left: ${props => `${props.baseWidth * 0.6}px solid transparent`};
  border-right: ${props => `${props.baseWidth * 0.6}px solid transparent`};
  border-bottom: ${props =>
    `${props.baseWidth}px solid ${props.theme.colors.all.rogue}`};
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: start;
`;

const AlertCount = styled.strong<{ baseWidth: number }>`
  /* needsto be proportional to the base width to fit correctly in the triangle */
  font-size: ${props => `${props.baseWidth * 0.75}px`};
`;

type Props = {
  baseWidth: number; // baseWidth is the bottom (base) width of the triangle in pixels
  alertCount: number;
};

const WarningTriangle = ({ baseWidth, alertCount }: Props): JSX.Element => {
  return (
    <Triangle baseWidth={baseWidth} data-testid="alert-triangle">
      <AlertCount baseWidth={baseWidth}>{alertCount}</AlertCount>
    </Triangle>
  );
};

export default WarningTriangle;
