import React from "react";
import styled, { css } from "styled-components/macro";
import RouteLink from "../RouteLink/RouteLink";

// styled components
const List = styled.ul<{ numItems: number }>`
  display: flex;
  justify-content: flex-start;
`;
const ListItem = styled.li`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  padding: 0 1rem 0 0;

  &:not(:nth-of-type(1)) {
    border-left: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    padding: 0 1rem;
  }
`;
const Base = css<{
  isActive: boolean;
  isDisabled?: boolean;
}>`
  border-bottom: ${props =>
    `${props.theme.borders.widths.lg} solid ${
      props.isActive ? props.theme.colors.all.wolverine : "transparent"
    }`};
  opacity: ${props => (props.isDisabled ? "0.3" : "1")};
  padding: 0 1.5rem;
`;
const Link = styled(RouteLink)`
  ${Base}
  display: inline-block;
  && {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
`;
const Placeholder = styled.div`
  ${Base}
`;

// typescript props
type Props = {
  className?: string;
  defaultTabId?: string;
  tabs: Array<{
    id: string;
    isDisabled?: boolean;
    path: string;
    title: React.ReactNode;
  }>;
  testId?: string;
};

const RouteLinkTabs = ({
  className,
  defaultTabId,
  tabs,
  testId = "testId"
}: Props): React.ReactElement | null => {
  const thisTestId = `${testId}-route-link-tabs`;
  // do a check to make sure the default tab id is actually in the tabs,
  // otherwise use the id of the first tab item
  const initialTabKey = !!tabs.length
    ? defaultTabId && !!tabs.find(t => t.id === defaultTabId)
      ? defaultTabId
      : tabs[0].id
    : null;

  return !!tabs.length ? (
    <nav className={className} data-testid={thisTestId}>
      <List numItems={tabs.length}>
        {tabs.map(t => (
          <ListItem key={t.id}>
            {t.isDisabled ? (
              <Placeholder
                data-testid={`${thisTestId}-${t.id}-tab`}
                isActive={t.id === initialTabKey}
                isDisabled={!!t.isDisabled}
              >
                {t.title}
              </Placeholder>
            ) : (
              <Link
                className="tabTrigger"
                data-testid={`${thisTestId}-${t.id}-tab`}
                isActive={t.id === initialTabKey}
                isDisabled={!!t.isDisabled}
                name={`${thisTestId}-${t.id}-tab`}
                to={t.path}
              >
                {t.title}
              </Link>
            )}
          </ListItem>
        ))}
      </List>
    </nav>
  ) : null;
};

export default RouteLinkTabs;
