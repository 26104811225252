import { AxiosResponse } from "axios";
import api from "./api";
import { kebabCase } from "lodash";
import { NIL } from "uuid";

// constants
import {
  groupByTypes,
  pageIds,
  pageMetadata,
  workDeepDiveSectionLabels
} from "../constants/constants";

// interfaces
import { OpMetricData } from "../interfaces/ops-metrics";
import { UserReportInterface } from "../interfaces/user";
import {
  EnhancedTeamMemberInterface,
  EpicDetailsInterface,
  GroupsListInterface,
  TeamMemberInterface,
  WorkItemsRequestParamsInterface
} from "../interfaces/work-items";
import { AnnotationsInterface } from "../interfaces/annotations";

// utils
import { getPageTitle } from "./router";
import { captureException } from "@sentry/react";

// typescript props
type BaseProps = {
  accessToken: string;
  tenantId: number;
};
type WorkItemsProps = BaseProps & {
  params: WorkItemsRequestParamsInterface;
};

type OperationalMetricsParams = BaseProps & {
  params: {
    startDate: string;
    endDate: string;
    teamId: string | null;
    sprintId: string | null;
  };
};

type AnnotationsProps = {
  accessToken: string;
  tenantId: number;
  params:
    | (WorkItemsRequestParamsInterface & { parentId?: string })
    | {
        users: Array<string>;
        context: { [key: string]: any };
        annotation: { [key: string]: any };
      };
};

export const requestWorkItems = async ({
  accessToken,
  params,
  tenantId
}: WorkItemsProps): Promise<EpicDetailsInterface | GroupsListInterface> => {
  const response: AxiosResponse<
    EpicDetailsInterface | GroupsListInterface
  > = await api.post<EpicDetailsInterface | GroupsListInterface>(
    `/${tenantId}/project-explorer/work-items`,
    params,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return response.data;
};

export const requestOperationalMetrics = async ({
  accessToken,
  params,
  tenantId
}: OperationalMetricsParams): Promise<OpMetricData> => {
  const response: AxiosResponse<OpMetricData> = await api.post<OpMetricData>(
    `/${tenantId}/ops-metrics`,
    params,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return response.data;
};

export const requestAnnotations = async ({
  accessToken,
  params,
  tenantId
}: WorkItemsProps): Promise<Array<AnnotationsInterface>> => {
  const response: AxiosResponse<Array<AnnotationsInterface>> = await api.post<
    Array<AnnotationsInterface>
  >(`/${tenantId}/project-explorer/user-annotations`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const writeAnnotations = async ({
  accessToken,
  params,
  tenantId
}: AnnotationsProps): Promise<any> => {
  if ("allUsers" in params && !params.allUsers.length) {
    return captureException(
      `Expected params with keys of "allUsers" as value type String[], but received ${JSON.stringify(
        params
      )}`
    );
  }
  const response = await api.put(`/${tenantId}/add-user-annotation`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const removeAnnotations = async ({
  accessToken,
  params,
  tenantId
}: {
  accessToken: string;
  params: {
    annotationId: string;
  };
  tenantId: number;
}): Promise<any> => {
  const response = await api.put(
    `/${tenantId}/remove-user-annotation`,
    params,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return response.data;
};

export const getWorkDeepDivePageTitle = (section: string): string => {
  const sectionTitle = workDeepDiveSectionLabels[section];
  const workDeepDiveTitle = pageMetadata[pageIds.WORK_DEEP_DIVE].title;
  return getPageTitle(
    `${!!sectionTitle ? `${sectionTitle} - ` : ""}${workDeepDiveTitle}`
  );
};

export const getEpicDetailsPageTitle = ({
  groupBy,
  workCategoryId,
  workCategoryName
}: {
  groupBy: string;
  workCategoryId: string | null;
  workCategoryName: string;
}): string => {
  const workDeepDiveTitle = pageMetadata[pageIds.WORK_DEEP_DIVE].title;
  const name =
    !!workCategoryId && groupBy === groupByTypes.EPIC && workCategoryId !== NIL
      ? workCategoryId
      : workCategoryName;
  return getPageTitle(`${name} - ${workDeepDiveTitle}`);
};

export const addTeamAttributes = (
  teamMember: TeamMemberInterface,
  userDetails: Array<UserReportInterface>
): EnhancedTeamMemberInterface => {
  const { displayName, uplevelUserId } = teamMember;
  const inTeamMember = userDetails.find(u => u.id === uplevelUserId);
  if (!inTeamMember) {
    return {
      ...teamMember,
      isCrossTeam: true,
      slackAvatar: null,
      teamMemberId: !!uplevelUserId
        ? uplevelUserId.toString()
        : kebabCase(displayName)
    };
  }
  return {
    ...teamMember,
    isCrossTeam: false,
    slackAvatar: inTeamMember.slackAvatar,
    teamMemberId: inTeamMember.id.toString()
  };
};
