import { useMemo } from "react";
import { flattenDeep, isEmpty, isUndefined } from "lodash";

// constants
import {
  namedTimerangeIds,
  pageIds,
  workDeepDiveSections
} from "../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { useUserSettings } from "./useUserSettings";
import { useFullOrganizationData } from "./useFullOrganizationData";
import { useActiveTeam } from "./useActiveTeam";
import { useProjectsAndBoardsData } from "./useProjectsAndBoardsData";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";
import { BoardInterface } from "../interfaces/sprint-metadata";
import { UrlParamsInterface } from "../interfaces/url-params";
import { TimerangeMetadataInterface } from "../interfaces/constants";

// utils
import {
  getAllUsers,
  getBoardId,
  getCallouts,
  getGroupBy,
  getOwnerContributor,
  getProjectIds,
  getShowRelatedItems,
  getSprintId,
  getOtherSprintItems,
  getStatus,
  getTeamId,
  getTeamLead,
  getTeamLeadId,
  getTypesOfWork,
  getUsers,
  getWorkItemTypes,
  getGroupsList,
  getGroupDetails,
  getTimerange,
  parseQuerystring,
  getSelectedUser,
  getRepositories,
  getVisibleRepositories,
  getRoles,
  getReportGroups,
  getTeams,
  getUrlParamsDefaultValues
} from "../utils/url-params";
import { getSprintTimerangesForBoard } from "../utils/timeranges";
import { useSprintMetadata } from "./useSprintMetadata";
import { getBoard } from "../utils/sprints";

export const useUrlParamsContextValues = (): UrlParamsInterface => {
  // hooks
  const { search } = window.location;
  const parsedQuerystring = useMemo(() => parseQuerystring(search), [search]);
  const match = useRouteMatch<{
    groupId?: string;
  }>(
    `/${pageIds.WORK_DEEP_DIVE}/${workDeepDiveSections.PROJECT_WORK}/:groupId`
  );
  const groupId = match?.params.groupId;
  const pageMatch = useRouteMatch<{
    pageId: string;
  }>(`/:pageId`);
  const pageId = pageMatch?.params.pageId;

  const activeTeam = useActiveTeam();
  const { data: projectsAndBoardsData } = useProjectsAndBoardsData();
  const { data: sprintMetadata } = useSprintMetadata(
    { type: "active-team", team: activeTeam },
    !!activeTeam?.useSprints && !activeTeam?.defaultBoardId
  );
  const { data: fullOrganizationData } = useFullOrganizationData();
  const { data: userSettings } = useUserSettings();
  const defaultJiraGroupBy = userSettings?.defaultJiraGroupBy;

  // selectors
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const session = useSelector((state: AppStateInterface) => state.session);
  const namedTimeranges = session.namedTimeranges;
  // this is a fake timerange meant only as a placeholder until the actual
  // timerange is set so that the ui can be unlocked.
  const today = namedTimeranges[namedTimerangeIds.TODAY];

  // variables
  const allBoards = projectsAndBoardsData?.boards;
  const querystringBoardId = parsedQuerystring.boardId as string | null;
  const activeBoard = useMemo(
    () =>
      getBoard({
        sprintMetadata,
        allBoards,
        boardId: querystringBoardId,
        team: activeTeam
      }),
    [activeTeam, allBoards, querystringBoardId, sprintMetadata]
  );
  const allSprintTimeranges: Array<TimerangeMetadataInterface> = useMemo(() => {
    return isEmpty(allBoards)
      ? []
      : flattenDeep(
          allBoards.map((b: BoardInterface) => getSprintTimerangesForBoard(b))
        );
  }, [allBoards]);

  return useMemo(() => {
    if (!!activeTeam && !isUndefined(activeBoard)) {
      const querystringTimerangeId = (parsedQuerystring.sprintId ||
        parsedQuerystring.timerangeId) as string | null;
      const timerange = getTimerange({
        allSprintTimeranges,
        board: activeBoard,
        endDate: parsedQuerystring.endDate as string | null,
        startDate: parsedQuerystring.startDate as string | null,
        timerangeId: querystringTimerangeId,
        namedTimeranges,
        team: activeTeam
      });
      const timerangeId = timerange.id;
      const endDate = timerange.end;
      const startDate = timerange.start;

      const sprintId = getSprintId(timerange);

      const allUsers = getAllUsers(activeTeam);
      const team = activeTeam;
      const teamId = getTeamId(activeTeam);
      const teamLeadId = getTeamLeadId(parsedQuerystring);
      const teamLead = getTeamLead({
        fullOrganizationData,
        teamLeadId
      });

      const board = activeBoard;
      const boardId = getBoardId(activeBoard);

      const groupBy = getGroupBy({
        defaultJiraGroupBy,
        parsedQuerystring,
        pageId
      });

      const reportGroups = getReportGroups(parsedQuerystring);

      const projectIds = getProjectIds(parsedQuerystring);

      const repositories = getRepositories(parsedQuerystring);

      const roles = getRoles(parsedQuerystring);

      const teams = getTeams(parsedQuerystring);

      const visibleRepositories = getVisibleRepositories(parsedQuerystring);

      const otherSprintItems = getOtherSprintItems(parsedQuerystring);

      const typesOfWork = getTypesOfWork(parsedQuerystring, timerange);

      const workItemTypes = getWorkItemTypes(parsedQuerystring);

      const callouts = getCallouts(parsedQuerystring);

      const ownerContributor = getOwnerContributor(
        parsedQuerystring,
        timerange
      );

      const groupsList = getGroupsList(parsedQuerystring);

      const groupDetails = getGroupDetails(parsedQuerystring);

      const showRelatedItems = getShowRelatedItems(parsedQuerystring);

      const status = getStatus(parsedQuerystring, timerange);

      const selectedUser = getSelectedUser(parsedQuerystring);

      return {
        allUsers,
        board,
        boardId,
        callouts,
        endDate,
        groupBy,
        groupDetails,
        groupsList,
        otherSprintItems,
        ownerContributor,
        projectIds,
        reportGroups,
        roles,
        showRelatedItems,
        sprintId,
        startDate,
        status,
        team,
        teamId,
        teamLeadId,
        teamLead,
        teams,
        tenantId,
        timerange,
        timerangeId,
        typesOfWork,
        workItemTypes,
        selectedUser,
        repositories,
        visibleRepositories
      };
    }
    return {
      ...getUrlParamsDefaultValues({ pageId }),
      allUsers: getAllUsers(activeTeam),
      board: activeBoard || null,
      boardId: getBoardId(activeBoard),
      endDate: today.end,
      startDate: today.start,
      team: activeTeam,
      teamId: getTeamId(parsedQuerystring),
      teamLeadId: getTeamLeadId(parsedQuerystring),
      teamLead: getTeamLead({
        fullOrganizationData,
        teamLeadId: getTeamLeadId(parsedQuerystring)
      }),
      users: getUsers(parsedQuerystring),
      tenantId,
      timerange: today,
      timerangeId: today.id
    };
  }, [
    activeBoard,
    activeTeam,
    allSprintTimeranges,
    defaultJiraGroupBy,
    fullOrganizationData,
    namedTimeranges,
    pageId,
    parsedQuerystring,
    tenantId,
    today
  ]);
};
