// hooks
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { requestProjectsAndBoards } from "../utils/sprints";

export const useProjectsAndBoardsData = (canRequest = true) => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const userHasUplevelAccount = useSelector(
    (state: AppStateInterface) => state.session.userHasUplevelAccount
  );

  // all projects and boards
  return useQuery(
    "projects-and-boards",
    () =>
      requestProjectsAndBoards({
        accessToken,
        tenantId
      }),
    {
      enabled:
        !!accessToken && !!tenantId && !!userHasUplevelAccount && !!canRequest,
      refetchOnWindowFocus: false
    }
  );
};
