import { isUndefined } from "lodash";
import React from "react";
import styled from "styled-components/macro";

// components
import EmptyState from "../EmptyState/EmptyState";
import Spinner from "../Spinner/Spinner";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";

// styled components
const Container = styled.div`
  width: 100%;
`;
const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 40rem;
  width: 100%;
`;

// typescript props
type Props = {
  className?: string;
  content: React.ReactNode;
  isNoDataState?: boolean;
  isSpinningState?: boolean;
  noDataContent?: React.ReactNode | null;
  testId?: string;
};

const LoadingStates = ({
  className,
  content,
  isNoDataState = false,
  isSpinningState = false,
  noDataContent,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-loading-states`;

  let displayContent;
  if (isSpinningState) {
    displayContent = (
      <SpinnerWrapper>
        <Spinner testId={thisTestId} />
      </SpinnerWrapper>
    );
  } else if (isNoDataState) {
    displayContent = (
      <TransitionWrapper testId={thisTestId}>
        {isUndefined(noDataContent) ? (
          <EmptyState
            body="Your team doesn't seem to have any data."
            header="Hmmm."
            testId={thisTestId}
          />
        ) : (
          noDataContent
        )}
      </TransitionWrapper>
    );
  } else {
    displayContent = (
      <TransitionWrapper testId={thisTestId}>{content}</TransitionWrapper>
    );
  }

  return (
    <Container className={className} data-testid={thisTestId}>
      <TransitionWrapper testId={thisTestId}>
        {displayContent}
      </TransitionWrapper>
    </Container>
  );
};

export default LoadingStates;
