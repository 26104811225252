import React from "react";
import styled from "styled-components/macro";
import { kebabCase } from "lodash";

// components
import CustomRadioInput from "../CustomRadioInput/CustomRadioInput";

// styled components
const Radio = styled(CustomRadioInput)`
  align-items: center;
  cursor: pointer;
`;
const Label = styled.span`
  font-size: 1.1rem;
`;

// typescript props
type Props = {
  className?: string;
  isDefault: boolean;
  onChangeDefaultTeam: (e: React.ChangeEvent<HTMLInputElement>) => void;
  teamName: string;
  testId?: string;
};

const DefaultTeamRadio = ({
  className,
  isDefault,
  onChangeDefaultTeam,
  teamName,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-default-team-radio`;
  return (
    <Radio
      className={className}
      label={<Label>Make my default team</Label>}
      name={`${kebabCase(teamName)}-default-team`}
      onChange={onChangeDefaultTeam}
      selected={isDefault}
      testId={thisTestId}
      value="default-team"
    />
  );
};

export default DefaultTeamRadio;
