import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

// styled components
const Container = styled.div`
  max-height: 30rem;
  overflow: auto;
`;

// typescript props
type Props = {
  children: React.ReactNode;
  className?: string;
  resetTrigger: any;
  testId?: string;
};

const ScrollContainerWithReset = ({
  children,
  className,
  resetTrigger,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-scroll-container-with-reset`;
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    if (isScrolled && containerRef.current !== null) {
      containerRef.current.scrollTop = 0;
      setIsScrolled(false);
    }
  }, [resetTrigger]);

  return (
    <Container
      ref={containerRef}
      onScroll={() => setIsScrolled(true)}
      className={className}
      data-testid={thisTestId}
    >
      {children}
    </Container>
  );
};

export default ScrollContainerWithReset;
