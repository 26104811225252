import React from "react";
import styled, { css } from "styled-components/macro";
import { currentTheme } from "../../themes/currentTheme";
import informationText from "../../constants/informationText.json";

// components
import Dropdown from "../Dropdown/Dropdown";
import InfoPopup from "../InfoPopup/InfoPopup";
import Switch from "../Switch/Switch";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";

// constants
import { sprintStates } from "../../constants/constants";

// interfaces
import { SelectOptionsInterface } from "../../interfaces/controls";
import { BoardInterface } from "../../interfaces/sprint-metadata";
import { TimerangeMetadataInterface } from "../../interfaces/constants";
import { ProjectInterface } from "../../interfaces/work-items";

// styled components
const ProjectInput = styled(Dropdown)`
  flex: 1;
`;
const BoardInput = styled(Dropdown)`
  flex: 1;
`;
const StyledSwitch = styled(Switch)`
  font-size: inherit;
  flex: 1;
`;
const Label = styled.span`
  font-size: inherit;
  margin-right: 1rem;

  &::after {
    content: ":";
  }
`;
const NoData = styled.span`
  color: ${props => props.theme.colors.all.jean};
`;
const Sprint = styled.div<{ isEditing: boolean }>``;
const MetadataType = styled.div`
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin-right: 0.5rem;

  &::after {
    content: ":";
  }
`;
const MetadataValue = styled.div`
  flex: 1;
`;
const MetadataWrapper = styled.div<{ isDropdown: boolean; isEditing: boolean }>`
  padding: 0.4rem 0;

  align-items: baseline;
  display: flex;

  & + & {
    margin-top: 0.8rem;
  }

  ${props => {
    if (props.isEditing) {
      const updatedMargin = css`
        & + & {
          margin-top: 0.4rem;
        }
      `;

      if (props.isDropdown) {
        return css`
          display: block;
          padding: 0;
          ${updatedMargin}
        `;
      }
      return css`
        border: ${props.theme.borders.widths.sm} solid
          ${props.theme.colors.all.jean};
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        ${updatedMargin}
      `;
    }
  }}
`;
const Error = styled.strong`
  color: ${props => props.theme.colors.all.cyclops};
  display: block;
  font-size: 1.1rem;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin-top: 0.4rem;
`;

const getInputStyles = (hasError: boolean) => ({
  control: (styleObj: Record<string, unknown>) => ({
    ...styleObj,
    border: `${currentTheme.borders.widths.sm} solid ${
      hasError ? currentTheme.colors.all.cyclops : currentTheme.colors.all.jean
    }`,
    borderRadius: 0,
    boxShadow: "none",
    padding: "0",
    ":hover": {
      border: `${currentTheme.borders.widths.sm} solid ${
        hasError
          ? currentTheme.colors.all.cyclops
          : currentTheme.colors.all.jean
      }`
    }
  })
});

// typescript props
type Props = {
  board: BoardInterface | null;
  boards: Array<BoardInterface>;
  className?: string;
  hasBoardError: boolean;
  hasProjectError: boolean;
  isEditing: boolean;
  isOrgChartTeam?: boolean;
  onSelectBoard: (selected: SelectOptionsInterface) => void;
  onSelectProject: (selected: SelectOptionsInterface) => void;
  onToggleSprints: (e: React.ChangeEvent<HTMLInputElement>) => void;
  project: ProjectInterface | null;
  projects: Array<ProjectInterface>;
  testId?: string;
  shouldUseSprints: boolean;
  sprint: TimerangeMetadataInterface | null;
};

const SprintsMetadataSelector = ({
  board,
  boards,
  className,
  hasBoardError,
  hasProjectError,
  isEditing,
  isOrgChartTeam = false,
  onSelectBoard,
  onSelectProject,
  onToggleSprints,
  project,
  projects,
  shouldUseSprints,
  sprint,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-sprints-metadata-selector`;
  // org chart teams have their useSprints attribute set to true by default but
  // if we attempt to find a board for the team and come up empty, then we
  // shouldn't show the 'use sprints' toggle as being active/yes and then show
  // no sprint data, so reconciling this with this somewhat janky workaround.
  // when we are able to edit the sprint data for an org chart team, maybe this
  // becomes unnecessary?
  const actuallyUsesSprints = isOrgChartTeam
    ? shouldUseSprints && !!board
    : shouldUseSprints;

  const projectOptions = projects.map((p: ProjectInterface) => {
    return {
      label: p.projectName,
      value: p.projectId,
      selected: p.projectId === project?.projectId
    };
  });
  const selectedProject = projectOptions.find(
    (p: { value: string; label: string; selected: boolean }) => p.selected
  );
  let selectedProjectValue;
  if (!!selectedProject) {
    selectedProjectValue = {
      ...selectedProject,
      label: `Project: ${selectedProject.label}`
    };
  }

  const boardOptions = boards.map((b: BoardInterface) => {
    return {
      label: b.boardName,
      value: b.boardId,
      selected: b.boardId === board?.boardId
    };
  });
  const selectedBoard = boardOptions.find(
    (b: { value: string; label: string; selected: boolean }) => b.selected
  );
  let selectedBoardValue;
  if (!!selectedBoard) {
    selectedBoardValue = {
      ...selectedBoard,
      label: `Board: ${selectedBoard.label}`
    };
  }

  const displayProject = isEditing ? (
    <MetadataWrapper isDropdown={true} isEditing={isEditing}>
      <ProjectInput
        bordered={true}
        isSearchable={true}
        label="Assign Project"
        maxMenuHeight={100}
        onChange={onSelectProject}
        options={projectOptions}
        placeholder="Assign Project"
        shouldShowLabel={false}
        value={selectedProjectValue || null}
        testId={thisTestId}
        styles={getInputStyles(hasProjectError)}
      />
      {hasProjectError ? (
        <TransitionWrapper>
          <Error>Please choose a Project</Error>
        </TransitionWrapper>
      ) : null}
    </MetadataWrapper>
  ) : (
    <MetadataWrapper isDropdown={true} isEditing={isEditing}>
      <MetadataType>Project</MetadataType>
      <MetadataValue>
        {project?.projectName || <NoData>no Jira project</NoData>}
      </MetadataValue>
    </MetadataWrapper>
  );

  const displayBoard = isEditing ? (
    <MetadataWrapper isDropdown={true} isEditing={isEditing}>
      <BoardInput
        bordered={true}
        isSearchable={true}
        label="Assign Board"
        maxMenuHeight={100}
        onChange={onSelectBoard}
        options={boardOptions}
        placeholder="Assign Board"
        shouldShowLabel={false}
        value={selectedBoardValue || null}
        testId={thisTestId}
        styles={getInputStyles(hasBoardError)}
      />
      {hasBoardError ? (
        <TransitionWrapper>
          <Error>Please choose a Board</Error>
        </TransitionWrapper>
      ) : null}
    </MetadataWrapper>
  ) : (
    <MetadataWrapper isDropdown={true} isEditing={isEditing}>
      <MetadataType>Board</MetadataType>
      <MetadataValue>
        {board?.boardName || <NoData>no Jira board</NoData>}
      </MetadataValue>
    </MetadataWrapper>
  );

  return (
    <div
      className={className}
      data-testid={thisTestId}
      data-heap-redact-text="true"
    >
      <MetadataWrapper isDropdown={false} isEditing={isEditing}>
        <StyledSwitch
          isActive={actuallyUsesSprints}
          isDisabled={!isEditing}
          labelContent={<Label>Use Sprints</Label>}
          name="sprints-toggle"
          onChange={onToggleSprints}
          showSwitchStatus={true}
          testId={thisTestId}
        />
      </MetadataWrapper>
      {actuallyUsesSprints && !!projectOptions.length ? displayProject : null}
      {actuallyUsesSprints ? displayBoard : null}
      {actuallyUsesSprints ? (
        <MetadataWrapper isDropdown={false} isEditing={isEditing}>
          <Sprint isEditing={isEditing}>
            <div>
              <Label>
                {sprint?.state === sprintStates.ACTIVE
                  ? "Recent Active Sprint"
                  : "Recent Sprint"}
              </Label>
              <InfoPopup
                content={informationText["team-card-recent-sprint"]}
                header="Get the best results from your team"
                name="team-card-recent-sprint"
                testId={thisTestId}
              />
            </div>
            <div>{sprint?.displayName || <NoData>No sprint</NoData>}</div>
          </Sprint>
        </MetadataWrapper>
      ) : null}
    </div>
  );
};

export default SprintsMetadataSelector;
