import React, { useMemo } from "react";
import { DefaultTheme, withTheme } from "styled-components/macro";

// components
import Chart from "../Chart/Chart";

// config
import generateConfig from "./config";

// interfaces
import { CycleTimeInterface } from "../../interfaces/work-items";

// typescript props
type Props = {
  className?: string;
  data: CycleTimeInterface;
  isFetchingData?: boolean;
  testId?: string;
  theme: DefaultTheme;
};

const ChartOperationalMetricsCycleTime = ({
  className,
  data,
  isFetchingData = false,
  testId = "testId",
  theme
}: Props) => {
  const thisTestId = `${testId}-operational-metrics-cycle-time`;

  const chart = useMemo(() => {
    return {
      config: generateConfig(data),
      id: "operational-metrics-cycle-time-chart",
      isFetchingData
    };
  }, [data, isFetchingData]);

  return (
    <div className={className} data-testid={thisTestId}>
      <Chart chart={chart} testId={thisTestId} theme={theme} />
    </div>
  );
};

export default withTheme(ChartOperationalMetricsCycleTime);
