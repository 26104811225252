import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import config from "../env-config";
import { get, isNull, merge } from "lodash";
import { captureException } from "@sentry/react";

// context
import { UrlParamsContext } from "../context/url-params";

// hooks
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// interfaces
import { Event } from "../interfaces/event";
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { getTimestampForDate } from "../utils/date";
import { sendEvent } from "../utils/uplevelAnalytics";
import { parsePageIdFromPathname } from "../utils/router";

export const useTracking = (): {
  trackEvent: (event: Event) => void;
} => {
  // using raw context values here instead of useUrlParams because including
  // that here would set up an infinite loop/circular dependency amongst the
  // other hooks included in useUrlParams
  const urlParamsContextValues = useContext(UrlParamsContext);
  const [triggeredEvent, setTriggeredEvent] = useState<Event | null>(null);
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const session = useSelector((state: AppStateInterface) => state.session);
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const farsUrl = config.FARS_URL;
  const { pathname } = useLocation();
  const pageId = useMemo(() => {
    return parsePageIdFromPathname(pathname);
  }, [pathname]);
  const baseEventParams = useMemo(() => {
    return {
      accessToken,
      farsUrl,
      tenantId,
      params: {
        // replacing the old uifilterparams with the urlparams values -- these
        // should be pretty much the same values as before, minus maybe some
        // stray logic here and there, but since the point of including these in
        // the session info is to surface 'what is the ui set to?', i think this
        // should satisfy that.
        session: { ...session, uiFilterParams: urlParamsContextValues }
      }
    };
  }, [accessToken, farsUrl, tenantId, session, urlParamsContextValues]);

  const trackEvent = useCallback(
    (event: Event) => {
      setTriggeredEvent(event);
    },
    [setTriggeredEvent]
  );

  useEffect(() => {
    (async () => {
      if (!isNull(triggeredEvent)) {
        const label = get(triggeredEvent, "label");
        const isNoAccountPage = /no-uplevel-account/.test(label);
        try {
          sendEvent(
            merge(baseEventParams, {
              params: {
                uiEvent: {
                  action: get(triggeredEvent, "e.type") || "click",
                  category: "web",
                  elementId: get(triggeredEvent, "e.currentTarget.id"),
                  // omit the pageid on the no uplevel account event because it
                  // isn't on its own route
                  eventLabel: isNoAccountPage ? label : `${pageId}-${label}`,
                  eventValue: get(triggeredEvent, "value"),
                  page: isNoAccountPage
                    ? "user-with-no-uplevel-account"
                    : pageId,
                  timestamp: getTimestampForDate(new Date())
                }
              }
            })
          );
          setTriggeredEvent(null);
        } catch (e) {
          setTriggeredEvent(null);
        }
      }
    })();
  }, [baseEventParams, pageId, triggeredEvent]);

  return { trackEvent };
};
