import { useEffect, useState } from "react";
import moment from "moment";
import { isNull } from "lodash";

// hooks
import { useSelector } from "react-redux";

// utils
import parseData from "./parser";
import dataFetcher from "./fetchMeetingClassificationsData";
import { getDateWeekBefore } from "../../utils/date";
import { captureException, withScope } from "@sentry/react";

export const useBackingData = (
  accessToken,
  tenantId,
  teamMembers,
  user,
  override,
  timerange
) => {
  const [data, _setData] = useState();
  const [isFetchingData, _setIsFetchingData] = useState(false);
  const [error, _setError] = useState(null);
  const flags = useSelector(state => state.flags);
  // TODO: confirm this is the flag result for the roles set and create flag for more generic use
  const canViewFullTeam = flags?.["transparent-data-for-peers"];
  const endDate = !!timerange
    ? timerange.end
    : moment()
        .endOf("week")
        .format();
  const startDate = !!timerange
    ? timerange.start
    : moment()
        .startOf("week")
        .format();

  // effect to fetch data when the request props change
  useEffect(() => {
    (async () => {
      if (!!accessToken && !!tenantId && !!teamMembers.length) {
        let params = {
          accessToken,
          tenantId,
          startDate,
          endDate,
          user,
          team: teamMembers,
          canViewFullTeam
        };

        try {
          _setIsFetchingData(true);

          if (!isNull(override)) {
            params = { ...params, override };
          }

          const responseDataCurrent = await dataFetcher(params);
          const responseDataPrevious = await dataFetcher({
            ...params,
            startDate: getDateWeekBefore(startDate),
            endDate: getDateWeekBefore(endDate)
          });
          const parsedData = parseData(
            responseDataCurrent,
            responseDataPrevious
          );
          _setData(parsedData);
          _setIsFetchingData(false);
        } catch (e) {
          _setError(e);
          _setData(null);
          _setIsFetchingData(false);
          withScope(scope => {
            scope.setContext("request-meeting-classifications", params);
            captureException(e);
          });
        }
      }
      _setIsFetchingData(false);
    })();
  }, [
    accessToken,
    tenantId,
    user,
    teamMembers,
    endDate,
    startDate,
    override,
    canViewFullTeam
  ]);

  return [data, isFetchingData, error];
};
