import moment from "moment";
import { timerangeTypes } from "../constants/constants";
import { TimerangeMetadataInterface } from "../interfaces/constants";
import { BoardInterface } from "../interfaces/sprint-metadata";
import { getTimestampForDate } from "./date";

export const getSprintTimerangesForBoard = (
  board: BoardInterface
): Array<TimerangeMetadataInterface> => {
  return board.sprints.map(sprint => {
    return {
      boardId: board.boardId,
      displayName: sprint.sprintName,
      id: sprint.sprintId,
      end: getTimestampForDate(
        moment.utc(sprint.completeAt || new Date().setMilliseconds(0))
      ),
      start: getTimestampForDate(moment.utc(sprint.startAt)),
      timerangeType: timerangeTypes.SPRINTS,
      state: sprint.state
    };
  });
};
