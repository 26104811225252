import { captureException } from "@sentry/react";
import api from "./api";

export const requestPrCadence = async ({ accessToken, params, tenantId }) => {
  if (
    !("users" in params) ||
    !("startDate" in params) ||
    !("endDate" in params) ||
    !Array.isArray(params.users)
  ) {
    return captureException(
      `Expected params with keys of "users" as value type String[], "startDate" as String, and "endDate" as value type String, but received ${JSON.stringify(
        params
      )}`
    );
  }
  const response = await api.post(`/${tenantId}/prcadence`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const requestPrCadence_privateic = async ({
  accessToken,
  params,
  tenantId
}) => {
  if (
    !("startDate" in params) ||
    !("endDate" in params) ||
    !("userEmail" in params)
  ) {
    return captureException(
      `Expected params with keys of "userEmail", "startDate" and "endDate" as String, but received ${JSON.stringify(
        params
      )}`
    );
  }
  const response = await api.post(`/${tenantId}/private-ic/prcadence`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};
