import React, { ReactElement, useMemo } from "react";
import styled from "styled-components/macro";
import { isNull } from "lodash";

// components
import PageTitle from "../PageTitle/PageTitle";
import RetrosSprintCompletion from "../RetrosSprintCompletion/RetrosSprintCompletion";
import RetrosSprintHealthFactors from "../RetrosSprintHealthFactors/RetrosSprintHealthFactors";
import RetrosSprintReactionsSummary from "../RetrosSprintReactionsSummary/RetrosSprintReactionsSummary";
import TeamRoster from "../TeamRoster/TeamRoster";
import TemplateHeader from "../TemplateHeader/TemplateHeader";
import SignalHealthColor from "../SignalHealthColor/SignalHealthColor";
import SprintUpdatesTable from "../SprintUpdatesTable/SprintUpdatesTable";
import TimeRange from "../TimeRange/TimeRange";
import UiCenteringWrapper from "../UiCenteringWrapper/UiCenteringWrapper";

// constants
import {
  sprintStates,
  workMetadataStatusTypes
} from "../../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AnnotationsInterface } from "../../interfaces/annotations";
import { AppStateInterface } from "../../interfaces/app-state";
import { SprintHealthInterface } from "../../interfaces/sprint-metadata";
import { TimerangeMetadataInterface } from "../../interfaces/constants";
import { GroupsListInterface } from "../../interfaces/work-items";

// utils
import { getTeamNameFromTeam } from "../../utils/teams";
import {
  getTeamRosterFromGroupsList,
  getWorkItemsActivitiesTotals
} from "../../utils/work-items";
import DataDisclaimer from "../DataDisclaimer/DataDisclaimer";

// styled components
const Container = styled.div`
  background: ${props => props.theme.colors.all.white};
  padding-bottom: 2rem;
`;
const StyledTemplateHeader = styled(TemplateHeader)`
  border-bottom: 0;
`;
const StyledPageTitle = styled(PageTitle)`
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
`;
const TitleWrapper = styled.hgroup`
  flex: 1;
`;
const HeaderWrapper = styled.div`
  align-items: flex-end;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
`;
const SubHeader = styled.div`
  align-items: center;
  display: flex;
  margin-top: 1.5rem;
`;
const SprintMetadata = styled.h4`
  align-items: center;
  border-right: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin: 0 1rem 0 0;
  padding-right: 1rem;
`;
const SprintName = styled.span`
  margin-right: 1rem;
`;
const Stats = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;
const Stat = styled.div`
  align-items: baseline;
  display: flex;
  line-height: 1.8rem;

  & + & {
    margin-left: 1rem;
  }
`;
const StatHeader = styled.header`
  font-size: 1.3rem;
  margin-right: 1rem;
`;
const StatValue = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  font-size: 2rem;
  font-weight: 700;
`;
const SprintHealthStatValue = styled(SignalHealthColor)`
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${props => props.theme.grid.gap};
`;
const Charts = styled.div`
  background: ${props =>
    `linear-gradient(${props.theme.colors.all.jean}, ${props.theme.colors.all.jean} 20.2rem, ${props.theme.colors.all.white} 20.2rem)`};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.1rem;
  margin-bottom: 3rem;
`;
const SprintHealthChart = styled(RetrosSprintHealthFactors)`
  background: ${props => props.theme.colors.all.white};
  padding-right: 1.2rem;
`;
const SprintCompletionChart = styled(RetrosSprintCompletion)`
  background: ${props => props.theme.colors.all.white};
  padding-left: 1.2rem;
`;
const ReactionsAndStats = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* TODO: uncomment this when sprint reactions are in for real */
  /* grid-template-columns: ${props =>
    `calc(8 * ${props.theme.grid.columnWidth} + 7 * ${props.theme.grid.gap}) calc(4 * ${props.theme.grid.columnWidth} + 3 * ${props.theme.grid.gap})`}; */
  gap: ${props => props.theme.grid.gap};
`;
const Footer = styled.footer`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;
const ActiveSprint = styled.strong`
  color: ${props => props.theme.colors.all.wolverine};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
`;

// typescript props
type Props = {
  annotations?: Array<AnnotationsInterface> | null;
  className?: string;
  sprint: TimerangeMetadataInterface;
  sprintCompletionData: GroupsListInterface;
  sprintHealthFactorsData: SprintHealthInterface;
  testId?: string;
};

const RetrosSummary = ({
  className,
  annotations = [],
  sprint,
  sprintCompletionData,
  sprintHealthFactorsData,
  testId = "testId"
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-retros-summary`;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const { urlParams } = useUrlParams();
  const activeTeam = urlParams.team;
  const activitiesTotals = useMemo(
    () => getWorkItemsActivitiesTotals(sprintCompletionData.activities),
    [sprintCompletionData]
  );

  const sprintCompletionPercentage = (
    activitiesTotals.find(
      activity => activity.status === workMetadataStatusTypes.DONE
    )?.percentage || 0
  ).toFixed(0);
  const sprintHealthScore = sprintHealthFactorsData?.[sprint.id]?.averageHealth;

  return !!activeTeam && !!sprintHealthScore ? (
    <Container className={className} data-testid={thisTestId}>
      <StyledTemplateHeader
        content={
          <HeaderWrapper data-heap-redact-text="true">
            <TitleWrapper>
              <StyledPageTitle
                title={`Sprint Retro Insights: ${getTeamNameFromTeam(
                  activeTeam
                )}`}
                testId={thisTestId}
              />
              <SubHeader>
                <SprintMetadata>
                  <SprintName>{sprint.displayName}</SprintName>
                  <TimeRange
                    config={{ format: { start: "M/DD", end: "M/DD" } }}
                    testId={thisTestId}
                    timerange={sprint}
                  />
                </SprintMetadata>
                <TeamRoster
                  header="Sprint Roster"
                  names={getTeamRosterFromGroupsList(
                    sprintCompletionData.workMetadata
                  )}
                  testId={thisTestId}
                />
              </SubHeader>
            </TitleWrapper>
            <Stats>
              {!!sprintHealthScore ? (
                <Stat>
                  <StatHeader>Sprint Health:</StatHeader>
                  <SprintHealthStatValue
                    score={sprintHealthScore}
                    testId={thisTestId}
                  />
                </Stat>
              ) : null}
              {!!sprintCompletionPercentage ? (
                <Stat>
                  <StatHeader>Sprint Completion:</StatHeader>
                  <StatValue>{sprintCompletionPercentage}</StatValue>
                  <span>%</span>
                </Stat>
              ) : null}
            </Stats>
          </HeaderWrapper>
        }
        testId={thisTestId}
      />
      <UiCenteringWrapper>
        <Grid>
          <Charts>
            <SprintHealthChart
              data={sprintHealthFactorsData?.[sprint.id]}
              isActiveSprint={sprint.state === sprintStates.ACTIVE}
              testId={thisTestId}
            />
            <SprintCompletionChart
              data={activitiesTotals}
              isActiveSprint={sprint.state === sprintStates.ACTIVE}
              testId={thisTestId}
            />
          </Charts>
          <ReactionsAndStats>
            {flags?.["retros-annotations-summary"] && !isNull(annotations) ? (
              <RetrosSprintReactionsSummary
                annotations={annotations}
                sprint={sprint}
                testId={thisTestId}
              />
            ) : null}
            <SprintUpdatesTable
              retroTotals={{
                ...sprintCompletionData.retroTotals,
                unestimatedItems: sprintCompletionData?.calloutTotals?.find(
                  record => record.callout === "NULL_STORY_POINTS"
                )?.total
              }}
              testId={thisTestId}
            />
          </ReactionsAndStats>
        </Grid>
        <Footer>
          <DataDisclaimer
            content={
              sprint.state === sprintStates.ACTIVE && (
                <>
                  <ActiveSprint>
                    Note: this sprint is currently active
                  </ActiveSprint>{" "}
                  and data will change as progress is made. Uplevel data is
                  refreshed daily.
                </>
              )
            }
            testId={thisTestId}
          />
        </Footer>
      </UiCenteringWrapper>
    </Container>
  ) : null;
};

export default RetrosSummary;
