import querystring from "query-string";
import { useEffect, useMemo, useState } from "react";

// hooks
import { useActiveUsersTeams } from "./useActiveUsersTeams";
import { useDefaultTeam } from "./useDefaultTeam";
import { useLocation } from "react-router-dom";

// interfaces
import { TeamInterface } from "../interfaces/team";

// utils
import { getTeamId, parseQuerystring } from "../utils/url-params";

export const useActiveTeam = (): TeamInterface | null => {
  const [activeTeam, setActiveTeam] = useState<TeamInterface | null>(null);
  // we have to use the raw querystring here because this hook is used by the
  // url params hook and referencing it would set up a circular dependency
  const { search } = useLocation();
  const parsedQuerystring = useMemo(() => parseQuerystring(search), [
    parseQuerystring,
    search
  ]);
  const querystringTeamId = getTeamId(parsedQuerystring);

  const defaultTeam = useDefaultTeam();
  const { data: activeUsersTeams } = useActiveUsersTeams();
  const querystringTeam = useMemo(
    () =>
      activeUsersTeams?.find(
        (t: TeamInterface) => t.teamId === querystringTeamId
      ),
    [activeUsersTeams, querystringTeamId]
  );

  useEffect(() => {
    // if teams for the zoomed in team lead or for the user are populated
    if (!!activeUsersTeams?.length && !!defaultTeam) {
      // if we located the querystring team
      if (!!querystringTeam) {
        setActiveTeam(querystringTeam);
      } else {
        setActiveTeam(defaultTeam);
      }
    } else {
      setActiveTeam(null);
    }
  }, [activeUsersTeams, defaultTeam, querystringTeam]);

  return activeTeam;
};
