import React, { useEffect } from "react";
import styled from "styled-components/macro";
import informationText from "../../constants/informationText.json";
import { get } from "lodash";

// components
import DataDisclaimer from "../DataDisclaimer/DataDisclaimer";
import HtmlText from "../HtmlText/HtmlText";
import Icon from "../Icon/Icon";
import InfoPopup from "../InfoPopup/InfoPopup";
import MeetingInsights from "../MeetingInsights/MeetingInsights";
import PageTitle from "../PageTitle/PageTitle";
import PeopleHealth from "../PeopleHealth/PeopleHealth";
import PeoplePicker from "../PeoplePicker/PeoplePicker";
import RouteLinkTabs from "../RouteLinkTabs/RouteLinkTabs";
import TemplateHeader from "../TemplateHeader/TemplateHeader";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";

// constants
import {
  teamDeepDiveSectionLabels,
  teamDeepDiveSections,
  pageIds,
  Signals,
  urlParamKeys
} from "../../constants/constants";

// hooks
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTeamDeepDiveData } from "../../hooks/useTeamDeepDiveData";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";

// utils
import { getSelectableTeamMembers } from "../../utils/people-picker";
import { getTeamNameFromTeam } from "../../utils/teams";
import { useTracking } from "../../hooks/useTracking";
import EmptyState from "../EmptyState/EmptyState";
import InformationText from "../../constants/informationText.json";
import { getTeamDeepDivePageTitle } from "../../utils/team-deep-dive";

// styled components
const StyledTemplateHeader = styled(TemplateHeader)`
  font-size: 2rem;
  width: 100%;
`;

const ContentWrapper = styled.div`
  background: ${props => props.theme.colors.all.white};
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled(PageTitle)`
  padding-bottom: 0.8rem;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const SubRouteContainer = styled.div`
  margin: 0 2rem;
`;

const TitleWrapper = styled.span`
  align-items: center;
  display: flex;
`;
const TitleText = styled.span`
  margin-right: 1rem;
`;
const PopupHeader = styled.h4`
  font-family: ${props => props.theme.fonts.header.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.header.sizes.xs};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
  margin: 0;
`;
const PopupContent = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const LinkIcon = styled(Icon)`
  color: inherit;
  margin-right: 0.5rem;
`;
const LearnLink = styled.a`
  align-items: center;
  display: flex;
  && {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    text-decoration: none;

    & ${LinkIcon} {
      color: inherit;
    }
  }
`;
const StyledTabs = styled(RouteLinkTabs)`
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;
const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin: 1.6rem 0;
`;

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.all.wolverine};
`;

const StyledEmptyState = styled(EmptyState)`
  > div {
    max-width: 100rem;
  }
`;

type Props = {
  className?: string;
  testId?: string;
};

const TeamDeepDivePage = ({ className, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-team-deep-dive-page`;
  const { trackEvent } = useTracking();
  const location = useLocation();

  const flags = useSelector((state: AppStateInterface) => state.flags);
  const user = useSelector((state: AppStateInterface) => get(state, "user"));
  const { urlParams, teamDeepDiveParams, getTeamDeepDivePath } = useUrlParams();
  const activeTeam = urlParams.team;
  const selectedUserEmail = teamDeepDiveParams.selectedUser;
  const {
    selectedSection: teamDeepDiveSelectedSection,
    selectedMetric: teamDeepDiveSelectedMetric
  } = teamDeepDiveParams;

  // page title change
  useEffect(() => {
    document.title = getTeamDeepDivePageTitle(teamDeepDiveSelectedMetric);
  }, [location, teamDeepDiveSelectedMetric]);

  const selectedUser =
    activeTeam?.teamMembers.find(m => m.email === selectedUserEmail) || "team";
  const selectableTeamMembers = getSelectableTeamMembers({
    user,
    flags,
    teamDeepDiveParams: teamDeepDiveParams,
    urlParams
  });

  const {
    data: peopleHealthData,
    isLoading: isFetchingPeopleHealthData,
    isError: errorPeopleHealthData
  } = useTeamDeepDiveData(activeTeam?.teamMembers);
  const canViewMeetingInsightsTab = flags?.["team-deep-dive-meeting-insights"];

  // don't show team avatar if on either meeting-insights tabs (meeting
  // distribution or meeting length) or always on tab
  const canViewTeamData =
    //FIXME: confirm this is the flag result for the roles set and create/rename flag for more generic use
    !(
      (teamDeepDiveSelectedSection === teamDeepDiveSections.HEALTH_METRICS &&
        teamDeepDiveSelectedMetric === Signals.ALWAYS_ON) ||
      teamDeepDiveSelectedSection === teamDeepDiveSections.MEETING_INSIGHTS
    ) || flags?.["transparent-data-for-peers"];

  if (
    !(selectedUser === "team"
      ? canViewTeamData
      : selectableTeamMembers.includes(selectedUser.id)) &&
    selectableTeamMembers.includes(user.id)
  ) {
    // match the user to a team member via the userid
    const userFromTeamMembers = activeTeam?.teamMembers.find(
      t => t.id === user.id
    );
    // FIXME: we can't show team for meeting distribution, for example, because
    // we can only show the individual user If the visible team members change
    // and the selected user is no longer visible, default to showing the root
    // user If the visible team members change and the team is selected but no
    // longer visible, also show the root user Rules for when to reset the user
    // can be found at
    // https://www.notion.so/People-Picker-Privacy-Rules-71740ac7fd34442e802abdba4e8c4931
    //
    // redirect to the team members email, which is what we're using for the url
    // params for consistency (rather than the current user's email address)
    if (!!userFromTeamMembers) {
      return (
        <Redirect
          to={getTeamDeepDivePath({
            querystringParams: {
              [urlParamKeys.SELECTED_USER]: userFromTeamMembers.email
            },
            selectedSection: teamDeepDiveSelectedSection,
            selectedMetric: teamDeepDiveSelectedMetric
          })}
        />
      );
    }
  }

  const meetingInsightsDisabled =
    !flags?.["transparent-data-for-peers"] &&
    !urlParams.team?.teamMembers?.find(t => t.id === user.id);
  const selectableSectionOptions = Object.values(teamDeepDiveSections)
    .filter(section =>
      section === teamDeepDiveSections.MEETING_INSIGHTS
        ? canViewMeetingInsightsTab
        : true
    )
    .map(section => ({
      id: section,
      isDisabled:
        section === teamDeepDiveSections.MEETING_INSIGHTS &&
        meetingInsightsDisabled,
      path: getTeamDeepDivePath({
        selectedSection: section
      }),
      title: teamDeepDiveSectionLabels[section]
    }));

  function onClickLearnLink(e: React.MouseEvent<HTMLElement>) {
    trackEvent({
      e,
      label: `${thisTestId}-learn-more-link`,
      value: "link clicked"
    });
  }

  const teamName = activeTeam ? getTeamNameFromTeam(activeTeam) : null;
  const titleSuffix = teamName ? `: ${teamName}` : "";

  const headerContent = (
    <HeaderContent>
      <Title
        title={
          <TitleWrapper>
            <TitleText>
              Team Deep Dive
              {titleSuffix}
            </TitleText>
            <InfoPopup
              action="click"
              header={<PopupHeader>What is Team Deep Dive?</PopupHeader>}
              content={
                <PopupContent>
                  <HtmlText
                    htmlText={informationText["team-deep-dive"]}
                    testId={thisTestId}
                  />
                  <LearnLink
                    href="https://uplevelteam.com/wp-content/uploads/2021/09/E-book-Data-Behind-Uplevel.pdf"
                    onClick={onClickLearnLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    data-testid={`${thisTestId}-learn-more-link`}
                  >
                    <LinkIcon icon="external-link" testId={thisTestId} />
                    Learn more about Uplevel metrics
                  </LearnLink>
                </PopupContent>
              }
              name="team-deep-dive"
              testId={thisTestId}
            />
          </TitleWrapper>
        }
        testId={pageIds.TEAM_DEEP_DIVE}
      />
      <PeoplePicker teamAvatarEnabled={canViewTeamData} />
    </HeaderContent>
  );

  return flags?.[pageIds.TEAM_DEEP_DIVE] ? (
    <TransitionWrapper>
      <ContentWrapper className={className} data-testid={thisTestId}>
        <StyledTemplateHeader
          content={headerContent}
          testId={pageIds.TEAM_DEEP_DIVE}
        />
        <SubRouteContainer>
          <StyledTabs
            defaultTabId={teamDeepDiveSelectedSection}
            tabs={selectableSectionOptions}
            testId="people-health-metrics"
          />
          <Switch>
            {canViewMeetingInsightsTab && (
              <Route
                path={`/${pageIds.TEAM_DEEP_DIVE}/${teamDeepDiveSections.MEETING_INSIGHTS}`}
              >
                {meetingInsightsDisabled ? (
                  <StyledEmptyState
                    body={InformationText["people-picker-privacy"]}
                    header={<StyledIcon icon={"lock"} />}
                    testId={"always-on-empty-state"}
                  />
                ) : (
                  <MeetingInsights testId={thisTestId} />
                )}
              </Route>
            )}

            {/* TODO: temp redirect until it seems like people are not bookmarking overall-team-health anymore */}
            <Route
              exact
              path={`/${pageIds.TEAM_DEEP_DIVE}/overall-team-health`}
            >
              <Redirect
                to={`/${pageIds.TEAM_DEEP_DIVE}/${
                  teamDeepDiveSections.PEOPLE_HEALTH
                }${!!window.location.search ? window.location.search : ""}`}
              />
            </Route>

            <Route
              exact
              path={`/${pageIds.TEAM_DEEP_DIVE}/${teamDeepDiveSections.PEOPLE_HEALTH}`}
            >
              <PeopleHealth
                data={peopleHealthData}
                isFetchingData={isFetchingPeopleHealthData}
                error={errorPeopleHealthData}
                selectedUser={selectedUser}
              />
            </Route>

            <Route
              path={`/${pageIds.TEAM_DEEP_DIVE}/${teamDeepDiveSections.HEALTH_METRICS}`}
            >
              <PeopleHealth
                data={peopleHealthData}
                isFetchingData={isFetchingPeopleHealthData}
                error={errorPeopleHealthData}
                selectedUser={selectedUser}
              />
            </Route>

            <Route path={`/${pageIds.TEAM_DEEP_DIVE}`}>
              <PeopleHealth
                data={peopleHealthData}
                isFetchingData={isFetchingPeopleHealthData}
                error={errorPeopleHealthData}
                selectedUser={selectedUser}
              />
            </Route>
          </Switch>
          <Footer>
            <DataDisclaimer testId={thisTestId} />
          </Footer>
        </SubRouteContainer>
      </ContentWrapper>
    </TransitionWrapper>
  ) : (
    <Redirect to={`/`} />
  );
};

export default TeamDeepDivePage;
