import React from "react";
import styled from "styled-components/macro";
import healthText from "../../constants/healthText.json";

//  compomemts
import ChartSprintHealth from "../ChartSprintHealth/ChartSprintHealth";
import InfoPopup from "../InfoPopup/InfoPopup";
import InfoBook from "../InfoBook/InfoBook";
import SignalHealthColor from "../SignalHealthColor/SignalHealthColor";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";
import HtmlText from "../HtmlText/HtmlText";

// interfaces
import { SprintHealthInterface } from "../../interfaces/sprint-metadata";
import { TimerangeMetadataInterface } from "../../interfaces/constants";

import informationText from "../../constants/informationText.json";

// styled components
const ChartHeader = styled.header`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.1rem;
`;
const Title = styled.h3`
  align-items: center;
  display: flex;
  font-family: ${props => props.theme.fonts.primary}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const TitleText = styled.span`
  margin-right: 1rem;
`;
const HealthScoreTitle = styled.h4`
  align-items: center;
  display: flex;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  line-height: 1;
`;
const HealthScoreHeader = styled.header`
  font-size: 1.3rem;
  margin-right: 1rem;
`;
const HealthScoreValue = styled(SignalHealthColor)`
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
`;
const Chart = styled.figure`
  margin-bottom: 0.5rem;
`;

const PopupHeader = styled.h4`
  font-family: ${props => props.theme.fonts.header.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.header.sizes.xs};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
  margin: 0;
`;
const PopupContent = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;

// typescript props
type Props = {
  className?: string;
  data: SprintHealthInterface;
  sprint: TimerangeMetadataInterface;
  testId?: string;
};

const SprintHealth = ({
  className,
  data,
  sprint,
  testId = "testId"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-sprint-health-chart-wrapper`;
  const bookTextContent = healthText.sprint.sprintHealth.definition;

  const sprintData = data?.[sprint.id];
  return (
    <div className={className} data-testid={thisTestId}>
      <TransitionWrapper testId={thisTestId}>
        <ChartHeader>
          <hgroup>
            <Title>
              <TitleText>Sprint Health</TitleText>
              <TitleText>
                <InfoBook
                  content={bookTextContent.content}
                  header={bookTextContent.header}
                  link={bookTextContent.link}
                  name={thisTestId}
                  testId={thisTestId}
                />
              </TitleText>
              <TitleText>
                <InfoPopup
                  action="click"
                  header={<PopupHeader>Who is included?</PopupHeader>}
                  content={
                    <PopupContent>
                      <HtmlText
                        htmlText={informationText["sprint-who-is-included"]}
                        testId={thisTestId}
                      />
                    </PopupContent>
                  }
                  name="team-deep-dive"
                  testId={thisTestId}
                />
              </TitleText>
            </Title>
          </hgroup>
          {!!sprintData ? (
            <HealthScoreTitle>
              <HealthScoreHeader>
                Current Sprint&rsquo;s Health:
              </HealthScoreHeader>
              <HealthScoreValue
                score={sprintData.averageHealth}
                testId={thisTestId}
              />
            </HealthScoreTitle>
          ) : null}
        </ChartHeader>
        <Chart>
          <ChartSprintHealth data={data} testId={thisTestId} />
          <figcaption className="visuallyHidden">
            Sprint Health chart
          </figcaption>
        </Chart>
      </TransitionWrapper>
    </div>
  );
};

export default SprintHealth;
