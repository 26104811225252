// constants
import { timerangeIds } from "../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { requestOrgChart } from "../utils/user";

export const useOrganizationData = (canRequest = true) => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const userHasUplevelAccount = useSelector(
    (state: AppStateInterface) => state.session.userHasUplevelAccount
  );
  const namedTimeranges = useSelector(
    (state: AppStateInterface) => state.session.namedTimeranges
  );
  const { start: startDate, end: endDate } = namedTimeranges[
    timerangeIds.PREVIOUS_14_DAYS
  ];

  return useQuery(
    "org-chart",
    () =>
      requestOrgChart({
        accessToken,
        tenantId,
        params: {
          startDate,
          endDate,
          users: [], // the backend resource template requires this object to be non null
          tenantId
        }
      }),
    {
      staleTime: Infinity,
      enabled:
        !!accessToken && !!tenantId && !!userHasUplevelAccount && canRequest
    }
  );
};
