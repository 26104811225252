import React, { ReactElement, useMemo } from "react";
import styled from "styled-components/macro";

// components
import InfoBook from "../InfoBook/InfoBook";
import SprintSuccess from "../SprintSuccess/SprintSuccess";
import WorkProgress from "../WorkProgress/WorkProgress";

// constants
import workProgressText from "../../constants/workProgressText.json";

// interfaces
import { getWorkItemsActivitiesTotals } from "../../utils/work-items";
import { GroupsListInterface } from "../../interfaces/work-items";

// styled components
const Container = styled.div`
  width: 100%;
`;
const Header = styled.header`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.h3`
  align-items: center;
  display: flex;
  font-family: ${props => props.theme.fonts.primary}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const TitleText = styled.span`
  margin-right: 1rem;
`;

// typescript props
type Props = {
  className?: string;
  data: GroupsListInterface;
  isSprintMode: boolean;
  testId?: string;
};

const StartWorkProgress = ({
  className,
  data,
  isSprintMode,
  testId = "testId"
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-sprint-progress-chart-wrapper`;
  const activitiesTotals = useMemo(
    () => getWorkItemsActivitiesTotals(data.activities),
    [data]
  );
  const textContent = (isSprintMode
    ? workProgressText.sprint
    : workProgressText.kanban
  ).workProgress.definition;

  return (
    <Container className={className} data-testid={thisTestId}>
      <Header>
        <hgroup>
          <Title>
            <TitleText>{isSprintMode ? "Sprint" : "Work"} Progress</TitleText>
            <InfoBook
              content={textContent.content}
              header={textContent.header}
              link={textContent.link}
              name={thisTestId}
              testId={thisTestId}
            />
          </Title>
        </hgroup>
        {isSprintMode ? <SprintSuccess testId={thisTestId} /> : null}
      </Header>
      <WorkProgress
        data={activitiesTotals}
        isSprintMode={isSprintMode}
        testId={thisTestId}
      />
    </Container>
  );
};

export default StartWorkProgress;
