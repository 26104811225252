import React from "react";
import { DeepDiveData, PeopleMetrics } from "../../interfaces/team-deep-dive";
import { UserReportWithMetadataInterface } from "../../interfaces/user";
import styled from "styled-components/macro";
import { getNumberDataSeries } from "../../utils/health-metrics";

// styled components

const Container = styled.div`
  margin-top: 1rem;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  height: 8rem;
  width: 100%;
`;
const DataAndHeader = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const HeaderSection = styled.div`
  background-color: ${props => props.theme.colors.all.white};
  padding: 0.5rem;
  margin-bottom: 0.25rem;
`;
const DataSection = styled.div`
  padding: 0 0.5rem;
  background-color: ${props => props.theme.colors.all.white};
  height: 100%;
  display: flex;
  align-items: center;
`;
const DataText = styled.b`
  margin-right: 0.5rem;
  font-family: ${props => props.theme.fonts.subheader.name};
  font-size: ${props => props.theme.fonts.subheader.sizes.sm};
  font-weight: ${props => props.theme.fonts.subheader.weights.bold};
`;

type Props = {
  testId?: string;
  className?: string;
  metricTitle: PeopleMetrics;
  data?: DeepDiveData;
  chartView: UserReportWithMetadataInterface | "team";
};

const MetricCalloutDetails = ({
  testId = "testId",
  className,
  metricTitle,
  data,
  chartView
}: Props) => {
  // details for context switching to come in the future when available
  if (metricTitle !== "DEEP_WORK" && metricTitle !== "CHAT_INTERRUPTIONS") {
    return null;
  }
  const timeUnit = metricTitle === "DEEP_WORK" ? "hrs" : "min";
  const seriesData = data?.map(record =>
    getNumberDataSeries(record, metricTitle, chartView, metricTitle)
  );
  if (!seriesData?.length) return null;
  const thisSprintData = Number(seriesData[0][1]).toFixed(1);
  const rollingAverageData = (
    seriesData
      .map(d => Number(d[1]))
      .reduce((v1: number, v2: number) => v1 + v2) / seriesData.length
  ).toFixed(1);

  return (
    <Container>
      <DataAndHeader>
        <HeaderSection>This sprint</HeaderSection>
        <DataSection>
          <DataText>{thisSprintData}</DataText>
          {timeUnit} / day
        </DataSection>
      </DataAndHeader>
      <DataAndHeader>
        <HeaderSection>Rolling average</HeaderSection>
        <DataSection>
          <DataText>{rollingAverageData}</DataText>
          {timeUnit} / day
        </DataSection>
      </DataAndHeader>
    </Container>
  );
};

export default MetricCalloutDetails;
