import React from "react";
import styled from "styled-components/macro";
import { currentTheme } from "../../themes/currentTheme";

// components
import {
  FormControlLabel,
  Switch as MaterialUiSwitch,
  withStyles
} from "@material-ui/core";

//  hooks
import { useTracking } from "../../hooks/useTracking";
import { merge } from "lodash";

// utils
import { hexToRgb } from "../../utils/color";

// styled components
const Container = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};

  & .MuiTypography-body1 {
    color: ${props =>
      hexToRgb({
        hex: props.theme.colors.all.wolverine,
        opacity: 1
      })} !important;
    font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
      sans-serif;
    font-size: inherit;
    line-height: 1;
    margin: 0;
  }
`;

// TODO: update this styling along with upgrade to most recent @mui library (needs react 17)
const StyledFormControlLabel = withStyles({
  root: {
    display: "flex",
    margin: 0,
    justifyContent: "space-between"
  }
})(FormControlLabel);

const baseStyles = {
  root: {
    width: 42,
    height: 26,
    padding: 0
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(1.6rem)",
      color: currentTheme.colors.all.marvelMint,
      "&$disabled": {
        color: currentTheme.colors.all.marvelMint,
        opacity: 0.5,
        "& + $track": {
          backgroundColor: currentTheme.colors.all.jean,
          opacity: 0.5,
          border: "none"
        }
      },
      "& + $track": {
        backgroundColor: currentTheme.colors.all.storm,
        opacity: 1,
        border: "none"
      }
    },
    "&$disabled": {
      color: currentTheme.colors.all.white,
      opacity: 0.5,
      "& + $track": {
        backgroundColor: currentTheme.colors.all.jean,
        opacity: 0.5,
        border: "none"
      }
    }
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: "none"
  },
  track: {
    borderRadius: 26 / 2,
    border: `.1rem solid ${currentTheme.colors.all.storm}`,
    backgroundColor: currentTheme.colors.all.storm,
    opacity: 1
  },
  checked: {},
  disabled: {}
};

const HorizontalSwitch = withStyles(baseStyles)(MaterialUiSwitch);

const HorizontalSwitchWithLabel = withStyles(
  merge({}, baseStyles, {
    switchBase: {
      "&$checked": {
        "& $thumb": {
          "&:before": {
            content: '"Yes"'
          }
        }
      }
    },
    thumb: {
      "&:before": {
        content: '"No"',
        color: currentTheme.colors.all.wolverine,
        fontFamily: `${currentTheme.fonts.primary.name}, Arial, Helvetica, sans-serif`,
        fontSize: "1.1rem"
      }
    }
  })
)(MaterialUiSwitch);

// typescript props
type Props = {
  className?: string;
  isActive: boolean;
  isDisabled?: boolean;
  labelContent: React.ReactNode;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showSwitchStatus?: boolean;
  testId?: string;
  labelPlacement?: "end" | "start" | "top" | "bottom";
};

const Switch = ({
  className,
  isActive,
  isDisabled = false,
  labelContent,
  name,
  onChange,
  showSwitchStatus = false,
  testId = "testId",
  labelPlacement = "start"
}: Props) => {
  const thisTestId = `${testId}-switch`;
  const { trackEvent } = useTracking();

  function onToggleSwitch(e: React.ChangeEvent<HTMLInputElement>) {
    trackEvent({
      label: `${name}-switch`,
      value: `changed to ${isActive ? "inactive" : "active"}`
    });
    onChange(e);
  }

  const DisplaySwitch = showSwitchStatus
    ? HorizontalSwitchWithLabel
    : HorizontalSwitch;
  return (
    <Container className={className} data-testid={thisTestId}>
      <StyledFormControlLabel
        control={
          <DisplaySwitch
            onChange={onToggleSwitch}
            checked={isActive}
            data-testid={`${thisTestId}-toggle`}
            disabled={isDisabled}
          />
        }
        label={labelContent}
        labelPlacement={labelPlacement}
      />
    </Container>
  );
};

export default Switch;
