import { AxiosResponse } from "axios";
import api from "./api";
import { TeamInterface, TeamSettingsInterface } from "../interfaces/team";
import {
  UserInterface,
  UserReportWithMetadataInterface
} from "../interfaces/user";
import { flattenDeep } from "lodash";

// typescript props
type BaseProps = {
  accessToken: string;
  tenantId: number;
};

type RequestProps = BaseProps & {
  params: {
    users: Array<string>;
  };
};

type CreateUpdateProps = BaseProps & {
  params: {
    avatarColor: string;
    avatarIcon: string;
    defaultBoardId: string | null;
    defaultProjectId: string | null;
    isOrgChartTeam: boolean;
    teamId: string | null;
    teamMembers: Array<
      UserReportWithMetadataInterface | { email: string; id: null }
    >;
    teamName: string;
    tenantId: number;
    useSprints: boolean;
  };
};

type DeleteProps = BaseProps & {
  params: {
    teamId: string;
  };
};

type RequestCustomizationProps = BaseProps & {
  params: {
    teamIds: string[];
  };
};

type SetCustomizationProps = BaseProps & {
  params: {
    teamSettings: TeamSettingsInterface;
  };
};

export const requestTeams = async ({
  accessToken,
  tenantId,
  params
}: RequestProps): Promise<Array<TeamInterface>> => {
  const response: AxiosResponse<Array<TeamInterface>> = await api.post<
    Array<TeamInterface>
  >(`/${tenantId}/teams/by-user-ids`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const createOrUpdateTeam = async ({
  accessToken,
  tenantId,
  params
}: CreateUpdateProps): Promise<TeamInterface> => {
  const response: AxiosResponse<TeamInterface> = await api.post<TeamInterface>(
    `/${tenantId}/teams/create-or-update`,
    params,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return response.data;
};

export const deleteTeam = async ({
  accessToken,
  tenantId,
  params
}: DeleteProps): Promise<any> => {
  const response = await api.post(`/${tenantId}/teams/delete`, params, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const requestTeamsCustomizations = async ({
  accessToken,
  tenantId,
  params
}: RequestCustomizationProps): Promise<any> => {
  const response = await api.get(
    `/${tenantId}/teams/customization/${params.teamIds.join(",")}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
  return response.data;
};

export const updateTeamCustomizations = async ({
  accessToken,
  tenantId,
  params
}: SetCustomizationProps): Promise<any> => {
  const response = await api.post(
    `/${tenantId}/teams/customization/${params.teamSettings.teamId}`,
    // note: the backend will reject objects that don't match the format of Record<string, Record<string, any>>
    // so we have to omit the teamId here
    { ...params.teamSettings, teamId: undefined },
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return response.data;
};

export const getTeamNameFromTeam = (team: TeamInterface) => {
  if (team?.teamName) {
    return team.teamName;
  }

  if (team?.isOrgChartTeam) {
    const teamLead = team.teamMembers.find(
      t => t.id === team.orgChartTeamLeadId
    );
    return `${teamLead?.name}’s Team`;
  }

  if (!!team?.createdByUser) {
    return `Team created by ${team.createdByUser.name}`;
  }
};

export const getFallbackOrgChartTeamFromTeams = (
  teams: Array<TeamInterface>,
  userIdToMatch?: number | null
): TeamInterface | undefined =>
  teams?.find(
    (t: TeamInterface) =>
      t.isOrgChartTeam && t.orgChartTeamLeadId === userIdToMatch
  ) || teams?.find((t: TeamInterface) => t.isOrgChartTeam);

export const getFlattenedTeamMembersTreeForTeam = (
  team?: TeamInterface | null
): Array<UserReportWithMetadataInterface> =>
  (team?.teamMembers || []).concat(
    flattenDeep(
      team?.teamMembers.map((t: UserReportWithMetadataInterface) => {
        if (t.team) {
          return getFlattenedTeamMembersTreeForTeam(t.team);
        }
        return [];
      })
    )
  );

export const sortTeam = (
  teamMembers: Array<UserReportWithMetadataInterface>,
  user: UserInterface
): Array<UserReportWithMetadataInterface> =>
  teamMembers.sort((u1, u2) => {
    // if one of the team members is the active user, they should be first in the list. otherwise sort alphabetically
    if (u1.id === user.id) return -1;
    if (u2.id === user.id) return 1;
    return u1.name.localeCompare(u2.name);
  });
