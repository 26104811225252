import React from "react";
import styled from "styled-components/macro";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, IconName } from "@fortawesome/fontawesome-svg-core";

import {
  faSlack as slack,
  faJira as jira
} from "@fortawesome/free-brands-svg-icons";

import {
  faBookmark as bookmarkSaved,
  faCalendar as calendar,
  faCaretDown as triangleDown,
  faCaretLeft as triangleLeft,
  faCaretRight as triangleRight,
  faCaretUp as triangleUp,
  faCheck as check,
  faEdit as edit,
  faExclamationTriangle as warning,
  faHeadphones as headphones,
  faHome as house,
  faPlus as plus,
  faUsers as team,
  faUsersMedical as teamPlus,
  faCheckCircle as circleCheck,
  faArrowRight as lineArrowRight,
  faQuestion as question,
  faCog as cog,
  faUsers as users,
  faSitemap as sitemap,
  faStar as star,
  faAlicorn as alicorn,
  faBadgerHoney as honeyBadger,
  faBat as bat,
  faCat as cat,
  faCatSpace as catSpace,
  faCow as cow,
  faCrow as crow,
  faDeer as deer,
  faDeerRudolph as deerRudolph,
  faDog as dog,
  faDove as dove,
  faDragon as dragon,
  faDuck as duck,
  faElephant as elephant,
  faFish as fish,
  faFrog as frog,
  faGhost as ghost,
  faHippo as hippo,
  faHorse as horse,
  faHorseHead as horseHead,
  faKiwiBird as kiwi,
  faMonkey as monkey,
  faNarwhal as narwhal,
  faOtter as otter,
  faPegasus as pegasus,
  faPig as pig,
  faRabbit as rabbit,
  faRabbitFast as rabbitFast,
  faRam as ram,
  faRobot as robot,
  faSheep as sheep,
  faSkull as skull,
  faSkullCow as skullCow,
  faSkullCrossbones as skullCrossbones,
  faSnake as snake,
  faSnowman as snowman,
  faSpider as spider,
  faSquirrel as squirrel,
  faTurtle as turtle,
  faUnicorn as unicorn,
  faWhale as whale,
  faUserAlien as alien,
  faUserAstronaut as astronaut,
  faUserSecret as spy,
  faUserNinja as ninja,
  faUserCowboy as cowboy,
  faHeadVr as vr,
  faUserNurse as nurse,
  faUserMd as medicalWorker,
  faUserHardHat as builder,
  faEllipsisH as ellipsisHorizontal,
  faRandom as shuffle,
  faFlask as beaker,
  faLockAlt as lock,
  faExpandArrows as expandArrows,
  faCompressArrowsAlt as compressArrows
} from "@fortawesome/pro-solid-svg-icons";
import {
  faTimes as close,
  faExclamationTriangle as warningOutlineOnly,
  faPaperPlane as paperAirplane,
  faComment as comment,
  faQuestionCircle as questionCircle,
  faUsersCog as usersCog,
  faArrowTrendDown as arrowTrendDown,
  faArrowTrendUp as arrowTrendUp
} from "@fortawesome/pro-light-svg-icons";

import {
  faArrowLeft as lineArrowLeft,
  faBookmark as bookmarkUnsaved,
  faChevronDown as arrowDown,
  faChevronLeft as arrowLeft,
  faChevronRight as arrowRight,
  faChevronUp as arrowUp,
  faComments as chat,
  faCrosshairs as crosshairs,
  faExternalLinkAlt as externalLink,
  faInfoCircle as info,
  faSlidersH as sliders,
  faThumbsUp as thumbsUp,
  faAngry as angry,
  faEye as eye,
  faStar as starOutline,
  faRulerTriangle as rulerTriangle,
  faBook as book,
  faCodeMerge as codeMerge,
  faCommentsAlt as commentsAlt,
  faTimes as closeSolid
} from "@fortawesome/pro-regular-svg-icons";

import {
  faSort as unsorted,
  faSortDown as sortedDesc,
  faSortUp as sortedAsc
} from "@fortawesome/pro-duotone-svg-icons";

import bug from "../../images/bug.svg";
import exclamationTriangle from "../../images/exclamation-triangle.svg";
import disappointed from "../../images/heart-break_wolverine.svg";
import issue from "../../images/issue.svg";
import pr from "../../images/pr.svg";
import prActivityChevronApproved from "../../images/pr-activity-chevron-approved.svg";
import prActivityChevronCommented from "../../images/pr-activity-chevron-commented.svg";
import prActivityChevronUpdate from "../../images/pr-activity-chevron-update.svg";
import partyHorn from "../../images/Party Popper - Wolverine.svg";
import subtask from "../../images/subtask.svg";
import partyHornAurora from "../../images/Party Popper - Aurora.svg";
import disappointedAurora from "../../images/heart-break_aurora.svg";
import { pick } from "lodash";
import { teamAvatarIcons } from "../../constants/constants";

// styled components
const StyledIcon = styled.span`
  color: ${props => props.color || props.theme.colors.icon};
`;
const StyledIconImage = styled.img`
  display: inline-block;
`;

// typescript props
type FaIconsProps = {
  [key: string]: IconDefinition;
};
type CustomIconsProps = {
  [key: string]: string;
};
type Props = {
  className?: string;
  color?: string;
  icon: IconName | string;
  testId?: string;
};

export const FA_ICONS: FaIconsProps = {
  "arrow-down": arrowDown,
  "arrow-trend-down": arrowTrendDown,
  "arrow-left": arrowLeft,
  "arrow-right": arrowRight,
  "arrow-up": arrowUp,
  "arrow-trend-up": arrowTrendUp,
  "bookmark-saved": bookmarkSaved,
  "bookmark-unsaved": bookmarkUnsaved,
  "external-link": externalLink,
  "logo-slack": slack,
  "paper-airplane": paperAirplane,
  "question-circle": questionCircle,
  "users-cog": usersCog,
  "sorted-asc": sortedAsc,
  "sorted-desc": sortedDesc,
  "team-plus": teamPlus,
  "thumbs-up": thumbsUp,
  "triangle-down": triangleDown,
  "triangle-left": triangleLeft,
  "triangle-right": triangleRight,
  "triangle-up": triangleUp,
  "warning-outline-only": warningOutlineOnly,
  "circle-check": circleCheck,
  "line-arrow-left": lineArrowLeft,
  "line-arrow-right": lineArrowRight,
  "cat-space": catSpace,
  "honey-badger": honeyBadger,
  "deer-rudolph": deerRudolph,
  "horse-head": horseHead,
  "rabbit-fast": rabbitFast,
  "skull-cow": skullCow,
  "skull-crossbones": skullCrossbones,
  "medical-worker": medicalWorker,
  "ellipsis-horizontal": ellipsisHorizontal,
  "ruler-triangle": rulerTriangle,
  "code-merge": codeMerge,
  "comments-alt": commentsAlt,
  "close-solid": closeSolid,
  "expand-arrows": expandArrows,
  "compress-arrows": compressArrows,
  alicorn,
  bat,
  cat,
  cow,
  crow,
  deer,
  dog,
  dove,
  dragon,
  duck,
  elephant,
  fish,
  frog,
  ghost,
  hippo,
  horse,
  kiwi,
  monkey,
  narwhal,
  otter,
  pegasus,
  pig,
  rabbit,
  ram,
  robot,
  sheep,
  skull,
  snake,
  snowman,
  spider,
  squirrel,
  turtle,
  unicorn,
  whale,
  question,
  comment,
  calendar,
  chat,
  check,
  close,
  crosshairs,
  edit,
  headphones,
  house,
  info,
  plus,
  sliders,
  angry,
  team,
  unsorted,
  warning,
  cog,
  eye,
  users,
  sitemap,
  star,
  starOutline,
  alien,
  astronaut,
  spy,
  ninja,
  cowboy,
  vr,
  nurse,
  builder,
  shuffle,
  book,
  jira,
  beaker,
  lock
};

export const CUSTOM_ICONS: CustomIconsProps = {
  "warning-outlined": exclamationTriangle,
  bug,
  issue,
  pr,
  "pr-activity-chevron-approved": prActivityChevronApproved,
  "pr-activity-chevron-commented": prActivityChevronCommented,
  "pr-activity-chevron-update": prActivityChevronUpdate,
  subtask,
  "party-horn": partyHorn,
  "party-horn-aurora": partyHornAurora,
  disappointed,
  "disappointed-aurora": disappointedAurora
};

export const TEAM_ICONS: FaIconsProps = pick(FA_ICONS, teamAvatarIcons);

export const PERSON_ICONS: FaIconsProps = pick(FA_ICONS, [
  "alien",
  "astronaut",
  "builder",
  "cowboy",
  "medical-worker",
  "ninja",
  "nurse",
  "spy",
  "vr"
]);

const Icon = ({ color, className, icon, testId = "testId" }: Props) => {
  const customIconKeys = Object.keys(CUSTOM_ICONS);

  const iconKeys = [...Object.keys(FA_ICONS), ...customIconKeys];
  if (!iconKeys.includes(icon)) {
    return null;
  }

  return customIconKeys.includes(icon) ? (
    <StyledIconImage
      alt={icon}
      className={className}
      data-testid={`${testId}-icon-${icon}`}
      src={CUSTOM_ICONS[icon]}
    />
  ) : (
    <StyledIcon
      className={className}
      color={color}
      data-testid={`${testId}-icon-${icon}`}
    >
      <FontAwesomeIcon icon={FA_ICONS[icon]} />
    </StyledIcon>
  );
};

export default Icon;
