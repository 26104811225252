import { omit } from "lodash";

// constants
import { timerangeTypes, urlParamKeys } from "../constants/constants";

// hooks
import { useSelector } from "react-redux";
import { useQuery, UseQueryResult } from "react-query";
import { useUrlParams } from "./useUrlParams";

// interfaces
import {
  EpicDetailsInterface,
  GroupsListInterface,
  WorkItemsRequestParamsInterface
} from "../interfaces/work-items";
import { AppStateInterface } from "../interfaces/app-state";

// utils
import { requestWorkItems } from "../utils/work-deep-dive";
import {
  getSprintRetrosRequestParamsFromWorkItemsRequestParams,
  getWorkItemsRequestParamsPRWorkflow
} from "../utils/url-params";

type KeyProps = {
  type: string;
  previous?: boolean;
};
export const useWorkItemsData = ({
  options,
  canRequest = true
}: {
  options?: KeyProps;
  canRequest?: boolean;
} = {}): UseQueryResult<EpicDetailsInterface> => {
  const { workItemsRequestParams, urlParams } = useUrlParams();
  const { timerange } = urlParams;
  const isKanbanMode = timerange.timerangeType !== timerangeTypes.SPRINTS;

  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );

  let dataRequestParams =
    options?.type === "retros"
      ? getSprintRetrosRequestParamsFromWorkItemsRequestParams(
          workItemsRequestParams
        )
      : options?.type === "pr-workflow"
      ? getWorkItemsRequestParamsPRWorkflow(workItemsRequestParams)
      : workItemsRequestParams;

  // if we are in kanban mode, there are some keys that shouldn't be passed
  // along to the work-items call, so filter those out -- since we're mixing
  // where we're building our request params between here and utils, do one
  // final sanity check here for now
  if (isKanbanMode) {
    dataRequestParams = omit(dataRequestParams, [
      urlParamKeys.SPRINT_WORK_TYPES,
      urlParamKeys.SPRINT_WORK_OTHER,
      urlParamKeys.SPRINT_ID
    ]) as WorkItemsRequestParamsInterface;
  }

  return useQuery(
    ["work-items", { ...options, ...dataRequestParams }],
    () =>
      requestWorkItems({
        accessToken,
        tenantId,
        params: dataRequestParams
      }).then(response =>
        !!dataRequestParams.epicId
          ? (response as EpicDetailsInterface)
          : (response as GroupsListInterface)
      ),
    {
      enabled:
        !!accessToken &&
        !!tenantId &&
        !!dataRequestParams?.allUsers.length &&
        canRequest,
      refetchOnWindowFocus: false
    }
  );
};
