import React from "react";
import styled from "styled-components/macro";
import { findIndex, kebabCase, min, sortBy } from "lodash";

// components
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import PagedStepper, {
  DEFAULT_NUM_VISIBLE_ITEMS
} from "../PagedStepper/PagedStepper";
import Tooltip from "../Tooltip/Tooltip";

// hooks
import { useTracking } from "../../hooks/useTracking";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { TeamInterface } from "../../interfaces/team";

// utils
import { getTeamNameFromTeam } from "../../utils/teams";
import AllTeamsMenu from "../AllTeamsMenu/AllTeamsMenu";

export const MAX_TEAM_MEMBERS_PER_COLUMN = 15;

// styled components
const Container = styled.nav`
  align-items: center;
  display: flex;
`;
const IconWrapper = styled.button<{ isActive: boolean }>`
  background: none;
  border: 0;
  border-bottom: ${props =>
    `${props.theme.borders.widths.md} solid ${
      props.isActive ? props.theme.colors.all.wolverine : "transparent"
    }`};
  padding: 0 0 1rem;
`;
const Stepper = styled(PagedStepper)`
  & ul {
    gap: 0.8rem;
  }
`;
const Icon = styled(AvatarIcon)`
  font-size: 3rem;
`;
const TeamPopup = styled.div`
  font-size: 1.1rem;
`;
const PopupHeader = styled.header`
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const DefinitionList = styled.dl`
  margin-top: 0.8rem;
`;
const DefinitionWrapper = styled.div`
  & + & {
    margin-top: 0.4rem;
  }
`;
const Term = styled.dt`
  font-weight: ${props => props.theme.fonts.primary.weights.regular};

  &::after {
    content: ":";
  }
`;
const List = styled.ul<{ listLength: number }>`
  && {
    border-top: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    padding: 0.8rem 0 0;
    margin-top: 0.8rem;
  }
  columns: ${props =>
    `${Math.ceil(props.listLength / MAX_TEAM_MEMBERS_PER_COLUMN)}`};
  gap: 2rem;
`;
const StyledListItem = styled.li`
  list-style-type: none;
  max-width: 12rem;
  word-break: break-word;
`;
const AllTeams = styled(AllTeamsMenu)`
  margin-left: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  teams: Array<TeamInterface>;
  testId?: string;
  onClickTeam: ({
    e,
    team
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    team: TeamInterface;
  }) => void;
  selectedTeam?: TeamInterface | null;
};

const FlexibleTeamsNavigation = ({
  className,
  teams,
  testId = "testId",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClickTeam,
  selectedTeam
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-flexible-teams-navigation`;
  const { trackEvent } = useTracking();

  function onShowTooltip({
    e,
    team
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    team: TeamInterface;
  }) {
    e.persist();
    trackEvent({
      e,
      label: "flexible-teams-navigation-tooltip",
      value: `hovered ${team.teamName} team`
    });
  }

  const foundTeamIndex = findIndex(
    teams,
    t => t.teamId === selectedTeam?.teamId
  );
  // substitute 0 if the found index returns -1 so that the paged stepper doesn't throw up
  const activeIndex = foundTeamIndex >= 0 ? foundTeamIndex : 0;
  const stepperIsPaged = teams.length > DEFAULT_NUM_VISIBLE_ITEMS;
  return (
    <Container className={className} data-testid={thisTestId}>
      <Stepper
        activeIndex={activeIndex}
        items={teams.map((t, index) => (
          <Tooltip
            onShowTooltip={(
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => onShowTooltip({ e, team: t })}
            placement="bottom"
            popupContent={
              <TeamPopup data-heap-redact-text="true">
                <PopupHeader>{getTeamNameFromTeam(t)}</PopupHeader>
                {t.createdByUser ? (
                  <DefinitionList>
                    <DefinitionWrapper>
                      <Term>Created by</Term>
                      <dd>{t.createdByUser.name}</dd>
                    </DefinitionWrapper>
                  </DefinitionList>
                ) : null}
                <List
                  listLength={t.teamMembers.length}
                  data-testid={`${thisTestId}-team-members-list`}
                >
                  {sortBy(t.teamMembers, "name").map(teamMember => (
                    <StyledListItem
                      key={kebabCase(teamMember.name)}
                      data-heap-redact-text="true"
                    >
                      {teamMember.name}
                    </StyledListItem>
                  ))}
                </List>
              </TeamPopup>
            }
            trigger={
              <IconWrapper
                onClick={e => onClickTeam({ e, team: t })}
                // don't mark anything active if the team wasn't found in the teams list
                isActive={
                  !!selectedTeam
                    ? t.teamId === selectedTeam.teamId
                    : foundTeamIndex >= 0 && activeIndex === index
                }
                key={t.teamId}
                data-testid={`${kebabCase(t.teamName)}-button`}
              >
                <Icon
                  avatarColor={t.avatarColor as string}
                  avatarIcon={t.avatarIcon as string}
                  testId={thisTestId}
                />
              </IconWrapper>
            }
            testId={`${thisTestId}-${kebabCase(t.teamName)}`}
          />
        ))}
        numVisibleItems={
          stepperIsPaged ? DEFAULT_NUM_VISIBLE_ITEMS : teams.length
        }
      />
      {stepperIsPaged ? (
        <AllTeams onClickTeam={onClickTeam} teams={teams} testId={thisTestId} />
      ) : null}
    </Container>
  );
};

export default FlexibleTeamsNavigation;
