import React from "react";
import styled from "styled-components/macro";

// components
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

// styled components
const StyledIconClose = styled(Icon)`
  color: ${props => props.theme.colors.modal.closeButton};
`;
const StyledButton = styled(Button)`
  font-size: 2rem;
`;

// typescript props
type Props = {
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  testId?: string;
};

const CloseButton = ({ className, onClick, testId = "testId" }: Props) => {
  const thisTestId = `${testId}-close`;
  return (
    <StyledButton
      button="unstyled"
      className={className}
      onClick={onClick}
      testId={thisTestId}
    >
      <StyledIconClose icon="close" testId={thisTestId} />
    </StyledButton>
  );
};

export default CloseButton;
