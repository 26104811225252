import React, { useState } from "react";
import styled from "styled-components/macro";
import { isNull } from "lodash";
import * as yup from "yup";

// components
import GridCol from "../GridCol/GridCol";
import GridContainer from "../GridContainer/GridContainer";
import GridRow from "../GridRow/GridRow";

// styled components
const StyledContainer = styled.section`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  height: 100vh;
  justify-content: center;
`;
const StyledGridContainer = styled(GridContainer)`
  max-width: 50rem;
`;
const StyledGridCol = styled(GridCol)`
  text-align: center;
`;
const Title = styled.h1`
  font-family: ${props => props.theme.fonts.header.name}, Arial, Helvetica,
    sans-serif;
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
  padding: 0;
  font-size: 5rem;
  margin-bottom: 2rem;
`;
const StyledForm = styled.form`
  margin-top: 2rem;
`;
const SubmitButton = styled.input`
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  padding: 0;
  color: ${props => props.theme.colors.button.default.default.bg};
  transition: 0.2s all;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  border-color: ${props => props.theme.colors.button.default.default.border};
  border-style: solid;
  border-width: ${props => props.theme.borders.widths.md};
  padding: 1.8rem 2.5rem;
  border-radius: ${props => props.theme.colors.button.border.radius};

  margin-top: 2rem;

  &&:hover {
    background-color: ${props => props.theme.colors.button.default.hover.bg};
    border-color: ${props => props.theme.colors.button.default.hover.border};
    color: ${props => props.theme.colors.button.default.hover.fg};
  }
`;
const Error = styled.strong`
  color: ${props => props.theme.colors.all.cyclops};
  display: block;
  font-size: 1.1rem;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin-top: 0.4rem;
`;

// typescript props
type Props = {
  onSubmit: (domain: string) => void;
};

const DomainForm = ({ onSubmit }: Props) => {
  const thisTestId = "domain-form";
  const [emailIsValid, setEmailIsValid] = useState<boolean | null>(null);

  const validateEmailAddress = async (valueToValidate: string) =>
    await yup
      .string()
      .email()
      .isValid(valueToValidate);

  async function onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
    if (!isNull(emailIsValid)) {
      const inputIsValid = await validateEmailAddress(e.target.value);
      setEmailIsValid(inputIsValid);
    }
  }

  async function onSubmitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const emailAddress = formData.get("emailAddress") as string;
    const emailAddressIsValid = await validateEmailAddress(emailAddress);
    if (emailAddressIsValid) {
      const domain = emailAddress.substring(emailAddress.indexOf("@") + 1);
      onSubmit(domain);
    } else {
      setEmailIsValid(emailAddressIsValid);
    }
  }

  return (
    <StyledContainer data-testid={thisTestId}>
      <StyledGridContainer>
        <GridRow>
          <StyledGridCol>
            <header>
              <Title>Welcome</Title>
              <p>
                Existing customers, please login with your corporate email. If
                you are having trouble logging in, please email{" "}
                <a href="mailto:support@uplevelteam.com">
                  support@uplevelteam.com
                </a>
                .
              </p>

              <p>
                If you are not a customer and are interested in access, please{" "}
                <a href="http://www.uplevelteam.com">
                  click here to schedule a demo
                </a>
                .
              </p>
            </header>
          </StyledGridCol>
        </GridRow>
        <GridRow>
          <StyledGridCol>
            <StyledForm
              onSubmit={onSubmitForm}
              data-testid={`${thisTestId}-element`}
            >
              <fieldset>
                <label className="visuallyHidden" htmlFor="emailAddress">
                  Email address
                </label>
                <input
                  onChange={onChangeInput}
                  type="text"
                  name="emailAddress"
                  id="emailAddress"
                  placeholder="name@domain.com"
                  required
                  data-testid={`${thisTestId}-input`}
                />
              </fieldset>
              {isNull(emailIsValid) || !!emailIsValid ? null : (
                <Error data-testid="error">
                  Please enter a valid email address
                </Error>
              )}
              <SubmitButton
                type="submit"
                value="Submit"
                data-testid={`${thisTestId}-submit`}
              />
            </StyledForm>
          </StyledGridCol>
        </GridRow>
      </StyledGridContainer>
    </StyledContainer>
  );
};

export default DomainForm;
