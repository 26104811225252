import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// components
import Icon from "../Icon/Icon";

// constants
import { directionalityTypes } from "../../constants/constants";

// styled components
const Container = styled.div<{ direction: string; isInverted: boolean }>`
  color: ${props =>
    props.isInverted
      ? props.direction === directionalityTypes.NEGATIVE
        ? props.theme.colors.all.petra
        : props.theme.colors.all.cyclops
      : props.direction === directionalityTypes.POSITIVE
      ? props.theme.colors.all.petra
      : props.theme.colors.all.cyclops};
  display: inline-block;
`;
const Value = styled.span`
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
`;
const Arrow = styled(Icon)`
  color: inherit;
  margin-left: 0.5em;
`;

// typescript props
type Props = {
  className?: string;
  direction: string;
  display?: "number" | "text";
  isInverted?: boolean;
  testId?: string;
  unit?: string;
  value: number;
};

const ValueWithDirectionality = ({
  className,
  direction,
  display = "number",
  isInverted = false,
  testId = "testId",
  unit,
  value
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-value-with-directionality`;
  if (value === 0) {
    return null;
  }
  const absoluteValue = Math.abs(value);
  const displayValue =
    value > 0 ? (
      display === "number" ? (
        <>
          +<Value>{absoluteValue}</Value>
        </>
      ) : isInverted ? (
        "Worse"
      ) : (
        "Better"
      )
    ) : display === "number" ? (
      <>
        -<Value>{absoluteValue}</Value>
      </>
    ) : isInverted ? (
      "Better"
    ) : (
      "Worse"
    );

  return (
    <Container
      className={className}
      data-testid={thisTestId}
      direction={direction}
      isInverted={isInverted}
    >
      {displayValue}
      {display === "number" ? unit : null}
      <Arrow
        icon={`arrow-trend-${
          direction === directionalityTypes.POSITIVE ? "up" : "down"
        }`}
        testId={thisTestId}
      />
    </Container>
  );
};

export default ValueWithDirectionality;
