import React from "react";
import styled, { css } from "styled-components/macro";
import { sortBy } from "lodash";

// components
import SlackAvatar from "../SlackAvatar/SlackAvatar";

// interfaces
import {
  NewUser,
  UserReportWithMetadataInterface
} from "../../interfaces/user";

// styled components
const TeamMembersList = styled.ul`
  overflow-y: scroll;
`;
const TeamMember = styled.li`
  align-items: center;
  display: flex;
`;
const TeamMemberAvatar = styled(SlackAvatar)<{ isPending: boolean }>`
  border-radius: 50%;
  border: ${props => `.2rem solid ${props.theme.colors.all.wolverine}`};
  height: 3rem;
  margin-right: 1rem;
  width: 3rem;

  ${props => {
    if (props.isPending) {
      return css`
        opacity: 0.5;
      `;
    }
  }}
`;
const TeamMemberNameAndTitle = styled.div<{ isPending: boolean }>`
  flex-grow: 1;

  ${props => {
    if (props.isPending) {
      return css`
        opacity: 0.5;
      `;
    }
  }}
`;
const TeamMemberName = styled.header`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  line-height: ${props => props.theme.fonts.primary.sizes.xs};
`;
const TeamMemberTitle = styled.p`
  font-size: 1.1rem;
  margin: 0;
`;
const Remove = styled.span`
  align-self: flex-end;
  color: ${props => props.theme.colors.all.cyclops};
  font-size: 1.1rem;
  bottom: 1rem;
  right: 1rem;
  visibility: hidden;
`;
const RemoveButton = styled.button<{ isEditing: boolean }>`
  align-items: center;
  background: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: ${props => props.theme.borders.widths.sm};
  display: flex;
  flex: 1;
  padding: 0.4rem 0.25rem;
  text-align: left;

  &:hover {
    border-color: ${props => props.theme.colors.all.jean};

    & ${Remove} {
      visibility: ${props => (props.isEditing ? "visible" : "hidden")};
    }
  }
`;

const SpacingWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0.4rem 0.25rem;
`;

// typescript props
type Props = {
  className?: string;
  isEditing?: boolean;
  onRemoveTeamMember?: ({
    e,
    teamMember
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    teamMember: UserReportWithMetadataInterface | NewUser;
  }) => void;
  teamMembers: Array<UserReportWithMetadataInterface | NewUser>;
  testId?: string;
};

const TeamCardTeamMembers = ({
  className,
  isEditing,
  onRemoveTeamMember,
  teamMembers,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-team-card-team-members`;
  return (
    <TeamMembersList className={className} data-testid={thisTestId}>
      {sortBy(teamMembers, t => t.name.toLowerCase()).map(teamMember => {
        const displayContent = (
          <>
            <TeamMemberAvatar
              altText={teamMember.name}
              isPending={!teamMember.id}
              src={teamMember.slackAvatar}
              testId={`${thisTestId}-${teamMember.id}`}
            />
            <TeamMemberNameAndTitle
              isPending={/pending/.test(teamMember.title.toLowerCase())}
              data-heap-redact-text="true"
            >
              <TeamMemberName>{teamMember.name}</TeamMemberName>
              <TeamMemberTitle>{teamMember.title}</TeamMemberTitle>
            </TeamMemberNameAndTitle>
            <Remove>remove</Remove>
          </>
        );
        return (
          <TeamMember key={teamMember.email}>
            {isEditing ? (
              <RemoveButton
                isEditing={isEditing}
                onClick={e =>
                  onRemoveTeamMember && onRemoveTeamMember({ e, teamMember })
                }
                data-testid={`remove-${teamMember.email}-button`}
              >
                {displayContent}
              </RemoveButton>
            ) : (
              <SpacingWrapper>{displayContent}</SpacingWrapper>
            )}
          </TeamMember>
        );
      })}
    </TeamMembersList>
  );
};

export default TeamCardTeamMembers;
