import React, { ReactElement, useMemo } from "react";
import styled from "styled-components/macro";
import { isEmpty, isNil, partition, pick, pickBy } from "lodash";

// components
import CycleTimeByGroupTable from "../CycleTimeByGroupTable/CycleTimeByGroupTable";
import RetrosFactorsList from "../RetrosFactorsList/RetrosFactorsList";
import RetrosTrendAnalysis from "../RetrosTrendAnalysis/RetrosTrendAnalysis";
import WorkMetadataReactionsRollup from "../WorkMetadataReactionsRollup/WorkMetadataReactionsRollup";

// hooks
import { useSelector } from "react-redux";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { AppStateInterface } from "../../interfaces/app-state";
import { SprintHealthInterface } from "../../interfaces/sprint-metadata";
import { GroupInterface } from "../../interfaces/work-items";
import { TimerangeMetadataInterface } from "../../interfaces/constants";

// utils
import {
  filterAnnotationsForGroup,
  filterSprintHealthFactorsToRange
} from "../../utils/sprints";
import { AnnotationsInterface } from "../../interfaces/annotations";
import {
  negativeSignals,
  neutralSignals,
  positiveSignals,
  signalTypes
} from "../../constants/constants";
import { GroupsListInterface } from "../../interfaces/work-items";

// styled components
const ContentWrapper = styled.div`
  align-items: start;
  display: grid;
  gap: ${props => props.theme.grid.gap};
  grid-template-columns: 1fr 1fr;
`;
const Header = styled.header`
  margin-bottom: 2rem;
`;
const FactorsAndCycleTime = styled.div`
  flex: 1;
`;
const ReactionsOrCycleTime = styled.div`
  flex: 1;
`;
const Factors = styled.div`
  align-items: start;
  display: grid;
  gap: ${props => props.theme.grid.gap};
  grid-template-columns: 1fr 1fr;
`;
const FactorsListHeader = styled.span`
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const CycleTime = styled(CycleTimeByGroupTable)`
  ${Factors} + & {
    margin-top: 5rem;
  }
`;
const TrendAnalysis = styled(RetrosTrendAnalysis)`
  margin-top: 5rem;
`;

// typescript props
type Props = {
  annotations?: Array<AnnotationsInterface>;
  className?: string;
  sectionType: string;
  sprintHealthFactorsData: SprintHealthInterface;
  sprintCompletionData?: GroupsListInterface;
  header: React.ReactNode;
  range: Array<number>;
  sprint: TimerangeMetadataInterface;
  testId?: string;
};

const RetrosRangeFactorsContainer = ({
  annotations = [],
  className,
  header,
  range,
  sectionType,
  sprint,
  sprintCompletionData,
  sprintHealthFactorsData,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-retros-range-factors-container`;
  const currentSprintHealthFactorsData = sprintHealthFactorsData[sprint.id];
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const hasRetrosReactions = flags?.["retros-annotations"];
  const hasPeopleHealthData = flags?.["people-health-insights"];

  const { urlParams } = useUrlParams();
  const selectedGroupBy = urlParams.groupBy;

  const [filteredPeopleFactors, filteredProjectFactors] = [
    currentSprintHealthFactorsData?.peopleHealthFactors || {},
    currentSprintHealthFactorsData?.projectHealthFactors || {}
  ].map(factors =>
    pick(
      pickBy(factors, f => !isNil(f)),
      filterSprintHealthFactorsToRange(Object.entries(factors), range)
    )
  ) as Array<{ [key: string]: number }>;

  const filteredWorkItems = useMemo(() => {
    if (!!sprintCompletionData) {
      return sprintCompletionData.workMetadata.filter(
        (group: GroupInterface) => {
          const annotationsForGroup = filterAnnotationsForGroup({
            annotations,
            group,
            groupBy: selectedGroupBy
          });

          if (!isEmpty(annotationsForGroup)) {
            // if every annotation for this group is a comment or question and there are no other reactions,
            // bucket it as unhealthy / look into
            if (
              annotationsForGroup.every(
                a =>
                  isEmpty(a.userAnnotationCheckinStatus) ||
                  neutralSignals.includes(a.userAnnotationCheckinStatus)
              )
            ) {
              return sectionType === signalTypes.UNHEALTHY;
            }

            // otherwise, proceed with bucketing logic based on which one has more non-comment reactions
            const annotationsWithNonNeutralReactions = annotationsForGroup.filter(
              a =>
                (!!a.userAnnotationCheckinStatus &&
                  positiveSignals.includes(a.userAnnotationCheckinStatus)) ||
                negativeSignals.includes(a.userAnnotationCheckinStatus)
            );
            const [
              positiveReactions,
              negativeReactions
            ] = partition(annotationsWithNonNeutralReactions, a =>
              positiveSignals.includes(a.userAnnotationCheckinStatus)
            );
            if (sectionType === signalTypes.HEALTHY) {
              return positiveReactions.length > negativeReactions.length;
            } else {
              return negativeReactions.length >= positiveReactions.length;
            }
          }
          return false;
        }
      );
    }
    return [];
  }, [annotations, sectionType, selectedGroupBy, sprintCompletionData]);

  return (
    <div className={className} data-testid={thisTestId}>
      <Header>{header}</Header>
      <ContentWrapper>
        <FactorsAndCycleTime>
          <Factors>
            <RetrosFactorsList
              header={<FactorsListHeader>Project Health</FactorsListHeader>}
              factors={filteredProjectFactors}
              sectionType={sectionType}
              testId={thisTestId}
            />
            {hasPeopleHealthData ? (
              <RetrosFactorsList
                header={<FactorsListHeader>People Health</FactorsListHeader>}
                factors={filteredPeopleFactors}
                sectionType={sectionType}
                testId={thisTestId}
              />
            ) : null}
          </Factors>
          {/* if annotations are turned on, cycle time is in left column */}
          {hasRetrosReactions ? (
            <CycleTime
              groupedWorkItems={sprintCompletionData?.workMetadata}
              testId={thisTestId}
              sectionType={sectionType}
            />
          ) : null}
        </FactorsAndCycleTime>
        <ReactionsOrCycleTime>
          {/* if annotations are turned off, cycle time replaces them in right column */}
          {hasRetrosReactions ? (
            <WorkMetadataReactionsRollup
              annotations={annotations}
              data={filteredWorkItems}
            />
          ) : (
            <CycleTime
              groupedWorkItems={sprintCompletionData?.workMetadata}
              testId={thisTestId}
              sectionType={sectionType}
            />
          )}
        </ReactionsOrCycleTime>
      </ContentWrapper>
      <TrendAnalysis
        data={sprintHealthFactorsData}
        range={range}
        sprint={sprint}
        testId={thisTestId}
      />
    </div>
  );
};

export default RetrosRangeFactorsContainer;
