// hooks
import { useTeamsData } from "./useTeamsData";
import { useOwnFallbackOrgChartTeam } from "./useOwnFallbackOrgChartTeam";
import { useActiveUser } from "./useActiveUser";

// interfaces
import { UserReportWithMetadataInterface } from "../interfaces/user";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../interfaces/app-state";

export const useActiveUsersTeams = () => {
  // user's default and fallback org chart teams
  const user = useSelector((state: AppStateInterface) => state.user);
  const ownFallbackOrgChartTeam = useOwnFallbackOrgChartTeam();
  const activeUser = useActiveUser();
  // if an active user is selected from the global nav, use the team for that user.
  // if the root user is the team lead for the default org chart team,
  // use the default org team. otherwise, just request for the root user.
  const teamMembersToRequest =
    (activeUser as UserReportWithMetadataInterface)?.team?.teamMembers ||
    (ownFallbackOrgChartTeam?.orgChartTeamLeadId === user.id
      ? ownFallbackOrgChartTeam?.teamMembers
      : [user]);

  return useTeamsData(
    {
      type: "active-users-teams",
      teamMembersToRequest
    },
    // wait to fetch teams until we have dependencies lined up
    !!teamMembersToRequest
  );
};
