import React from "react";

// typescript props
type Props = {
  className?: string;
  content: React.ReactNode;
  header?: React.ReactNode;
  testId?: string;
};

const PopupContent = ({
  className,
  content,
  header,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-popup-content`;
  return (
    <div className={className} data-testid={thisTestId}>
      {!!header ? <header>{header}</header> : null}
      {content}
    </div>
  );
};

export default PopupContent;
