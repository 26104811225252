import React, { useEffect, useMemo, useState } from "react";
import { get, last } from "lodash";
import moment from "moment";

// utils
import { getTimestampForDate } from "../../utils/date";
import { requestStoryPoints } from "../../utils/story-points";
import { captureException, withScope } from "@sentry/react";

export const useBackingData = (
  manualState,
  accessToken,
  tenantId,
  user,
  team,
  startDate,
  endDate
) => {
  const [data, _setData] = useState([]);
  // not using the setters currently, because there's no changing
  // the date range
  // if startDate and endDate are undefined, use the last 2 weeks as the default
  const [defaultStartDate, setDefaultStartDate] = useState(
    getTimestampForDate(
      moment(new Date())
        .startOf("day")
        .subtract(15, "days")
    )
  );
  const [defaultEndDate, setDefaultEndDate] = useState(
    getTimestampForDate(moment(new Date()).startOf("day"))
  );

  const [isFetchingData, _setIsFetchingData] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [error, _setError] = useState(null);
  const requestProps = useMemo(() => {
    return {
      accessToken,
      // end date is inclusive on the backend, so we have to shift by 1 day
      endDate: !!endDate
        ? moment(endDate)
            .subtract(1, "days")
            .toISOString()
        : defaultEndDate,
      // for start date we go one day earlier to show day/day diffs
      startDate: !!startDate
        ? moment(startDate)
            .subtract(1, "days")
            .toISOString()
        : defaultStartDate,
      tenantId,
      user,
      team
    };
  }, [
    accessToken,
    defaultEndDate,
    defaultStartDate,
    tenantId,
    user,
    team,
    startDate,
    endDate
  ]);

  useEffect(() => {
    (async () => {
      if (manualState) {
        _setData(manualState.data);
        setSelectedDate(manualState.selectedDate);
      } else {
        try {
          if (!error) {
            _setIsFetchingData(true);
            const responseData = await requestStoryPoints({
              accessToken: requestProps.accessToken,
              tenantId: requestProps.tenantId,
              params: {
                tenantId: requestProps.tenantId,
                startDate: requestProps.startDate,
                endDate: requestProps.endDate,
                users: requestProps.team.map(t => t.email)
              }
            });
            _setData(responseData.responseByDate);
            setSelectedDate(
              get(last(responseData.responseByDate), "startDate")
            );
            _setIsFetchingData(false);
          }
        } catch (e) {
          _setError(e);
          _setIsFetchingData(false);
          withScope(scope => {
            scope.setContext("request-story-points", requestProps);
            captureException(e);
          });
        }
      }
    })();
  }, [manualState, error, requestProps]);

  return [selectedDate, data, isFetchingData, setSelectedDate, error];
};
