import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components/macro";
import { isNull } from "lodash";

// components
import MeetingClassificationOverrideConfirmation from "../MeetingClassificationOverrideConfirmation/MeetingClassificationOverrideConfirmation";
import MeetingClassificationOverrideForm from "../MeetingClassificationOverrideForm/MeetingClassificationOverrideForm";
import MeetingList from "../MeetingList/MeetingList";
import Modal from "../Modal/Modal";

// hooks
import { useTracking } from "../../hooks/useTracking";

// styled components
const Container = styled.div`
  border-top: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.storm}`};
  font-family: ${props => props.theme.fonts.primary.name}, Arial, Helvetica,
    sans-serif;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  padding-top: 1rem;
`;
const StyledMeetingList = styled(MeetingList)`
  max-height: 50rem;
`;

export const MeetingListSummary = ({
  className,
  error,
  meetings = [],
  testId = "testId",
  handleOverride,
  theme,
  name
}) => {
  const thisTestId = `${testId}-meeting-list-summary`;
  const { trackEvent } = useTracking();
  const [meetingToEdit, setMeetingToEdit] = useState(null);
  const [modalDisplay, setModalDisplay] = useState(null);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  // slightly jank comparator for useEffect dependency array
  const meetingsStringified = JSON.stringify(meetings);

  useEffect(() => {
    if (!!error) {
      setModalDisplay("error");
    } else {
      setModalDisplay("success");
    }
  }, [error, meetingsStringified]);

  function onSubmitOverride(overrideParams) {
    handleOverride(overrideParams);
  }

  function onClickResumeEditing() {
    setModalDisplay("form");
    trackEvent({
      label: `${name}-meeting-classification-override__resume-editing`,
      value: "clicked"
    });
  }

  function onCancelModal(isConfirmationButton) {
    setModalIsVisible(false);
    setModalDisplay(null);
    setMeetingToEdit(null);
    trackEvent({
      label: `${name}-meeting-classification-override__${
        !!isConfirmationButton ? "confirmation-button" : `modal-x-close`
      }`,
      value: "clicked"
    });
  }

  function onClickEdit(meeting, listName) {
    setModalIsVisible(true);
    setModalDisplay("form");
    setMeetingToEdit(meeting);
    trackEvent({
      label: `${listName}-meeting-classification-override__meeting-name`,
      value: `${meeting.title} clicked`
    });
  }

  let modalContent;
  if (modalDisplay === "form") {
    modalContent = (
      <MeetingClassificationOverrideForm
        name={name}
        meeting={meetingToEdit}
        onSubmit={onSubmitOverride}
      />
    );
  } else {
    modalContent = (
      <MeetingClassificationOverrideConfirmation
        isSuccess={modalDisplay === "success"}
        onClickResumeEditing={onClickResumeEditing}
        onClickConfirmation={onCancelModal}
        testId={thisTestId}
      />
    );
  }

  return (
    <Container className={className} data-testid={thisTestId}>
      {modalIsVisible ? (
        <Modal
          dialogClassName="meeting-classification-override-modal"
          onHide={onCancelModal}
          popupContent={modalContent}
          testId={testId}
        />
      ) : null}
      <StyledMeetingList
        isEditing={!isNull(meetingToEdit)}
        list={meetings}
        testId={thisTestId}
        onClickEdit={meeting => onClickEdit(meeting, name)}
      />
    </Container>
  );
};

MeetingListSummary.propTypes = {
  className: PropTypes.string,
  meetings: PropTypes.array,
  name: PropTypes.string.isRequired,
  onCancelOverrideSuccessMessageTrigger: PropTypes.func,
  overrideOption: PropTypes.object,
  shouldTriggerOverrideSuccessMessage: PropTypes.bool,
  testId: PropTypes.string.isRequired
};

export default withTheme(MeetingListSummary);
