import React, { useMemo } from "react";
import styled, { css } from "styled-components/macro";
import {
  useTable,
  useFilters,
  useSortBy,
  TableInstance,
  ColumnInstance
} from "react-table";
import { get, invoke, isEmpty } from "lodash";

// components
import SortableColumnHeader from "../SortableColumnHeader/SortableColumnHeader";

// styled components
const Container = styled.table`
  text-align: left;
`;
const StyledTr = styled.tr`
  &:hover {
    background: ${props => props.theme.colors.table.hover};
  }
`;
const StyledTh = styled.th`
  padding-bottom: 1rem;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const StyledTd = styled.td`
  line-height: 1em;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  vertical-align: top;

  &:last-child {
    padding-right: 0;
  }
`;
const ClickWrapper = styled.div<{ isClickable: boolean }>`
  ${props => {
    if (props.isClickable) {
      return css`
        cursor: pointer;
      `;
    }
  }}
`;

// typescript props
type Props = TableInstance & {
  allowSorting?: boolean;
  caption?: string;
  className?: string;
  manuallySort?: boolean;
  testId?: string;
};

const Table = ({
  allowSorting = true,
  caption,
  className,
  columns = [],
  controlledState = {},
  data = [],
  getRowProps = () => ({}),
  initialState = {},
  manuallySort = false,
  testId = "testId"
}: Props) => {
  // react-table wants these memoized
  const reactTableColumns = useMemo(() => columns, [columns]);
  const reactTableData = useMemo(() => data, [data]);
  const memoizedInitialState = useMemo(() => initialState, [initialState]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns: reactTableColumns,
      data: reactTableData,
      disableSortBy: !allowSorting,
      manualSortBy: manuallySort,
      initialState: memoizedInitialState,
      useControlledState: state => {
        return React.useMemo(
          () => ({
            ...state,
            ...controlledState
          }),
          [state, controlledState]
        );
      }
    },
    useFilters,
    useSortBy
  );

  if (isEmpty(columns) || isEmpty(data)) {
    return null;
  }

  return (
    <Container
      className={className}
      data-testid={`${testId}-table`}
      {...getTableProps()}
    >
      <caption className="visuallyHidden">
        {caption || `${testId} table`}
      </caption>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(
              (
                column: ColumnInstance & {
                  onClickHeader?: (props?: any) => void;
                }
              ) => {
                const isClickable = !isEmpty(column.onClickHeader);
                const renderedHeader = column.render("Header");
                return (
                  <StyledTh
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        // slightly sketchy fix for typescript error
                        // https://github.com/tannerlinsley/react-table/issues/1612#issuecomment-728778889
                        className: (column as any).className
                      })
                    )}
                  >
                    <ClickWrapper
                      isClickable={isClickable}
                      onClick={e =>
                        invoke(column, "onClickHeader", { e, column })
                      }
                    >
                      {allowSorting && !column.disableSortBy ? (
                        <SortableColumnHeader
                          state={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "sorted-desc"
                                : "sorted-asc"
                              : "unsorted"
                          }
                          testId={testId}
                        >
                          {renderedHeader}
                        </SortableColumnHeader>
                      ) : (
                        renderedHeader
                      )}
                    </ClickWrapper>
                  </StyledTh>
                );
              }
            )}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <StyledTr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map(cell => {
                return (
                  <StyledTd
                    onClick={e =>
                      invoke(cell.column, "onClickCell", {
                        e,
                        row: row,
                        column: cell.column
                      })
                    }
                    {...cell.getCellProps({
                      // slightly sketchy fix for typescript error
                      // https://github.com/tannerlinsley/react-table/issues/1612#issuecomment-728778889
                      className: (cell.column as any).className
                    })}
                  >
                    {cell.render("Cell")}
                  </StyledTd>
                );
              })}
            </StyledTr>
          );
        })}
      </tbody>
    </Container>
  );
};

export default Table;
