import React from "react";
import styled from "styled-components/macro";
import { isEmpty } from "lodash";

// components
import InputsList from "../InputsList/InputsList";

// interfaces
import {
  RadioCheckboxOptionsInterface,
  RadioCheckboxToggleAllHandlerInterface,
  RadioCheckboxToggleHandlerInterface
} from "../../interfaces/controls";

// styled components
const AllSelectedToggle = styled.button`
  background: none;
  border: 0;
  color: ${props => props.theme.colors.all.auroraTeal};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  margin-top: 1rem;
  padding: 0;
`;
const StyledSelectableInputsList = styled.div``;

// typescript props
type Props = {
  className?: string;
  columns?: number;
  header?: React.ReactNode;
  name: string;
  onToggleAllSelected?: ({
    e,
    allSelected
  }: RadioCheckboxToggleAllHandlerInterface) => void;
  onToggleListItem: (params: RadioCheckboxToggleHandlerInterface) => void;
  options: Array<RadioCheckboxOptionsInterface>;
  showSelectAll?: boolean;
  testId?: string;
  type?: "checkbox" | "radio";
};

const SelectableInputsList = ({
  columns = 1,
  className,
  header,
  name,
  options,
  onToggleListItem,
  onToggleAllSelected = () => {
    // do nothing
  },
  showSelectAll = false,
  testId = "testId",
  type = "checkbox"
}: Props) => {
  const thisTestId = `${testId}-checkbox-list`;
  if (isEmpty(options) || !onToggleListItem) {
    return null;
  }

  const allSelected = options.every(o => o.selected);
  return (
    <StyledSelectableInputsList className={className} data-testid={thisTestId}>
      {header}
      <InputsList
        columns={columns}
        onChange={onToggleListItem}
        options={options}
        name={name}
        testId={thisTestId}
        type={type}
      />
      {showSelectAll && (
        <AllSelectedToggle
          onClick={e => onToggleAllSelected({ e, allSelected: !allSelected })}
          data-testid={`${testId}-all-selected-toggle`}
        >
          {allSelected ? "Unselect" : "Select"} all
        </AllSelectedToggle>
      )}
    </StyledSelectableInputsList>
  );
};

export default SelectableInputsList;
