import { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Chart,
  TooltipFormatterCallbackFunction,
  TooltipFormatterContextObject
} from "highcharts";
import { isNull } from "lodash";

interface Props {
  chart: Chart | null;
  children(formatterContext: TooltipFormatterContextObject): JSX.Element | null;
}

// fully ganked from here: https://gist.github.com/dankremniov/a9a6b969e63dfc4f0f83e6f82b82eb4f
const ChartTooltipWrapper = ({ chart, children }: Props) => {
  const isInit = useRef(false);
  const [context, setContext] = useState<TooltipFormatterContextObject | null>(
    null
  );

  useEffect(() => {
    if (chart) {
      const formatter: TooltipFormatterCallbackFunction = function() {
        // Ensures that tooltip DOM container is rendered before React portal is created.
        if (!isInit.current) {
          isInit.current = true;
          chart.tooltip.refresh.apply(chart.tooltip, [this.point]);
          chart.tooltip.hide(0);
        }

        setContext(this);

        return `<div id="highcharts-custom-tooltip-${chart.index}"></div>`;
      };

      chart.update({
        tooltip: {
          formatter,
          useHTML: true
        }
      });
    }
  }, [chart]);

  const node =
    chart &&
    document.getElementById(`highcharts-custom-tooltip-${chart.index}`);

  return node && context && !isNull(children)
    ? ReactDOM.createPortal(children(context), node)
    : null;
};

export default ChartTooltipWrapper;
