import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { findIndex, isEmpty } from "lodash";

// components
import ChartStoryPoints from "../ChartStoryPoints/ChartStoryPoints";
import EmptyState from "../EmptyState/EmptyState";
import GridCol from "../GridCol/GridCol";
import GridContainer from "../GridContainer/GridContainer";
import GridRow from "../GridRow/GridRow";
import Spinner from "../Spinner/Spinner";
import StoryPointsAverage from "../StoryPointsAverage/StoryPointsAverage";
import StoryPointsList from "../StoryPointsList/StoryPointsList";

// constants
import { storyPointsListTypes } from "../../constants/components";

// utils
import { formatTimestamp } from "../../utils/date";
import parseData from "./parser";
import { useBackingData } from "./useBackingData";
import { legacyRoleTypes } from "../../constants/users";

// styled components
const Container = styled.div`
  width: 100%;
`;
const StyledGridContainer = styled(GridContainer)`
  margin-top: 5rem;
`;
const StyledFigCaption = styled.figcaption`
  display: flex;
  justify-content: flex-end;
`;
const StyledEmptyState = styled(EmptyState)`
  margin-top: 5rem;
`;

const StoryPointsChartWrapper = ({ testId = "testId", ...props }) => {
  const {
    className,
    accessToken,
    tenantId,
    manualState,
    user,
    teamMembers,
    startDate,
    endDate
  } = props;
  const [
    selectedDate,
    storyPointsData,
    isFetchingData,
    setSelectedDate,
    error
  ] = useBackingData(
    manualState,
    accessToken,
    tenantId,
    user,
    teamMembers,
    startDate,
    endDate
  );

  if (error) {
    return null;
  }

  if (isFetchingData) {
    return <Spinner testId={testId} />;
  }

  const onSelectDate = date => {
    setSelectedDate(date);
  };

  // do a check up front to see if anyone on the team has tickets assigned.
  let teamHasNoAssignedTicketsForAllDates = storyPointsData.every(date => {
    return date.resultsByUser.every(r => isEmpty(r.metaData));
  });

  let displayContent;
  // if no one on the team has tickets assigned, skip all the date parsing and
  // just show an empty state message
  if (teamHasNoAssignedTicketsForAllDates) {
    // we use the same component for both the ic private and team widgets. The data fetcher
    // uses this role check to determine as well
    const emptyStateRole =
      user.enabled_roles.includes(legacyRoleTypes.PRIVATEIC) ||
      user.enabled_roles.includes(legacyRoleTypes.PRIVATEIC_EXEC)
        ? "You don't"
        : "Your team doesn't";

    displayContent = (
      <StyledEmptyState
        body={emptyStateRole.concat(
          " seem to have any recent Stories or Tasks assigned."
        )}
        header="Hmmm."
        testId={testId}
      />
    );
  } else {
    const selectedDateIndex = findIndex(
      storyPointsData,
      d => d.startDate === selectedDate
    );
    if (selectedDateIndex < 0) {
      return null;
    }
    const selectedDateMetadata = storyPointsData[selectedDateIndex];
    const previousDateMetadata = storyPointsData[selectedDateIndex - 1];

    const average =
      selectedDateMetadata.overall.values[selectedDateMetadata.measures[0]];
    const delta =
      average -
      previousDateMetadata.overall.values[previousDateMetadata.measures[0]];

    const parsedData = parseData({
      data: selectedDateMetadata,
      userDetails: [...teamMembers, user]
    });

    const { ticketsWithStoryPoints, ticketsWithoutStoryPoints } = parsedData;
    const selectedDateFormatted = formatTimestamp({
      format: "M/D",
      timestamp: selectedDate
    });
    const teamHasNoAssignedTicketsForSelectedDate =
      isEmpty(ticketsWithStoryPoints) && isEmpty(ticketsWithoutStoryPoints);

    displayContent = (
      <Fragment>
        <figure>
          <ChartStoryPoints
            // take from 2nd date in the array because we deliberately fetched num + 1 days
            data={storyPointsData.slice(1)}
            isFetchingData={isFetchingData}
            // use the raw unformatted date because we need to be able to
            // match the startDate from the data
            selectedDate={selectedDate}
            onSelectDate={date => onSelectDate(date)}
            testId={testId}
          />
          <StyledFigCaption>
            <StoryPointsAverage
              date={selectedDateFormatted}
              value={average}
              delta={delta}
              testId={testId}
            />
          </StyledFigCaption>
        </figure>
        {teamHasNoAssignedTicketsForSelectedDate ? (
          <StyledEmptyState
            body={`Your team doesn't seem to have any recent Stories or Tasks assigned as of ${selectedDateFormatted}.`}
            header="Hmmm."
            testId={testId}
          />
        ) : (
          <StyledGridContainer>
            <GridRow>
              <GridCol>
                <StoryPointsList
                  date={selectedDateFormatted}
                  list={ticketsWithStoryPoints}
                  listType={storyPointsListTypes.WITH_STORY_POINTS}
                  testId={testId}
                />
              </GridCol>
              <GridCol>
                <StoryPointsList
                  date={selectedDateFormatted}
                  list={ticketsWithoutStoryPoints}
                  listType={storyPointsListTypes.WITHOUT_STORY_POINTS}
                  testId={testId}
                />
              </GridCol>
            </GridRow>
          </StyledGridContainer>
        )}
      </Fragment>
    );
  }

  return (
    <Container className={className} data-testid={`${testId}-story-points`}>
      {displayContent}
    </Container>
  );
};

StoryPointsChartWrapper.propTypes = {
  accessToken: PropTypes.string.isRequired,
  className: PropTypes.string,
  tenantId: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

export default StoryPointsChartWrapper;
