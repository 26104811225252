import React, { useMemo, useState } from "react";
import { isEmpty, debounce } from "lodash";

// components
import EpicFilterCheckboxList from "../EpicFilterCheckboxList/EpicFilterCheckboxList";

// constants
import {
  ownerContributorAlternativeLabels,
  ownerContributorTypes,
  urlParamKeys,
  urlParamsValuesLabels
} from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { UiFilterSelectionStatusInterface } from "../../interfaces/ui-filter";
import {
  UrlParamsInterface,
  UrlParamsHookReturnInterface
} from "../../interfaces/url-params";

// typescript props
type Props = {
  className?: string;
  testId?: string;
  ownerContributor: UrlParamsInterface["ownerContributor"];
  updateUrlParams: UrlParamsHookReturnInterface["updateUrlParams"];
  useAlternativeLabels: boolean;
};

const OwnerContributorFilter = ({
  className,
  ownerContributor,
  testId = "testId",
  updateUrlParams,
  useAlternativeLabels
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-owner-contributor-filter`;
  const { trackEvent } = useTracking();
  const [areFiltersDisabled, setAreFiltersDisabled] = useState(false);

  const optionsList = [
    ownerContributorTypes.OWNER,
    ownerContributorTypes.CONTRIBUTOR
  ].map(m => {
    return {
      id: m,
      isDisabled: areFiltersDisabled,
      isSelected: ownerContributor.includes(m),
      name: `${
        useAlternativeLabels
          ? ownerContributorAlternativeLabels[m]
          : urlParamsValuesLabels[m]
      }`
    };
  });

  // fixing ts error with this:
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedToggle = useMemo(
    () =>
      debounce(updatedTypes => {
        updateUrlParams({
          [urlParamKeys.OWNER_CONTRIBUTOR]: updatedTypes
        });
        setAreFiltersDisabled(false);
      }, 500),
    [updateUrlParams]
  );

  function onToggleType(updated: Array<UiFilterSelectionStatusInterface>) {
    const updatedTypes = updated
      .filter((u: UiFilterSelectionStatusInterface) => u.isSelected)
      .map((s: UiFilterSelectionStatusInterface) => s.id);

    setAreFiltersDisabled(true);
    debouncedToggle(updatedTypes);
    trackEvent({
      label: "ui-filter-owner-contributor-change",
      value: `changed owner-contributor to ${(isEmpty(updatedTypes)
        ? Object.values(ownerContributorTypes)
        : updatedTypes
      ).join(",")}`
    });
  }

  return (
    <div className={className} data-testid={thisTestId}>
      <EpicFilterCheckboxList
        header="Roles"
        list={optionsList}
        name="owner-contributor"
        onUpdateSelection={onToggleType}
        testId="owner-contributor"
      />
    </div>
  );
};

export default OwnerContributorFilter;
