import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { AppStateInterface } from "../../interfaces/app-state";

// interfaces
import { UserReportWithMetadataInterface } from "../../interfaces/user";

// styled components
const List = styled.ul`
  margin-top: 1.6rem;
`;
const Paragraph = styled.p`
  & + &,
  ${List} + & {
    margin-top: 1.6rem;
  }
`;
const TeamName = styled.b`
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;
const TeamMembers = styled.b`
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;

// typescript props
type Props = {
  action?: "confirm" | "verify";
  addedTeamMembers: Array<UserReportWithMetadataInterface>;
  className?: string;
  displayTenantAlias: string;
  newTeamMembers: Array<UserReportWithMetadataInterface>;
  removedTeamMembers?: Array<UserReportWithMetadataInterface>;
  teamName: string;
  testId?: string;
};

const TeamCardSubmitModalContent = ({
  action = "confirm",
  addedTeamMembers,
  className,
  displayTenantAlias,
  newTeamMembers,
  removedTeamMembers,
  teamName,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-team-card-submit-modal-content`;
  const isConfirmAction = action === "confirm";
  const filteredAddedTeamMembers = addedTeamMembers.filter(
    a => !newTeamMembers.find(n => n.email === a.email)
  );
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const canReceiveEmailNotifications =
    flags?.["flexible-teams-email-notifications"];

  return (
    <div className={className} data-testid={thisTestId}>
      {canReceiveEmailNotifications && !!addedTeamMembers.length ? (
        <Paragraph>
          {/* FIXME: need to change back to "has been/will be" when we're sending automated emails.
                https://uplevelteam.atlassian.net/browse/TEAM-439
          */}
          An email will be sent to{" "}
          <TeamMembers>
            {filteredAddedTeamMembers.map(t => t.email).join(", ")}
          </TeamMembers>{" "}
          letting them know that they have been added to{" "}
          <TeamName>{teamName}.</TeamName>
        </Paragraph>
      ) : null}
      {!!newTeamMembers.length ? (
        <>
          <List>
            {newTeamMembers.map(t => (
              <li>{t.email}</li>
            ))}
          </List>
          <Paragraph>
            {newTeamMembers.length > 1 ? "are" : "is"} not in the Uplevel
            database for {displayTenantAlias}. Our Customer Success team{" "}
            {isConfirmAction ? "has been" : "will be"} notified and will add
            them once confirmed.
          </Paragraph>
        </>
      ) : null}
      {canReceiveEmailNotifications && !!removedTeamMembers?.length ? (
        <Paragraph>
          {/* FIXME: need to change back to "has been/will be" when we're sending automated emails.
                https://uplevelteam.atlassian.net/browse/TEAM-439
          */}
          An email will be sent to{" "}
          <TeamMembers>
            {removedTeamMembers.map(t => t.email).join(", ")}
          </TeamMembers>{" "}
          letting them know that they have been removed from{" "}
          <TeamName>{teamName}.</TeamName>
        </Paragraph>
      ) : null}
      <Paragraph>
        {isConfirmAction
          ? "Thank You!"
          : "Are you sure you want to create this team?"}
      </Paragraph>
    </div>
  );
};

export default TeamCardSubmitModalContent;
