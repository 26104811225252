import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// components
import RouteLink from "../RouteLink/RouteLink";

// styled components
const List = styled.dl`
  align-items: baseline;
  display: flex;
`;
const Total = styled.dt`
  flex: 1;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  order: 2;
  white-space: nowrap;
`;
const TotalNumber = styled.dd`
  font-family: ${props => props.theme.fonts.subheader.name};
  font-weight: 700;
  margin: 0;
  order: 1;
  width: 2.4em;
`;
const WorkDeepDiveLink = styled(RouteLink)`
  color: ${props => props.theme.colors.all.wolverine};

  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    cursor: "pointer";
    text-decoration: none;

    & ${Total} {
      font-weight: ${props => props.theme.fonts.primary.weights.bold};
    }
  }
`;

// typescript props
type Props = {
  className?: string;
  header: React.ReactNode;
  path: string;
  testId?: string;
  value: number;
};

const SprintUpdatesTableItem = ({
  className,
  header,
  path,
  testId = "testId",
  value
}: Props): ReactElement => {
  const thisTestId = `${testId}-sprint-updates-table-item`;
  return (
    <WorkDeepDiveLink
      name={thisTestId}
      to={path}
      className={className}
      data-testid={thisTestId}
    >
      <List>
        <Total>{header}</Total>
        <TotalNumber>{value}</TotalNumber>
      </List>
    </WorkDeepDiveLink>
  );
};

export default SprintUpdatesTableItem;
