import React from "react";
import styled from "styled-components/macro";
import { isNil } from "lodash";

// components
import Button from "../Button/Button";

// constants
import { directionTypes } from "../../constants/constants";

// styled components
const StyledContainer = styled.nav`
  align-items: center;
  display: flex;
`;

// typescript props
type Props = {
  className?: string;
  content: React.ReactNode;
  nextButton: React.ReactNode;
  prevButton: React.ReactNode;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  onChange: (e: React.MouseEvent<HTMLButtonElement>, direction: string) => void;
  testId?: string;
};

const Stepper = ({
  className,
  content,
  onChange,
  isNextDisabled = false,
  isPrevDisabled = false,
  nextButton,
  prevButton,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-stepper`;

  if (isNil(content) || !nextButton || !onChange || !prevButton) {
    return null;
  }

  function onClickNext(e: React.MouseEvent<HTMLButtonElement>) {
    e.persist();
    onChange(e, directionTypes.NEXT);
  }

  function onClickPrevious(e: React.MouseEvent<HTMLButtonElement>) {
    e.persist();
    onChange(e, directionTypes.PREV);
  }

  return (
    <StyledContainer className={className} data-testid={thisTestId}>
      <Button
        button="unstyled"
        disabled={isPrevDisabled}
        id={`${thisTestId}-previous`}
        onClick={onClickPrevious}
        testId={`${thisTestId}-previous`}
      >
        {prevButton}
      </Button>
      {content}
      <Button
        button="unstyled"
        disabled={isNextDisabled}
        id={`${thisTestId}-next`}
        onClick={onClickNext}
        testId={`${thisTestId}-next`}
      >
        {nextButton}
      </Button>
    </StyledContainer>
  );
};

export default Stepper;
