import { useEffect, useMemo, useState } from "react";
import { flattenDeep } from "lodash";

// hooks
import { useUserSettings } from "./useUserSettings";
import { useOwnFallbackOrgChartTeam } from "./useOwnFallbackOrgChartTeam";
import { useActiveUser } from "./useActiveUser";
import { useActiveUsersTeams } from "./useActiveUsersTeams";

// interfaces
import { TeamInterface } from "../interfaces/team";
import { UserReportWithMetadataInterface } from "../interfaces/user";

const getTeamMembersForTeam = (
  team: TeamInterface | null
): Array<UserReportWithMetadataInterface> =>
  (team?.teamMembers || []).concat(
    flattenDeep(
      team?.teamMembers.map((t: UserReportWithMetadataInterface) => {
        if (t.team) {
          return getTeamMembersForTeam(t.team);
        }
        return [];
      })
    )
  );

export const useDefaultTeam = () => {
  const [defaultTeam, setDefaultTeam] = useState<TeamInterface | null>(null);
  const ownFallbackOrgChartTeam = useOwnFallbackOrgChartTeam();
  const activeUser = useActiveUser();
  const activeUserId = activeUser?.id;
  const orgChartTeam =
    (activeUser as UserReportWithMetadataInterface)?.team ||
    ownFallbackOrgChartTeam;
  const { data: activeUsersTeams } = useActiveUsersTeams();
  const { data: userSettings } = useUserSettings();
  const ownDefaultTeamId = userSettings?.defaultTeamId;

  const settingsDefaultTeam: TeamInterface | undefined = useMemo(() => {
    if (!!activeUsersTeams?.length) {
      return activeUsersTeams.find(
        (t: TeamInterface) => t.teamId === ownDefaultTeamId
      );
    }
  }, [activeUsersTeams, ownDefaultTeamId]);

  useEffect(() => {
    if (!!activeUserId && !!activeUsersTeams?.length && !!orgChartTeam) {
      // 1. users own settings default team
      // 2. org chart team of the active users (zoomed in or logged in)
      // 3. users own fallback org chart team
      setDefaultTeam(
        settingsDefaultTeam || orgChartTeam || ownFallbackOrgChartTeam
      );
    } else {
      setDefaultTeam(null);
    }
  }, [activeUserId, settingsDefaultTeam, orgChartTeam, activeUsersTeams]);

  return defaultTeam;
};
