import {
  CycleTimeInterface,
  CycleTimeStageInterface
} from "../interfaces/work-items";
import { compact, isFinite, isNull, sum } from "lodash";
import { cycleTimeStages, directionalityTypes } from "../constants/constants";
import { CycleTimeStageTypes } from "../interfaces/constants";
import { CycleTimeUnit, OpMetricUnit } from "../interfaces/ops-metrics";

export const getValueInHours = (unit: OpMetricUnit, value: number): number =>
  unit === "DAYS" ? value * 24 : value;

export const getCycleTimeStageInHours = (
  cycleTime: Array<CycleTimeStageInterface>
): number =>
  sum(
    Object.values(cycleTime)
      .filter(c => !!c?.unit)
      .map((total: CycleTimeStageInterface) =>
        getValueInHours(total.unit, total.value)
      )
  );
export function rollupCycleTime(
  cycleTime: CycleTimeInterface
): Array<CycleTimeStageInterface> {
  if (isNull(cycleTime)) {
    return [
      {
        value: 0,
        unit: "DAYS"
      }
    ];
  }

  return splitCycleTimeHours(
    getCycleTimeStageInHours(Object.values(cycleTime))
  );
}

export const splitCycleTimeHours = (
  cycleTimeHours: number,
  roundHours = true
): Array<CycleTimeStageInterface> => {
  const cycleTimeDays = Math.floor(cycleTimeHours / 24);
  const modHours = cycleTimeHours % 24;
  const cycleTimeRemainderHours = roundHours ? Math.round(modHours) : modHours;
  return compact([
    cycleTimeDays >= 0
      ? {
          value: cycleTimeDays,
          unit: "DAYS"
        }
      : null,
    cycleTimeRemainderHours >= 0
      ? {
          value: cycleTimeRemainderHours,
          unit: "HOURS"
        }
      : null
  ]);
};

function getMedianCycleStageTime(
  stageTimes: Array<CycleTimeStageInterface>
): CycleTimeStageInterface {
  const stageTimesInHours = stageTimes
    .map(s => (s.unit === "DAYS" ? s.value * 24 : s.value))
    .sort((v1, v2) => v1 - v2);

  const medianIndex = Math.floor(stageTimesInHours.length / 2);
  const medianStageHours = !stageTimesInHours.length
    ? 0
    : stageTimesInHours.length % 2 === 0
    ? (stageTimesInHours[medianIndex - 1] + stageTimesInHours[medianIndex]) /
      2.0
    : stageTimesInHours[medianIndex];

  return {
    unit: medianStageHours >= 24 ? "DAYS" : "HOURS",
    value:
      medianStageHours >= 24
        ? Math.floor(medianStageHours / 24)
        : Math.round(medianStageHours)
  };
}

export function getMedianCycleTime(
  cycleTimes: Array<CycleTimeInterface>
): CycleTimeInterface | null {
  if (!cycleTimes.length) return null;

  // build cycle time object
  const medians: CycleTimeInterface = {};
  ([
    cycleTimeStages.DEV,
    cycleTimeStages.WAITING_FOR_REVIEW,
    cycleTimeStages.REVIEW,
    cycleTimeStages.RELEASE
  ] as Array<keyof CycleTimeStageTypes>).forEach(stageKey => {
    const medianTimes = compact(
      cycleTimes.filter(c => !!c[stageKey]?.unit).map(c => c[stageKey])
    );
    if (!!medianTimes.length) {
      medians[stageKey] = getMedianCycleStageTime(medianTimes);
    }
  });
  return medians;
}

export const getCycleTimeDelta = (
  current?: Array<CycleTimeStageInterface> | null,
  previous?: Array<CycleTimeStageInterface> | null
):
  | (CycleTimeStageInterface & {
      direction: string;
      isInverted: boolean;
    })
  | null => {
  if (!!current && !!previous) {
    const deltaInHours =
      getCycleTimeStageInHours(current) - getCycleTimeStageInHours(previous);

    if (!!deltaInHours) {
      const splitDelta = splitCycleTimeHours(Math.abs(deltaInHours));
      const unit =
        Math.abs(deltaInHours) >= 24 ? "DAYS" : ("HOURS" as CycleTimeUnit);
      return {
        direction:
          deltaInHours < 0
            ? directionalityTypes.NEGATIVE
            : directionalityTypes.POSITIVE,
        isInverted: true,
        unit,
        value:
          (splitDelta.find(s => s.unit === unit)?.value as number) *
          (deltaInHours < 0 ? -1 : 1)
      };
    }
  }
  return null;
};

export const filterTruthyCycleTimeValues = (
  stage: Array<CycleTimeStageInterface>
): Array<CycleTimeStageInterface> => {
  return stage.every(s => s.value === 0)
    ? stage.filter(s => s.unit === "HOURS")
    : stage.filter(s => isFinite(s.value) && s.value > 0);
};
