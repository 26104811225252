import React from "react";
import styled from "styled-components/macro";

// components
import Icon from "../Icon/Icon";

// constants
import { SUPPORT_EMAIL_ADDRESS } from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// styled components
const ExternalLink = styled(Icon)`
  color: inherit;
  font-size: inherit;
  margin-left: 0.3em;
`;
const Container = styled.a`
  align-items: baseline;
  display: inline-flex;

  && {
    color: ${props => props.theme.colors.all.wolverine};
  }

  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    text-decoration: none;

    & ${ExternalLink} {
      color: inherit;
    }
  }
`;

// typescript props
type Props = {
  className?: string;
  content?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  name: string;
  testId?: string;
};

const SupportEmailLink = ({
  className,
  content = SUPPORT_EMAIL_ADDRESS,
  onClick,
  name,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-support-email-link`;
  const { trackEvent } = useTracking();

  function onClickLink(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.persist();
    trackEvent({
      e,
      label: `${name}-support-email-link`,
      value: `opening email to support from ${name}`
    });

    if (onClick) {
      onClick(e);
    }
  }

  return (
    <Container
      className={className}
      onClick={onClickLink}
      data-testid={thisTestId}
      href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
    >
      {content} <ExternalLink icon="external-link" testId={testId} />
    </Container>
  );
};

export default SupportEmailLink;
