import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";

// styled components
// exporting to target in tests
export const UnstyledButton = styled.button`
  /* button resets */
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.primary.name}, "Helvetica Neue",
    "Aria", sans-serif;
  padding: 0;

  ${props => {
    if (props.disabled) {
      return css`
        cursor: inherit;
      `;
    }
  }}
`;

// FIXME: color is bg to avoid white on white until further button refactor
export const StyledButton = styled(UnstyledButton)`
  color: ${props => props.theme.colors.button[props.button].default.bg};
  transition: 0.2s all;
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  ${props => {
    if (props.disabled) {
      return css`
        color: ${props => props.theme.colors.disabled};
      `;
    }
  }}
`;

const hoverActiveButtonStyles = css`
  background-color: ${props =>
    props.theme.colors.button[props.button].hover.bg};
  border-color: ${props =>
    props.theme.colors.button[props.button].hover.border};
  color: ${props => props.theme.colors.button[props.button].hover.fg};

  & svg {
    color: ${props => props.theme.colors.button[props.button].hover.fg};
  }
`;

export const StyledBorderedButton = styled(StyledButton)`
  border-color: ${props =>
    props.theme.colors.button[props.button].default.border};
  border-style: solid;
  border-width: ${props => props.theme.borders.widths.md};
  padding: 1.8rem 2.5rem;
  border-radius: ${props => props.theme.colors.button.border.radius};

  ${props => {
    if (props.disabled) {
      return css`
        border-color: ${props => props.theme.colors.disabled};
      `;
    } else if (props.isActive) {
      return hoverActiveButtonStyles;
    } else {
      return css`
        &&:hover {
          ${hoverActiveButtonStyles}
        }
      `;
    }
  }}
`;

const Button = ({
  isActive = false,
  bordered = true,
  button = "default",
  children,
  className = "", // hacky getting ts to stop erroring
  disabled = false,
  onClick,
  style = {}, // hacky getting ts to stop erroring
  testId
}) => {
  if (!button || !children || !onClick || !testId) {
    return null;
  }

  let DisplayButton;
  if (button === "unstyled") {
    DisplayButton = UnstyledButton;
  } else {
    DisplayButton = bordered ? StyledBorderedButton : StyledButton;
  }

  return (
    <DisplayButton
      isActive={isActive}
      button={button}
      className={className}
      disabled={disabled}
      onClick={onClick}
      data-testid={`${testId}-button`}
      style={style}
    >
      {children}
    </DisplayButton>
  );
};

Button.propTypes = {
  bordered: PropTypes.bool,
  button: PropTypes.oneOf(["default", "reversed", "unstyled"]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  testId: PropTypes.string.isRequired
};
export default Button;
