import { merge } from "lodash";

export default ({ config = {}, theme }) => {
  return merge(
    {},
    {
      chart: {
        type: "packedbubble",
        style: {
          fontFamily: theme.fonts.subheader.name,
          fontSize: 15
        }
      },
      title: null,
      tooltip: {
        enabled: true,
        useHTML: true,
        backgroundColor: null,
        shadow: false,
        borderWidth: 0,
        borderRadius: 0
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true
          }
        }
      }
    },
    config
  );
};
