// my-theme.ts
import { DefaultTheme } from "styled-components/macro";
import { hexToRgb } from "../utils/color";
import { mapValues } from "lodash";
import { healthScoreTypes } from "../constants/constants";
import { CycleTimeStageTypes } from "../interfaces/constants";

// VARIABLES
// from bootstrap grid
const breakpointsValues = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1450
};

// COLORS

// brand
const auroraTeal = "#008080";
const jean = "#e2deda";
const lightJean = "#edeae8";
const lightMarvel = "#d7ffe1";
const marvelMint = "#aaffc3";
const storm = "#808080";
const white = "#ffffff";
const wolverine = "#111111";

// other
const archangel = "#46f0f0";
const banshee = "#bcf60c";
const beast = "#4363d8";
const cyclops = "#e41e26";
const gambit = "#ffd8b1";
const iceman = "#cfe6ff";
const jubilee = "#fffac8";
const juggernaut = "#9a6324";
const magma = "#800000";
const magneto = "#e6beff";
const nightcrawler = "#000075";
const petra = "#3cb44b";
const phoenix = "#f58231";
const pixie = "#f032e6";
const professorX = "#808000";
const rogue = "#ffe119";
const sunfire = "#fabebe";
const thunderbird = "#911eb4";

// and extend them!
declare module "styled-components" {
  export interface DefaultTheme {
    breakpoints: {
      sm: string;
      md: string;
      lg: string;
      xl: string;
      xxl: string;
    };
    breakpointsValues: {
      sm: number;
      md: number;
      lg: number;
      xl: number;
      xxl: number;
    };
    borders: {
      widths: {
        sm: string;
        md: string;
        lg: string;
      };
    };
    colors: {
      all: {
        [key: string]: string;
      };
      reversed: Array<string>;
      border: string;
      borderedBox: {
        border: string;
      };
      brand: {
        wolverine: string;
        storm: string;
        jean: string;
        marvelMint: string;
        auroraTeal: string;
        white: string;
      };
      breadcrumb: {
        icon: string;
      };
      button: {
        disabled: {
          default: { bg: string; border: string; fg: string };
          hover: { bg: string; border: string; fg: string };
        };
        reversed: {
          default: { bg: string; border: string; fg: string };
          hover: { bg: string; border: string; fg: string };
        };
        default: {
          default: { bg: string; border: string; fg: string };
          hover: { bg: string; border: string; fg: string };
        };
        unstyled: {
          default: {
            bg: string;
            border: string;
            fg: string;
          };
          hover: { bg: string; border: string; fg: string };
        };
        border: {
          thickness: string;
          radius: string;
        };
      };
      card: {
        bg: string;
      };
      carousel: {
        icon: string;
      };
      chart: {
        text: string;
        deepWorkByIc: {
          warning: string;
          ramp: Array<string>;
          scheduled: string;
          available: string;
        };
        cycleTime: {
          [key in keyof CycleTimeStageTypes]: {
            color: string;
            backgroundColor: string;
          };
        };
        storyPoints: {
          marker: {
            fill: string;
            stroke: string;
          };
        };
        alwaysOnRisk: {
          normal: string;
          "above normal": string;
          high: string;
          dash: string;
        };
        alwaysOnRiskTrend: {
          marker: {
            fill: {
              HIGH: string;
              ABOVE_NORMAL: string;
              NORMAL: string;
            };
            stroke: string;
            opacity: {
              HIGH: number;
              ABOVE_NORMAL: number;
              NORMAL: number;
            };
          };
        };
        meetingClassifications: {
          [key: string]: string;
        };
        prCadence: {
          prs: string;
          reviewTime: string;
          marker: { fill: string; stroke: string };
          column: {
            fill: string;
            stroke: string;
          };
        };
        deepWorkTime: {
          user: string;
          team: string;
          marker: { fill: string; stroke: string };
          column: {
            fill: string;
            stroke: string;
          };
        };
        meetingTime: {
          team: string;
          user: string;
          marker: { fill: string; stroke: string };
          column: {
            fill: string;
            stroke: string;
          };
        };
        healthFactors: {
          [key: string]: string;
        };
      };
      disabled: string;
      dropdown: {
        text: string;
        indicator: string;
        option: {
          hover: string;
          selected: string;
          selectedHover: string;
        };
      };
      expander: {
        border: string;
        icon: string;
      };
      featuredContent: {
        bg: string;
        text: { default: string; reversed: string; bg: string };
      };
      feedback: {
        header: string;
      };
      highlight: string;
      icon: string;
      avatarIcons: Array<string>;
      iconInfo: {
        light: string;
        dark: string;
      };
      link: {
        color: string;
        hover: {
          color: string;
          background: string;
        };
      };
      modal: {
        closeButton: string;
      };
      overlay: {
        background: string;
        popupContent: {
          background: string;
          border: string;
        };
      };
      pipedList: string;
      recommendation: {
        bg: string;
        header: { bg: string; fg: string };
        subHeader: string;
      };
      scheduleOptionStatus: {
        ideal: string;
        "minimal-conflict": string;
      };
      signalHealth: {
        [key: string]: string;
      };
      relativeHealth: {
        [key: string]: string;
      };
      statusMessage: {
        focus: string;
        success: string;
        warning: string;
      };
      stepper: {
        indicators: string;
      };
      table: {
        hover: string;
      };
      text: {
        body: string;
        highlight: string;
        strong: string;
      };
      textFlag: string;
      texturedContainer: {
        white: { bg: string };
        lightGrey: { bg: string };
        darkGrey: { bg: string; fg: string };
        teal: { bg: string; fg: string };
      };
      tooltip: {
        popupShadow: string;
      };
      uiBackground: string;
      uplevelFooter: {
        color: string;
        border: string;
      };
      validationMessage: {
        success: {
          bg: string;
          fg: string;
        };
        warning: {
          bg: string;
          fg: string;
        };
      };
      widgetHeader: { text: string };
    };
    fonts: {
      primary: {
        name: string;
        sizes: {
          xl: string;
          lg: string;
          md: string;
          sm: string;
          xs: string;
        };
        weights: {
          hairline: number;
          thin: number;
          ultraLight: number;
          light: number;
          semiLight: number;
          regular: number;
          book: number;
          medium: number;
          demiBold: number;
          bold: number;
          extraBold: number;
          black: number;
        };
      };
      header: {
        name: string;
        sizes: {
          xl: string;
          lg: string;
          md: string;
          sm: string;
          xs: string;
        };
        weights: {
          demiBold: number;
          extraBold: number;
        };
      };
      subheader: {
        name: string;
        sizes: {
          xl: string;
          lg: string;
          md: string;
          sm: string;
          xs: string;
        };
        weights: {
          ultraLight: number;
          light: number;
          regular: number;
          bold: number;
          extraBold: number;
          black: number;
        };
      };
    };
    grid: {
      columnWidth: string;
      gap: string;
      sidebar: string;
      sidebarGutter: string;
      gridOffset: string;
    };
    charts: {
      series: {
        opacity: {
          full: number;
          partial: number;
        };
      };
    };
  }
}

// theme values
export const currentTheme: DefaultTheme = {
  breakpoints: mapValues(breakpointsValues, (b: number) => `${b}px`),
  breakpointsValues,
  borders: {
    widths: {
      sm: "0.1rem",
      md: "0.3rem",
      lg: "0.5rem"
    }
  },
  colors: {
    all: {
      wolverine,
      storm,
      jean,
      lightJean,
      marvelMint,
      auroraTeal,
      white,
      archangel,
      banshee,
      beast,
      cyclops,
      gambit,
      iceman,
      jubilee,
      juggernaut,
      magma,
      magneto,
      nightcrawler,
      petra,
      phoenix,
      pixie,
      professorX,
      rogue,
      sunfire,
      thunderbird
    },
    reversed: [
      beast,
      cyclops,
      magma,
      nightcrawler,
      professorX,
      thunderbird,
      juggernaut,
      pixie,
      petra,
      phoenix
    ],
    border: wolverine,
    borderedBox: {
      border: wolverine
    },
    brand: {
      wolverine,
      storm,
      jean,
      marvelMint,
      auroraTeal,
      white
    },
    breadcrumb: {
      icon: jean
    },
    button: {
      disabled: {
        default: { bg: jean, border: storm, fg: storm },
        hover: { bg: jean, border: storm, fg: storm }
      },
      reversed: {
        default: { bg: jean, border: wolverine, fg: wolverine },
        hover: { bg: marvelMint, border: wolverine, fg: wolverine }
      },
      default: {
        default: { bg: auroraTeal, border: wolverine, fg: white },
        hover: { bg: marvelMint, border: wolverine, fg: wolverine }
      },
      unstyled: {
        default: {
          bg: "transparent",
          border: "transparent",
          fg: wolverine
        },
        hover: { bg: marvelMint, border: "transparent", fg: wolverine }
      },
      border: {
        thickness: "3px solid",
        radius: "15% / 50%"
      }
    },
    card: {
      bg: jean
    },
    carousel: {
      icon: storm
    },
    chart: {
      text: wolverine,
      healthFactors: {
        averageHealth: beast,
        peopleHealth: auroraTeal,
        projectHealth: nightcrawler,
        ALWAYS_ON: phoenix,
        CHAT_INTERRUPTIONS: professorX,
        CONTEXT_SWITCHING: thunderbird,
        CONTEXT_SWITCHING_TOTAL: thunderbird,
        CONTEXT_SWITCHING_TOP_PRIORITY_BUGS: cyclops,
        CONTEXT_SWITCHING_MEETINGS: magneto,
        CONTEXT_SWITCHING_CHAT_CHANNELS: professorX,
        CONTEXT_SWITCHING_PR_REPOS: banshee,
        CONTEXT_SWITCHING_JIRA_EPICS: nightcrawler,
        DEEP_WORK: auroraTeal,
        ISSUES_ADDED: magma,
        ISSUES_CARRYOVER: juggernaut,
        ISSUES_CLOSED: sunfire,
        ISSUES_WITH_DESCRIPTIONS: pixie,
        ISSUES_WITH_STORY_POINTS: sunfire,
        JIRA_BUGS: cyclops,
        PEOPLE_HEALTH: beast,
        PRS_MERGED_NO_APPROVAL: magneto,
        PRS_NO_REVIEWERS: iceman,
        PRS_STUCK: archangel
      },
      deepWorkByIc: {
        warning: rogue,
        ramp: [iceman, auroraTeal],
        scheduled: auroraTeal,
        available: iceman
      },
      cycleTime: {
        DEV: {
          color: wolverine,
          backgroundColor: jean
        },
        WAITING_FOR_REVIEW: {
          color: wolverine,
          backgroundColor: iceman
        },
        REVIEW: {
          color: white,
          backgroundColor: beast
        },
        RELEASE: {
          color: white,
          backgroundColor: nightcrawler
        }
      },
      storyPoints: {
        marker: {
          fill: petra,
          stroke: wolverine
        }
      },
      alwaysOnRisk: {
        normal: petra,
        "above normal": gambit,
        high: phoenix,
        dash: wolverine
      },
      alwaysOnRiskTrend: {
        marker: {
          fill: {
            HIGH: rogue,
            ABOVE_NORMAL: rogue,
            NORMAL: marvelMint
          },
          stroke: wolverine,
          opacity: {
            HIGH: 0.25,
            ABOVE_NORMAL: 0.1,
            NORMAL: 0.45
          }
        }
      },
      meetingClassifications: {
        MANAGER_IC_ONE_ON_ONES: phoenix,
        BLOCKED_FOR_WORK: archangel,
        PRODUCT_RELEASE_REVIEWS: rogue,
        WORKING_SESSIONS_PROJECT_MEETINGS: nightcrawler,
        RECRUITING: magneto,
        SCRUM: gambit,
        SOCIAL_COMPANY_EVENTS: pixie,
        TRAINING_SESSIONS: iceman,
        OUT_OF_OFFICE: storm,
        OFFICE_HOURS: thunderbird,
        OTHER_MEETING_EVENTS: beast,
        NOT_A_MEETING: beast
      },
      prCadence: {
        prs: petra,
        reviewTime: beast,
        marker: { fill: beast, stroke: wolverine },
        column: {
          fill: petra,
          stroke: wolverine
        }
      },
      deepWorkTime: {
        user: iceman,
        team: thunderbird,
        marker: { fill: thunderbird, stroke: wolverine },
        column: {
          fill: iceman,
          stroke: wolverine
        }
      },
      meetingTime: {
        team: thunderbird,
        user: beast,
        marker: { fill: thunderbird, stroke: wolverine },
        column: {
          fill: beast,
          stroke: wolverine
        }
      }
    },
    disabled: jean,
    dropdown: {
      text: wolverine,
      indicator: wolverine,
      option: {
        hover: jean,
        selected: marvelMint,
        selectedHover: hexToRgb({ hex: petra, opacity: 0.25 })
      }
    },
    expander: {
      border: wolverine,
      icon: wolverine
    },
    featuredContent: {
      bg: auroraTeal,
      text: { default: white, reversed: wolverine, bg: marvelMint }
    },
    feedback: {
      header: wolverine
    },
    highlight: marvelMint,
    icon: auroraTeal,
    avatarIcons: [
      sunfire,
      archangel,
      gambit,
      jubilee,
      iceman,
      magneto,
      banshee,
      rogue,
      beast,
      cyclops,
      magma,
      nightcrawler,
      professorX,
      thunderbird,
      juggernaut,
      pixie,
      petra,
      phoenix
    ],
    iconInfo: {
      light: storm,
      dark: storm
    },
    link: {
      color: auroraTeal,
      hover: {
        color: wolverine,
        background: marvelMint
      }
    },
    modal: {
      closeButton: wolverine
    },
    overlay: {
      background: hexToRgb({ hex: white, opacity: 0.5 }),
      popupContent: {
        background: white,
        border: jean
      }
    },
    pipedList: wolverine,
    recommendation: {
      bg: jean,
      header: { bg: storm, fg: white },
      subHeader: auroraTeal
    },
    scheduleOptionStatus: {
      ideal: beast,
      "minimal-conflict": auroraTeal
    },
    signalHealth: {
      [healthScoreTypes.EXCELLENT]: marvelMint,
      [healthScoreTypes.GOOD]: lightMarvel,
      [healthScoreTypes.OK]: jean,
      [healthScoreTypes.CONCERNING]: rogue
    },
    relativeHealth: {
      LESS: marvelMint,
      ABOUT_THE_SAME: jean,
      MORE: rogue
    },
    statusMessage: {
      focus: nightcrawler,
      success: auroraTeal,
      warning: phoenix
    },
    stepper: {
      indicators: wolverine
    },
    table: {
      hover: jean
    },
    text: {
      body: wolverine,
      highlight: marvelMint,
      strong: wolverine
    },
    textFlag: wolverine,
    texturedContainer: {
      white: { bg: white },
      lightGrey: { bg: jean },
      darkGrey: { bg: storm, fg: white },
      teal: { bg: auroraTeal, fg: white }
    },
    tooltip: {
      popupShadow: hexToRgb({ hex: jean, opacity: 0.5 })
    },
    uiBackground: white,
    uplevelFooter: {
      color: white,
      border: wolverine
    },
    validationMessage: {
      success: {
        bg: marvelMint,
        fg: auroraTeal
      },
      warning: {
        bg: gambit,
        fg: phoenix
      }
    },
    widgetHeader: { text: wolverine }
  },
  fonts: {
    primary: {
      name: "Real Text Pro",
      sizes: {
        xl: "5.5rem",
        lg: "3.6rem",
        md: "2.6rem",
        sm: "1.5rem",
        xs: "1.3rem"
      },
      weights: {
        hairline: 100,
        thin: 150,
        ultraLight: 200,
        light: 300,
        semiLight: 350,
        regular: 400,
        book: 450,
        medium: 500,
        demiBold: 600,
        bold: 700,
        extraBold: 800,
        black: 900
      }
    },
    header: {
      name: "Real Head Pro",
      sizes: {
        xl: "5.5rem",
        lg: "3.6rem",
        md: "2.6rem",
        sm: "1.5rem",
        xs: "1.3rem"
      },
      weights: {
        demiBold: 600,
        extraBold: 800
      }
    },
    subheader: {
      name: "IBM Plex Mono",
      sizes: {
        xl: "5.5rem",
        lg: "3.6rem",
        md: "2.6rem",
        sm: "1.5rem",
        xs: "1.3rem"
      },
      weights: {
        ultraLight: 200,
        light: 300,
        regular: 400,
        bold: 700,
        extraBold: 800,
        black: 900
      }
    }
  },
  grid: {
    columnWidth: "7.2rem",
    gap: "2.4rem",
    sidebar: "18rem",
    sidebarGutter: "2.8rem",
    gridOffset: "2rem"
  },
  charts: {
    series: {
      opacity: {
        full: 1,
        partial: 0.2
      }
    }
  }
};
