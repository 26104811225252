import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { chunk, times } from "lodash";

// components
import Icon from "../Icon/Icon";
import Stepper from "../Stepper/Stepper";

// hooks
import { useTracking } from "../../hooks/useTracking";

// constants
import { directionTypes } from "../../constants/constants";
const PAGE_SET_LENGTH = 5;

// styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const ChunkStepper = styled(Stepper)`
  align-items: center;
  display: flex;
`;
const PageList = styled.ul`
  display: flex;
  margin: 0 2.5rem;
`;
const StepperIconWrapper = styled.div<{ disabled: boolean }>`
  align-items: center;
  color: ${props =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors.all.auroraTeal};
  display: flex;
  font-family: ${props => props.theme.fonts.subheader.name}, monospace;
  text-transform: uppercase;
`;
const StepperIcon = styled(Icon)`
  color: currentColor;
  font-size: 3rem;
`;
const PageButton = styled.button<{ isActive: boolean }>`
  background: transparent;
  border: 0;

  ${props => {
    if (props.isActive) {
      return css`
        &::after {
          content: "";
          display: block;
          height: 0.2rem;
          background: ${props.theme.colors.all.wolverine} !important;
        }
      `;
    }
  }}
`;
const PageListItem = styled.li`
  & + & {
    margin-left: 1rem;
  }
`;
const Ellipsis = styled(PageListItem)`
  margin-left: 0 !important;
`;

// typescript props
type Props = {
  className?: string;
  currentPage: number;
  onChange: ({
    e,
    updatedPage
  }: {
    e: React.MouseEvent<HTMLButtonElement>;
    updatedPage: number;
  }) => void;
  name: string;
  pageSetLength?: number;
  finalPage: number;
  testId?: string;
};

const PaginationStepper = ({
  className,
  currentPage,
  onChange,
  name,
  pageSetLength = PAGE_SET_LENGTH,
  testId = "testId",
  finalPage
}: Props) => {
  const thisTestId = `${testId}-pagination`;
  const { trackEvent } = useTracking();

  function getChunkIndex(page: number) {
    return Math.floor(page / pageSetLength);
  }

  const [chunkedPages] = useState<Array<Array<number>>>(
    chunk(
      times(finalPage + 1, (i: number) => i),
      pageSetLength
    )
  );
  const [currentChunkIndex, setCurrentChunkIndex] = useState<number>(
    getChunkIndex(currentPage)
  );

  function onClickPage({
    e,
    updatedPage
  }: {
    e: React.MouseEvent<HTMLButtonElement>;
    updatedPage: number;
  }) {
    e.persist();
    onChange({ e, updatedPage });
    trackEvent({
      e,
      label: `${name}-page-change`,
      value: `clicked on page ${updatedPage + 1}`
    });
  }
  function onChangeStepper(
    e: React.MouseEvent<HTMLButtonElement>,
    direction: string
  ) {
    let updatedPage;
    if (direction === directionTypes.NEXT) {
      updatedPage = currentPage + 1;
    } else {
      updatedPage = currentPage - 1;
    }
    setCurrentChunkIndex(getChunkIndex(updatedPage));
    onChange({ e, updatedPage });
    trackEvent({
      e,
      label: `${name}-page-change`,
      value: `stepped page ${direction} to ${updatedPage + 1}`
    });
  }

  const isNextDisabled = currentPage === finalPage;
  const isPrevDisabled = currentPage === 0;
  const hasMorePageSets = currentChunkIndex < chunkedPages.length - 1;
  return (
    <Container className={className} data-testid={thisTestId}>
      <ChunkStepper
        content={
          <PageList>
            {chunkedPages[currentChunkIndex].map(index => (
              <PageListItem key={`page-${index}`}>
                <PageButton
                  id={`${thisTestId}-page-${index + 1}`}
                  isActive={index === currentPage}
                  onClick={e => onClickPage({ e, updatedPage: index })}
                >
                  {index + 1}
                </PageButton>
              </PageListItem>
            ))}
            {hasMorePageSets && <Ellipsis>{"\u2026"}</Ellipsis>}
          </PageList>
        }
        isNextDisabled={isNextDisabled}
        isPrevDisabled={isPrevDisabled}
        nextButton={
          <StepperIconWrapper disabled={isNextDisabled}>
            <span className="visuallyHidden">Next</span>
            <StepperIcon icon="triangle-right" testId={thisTestId} />
          </StepperIconWrapper>
        }
        onChange={onChangeStepper}
        prevButton={
          <StepperIconWrapper disabled={isPrevDisabled}>
            <span className="visuallyHidden">Back</span>{" "}
            <StepperIcon icon="triangle-left" testId={thisTestId} />
          </StepperIconWrapper>
        }
        testId={thisTestId}
      />
    </Container>
  );
};

export default PaginationStepper;
