import React from "react";
import * as LDClient from "launchdarkly-js-client-sdk";
import Tooltip from "../Tooltip/Tooltip";
import { getTeamNameFromTeam, sortTeam } from "../../utils/teams";
import {
  teamDeepDiveSections,
  pageIds,
  urlParamKeys
} from "../../constants/constants";
import { findIndex, get, kebabCase, min } from "lodash";
import {
  UserInterface,
  UserReportWithMetadataInterface
} from "../../interfaces/user";
import PagedStepper, {
  DEFAULT_NUM_VISIBLE_ITEMS
} from "../PagedStepper/PagedStepper";
import { useUrlParams } from "../../hooks/useUrlParams";
import styled from "styled-components/macro";
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import SlackAvatar from "../SlackAvatar/SlackAvatar";
import { useTracking } from "../../hooks/useTracking";
import { getSelectableTeamMembers } from "../../utils/people-picker";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import InformationText from "../../constants/informationText.json";
import { useRouteMatch } from "react-router-dom";
import {
  TeamDeepDiveParams,
  WorkDeepDiveParams,
  UrlParamsInterface
} from "../../interfaces/url-params";

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const AvatarHoverName = styled.div`
  font-size: 1.2rem;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;

const IconWrapper = styled.button<{ isActive: boolean }>`
  background: none;
  border: 0;
  border-bottom: ${props =>
    `${props.theme.borders.widths.md} solid ${
      props.isActive ? props.theme.colors.all.wolverine : "transparent"
    }`};
  padding: 0 0 0.5rem;
  margin-left: 0.25rem;
`;
const TeamIcon = styled(AvatarIcon)<{ enabled: boolean }>`
  font-size: 3rem;
  opacity: ${props => (props.enabled ? 1 : 0.5)};
`;
const StyledSlackAvatar = styled(SlackAvatar)<{ enabled: boolean }>`
  border: ${props => `.2rem solid ${props.theme.colors.border}`};
  border-radius: 50% 50%;
  height: 3rem;
  width: 3rem;
  opacity: ${props => (props.enabled ? 1 : 0.5)};
`;

const Stepper = styled(PagedStepper)`
  & ul {
    gap: 0.8rem;
  }
`;

type Props = {
  className?: string;
  testId?: string;
  teamAvatarEnabled?: boolean;
};

const PeoplePicker = ({
  className,
  testId = "testId",
  teamAvatarEnabled = true
}: Props) => {
  const match = useRouteMatch<{ pageId?: string }>();
  const {
    urlParams,
    updateUrlParams,
    teamDeepDiveParams,
    workDeepDiveParams
  } = useUrlParams();
  const { trackEvent } = useTracking();
  const activeTeam = urlParams.team;
  const user = useSelector((state: AppStateInterface) => get(state, "user"));
  const flags = useSelector((state: AppStateInterface) => state.flags);
  let selectableTeamMembersParams: {
    user: UserInterface;
    flags: LDClient.LDFlagSet;
    teamDeepDiveParams?: TeamDeepDiveParams;
    workDeepDiveParams?: WorkDeepDiveParams;
    urlParams: UrlParamsInterface;
  } = {
    user,
    flags,
    urlParams
  };
  if (match.params.pageId === pageIds.WORK_DEEP_DIVE) {
    selectableTeamMembersParams = {
      ...selectableTeamMembersParams,
      workDeepDiveParams
    };
  }
  if (match.params.pageId === pageIds.TEAM_DEEP_DIVE) {
    selectableTeamMembersParams = {
      ...selectableTeamMembersParams,
      teamDeepDiveParams: teamDeepDiveParams
    };
  }
  const selectableTeamMembers = getSelectableTeamMembers(
    selectableTeamMembersParams
  );
  // we are only showing the privacy tooltip on the deep dive page (except with overall health). On the project explorer,
  // the selectable team members is dependent on groupBy, but content does not change, so we
  // do not want to display the privacy tooltip there.
  const showPrivacyTooltip =
    !!teamDeepDiveParams.selectedSection &&
    teamDeepDiveParams.selectedMetric !== "OVERALL_TEAM_HEALTH";
  const privacyText = InformationText["people-picker-privacy"];
  const teamInsightDisclaimer = InformationText["team-insight-disclaimer"];
  if (!activeTeam) return null;
  const selectedUser =
    activeTeam?.teamMembers.find(m => m.email === urlParams.selectedUser) ||
    "team";
  const foundTeamMemberIndex =
    selectedUser === "team"
      ? 0
      : findIndex(
          activeTeam.teamMembers,
          (u: UserReportWithMetadataInterface) => u.id === selectedUser.id
        );
  const setSelectedUser = (updatedSelectedUser: string) => {
    updateUrlParams({
      [urlParamKeys.SELECTED_USER]: updatedSelectedUser
    });
  };
  const activeIndex = foundTeamMemberIndex >= 0 ? foundTeamMemberIndex : 0;
  const handleAvatarClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    user: UserReportWithMetadataInterface
  ) => {
    if (selectableTeamMembers.includes(user.id)) {
      const isSelectingSameUser = Boolean(
        selectedUser !== "team" &&
          selectedUser.id === user.id &&
          teamAvatarEnabled
      ).valueOf();
      const selectedASpecificPerson = isSelectingSameUser
        ? false
        : Boolean(user).valueOf() && Boolean(user.id).valueOf();
      const whoClicked = selectedASpecificPerson ? user.id.toString() : "team";

      trackEvent({
        e,
        label: `people-picker-avatar`,
        value: `clicked ${whoClicked}`
      });

      e.preventDefault();
      setSelectedUser(selectedASpecificPerson ? user.email : "team");
    }
  };
  const onClickTeam = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (teamAvatarEnabled) {
      trackEvent({
        e,
        label: `people-picker-avatar`,
        value: `clicked the team avatar`
      });

      e.preventDefault();
      selectedUser !== "team" && setSelectedUser("team");
    }
  };

  const peopleAvatars = sortTeam(activeTeam.teamMembers, user).map(
    (t: UserReportWithMetadataInterface): JSX.Element => {
      const personIsEnabled = selectableTeamMembers.includes(t.id);
      return (
        <>
          <Tooltip
            placement="bottom"
            popupContent={
              <AvatarHoverName>
                {personIsEnabled
                  ? t.name
                  : showPrivacyTooltip
                  ? privacyText
                  : teamInsightDisclaimer}
              </AvatarHoverName>
            }
            trigger={
              <IconWrapper
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleAvatarClick(e, t)
                }
                key={t.id}
                isActive={
                  selectedUser !== "team" &&
                  selectedUser.id === t.id &&
                  personIsEnabled
                }
              >
                <StyledSlackAvatar
                  altText={t.name}
                  src={t.slackAvatar}
                  testId={`people-picker-${t.id}`}
                  data-testid={`people-picker-${t.id}`}
                  enabled={personIsEnabled}
                />
              </IconWrapper>
            }
            testId={`people-picker-${kebabCase(t.name)}`}
          />
        </>
      );
    }
  );

  const avatarList = [
    <Tooltip
      placement="bottom"
      popupContent={
        <AvatarHoverName>
          {teamAvatarEnabled || !showPrivacyTooltip
            ? getTeamNameFromTeam(activeTeam)
            : privacyText}
        </AvatarHoverName>
      }
      trigger={
        <IconWrapper
          onClick={e => onClickTeam(e)}
          isActive={selectedUser === "team" && teamAvatarEnabled}
          key={activeTeam.teamId}
          data-testid="team-select-button"
        >
          <TeamIcon
            avatarColor={activeTeam.avatarColor as string}
            avatarIcon={activeTeam.avatarIcon as string}
            testId="team-select-icon"
            data-testid="team-select-icon"
            enabled={teamAvatarEnabled}
          />
        </IconWrapper>
      }
      testId={`${pageIds.TEAM_DEEP_DIVE}-${kebabCase(
        getTeamNameFromTeam(activeTeam)
      )}`}
    />,
    ...peopleAvatars
  ];

  return (
    <AvatarContainer className={"people-picker-avatar-container"}>
      <Stepper
        activeIndex={activeIndex}
        numVisibleItems={min([avatarList.length, DEFAULT_NUM_VISIBLE_ITEMS])}
        items={avatarList}
      />
    </AvatarContainer>
  );
};

export default PeoplePicker;
