import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

// components
import Row from "react-bootstrap/Row";

// styled components
const StyledGridRow = styled(Row)``;

const GridRow = ({ children, ...otherProps }) => {
  if (!children) {
    return null;
  }

  return <StyledGridRow {...otherProps}>{children}</StyledGridRow>;
};

GridRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired
};

export default GridRow;
