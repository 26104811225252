import { useMemo } from "react";

// hooks
import { useSelector } from "react-redux";
import { useQuery, UseQueryResult } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";
import { SimpleTeamInterface } from "../interfaces/team";

// utils
import { requestOrganizationTeamsData } from "../utils/organization-data";

export const useOrganizationTeamsData = (
  includeFlexTeams = true,
  canRequest = true
): UseQueryResult<Array<SimpleTeamInterface>> => {
  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );

  const params = useMemo(
    () => ({
      includeFlexTeams
    }),
    [includeFlexTeams]
  );

  return useQuery(
    ["organization-teams-data", { ...params }],
    () =>
      requestOrganizationTeamsData({
        accessToken,
        tenantId,
        params
      }),
    {
      enabled: !!accessToken && !!tenantId && canRequest,
      refetchOnWindowFocus: false
    }
  );
};
