import { get } from "lodash";

// hooks
import { useSelector } from "react-redux";
import { useTracking } from "./useTracking";
import { useQuery, UseQueryResult } from "react-query";

// interfaces
import { AppStateInterface } from "../interfaces/app-state";
import { OrgTimeRanges } from "../interfaces/organization";

// utils
import { requestOrganizationTimeRanges } from "../utils/organization-data";
import { useMemo } from "react";

export const useOrganizationTimeRanges = (
  canRequest = true
): UseQueryResult<OrgTimeRanges> => {
  const { trackError } = useTracking();

  const accessToken = useSelector(
    (state: AppStateInterface) => state.auth.authAccessToken
  );
  const tenantId = useSelector(
    (state: AppStateInterface) => state.auth.authParams.tenantId
  );
  const params = useMemo(
    () => ({
      accessToken,
      tenantId
    }),
    [accessToken, tenantId]
  );

  return useQuery(
    ["organization-time-ranges", params],
    () => requestOrganizationTimeRanges(params),
    {
      enabled: !!accessToken && !!tenantId && canRequest,
      refetchOnWindowFocus: false,
      onError: error => {
        return trackError({
          errorType: get(error, "errorType"),
          errorParams: {
            id: `fetch-organization-timeranges`,
            requestParams: get(error, "errorParams.requestParams")
          }
        });
      }
    }
  );
};
