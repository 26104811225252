import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// styled components
const Container = styled.small`
  color: ${props => props.theme.colors.all.storm};
`;

// typescript props
type Props = {
  className?: string;
  content?: React.ReactNode;
  testId?: string;
};

const DataDisclaimer = ({
  className,
  content,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-data-disclaimer`;
  return (
    <Container className={className} data-testid={thisTestId}>
      <em>{content || "Uplevel data is refreshed daily."}</em>
    </Container>
  );
};

export default DataDisclaimer;
