import { Primitive, z, ZodLiteral } from "zod";
import { orderedRoles, teamAvatarIcons } from "../constants/constants";
import { legacyRoles, legacyRolesSnakeCase } from "../constants/users";

// start borrowed code from https://tsplay.dev/mxaRKW
type MappedZodLiterals<T extends readonly Primitive[]> = {
  -readonly [K in keyof T]: ZodLiteral<T[K]>;
};

function createManyUnion<
  A extends Readonly<[Primitive, Primitive, ...Primitive[]]>
>(literals: A) {
  return z.union(
    literals.map(value => z.literal(value)) as MappedZodLiterals<A>
  );
}
// end borrowed code

const orgShortIsoFormatRegex = new RegExp(/^\d{4}-\d{2}-\d{2}/);

//---------- start globals ----------//
export const zHexColor = z.string().regex(/^#?([0-9a-f]{6}|[0-9a-f]{3})$/);
export const zRoles = createManyUnion([...orderedRoles, "None"]);
export const zLegacyRoles = createManyUnion(legacyRoles);
export const zLegacyRolesSnakeCase = createManyUnion(legacyRolesSnakeCase);
//---------- end globals ----------//

//---------- start user ----------//
const zUserId = z.number();
const zUplevelRole = z.union([zRoles, z.null()]);
const allUserAttributes = z.object({
  email: z.string().email(),
  enabled_roles: z.array(zLegacyRoles),
  id: zUserId,
  image: z.string().url(),
  include_in_team_roster: z.boolean(),
  name: z.string(),
  reports: z.array(z.undefined()),
  reportGroup: z.string().nullable(),
  role: zLegacyRolesSnakeCase,
  slackAvatar: z.string().url(),
  slackHandle: z.string(),
  team: z.null(),
  tenantAlias: z.null(),
  title: z.string(),
  uplevel_role: zUplevelRole
});
export const zUser = allUserAttributes;
//---------- end user ----------//

//---------- start teams ----------//
export const zTeamAvatarIcons = createManyUnion(teamAvatarIcons);
export const zTruthyOrgChartVersion = z.string().startsWith("bootstrap_");
const allTeamAttributes = z.object({
  avatarColor: zHexColor,
  avatarIcon: zTeamAvatarIcons,
  createdAt: z.string().regex(orgShortIsoFormatRegex),
  createdByUser: zUser,
  createdByUplevelUserId: z.number().nullable(),
  defaultBoardId: z
    .string()
    .uuid()
    .nullable(),
  defaultProjectId: z
    .string()
    .uuid()
    .nullable(),
  includeInExecCharts: z.boolean(),
  isOrgChartTeam: z.boolean(),
  orgChartTeamLeadId: z.number(),
  orgChartVersion: zTruthyOrgChartVersion,
  orgChartVertical: z.null(),
  teamId: z.string().uuid(),
  teamMembers: z.array(zUser),
  teamMemberUplevelUserIds: z.array(z.number()),
  teamName: z.string(),
  tenantId: z.number(),
  useSprints: z.boolean()
});
const baseTeam = allTeamAttributes.pick({
  avatarColor: true,
  avatarIcon: true,
  defaultBoardId: true,
  defaultProjectId: true,
  isOrgChartTeam: true,
  teamId: true,
  teamMembers: true,
  teamName: true,
  tenantId: true,
  useSprints: true
});
export const zNewFlexTeam = baseTeam.extend({
  teamId: z.null()
});
export const zExistingFlexTeam = allTeamAttributes
  .pick({
    createdByUser: true,
    orgChartVertical: true
  })
  .merge(baseTeam)
  .extend({
    orgChartTeamLeadId: z.null(),
    orgChartVersion: z.null()
  });
export const zExistingOrgTeam = allTeamAttributes
  .pick({
    orgChartVersion: true,
    orgChartVertical: true
  })
  .merge(baseTeam)
  .extend({
    createdByUser: z.null(),
    orgChartTeamLeadId: z.number(),
    teamName: z.null()
  });
export const zSimpleTeam = allTeamAttributes
  .pick({
    createdAt: true,
    createdByUplevelUserId: true,
    includeInExecCharts: true,
    orgChartVersion: true,
    orgChartVertical: true,
    teamMemberUplevelUserIds: true
  })
  .merge(baseTeam)
  .omit({ teamMembers: true })
  .extend({
    orgChartTeamLeadId: z.number().nullable(),
    orgChartVersion: zTruthyOrgChartVersion.nullable(),
    teamName: z.string().nullable()
  });

//---------- end teams ----------//

//---------- start charts ----------//
export const zPoint = z.object({
  x: z.number(),
  y: z.number()
});
export const zBenchmark = z.object({
  low: z.number(),
  mid: z.number(),
  high: z.number()
});
//---------- end charts ----------//

//---------- start organization ----------//
export const zOrgTimeRange = z.object({
  endDate: z.string().regex(orgShortIsoFormatRegex),
  startDate: z.string().regex(orgShortIsoFormatRegex)
});
export const zOrgTimeRanges = z.array(zOrgTimeRange);
export const zSimpleTeamItem = zSimpleTeam.extend({
  teamLeadsName: z.string().nullable(),
  teamName: z.string()
});
export const zOrgTeams = z.array(zSimpleTeam);
export const zOrgTeamItems = z.array(zSimpleTeamItem);

// by time
export const zOrgByTime = z.record(
  z.string().regex(orgShortIsoFormatRegex),
  z.number().nullable()
);
export const zOrgByTimeItem = zOrgTimeRange.extend({
  includesFutureDates: z.boolean(),
  value: z.number().nullable()
});
export const zOrgByTimeItems = z.array(zOrgByTimeItem);
export const zOrgByTimeHighlightDataPoints = z.array(zOrgByTimeItem.optional());
export const zOrgPoint = zPoint.merge(zOrgTimeRange);
export const zOrgByTimePoint = zOrgPoint.extend({
  includesFutureDates: z.boolean()
});
export const zOrgByGroupPoint = zOrgPoint.extend({
  groupName: z.union([zRoles, z.string().uuid(), z.string()]),
  role: zRoles,
  teamLeadsName: z.string()
});

// by user
export const zOrgByUserDataRecord = z.object({
  userId: zUserId,
  value: z.number().nullable()
});
export const zOrgByUserDataRecordExtended = zOrgByUserDataRecord
  .extend({
    role: zUplevelRole,
    teamLeadsName: z.string(),
    value: z.number()
  })
  .merge(allTeamAttributes.pick({ teamId: true, teamName: true }))
  .merge(allUserAttributes.pick({ reportGroup: true }));
export const zOrgByUser = zOrgTimeRange.extend({
  data: z.array(zOrgByUserDataRecord)
});

// deep work
export const zOrgDeepWorkGroups = z.record(
  z.union([zRoles, z.string()]),
  z.array(zOrgByUserDataRecordExtended)
);
export const zOrgDeepWorkByGroup = zOrgTimeRange.extend({
  data: zOrgDeepWorkGroups
});

// cycle time
export const zOrgCycleTimeByTimePoint = zOrgByTimePoint.extend({
  isDaysScale: z.boolean()
});
//---------- end organization ----------//
