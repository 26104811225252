import { currentTheme } from "../../themes/currentTheme";
import moment from "moment";
import { sortBy, min, max, round } from "lodash";

// constants
import { chartToolTipConfig, momentUnitTypes } from "../../constants/constants";

// interfaces
import { TooltipPositionerPointObject } from "highcharts";
import { Benchmark, OrgByTimeItems } from "../../interfaces/organization";
import { OrgByTimePoint } from "../../interfaces/charts";
import { UrlParamsInterface } from "../../interfaces/url-params";

// utils
import {
  getAdjustedTooltipPosition,
  getOverviewTrendlineYAxisLabelFormatter
} from "../../utils/chart";
import { hexToRgb } from "../../utils/color";
import { formatTimestamp } from "../../utils/date";
import {
  filterTruthyCycleTimeValues,
  splitCycleTimeHours
} from "../../utils/cycle-time";

const config = ({
  benchmark,
  data,
  selectedStartDate,
  onSelectDate,
  isDaysScale,
  title,
  measure
}: {
  benchmark?: Benchmark;
  selectedStartDate?: UrlParamsInterface["startDate"];
  data: OrgByTimeItems;
  onSelectDate: (
    pointData: Pick<OrgByTimePoint, "endDate" | "startDate">
  ) => void;
  isDaysScale?: boolean;
  title: string;
  measure: "deep-work" | "cycle-time" | "release-frequency";
}): Highcharts.Options => {
  const sortedData = sortBy(data, [d => d.startDate]);
  // making this the same color as deep work but since this isn't deep work,
  // trying not to confuse the issue by using the health factors definition
  const mainColor = currentTheme.colors.all.auroraTeal;
  const series: Array<Highcharts.SeriesLineOptions> = [
    {
      data: sortedData.map(d => {
        return {
          // datetime axis needs to use epoch milliseconds
          x: moment(d.startDate).valueOf(),
          y: d.value,
          startDate: d.startDate,
          endDate: formatTimestamp({
            format: "YYYY-MM-DD",
            timestamp: d.endDate
          }),
          includesFutureDates: d.includesFutureDates,
          marker: {
            fillColor: d.includesFutureDates
              ? currentTheme.colors.all.white
              : mainColor,
            lineColor: mainColor,
            lineWidth: 2,
            radius: moment(d.startDate).isSame(
              selectedStartDate,
              momentUnitTypes.DAYS
            )
              ? 8
              : 4
          }
        } as OrgByTimePoint;
      }),
      name: title,
      type: "line",
      zoneAxis: "x",
      zones: [
        {
          value: moment(sortedData[sortedData.length - 2]?.startDate).valueOf()
        },
        {
          dashStyle: "ShortDot"
        }
      ]
    }
  ];
  const yAxisValuesWithBenchmarks = [
    ...sortedData.map(d => d.value),
    benchmark?.low || 1,
    benchmark?.high || 0
  ];
  const yMin = min(yAxisValuesWithBenchmarks);
  const yMax = max(yAxisValuesWithBenchmarks);

  return {
    chart: {
      height: 500,
      spacingTop: 20,
      style: {
        fontFamily: currentTheme.fonts.primary.name,
        overflow: "visible"
      }
    },
    xAxis: {
      accessibility: {
        description: `Dates from ${sortedData[0].startDate} to ${
          sortedData[sortedData.length - 1].endDate
        }`
      },
      minPadding: 0.05,
      maxPadding: 0.05,
      type: "datetime",
      dateTimeLabelFormats: { month: "%b %Y" },
      lineColor: currentTheme.colors.all.wolverine,
      labels: {
        overflow: "allow",
        enabled: true,
        style: {
          color: currentTheme.colors.all.wolverine,
          fontFamily: currentTheme.fonts.subheader.name
        },
        y: 30
      },
      tickColor: currentTheme.colors.all.wolverine
    },
    yAxis: [
      {
        accessibility: {
          description: `${title} from ${Math.round(yMin || 0)} to ${Math.round(
            yMax || 0
          )}`
        },
        min: yMin,
        max: yMax,
        endOnTick: true,
        startOnTick: true,
        labels: {
          x: 0,
          y: -5,
          align: "left",
          enabled: true,
          formatter: function() {
            return getOverviewTrendlineYAxisLabelFormatter(
              measure,
              this.isFirst,
              this.isLast,
              isDaysScale,
              this.value
            );
          },
          style: {
            color: currentTheme.colors.all.wolverine,
            fontFamily: currentTheme.fonts.subheader.name
          }
        },
        plotLines: [
          {
            color: currentTheme.colors.all.storm,
            dashStyle: "Dash",
            width: 2,
            value: benchmark?.mid
          }
        ],
        plotBands: [
          {
            color: hexToRgb({
              hex: currentTheme.colors.all.iceman,
              opacity: 0.25
            }),
            from: benchmark?.low,
            to: benchmark?.high
          }
        ],
        title: { text: null }
      }
    ],
    legend: {
      enabled: false
    },
    plotOptions: {
      line: {
        connectNulls: true,
        color: mainColor,
        cursor: "pointer",
        lineWidth: 2,
        dataLabels: {
          enabled: false
        },
        states: {
          hover: {
            lineWidth: 2
          }
        },
        point: {
          events: {
            click: function() {
              const { endDate, startDate } = this as any;
              onSelectDate({
                endDate,
                startDate
              });
            }
          }
        }
      }
    },
    title: {
      text: undefined
    },
    tooltip: {
      ...chartToolTipConfig,
      positioner: function(
        w: number,
        h: number,
        point: TooltipPositionerPointObject
      ) {
        return getAdjustedTooltipPosition({
          currentPosition: (this as any).getPosition(w, h, point),
          windowScroll: window.scrollY
        });
      }
    },
    series
  };
};

export default config;
