import React from "react";
import styled from "styled-components/macro";

// styled components
const Container = styled.h2`
  font-family: ${props => props.theme.fonts.header.name}, sans-serif;
  font-size: 2rem;
  font-weight: ${props => props.theme.fonts.header.weights.demiBold};
  margin: 0;
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  title: React.ReactNode;
};

const PageTitle = ({ className, testId = "testId", title }: Props) => {
  const thisTestId = `${testId}-page-title`;
  return (
    <Container className={className} data-testid={thisTestId}>
      {title}
    </Container>
  );
};

export default PageTitle;
