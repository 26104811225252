import React from "react";
import styled from "styled-components/macro";

// components
import Expander from "../Expander/Expander";

// constants
import { signalTypes } from "../../constants/constants";

// styled components
const Container = styled.div<{ type?: string }>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${props => `${props.theme.colors.all.white}`};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  box-shadow: inset 5px 0px
    ${props =>
      props.theme.colors.all[
        props.type === "unhealthy" ? "rogue" : "marvelMint"
      ]};
`;
const Content = styled.div`
  margin-top: 1rem;
`;

// typescript props
type Props = {
  className?: string;
  content?: React.ReactNode;
  defaultIsOpen?: boolean;
  header: React.ReactNode;
  isExpandable?: boolean;
  testId?: string;
  type?: string;
};

const SignalTypeContainer = ({
  className,
  content = null,
  defaultIsOpen = false,
  header,
  isExpandable = false,
  testId = "testId",
  type = signalTypes.HEALTHY
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-signal-type-container`;

  const displayContent = isExpandable ? (
    <Expander defaultIsOpen={defaultIsOpen} header={header} testId={thisTestId}>
      <Content>{content}</Content>
    </Expander>
  ) : (
    <>
      <header>{header}</header>
      {content ? <Content>{content}</Content> : null}
    </>
  );

  return (
    <Container className={className} data-testid={thisTestId} type={type}>
      {displayContent}
    </Container>
  );
};

export default SignalTypeContainer;
