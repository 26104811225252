import React, { useMemo } from "react";
import { differenceBy, sortBy } from "lodash";
import styled, { css } from "styled-components/macro";

// components
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import AvatarIconCreator from "../AvatarIconCreator/AvatarIconCreator";
import SprintsMetadataSelector from "../SprintsMetadataSelector/SprintsMetadataSelector";
import TeamCardDotMenu from "../TeamCardDotMenu/TeamCardDotMenu";
import TeamCardTeamMembers from "../TeamCardTeamMembers/TeamCardTeamMembers";
import TeamMembersAdder from "../TeamMembersAdder/TeamMembersAdder";
import TextInput from "../TextInput/TextInput";
import TransitionWrapper from "../TransitionWrapper/TransitionWrapper";

// hooks

// interfaces
import { BoardInterface } from "../../interfaces/sprint-metadata";
import { TimerangeMetadataInterface } from "../../interfaces/constants";
import { ProjectInterface } from "../../interfaces/work-items";
import {
  NewUser,
  UserReportWithMetadataInterface
} from "../../interfaces/user";
import { SelectOptionsInterface } from "../../interfaces/controls";

// styled components
const Container = styled.div`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
`;
const Header = styled.header`
  align-items: flex-start;
  border-bottom: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
  padding-bottom: 0.4rem;
`;
const DotMenu = styled(TeamCardDotMenu)`
  margin-left: 0.8rem;
`;
const NameWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;
const TeamNameWrapper = styled.div`
  flex: 1;
`;
const Label = styled.label`
  align-items: center;
  display: flex;
  flex: 1;
  margin: 0;
`;
const Icon = styled(AvatarIcon)`
  font-size: 3rem;
  margin-right: 1rem;
`;
const IconCreator = styled(AvatarIconCreator)`
  font-size: 3rem;
  margin-right: 1rem;
`;
const Name = styled.h3`
  font-size: inherit;
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  margin: 0;
`;
const NameInput = styled(TextInput)<{ hasTeamNameError: boolean }>`
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${
      props.hasTeamNameError
        ? props.theme.colors.all.cyclops
        : props.theme.colors.all.jean
    }`};
  flex: 1;
  padding: 0.8rem;
`;
const Adder = styled(TeamMembersAdder)<{ hasTeamMembersError: boolean }>`
  flex: 1;
  margin-top: 0.4rem;
  width: 100%;

  ${props => {
    if (props.hasTeamMembersError) {
      return css`
        border: ${props.theme.borders.widths.sm} solid
          ${props.theme.colors.all.cyclops};
      `;
    }
  }}
`;
const TeamMembers = styled(TeamCardTeamMembers)`
  overflow-y: scroll;
  width: 100%;

  &:not(:empty) {
    margin-top: 1.6rem;
  }
`;
const Error = styled.strong`
  color: ${props => props.theme.colors.all.cyclops};
  display: block;
  font-size: 1.1rem;
  font-weight: ${props => props.theme.fonts.primary.weights.regular};
  margin-top: 0.4rem;
`;

// typescript props
type Props = {
  addedTeamMembers: Array<UserReportWithMetadataInterface | NewUser>;
  avatarColor: string;
  avatarIcon: string;
  board: BoardInterface | null;
  boards: Array<BoardInterface>;
  canEdit: boolean;
  className?: string;
  fullOrganization: Array<UserReportWithMetadataInterface>;
  hasBoardError: boolean;
  hasProjectError: boolean;
  hasTeamMembersError: boolean;
  hasTeamNameError: boolean;
  isEditing: boolean;
  isOrgChartTeam: boolean;
  onBlurTeamName: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChangeTeamName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearAll: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCreateIcon: (avatarIcon: string, avatarColor: string) => void;
  onCreateTeamMember: (value: string) => void;
  onDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRemoveTeamMember: ({
    e,
    teamMember
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    teamMember: UserReportWithMetadataInterface | NewUser;
  }) => void;
  onSelectBoard: (selected: SelectOptionsInterface) => void;
  onSelectProject: (selected: SelectOptionsInterface) => void;
  onToggleSprints: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdateTeamMembers: (selected: Array<SelectOptionsInterface>) => void;
  project: ProjectInterface | null;
  projects: Array<ProjectInterface>;
  shouldUseSprints: boolean;
  sprint: TimerangeMetadataInterface | null;
  teamMembers: Array<UserReportWithMetadataInterface>;
  teamName: string;
  testId?: string;
};

const ExistingTeam = ({
  addedTeamMembers,
  avatarColor,
  avatarIcon,
  canEdit,
  className,
  board,
  boards,
  fullOrganization,
  hasBoardError,
  hasProjectError,
  hasTeamMembersError,
  hasTeamNameError,
  isEditing,
  isOrgChartTeam,
  onBlurTeamName,
  onChangeTeamName,
  onClearAll,
  onCreateIcon,
  onCreateTeamMember,
  onDelete,
  onEdit,
  onRemoveTeamMember,
  onSelectBoard,
  onSelectProject,
  onToggleSprints,
  onUpdateTeamMembers,
  project,
  projects,
  shouldUseSprints,
  sprint,
  teamMembers,
  teamName,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-existing-team`;
  // filter existing team members out of the org options so that they can't be chosen
  const filteredOrganization = useMemo(
    () => differenceBy(fullOrganization, teamMembers, "email"),
    [teamMembers, fullOrganization]
  );

  return (
    <Container
      aria-labelledby="header-your-teams"
      className={className}
      data-testid={thisTestId}
    >
      <Header>
        <NameWrapper data-heap-redact-text="true">
          {isEditing ? (
            <IconCreator
              avatarColor={avatarColor}
              avatarIcon={avatarIcon}
              onCreateIcon={onCreateIcon}
              testId={thisTestId}
            />
          ) : (
            <Icon
              avatarColor={avatarColor}
              avatarIcon={avatarIcon}
              testId={thisTestId}
            />
          )}
          {isEditing ? (
            <TeamNameWrapper>
              <Label htmlFor="team-name-input">
                <span className="visuallyHidden">Team Name</span>
                <NameInput
                  hasTeamNameError={hasTeamNameError}
                  id="team-name-input"
                  onBlur={onBlurTeamName}
                  onChange={onChangeTeamName}
                  placeholder="Team Name"
                  testId={thisTestId}
                  value={teamName || ""}
                />
              </Label>
              {hasTeamNameError ? (
                <TransitionWrapper>
                  <Error>Please enter a Team Name</Error>
                </TransitionWrapper>
              ) : null}
            </TeamNameWrapper>
          ) : (
            <Name>{teamName}</Name>
          )}
        </NameWrapper>
        {canEdit ? (
          <DotMenu
            isEditing={isEditing}
            onClearAll={onClearAll}
            onDelete={onDelete}
            onEdit={onEdit}
            teamName={teamName}
            testId={thisTestId}
          />
        ) : null}
      </Header>
      <SprintsMetadataSelector
        board={board}
        boards={boards}
        hasBoardError={hasBoardError}
        hasProjectError={hasProjectError}
        isEditing={isEditing}
        isOrgChartTeam={isOrgChartTeam}
        onSelectBoard={onSelectBoard}
        onSelectProject={onSelectProject}
        onToggleSprints={onToggleSprints}
        project={project}
        projects={projects}
        shouldUseSprints={shouldUseSprints}
        sprint={sprint}
        testId={thisTestId}
      />
      {isEditing ? (
        <Adder
          addablePeople={filteredOrganization}
          hasTeamMembersError={hasTeamMembersError}
          onCreateTeamMember={onCreateTeamMember}
          onUpdateTeamMembers={onUpdateTeamMembers}
          teamMembers={addedTeamMembers}
          testId={thisTestId}
        />
      ) : null}
      <TeamMembers
        isEditing={isEditing}
        onRemoveTeamMember={onRemoveTeamMember}
        teamMembers={sortBy([...addedTeamMembers, ...teamMembers], t =>
          t.name.toLowerCase()
        )}
        testId={thisTestId}
      />
      {isEditing && hasTeamMembersError ? (
        <TransitionWrapper>
          <Error>Add members to create a Team</Error>
        </TransitionWrapper>
      ) : null}
    </Container>
  );
};

export default ExistingTeam;
