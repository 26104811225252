import React, { ReactElement } from "react";
import styled, { css } from "styled-components/macro";
import { isNil } from "lodash";

// components
import InfoIcon from "../InfoIcon/InfoIcon";
import SprintUpdatesTableItem from "../SprintUpdatesTableItem/SprintUpdatesTableItem";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { RetroTotalsInterface } from "../../interfaces/work-items";
import { useSelector } from "react-redux";
import { AppStateInterface } from "../../interfaces/app-state";
import { getSprintUpdateTablePathParams } from "../../utils/retros";

// styled components
const Header = styled.header`
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
`;
const Title = styled.h3`
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
  font-size: ${props => props.theme.fonts.primary.sizes.sm};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${props => props.theme.grid.gap};
`;
const ValuesWrapper = styled.div<{ canShowAddedMidSprintBreakout?: boolean }>`
  border-top: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  margin-top: 1rem;
  padding-top: 1rem;

  ${props => {
    if (!!props.canShowAddedMidSprintBreakout) {
      return css`
        grid-area: 1/1/3/2;
        display: grid;
        grid-template-columns: 1fr;
      `;
    }
  }}

  &:nth-of-type(-n + 2) {
    border: none;
    margin-top: 0;
    padding-top: 0;
  }
`;
const Nested = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: 2.5em;

  & a + a {
    border-left: ${props =>
      `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
    margin-left: 0.8em;
    padding-left: 0.8em;
  }
`;

// typescript props
type Props = {
  className?: string;
  testId?: string;
  retroTotals: RetroTotalsInterface;
};

const SprintUpdatesTable = ({
  className,
  testId = "testId",
  retroTotals
}: Props): ReactElement | null => {
  const thisTestId = `${testId}-sprint-updates-table`;
  const flags = useSelector((state: AppStateInterface) => state.flags);
  const { getWorkDeepDivePath } = useUrlParams();
  const isStrictUi = flags?.["strict-ui"];
  const canShowAddedMidSprintBreakout =
    isStrictUi &&
    (!isNil(retroTotals.addedMidSprintInSprint) ||
      !isNil(retroTotals.addedMidSprintRemoved));

  return Object.values(retroTotals).every(t => isNil(t)) ? null : (
    <div className={className} data-testid={thisTestId}>
      <Header>
        <Title>Sprint Updates</Title>
        <InfoIcon content="sprint-updates" />
      </Header>
      <Grid>
        {isNil(retroTotals.addedMidSprint) ? null : (
          <ValuesWrapper
            canShowAddedMidSprintBreakout={canShowAddedMidSprintBreakout}
          >
            <SprintUpdatesTableItem
              header="Added mid sprint"
              path={getWorkDeepDivePath(
                getSprintUpdateTablePathParams("addedMidSprint", isStrictUi)
              )}
              value={retroTotals.addedMidSprint}
              testId={thisTestId}
            />
            {canShowAddedMidSprintBreakout ? (
              <Nested>
                {isNil(retroTotals.addedMidSprintInSprint) ? null : (
                  <SprintUpdatesTableItem
                    header="In sprint"
                    path={getWorkDeepDivePath(
                      getSprintUpdateTablePathParams("addedMidSprintInSprint")
                    )}
                    value={retroTotals.addedMidSprintInSprint}
                    testId={thisTestId}
                  />
                )}
                {isNil(retroTotals.addedMidSprintRemoved) ? null : (
                  <SprintUpdatesTableItem
                    header="Removed"
                    path={getWorkDeepDivePath(
                      getSprintUpdateTablePathParams("addedMidSprintRemoved")
                    )}
                    value={retroTotals.addedMidSprintRemoved}
                    testId={thisTestId}
                  />
                )}
              </Nested>
            ) : null}
          </ValuesWrapper>
        )}
        {isNil(retroTotals.notCompleted) ? null : (
          <ValuesWrapper>
            <SprintUpdatesTableItem
              header="Not completed"
              path={getWorkDeepDivePath(
                getSprintUpdateTablePathParams("notCompleted", isStrictUi)
              )}
              value={retroTotals.notCompleted}
              testId={thisTestId}
            />
          </ValuesWrapper>
        )}
        {isNil(retroTotals.carryOverFromPreviousSprint) ? null : (
          <ValuesWrapper>
            <SprintUpdatesTableItem
              header="Carry-over from previous sprint"
              path={getWorkDeepDivePath(
                getSprintUpdateTablePathParams(
                  "carryOverFromPreviousSprint",
                  isStrictUi
                )
              )}
              value={retroTotals.carryOverFromPreviousSprint}
              testId={thisTestId}
            />
          </ValuesWrapper>
        )}
        {isNil(retroTotals.removedMidSprint) ? null : (
          <ValuesWrapper>
            <SprintUpdatesTableItem
              header="Total removed mid sprint"
              path={getWorkDeepDivePath(
                getSprintUpdateTablePathParams("removedMidSprint", isStrictUi)
              )}
              value={retroTotals.removedMidSprint}
              testId={thisTestId}
            />
          </ValuesWrapper>
        )}
        {isNil(retroTotals.unestimatedItems) ? null : (
          <ValuesWrapper>
            <SprintUpdatesTableItem
              header="Unestimated Items"
              path={getWorkDeepDivePath(
                getSprintUpdateTablePathParams("unestimatedItems", isStrictUi)
              )}
              value={retroTotals.unestimatedItems}
              testId={thisTestId}
            />
          </ValuesWrapper>
        )}
        {isNil(retroTotals.completedOutsideSprint) ? null : (
          <ValuesWrapper>
            <SprintUpdatesTableItem
              header="Completed outside of sprint"
              path={getWorkDeepDivePath(
                getSprintUpdateTablePathParams(
                  "completedOutsideSprint",
                  isStrictUi
                )
              )}
              value={retroTotals.completedOutsideSprint}
              testId={thisTestId}
            />
          </ValuesWrapper>
        )}
      </Grid>
    </div>
  );
};

export default SprintUpdatesTable;
