import React, { useState } from "react";
import styled from "styled-components/macro";

// components
import Dropdown from "../Dropdown/Dropdown";
import MeetingClassificationLabel from "../MeetingClassificationLabel/MeetingClassificationLabel";

// constants
import meetingClassifications from "../../constants/meetingClassifications.json";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { SelectOptionsInterface } from "../../interfaces/controls";
import { MeetingInterface } from "../../interfaces/meeting";

// styled components
const Container = styled.div`
  padding: 0 2rem 3rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 8rem 3rem;
  }
`;
const Form = styled.form`
  max-width: 48rem;
`;
const FormHeader = styled.header`
  margin-bottom: 3rem;
`;
const Header = styled.h1`
  font-family: ${props => props.theme.fonts.header.name}, "Helvetica Neue",
    "Aria", sans-serif;
  font-size: ${props => props.theme.fonts.header.sizes.md};
  font-weight: ${props => props.theme.fonts.header.weights.extraBold};
  margin: 0 0 2rem;
  padding: 0;
`;
const Subheader = styled(Header)`
  font-size: ${props => props.theme.fonts.header.sizes.sm};
  margin: 0 0 1rem;
`;
const Text = styled.p`
  margin-bottom: 2rem;
`;
const StyledDropdown = styled(Dropdown)`
  border: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
`;
const SubmitButton = styled.button`
  background: ${props => props.theme.colors.all.auroraTeal};
  border: 0;
  color: ${props => props.theme.colors.all.white};
  padding: 1rem 4rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
`;

// typescript props
type Props = {
  className?: string;
  onSubmit: ({
    title,
    overrideLabel
  }: {
    title: string;
    overrideLabel: string;
  }) => void;
  meeting: MeetingInterface;
  name: string;
  testId?: string;
};

const MeetingClassificationOverrideForm = ({
  className,
  name,
  onSubmit,
  meeting,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-meeting-classification-override-form`;
  const { trackEvent } = useTracking();

  const [selectedClassification, setSelectedClassification] = useState(
    meeting.measure
  );

  function formatOptionLabel(data: SelectOptionsInterface) {
    return (
      <MeetingClassificationLabel
        label={data.label}
        measure={data.value}
        testId={`${thisTestId}-${data.value}`}
      />
    );
  }
  function onSubmitClassification({
    classification,
    listName,
    title
  }: {
    classification: string;
    listName: string;
    title: string;
  }) {
    trackEvent({
      label: `${listName}-meeting-override`,
      value: `changed meeting ${title} to new type ${classification}`
    });
    onSubmit({
      title,
      overrideLabel: classification
    });
  }

  const currentClassification = meetingClassifications.find(
    option => option.value === selectedClassification
  );
  const options = meetingClassifications.map(c => {
    return {
      ...c,
      selected: c.value === currentClassification?.value
    };
  });

  return (
    <Container className={className} data-testid={thisTestId}>
      <Form>
        <FormHeader>
          <Header>Edit Your Meeting Classification</Header>
          <Subheader>Meeting title</Subheader>
          <p>{meeting.title}</p>
        </FormHeader>
        <Text>
          Please choose the new classification that most closely matches your
          meeting type from the dropdown.
        </Text>
        <StyledDropdown
          defaultValue={options.find(o => o.selected)}
          formatOptionLabel={formatOptionLabel}
          label="Meeting classification"
          onChange={(selected: SelectOptionsInterface) => {
            setSelectedClassification(selected.value);
          }}
          options={options}
          testId={testId}
          value={options.find(o => o.selected)}
        />
        <ButtonWrapper>
          <SubmitButton
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              onSubmitClassification({
                classification: selectedClassification,
                listName: name,
                title: meeting.title
              });
            }}
            data-testid={`${testId}-submit-updated-classification-button`}
          >
            Let&rsquo;s go
          </SubmitButton>
        </ButtonWrapper>
      </Form>
    </Container>
  );
};

export default MeetingClassificationOverrideForm;
