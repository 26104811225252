import React, { useState } from "react";
import styled from "styled-components/macro";
import { flatten, isEmpty, get } from "lodash";

// actions
import { storeOrganization } from "../../actions/sessionActions";

// components
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import InfoIcon from "../InfoIcon/InfoIcon";
import SlackAvatar from "../SlackAvatar/SlackAvatar";

// hooks
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "../../hooks/useTracking";
import { useQueryClient } from "react-query";
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import {
  UserReportInterface,
  UserReportWithMetadataInterface
} from "../../interfaces/user";
import { AppStateInterface } from "../../interfaces/app-state";
import { TeamInterface } from "../../interfaces/team";

// styled components
const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  background-color: ${props => props.theme.colors.all.jean};
  width: 26.4rem;
`;

const AvatarWrapper = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: 9.9rem;
`;
const Avatar = styled(SlackAvatar)`
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  border: ${props => `0.2rem solid ${props.theme.colors.border}`};
`;
const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;
const StyledReporteePill = styled.div<{ bordered: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  border-bottom: ${props =>
    `${props.bordered ? "0.2rem" : 0} solid ${
      props.theme.colors.all.lightJean
    }`};
  padding-bottom: 0.5rem;
`;
const ReporteeNameAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const StyledIcon = styled(Icon)<{
  marginLeft: string;
  marginRight: string;
  lineArrow: boolean;
}>`
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  color: ${props =>
    props.lineArrow
      ? props.theme.colors.all.auroraTeal
      : props.theme.colors.all.wolverine};
`;
const ReportsWrapper = styled.div`
  background-color: ${props => props.theme.colors.all.white};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
`;
const ReportsText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const ReportsWrapperExpanded = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.all.white};
`;
const TotalOrgText = styled.div`
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: ${props => `0.2rem solid ${props.theme.colors.all.lightJean}`};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const Link = styled(Button)<{ isActive: boolean }>`
  font-weight: ${props =>
    props.theme.fonts.primary.weights[props.isActive ? "demiBold" : "regular"]};
  text-align: left;

  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    & > span {
      color: inherit !important;
    }
  }
`;
const NumberText = styled.div`
  font-family: ${props => props.theme.fonts.subheader.name};
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  position: "relative";
  font-weight: ${props => props.theme.fonts.primary.weights.bold};
`;

// typescript props
type Props = {
  className?: string;
  person: UserReportWithMetadataInterface;
  isFetchingData?: boolean;
  testId?: string;
};

export const ReportsCard = ({
  className,
  person,
  isFetchingData = false,
  testId = "testId"
}: Props) => {
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { updateUrlParams } = useUrlParams();

  const [reportsDropdownOpen, setReportsDropdownOpen] = useState(false);
  const parentsOfActiveTeam = useSelector((state: AppStateInterface) =>
    get(state, "session.organization.parentsOfActiveTeam")
  );
  const team = person?.team;
  const reports = team?.teamMembers;
  if (!team || !reports?.length) return null;

  function getTotalReportsLength(
    currentReports?: Array<UserReportWithMetadataInterface>
  ): number {
    if (!currentReports) return 0;
    const reportsOfReports = flatten(
      currentReports.map(r => r?.team?.teamMembers || [])
    );
    if (isEmpty(reportsOfReports)) return currentReports.length;
    return currentReports.length + getTotalReportsLength(reportsOfReports);
  }

  async function onClickFactorSet(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newActiveTeam: TeamInterface,
    parentsToAdd: Array<UserReportInterface>
  ) {
    const updatedParents = [...parentsOfActiveTeam, ...parentsToAdd];
    const updatedUser = updatedParents[updatedParents.length - 1];

    dispatch(
      storeOrganization({
        parentsOfActiveTeam: updatedParents
      })
    );

    updateUrlParams({ teamLeadId: updatedUser.id }, false);

    // this is a bit duplicative with OrganiationNavigationMenu, this should be
    // in a hook somewhere central, not a super good place until we're off the
    // legacy app completely
    queryClient.invalidateQueries("projects-and-boards");
    queryClient.invalidateQueries("sprint-metadata");
    queryClient.invalidateQueries("sprint-health");
    queryClient.invalidateQueries("active-board");
    queryClient.invalidateQueries(["teams", { type: "active-users-teams" }]);

    // TODO: investigate why only one of these events is firing, despite similar
    // events in OrganizationNavigationMenu -- something about the component
    // getting unmounted?
    // await trackEvent({
    //   e,
    //   label: `team-roster-set`,
    //   value: `clicked roster for ${updatedUser.name}`
    // });
    await trackEvent({
      e,
      label: "changed-user",
      value: `updated app to ${updatedUser.name} (${updatedUser.email})`
    });
  }

  function onClickDropdown(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    dropdownOpen: boolean
  ) {
    trackEvent({
      e,
      label: `reports-dropdown-clicked`,
      value: `${dropdownOpen}`
    });
    setReportsDropdownOpen(dropdownOpen);
  }

  function getReportsText(currentReports?: TeamInterface) {
    if (!currentReports?.teamMembers.length) return null;
    const teamMembers = currentReports.teamMembers.filter(
      t => t.id !== currentReports.orgChartTeamLeadId
    );
    const totalReportsLength = getTotalReportsLength(teamMembers);
    return (
      <ReportsWrapper>
        <Link
          button="unstyled"
          isActive={false}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onClickFactorSet(e, currentReports, [person])
          }
          testId={testId}
        >
          <ReportsText>
            <StyledIcon
              icon={"users"}
              marginLeft={"1rem"}
              marginRight={"0.5rem"}
              lineArrow={false}
            />
            <b>Direct Reports: </b>
            <NumberText>{teamMembers.length}</NumberText>
            <StyledIcon
              icon={"line-arrow-right"}
              marginLeft={"auto"}
              marginRight={"0"}
              lineArrow={true}
            />
          </ReportsText>
        </Link>
        <Link
          button="unstyled"
          isActive={false}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onClickDropdown(e, !reportsDropdownOpen)
          }
          testId={testId}
        >
          <TotalOrgText>
            <StyledIcon
              icon={"sitemap"}
              marginLeft={"1rem"}
              marginRight={"0.5rem"}
              lineArrow={false}
            />
            <b>Total Employees: </b>
            <NumberText>{totalReportsLength}</NumberText>
            <StyledIcon
              icon={reportsDropdownOpen ? "arrow-up" : "arrow-down"}
              marginLeft={"auto"}
              marginRight={"0"}
              lineArrow={false}
            />
          </TotalOrgText>
        </Link>
      </ReportsWrapper>
    );
  }
  function getReportsList(currentReports: TeamInterface) {
    const currentReportsTeamMembers = currentReports.teamMembers.filter(
      t => t.id !== currentReports.orgChartTeamLeadId
    );
    return (
      <ReportsWrapperExpanded>
        {currentReportsTeamMembers.map((r, idx) => {
          const newTeam = r?.team;
          const teamMembers = newTeam?.teamMembers.filter(
            t => t.id !== newTeam?.orgChartTeamLeadId
          );
          const totalReports = getTotalReportsLength(teamMembers);
          return (
            <ReportsWrapper>
              <Link
                button="unstyled"
                isActive={false}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  onClickFactorSet(
                    e,
                    !!newTeam ? newTeam : currentReports,
                    teamMembers?.length ? [person, r] : [person]
                  )
                }
                testId={testId}
              >
                <StyledReporteePill
                  bordered={idx < currentReportsTeamMembers.length - 1}
                >
                  <AvatarWrapper>
                    <Avatar
                      altText={`${r.name} image`}
                      src={r.image}
                      testId={`${testId}-reports-card`}
                    />
                  </AvatarWrapper>
                  <ReporteeNameAndTitle data-heap-redact-text="true">
                    <b>{r.name}</b>
                    <div>{r.title}</div>
                    {teamMembers && teamMembers?.length ? (
                      <ReportsText>
                        <StyledIcon
                          icon={"users"}
                          lineArrow={false}
                          marginLeft={"0"}
                          marginRight={"0.5rem"}
                        />
                        <NumberText>{teamMembers.length}</NumberText>
                        {totalReports > teamMembers?.length ? (
                          <ReportsText>
                            <StyledIcon
                              icon={"sitemap"}
                              marginLeft={"2rem"}
                              marginRight={"0.5rem"}
                              lineArrow={false}
                            />
                            <NumberText>{totalReports}</NumberText>
                          </ReportsText>
                        ) : null}
                      </ReportsText>
                    ) : null}
                  </ReporteeNameAndTitle>
                  <StyledIcon
                    icon={"line-arrow-right"}
                    marginLeft={"auto"}
                    marginRight={"0"}
                    lineArrow={true}
                  />
                </StyledReporteePill>
              </Link>
            </ReportsWrapper>
          );
        })}
      </ReportsWrapperExpanded>
    );
  }
  return (
    <Container>
      <StyledHeader>
        <div>{"Management Org"}</div>
        <InfoIcon content={"direct-reports"} />
      </StyledHeader>
      {getReportsText(team)}
      {reportsDropdownOpen ? getReportsList(team) : null}
    </Container>
  );
};

export default ReportsCard;
