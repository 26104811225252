import React from "react";
import styled from "styled-components/macro";
import moment from "moment";

// components
import Icon from "../Icon/Icon";
import Stepper from "../Stepper/Stepper";
import Time from "../Time/Time";
import TimeRange from "../TimeRange/TimeRange";

// constants
import {
  directionTypes,
  momentUnitTypes,
  timerangeTypesMetadata
} from "../../constants/constants";

// hooks
import { useTracking } from "../../hooks/useTracking";

// interfaces
import { TimerangeMetadataInterface } from "../../interfaces/constants";

// utils
import {
  formatTimestamp,
  getTimeDifference,
  getTimestampForDate
} from "../../utils/date";

// styled components
const StepperContent = styled.div`
  border-left: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  border-right: ${props =>
    `${props.theme.borders.widths.sm} solid ${props.theme.colors.all.jean}`};
  font-family: ${props => props.theme.fonts.primary.name}, sans-serif;
  font-weight: ${props => props.theme.fonts.primary.weights.demiBold};
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  padding: 0.5rem 2rem;
  margin: 0;
`;
const StepperIconWrapper = styled.div<{ disabled: boolean }>`
  align-items: center;
  color: ${props =>
    props.disabled
      ? props.theme.colors.all.storm
      : props.theme.colors.all.wolverine};
  display: flex;
  padding: 0 1rem;
`;
const StepperIcon = styled(Icon)`
  color: currentColor;
`;
const StyledTimeRange = styled(TimeRange)`
  display: inline-block;
`;
const StepperText = styled.span`
  margin: 0rem 1rem;
`;
const Clamped = styled.span`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
`;

// typescript props
type Props = {
  className?: string;
  isDisabled: boolean;
  onChange: (timerange: TimerangeMetadataInterface) => void;
  testId?: string;
  timerange: TimerangeMetadataInterface;
};

const TimerangeStepper = ({
  className,
  isDisabled = false,
  timerange,
  onChange,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-timerange-stepper`;
  const { trackEvent } = useTracking();

  function getUpdatedStartAndEnd(
    direction: string,
    initialTimerange: TimerangeMetadataInterface
  ) {
    const unit = momentUnitTypes.DAYS;
    const { start, end } = initialTimerange;
    // adding 1 here because the diff between two dates is basically 0-indexed,
    // ie, the diff between start/end for the same day is 0
    const changeInterval =
      (getTimeDifference({
        base: start,
        comparator: end,
        unit
      }) +
        1) *
      (direction === directionTypes.PREV ? -1 : 1);

    const updatedStart = getTimestampForDate(
      moment(start)
        .add(changeInterval, unit)
        .startOf(unit)
    );
    const updatedEnd = getTimestampForDate(
      moment(end)
        .add(changeInterval, unit)
        .endOf(unit)
    );

    return [updatedStart, updatedEnd];
  }

  function onChangeTimerange({
    e,
    direction,
    timerange
  }: {
    e: React.MouseEvent;
    direction: string;
    timerange: TimerangeMetadataInterface;
  }) {
    // only change the time range if the direction is previous or if isNextDisabled is falsy
    if (direction === directionTypes.PREV || !isNextDisabled) {
      const { timerangeType } = timerange;
      const [updatedStart, updatedEnd] = getUpdatedStartAndEnd(
        direction,
        timerange
      );

      onChange({
        ...timerangeTypesMetadata[timerangeType],
        start: updatedStart,
        end: updatedEnd
      });
      trackEvent({
        e,
        label: "ui-filter-time-range-change",
        value: `stepped timerange ${direction} (${formatTimestamp({
          format: "MM/DD/YYYY",
          timestamp: updatedStart
        })}-${formatTimestamp({
          format: "MM/DD/YYYY",
          timestamp: updatedEnd
        })})`
      });
    }
  }

  const timerangeSpansMultipleDays = !!getTimeDifference({
    base: timerange.start,
    comparator: timerange.end,
    unit: momentUnitTypes.DAYS
  });
  const displayTime = timerangeSpansMultipleDays ? (
    <StyledTimeRange
      config={{ format: { end: "M/DD", start: "M/DD" } }}
      timerange={timerange}
      testId={thisTestId}
    />
  ) : (
    <Time format="M/DD" timestamp={timerange.start} testId={thisTestId} />
  );
  // if the next start date is on or after today, the next button should be disabled
  const [nextStart, nextEnd] = getUpdatedStartAndEnd(
    directionTypes.NEXT,
    timerange
  );
  const isNextDisabled =
    isDisabled ||
    nextStart >=
      moment()
        .startOf("day")
        .toISOString();

  return (
    <div className={className} data-testid={thisTestId}>
      <Stepper
        content={
          <StepperContent>
            <Clamped>{displayTime}</Clamped>
          </StepperContent>
        }
        isNextDisabled={isDisabled}
        isPrevDisabled={isDisabled}
        nextButton={
          <StepperIconWrapper disabled={isNextDisabled}>
            <StepperText className="visuallyHidden">Next</StepperText>
            <StepperIcon icon="triangle-right" testId={thisTestId} />
          </StepperIconWrapper>
        }
        onChange={(e: React.MouseEvent, direction: string) =>
          onChangeTimerange({
            e,
            direction,
            timerange
          })
        }
        prevButton={
          <StepperIconWrapper disabled={isDisabled}>
            <StepperIcon icon="triangle-left" testId={thisTestId} />
            <StepperText className="visuallyHidden">Back</StepperText>
          </StepperIconWrapper>
        }
        testId={thisTestId}
      />
    </div>
  );
};

export default TimerangeStepper;
