import React, { ReactElement } from "react";
import styled from "styled-components/macro";

// components
import ChartOpsMetricsSparkline from "../ChartOpsMetricsSparkline/ChartOpsMetricsSparkline";
import CycleTimeValueDisplay from "../CycleTimeValueDisplay/CycleTimeValueDisplay";
import RouteLink from "../RouteLink/RouteLink";
import ValueWithDirectionality from "../ValueWithDirectionality/ValueWithDirectionality";

// constants
import { workDeepDiveSections } from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import {
  OpMetricData,
  OpMetricSprintRecord
} from "../../interfaces/ops-metrics";

// utils
import {
  filterTruthyCycleTimeValues,
  getCycleTimeDelta,
  splitCycleTimeHours
} from "../../utils/cycle-time";
import { getMetricValue } from "../../utils/operational-metrics";

// styled components
const Current = styled(CycleTimeValueDisplay)`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  font-size: ${props => props.theme.fonts.primary.sizes.xl};
  line-height: 1;
  margin-bottom: ${props => props.theme.grid.gap};

  & > div {
    &:nth-of-type(1) {
      flex-wrap: wrap;
      &:not(:last-of-type) {
        margin-right: 0.8rem;
      }
    }
  }
`;
const PreviousHeader = styled.header`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
  &::after {
    content: ":";
    margin-right: 0.8rem;
  }
`;
const Previous = styled.div`
  align-items: baseline;
  display: flex;
`;
const PreviousValue = styled(CycleTimeValueDisplay)`
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const Link = styled(RouteLink)`
  color: ${props => props.theme.colors.all.wolverine};

  &,
  &:visited {
    color: ${props => props.theme.colors.all.wolverine};
  }
  &:hover {
    color: ${props => props.theme.colors.all.auroraTeal};
    text-decoration: none;
  }
`;
const Sparkline = styled(ChartOpsMetricsSparkline)`
  margin: 1.6rem 0;
`;
const Comparison = styled.div`
  align-items: baseline;
  display: flex;
  font-size: ${props => props.theme.fonts.primary.sizes.xs};
`;
const ComparisonHeader = styled.header`
  margin-right: 0.8rem;
  &::after {
    content: ": ";
  }
`;
const OverallDelta = styled(ValueWithDirectionality)`
  white-space: nowrap;
`;

// typescript props
type Props = {
  all: OpMetricData;
  className?: string;
  current: OpMetricSprintRecord;
  first: OpMetricSprintRecord;
  firstMonth: string;
  previous?: OpMetricSprintRecord;
  previousTimerangeHeader: React.ReactNode;
  testId?: string;
};

const OperationalMetricsCycleTimeSummary = ({
  all,
  className,
  current,
  first,
  firstMonth,
  previous,
  previousTimerangeHeader,
  testId = "testId"
}: Props): ReactElement => {
  const thisTestId = `${testId}-ops-metrics-cycle-time-summary`;
  const { getWorkDeepDivePath } = useUrlParams();
  const currentRollup = splitCycleTimeHours(
    getMetricValue(current, "cycleTime") as number
  );
  const firstRollup = splitCycleTimeHours(
    getMetricValue(first, "cycleTime") as number
  );
  let previousRollup;
  if (!!previous) {
    previousRollup = splitCycleTimeHours(
      getMetricValue(previous, "cycleTime") as number
    );
  }
  const overallDeltaValue = getCycleTimeDelta(currentRollup, firstRollup);
  return (
    <div className={className} data-testid={thisTestId}>
      <div>
        <Link
          name="operational-metrics-cycle-time-summary-rollup"
          to={getWorkDeepDivePath({
            selectedSection: workDeepDiveSections.PR_WORKFLOW
          })}
          testId={thisTestId}
        >
          <Current
            delta={getCycleTimeDelta(currentRollup, previousRollup)}
            display="rollup"
            stage={filterTruthyCycleTimeValues(currentRollup)}
            testId={thisTestId}
          />
        </Link>
      </div>
      {!!previousRollup ? (
        <Previous>
          <PreviousHeader>{previousTimerangeHeader}</PreviousHeader>
          <PreviousValue
            display="rollup"
            stage={filterTruthyCycleTimeValues(previousRollup)}
            testId={thisTestId}
          />
        </Previous>
      ) : null}
      <Comparison>
        <ComparisonHeader>Compared to {firstMonth}</ComparisonHeader>
        {!!overallDeltaValue ? (
          <OverallDelta {...overallDeltaValue} display="text" unit="" />
        ) : (
          <div>--</div>
        )}
      </Comparison>
      <footer>
        <Sparkline
          data={all}
          isInverted={true}
          metric="cycleTime"
          name="cycleTime"
          testId={thisTestId}
        />
      </footer>
    </div>
  );
};

export default OperationalMetricsCycleTimeSummary;
