import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { debounce } from "lodash";
import { currentTheme } from "../../themes/currentTheme";

// components
import Dropdown from "../Dropdown/Dropdown";
import GroupByLabel from "../GroupByLabel/GroupByLabel";

// constants
import { groupByTypes, urlParamsValuesLabels } from "../../constants/constants";

// hooks
import { useUrlParams } from "../../hooks/useUrlParams";

// interfaces
import { SelectOptionsInterface } from "../../interfaces/controls";

// styled components
const Container = styled.div`
  position: relative;
  width: 16rem;
`;

// typescript props
type Props = {
  className?: string;
  options: Array<string>;
  testId?: string;
};

const GroupBySelector = ({
  className,
  options,
  testId = "testId"
}: Props): React.ReactElement => {
  const thisTestId = `${testId}-group-by-selector`;
  const { urlParams, updateUrlParams } = useUrlParams();
  const groupBy = urlParams.groupBy;
  const selectedUser = urlParams.selectedUser;

  function formatOptionLabel(data: SelectOptionsInterface) {
    return (
      <GroupByLabel
        label={data.label}
        value={data.value}
        testId={`${thisTestId}-${data.value}`}
      />
    );
  }

  const optionsList = options.map(o => {
    return {
      selected: groupBy === o,
      label: `${urlParamsValuesLabels[o]}`,
      value: `${o}`
    };
  });

  // fixing ts error with this:
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedToggle = useMemo(
    () =>
      debounce(updatedGroupBy => {
        updateUrlParams(
          {
            groupBy: updatedGroupBy,
            selectedUser:
              updatedGroupBy === groupByTypes.USER ? "team" : selectedUser
          },
          true
        );
      }, 500),
    [selectedUser, updateUrlParams]
  );

  return (
    <Container className={className} data-testid={thisTestId}>
      <Dropdown
        defaultValue={optionsList.find(o => o.selected)}
        formatOptionLabel={formatOptionLabel}
        label="Group By"
        onChange={(selected: SelectOptionsInterface) =>
          debouncedToggle(selected.value)
        }
        options={optionsList}
        testId={testId}
        value={optionsList
          .map(o => {
            return {
              selected: o.selected,
              label: "by " + o.label,
              value: o.value
            };
          })
          .find(o => o.selected)}
        isSearchable={false}
      />
    </Container>
  );
};

export default GroupBySelector;
