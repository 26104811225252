import React from "react";
import styled from "styled-components/macro";
import { bgToFgColor } from "../../utils/color";

// components
import Icon from "../Icon/Icon";

// styled components
const Container = styled.div<{ bgColor: string }>`
  align-items: center;
  background: ${props => props.bgColor};
  border: ${props => `.2rem solid ${props.theme.colors.all.wolverine}`};
  border-radius: 50%;
  display: flex;
  height: 1em;
  justify-content: center;
  width: 1em;
  padding: 0.25em;
`;
const StyledIcon = styled(Icon)<{ bgColor: string }>`
  color: ${props => bgToFgColor(props.bgColor)};
  font-size: 0.5em;

  & svg {
    color: inherit;
  }
`;

// typescript props
type Props = {
  avatarColor: string;
  avatarIcon: string;
  className?: string;
  testId?: string;
};

const AvatarIcon = ({
  avatarColor,
  avatarIcon,
  className,
  testId = "testId"
}: Props) => {
  const thisTestId = `${testId}-avatar-icon`;
  return (
    <Container
      className={className}
      bgColor={avatarColor}
      data-testid={thisTestId}
    >
      <StyledIcon bgColor={avatarColor} icon={avatarIcon} testId={thisTestId} />
    </Container>
  );
};

export default AvatarIcon;
