import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { times } from "lodash";

// utils
import parseData from "./parser";
import { getTimestampForDate } from "../../utils/date";

// constants
import { DEFAULT_NUM_WEEKS_DATA_RANGE } from "../../constants/components";
import { momentUnitTypes } from "../../constants/date";
import { requestPrCadence } from "../../utils/pr-cadence";
import { getChartTimerangesForBoardAndTimerange } from "../../utils/date";
import { captureException, withScope } from "@sentry/react";

export const useBackingData = (
  manualState,
  accessToken,
  tenantId,
  user,
  teamMembers,
  board,
  timerange
) => {
  const [data, _setData] = useState([]);
  // set the start date as the beginning of the current week
  // minus DEFAULT_NUM_WEEKS_DATA_RANGE - 1 weeks, eg 12 weeks ago
  const [defaultStartDate] = useState(
    getTimestampForDate(
      moment(new Date())
        .startOf(momentUnitTypes.WEEKS)
        .subtract(DEFAULT_NUM_WEEKS_DATA_RANGE - 1, momentUnitTypes.WEEKS)
    )
  );
  // create array of start/end timestamps for the range of weeks covering the fetched data.
  // using state here to it can be passed to useEffect without infinite looping.
  const [defaultWeekRanges] = useState(
    times(DEFAULT_NUM_WEEKS_DATA_RANGE, i => {
      return {
        start: moment(defaultStartDate)
          .add(i, momentUnitTypes.WEEKS)
          .toISOString(),
        end: moment(defaultStartDate)
          .add(i, momentUnitTypes.WEEKS)
          .endOf(momentUnitTypes.WEEKS)
          .toISOString()
      };
    })
  );
  const weekRanges = useMemo(() => {
    return !!timerange
      ? getChartTimerangesForBoardAndTimerange(timerange, board)
      : defaultWeekRanges;
  }, [board, timerange, defaultWeekRanges]);
  const [isFetchingData, _setIsFetchingData] = useState(null);
  const [error, _setError] = useState(null);
  const requestProps = useMemo(() => {
    return {
      accessToken,
      endDate: weekRanges
        .map(w => w.end)
        .sort()
        .reverse()[0],
      startDate: weekRanges.map(w => w.start).sort()[0],
      tenantId,
      user,
      users: teamMembers?.map(t => t.email)
    };
  }, [accessToken, user, tenantId, teamMembers, weekRanges]);

  // effect to fetch data when the request props change
  useEffect(() => {
    (async () => {
      if (manualState) {
        _setIsFetchingData(false);
        _setData(manualState);
      } else {
        try {
          if (!error) {
            _setIsFetchingData(true);
            const responseData = await requestPrCadence({
              accessToken: requestProps.accessToken,
              tenantId: requestProps.tenantId,
              params: {
                tenantId: requestProps.tenantId,
                startDate: requestProps.startDate,
                endDate: requestProps.endDate,
                users: requestProps.users
              }
            });
            const parsedData = parseData(responseData, weekRanges);
            _setData(parsedData);
            _setIsFetchingData(false);
          }
        } catch (e) {
          _setError(e);
          _setIsFetchingData(false);
          withScope(scope => {
            scope.setContext("request-pr-cadence", requestProps);
            captureException(e);
          });
        }
      }
    })();
  }, [manualState, weekRanges, requestProps, error]);

  return [data, isFetchingData, error];
};
