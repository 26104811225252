import React from "react";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import styled from "styled-components/macro";
import { currentTheme } from "../../themes/currentTheme";
import InformationText from "../../constants/informationText.json";
import HtmlText from "../HtmlText/HtmlText";
import { useTracking } from "../../hooks/useTracking";

type Props = {
  className?: string;
  testId?: string;
  content:
    | "date-range"
    | "people"
    | "owners"
    | "contributors"
    | "sprint-work"
    | "prs-not-in-sprint"
    | "other-activities"
    | "jira-projects"
    | "sprint-callout-added-mid-sprint"
    | "sprint-callout-open-multiple-sprints"
    | "work-items-callout-null-story-points"
    | "pr-callout-stuck-no-comments"
    | "pr-callout-stuck-few-comments"
    | "pr-callout-stuck-many-comments"
    | "pr-callout-stuck-not-merged"
    | "pr-callout-merged-no-approval"
    | "pr-callout-surprising"
    | "bug"
    | "pull-request"
    | "issue"
    | "sub-task"
    | "open"
    | "stuck"
    | "done"
    | "your-team"
    | "cycle-time"
    | "work-allocation"
    | "in-progress"
    | "settings-default-timerange"
    | "settings-default-group-by"
    | "removed-from-sprint"
    | "sprint-updates"
    | "retros-sprint-completion"
    | "retros-sprint-completion-final"
    | "retros-sprint-health"
    | "retros-sprint-health-final"
    | "retros-factor-always-on"
    | "retros-factor-context-switching"
    | "retros-factor-deep-work"
    | "retros-factor-issues-added"
    | "retros-factor-issues-carryover"
    | "retros-factor-issues-closed"
    | "retros-factor-issues-with-descriptions"
    | "retros-factor-issues-with-story-points"
    | "retros-factor-jira-bugs"
    | "retros-factor-prs-merged-no-approval"
    | "retros-factor-prs-no-reviewers"
    | "retros-factor-prs-stuck"
    | "retros-factor-chat-interruptions"
    | "sprint-reactions"
    | "cycle-time-healthy"
    | "cycle-time-unhealthy"
    | "retros-date-range"
    | "retros-people"
    | "direct-reports"
    | "sprint-options-unassigned"
    | "sprint-options-other-teams"
    | "show-related-items"
    | "repositories"
    | "completed-outside-sprint"
    | "deep-work"
    | "organization-overview"
    | "overview-by-group"
    | "overview-benchmark"
    | "release-frequency";
  theme?: string;
};

const PopupContent = styled.div`
  max-width: 40rem;
  word-wrap: anywhere;
  overflow-wrap: anywhere;
  font-size: 1.25rem;
`;

const IconWrapper = styled.div`
  font-size: 1.1rem;
  margin-left: 1rem;
`;

const InfoIcon = ({
  className = "info-icon-trigger",
  testId = "info-icon-trigger",
  content,
  theme = "dark"
}: Props): React.ReactElement => {
  const { trackEvent } = useTracking();

  function onShowTooltip(e: React.MouseEvent<HTMLElement>) {
    e.persist();
    trackEvent({
      e,
      label: `${testId}-${content}-tooltip`,
      value: "tooltip text shown"
    });
  }

  return (
    <IconWrapper>
      <Tooltip
        className={className}
        onShowTooltip={onShowTooltip}
        testId={testId}
        trigger={
          <Icon
            icon="info"
            className="info-icon"
            color={
              theme === "light"
                ? currentTheme.colors.iconInfo.light
                : currentTheme.colors.iconInfo.dark
            }
          />
        }
        popupContent={
          <PopupContent>
            <HtmlText htmlText={InformationText[content]} testId={testId} />
          </PopupContent>
        }
      />
    </IconWrapper>
  );
};

export default InfoIcon;
